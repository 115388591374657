import { MenuItem, MenuList, Paper, Popover, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { getAllPermissions, getAllRolesPermissions } from 'redux/actions/Manage/Permissions/Permissions';
import EditProfile from 'routes/Pages/Manage/EditProfile';
import SidebarThemeContext from '../../../../@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext';
import { AuhMethods } from '../../../../services/auth';
import { CurrentAuthMethod } from '../../../constants/AppConstants';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '30px 16px 12px 16px',
    borderBottom: props => `solid 1px ${props.sidebarTheme.borderColor}`,
  },
  userInfo: {
    paddingTop: 24,
    transition: 'all 0.1s ease',
    height: 75,
    opacity: 1,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: 'all 0.3s ease',
    },
  },
  userTitle: {
    color: props => props.sidebarTheme.textDarkColor,
    marginBottom: 8,
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25,
  },
}));

const SidebarHeader = () => {
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useStyles({ sidebarTheme });
  const dispatch = useDispatch();
  const { authUser } = useSelector(state => state.auth)
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userName, setUserName] = React.useState("");
  const [userEmail, setUserEmail] = React.useState("");
  const successAction = useSelector(state => state.Success.action)
  const [permissionData] = useState([])
  const location = useLocation();
  const [flagSet, setflagSet] = useState(false)
  const [listPermission, setListPermission] = useState(false);
  // const { user } = useSelector(({ auth }) => auth);

  const [openUserDialog, setOpenUserDialog] = useState(false);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const onLogoutClick = () => {
    handlePopoverClose();
    dispatch(AuhMethods[CurrentAuthMethod].onLogout());
    history.push('/signin')
  };
  const handleProfileClick = () => {
    setOpenUserDialog(true)
  };
  useEffect(() => {
    if (authUser) {
      if (authUser?.name)
        setUserName(authUser?.name)
      else if (authUser.user?.name)
        setUserName(authUser.user?.name)
      if (authUser.user?.email)
        setUserEmail(authUser.user?.email)
      else if (authUser?.email)
        setUserEmail(authUser?.email)
    }
  }, [userName, userEmail])
  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    handlePopoverClose();
  };
  useEffect(() => {
    if (authUser?.user?.permissions) {
      const permissionList = Object.keys(authUser?.user?.permissions)
      // const { roleId } = authUser?.user;
      // if (roleId?.roleSlug === 'admin') {
      // dispatch(getAllPermissions((permissionList) => 
      // {
      permissionList.map(permissions => { permissionData.push(permissions) })
      // localStorage.setItem('permission', JSON.stringify(permissionData));
      setflagSet(true)
      // }
      // )
      // )
      // }
    }
    // if (authUser?.user?.roleId) {
    //   const { roleId } = authUser?.user;
    //   if (roleId?.roleSlug === 'admin') {
    //     dispatch(getAllPermissions((permissionList) => {
    //       permissionList.map(permissions => {
    //         permissions.permissionData.map(currPermission => { permissionData.push(currPermission) })
    //       })
    //       localStorage.setItem('permission', JSON.stringify(permissionData));
    //       setflagSet(true)
    //     }))
    //   } else if (roleId?._id) {
    //     dispatch(getAllRolesPermissions(roleId?._id, (rolePermissions) => {
    //       const { permissionId } = rolePermissions
    //       permissionId.map(permissions => permissionData.push(permissions))
    //       localStorage.setItem('permission', JSON.stringify(permissionData));
    //       setflagSet(true)
    //     }))
    //   }
    //   else {
    //     dispatch(getAllRolesPermissions(roleId, (rolePermissions) => {
    //       const { permissionId } = rolePermissions
    //       permissionId.map(permissions => permissionData.push(permissions))
    //       localStorage.setItem('permission', JSON.stringify(permissionData));
    //       setflagSet(true)
    //     }))
    //   }
    // }
  }, [authUser])
  const [permissionList] = useState(JSON.parse(localStorage.getItem('permission')));

  const getPermission = useCallback(() => {
    permissionData &&
      permissionData.forEach(function (num) {
        if (num === 'viewProfileAccess') {
          setListPermission(true);
        }
      });
  }, [permissionData, authUser]);
  useEffect(() => {
    getPermission()
  }, [flagSet])
  return (
    <div className={classes.root}>
      {/* <CmtAvatar src={'https://via.placeholder.com/150'} alt="User Avatar" /> */}
      <div className={classes.userInfo} onClick={handlePopoverOpen}>
        <div
          className="pointer"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}>
          <div className="mr-2">
            <Typography className={classes.userTitle} component="h3" variant="h6">
              {userName}
            </Typography>
            <Typography className={classes.userSubTitle}>{userEmail}</Typography>
          </div>
          <ArrowDropDownIcon />
        </div>
      </div>

      {open && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          container={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}>
          <Paper elevation={8}>
            <MenuList>
              {listPermission && <MenuItem onClick={handleProfileClick}>
                <PersonIcon />
                <div className="ml-2">Profile</div>
              </MenuItem>}
              <MenuItem onClick={onLogoutClick}>
                <ExitToAppIcon />
                <div className="ml-2">Logout</div>
              </MenuItem>
            </MenuList>
            {openUserDialog && <EditProfile open={openUserDialog} onCloseDialog={handleCloseUserDialog} />}
          </Paper>
        </Popover>
      )}
    </div>
  );
};

export default SidebarHeader;
