import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  makeStyles,
  Chip,
  TextField,
} from '@material-ui/core';
import { Add, RotateLeft } from '@material-ui/icons';

import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import WysiwygEditorExample from 'components/wysiwygEditor';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import { getGradeModalList } from 'redux/actions/Manage/GradeModal';
import {
  crateMaterialGrade,
  getMaterialGradeListById,
  getMaterialList,
  updateMaterialGrade,
} from 'redux/actions/Manage/MaterialGrade';
import { getAllEqGrades } from 'redux/actions/Manage/EqGrades/EqGrade';
import CustomEditor from 'components/CustomEditor';
import { Autocomplete } from '@material-ui/lab';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

const useStyles = makeStyles((theme) => ({
  errorCk: {
    color: '#f44336',
    marginRight: '14px',
    marginTop: '4px',
    fontSize: '0.75rem',
  },
  avatar: {
    borderRadius: '0',
    width: 140,
    height: 140,
    objectFit: 'cover',
    objectPosition: 'center',
    padding: 5,
    marginTop: '14px',
  },
  typoMarginBottom: {
    marginBottom: '10px',
  },
  editorLabelError: {
    color: '#f44336',
    fontSize: '16px',
    fontWeight: 500,
  },
  textarea: {
    width: '100%',
    height: '100%',
    padding: '8px',
    fontSize: '16px',
    fontFamily: 'inherit',
    fontWeight: 300,
    borderColor: 'lightgray',
    borderRadius: '4px',

    '&:focus': {
      borderColor: '#1C61CA',
      borderWidth: '2px',
      outline: 'none',
      borderRadius: 4,
    },
  },
  textarea_red: {
    width: '100%',
    height: '100%',
    padding: '8px',
    fontSize: '19px',
    fontFamily: 'Normal',
    fontWeight: 200,
    borderColor: '#f44336',
    borderRadius: '4px',
    '&:focus': {
      borderColor: '#1C61CA',
      borderWidth: '2px',
      outline: 'none',
      borderRadius: 4,
    },
  },

  label: {
    fontSize: '16px',
    fontFamily: 'inherit',
    fontWeight: 400,
    lineHeight: 1.43,
  },
  errorLabel: {
    color: '#f44336',
    fontSize: '16px',
    fontFamily: 'inherit',
    fontWeight: 400,
    lineHeight: 1.43,
  },
  grid: {
    marginBottom: '10px',
    marginTop: '10px',
  },
  gridFile: {
    marginTop: '13px',
  },

  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      transform: "translate(14px, 13px) scale(1);"
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      padding: 1
    }
  }
}));


const AddEditMaterialGrade = ({ history,singleMaterialGrade }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [descriptionHTML, setDescriptionHTML] = useState('');
  const [errorText, setErrorText] = useState({});
  const [file, setFile] = useState([]);
  const [materialData, setMaterialData] = useState([]);
  const [eqGradeData, setEqGradeData] = useState([]);
  // const materialData = useSelector(state => state?.Materials?.materials);
  const gradeData = useSelector(state => state?.GradeModel?.gradesModal);
  const [keywordsArr, setKeywordsArr] = useState([]);
  const [newValue, setValue] = useState("");
  const [inputValue, setInputValue] = useState('')
  const [openDialog, setOpenDialog] = useState(false);
  const [metaCount, setMetaCount] = useState({
    title: 0,
    meta: 0
  })
  const [materialGrade, setMaterialGrade] = useState({
    materialId: '',
    gradeId: '',
    eqGradeId: '',
    meta: '',
    name: '',
    code: '',
    url: '',
    AltImageName: '',
    title: '',
    keywords: '',
    preview: '',
  });

  const handleChange = evt => {
    const { name, value } = evt.target;
    console.log(name, value)
    const regex = /[\W_]+|\s+/g;
    if (name === 'name') {
      const nameValue = value ? value.replace(regex, '-').toLowerCase() : '';
      materialGrade.code = nameValue;
      materialGrade.url = nameValue;
    }
    setMaterialGrade({ ...materialGrade, [name]: value });
    setMetaCount({ ...metaCount, [name]: value.length })
  };

  const handleOnSelect = (params, n) => {
    console.log(params, n)
  }

  const countWords = str => {
    // Remove leading and trailing whitespaces
    str = str.trim();

    // Remove consecutive whitespaces and replace them with a single space
    str = str.replace(/\s+/g, ' ');

    // Split the string by spaces to get an array of words
    var words = str.split(' ');

    // Return the number of words
    return words.length;
  };

  const validate = () => {
    let errors = {};
    let isError = false;
    const {
      materialId,
      gradeId,
      eqGradeId,
      code,
      meta,
      name,
      title,
      url,
      preview,
    } = materialGrade;
    let tempDescription = descriptionHTML;
    let noSpaceData = tempDescription.replace(/\&nbsp;/g, '');
    const noTagData = noSpaceData.replace(/<[^>]*>/g, '');

    if (!materialId) {
      errors.materialId = 'Please select material.';
      isError = true;
    }
    if (!gradeId && !eqGradeId) {
      errors.gradeId = 'Please select anyone grade or equivalent.';
      isError = true;
    } else if (gradeId && eqGradeId) {
      errors.gradeId = '';
      setOpenDialog(true);
      isError = true;
    }
    if (!name.trim()) {
      errors.name = 'Please enter name.';
      isError = true;
    }
    if (!code) {
      errors.code = 'Please enter code.';
      isError = true;
    }
    if (noTagData.length < 2) {
      errors.descriptionHTML = 'Please enter description.';
      isError = true;
    }
    if (!meta) {
      errors.meta = 'Please enter meta description.';
      isError = true;
    }
    if (countWords(meta) > 300) {
      errors.meta = 'Meta description must be less than 300 words.';
      isError = true;
    }
    if (!title) {
      errors.title = 'Please enter meta title.';
      isError = true;
    }
    if (!url) {
      errors.url = 'Please enter URL.';
      isError = true;
    }
    if (!keywordsArr.length) {
      errors.keywords = 'Please enter keyword.';
      isError = true;
    }
    if (!file.length && !preview) {
      errors.file = 'Please upload image.';
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };

  const validateKeyword = () => {
    let error = '';
    let isError = false;
    if (!keywords) {
      error = 'Please enter keywords.';
      isError = true;
    }
    setErrorText({ ...errorText, keywords: error });
    return {
      error,
      isError,
    };
  };

  const onKeywordsAdd = () => {
    let validateData = validateKeyword();
    if (!validateData.isError) {
      setKeywordsArr(keywordsArr => [...keywordsArr, keywords]);
      setMaterialGrade({ ...materialGrade, keywords: '' });
    }
  };

  const onKeywordsDelete = (e, value) => {
    setKeywordsArr(keywordsArr =>
      keywordsArr.filter(e => {
        if (e.length) {
          return e != value;
        }
      }),
    );
  };

  const onSubmitClick = () => {
    let validateData = validate();
    if (!validateData.isError) {
      handleProductSave();
    }
  };

  const handleProductSave = async () => {
    let formData = new FormData();
    id && formData.append('id', id);
    formData.append('materialId', materialId);
    gradeId && formData.append('gradeModelId', gradeId);
    eqGradeId && formData.append('equivalentGradeId', eqGradeId);
    formData.append('name', name);
    formData.append('metaTitle', title);
    formData.append('code', url);
    formData.append('url', url);
    formData.append('AltImageName', AltImageName);
    formData.append('metaDescription', meta);
    keywordsArr.map(e => formData.append('keywords', e));
    formData.append('description', descriptionHTML);
    formData.append('logo', file[0] ? file[0] : preview);
    if (id) {
      dispatch(updateMaterialGrade(id, formData)).then(res => {
        if (res?.status === 200) {
          // window.location.reload();
          // history.push('/manage-material-grade');
          dispatch(getMaterialGradeListById(id))
        }
      })
    } else {
      dispatch(crateMaterialGrade(formData)).then(res => {
        if (res?.status === 200) {
          history.push('/manage-material-grade');
        }
      })
    }
    // dispatch(
    //   id ? updateMaterialGrade(id, formData) : crateMaterialGrade(formData),
    // ).then(res => {
    //   if (res?.status === 200) {
    //     window.location.reload();
    //     // history.push('/manage-material-grade');
    //   }
    // });
  };

  useEffect(() => {
    dispatch(getMaterialList()).then(res => {
      if (res?.status === 200) {
        setMaterialData(res?.data?.data?.findExistingMaterial);
      }
    });
    dispatch(getGradeModalList({}));
    dispatch(getAllEqGrades()).then(res => {
      if (res?.status === 200) {
        setEqGradeData(res?.data?.data?.equivalentGradeList);
      }
    });
  }, []);

  useEffect(()=>{
    if (id && singleMaterialGrade) {
      const {
        materialId,
        gradeModelId,
        equivalentGradeId,
        metaDescription,
        name,
        code,
        url,
        metaTitle,
        AltImageName,
        keywords,
        logo,
        description,
      } = singleMaterialGrade;
      setMaterialGrade({
        materialId,
        gradeId: gradeModelId ? gradeModelId : '',
        eqGradeId: equivalentGradeId ? equivalentGradeId : '',
        meta: metaDescription,
        name,
        code,
        url,
        AltImageName,
        title: metaTitle,
        keywords: '',
        preview: logo,
        description,
      });
      setMetaCount({ ...metaCount, title: metaTitle?.length, meta: metaDescription?.length })
      setKeywordsArr(keywords);
      setDescriptionHTML(description);
    }
  },[singleMaterialGrade])

  useEffect(() => {
    if (id) {
      setValue({ ...gradeData?.filter(e => e._id == materialGrade.gradeId)[0] })
    }
  }, [gradeData, materialGrade])

  const {
    gradeId,
    materialId,
    meta,
    name,
    code,
    url,
    title,
    keywords,
    AltImageName,
    preview,
    eqGradeId,
    description,
  } = materialGrade;
  return (
    <PageContainer>
      <CmtCard>
        <CmtCardContent>
          <Typography variant="h2" className="mb-2">
            {id ? 'Edit Material Grade' : 'Add Material Grade'}
          </Typography>
          <Divider />
          <GridContainer className="mt-2">
            <Grid item md={6} sx={12}>
              <AppSelectBox
                required
                data={materialData}
                name="materialId"
                label="Material"
                valueKey="materialId"
                variant="outlined"
                labelKey="name"
                value={materialId}
                onChange={handleChange}
                helperText={errorText?.materialId}
              />
              <br />
              <br />

              <AppTextInput
                required
                fullWidth
                name="name"
                variant="outlined"
                label="Name"
                value={name}
                onChange={handleChange}
                className="mb-5"
                helperText={errorText?.name}
              />
              {/* <AppTextInput
                required
                fullWidth
                name="code"
                variant="outlined"
                label="Code"
                value={code}
                onChange={handleChange}
                className="mb-5"
                helperText={errorText?.code}
              /> */}
              <AppTextInput
                fullWidth
                name="AltImageName"
                variant="outlined"
                // required
                label="Alt Image Name"
                value={AltImageName}
                onChange={handleChange}
              />

              <Grid className={classes.grid} item xs={12} sm={12}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <label className={errorText.meta ? classes.errorLabel : classes.label}>Meta Description*</label>
                  <label className={classes.label}>{metaCount.meta}</label>
                </Box>
                <textarea
                  className={
                    errorText.meta ? classes.textarea_red : classes.textarea
                  }
                  rows={3}
                  fullWidth
                  name="meta"
                  width={100}
                  required
                  variant="outlined"
                  label="Meta Description"
                  value={meta}
                  onChange={handleChange}
                />
                {errorText.meta ? (
                  <p style={{ color: '#f44336', fontSize: '12px' }}>
                    {errorText?.meta}
                  </p>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>

            <Grid item md={6} sx={12}>
              <GridContainer>
                <Grid item md={5} sx={12}>
                  {/* <AppSelectBox
                    required
                    data={gradeData?.sort(({ name: a }, { name: b }) => a.localeCompare(b))}
                    name="gradeId"
                    label="Grade"
                    valueKey="_id"
                    variant="outlined"
                    labelKey="name"
                    value={gradeId}
                    onChange={handleChange}
                    helperText={errorText?.gradeId}
                  /> */}
                  {gradeData && <Autocomplete
                    options={gradeData}
                    classes={classes}
                    helperText={errorText?.gradeId}
                    getOptionLabel={(option) => option.name}
                    // getOptionSelected={(option, value) => option?._id === value?._id} // Compare by "_id"
                    onInputChange={(evt, inputValue) => {
                      setInputValue(inputValue);
                    }}
                    value={newValue}
                    onChange={(evt, newValue) => {
                      setValue(newValue);
                      setMaterialGrade({ ...materialGrade, gradeId: newValue?._id });
                    }}
                    inputValue={inputValue}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Grade"
                        variant="outlined"
                      />
                    )}
                  />
                  }

                </Grid>
                <Grid item md={5} sx={12}>
                  <AppSelectBox
                    required
                    data={eqGradeData || []}
                    name="eqGradeId"
                    label="Eq-Grade"
                    valueKey="_id"
                    variant="outlined"
                    labelKey="name"
                    value={eqGradeId}
                    onChange={handleChange}
                    helperText={errorText?.gradeId}
                  />
                </Grid>
                <Grid item md={2} sx={12}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                      setMaterialGrade({
                        ...materialGrade,
                        eqGradeId: '',
                        gradeId: '',
                      })
                    }
                  >
                    <RotateLeft />
                  </Button>
                </Grid>
              </GridContainer>
              <br />
              <AppTextInput
                required
                fullWidth
                name="title"
                variant="outlined"
                label={`Meta Title / ${metaCount.title}`}
                value={title}
                onChange={handleChange}
                className="mb-5"
                helperText={errorText?.title}
              />

              <AppTextInput
                required
                fullWidth
                name="url"
                variant="outlined"
                label="URL"
                value={url}
                onChange={handleChange}
                className="mb-5"
                helperText={errorText?.url}
              />
              <Grid className={classes.gridFile} item xs={12} sm={12}>
                <AppTextInput
                  fullWidth
                  name="file"
                  variant="outlined"
                  type="file"
                  onChange={evt => setFile(evt.target.files)}
                  className="mb-5"
                  helperText={errorText?.file}
                />
              </Grid>
              {preview && <img src={preview} alt={AltImageName} className={classes?.avatar} />}
            </Grid>

            <Grid item xs={12} sm={11} md={11}>
              <AppTextInput
                fullWidth
                name="keywords"
                variant="outlined"
                required
                label="Keywords"
                value={keywords}
                onChange={handleChange}
                className="mt-5"
                helperText={errorText?.keywords}
              />
            </Grid>
            <Grid item sm={1} md={1}>
              <Button
                variant="contained"
                color="secondary"
                className="mt-5"
                onClick={onKeywordsAdd}
              >
                <Add />
              </Button>
            </Grid>

            {keywordsArr && keywordsArr.length > 0 && (
              <Grid item xs={12} sm={12}>
                <Box>
                  {keywordsArr.map((e, key) => {
                    return (
                      e.length !== 0 && (
                        <Chip
                          size="medium"
                          key={e}
                          label={e}
                          clickable
                          onDelete={evt => onKeywordsDelete(evt, e)}
                          color="secondary"
                          variant="outlined"
                          className="mb-2 mr-2"
                        />
                      )
                    );
                  })}
                </Box>
              </Grid>
            )}

            {/* {keywordsArr && keywordsArr.length > 0 && (
              <Grid item xs={12} sm={12}>
                <Box>
                  {keywordsArr.map((e, key) => {
                    return (
                      e.length !== 0 && (
                        <Chip
                          size="medium"
                          key={e}
                          label={e}
                          clickable
                          onDelete={evt => onKeywordsDelete(evt, e)}
                          color="secondary"
                          variant="outlined"
                          className="mb-2 mr-2"
                        />
                      )
                    );
                  })}
                </Box>
              </Grid>
            )} */}

            <p
              style={{ marginLeft: "12px" }}
              className={
                errorText?.descriptionHTML ? classes?.editorLabelError : ''
              }
            >
              Description *
            </p>
            <Grid item xs={12} sm={12}>
              {/* <WysiwygEditorExample
                defaultDescription={description}
                setDescriptionHTML={setDescriptionHTML}
                descriptionHTML={description}
              /> */}

              {/* {console.log("description :", description)} */}

              <CustomEditor
                editorData={descriptionHTML || ''}
                setEditorData={setDescriptionHTML}
                error={errorText?.descriptionHTML}
              />


              <span className={classes?.errorCk}>
                {errorText?.descriptionHTML}
              </span>
            </Grid>
            <Box display="flex" justifyContent="flex-end" mb={4}>
              <Button onClick={() => history.push('/manage-material-grade')}>
                Cancel
              </Button>
              <Box ml={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onSubmitClick}
                >
                  {id ? 'Update' : 'Save'}
                </Button>
              </Box>
            </Box>
          </GridContainer>
        </CmtCardContent>
      </CmtCard>
      <ConfirmDialog
        open={openDialog}
        title={`Warning`}
        content={'Please select any one between Eq-Grade and Grade.'}
        onClose={() => setOpenDialog(false)}
        onConfirm={() => setOpenDialog(false)}
      />
    </PageContainer>
  );
};

export default AddEditMaterialGrade;
