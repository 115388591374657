import { isValidEmail } from '@jumbo/utils/commonHelper';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { alpha, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import CmtImage from '../../../../@coremat/CmtImage';
import { AuhMethods } from '../../../../services/auth';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import IntlMessages from '../../../utils/IntlMessages';
import ContentLoader from '../../ContentLoader';
import AppTextInput from '../formElements/AppTextInput';
import AuthWrapper from './AuthWrapper';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
}));
//variant = 'default', 'standard'
const SignIn = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles({ variant });

  const [errorText, setErrorText] = React.useState({});
  const validate = () => {
    const errors = {};
    let isError = false;
    if (!email) {
      errors.email = 'Please enter email.';
      isError = true;
    }
    else if (!isValidEmail(email)) {
      errors.email = 'Please enter valid email.';
      isError = true;
    }
    if (!password) {
      errors.password = 'Please enter password.';
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };
  const onSubmitClick = () => {
    let validateData = validate();
    if (!validateData.isError) {
      onUserSave();
    }
  };
  const onUserSave = () => {
    dispatch(AuhMethods[method].onLogin({ email, password }));
  };
  // useEffect(() => {
  //   if (successAction === USER_LOGOUT_SUCCESS || successAction === SESSION_EXPIRED || successAction === RESET_PASSWORD_SUCCESS) {
  //     dispatch(setOtpVerified(false));
  //     setSuccess(true)
  //     setSuccessMsg(success)
  //   }
  // }, [successAction])
  // useEffect(() => {
  //   if (!success)
  //     setSuccess(false)
  // }, [success])
  // useEffect(() => {
  //   return () => {
  //     dispatch(hideError())
  //   }
  // }, [])

  return (
    <>
      {/* {error && isOpen && <AlertBox type='error' style="filled" >{error}</AlertBox>} */}
      {/* {Success && <AlertBox type='success' style="filled" >{successMsg}</AlertBox>} */}
      <AuthWrapper variant={wrapperVariant}>
        {variant === 'default' ? (
          <Box className={classes.authThumb}>
            {/* <CmtImage src={'/images/auth/login-img.png'} /> */}
            <CmtImage src={'/images/piping-logo.png'} />
          </Box>
        ) : null}
        <Box className={classes.authContent}>
          <Box mb={7}>
            {/* <CmtImage src={'/images/logo.png'} /> */}
            <CmtImage src={'/images/piping-logo.png'} />
          </Box>
          <Typography component="div" variant="h1" className={classes.titleRoot}>
            Login
          </Typography>
          <form>
            <Box mb={2}>
              <AppTextInput
                label={<IntlMessages id="appModule.email" />}
                fullWidth
                onChange={event => setEmail(event.target.value)}
                defaultValue={email}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
                helperText={errorText?.email}
              />
            </Box>
            <Box mb={2}>
              <AppTextInput
                type="password"
                eyeBtn={true}
                label={<IntlMessages id="appModule.password" />}
                fullWidth
                onChange={event => setPassword(event.target.value)}
                defaultValue={password}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
                helperText={errorText?.password}
              />
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
              {/* <Button onClick={onSubmit} variant="contained" color="primary"> */}
              <Button onClick={onSubmitClick} variant="contained" color="primary">
                <IntlMessages id="appModule.signIn" />
              </Button>
              {/* <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
                <NavLink to="/forgot-password">
                  <IntlMessages id="appModule.forgotPassword" />
                </NavLink>
              </Box> */}
            </Box>
          </form>
          {dispatch(AuhMethods[method].getSocialMediaIcons())}
          <ContentLoader />
        </Box>
      </AuthWrapper>
    </>
  );
};

export default SignIn;
