import Axios from "axios";
import { MSG_ERR_RESPONDING_SERVER, PER_PAGE_DEFAULT } from 'redux/actions/Common/Constant';
import { ErrorCatch } from "utils/ErrorCatch";
import { setError } from "../../Common/ErrorAction";
import { setLoading } from "../../Common/LoadingAction";
import { setSuccess } from "../../Common/SuccessAction";
import { DELETE_BULK_GRADE_FAILED, DELETE_BULK_GRADE_REQUEST, DELETE_BULK_GRADE_SUCCESS, GRADE_CREATE_FAILED, GRADE_CREATE_REQUEST, GRADE_CREATE_SUCCESS, GRADE_DELETE_FAILED, GRADE_DELETE_REQUEST, GRADE_DELETE_SUCCESS, GRADE_LIST_FAILED, GRADE_LIST_REQUEST, GRADE_LIST_SUCCESS, GRADE_UPDATE_FAILED, GRADE_UPDATE_REQUEST, GRADE_UPDATE_SUCCESS, SET_GRADEELM_DETAILS, SET_GRADE_DETAILS } from "./Constant";

let RES_MSG = MSG_ERR_RESPONDING_SERVER

// export const getAllUsers = (filterOptions = [],params, searchTerm = '', callbackFun) =>
export const getAllGrades = (params, callbackFun, isDownload = false) =>
    async (dispatch) => {
        dispatch(setLoading(GRADE_LIST_REQUEST))
        try {
            // const { data } = await Axios.get(`/user/list,?page=${1}`)
            // const { data } = await Axios.get(`/auth/fetch_admins`, { params })
            // const { data } = await Axios.get(`/auth/fetch_admins`)
            const response = await Axios.post(`/product/gradeAdmin`, { params })
            const { data } = response
            const total = data?.total
            if (response.status === 200) {
                if (isDownload)
                    dispatch(setSuccess(null, GRADE_LIST_SUCCESS))
                else {
                    dispatch({
                        type: GRADE_LIST_SUCCESS,
                        data: data.data,
                        total: total,
                        message: data.message,
                        perPage: PER_PAGE_DEFAULT,
                    });
                }
                if (callbackFun) callbackFun(data.data);
            }
            else {
                dispatch(setError(RES_MSG, GRADE_LIST_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, GRADE_LIST_FAILED, dispatch)
        }
    };
;
export const fetchAllGrades = (payload, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(GRADE_LIST_REQUEST))
        try {
            // const { data } = await Axios.get(`/user/list,?page=${1}`)
            // const { data } = await Axios.get(`/auth/fetch_admins`, { params })
            // const { data } = await Axios.get(`/auth/fetch_admins`)
            const response = await Axios.post(`/product/grades`, payload)
            const { data } = response
            const total = data?.data?.length
            if (response.status === 200) {
                dispatch({
                    type: GRADE_LIST_SUCCESS,
                    data: data.data,
                    total: total,
                    message: data.message,
                    perPage: PER_PAGE_DEFAULT,
                });
                if (callbackFun) callbackFun(data.data);
            }
            else {
                dispatch(setError(RES_MSG, GRADE_LIST_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, GRADE_LIST_FAILED, dispatch)
        }
    };
;
export const updateGrade = (payload, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(GRADE_UPDATE_REQUEST))
        try {
            const response = await Axios.post(`/product/updateGrade`, payload)
            const { data } = response
            if (response.status === 200) {
                dispatch(setSuccess(data.message, GRADE_UPDATE_SUCCESS))
                dispatch({
                    type: GRADE_UPDATE_SUCCESS,
                    data: data.data,
                    message: data.message,
                });
                if (callbackFun) callbackFun(data.data);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, GRADE_UPDATE_FAILED));
            }
        } catch (error) {
            dispatch(setError(error?.data?.message, GRADE_CREATE_FAILED));
        }
    };


export const createGrade = (payload, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(GRADE_CREATE_REQUEST))
        try {
            const response = await Axios.post(`/product/addGrade`, payload)
            const { data } = response
            if (response.status === 200) {
                dispatch(setSuccess(data.message, GRADE_CREATE_SUCCESS))
                dispatch({
                    type: GRADE_CREATE_SUCCESS,
                    data: data.data,
                    message: data.message,
                });
                if (callbackFun) callbackFun(response);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, GRADE_CREATE_FAILED));
            }
        } catch (error) {
            dispatch(setError(error?.data?.message, GRADE_CREATE_FAILED));
            // ErrorCatch(error, GRADE_CREATE_FAILED, dispatch)
        }
    };
export const addGradeToMaterial = (payload, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(GRADE_CREATE_REQUEST))
        try {
            const response = await Axios.post(`/product/addGrade`, payload)
            const { data } = response
            if (response.status === 200) {
                dispatch(setSuccess(data.message, GRADE_CREATE_SUCCESS))
                dispatch({
                    type: GRADE_CREATE_SUCCESS,
                    data: data.data,
                    message: data.message,
                });
                if (callbackFun) callbackFun(data.data);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, GRADE_CREATE_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, GRADE_CREATE_FAILED, dispatch)
        }
    };
//take user id for delete api request
export const deleteGrade = (userId, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(GRADE_DELETE_REQUEST))
        try {
            const { data } = await Axios.delete(`/user/delete/${userId}`)
            if (data.statusCode === 200) {
                dispatch(setSuccess(data.responseMsg, GRADE_DELETE_SUCCESS))
                dispatch({
                    type: GRADE_DELETE_SUCCESS,
                    data: data.data.userDelete,
                    message: data.responseMsg,
                });
                if (callbackFun) callbackFun(data.data.userDelete);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, GRADE_DELETE_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, GRADE_DELETE_FAILED, dispatch)
        }
    };
;
export const deleteBulkGrade = (userIds, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(DELETE_BULK_GRADE_REQUEST))
        try {
            // const { data } = await Axios.delete(`/admin/roles/delete/${roleId}`)
            const data = {
                statusCode: 200,
                data: userIds,
                responseMsg: "Selected Users were deleted successfully!",
            }
            if (data.statusCode === 200) {
                dispatch(setSuccess(data.responseMsg, DELETE_BULK_GRADE_SUCCESS))
                dispatch({
                    type: DELETE_BULK_GRADE_SUCCESS,
                    data: data.data,
                    message: data.message,
                });
                if (callbackFun) callbackFun(data.data);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, DELETE_BULK_GRADE_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, DELETE_BULK_GRADE_FAILED, dispatch)
        }
    };
;

export const setGradeDetail = id => {
    return async dispatch => {
        try {
            const product = await Axios.get(`/product/fetchSingleProductMaterialGrade/${id}`)
            dispatch({
                type: SET_GRADE_DETAILS,
                data: product?.data.data,
                message: product?.message
            });
            dispatch(setSuccess(product?.message, GRADE_UPDATE_SUCCESS))
        } catch (error) {
            dispatch(setError(MSG_ERR_RESPONDING_SERVER, GRADE_UPDATE_FAILED));
        }
    };
};

export const updateProductGradeFnqDetail = (id, data) => {
    return async dispatch => {
        try {
            await Axios.post(`/product/updateFaqs-materialGrade/${id}`, data);
            dispatch(setSuccess("Updated"))
            return Promise.resolve();
        } catch (error) {
            dispatch(setError(MSG_ERR_RESPONDING_SERVER));
        }
    };
};

export const setProductGradeElmDetail = grade => {
    return async dispatch => {
        try {
            dispatch({
                type: SET_GRADEELM_DETAILS,
                data: grade,
            });
        } catch (error) {
            dispatch(setError(MSG_ERR_RESPONDING_SERVER, GRADE_UPDATE_FAILED));
        }
    };
};