import React, { useState, useEffect } from 'react';
import { Box, Switch, makeStyles, } from '@material-ui/core';
import { MoreHoriz, Edit, Link, Visibility } from '@material-ui/icons';

import CustomTable from 'components/CustomeTable';
import config from 'utils/Config.util';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import { getMaterialGradeList, updateMaterialGradeStatus } from 'redux/actions/Manage/MaterialGrade';
import { useDispatch, useSelector } from 'react-redux';
import ViewMaterialGradeDetails from './ViewMaterialGradeDetails';
import CmtCardMedia from '@coremat/CmtCard/CmtCardMedia';

const useStyles = makeStyles(theme => ({
  imageRoot: {
    // width: 150,
    // height: 150,
    width: 100,
    height: 100,
    objectFit: 'cover',
    objectPosition: 'center',
    padding: 0,
  },
}));

const MaterialGrade = ({ history }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const materialGradeData = useSelector(state => state?.MaterialGrade)
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [openViewMaterialGradeDetails, setOpenViewMaterialGradeDetails] = useState(false)
  const [id, setId] = useState('')
  const [flag, setFlag] = useState(false)

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'logo',
      numeric: false,
      disablePadding: false,
      label: 'Image',
      sort: false,
      render: elm => (
        <Box>
          <CmtCardMedia className={classes.imageRoot} image={elm?.logo} />
        </Box>
      ),
    },
    {
      id: 'metaTitle',
      numeric: false,
      disablePadding: false,
      label: 'Title',
      sort: false,
    },
    {
      id: 'active',
      numeric: false,
      disablePadding: false,
      sort: false,
      label: 'Status',
      render: elm => (
        <Switch
          checked={elm.isActive === true ? true : false}
          onChange={() => handleChangeStatusSwitch(elm)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      ),
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Actions',
      sort: false,
      render: elm => (
        <>
          {
            <CmtDropdownMenu
              items={userActions}
              onItemClick={menu => onUserMenuClick(menu, elm)}
              TriggerComponent={<MoreHoriz />}
            />
          }
        </>
      ),
    },
  ];

  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const getUserActions = () => {
    const actions = [];
    actions.push({ action: 'url', label: 'View Live', icon: <Link /> });
    actions.push({ action: 'view', label: 'View', icon: <Visibility /> });
    actions.push({ action: 'edit', label: 'Edit', icon: <Edit /> });
    return actions;
  };

  const handleChangeStatusSwitch = elm => {
    let status;
    if (elm.isActive === true) status = false;
    else status = true;
    const data = { isActive: status };
    dispatch(updateMaterialGradeStatus(elm?._id, data)).then(res => {
      if (res?.status === 200) {
        setFlag(!flag)
      }
    });
  };

  const userActions = getUserActions();


  const onUserMenuClick = (menu, elm) => {
    if (menu.action === 'edit') {
      history.push(`/edit-manage-material-grade/${elm?._id}`)
    }
    else if (menu.action === 'view') {
      setId(elm?._id)
      setOpenViewMaterialGradeDetails(true)
    }
    else if (menu.action === 'url') {
      // window.open(`https://www.thepipingmart.com/grade/${elm?.url}`);
      window.open( config.REACT_APP_API_BASE_URL === 'https://api.pipingmart.co.in' ? `http://pipingmart.co.in/grade/${elm?.url}` : `https://www.thepipingmart.com/grade/${elm?.url}`)
    }
  };

  useEffect(() => {
    let value = { page: page + 1, perPage: 10 }
    if (searchTerm) {
      value.search = searchTerm
    }
    dispatch(getMaterialGradeList(value))
  }, [flag, page])

  return (
    <>
      <CustomTable
        headCells={headCells}
        row={materialGradeData?.materialGrades || []}    // this is wrong data confirm with console
        count={materialGradeData?.total || 0}  // this is wrong data confirm with console
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        rowsPerPage={materialGradeData?.perPage}   // this is wrong data confirm with console
        onPageChange={handlePageChange}
        page={page}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleSearch={text => setSearchTerm(text)}
        handleSearchSubmit={() => {
          setPage(0)
          setFlag(!flag)
        }}
        addButtonText={'Add Material Grade'}
        handleAddButton={() => history.push('/add-manage-material-grade')}
        isDynamic={true}
      />
      {openViewMaterialGradeDetails && (
        <ViewMaterialGradeDetails
          open={openViewMaterialGradeDetails}
          onCloseDialog={() => setOpenViewMaterialGradeDetails(false)}
          id={id}
        />
      )}
    </>
  );
};

export default MaterialGrade;
