import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Button, List, ListItem, withWidth } from '@material-ui/core';
import { MoveToInbox, Send, Delete, Archive, Edit, WatchLater, Report, Drafts } from '@material-ui/icons';

import useStyles from '../index.style';
import ItemCell from './ItemCell';
import AddLabel from './AddLabel';
import LabelCell from './LabelCell';
import { getAppSidebarHeight } from '@jumbo/constants/AppConstants';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import CmtList from '@coremat/CmtList';
// import { filterOptions, folderList } from '@fake-db/apps/mail';
// import { filterOptions, folderList } from '../../../../@fake-db/apps/mail';

import {
  getConnectionsList,
  getLabelsList,
  getMailCounts,
  onNullifyMail,
  setFilterType,
} from 'redux/actions/MailApp';
import { isTemplateTail } from 'typescript';

const MailSidebar = ({ width, onOpenComposeDialog, onClickSendMail ,  labelsList, openComposeDialog}) => {
  const { showFooter } = useContext(AppContext);
  const {
    isSideBarCollapsed,
    connectionsList,
    filterType,
    mailsList,
    selectedMail,
    counter,
   
  } = useSelector(({ mailApp }) => mailApp);
  
  const folderList = [
    { id: 1, name: 'Inbox', slug: 'inbox', icon: <MoveToInbox /> },
    { id: 2, name: 'Sent', slug: 'sent', icon: <Send /> },
    { id: 3, name: 'Approved', slug: 'approve', icon: <Drafts /> },
    { id: 4, name: 'Scheduled', slug: 'scheduled', icon: <WatchLater /> },
    { id: 5, name: 'Disapproved', slug: 'disapprove', icon: <Report /> },
    { id: 6, name: 'Trash', slug: 'trash', icon: <Delete /> },
    { id: 7, name: 'Archived', slug: 'archive', icon: <Archive /> },
    { id: 7, name: 'Ignorned', slug: 'ignored', icon: <Archive /> },
  ];

  // const labelsList = [
  //   { id: 1, name: 'Supplier', slug: 'supplier_email', color: '#FF8C00' },
  //   { id: 2, name: 'Enquiry', slug: 'enquiry', color: '#00C4B4' },
  // ];

  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getLabelsList());
  //   dispatch(getConnectionsList());
  // }, [dispatch]);

  // useEffect(() => {
  //   dispatch(getMailCounts());
  // }, [dispatch, mailsList, selectedMail]);

  const onChangeFolder = folder => {
    dispatch(onNullifyMail());
    dispatch(
      setFilterType({
        selectedFolder: folder,
        selectedFilter: '',
        selectedLabel: '',
        searchText: '',
        page: 0,
        perPage:filterType?.perPage,
      }),
    );
  };

  const onChangeLabel = item => {
    dispatch(
      setFilterType({
        selectedFolder: '',
        selectedFilter: '',
        selectedLabel: item?.slug,
        searchText: '',
        page: 0,
        perPage:filterType?.perPage,
      }),
    );
  };

  const classes = useStyles({
    isCollapsed: isSideBarCollapsed,
    height: getAppSidebarHeight(width, showFooter),
  });

  return (
    <Box className={classes.inBuildAppSidebar}>
      <Box className={classes.inBuildAppSidebarHeader}>
        <Button
          className={classes.addTaskBtn}
          variant="contained"
          color="primary"
          disabled={openComposeDialog}
          onClick={() =>{
            localStorage.setItem("compose", true)
            onOpenComposeDialog()
            }}
        >
          <Edit />
          <Box component="span" className="add-task-btn-text">
            Compose
          </Box>
        </Button>
      </Box>
      <PerfectScrollbar className={classes.perfectScrollbarMailSidebar}>
        <List component="nav" className={classes.appNav}>
          <CmtList
            data={folderList}
            renderRow={(item, index) => (
              <ItemCell
                key={index}
                item={item}
                counter={counter ? counter.folders[item?.slug] : 0}
                classes={classes}
                selectedItem={filterType?.selectedFolder}
                onChange={onChangeFolder}
              />
            )}
          />

          <ListItem component="div" className={classes.appNavHeaderItem}>
            <Box component="span" className={classes.appNavHeaderItemText}>
              Labels
            </Box>
          </ListItem>
          <CmtList
            data={labelsList}
            renderRow={(item, index) => (
              <LabelCell
                key={index}
                item={item}
                classes={classes}
                selectedItem={filterType?.selectedLabel}
                onChange={() => onChangeLabel(item)}
                isVisibleMore={false}
              />
            )}
          />
        </List>
      </PerfectScrollbar>
    </Box>
  );
};

export default withWidth()(MailSidebar);

MailSidebar.prototype = {
  onOpenComposeDialog: PropTypes.func,
};
