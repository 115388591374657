//-------------vendors constants------------------
export const VENDORS_LIST_REQUEST = "VENDORS_LIST_REQUEST";
export const VENDORS_LIST_SUCCESS = "VENDORS_LIST_SUCCESS";
export const VENDORS_LIST_FAILED = "VENDORS_LIST_FAILED";
// export const DELETE_BULK_USERS_REQUEST = "DELETE_BULK_USERS_REQUEST";
// export const DELETE_BULK_USERS_SUCCESS = "DELETE_BULK_USERS_SUCCESS";
// export const DELETE_BULK_USERS_FAILED = "DELETE_BULK_USERS_FAILED";
export const SET_VENDOR_DETAILS = "SET_VENDOR_DETAILS";
export const VENDOR_CREATE_REQUEST = "VENDOR_CREATE_REQUEST";
export const VENDOR_CREATE_SUCCESS = "VENDOR_CREATE_SUCCESS";
export const VENDOR_CREATE_FAILED = "VENDOR_CREATE_FAILED";
export const VENDOR_UPDATE_REQUEST = "VENDOR_UPDATE_REQUEST";
export const VENDOR_UPDATE_SUCCESS = "VENDOR_UPDATE_SUCCESS";
export const VENDOR_UPDATE_FAILED = "VENDOR_UPDATE_FAILED";
export const VENDOR_DELETE_REQUEST = "VENDOR_DELETE_REQUEST";
export const VENDOR_DELETE_SUCCESS = "VENDOR_DELETE_SUCCESS";
export const VENDOR_DELETE_FAILED = "VENDOR_DELETE_FAILED";