export const RFQ_LIST_REQUEST = "RFQ_LIST_REQUEST";
export const RFQ_LIST_SUCCESS = "RFQ_LIST_SUCCESS";
export const RFQ_LIST_FAILED = "RFQ_LIST_FAILED";

export const RFQ_UPDATE_REQUEST = "RFQ_UPDATE_REQUEST";
export const RFQ_UPDATE_SUCCESS = "RFQ_UPDATE_SUCCESS";
export const RFQ_UPDATE_FAILED = "RFQ_UPDATE_FAILED";

export const RFQ_ADD_REQUEST = "RFQ_ADD_REQUEST";
export const RFQ_ADD_SUCCESS = "RFQ_ADD_SUCCESS";
export const RFQ_ADD_FAILED = "RFQ_ADD_FAILED";

