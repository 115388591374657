import Axios from "axios";
import { MSG_ERR_RESPONDING_SERVER, PER_PAGE_DEFAULT } from "redux/actions/Common/Constant";
// import {PER_PAGE_DEFAULT} from "./Constant";
import {ErrorCatch} from "utils/ErrorCatch";
import {setError} from "../../Common/ErrorAction";
import {setLoading} from "../../Common/LoadingAction";
import {setSuccess} from "../../Common/SuccessAction";
import {
  SUBPRODUCT_LIST_REQUEST,
  SUBPRODUCT_LIST_SUCCESS,
  SUBPRODUCT_LIST_FAILED,
  SUBPRODUCT_UPDATE_SUCCESS,
  SUBPRODUCT_UPDATE_REQUEST,
  SUBPRODUCT_UPDATE_FAILED,
  SUBPRODUCT_CREATE_REQUEST,
  SUBPRODUCT_CREATE_SUCCESS,
  SUBPRODUCT_CREATE_FAILED,
//   SUBPRODUCT_MATERIAL_CREATE_REQUEST,
//   SUBPRODUCT_MATERIAL_CREATE_SUCCESS,
//   SUBPRODUCT_MATERIAL_CREATE_FAILED,
  SUBPRODUCT_DELETE_REQUEST,
  SUBPRODUCT_DELETE_SUCCESS,
  SUBPRODUCT_DELETE_FAILED,
  DELETE_BULK_SUBPRODUCT_FAILED,
  DELETE_BULK_SUBPRODUCT_SUCCESS,
  DELETE_BULK_SUBPRODUCT_REQUEST,
  SET_SUBPRODUCT_DETAILS,
  SET_SUBPRODUCTELM_DETAILS,

} from "./Constant";

let RES_MSG = MSG_ERR_RESPONDING_SERVER;

// export const getAllSubProducts = id => async dispatch => {
//   dispatch(setLoading(SUBPRODUCT_LIST_REQUEST));
//   try {
//     const response = await Axios.get(
//       `product/fetchSubProduct/${id}`
//     ).then(res =>
//       dispatch({
//         type: SUBPRODUCT_LIST_SUCCESS,
//         payload: res.data
//       })
//     );
//   } catch (error) {
//     ErrorCatch(error, SUBPRODUCT_LIST_FAILED);
//   }
// };

export const getAllSubProducts = (id,params, callbackFun, isDownload = false) =>
    async (dispatch) => {
        // let id=1;
        dispatch(setLoading(SUBPRODUCT_LIST_REQUEST))
        // console.log(params)
        try {
            // { params: { page, search, type, field, sort } }
            const response = await Axios.get(`/product/fetchSubProduct/${id}`, { params: { search:params?.search } })
            // const response = await Axios.get(`/product/fetchSubProduct/${id}?search=${params.search}`)
            const { data } = response
            const total = data?.data?.length
            if (response.status === 200) {
                if (isDownload)
                    dispatch(setSuccess(null, SUBPRODUCT_LIST_SUCCESS))
                else {
                    dispatch({
                        type: SUBPRODUCT_LIST_SUCCESS,
                        data: data.data,
                        total: total,
                        message: data.message,
                        perPage: PER_PAGE_DEFAULT,
                    });
                }
                if (callbackFun) callbackFun(data.data.userList);
            }
            else {
                dispatch(setError(RES_MSG, SUBPRODUCT_LIST_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, SUBPRODUCT_LIST_FAILED, dispatch)
        }
    };

export const updateSubProduct = (payload, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(SUBPRODUCT_UPDATE_REQUEST))
        try {
            const response = await Axios.post(`/product/updatesubproductdetails`, payload)
            const { data } = response
            if (response.status === 200) {
                dispatch(setSuccess(data.message, SUBPRODUCT_UPDATE_SUCCESS))
                dispatch({
                    type: SUBPRODUCT_UPDATE_SUCCESS,
                    data: data.data,
                    message: data.message,
                });
                if (callbackFun) callbackFun(data.data);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, SUBPRODUCT_UPDATE_FAILED));
            }
        } catch (error) {
            dispatch(setError(error?.data?.message, SUBPRODUCT_CREATE_FAILED))
        }
    };

export const setSubProductDetail = id => {
    return async (dispatch) => {
        try {
            const response = await Axios.get(`/product/fetchSingleSubProduct/${id}`)
            dispatch({
                type: SET_SUBPRODUCT_DETAILS,
                data: response?.data?.data,
            });
        } catch (error) {
            dispatch(setError(error?.data?.message, SUBPRODUCT_CREATE_FAILED))
        }
    };
};

export const setSubProductElmDetail = user => {
    return dispatch => {
        dispatch({
            type: SET_SUBPRODUCTELM_DETAILS,
            data: user,
        });
    };
};

export const createSubProduct = (payload, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(SUBPRODUCT_CREATE_REQUEST))
        try {
            const response = await Axios.post(`/product/updateSubProduct`, payload)
            const { data } = response
            if (response.status === 200) {
                dispatch(setSuccess(data.message, SUBPRODUCT_CREATE_SUCCESS))
                dispatch({
                    type: SUBPRODUCT_CREATE_SUCCESS,
                    data: data.data,
                    message: data.message,
                });
                if (callbackFun) callbackFun(data.data);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, SUBPRODUCT_CREATE_FAILED));
            }
        } catch (error) {
            dispatch(setError(error?.data?.message, SUBPRODUCT_CREATE_FAILED))
        }
    };
// export const addMaterialToProduct = (payload, callbackFun) =>
//     async (dispatch) => {
//         dispatch(setLoading(SUBPRODUCT_MATERIAL_CREATE_REQUEST))
//         try {
//             const response = await Axios.post(`/product/addMaterialsToProduct`, payload)
//             const { data } = response
//             console.log("😃 ~ file: Users.js:164 ~ data", data)
//             if (response.status === 200) {
//                 dispatch(setSuccess(data.message, SUBPRODUCT_MATERIAL_CREATE_SUCCESS))
//                 dispatch({
//                     type: SUBPRODUCT_MATERIAL_CREATE_SUCCESS,
//                     data: data.data,
//                     message: data.message,
//                 });
//                 if (callbackFun) callbackFun(data.data);
//             }
//             else {
//                 dispatch(setError(MSG_ERR_RESPONDING_SERVER, SUBPRODUCT_MATERIAL_CREATE_FAILED));
//             }
//         } catch (error) {
//             ErrorCatch(error, SUBPRODUCT_MATERIAL_CREATE_FAILED, dispatch)
//         }
//     };
//take user id for delete api request

export const deleteSubProduct = (userId, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(SUBPRODUCT_DELETE_REQUEST))
        try {
            const { data } = await Axios.delete(`/user/delete/${userId}`)
            if (data.statusCode === 200) {
                dispatch(setSuccess(data.responseMsg, SUBPRODUCT_DELETE_SUCCESS))
                dispatch({
                    type: SUBPRODUCT_DELETE_SUCCESS,
                    data: data.data.userDelete,
                    message: data.responseMsg,
                });
                if (callbackFun) callbackFun(data.data.userDelete);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, SUBPRODUCT_DELETE_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, SUBPRODUCT_DELETE_FAILED, dispatch)
        }
    };
;
export const deleteBulkSubProduct = (userIds, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(DELETE_BULK_SUBPRODUCT_REQUEST))
        try {
            // const { data } = await Axios.delete(`/admin/roles/delete/${roleId}`)
            const data = {
                statusCode: 200,
                data: userIds,
                responseMsg: "Selected Users were deleted successfully!",
            }
            if (data.statusCode === 200) {
                dispatch(setSuccess(data.responseMsg, DELETE_BULK_SUBPRODUCT_SUCCESS))
                dispatch({
                    type: DELETE_BULK_SUBPRODUCT_SUCCESS,
                    data: data.data,
                    message: data.message,
                });
                if (callbackFun) callbackFun(data.data);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, DELETE_BULK_SUBPRODUCT_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, DELETE_BULK_SUBPRODUCT_FAILED, dispatch)
        }
    };
;

export const updateSubproductFaqDetail = (id, data) => {
    return async dispatch => {
        try {
            await Axios.post(`/product/updateFaqs-subproduct/${id}`, data);
            dispatch(setSuccess("Updated"))
            return Promise.resolve();
        } catch (error) {
            dispatch(setError(MSG_ERR_RESPONDING_SERVER));
        }
    };
};