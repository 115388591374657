import Axios from "axios";
import { MSG_ERR_RESPONDING_SERVER, PER_PAGE_DEFAULT } from 'redux/actions/Common/Constant';
import { ErrorCatch } from "utils/ErrorCatch";
import { setError } from "../../Common/ErrorAction";
import { setLoading } from "../../Common/LoadingAction";
import { setSuccess } from "../../Common/SuccessAction";
import { DELETE_BULK_PRODUCT_FAILED, DELETE_BULK_PRODUCT_REQUEST, DELETE_BULK_PRODUCT_SUCCESS, PRODUCT_CREATE_FAILED, PRODUCT_CREATE_REQUEST, PRODUCT_CREATE_SUCCESS, PRODUCT_DELETE_FAILED, PRODUCT_DELETE_REQUEST, PRODUCT_DELETE_SUCCESS, PRODUCT_LIST_FAILED, PRODUCT_LIST_REQUEST, PRODUCT_LIST_SUCCESS, PRODUCT_MATERIAL_CREATE_FAILED, PRODUCT_MATERIAL_CREATE_REQUEST, PRODUCT_MATERIAL_CREATE_SUCCESS, PRODUCT_UPDATE_FAILED, PRODUCT_UPDATE_REQUEST, PRODUCT_UPDATE_SUCCESS, SET_PRODUCTELM_DETAILS, SET_PRODUCT_DETAILS, UPDATE_PRODUCT_DETAILS } from "./Constant";

let RES_MSG = MSG_ERR_RESPONDING_SERVER

// export const getAllUsers = (filterOptions = [],params, searchTerm = '', callbackFun) =>
// export const getAllProducts = (params, callbackFun, isDownload = false) =>
//     async (dispatch) => {
//         dispatch(setLoading(PRODUCT_LIST_REQUEST))
//         try {
//             // const { data } = await Axios.get(`/user/list,?page=${1}`)
//             // const { data } = await Axios.get(`/auth/fetch_admins`, { params })
//             // const { data } = await Axios.get(`/auth/fetch_admins`)
//             const response = await Axios.post(`/product/products`, { params })
//             const { data } = response
//             const total = data?.data?.length
//             if (response.status === 200) {
//                 if (isDownload)
//                     dispatch(setSuccess(null, PRODUCT_LIST_SUCCESS))
//                 else {
//                     dispatch({
//                         type: PRODUCT_LIST_SUCCESS,
//                         data: data.data,
//                         total: total,
//                         message: data.message,
//                         perPage: PER_PAGE_DEFAULT,
//                     });
//                 }
//                 if (callbackFun) callbackFun(data.data.userList);

//             }
//             else {
//                 dispatch(setError(RES_MSG, PRODUCT_LIST_FAILED));
//             }
//         } catch (error) {
//             ErrorCatch(error, PRODUCT_LIST_FAILED, dispatch)
//         }
//     };
// ;

export const getAllProducts = params => {
    return dispatch => {
        dispatch(setLoading(PRODUCT_LIST_REQUEST));
        return Axios.post('/product/products', { params })
            .then(response => {
                if (response?.status === 200) {
                    const { data } = response
                    const total = data?.data?.length
                    dispatch({
                        type: PRODUCT_LIST_SUCCESS,
                        data: data.data,
                        total: total,
                        message: data.message,
                        perPage: PER_PAGE_DEFAULT,
                    });
                    // dispatch(setSuccess(data.responseMsg, MATERIAL_GRADE_LIST_SUCCESS))
                    return Promise.resolve(response);
                }
            })
            .catch(err => {
                dispatch(setError(err?.data?.error, PRODUCT_LIST_FAILED));
                return Promise.resolve(err);
            });
    };
};
export const getProductsDropdown = params => {
    return dispatch => {
        dispatch(setLoading(PRODUCT_LIST_REQUEST));
        return Axios.post('/product/products-dropdown', { params })
            .then(response => {
                if (response?.status === 200) {
                    const { data } = response
                    const total = data?.data?.length
                    dispatch({
                        type: PRODUCT_LIST_SUCCESS,
                        data: data.data,
                        message: data.message,
                        perPage: PER_PAGE_DEFAULT,
                    });
                    // dispatch(setSuccess(data.responseMsg, MATERIAL_GRADE_LIST_SUCCESS))
                    return Promise.resolve(response);
                }
            })
            .catch(err => {
                dispatch(setError(err?.data?.error, PRODUCT_LIST_FAILED));
                return Promise.resolve(err);
            });
    };
};


export const getProductList = ({ search }) => {
    return dispatch => {
        dispatch(setLoading(PRODUCT_LIST_REQUEST))
        return Axios
            .post('/product/products', { params: { search, } })
            .then(response => {
                const { data } = response
                const total = data?.data?.length
                if (response?.status === 200) {
                    dispatch({
                        type: PRODUCT_LIST_SUCCESS,
                        data: data.data,
                        total: total,
                        message: data.message,
                        perPage: PER_PAGE_DEFAULT,
                    });
                    return Promise.resolve(response);
                }
            })
            .catch(err => {
                ErrorCatch(err, PRODUCT_LIST_FAILED, dispatch)
                return Promise.resolve(err);
            });
    };
};
export const updateProduct = (payload, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(PRODUCT_UPDATE_REQUEST))
        try {
            const response = await Axios.post(`/product/updateProduct`, payload)
            const { data } = response
            if (response.status === 200) {
                dispatch(setSuccess(data.message, PRODUCT_UPDATE_SUCCESS))
                dispatch({
                    type: PRODUCT_UPDATE_SUCCESS,
                    data: data.data,
                    message: data.message,
                });
                if (callbackFun) callbackFun(data.data);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, PRODUCT_UPDATE_FAILED));
            }
        } catch (error) {
            dispatch(setError(error?.data?.message, PRODUCT_CREATE_FAILED));
        }
    };

export const createProduct = (payload, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(PRODUCT_CREATE_REQUEST))
        try {
            const response = await Axios.post(`/product/addProduct`, payload)
            const { data } = response
            if (response.status === 200) {
                dispatch(setSuccess(data.message, PRODUCT_CREATE_SUCCESS))
                dispatch({
                    type: PRODUCT_CREATE_SUCCESS,
                    data: data.data,
                    message: data.message,
                });
                if (callbackFun) callbackFun(data.data);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, PRODUCT_CREATE_FAILED));
            }
        } catch (error) {
            dispatch(setError(error?.data?.message, PRODUCT_CREATE_FAILED));
        }
    };
export const addMaterialToProduct = (payload, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(PRODUCT_MATERIAL_CREATE_REQUEST))
        try {
            const response = await Axios.post(`/product/addMaterialsToProduct`, payload)
            const { data } = response
            if (response.status === 200) {
                dispatch(setSuccess(data.message, PRODUCT_MATERIAL_CREATE_SUCCESS))
                dispatch({
                    type: PRODUCT_MATERIAL_CREATE_SUCCESS,
                    data: data.data,
                    message: data.message,
                });
                if (callbackFun) callbackFun(data.data);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, PRODUCT_MATERIAL_CREATE_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, PRODUCT_MATERIAL_CREATE_FAILED, dispatch)
        }
    };
//take user id for delete api request
export const deleteProduct = (userId, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(PRODUCT_DELETE_REQUEST))
        try {
            const { data } = await Axios.delete(`/user/delete/${userId}`)
            if (data.statusCode === 200) {
                dispatch(setSuccess(data.responseMsg, PRODUCT_DELETE_SUCCESS))
                dispatch({
                    type: PRODUCT_DELETE_SUCCESS,
                    data: data.data.userDelete,
                    message: data.responseMsg,
                });
                if (callbackFun) callbackFun(data.data.userDelete);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, PRODUCT_DELETE_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, PRODUCT_DELETE_FAILED, dispatch)
        }
    };
;
export const deleteBulkProduct = (userIds, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(DELETE_BULK_PRODUCT_REQUEST))
        try {
            // const { data } = await Axios.delete(`/admin/roles/delete/${roleId}`)
            const data = {
                statusCode: 200,
                data: userIds,
                responseMsg: "Selected Users were deleted successfully!",
            }
            if (data.statusCode === 200) {
                dispatch(setSuccess(data.responseMsg, DELETE_BULK_PRODUCT_SUCCESS))
                dispatch({
                    type: DELETE_BULK_PRODUCT_SUCCESS,
                    data: data.data,
                    message: data.message,
                });
                if (callbackFun) callbackFun(data.data);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, DELETE_BULK_PRODUCT_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, DELETE_BULK_PRODUCT_FAILED, dispatch)
        }
    };
;

export const updateProductDetail = (id, data) => {
    return async dispatch => {
        try {
            await Axios.post(`/product/updateFaqs/${id}`, data);
            dispatch(setSuccess("Updated"))
            return Promise.resolve();
        } catch (error) {
            dispatch(setError(MSG_ERR_RESPONDING_SERVER));
        }
    };
};
export const setProductDetail = id => {
    return async dispatch => {
        try {
            const product = await Axios.get(`/product/fetchSingleProduct/${id}`)
            dispatch({
                type: SET_PRODUCT_DETAILS,
                data: product?.data.data,
                message: product?.message
            });
        } catch (error) {
            dispatch(setError(MSG_ERR_RESPONDING_SERVER));
        }
    };
};
export const setProductElmDetail = prod => {
    return async dispatch => {
        try {
            dispatch({
                type: SET_PRODUCTELM_DETAILS,
                data: prod,
            });
        } catch (error) {
            dispatch(setError(MSG_ERR_RESPONDING_SERVER, PRODUCT_UPDATE_FAILED));
        }
    };
};