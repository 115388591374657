import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuList,
  MenuItem,
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import GridContainer from '@jumbo/components/GridContainer';

import {
  fetchCategoryBySearch,
  fetchEmailList,
  fetchSupplierByPlan,
  fetchSUpplierByPlan,
  setEmailListPopUp,
  UpgradeEmailList,
} from 'redux/actions/Manage/Emails/Emails';
import { getAllMaterials } from 'redux/actions/Manage/Materials/Materials';
import { getAllProducts } from 'redux/actions/Manage/Products/Products';
import { fetchAllGrades } from 'redux/actions/Manage/ProductMaterialGrades';
import CmtSearch from '@coremat/CmtSearch';
import { Search } from '@material-ui/icons';
import CustomTable from 'components/CustomeTable';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  noDataFound: {
    display: 'flex',
    justifyContent: 'center',
    background: '#EBECF0',
    height: '40px',
    alignItems: 'center',
    borderRadius: '4px',
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const UpdateEmailLimit = ({ open, onCloseDialog, data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [flag, setFlag] = useState(false);

  let updatedLimit = [];

  const handleChange = (evt, selectedData) => {
    if (!updatedLimit.length) {
      updatedLimit.push({
        email: selectedData?.email,
        userEmailLimit: +evt.target.value,
      });
    } else {
      for (let n = 0; n < updatedLimit.length; n++) {
        if (updatedLimit[n].email === selectedData?.email) {
          if (updatedLimit[n].email.indexOf(selectedData?.email) !== -1) {
            const selectedIndex = updatedLimit.findIndex(
              item => item?.email === selectedData.email,
            );
            updatedLimit.splice(selectedIndex, 1, {
              email: selectedData?.email,
              userEmailLimit: +evt.target.value,
            });
            break;
          } else {
            updatedLimit.push({
              email: selectedData?.email,
              userEmailLimit: +evt.target.value,
            });
            break;
          }
        } else {
          const existObj =
            updatedLimit &&
            updatedLimit.some(ite => ite?.email === selectedData?.email);
          if (!existObj) {
            updatedLimit.push({
              email: selectedData?.email,
              userEmailLimit: +evt.target.value,
            });
          }
        }
      }
    }
  };

  const handleSubmit = async () => {
    const response = await dispatch(UpgradeEmailList(updatedLimit));
    if (response?.status === 200) {
      setFlag(true);
      onCloseDialog();
    } else {
      setFlag(false);
    }
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        <div className={classes?.dialogHeader}>
          <Typography variant="h6" gutterBottom>
            {'Add Suppliers'}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={flag}
          >
            Submit
          </Button>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        {data?.length &&
          data.map((item, index) => (
            <List ke={index}>
              <ListItem disablePadding>
                <GridContainer>
                  <Grid item md={8}>
                    <ListItemText primary={item?.email} />
                  </Grid>
                  <Grid item md={4}>
                    <AppTextInput
                      fullWidth
                      name="name"
                      type="number"
                      variant="outlined"
                      label="Limit"
                      // value={name}
                      onChange={evt => handleChange(evt, item)}
                    />
                  </Grid>
                </GridContainer>
              </ListItem>
            </List>
          ))}
      </DialogContent>
    </Dialog>
  );
};

export default UpdateEmailLimit;

UpdateEmailLimit.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
