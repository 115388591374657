// loaderReducer.js

import { HIDE_LOADER } from "redux/actions/Common/Constant";

const initState = {
    loading: false,
    isOpen: false
};

export function LoadingReducer(state = initState, action) {
    const { loading } = action;

    if (loading) {
        return {
            loading: action.loading,
            action: action.action,
            isOpen: true
        }
    }
    else if (action.type === HIDE_LOADER || !loading) {
        return initState
    }

    return state;
}