import Axios from 'axios';
import {
  MSG_ERR_RESPONDING_SERVER,
  PER_PAGE_DEFAULT,
} from 'redux/actions/Common/Constant';
import { ErrorCatch } from 'utils/ErrorCatch';
import { setError } from '../../Common/ErrorAction';
import { hideLoading, setLoading } from '../../Common/LoadingAction';
import { setSuccess } from '../../Common/SuccessAction';
import {
  DELETE_BULK_SUPPLIER_FAILED,
  DELETE_BULK_SUPPLIER_REQUEST,
  DELETE_BULK_SUPPLIER_SUCCESS,
  SUPPLIER_CREATE_FAILED,
  SUPPLIER_CREATE_REQUEST,
  SUPPLIER_CREATE_SUCCESS,
  SUPPLIER_DELETE_FAILED,
  SUPPLIER_DELETE_REQUEST,
  SUPPLIER_DELETE_SUCCESS,
  SUPPLIER_LIST_FAILED,
  SUPPLIER_LIST_REQUEST,
  SUPPLIER_LIST_SUCCESS,
  SUPPLIER_PRODUCT_CREATE_FAILED,
  SUPPLIER_PRODUCT_CREATE_REQUEST,
  SUPPLIER_PRODUCT_CREATE_SUCCESS,
  SUPPLIER_PRODUCTLIST_REQUEST,
  SUPPLIER_PRODUCTLIST_SUCCESS,
  SUPPLIER_PRODUCTLIST_FAILED,
  SUPPLIER_UPDATE_FAILED,
  SUPPLIER_UPDATE_REQUEST,
  SUPPLIER_UPDATE_SUCCESS,
  SET_SUPPLIER_DETAILS,
  CLEAR_SUPPLIER_DETAILS,
  PRODUCT_MATERIAL_GRADE_LIST_REQUEST,
  PRODUCT_MATERIAL_GRADE_LIST_SUCCESS,
  PRODUCT_MATERIAL_GRADE_LIST_FAILED,
  SUPPLIER_ADDPRODUCT_REQUEST,
  SUPPLIER_ADDPRODUCT_SUCCESS,
  SUPPLIER_ADDPRODUCT_FAILED,
  SUPPLIER_SETPRODUCT_REQUEST,
  SUPPLIER_SETPRODUCT_SUCCESS,
  SUPPLIER_SETPRODUCT_FAILED,
  SUPPLIER_EDITPRODUCT_REQUEST,
  SUPPLIER_EDITPRODUCT_SUCCESS,
  SUPPLIER_EDITPRODUCT_FAILED,
  DELETE_PRODUCTIMAGE_REQUEST,
  DELETE_PRODUCTIMAGE_SUCCESS,
  DELETE_PRODUCTIMAGE_FAILED,
  UPDATE_PRODUCTIMAGE_REQUEST,
  UPDATE_PRODUCTIMAGE_SUCCESS,
  SUPPLIER_SETPRODUCTLIST_REQUEST,
  SUPPLIER_SETPRODUCTLIST_SUCCESS,
  SUPPLIER_SETPRODUCTLIST_FAILED,
  // Fetch Grade
  FETCH_GRADE_REQUEST,
  FETCH_GRADE_SUCCESS,
  FETCH_GRADE_FAILED,
  FETCH_HOT_PRODUCT_REQUEST,
  FETCH_HOT_PRODUCT_FAILED,
  FETCH_HOT_PRODUCT_SUCCESS
} from './Constant';
import config from 'utils/Config.util';

let RES_MSG = MSG_ERR_RESPONDING_SERVER;

// export const getAllUsers = (filterOptions = [],params, searchTerm = '', callbackFun) =>
export const getAllSupplier = (
  filter,
  callbackFun,
  isDownload = false,
) => async dispatch => {
  dispatch(setLoading(SUPPLIER_LIST_REQUEST));
  try {
    // const { data } = await Axios.get(`/user/list,?page=${1}`)
    // const { data } = await Axios.get(`/auth/fetch_admins`, { params })
    // const { data } = await Axios.get(`/auth/fetch_admins`)
    const response = await Axios.post(`/supplier/fetch-all-supplier-admin`, {
      filter,
    });
    // console.log(filter)
    const { data } = response;
    // console.log(response);
    const total = data?.data?.length;
    // console.log(data);
    if (response.status === 200) {
      if (isDownload) dispatch(setSuccess(null, SUPPLIER_LIST_SUCCESS));
      else {
        dispatch({
          type: SUPPLIER_LIST_SUCCESS,
          data: data.data,
          //   emails:data.data.emails,
          total: total,
          message: data.message,
          perPage: PER_PAGE_DEFAULT,
        });
      }
      if (callbackFun) callbackFun(data.data);
    } else {
      dispatch(setError(RES_MSG, SUPPLIER_LIST_FAILED));
    }
  } catch (error) {
    ErrorCatch(error, SUPPLIER_LIST_FAILED, dispatch);
  }
};
export const updateSupplier = (payload, id, callbackFun) => async dispatch => {
  dispatch(setLoading(SUPPLIER_UPDATE_REQUEST));
  try {
    // console.log("Payload :", payload,id)
    const response = await Axios.post(`/supplier/updateSupplierdata/${id}`, payload);
    const { data } = response;
    if (response.status === 200) {
      dispatch(setSuccess(data.message, SUPPLIER_UPDATE_SUCCESS));
      dispatch({
        type: SUPPLIER_UPDATE_SUCCESS,
        data: data.data,
        message: data.message,
      });
      if (callbackFun) callbackFun(data.data);
    } else {
      dispatch(setError(MSG_ERR_RESPONDING_SERVER, SUPPLIER_UPDATE_FAILED));
    }
  } catch (error) {
    ErrorCatch(error, SUPPLIER_UPDATE_FAILED, dispatch);
  }
};

export const setSupplierDetail = user => async dispatch => {
  try {
    const response = await Axios.get(`/supplier/suppliers/${user}`);
    const { data } = response

    // console.log(data.data,"127")
    if (response.status === 200) {
      dispatch({
        type: SET_SUPPLIER_DETAILS,
        data: data.data,
      });
    }
  } catch (error) {
    console.log(error)
  }

};
export const clearSupplierDetail = () => {
  return dispatch => {
    // console.log(user)
    dispatch({
      type: CLEAR_SUPPLIER_DETAILS,

    });
  };
};
export const createSupplier = (payload, callbackFun) => async dispatch => {
  dispatch(setLoading(SUPPLIER_CREATE_REQUEST));
  try {
    // console.log(payload,"redux")
    const response = await Axios.post(`/supplier/addnewSupplier`, payload);
    const { data } = response;
    // console.log(response)
    dispatch(setSuccess(data.message, SUPPLIER_CREATE_SUCCESS));
    dispatch({
      type: SUPPLIER_CREATE_SUCCESS,
      data: data.data,
      message: data.message,
    });
    if (callbackFun) callbackFun(data.data);
  } catch (error) {
    console.log(error);
    // ErrorCatch(error.data.message, SUPPLIER_CREATE_FAILED, dispatch);
    dispatch(setError(error.data.message, SUPPLIER_CREATE_FAILED));
  }
};
export const deleteSupplier = supplierId => {

  return dispatch => {
    dispatch(setLoading(SUPPLIER_DELETE_REQUEST));
    return Axios.get(`supplier/deactivate-supplier/${supplierId}`)
      .then(response => {
        if (response?.status === 200) {
          dispatch(
            setSuccess(SUPPLIER_DELETE_SUCCESS),
          );
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        dispatch(
          setError(MSG_ERR_RESPONDING_SERVER, SUPPLIER_DELETE_FAILED),
        );
        return Promise.resolve(err);
      });
  };
};
export const deleteBulkProduct = (userIds, callbackFun) => async dispatch => {
  dispatch(setLoading(DELETE_BULK_SUPPLIER_REQUEST));
  try {
    // const { data } = await Axios.delete(`/admin/roles/delete/${roleId}`)
    const data = {
      statusCode: 200,
      data: userIds,
      responseMsg: 'Selected Users were deleted successfully!',
    };
    if (data.statusCode === 200) {
      dispatch(setSuccess(data.responseMsg, DELETE_BULK_SUPPLIER_SUCCESS));
      dispatch({
        type: DELETE_BULK_SUPPLIER_SUCCESS,
        data: data.data,
        message: data.message,
      });
      if (callbackFun) callbackFun(data.data);
    } else {
      dispatch(
        setError(MSG_ERR_RESPONDING_SERVER, DELETE_BULK_SUPPLIER_FAILED),
      );
    }
  } catch (error) {
    ErrorCatch(error, DELETE_BULK_SUPPLIER_FAILED, dispatch);
  }
};
export const fetchProductList = (
  supplierId,
  page = 1,
  callbackFun,
  isDownload = false,
) => async dispatch => {
  dispatch(setLoading(SUPPLIER_PRODUCTLIST_REQUEST));
  try {
    const response = await Axios.get(`/supplier/fetchpaginationadmin/${supplierId}/${page}`)
    const { data } = response;
    //  console.log(response)
    //  const total = data?.data.result?.length;
    //  console.log(data.data.total_products);
    if (response.status === 200) {
      if (isDownload)
        dispatch(setSuccess(null, SUPPLIER_PRODUCTLIST_SUCCESS));
      else {
        dispatch({
          type: SUPPLIER_PRODUCTLIST_SUCCESS,
          data: data.data.products,
          total: data.data.total_products,
          message: data.message,
          perPage: PER_PAGE_DEFAULT,
        });
      }
      if (callbackFun) callbackFun(data.data);
    } else {
      dispatch(setError(RES_MSG, SUPPLIER_PRODUCTLIST_FAILED));
    }
  } catch (error) {
    ErrorCatch(error, SUPPLIER_PRODUCTLIST_FAILED, dispatch);
  }
};

// export const addproducttosupplier = (userId, materialId, gradeId, productId,callbackFun,isDownload=false)=>async dispatch=>{
//    dispatch(setLoading(SUPPLIER_ADDPRODUCT_REQUEST));
//    try {
//      await Axios.post(`/supplier/addSupplier`, {
//        userId,
//        materialId,
//        gradeId,
//        productId
//      });
//      const { data } = response;
//     //  console.log(response);
//      if (response.status === 200) {
//        dispatch(setSuccess(data.message, SUPPLIER_ADDPRODUCT_SUCCESS));
//        dispatch({
//          type: SUPPLIER_ADDPRODUCT_SUCCESS,
//          data: data.data,
//          message: data.message,
//        });
//        if (callbackFun) callbackFun(data.data);
//      } else {
//        dispatch(
//          setError(MSG_ERR_RESPONDING_SERVER, SUPPLIER_ADDPRODUCT_FAILED),
//        );
//      }
//    } catch (error) {
//      ErrorCatch(error, SUPPLIER_ADDPRODUCT_FAILED, dispatch);
//    }
// };

export const addproducttosupplier = (payload) => {
  console.log(payload, "supplier Redux 282")
  return dispatch => {
    dispatch(setLoading(SUPPLIER_ADDPRODUCT_REQUEST));
    return Axios.post(`supplier/addSupplier`, payload)
      .then(response => {
        if (response?.status === 200) {
          dispatch(setSuccess(SUPPLIER_ADDPRODUCT_SUCCESS));
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        dispatch(
          setError(
            MSG_ERR_RESPONDING_SERVER,
            SUPPLIER_ADDPRODUCT_FAILED,
          ),
        );
        return Promise.resolve(err);
      });
  };
};


export const deleteproductfromsupplier = payload => {
  console.log(payload, 'supplier Redux 305');
  return dispatch => {
    dispatch(setLoading(SUPPLIER_EDITPRODUCT_REQUEST));
    return Axios.post(`supplier/deleteSupplierProduct`, payload)
      .then(response => {
        if (response?.status === 200) {
          dispatch(setSuccess(SUPPLIER_EDITPRODUCT_SUCCESS));
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        dispatch(
          setError(
            MSG_ERR_RESPONDING_SERVER,
            SUPPLIER_EDITPRODUCT_FAILED,
          ),
        );
        return Promise.resolve(err);
      });
  };
};

export const setSupplierProductDetail = (data, callbackFun,
  isDownload = false,) => async dispatch => {
    // console.log(data);
    dispatch({
      type: SUPPLIER_SETPRODUCT_SUCCESS,
      data: data,
    });
  };
export const fetchProductMaterialGradeList = (
  callbackFun,
  isDownload = false,
) => async dispatch => {
  dispatch(setLoading(PRODUCT_MATERIAL_GRADE_LIST_REQUEST));
  try {
    const response = await Axios.get(
      `/supplier/product-material-grade-list`
    );
    const { data } = response;
    //  console.log(data.gradeslist)
    //  const total = data?.data.result?.length;
    //  console.log(data.data.total_products);
    if (response.status === 200) {
      if (isDownload)
        dispatch(setSuccess(null, PRODUCT_MATERIAL_GRADE_LIST_SUCCESS));
      else {
        dispatch({
          type: PRODUCT_MATERIAL_GRADE_LIST_SUCCESS,
          data: data,
          //  total: data.data.total_products,
          message: data.message,
          perPage: PER_PAGE_DEFAULT,
        });
      }
      if (callbackFun) callbackFun(data.data);
    } else {
      dispatch(setError(RES_MSG, PRODUCT_MATERIAL_GRADE_LIST_FAILED));
    }
  } catch (error) {
    ErrorCatch(error, PRODUCT_MATERIAL_GRADE_LIST_FAILED, dispatch);
  }
};

export const deleteSupplierGalleryImage = (userId, index, category) => {
  return dispatch => {
    dispatch(setLoading(DELETE_PRODUCTIMAGE_REQUEST));
    return Axios.post(`product/user-gallery-delete`, {
      userId,
      index,
      category
    })
      .then(response => {
        if (response?.status === 200) {
          dispatch(setSuccess(DELETE_PRODUCTIMAGE_SUCCESS));
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        dispatch(
          setError(
            MSG_ERR_RESPONDING_SERVER,
            DELETE_PRODUCTIMAGE_FAILED,
          ),
        );
        return Promise.resolve(err);
      });
  };
};

export const updateSupplierGalleryImage = (userId, index, caption, file, category) => {
  return dispatch => {
    dispatch(setLoading(UPDATE_PRODUCTIMAGE_REQUEST));
    return Axios.post(`product/user-gallery-update`, {
      userId,
      index,
      category, caption, file
    })
      .then(response => {
        if (response?.status === 200) {
          dispatch(setSuccess(UPDATE_PRODUCTIMAGE_SUCCESS));
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        dispatch(
          setError(
            MSG_ERR_RESPONDING_SERVER,
            DELETE_PRODUCTIMAGE_FAILED,
          ),
        );
        return Promise.resolve(err);
      });
  };
};



export const ImageAdd = (payload) => async dispatch => {
  // dispatch(setLoading(SUPPLIER_ADDPRODUCT_REQUEST));
  try {
    const response = await Axios.post(`/product/user-gallery-add`, payload);
    const { data } = response;
    console.log(data);
    // if (response.status === 200) {
    //   dispatch(setSuccess(data.message, SUPPLIER_ADDPRODUCT_SUCCESS));
    //   dispatch({
    //     type: SUPPLIER_ADDPRODUCT_SUCCESS,
    //     data: data.data,
    //     message: data.message,
    //   });
    //   if (callbackFun) callbackFun(data.data);
    // } else {
    //   dispatch(
    //     setError(MSG_ERR_RESPONDING_SERVER, SUPPLIER_ADDPRODUCT_FAILED),
    //   );
    // }
    return Promise.resolve()
  } catch (error) {
    ErrorCatch(error, SUPPLIER_ADDPRODUCT_FAILED, dispatch);
  }
};

export const certificateAdd = (payload) => async dispatch => {
  // dispatch(setLoading(SUPPLIER_ADDPRODUCT_REQUEST));
  try {
    const response = await Axios.post(`/auth/addCertificate`, payload);
    const { data } = response;
    console.log(data, "########################################################################");
    // if (response.status === 200) {
    //   dispatch(setSuccess(data.message, SUPPLIER_ADDPRODUCT_SUCCESS));
    //   dispatch({
    //     type: SUPPLIER_ADDPRODUCT_SUCCESS,
    //     data: data.data,
    //     message: data.message,
    //   });
    //   if (callbackFun) callbackFun(data.data);
    // } else {
    //   dispatch(
    //     setError(MSG_ERR_RESPONDING_SERVER, SUPPLIER_ADDPRODUCT_FAILED),
    //   );
    // }
    return Promise.resolve();
  } catch (error) {
    ErrorCatch(error, SUPPLIER_ADDPRODUCT_FAILED, dispatch);
  }
};
export const certificateUpdate = (payload) => async dispatch => {
  // dispatch(setLoading(SUPPLIER_ADDPRODUCT_REQUEST));
  try {
    const response = await Axios.post(`/auth/updateCertificate`, payload);
    const { data } = response;
    console.log(data);
    // if (response.status === 200) {
    //   dispatch(setSuccess(data.message, SUPPLIER_ADDPRODUCT_SUCCESS));
    //   dispatch({
    //     type: SUPPLIER_ADDPRODUCT_SUCCESS,
    //     data: data.data,
    //     message: data.message,
    //   });
    //   if (callbackFun) callbackFun(data.data);
    // } else {
    //   dispatch(
    //     setError(MSG_ERR_RESPONDING_SERVER, SUPPLIER_ADDPRODUCT_FAILED),
    //   );
    // }
    return Promise.resolve();
  } catch (error) {
    ErrorCatch(error, SUPPLIER_ADDPRODUCT_FAILED, dispatch);
  }
};
export const ImageUpload = (payload) => async dispatch => {
  // dispatch(setLoading(SUPPLIER_ADDPRODUCT_REQUEST));
  try {
    const response = await Axios.post(`/product/user-gallery-update`, payload);
    const { data } = response;
    console.log(data);
    // if (response.status === 200) {
    //   dispatch(setSuccess(data.message, SUPPLIER_ADDPRODUCT_SUCCESS));
    //   dispatch({
    //     type: SUPPLIER_ADDPRODUCT_SUCCESS,
    //     data: data.data,
    //     message: data.message,
    //   });
    //   if (callbackFun) callbackFun(data.data);
    // } else {
    //   dispatch(
    //     setError(MSG_ERR_RESPONDING_SERVER, SUPPLIER_ADDPRODUCT_FAILED),
    //   );
    // 
    return Promise.resolve();
  } catch (error) {
    ErrorCatch(error, SUPPLIER_ADDPRODUCT_FAILED, dispatch);
  }
};


export const setProductDetails = (userId) => async dispatch => {
  dispatch(setLoading(SUPPLIER_SETPRODUCTLIST_REQUEST));
  try {
    const response = await Axios.get(`supplier/product/${userId}`);
    const { data } = response;

    console.log(data, "#########################");
    if (response.status === 200) {
      dispatch(setSuccess(data.message, SUPPLIER_SETPRODUCTLIST_SUCCESS));
      dispatch({
        type: SUPPLIER_SETPRODUCTLIST_SUCCESS,
        data: data.data.products,
        message: data.message,
      });
    } else {
      dispatch(
        setError(MSG_ERR_RESPONDING_SERVER, SUPPLIER_SETPRODUCTLIST_FAILED),
      );
    }
  } catch (error) {
    ErrorCatch(error, SUPPLIER_SETPRODUCTLIST_FAILED, dispatch);
  }
};
export const setHotProductDetails = ({ userId, page = 1, limit = 25 }) => async dispatch => {
  dispatch(setLoading(FETCH_HOT_PRODUCT_REQUEST));
  try {
    const response = await Axios.get(`supplier/hot-product/${userId}?limit=${limit}&page=${page}`);
    const { data } = response;
    if (response.status === 200) {
      dispatch(setSuccess(data.message, FETCH_HOT_PRODUCT_SUCCESS));
      dispatch({
        type: FETCH_HOT_PRODUCT_SUCCESS,
        payload: {
          hotProduct: data.data.hotproduct,
          totalPages: data.data.total,
          totalHotProduct: data.data.totalHotProduct
        }
      });
    } else {
      dispatch(
        setError(MSG_ERR_RESPONDING_SERVER, FETCH_HOT_PRODUCT_FAILED),
      );
    }
  } catch (error) {
    ErrorCatch(error, FETCH_HOT_PRODUCT_FAILED, dispatch);
  }
};
export const fetchGrade = (productId, materialId) => async dispatch => {
  // console.log(productId,materialId,"Action.js")
  dispatch(setLoading(FETCH_GRADE_REQUEST));
  try {
    const response = await Axios.post(`product/grades`, { productId, materialId });
    const { data } = response;
    // console.log(data.data,"#########################");
    if (response.status === 200) {
      dispatch(setSuccess(data.message, SUPPLIER_SETPRODUCTLIST_SUCCESS));
      dispatch({
        type: FETCH_GRADE_SUCCESS,
        data: data.data,
        message: data.message,
      });
    } else {
      dispatch(
        setError(MSG_ERR_RESPONDING_SERVER, SUPPLIER_SETPRODUCTLIST_FAILED),
      );
    }
  } catch (error) {
    ErrorCatch(error, SUPPLIER_SETPRODUCTLIST_FAILED, dispatch);
  }
};

export const deleteSupplierDetail = userId => async dispatch => {
  try {
    dispatch(setLoading())
    const response = await Axios.delete(`/supplier/deleteSupplierdata/${userId}`);
    if (response.status === 200) {
      dispatch(setSuccess("User Deleted Successfully"))
    }
    dispatch(hideLoading())
    return Promise.resolve();
  } catch (error) {
    dispatch(
      setError(error?.data?.message),
    );
  }

};
export const downloadSupplierDataCsv = () => async dispatch => {
  try {
    dispatch(setLoading())
    const response = await Axios.get(`/supplier/downloadSupplierCsv`);
    if (response.status === 200) {
      const downloadUrl = `${response?.config?.baseURL}supplier/downloadSupplierCsv`;
      window.open(downloadUrl, '_self');
      dispatch(setSuccess("Download CSV Successfully"))
    }
    dispatch(hideLoading())
    return Promise.resolve();
  } catch (error) {
    dispatch(
      setError(error?.data?.message),
    );
  }
};