import { DELETE_BULK_PRODUCTMATERIAL_SUCCESS, PRODUCTMATERIAL_CREATE_SUCCESS, PRODUCTMATERIAL_DELETE_SUCCESS, PRODUCTMATERIAL_LIST_SUCCESS, PRODUCTMATERIAL_UPDATE_SUCCESS, SET_PRODUCTMATERIALELM_DETAILS, SET_PRODUCTMATERIAL_DETAILS } from "redux/actions/Manage/ProductMaterial/Constant";

const INIT_STATE =
{
    productmaterials: [],
    productmaterial: null,
    total: 0,
    perPage: 0
    // permissions: null,
}
const INIT_STATE_DETAIL =
{
    productmaterialElm:null,
    productmaterial: null,
}

export const ProductMaterialsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case PRODUCTMATERIAL_CREATE_SUCCESS: {
            return {
                ...state,
                productmaterial: action.data,
                action: action.type,
                message: action.message,
                productmaterials: [action.data, ...state.productmaterials]
            };
        }
        case PRODUCTMATERIAL_LIST_SUCCESS: {
            return {
                ...state,
                productmaterials: action.data,
                total: action.total,
                action: action.type,
                message: action.message,
                perPage: action.perPage,
            };
        }
        //products: state.products.map(product => (product.id === action.payload.id ? action.payload : product))
        case PRODUCTMATERIAL_UPDATE_SUCCESS: {
            return {
                ...state,
                productmaterial: action.data,
                action: action.type,
                message: action.message,
                productmaterials: state.productmaterials.map(productmaterial => (productmaterial._id === action.data._id ? action.data : productmaterial))
            };
        }
        case PRODUCTMATERIAL_DELETE_SUCCESS: {
            return {
                ...state,
                productmaterial: action.data,
                action: action.type,
                message: action.message,
                productmaterials: state.productmaterials.filter(productmaterial => productmaterial._id !== action.data._id),
            };
        }
        case DELETE_BULK_PRODUCTMATERIAL_SUCCESS: {
            return {
                ...state,
                ...state,
                action: action.type,
                message: action.message,
                productmaterials: state.productmaterials.filter(productmaterial => !action.data.includes(productmaterial.id)),
            };
        }
        default:
            return state;
    }
};
export const SetProductMaterialDetailReducer = (state = INIT_STATE_DETAIL, action) => {
    switch (action.type) {
        case SET_PRODUCTMATERIAL_DETAILS:
            return {
                ...state,
                productmaterial: action.data,
                action: action.type,
            };
        case SET_PRODUCTMATERIALELM_DETAILS:
            return {
                ...state,
                productmaterialElm:action.data
            }
        default:
            return state;
    }
};