import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';

import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import GridContainer from '@jumbo/components/GridContainer';

import { getAllMaterials, getMaterialsDropdown } from 'redux/actions/Manage/Materials/Materials';
import { getAllProducts, getProductsDropdown } from 'redux/actions/Manage/Products/Products';
import { getAllSubProducts } from 'redux/actions/Manage/SubProduct/SubProduct';
import {
  createGrade,
  getAllGrades,
} from 'redux/actions/Manage/ProductMaterialGrades';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import WysiwygEditorExample from 'components/wysiwygEditor';
import { addMapping } from 'redux/actions/Manage/Mapping';
import {
  getGradesEqGrades,
  getGradesEqGradesDropdown,
  getGradesListForEqGrade,
} from 'redux/actions/Manage/EqGrades/EqGrade';
import { getGradeModalDropdown, getGradeModalList } from 'redux/actions/Manage/GradeModal';
import { Add } from '@material-ui/icons';
import CustomEditor from 'components/CustomEditor';

const useStyles = makeStyles(theme => ({
  avatar: {
    borderRadius: '0',
    height: '50vh',
    width: '20vw',
  },
  errorText: {
    color: 'red',
  },
  grid: {
    marginBottom: '-20px',
    marginTop: '-10px',
    display: 'flex',
    flexDirection: 'column',
  },
  textarea: {
    width: '100%',
    height: '100%',
    padding: '8px',
    fontSize: '17px',
    fontFamily: 'inherit',
    fontWeight: 300,
    borderColor: 'lightgray',
    borderRadius: '4px',

    '&:focus': {
      borderColor: '#1C61CA',
      borderWidth: '2px',
      outline: 'none',
      borderRadius: 4,
    },
  },
  textarea_red: {
    width: '100%',
    height: '100%',
    padding: '8px',
    fontSize: '19px',
    fontFamily: 'Normal',
    fontWeight: 200,
    borderColor: '#f44336',
    borderRadius: '4px',
    '&:focus': {
      borderColor: '#1C61CA',
      borderWidth: '2px',
      outline: 'none',
      borderRadius: 4,
    },
  },
}));

const AddProductMaterialGrade = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const productData = useSelector(state => state?.Products?.products);
  const materialData = useSelector(state => state?.Materials?.materials);
  //   const gradeData = useSelector(state => state?.Grades?.grades);
  const gradeData = useSelector(state => state?.GradeModel?.gradesModal);
  const [descriptionHTML, setDescriptionHTML] = useState('');
  const subProductData = useSelector(state => state?.SubProducts?.subproducts);
  // console.log(subProductData, '64914318f506142aa583935b');

  const { eqGrades } = useSelector(state => state.EqGrades);
  console.log(eqGrades, 'gradeListForEqGradegradeListForEqGrade');
  const [keywordsArr, setkeywordsArr] = useState([]);
  const [parameterValue, setParameterValue] = useState("");
  const [file, setFile] = useState([]);
  const [errorText, setErrorText] = useState({});
  const [metaCount, setMetaCount] = useState({
    title: 0,
    meta: 0,
  });
  const [mapping, setMapping] = useState({
    productId: '',
    materialId: '',
    gradeId: '',
    subProductId: '',
    eqgradeId: '',
    keywords: '',
    parameters: [],
    name: '',
    meta: '',
    AltImageName: '',
    url: '',
    title: '',
    preview: '',
    description: '',
  });

  const handleChange = evt => {
    const data = { ...mapping, [evt.target.name]: evt.target.value }

    if (evt.target.name === 'productId') {
      let params = {};
      dispatch(getAllSubProducts(evt.target.value, params));
      dispatch(getMaterialsDropdown({
        productId:evt.target.value
      }))
      data.subProductId = ''
      data.materialId = ''
      data.gradeId = ''
      data.eqgradeId = ''
    }
    if (evt.target.name === 'subProductId') {
      let params = {};
      dispatch(getMaterialsDropdown({
        subProductId:evt.target.value
      }))
      data.materialId = ''
      data.gradeId = ''
      data.eqgradeId = ''
    }
    if(evt.target.name === 'materialId'){
      const materialId = evt.target.value;
      dispatch(getGradeModalDropdown({
        materialId:materialId,
      }));
      data.gradeId = ''
      data.eqgradeId = ''
    }
    if (evt.target.name === 'gradeId') {
      let _id = evt.target.value;
      // dispatch(getGradesEqGrades(_id));
      dispatch(getGradesEqGradesDropdown({
        materialId:data?.materialId,
        gradeModelId: _id
      }));
    }
    setMapping(data);
  };

  const onLabelChange = evt => {
    const { name, value } = evt.target;
    const regex = /[\W_]+|\s+/g;
    if (name === 'name') {
      const nameValue = value ? value.replace(regex, '-').toLowerCase() : '';
      mapping.url = nameValue;
    }
    setMapping({ ...mapping, [name]: value });
    if(name === 'title' || name === "meta") {
      setMetaCount({ ...metaCount, [name]: value.length });
    }
  };

  const countWords = str => {
    // Remove leading and trailing whitespaces
    str = str.trim();

    // Remove consecutive whitespaces and replace them with a single space
    str = str.replace(/\s+/g, ' ');

    // Split the string by spaces to get an array of words
    var words = str.split(' ');

    // Return the number of words
    return words.length;
  };

  const validate = () => {
    let errors = {};
    let isError = false;
    if (!productId) {
      errors.productId = 'Please select product.';
      isError = true;
    }
    if (!materialId) {
      errors.materialId = 'Please select material.';
      isError = true;
    }
    // if (!subProductId) {
    //   errors.subProductId = 'Please select sub-product.';
    //   isError = true;
    // }
    // if (!gradeId) {
    //   errors.gradeId = 'Please select grade.';
    //   isError = true;
    // }
    if (!keywordsArr.length) {
      errors.keywords = 'Please enter keyword.';
      isError = true;
    }
    if (!name) {
      errors.name = 'Please enter name.';
      isError = true;
    }
    if (!url) {
      errors.url = 'Please enter url.';
      isError = true;
    }
    if (!meta) {
      errors.meta = 'Please enter meta.';
      isError = true;
    }
    if (countWords(meta) > 300) {
      errors.meta = 'Meta description must be less than 300 words.';
      isError = true;
    }
    if (!title) {
      errors.title = 'Please enter meta title.';
      isError = true;
    }
    if (!descriptionHTML) {
      errors.descriptionHTML = 'Please enter description.';
      isError = true;
    }
    if (!gradeId) {
      errors.gradeId = 'Please select Grade';
      isError = true;
    }
    if (!file.length) {
      errors.file = 'Please upload image.';
      isError = true;
    }
    if (parameters?.length < 4) {
      errors.parameters = 'Please enter minimum 4 parameters';
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };
  const validateKeyword = () => {
    let error = '';
    let isError = false;
    if (!keywords) {
      error = 'Please enter keywords.';
      isError = true;
    }
    setErrorText({ ...errorText, keywords: error });
    return {
      error,
      isError,
    };
  };
  const onKeywordsAdd = () => {
    let validateData = validateKeyword();
    if (!validateData.isError)
      setkeywordsArr(keywordsArr => [...keywordsArr, keywords]);
    setMapping({ ...mapping, keywords: '' });
  };
  const onKeywordsDelete = (e, value) => {
    setkeywordsArr(keywordsArr => keywordsArr.filter(e => e != value));
  };

  // Keywords

  const handleSubmit = () => {
    const validation = validate();
    if (!validation.isError) {
      let keywordStr = '';
      keywordsArr.map(e => (keywordStr += ',' + e));
      let formData = new FormData();

      formData.append('productId', productId);
      formData.append('gradeId', gradeId);
      formData.append('materialId', materialId);
      formData.append('subproductId', subProductId);
      formData.append('name', name);
      formData.append('url', url);
      formData.append('eqgradeId', eqgradeId);
      formData.append('keywords', keywordStr);
      formData.append('parameters', JSON.stringify(parameters));
      // formData.append('code', name.replace(/\s+/g, '-').toLowerCase());
      formData.append('title', title);
      formData.append('meta', meta);
      formData.append('description', descriptionHTML);
      formData.append('AltImageName', AltImageName);
      formData.append('logo', file[0]);

      dispatch(createGrade(formData,res => {
        if (res?.status === 201 || res?.status === 200) {
          history.push('/manage-product-material-grade');
        }
      }));
    }
  };

  useEffect(() => {
    dispatch(getProductsDropdown({}));
    // dispatch(getAllProducts({}));
    // dispatch(getAllMaterials({}));
    // dispatch(getAllGrades({}));
    // dispatch(getGradeModalList({}));
  }, []);

  const {
    productId,
    materialId,
    subProductId,
    gradeId,
    title,
    eqgradeId,
    url,
    name,
    meta,
    keywords,
    parameters,
    AltImageName,
    preview,
    description,
  } = mapping;

  return (
    <PageContainer>
      <CmtCard>
        <CmtCardContent>
          <Typography variant="h2" className="mb-2">
            Add Product Material Grade
          </Typography>
          <Divider />
          <GridContainer className="mt-2">
            <Grid item md={6} sx={12}>
              <AppSelectBox
                required
                data={productData}
                name="productId"
                label="Product"
                valueKey="productId"
                variant="outlined"
                labelKey="name"
                value={productId}
                displayName="name"
                onChange={handleChange}
                helperText={errorText?.productId}
              />
              <br />
              <br />
              <AppSelectBox
                required
                data={materialData}
                name="materialId"
                label="Material"
                valueKey="materialId"
                variant="outlined"
                labelKey="name"
                value={materialId}
                displayName="name"
                onChange={handleChange}
                helperText={errorText?.materialId}
              />
              <br />
              <br />
              <AppTextInput
                required
                fullWidth
                name="title"
                variant="outlined"
                label={`Meta Title / ${metaCount.title}`}
                value={title}
                onChange={handleChange}
                className="mb-2"
                helperText={errorText?.title}
              />
              <AppTextInput
                required
                fullWidth
                name="name"
                variant="outlined"
                label="Name"
                value={name}
                // onChange={handleChange}
                onChange={onLabelChange}
                className="mt-3"
                helperText={errorText?.name}
              />
              <AppTextInput
                fullWidth
                name="file"
                variant="outlined"
                type="file"
                // value={title}
                onChange={evt => setFile(evt.target.files)}
                className="mt-5"
                helperText={errorText?.file}
              />
            </Grid>
            <Grid item md={6} sx={12}>
              <AppSelectBox
                data={subProductData}
                name="subProductId"
                label="Sub Product"
                valueKey="subProductId"
                variant="outlined"
                labelKey="name"
                value={subProductId}
                displayName="name"
                onChange={handleChange}
                helperText={errorText?.subProductId}
              />
              <br />
              <br />
              <AppSelectBox
                required
                data={gradeData}
                name="gradeId"
                label="Grade"
                valueKey="_id"
                variant="outlined"
                labelKey="name"
                value={gradeId}
                displayName="name"
                onChange={handleChange}
                helperText={errorText?.gradeId}
              />
              <br />
              <br />
              <AppSelectBox
                required
                data={eqGrades}
                name="eqgradeId"
                label="Eq. Grade"
                valueKey="_id"
                variant="outlined"
                labelKey="name"
                value={eqgradeId}
                displayName="name"
                onChange={handleChange}
              // helperText={errorText?.gradeId}
              />
              <br />
              <br />
              <AppTextInput
                fullWidth
                name="AltImageName"
                variant="outlined"
                // required
                label="Alt Image Name"
                value={AltImageName}
                onChange={onLabelChange}
              />
              <br />
              <br />
              <AppTextInput
                required
                fullWidth
                name="url"
                variant="outlined"
                label="Url"
                value={url}
                // onChange={handleChange}
                onChange={onLabelChange}
                className="mb-5"
                helperText={errorText?.url}
              />
              <Grid className={classes.grid} item xs={12} sm={12}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <label className={errorText.meta ? classes.errorText : classes.label}>Meta Description*</label>
                  <label className={classes.label}>{metaCount.meta}</label>
                </Box>
                <textarea
                  className={
                    errorText.meta ? classes.textarea_red : classes.textarea
                  }
                  name="meta"
                  rows={4}
                  width={100}
                  variant="outlined"
                  value={meta}
                  onChange={onLabelChange}
                />
                {errorText.meta ? (
                  <p style={{ color: '#f44336', fontSize: '12px' }}>
                    {errorText?.meta}
                  </p>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
            {/* parameters */}
            <Grid item xs={12} sm={11}>
              <AppTextInput
                fullWidth
                required
                name="parameterValue"
                variant="outlined"
                label="Parameters"
                value={parameterValue}
                onChange={(e) => setParameterValue(e.target.value)}
                helperText={errorText?.parameters}
              />
            </Grid>
            <Grid item sm={1}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setMapping((pre) => {
                    if (parameterValue) {
                      return { ...pre, parameters: [...pre.parameters, parameterValue] }
                    } else {
                      return pre
                    }
                  })
                  setParameterValue("")
                }}
              >
                <Add />
              </Button>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box>
                {parameters &&
                  parameters.length > 0 &&
                  parameters.map((e, key) => {
                    return (
                      e.length !== 0 && (
                        <Chip
                          size="medium"
                          key={key}
                          label={e}
                          clickable
                          onDelete={evt => {
                            const deleteParameterValue = mapping.parameters.filter((ele,i) => ele !== e)
                            setMapping((pre) => ({ ...pre, parameters: deleteParameterValue }))
                          }}
                          color="secondary"
                          variant="outlined"
                        />
                      )
                    );
                  })}
              </Box>
            </Grid>
            {/* keywords starting */}
            <Grid item xs={12} sm={11}>
              <AppTextInput
                fullWidth
                required
                name="keywords"
                variant="outlined"
                label="Keywords"
                value={keywords}
                onChange={onLabelChange}
                helperText={errorText?.keywords}
              />
            </Grid>
            <Grid item sm={1}>
              <Button
                variant="contained"
                color="secondary"
                onClick={onKeywordsAdd}
              >
                <Add />
              </Button>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box>
                {keywordsArr &&
                  keywordsArr.length > 0 &&
                  keywordsArr.map((e, key) => {
                    return (
                      e.length !== 0 && (
                        <Chip
                          size="medium"
                          key={key}
                          label={e}
                          clickable
                          onDelete={evt => onKeywordsDelete(evt, e)}
                          color="secondary"
                          variant="outlined"
                        />
                      )
                    );
                  })}
              </Box>
            </Grid>

            {/* new keywords */}
            <p
              style={{ marginLeft: "12px" }}
              className={
                errorText?.descriptionHTML ? classes?.errorText : ''
              }
            >
              Description *
            </p>
            <Grid item xs={12} sm={12}>
              {/* <WysiwygEditorExample
                defaultDescription={description}
                setDescriptionHTML={setDescriptionHTML}
                descriptionHTML={descriptionHTML}
              /> */}

              <CustomEditor
                editorData={descriptionHTML || ''}
                setEditorData={setDescriptionHTML}
                error={errorText?.descriptionHTML}
              />
              <span className={classes?.errorText}>
                {errorText?.descriptionHTML}
              </span>
            </Grid>
            <Box display="flex" justifyContent="flex-end" mb={4}>
              <Button
                onClick={() => history.push('/manage-product-material-grade')}
              >
                Cancel
              </Button>
              <Box ml={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </GridContainer>
        </CmtCardContent>
      </CmtCard>
    </PageContainer>
  );
};

export default AddProductMaterialGrade;
