import {
  MATERIAL_SUB_PRODUCT_PRODUCT_LIST_SUCCESS,
  MATERIAL_SUB_PRODUCT_PRODUCT_UPDATE_SUCCESS,
  MATERIAL_SUB_PRODUCT_PRODUCT_STATUS_UPDATE_SUCCESS,
  MATERIAL_SUB_PRODUCT_PRODUCT_LIST_BY_ID_REQUEST,
  MATERIAL_SUB_PRODUCT_PRODUCT_LIST_BY_ID_SUCCESS,
  MATERIAL_SUB_PRODUCT_PRODUCT_LIST_BY_ID_FAILED,
} from 'redux/actions/Manage/ProductMaterialSubProduct/Constant';

const INIT_STATE = {
  productMaterialSubProducts: [],
  singleProductMaterialSubProduct: null,
  total: 0,
  perPage: 0,
};

export const ProductMaterialSubProductReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case MATERIAL_SUB_PRODUCT_PRODUCT_LIST_SUCCESS: {
      return {
        ...state,
        productMaterialSubProducts: action.data,
        total: action.total,
        action: action.type,
        message: action.message,
        perPage: action.perPage,
      };
    }
    case MATERIAL_SUB_PRODUCT_PRODUCT_STATUS_UPDATE_SUCCESS: {
      return {
        ...state,
        productMaterialSubProducts: action.data,
        action: action.type,
        message: action.message,
      };
    }
    case MATERIAL_SUB_PRODUCT_PRODUCT_UPDATE_SUCCESS: {
      return {
        ...state,
        productMaterialSubProducts: action.data,
        action: action.type,
        message: action.message,
      };
    }
    case MATERIAL_SUB_PRODUCT_PRODUCT_LIST_BY_ID_REQUEST: {
      return {
        ...state,
        singleProductMaterialSubProduct: null,
      };
    }
    case MATERIAL_SUB_PRODUCT_PRODUCT_LIST_BY_ID_SUCCESS: {
      return {
        ...state,
        singleProductMaterialSubProduct: action.data,
      };
    }
    case MATERIAL_SUB_PRODUCT_PRODUCT_LIST_BY_ID_FAILED: {
      return {
        ...state,
        singleProductMaterialSubProduct: null,
      };
    }
    default:
      return state;
  }
};
