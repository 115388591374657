import {
  GRADE_MODAL_LIST_SUCCESS,
  GRADE_MODAL_CREATE_SUCCESS,
  GRADE_MODAL_UPDATE_SUCCESS,
} from 'redux/actions/Manage/GradeModal/Constant';

const INIT_STATE = {
  GradesModal: [],
  GradeModal: null,
  total: 0,
  perPage: 0,

  //   products: [],
  //   product: null,
  //   total: 0,
  //   perPage: 0,
};

export const GradeModalReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GRADE_MODAL_LIST_SUCCESS: {
      return {
        ...state,
        gradesModal: action.data,
        total: action.total,
        action: action.type,
        message: action.message,
        perPage: action.perPage,
      };
    }
    case GRADE_MODAL_UPDATE_SUCCESS: {
      return {
        ...state,
        gradesModal: action.data,
        action: action.type,
        message: action.message,
        gradesModal: state.gradesModal.map(grade =>
          grade?._id === action?.data?._id ? action?.data : grade,
        ),
      };
    }
    default:
      return state;
  }
};
