import React, { useEffect, useRef, useState } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import { Button, Chip, Menu, MenuItem } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import CmtSearch from '@coremat/CmtSearch';
import useStyles from './index.style';
import { deleteBulkUser } from 'redux/actions/Manage/Users/Users';
import { CSVLink } from 'react-csv';
import { CloudDownload, Search } from '@material-ui/icons';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';

const filterOptionsList = [
  { label: 'Active', value: 'active' },
  { label: 'Suspended', value: 'suspended' },
];

const CustomTableToolbar = ({
  downloadData,
  setIsDownload,
  TableName,
  selected,
  setSelected,
  onUserAdd,
  filterOptions,
  setFilterOptions,
  handleSearch,
  searchTerm,
  setSearchTerm,
  addButtonText,
  handleAddButton,
  downloadTableBtn,
  fileName,
  handleSearchSubmit,
  fileHeaders,
  fileData,
  filter
}) => {
  const classes = useStyles();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [text, setText] = useState("")
  const [downloadClicked, setDownloadClicked] = useState(false);
  const dispatch = useDispatch();
  const csvLink = useRef()
  const onDeleteCLick = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    dispatch(deleteBulkUser(selected, () => setSelected([])));
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const onChipDelete = option => {
    setFilterOptions(filterOptions.filter(item => item !== option.value));
  };

  const onSearchChipDelete = () => {
    setSearchTerm('')
    handleSearchSubmit('')
  };

  const handleKeywordEnter = (e) => {
    if (e.key === "Enter") {
      Promise.resolve(handleSearchSubmit(text))
        // .then(() => setSearchTerm(''))
    }
  }

  const numSelected = selected.length;

  const downloadFn = async () => {
    setIsDownload((data) => !data)
    setDownloadClicked(true)
  };
  useEffect(() => {
    setHeaders(fileHeaders);
    setCsvData(fileData);
  }, [downloadData])
  useEffect(() => {
    if (downloadClicked) {
      setDownloadClicked(false)
      return csvLink.current?.link?.click()
    }
  }, [csvData])
  return (
    <React.Fragment>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <>
            {' '}
            {!addButtonText && (
              <Typography
                className={classes.title}
                variant="h4"
                id="tableTitle"
                component="div"
              >
                {TableName}
              </Typography>
            )}
            <Typography
              className={classes.title}
              variant="h4"
              id="tableTitle"
              component="div"
            >
              {addButtonText && (
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleAddButton}
                >
                  {addButtonText}
                </Button>
              )}
            </Typography>
          </>
        )}
        {filter && (
          <CmtDropdownMenu TriggerComponent={filter.TriggerComponent} items={filter.items} onItemClick={filter.onItemClick} />
        )}
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="delete" onClick={onDeleteCLick}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <React.Fragment>
            {/* <CmtSearch onChange={e => handleSearch(e.target.value)}  value={searchTerm} border={false} onlyIcon /> */}
            {TableName !== 'suppliers' && <>
              <CmtSearch onChange={e => { handleSearch(e.target.value) }} onKeyPress={(e) => handleKeywordEnter(e)} value={searchTerm} border={false} onlyIcon />
              <div className={classes.chipsRoot}>
                {/* {searchTerm && (
                <Chip label={searchTerm} onDelete={onSearchChipDelete} />
              )} */}
                {/* {filterOptionsList.map(
                (option, index) =>
                  filterOptions.includes(option.value) && (
                    <Chip
                      key={index}
                      label={option.label}
                      onDelete={() => onChipDelete(option)}
                    />
                  ),
              )} */}
                <Button
                  onClick={() => {
                    Promise.resolve(handleSearchSubmit(text))
                      // .then(() => setSearchTerm(''))
                  }}
                  color="primary"
                  variant="contained"
                  style={{ float: 'right', minWidth: '56px' }}
                >
                  <Search />
                </Button>
              </div>
            </>}
            {downloadTableBtn && (
              // <CSVLink onClick={() => downloadFn()}
              <>
                <Button
                  onClick={() => downloadFn()}
                  color="primary"
                  variant="contained"
                  style={{ float: 'right', margin: 10, minWidth: '140px' }}
                >
                  Download&nbsp;
                  <CloudDownload />{' '}
                </Button>
                <CSVLink
                  headers={headers}
                  data={csvData}
                  ref={csvLink}
                  // data={csvData.map(x => ({ lastname: x.lastname, email: x.email }))}
                  filename={fileName}
                  className="btn btn-primary"
                  target="_blank"
                />
                {/* <Button onClick={() => downloadFn()} color="primary" variant="contained" style={{ float: "right", margin: 10 }}>Download&nbsp;<CloudDownload /> </Button> */}
                {/* </CSVLink> */}
              </>
            )}
          </React.Fragment>
        )}
      </Toolbar>

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete users`}
        content={'Are you sure, you want to  delete selected users?'}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </React.Fragment>
  );
};

CustomTableToolbar.propTypes = {
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  filterOptions: PropTypes.array,
  setFilterOptions: PropTypes.func,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
  onUserAdd: PropTypes.func,
  add: PropTypes.func,
  addButtonText: PropTypes.string,
  handleAddButton: PropTypes.func,
};
CustomTableToolbar.defaultProps = {
  filterOptions: [],
  selected: [],
};

export default React.memo(CustomTableToolbar);
