import React, { useEffect, useState } from 'react';
import { Box, Button, Switch } from '@material-ui/core';
import { MoreHoriz, Edit } from '@material-ui/icons';

import CustomTable from 'components/CustomeTable';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import CustomModal from 'components/CustomModal';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import { useDispatch, useSelector } from 'react-redux';
import {
  createPlanList,
  editPlanList,
  getPlanList,
  updatePlanList,
} from 'redux/actions/Manage/Plan/Plan';

const Plan = history => {
  const dispatch = useDispatch();
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState();
  const [dataUpdated, setDataUpdated] = useState(false);
  const [planFetched, setplanFetched] = useState(false);
  const [planListData, setPlanListData] = useState([])
  const [errorText, setErrorText] = useState({});
  const [plan, setPlan] = useState({
    name: '',
    emailLimit: null,
    productLimit: null,
    priority: null,
  });

  const { planList, total } = useSelector(({ Plan }) => Plan);

  // const [planDetail, setPlanDetail] = useState({
  //   name: '',
  //   productLimit: '',
  //   emailLimit: '',
  //   priority:''
  // });

  let headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
      visible: true,
    },
    {
      id: 'emailLimit',
      numeric: false,
      disablePadding: false,
      label: 'Email Limit',
    },
    {
      id: 'productLimit',
      numeric: false,
      disablePadding: false,
      label: 'Product Limit',
    },
    {
      id: 'priority',
      numeric: false,
      disablePadding: false,
      label: 'Priority',
    },
    {
      id: 'active',
      numeric: false,
      disablePadding: false,
      sort: false,
      label: 'Status',
      render: elm => (
        <Switch
          checked={elm.isActive === true ? true : false}
          onChange={() => handleChangeStatusSwitch(elm)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      ),
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Actions',
      sort: false,
      render: elm => (
        <CmtDropdownMenu
          items={userActions}
          onItemClick={menu => onUserMenuClick(menu, elm)}
          TriggerComponent={<MoreHoriz />}
        />
      ),
    },
  ];

  const validate = () => {
    let errors = {};
    let isError = false;

    if (!name.trim()) {
      errors.name = 'Please enter name.';
      isError = true;
    }
    if (!productLimit) {
      errors.productLimit = 'Please enter product limit.';
      isError = true;
    }
    if (!emailLimit) {
      errors.emailLimit = 'Please enter email limit.';
      isError = true;
    }
    if (isEdit && !priority) {
      errors.priority = 'Please enter priority.';
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };

  const handleChangeStatusSwitch = async elm => {
    let status;
    if (elm.isActive === true) status = false;
    else status = true;
    const data = { isActive: status };
    const res = await dispatch(updatePlanList(elm?._id, data));
    if (res.status === 200) {
      setDataUpdated(!dataUpdated);
      // dispatch(getPlanList({ page: page + 1, perPage: 10 }))
    }
  };

  useEffect(() => {
    let params = { page: page + 1, perPage: 10 };
    if (searchTerm) {
      params.search = searchTerm;
    }
    dispatch(getPlanList(params)).then(res=>{
      if(res?.status ===200){
        setPlanListData( res?.data?.data)
      }
    })
  }, [dispatch, dataUpdated, page]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const getUserActions = () => {
    const actions = [];
    actions.push({ action: 'edit', label: 'Edit', icon: <Edit /> });
    return actions;
  };

  const userActions = getUserActions();
  const onUserMenuClick = async (menu, elm) => {
    if (menu.action === 'edit') {
      const res = await dispatch(editPlanList(elm?._id));
      if (res.status === 200) {
        setId(elm?._id);
        const {
          name,
          emailLimit,
          productLimit,
          priority,
        } = res?.data?.data?.planDetail;
        setOpenModal(true);
        setIsEdit(true);
        setPlan({ name, emailLimit, productLimit, priority });
      }
    }
  };

  const handleChange = evt => {
    setPlan({ ...plan, [evt.target.name]: evt.target.value });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setIsEdit(false);
    setPlan({
      name: '',
      productLimit: null,
      emailLimit: null,
    });
    setErrorText({});
  };

  const onSubmitClick = () => {
    let validateData = validate();
    if (!validateData.isError) {
      handlePlanSave();
    }
  };

  const handlePlanSave = async () => {
    setId(null);
    const obj = {
      productLimit,
      name,
      emailLimit,
    };
    if (isEdit) {
      obj.priority = priority;
    }
    const res = await dispatch(
      id ? updatePlanList(id, obj) : createPlanList(obj),
    );
    if (res.status === 200) {
      handleCloseModal();
      setDataUpdated(!dataUpdated);
    }
  };

  const { name, emailLimit, productLimit, priority } = plan;

  return (
    <>
      <CustomTable
        headCells={headCells}
        row={planListData?.planList|| []}
        count={planListData?.total}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        rowsPerPage={+planListData?.perPage}
        onPageChange={handlePageChange}
        page={page}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleSearch={text => setSearchTerm(text)}
        handleSearchSubmit={() => {
          setPage(0);
          setDataUpdated(!dataUpdated);
        }}
        addButtonText={'Add Plan'}
        handleAddButton={() => setOpenModal(true)}
        isDynamic={true}
      />
      <CustomModal
        open={openModal}
        onClose={handleCloseModal}
        title={isEdit ? 'Edit Plan' : 'Add Plan'}
      >
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            name="name"
            required
            variant="outlined"
            label="Plan Name"
            value={name}
            onChange={handleChange}
            helperText={errorText?.name}
          />
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            required
            name="emailLimit"
            variant="outlined"
            label="Email Limit"
            type="number"
            value={emailLimit}
            onChange={handleChange}
            helperText={errorText?.emailLimit}
          />
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            required
            name="productLimit"
            variant="outlined"
            label="Product Limit"
            type="number"
            value={productLimit}
            onChange={handleChange}
            helperText={errorText?.productLimit}
          />
        </Box>
        {isEdit && (
          <Box mb={{ xs: 6, md: 5 }}>
            <AppTextInput
              fullWidth
              required
              name="priority"
              variant="outlined"
              label="Priority"
              type="number"
              value={priority}
              onChange={handleChange}
              helperText={errorText?.priority}
            />
          </Box>
        )}
        <Box mb={{ xs: 6, md: 5 }}>
          <Button variant="contained" color="primary" onClick={onSubmitClick}>
            {isEdit ? 'Update' : 'Submit'}
          </Button>
        </Box>
      </CustomModal>
    </>
  );
};

export default Plan;
