//-------------NEWS constants------------------
export const NEWS_LIST_REQUEST = "NEWS_LIST_REQUEST";
export const NEWS_LIST_SUCCESS = "NEWS_LIST_SUCCESS";
export const NEWS_LIST_FAILED = "NEWS_LIST_FAILED";
export const DELETE_BULK_NEWS_REQUEST = "DELETE_BULK_NEWS_REQUEST";
export const DELETE_BULK_NEWS_SUCCESS = "DELETE_BULK_NEWS_SUCCESS";
export const DELETE_BULK_NEWS_FAILED = "DELETE_BULK_NEWS_FAILED";
export const SET_NEWS_DETAILS = "SET_NEWS_DETAILS";
export const NEWS_CREATE_REQUEST = "NEWS_CREATE_REQUEST";
export const NEWS_CREATE_SUCCESS = "NEWS_CREATE_SUCCESS";
export const NEWS_CREATE_FAILED = "NEWS_CREATE_FAILED";
export const NEWS_UPDATE_REQUEST = "NEWS_UPDATE_REQUEST";
export const NEWS_UPDATE_SUCCESS = "NEWS_UPDATE_SUCCESS";
export const NEWS_UPDATE_FAILED = "NEWS_UPDATE_FAILED";
export const NEWS_DELETE_REQUEST = "NEWS_DELETE_REQUEST";
export const NEWS_DELETE_SUCCESS = "NEWS_DELETE_SUCCESS";
export const NEWS_DELETE_FAILED = "NEWS_DELETE_FAILED";
