import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../index.style';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { filterOptions } from '@fake-db/apps/mail';
import ListItem from '@material-ui/core/ListItem';
import CmtList from '@coremat/CmtList';
import List from '@material-ui/core/List';
import ItemCell from './ItemCell';
import ConnectionCell from './ConnectionCell';
import {
  getConnectionsList,
  getLabelsList,
  getMailCounts,
} from 'redux/actions/MailApp';
import {
  getReScheduleEmails,
  setFilterType,
} from 'redux/actions/Manage/Emails/Emails';
import AddLabel from './AddLabel';
import PropTypes from 'prop-types';
import { withWidth } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getAppSidebarHeight } from '@jumbo/constants/AppConstants';
import EditIcon from '@material-ui/icons/Edit';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import LabelCell from './LabelCell';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import SendIcon from '@material-ui/icons/Send';
import DraftsIcon from '@material-ui/icons/Drafts';
import DeleteIcon from '@material-ui/icons/Delete';
import ArchiveIcon from '@material-ui/icons/Archive';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import StarIcon from '@material-ui/icons/Star';
import ReportIcon from '@material-ui/icons/Report';
import { getAllEmails } from 'redux/actions/Manage/Emails/Emails';
import { Block, WatchLater } from '@material-ui/icons';

const folderList = [
  { id: 1, name: 'Inbox', slug: 'inbox', icon: <MoveToInboxIcon /> },
  { id: 2, name: 'Sent', slug: 'sent', icon: <SendIcon /> },
  { id: 3, name: 'Approved', slug: 'approve', icon: <DraftsIcon /> },
  { id: 4, name: 'Scheduled', slug: 're-schedule', icon: <WatchLater /> },
  { id: 5, name: 'Disapproved', slug: 'disapprove', icon: <ReportIcon /> },
  { id: 6, name: 'Trash', slug: 'trash', icon: <DeleteIcon /> },
  { id: 7, name: 'Archived', slug: 'archive', icon: <ArchiveIcon /> },
  { id: 8, name: 'Blocked', slug: 'block_by_admin', icon: <Block /> },
];
const Sidebar = ({
  width,
  onOpenComposeDialog,
  onClickSendMail,
  labelsList,
}) => {
  const { showFooter } = useContext(AppContext);
  const {
    isSideBarCollapsed,
    connectionsList,
    filterType,
    mailsList,
    selectedMail,
    counter,
  } = useSelector(({ Emails }) => Emails);
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(getLabelsList());
    // dispatch(getConnectionsList());
  }, [dispatch]);

  useEffect(() => {
    // dispatch(getMailCounts());
  }, [dispatch, mailsList, selectedMail]);

  const onChangeFolder = folder => {
    dispatch(
      setFilterType({
        selectedFolder: folder.slug,
        selectedFilter: '',
        selectedLabel: '',
        searchText: '',
        page: 0,
        perPage:10,
      }),
    );
  };

  const onChangeFilter = filter => {
    dispatch(
      setFilterType({
        selectedFolder: '',
        selectedFilter: filter,
        selectedLabel: '',
        searchText: '',
        page: 0,
        perPage:10,
      }),
    );
  };

  const onChangeLabel = item => {
    dispatch(
      setFilterType({
        selectedFolder: '',
        selectedFilter: '',
        selectedLabel: item.slug,
        searchText: '',
        page: 0,
        perPage:10,
      }),
    );
  };

  const classes = useStyles({
    isCollapsed: isSideBarCollapsed,
    height: getAppSidebarHeight(width, showFooter),
  });

  return (
    <Box className={classes.inBuildAppSidebar}>
      <Box className={classes.inBuildAppSidebarHeader}>
        <Button
          className={classes.addTaskBtn}
          variant="contained"
          color="primary"
          onClick={() => {
            localStorage.removeItem('schedule');
            onOpenComposeDialog();
          }}
        >
          <EditIcon />
          <Box component="span" className="add-task-btn-text">
            Compose
          </Box>
        </Button>
      </Box>
      <PerfectScrollbar className={classes.perfectScrollbarMailSidebar}>
        <List component="nav" className={classes.appNav}>
          <CmtList
            data={folderList}
            renderRow={(item, index) => (
              <ItemCell
                key={index}
                item={item}
                counter={counter ? counter.folders[item.slug] : 0}
                classes={classes}
                selectedItem={filterType.selectedFolder}
                onChange={() => onChangeFolder(item)}
              />
            )}
          />

          {/* <ListItem component="div" className={classes.appNavHeaderItem}>
            <Box component="span" className={classes.appNavHeaderItemText}>
              Filters
            </Box>
          </ListItem> */}

          {/* <CmtList
            data={filterOptions}
            renderRow={(item, index) => (
              <ItemCell
                key={index}
                item={item}
                counter={counter ? counter.filters[item.slug] : 0}
                classes={classes}
                selectedItem={filterType.selectedFilter}
                onChange={onChangeFilter}
              />
            )}
          /> */}

          <ListItem component="div" className={classes.appNavHeaderItem}>
            <Box component="span" className={classes.appNavHeaderItemText}>
              Labels
            </Box>
          </ListItem>

          <CmtList
            data={labelsList}
            renderRow={(item, index) => (
              <LabelCell
                key={index}
                item={item}
                classes={classes}
                selectedItem={filterType.selectedLabel}
                onChange={() => onChangeLabel(item)}
              />
            )}
          />

          {/* <AddLabel /> */}

          {/* <ListItem component="div" className={classes.appNavHeaderItem}>
            <Box component="span" className={classes.appNavHeaderItemText}>
              Connections
            </Box>
          </ListItem> */}

          <CmtList
            style={{ marginBottom: 10 }}
            data={connectionsList}
            renderRow={(item, index) => (
              <ConnectionCell
                key={index}
                item={item}
                onClickSendMail={onClickSendMail}
              />
            )}
          />
        </List>
      </PerfectScrollbar>
    </Box>
  );
};

export default withWidth()(Sidebar);

Sidebar.prototype = {
  onOpenComposeDialog: PropTypes.func,
};
