import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import {
  Box,
  Grid,
  Button,
  makeStyles,
  Chip,
  Divider,
  Typography,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';

import GridContainer from '@jumbo/components/GridContainer';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import WysiwygEditorExample from 'components/wysiwygEditor';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';

import {
  createMaterial,
  setSingleMaterialDetail,
  updateMaterial,
} from 'redux/actions/Manage/Materials/Materials';
import CustomEditor from 'components/CustomEditor';

const useStyles = makeStyles(() => ({
  errorCk: {
    color: '#f44336',
    marginRight: '14px',
    marginTop: '4px',
    fontSize: '0.75rem',
  },
  avatar: {
    // borderRadius: '0',
    // height: '50vh',
    // width: '20vw',
    borderRadius: '0',
    width: 140,
    height: 140,
    objectFit: 'cover',
    objectPosition: 'center',
    padding: 5,
  },
  typoMarginBottom: {
    marginBottom: '10px',
  },
  editorLabelError: {
    color: '#f44336',
    fontSize: '16px',
    fontWeight: 500,
  },
  textarea: {
    width: '100%',
    height: '100%',
    padding: "8px",
    fontSize: "16px",
    fontFamily: 'inherit',
    fontWeight: 300,
    borderColor: "lightgray",
    borderRadius: "4px",
    '&:focus': {
      borderColor: "#1C61CA",
      borderWidth: "2px",
      outline: "none",
      borderRadius: 4
    },
  },
  label: {
    fontSize: '16px',
    fontFamily: "inherit",
    fontWeight: 400,
    lineHeight: 1.43,
  },
  grid: {
    marginBottom: "-20px",
    marginTop: "-10px"
  },
  textarea_red: {
    width: '100%',
    height: '100%',
    padding: "8px",
    fontSize: "19px",
    fontFamily: 'Normal',
    fontWeight: 200,
    borderColor: "#f44336",
    borderRadius: "4px",
    '&:focus': {
      borderColor: "#1C61CA",
      borderWidth: "2px",
      outline: "none",
      borderRadius: 4
    },
  },
}));

const AddEditMaterial = ({ history, open, onCloseDialog, setDataUpdated,material }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();

  //------------all use selectors
  const { user } = useSelector(({ auth }) => auth.authUser);
  // const { singleMaterial: material } = useSelector(state => state.SetMaterialDetail);
  const successAction = useSelector(state => state.Success.action);

  //-------------all use states
  const [keywordsArr, setKeywordsArr] = useState([]);
  const [descriptionHTML, setDescriptionHTML] = useState('');
  const [file, setFile] = useState([]);
  const [materialDetail, setMaterialDetail] = useState({
    code: '',
    description: '',
    h1: '',
    h2: '',
    idOfProductCreator: '',
    keywords: '',
    meta: '',
    name: '',
    AltImageName:'',
    nameOfProductCreator: '',
    title: '',
    image_path: '',
    preview: '',
    materialId: '',
  });
  const [errorText, setErrorText] = React.useState({});

  const countWords = (str) => {
    // Remove leading and trailing whitespaces
    str = str.trim();

    // Remove consecutive whitespaces and replace them with a single space
    str = str.replace(/\s+/g, ' ');

    // Split the string by spaces to get an array of words
    var words = str.split(' ');

    // Return the number of words
    return words.length;
  }

  //-------all handle functions
  const validate = () => {
    const errors = {};
    let isError = false;
    const { code, h1, h2, meta, name, title } = materialDetail;
    let tempDescription = descriptionHTML;
    let noSpaceData = tempDescription.replace(/\&nbsp;/g, '');
    const noTagData = noSpaceData.replace(/<[^>]*>/g, '');
    if (!name) {
      errors.name = 'Please enter name.';
      isError = true;
    }
    if (!code) {
      errors.code = 'Please enter code.';
      isError = true;
    }
    if (noTagData.length < 2) {
      errors.descriptionHTML = 'Please enter description.';
      isError = true;
    }
    if (!meta) {
      errors.meta = 'Please enter meta description.';
      isError = true;
    }
    if (countWords(meta) > 300) {
      errors.meta = 'Meta description must be less than 300 words.';
      isError = true;
    }
    if (!title) {
      errors.title = 'Please enter meta title.';
      isError = true;
    }
    if (!file.length && !preview) {
      errors.file = 'Please upload image.';
      isError = true;
    }
    if (!keywordsArr.length) {
      errors.keywords = 'Please enter keyword.';
      isError = true;
    }

    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };

  const validateKeyword = () => {
    let error = '';
    let isError = false;
    const { keywords } = materialDetail;
    if (!keywords) {
      error = 'Please enter keywords.';
      isError = true;
    }
    setErrorText({ ...errorText, keywords: error });
    return {
      error,
      isError,
    };
  };

  const onKeywordsAdd = () => {
    let validateData = validateKeyword();
    if (!validateData.isError) {
      setKeywordsArr(keywordsArr => [...keywordsArr, keywords]);
      setMaterialDetail({ ...materialDetail, keywords: '' });
    }
  };

  //  const onKeywordsDelete = (e, value) => {
  //   setKeywordsArr(keywordsArr => keywordsArr.filter(e => e != value));
  // };

  const onKeywordsDelete = (e, value) => {
    setKeywordsArr(keywordsArr =>
      keywordsArr.filter(e => {
        if (e.length) {
          return e != value;
        }
      }),
    );
  };

  // const onLabelChange = evt => {
  //   setMaterialDetail({
  //     ...materialDetail,
  //     [evt.target.name]: evt.target.value,
  //   });
  // };

  const onLabelChange = evt => {
    const { name, value } = evt.target;
    const regex = /[\W_]+|\s+/g;
    if (name === 'name') {
      const nameValue = value ? value.replace(regex, '-').toLowerCase() : '';
      materialDetail.code = nameValue
    }
    setMaterialDetail({ ...materialDetail, [name]: value });
  };

  const onSubmitClick = () => {
    let validateData = validate();
    if (!validateData.isError) {
      onMaterialSave();
    }
  };

  const onMaterialSave = () => {
    let keywordStr = '';
    keywordsArr.map(e => (keywordStr += ',' + e));

    let formData = new FormData();

    formData.append('code', code);
    formData.append('description', descriptionHTML);
    // formData.append('h1', h1);
    formData.append('AltImageName', AltImageName);
    formData.append('idOfProductCreator', user?.userId);
    formData.append('keywords', keywordStr);
    formData.append('meta', meta);
    formData.append('name', name);
    formData.append('nameOfProductCreator', user?.name);
    formData.append('title', title);
    formData.append('logo', file[0]);
    materialId && formData.append('id', id);
    materialId && formData.append('materialId', materialId);

    if (material) {
      let newProduct = { ...material, ...materialDetail };
      const { _id, materials, ...data } = newProduct;
      dispatch(
        updateMaterial(formData, data => {
          if (data) window.reload();
        }),
      );
    } else {
      dispatch(
        createMaterial(formData, data => {
          if (data) history.push('/manage-materials');
        }),
      );
    }
  };

  //------all useEffects

  useEffect(() => {
    if (material && id) {
      const {
        code,
        description,
        h1,
        h2,
        idOfProductCreator,
        keywords,
        meta,
        name,
        AltImageName,
        nameOfProductCreator,
        title,
        image_path,
        logo,
        materialId,
      } = material;
      setMaterialDetail({
        code,
        description,
        h1,
        h2,
        idOfProductCreator,
        AltImageName,
        keywords: '',
        meta,
        name,
        preview: logo,
        nameOfProductCreator,
        title,
        image_path,
        materialId,
      });
      const x = keywords?.split(',') || [];
      let data = x?.filter((item, index) => item !== '')
      setDescriptionHTML(description);
      setKeywordsArr(data);
    }
  }, [material]);

  // useEffect(() => {
  //   if (material) {
  //     const data = material?.keywords.split(',');
  //     setKeywordsArr(data);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (id) {
  //     dispatch(setSingleMaterialDetail(id));
  //   }
  // }, [id])

  const {
    code,
    description,
    h1,
    h2,
    keywords,
    AltImageName,
    meta,
    name,
    title,
    preview,
    materialId,
  } = materialDetail;
  return (
    <PageContainer>
      <CmtCard>
        <CmtCardContent>
          <Typography variant="h3" className={classes?.typoMarginBottom}>
            {id ? 'Edit Material' : 'Add Material'}
          </Typography>
          <Divider />
          <Box
            mt={3}
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            alignItems="center"
            mb={{ xs: 6, md: 5 }}
          >
            <GridContainer>
              <Grid item xs={12} sm={6}>
                <AppTextInput
                  required
                  fullWidth
                  name="name"
                  variant="outlined"
                  label="Material Name"
                  value={name}
                  onChange={onLabelChange}
                  helperText={errorText?.name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <AppTextInput
                  required
                  fullWidth
                  name="code"
                  variant="outlined"
                  label="Code"
                  value={code}
                  onChange={onLabelChange}
                  helperText={errorText?.code}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <AppTextInput
                  fullWidth
                  name="h1"
                  variant="outlined"
                  label="H1"
                  value={h1}
                  onChange={onLabelChange}
                  helperText={errorText?.h1}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <AppTextInput
                  fullWidth
                  name="h2"
                  variant="outlined"
                  label="H2"
                  value={h2}
                  onChange={onLabelChange}
                  helperText={errorText?.h2}
                />
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <AppTextInput
                  required
                  fullWidth
                  name="title"
                  variant="outlined"
                  label="Meta Title"
                  value={title}
                  onChange={onLabelChange}
                  helperText={errorText?.title}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <AppTextInput
                  fullWidth
                  name="file"
                  type="file"
                  variant="outlined"
                  onChange={evt => {
                    setMaterialDetail({ ...materialDetail, preview: '' });
                    setFile(evt.target.files);
                  }}
                  helperText={errorText?.file}
                />
              </Grid>
              <Grid className={classes.grid} item xs={12} sm={6}>
                <label className={classes.label}>Meta Description*</label>
              </Grid>
              <Grid className={classes.grid} item xs={12} sm={6}>
                <label className={classes.label}></label>
              </Grid>
              <Grid item xs={12} sm={6}>
                <textarea
                  className={errorText.meta ? classes.textarea_red : classes.textarea}
                  required
                  fullWidth
                  rows={3}
                  width={100}
                  name="meta"
                  variant="outlined"
                  // placeholder="Meta Description"
                  value={meta}
                  onChange={onLabelChange}
                />
                {errorText.meta ? <p style={{ color: "#f44336", fontSize: "12px" }}>{errorText?.meta}</p> : ""}
              </Grid>
              <Grid item xs={12} sm={6}>
                {preview && <img src={preview} alt={AltImageName} className={classes?.avatar} />}
              </Grid>
              <Grid item xs={12} sm={6}>
                <AppTextInput
                  fullWidth
                  name="AltImageName"
                  variant="outlined"
                  // required
                  label="Alt Image Name"
                  value={AltImageName}
                  onChange={onLabelChange}
                />
              </Grid>
              <Grid item xs={12} sm={11}>
                <AppTextInput
                  required
                  fullWidth
                  name="keywords"
                  variant="outlined"
                  label="Keywords"
                  value={keywords}
                  onChange={onLabelChange}
                  helperText={errorText?.keywords}
                />
              </Grid>
              <Grid item sm={1}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={onKeywordsAdd}
                >
                  <Add />
                </Button>
              </Grid>
              {console.log(keywordsArr)}
              {keywordsArr && keywordsArr.length > 0 && (
                <Grid item xs={12} sm={12}>
                  <Box>
                    {keywordsArr.map((e, key) => {
                      return (
                        e.length !== 0 && (
                          <Chip
                            size="medium"
                            key={key}
                            label={e}
                            clickable
                            onDelete={evt => onKeywordsDelete(evt, e)}
                            color="secondary"
                            variant="outlined"
                            className="mb-2 mr-2"
                          />
                        )
                      );
                    })}
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} sm={12}>
                <span
                  className={
                    errorText?.descriptionHTML ? classes?.editorLabelError : ''
                  }
                >
                  Description *
                </span>
                {/* <WysiwygEditorExample
                  defaultDescription={description}
                  setDescriptionHTML={setDescriptionHTML}
                  descriptionHTML={description}
                /> */}

                <CustomEditor
                  editorData={descriptionHTML || ''}
                  setEditorData={setDescriptionHTML}
                  error={errorText?.descriptionHTML}
                />

                <span className={classes?.errorCk}>
                  {errorText?.descriptionHTML}
                </span>
              </Grid>
            </GridContainer>
          </Box>
          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button onClick={() => history.push('/manage-materials')}>
              Cancel
            </Button>
            <Box ml={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={onSubmitClick}
              >
                {id ? 'Update' : 'Save'}
              </Button>
            </Box>
          </Box>
        </CmtCardContent>
      </CmtCard>
    </PageContainer>
  );
};

export default AddEditMaterial;

AddEditMaterial.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
