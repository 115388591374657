import CmtDropdownMenu from "@coremat/CmtDropdownMenu";
import { Switch } from "@material-ui/core";
import { Edit, MoreHoriz } from "@material-ui/icons";
import { Box, Button, Grid, TextField } from "@mui/material";
import Axios from "axios";
import CustomTable from "components/CustomeTable";
import CustomModal from "components/CustomModal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { editBlockIp, fetchAllBlockIp, getLimitAndDuration, updateLimitAndDuration } from "redux/actions/Manage/IPBlock/IpBlock";


const ListBlockIp = () => {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = React.useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLimitOpen, setIslimitOpen] = useState(false);
  const [limitAndDuration,setLimitAndDuration] = useState({
    limit:10,
    duration:120
  })
  const dispatch = useDispatch();
  const history = useHistory()
  const ipBlock = useSelector((state) => state?.ipBlock);

  const onUserMenuClick = (menu, elm) => {
    if (menu.action === 'edit') {
      history.push(`/manage-edit-block-ip/${elm?._id}`)
    }
  };

  const handleAddButton = function () {
    history.push('/manage-block-ip')
  }

  const handleRequestSort = (event, property) => {
    const isAsc = order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeStatusSwitch = async (elm) => {
    let status;
    if (elm.isBlock === true) status = false;
    else status = true;
    dispatch(editBlockIp(elm?._id, {
      isBlock: status
    })).then(() => {
      dispatch(fetchAllBlockIp({
        search: searchTerm,
        page: page + 1
      }));
    })
  };

  const getUserActions = user => {
    const actions = [];
    actions.push({ action: 'edit', label: 'Edit', icon: <Edit /> });
    return actions;
  };

  const userActions = getUserActions();


  const headCells = [
    {
      id: 'ipAddress',
      numeric: false,
      disablePadding: false,
      label: 'IP Address',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Blocked Status',
    },
    {
      id: 'isBlock',
      numeric: false,
      disablePadding: false,
      sort: false,
      label: 'Blocked',
      render: elm => (
        <Switch
          // defaultChecked={elm.showInMiddle === true ? true : false}
          checked={elm.isBlock === true ? true : false}
          onChange={() => handleChangeStatusSwitch(elm)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      ),
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Actions',
      sort: false,
      render: elm => (
        <>
          {/* {!updatePermission && !deletePermission ? '-' : <CmtDropdownMenu items={userActions} onItemClick={(menu) => onUserMenuClick(menu, elm)} TriggerComponent={<MoreHoriz />} />} */}
          {
            <CmtDropdownMenu
              items={userActions}
              onItemClick={menu => onUserMenuClick(menu, elm)}
              TriggerComponent={<MoreHoriz />}
            />
          }
        </>
      ),
    },
  ]

  const handleSubmitLimit = async (e) => {
    e.preventDefault();
    dispatch(updateLimitAndDuration(limitAndDuration?._id,limitAndDuration))
   }

  useEffect(() => {
    dispatch(fetchAllBlockIp({
      search: searchTerm,
      page: page + 1
    }))
  }, [searchTerm, page])

  useEffect(()=>{
    dispatch(getLimitAndDuration()).then((data)=>{
      setLimitAndDuration(data)
    })
  },[isLimitOpen])

  return (
    <>
      <Box mb={2} textAlign={"end"}>
        <Button variant="contained" sx={{ bgcolor: "#E56B6F" ,"&:hover":{bgcolor:"#E56B6F"} }} onClick={() => setIslimitOpen(true)}>Set Limit & Duration</Button>
      </Box>
      <CustomTable
        TableName="Blocked Ip Address"
        addButtonText={'Block Ip Address'}
        handleAddButton={handleAddButton}
        row={ipBlock?.blockedIp}
        headCells={headCells}
        isDynamic={true}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        count={ipBlock?.total || 0}
        rowsPerPage={perPage}
        onPageChange={handlePageChange}
        page={page}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleSearch={(text) => setSearchTerm(text)}
        handleSearchSubmit={() => {
          setPage(0)
        }}
      />
      {isLimitOpen && <CustomModal
        open={isLimitOpen}
        onClose={() => setIslimitOpen(false)}
        title={'Limit And Duration'}
      >
        <form onSubmit={handleSubmitLimit}>
          <Box mb={4}>
            <Grid container justifyContent={"space-between"} rowSpacing={2} columnSpacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required
                  name="limit"
                  value={limitAndDuration?.limit}
                  size="small"
                  label='Limit'
                  type="number"
                  onChange={(e)=> setLimitAndDuration((prev)=> ({...prev,[e.target.name]:e.target.value}))}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required
                  name="duration"
                  value={limitAndDuration?.duration}
                  size="small"
                  label='Duration In Minutes'
                  type="number"
                  onChange={(e)=> setLimitAndDuration((prev)=> ({...prev,[e.target.name]:e.target.value}))}
                />
              </Grid>
            </Grid>
            <Box textAlign={'center'}>
              <Button type="submit" variant="contained" sx={{ mt: 3 }}>Update</Button>
            </Box>
          </Box>
        </form>
      </CustomModal>}
    </>
  )
}

export default ListBlockIp