import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import {
  Delete,
  Edit,
  Lock,
  LockOpen,
  MoreHoriz,
  Visibility,
} from '@material-ui/icons';
import Axios from 'axios';
import CustomTable from 'components/CustomeTable';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getBlockedUsers } from '../../../../redux/actions/Manage/EmailBlock/EmailBlock';
import AddEditUser from './AddEditUser';

const EmailBlock = ({ history }) => {
  const dispatch = useDispatch();

  //------------all use selectors
  const { user } = useSelector(({ auth }) => auth.authUser);
  const { blockedEmails, total, perPage } = useSelector(
    state => state.emailBlock,
  );
  // const UserData = useSelector(state => state.SetUserDetail);
  // const userDetail = UserData.user;

  //-------------all use states
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState({ name: '' });
  const [usersFetched, setUsersFetched] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [row, setRow] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [permissionData] = useState(
    JSON.parse(localStorage.getItem('permission')),
  );
  const [createPermission, setCreatePermission] = useState(false);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [exportPermission, setExportPermission] = useState(false);
  const [chgPasswordPermission, setChgPasswordPermission] = useState(false);
  const [openChgPassDialog, setOpenChgPassDialog] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [search, setSearch] = useState({ sort: null, field: null });
  const [fileData, setFileData] = useState([]);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [downloadData, setDownloadData] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [sort, setSort] = useState({
    orderBy: '',
    order: 'asc',
  });

  console.log(blockedEmails);
  //-------table config actions
  const getUserActions = user => {
    const actions = [];
    actions.push({ action: 'view', label: 'View', icon: <Visibility /> });
    // chgPasswordPermission && actions.push({ action: 'change_password', label: 'Change Password', icon: <Lock /> })
    // updatePermission && actions.push({ action: 'edit', label: 'Edit', icon: <Edit /> })

    // deletePermission && actions.push({ action: 'delete', label: 'Delete', icon: <Delete /> });
    actions.push({ action: 'delete', label: 'Unblock', icon: <LockOpen /> });
    return actions;
  };
  const userActions = getUserActions(row);
  const onUserMenuClick = (menu, elm) => {
    if (menu.action === 'view') {
      // dispatch(setUserDetail(elm));
      setOpenViewDialog(true);
    } else if (menu.action === 'change_password') {
      // dispatch(setUserDetail(elm));
      setOpenChgPassDialog(true);
      // onUserChangePassword(row);
    } else if (menu.action === 'edit') {
      // dispatch(setUserDetail(elm));
      setOpenUserDialog(true);
    } else if (menu.action === 'delete') {
      // setSelectedUser(elm);
      setOpenConfirmDialog(true);
    }
  };
  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
      sort: true,
      // render: elm => { return elm?.firstName + " " + elm?.lastName },
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'mobileNo',
      numeric: false,
      disablePadding: false,
      label: 'Contact',
      // render: elm => { return elm?.roleId?.roleName },
    },
    // {
    //     id: 'departmentName',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'Dept',
    //     render: elm => { return elm?.roleId?.departmentId?.departmentName },
    // },
    {
      id: 'active',
      numeric: false,
      disablePadding: false,
      label: 'Status',
      render: elm => {
        return elm?.status === 1 ? 'Temporary' : 'Permanent';
      },
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Actions',
      sort: false,
      render: elm => (
        <>
          {/* {!updatePermission && !deletePermission ? '-' : <CmtDropdownMenu items={userActions} onItemClick={(menu) => onUserMenuClick(menu, elm)} TriggerComponent={<MoreHoriz />} />} */}
          {
            <CmtDropdownMenu
              items={userActions}
              onItemClick={menu => onUserMenuClick(menu, elm)}
              TriggerComponent={<MoreHoriz />}
            />
          }
        </>
      ),
    },
  ];
  //download file setting
  var fileHeaders = [
    {
      key: 'firstName',
      label: 'Name',
    },
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'roleName',
      label: 'Role',
    },
    {
      key: 'departmentName',
      label: 'Dept',
    },
    {
      key: 'status',
      label: 'Status',
    },
  ];

  //-------all handle functions
  const handleViewDialog = user => {
    // dispatch(setUserDetail(user));
    setOpenViewDialog(true);
  };
  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    // dispatch(setUserDetail(null));
  };
  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    // dispatch(setUserDetail(null));
  };
  const handleAddButton = () => {
    setOpenUserDialog(true);
    // dispatch(setUserDetail(null));
  };
  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };
  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    // dispatch(
    //   deleteUser(selectedUser._id, data => {
    //     if (data) setDataUpdated(data => !data);
    //   }),
    // );
  };
  const handleCloseChgPassDialog = () => {
    setOpenChgPassDialog(false);
    // dispatch(setUserDetail(null));
  };
  const onChangePage = query => {
    // localStorage.setItem('page', query + 1);
    setPage(query + 1);
  };
  const onRequestSort = (event, property) => {
    const isAsc = sort.order === 'desc';
    setSort({ ...sort, orderBy: property, order: isAsc ? 'asc' : 'desc' });
    if (sort.order === 'desc')
      setSearch({ ...search, sort: 1, field: property });
    else if (sort.order === 'asc')
      setSearch({ ...search, sort: -1, field: property });
  };
  const handleSearch = dateset_name => {
    setPage(1);
    setSearchTerm(dateset_name);
  };
  // const downloadFn = async params => {
  //   dispatch(
  //     getBlockedUsers(
  //       params,
  //       data => {
  //         if (data) {
  //           let fileData = [];
  //           data.forEach(item => {
  //             fileData.push({
  //               name: item.firstName + ' ' + item.lastName,
  //               email: item.email,

  //               status: item.status === 1 ? 'Temporary' : 'Permanent',
  //             });
  //           });
  //           setFileData(fileData);
  //         }
  //       },
  //       true,
  //     ),
  //   );
  // };
  // const getPermission = useCallback(() => {
  //   permissionData &&
  //     permissionData.forEach(function(num) {
  //       if (user?.roleId?.roleSlug === 'admin' || num.name === 'user-create') {
  //         setCreatePermission(true);
  //       }
  //       if (user?.roleId?.roleSlug === 'admin' || num.name === 'user-delete') {
  //         setDeletePermission(true);
  //       }
  //       if (user?.roleId?.roleSlug === 'admin' || num.name === 'user-update') {
  //         setUpdatePermission(true);
  //       }
  //       if (user?.roleId?.roleSlug === 'admin' || num.name === 'user-export') {
  //         setExportPermission(true);
  //       }
  //       if (user?.roleId?.roleSlug === 'admin') {
  //         setChgPasswordPermission(true);
  //       }
  //     });
  // }, [permissionData, user]);

  //------all useEffects
  useEffect(() => {
    let params;
    params = {
      page,
      search: searchTerm,
    };
    // if (search) {
    //   params = {
    //     ...params,
    //     ...search,
    //   };
    // }
    dispatch(getBlockedUsers, () => {
      setUsersFetched(true);
    });
    // getPermission();
  }, []);

  // useEffect(() => {
  //   //to download data
  //   let params;
  //   params = {
  //     search: searchTerm,
  //   };
  //   if (search) {
  //     params = {
  //       ...params,
  //       ...search,
  //     };
  //   }
  //   downloadFn(params);
  // }, [isDownload]);

  // useEffect(() => {
  //   setDownloadData(data => !data); //update state at custom toolbar component
  // }, [fileData]);

  return (
    <>
      <CustomTable
        TableName="Blocked Users"
        downloadTableBtn={exportPermission ? true : false}
        fileName="BlockedEmail .xlsx"
        fileHeaders={fileHeaders}
        fileData={fileData}
        setIsDownload={setIsDownload}
        downloadData={downloadData}
        row={blockedEmails && blockedEmails}
        // addButtonText={createPermission ? "Add User" : ""}
        addButtonText={'Block User Email'}
        handleAddButton={handleAddButton}
        headCells={headCells}
        order={sort.order}
        orderBy={sort.orderBy}
        onRequestSort={onRequestSort}
        count={total}
        rowsPerPage={perPage}
        onPageChange={onChangePage}
        page={page}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleSearch={handleSearch}
        handleViewDialog={handleViewDialog}
        userDetailModal={{
          // emailData: userDetail?.email,
          // modalHeading: userDetail?.email,
          headerArray: [
            // userDetail?.roleId?.roleName,
            // userDetail?.roleId?.departmentId?.departmentName,
          ],
          sectionHeading: 'Contact Details',
          openViewDialog: openViewDialog,
          handleCloseViewDialog: handleCloseViewDialog,
          // activeChip: userDetail?.status,
        }}
      />
      {openUserDialog && (
        <AddEditUser
          setDataUpdated={setDataUpdated}
          open={openUserDialog}
          onCloseDialog={handleCloseUserDialog}
        />
      )}

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete`}
        content={'Are you sure, you want to  delete this user?'}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};

export default EmailBlock;
