//-------------permissions constants------------------
export const PERMISSIONS_LIST_REQUEST = "PERMISSIONS_LIST_REQUEST";
export const PERMISSIONS_LIST_SUCCESS = "PERMISSIONS_LIST_SUCCESS";
export const PERMISSIONS_LIST_FAILED = "PERMISSIONS_LIST_FAILED";
export const ROLES_PERMISSIONS_LIST_REQUEST = "ROLES_PERMISSIONS_LIST_REQUEST";
export const ROLES_PERMISSIONS_LIST_SUCCESS = "ROLES_PERMISSIONS_LIST_SUCCESS";
export const ROLES_PERMISSIONS_LIST_FAILED = "ROLES_PERMISSIONS_LIST_FAILED";
export const ROLES_PERMISSIONS_UPDATE_REQUEST = "ROLES_PERMISSIONS_UPDATE_REQUEST";
export const ROLES_PERMISSIONS_UPDATE_SUCCESS = "ROLES_PERMISSIONS_UPDATE_SUCCESS";
export const ROLES_PERMISSIONS_UPDATE_FAILED = "ROLES_PERMISSIONS_UPDATE_FAILED";
export const SET_PERMISSIONS_DETAILS = "SET_PERMISSIONS_DETAILS";
export const PERMISSIONS_CREATE_REQUEST = "PERMISSIONS_CREATE_REQUEST";
export const PERMISSIONS_CREATE_SUCCESS = "PERMISSIONS_CREATE_SUCCESS";
export const PERMISSIONS_CREATE_FAILED = "PERMISSIONS_CREATE_FAILED";
export const PERMISSIONS_UPDATE_REQUEST = "PERMISSIONS_UPDATE_REQUEST";
export const PERMISSIONS_UPDATE_SUCCESS = "PERMISSIONS_UPDATE_SUCCESS";
export const PERMISSIONS_UPDATE_FAILED = "PERMISSIONS_UPDATE_FAILED";
export const PERMISSIONS_DELETE_REQUEST = "PERMISSIONS_DELETE_REQUEST";
export const PERMISSIONS_DELETE_SUCCESS = "PERMISSIONS_DELETE_SUCCESS";
export const PERMISSIONS_DELETE_FAILED = "PERMISSIONS_DELETE_FAILED";
