//-------------MATERIAL constants------------------
export const GRADE_LIST_REQUEST = "GRADE_LIST_REQUEST";
export const GRADE_LIST_SUCCESS = "GRADE_LIST_SUCCESS";
export const GRADE_LIST_FAILED = "GRADE_LIST_FAILED";
export const DELETE_BULK_GRADE_REQUEST = "DELETE_BULK_GRADE_REQUEST";
export const DELETE_BULK_GRADE_SUCCESS = "DELETE_BULK_GRADE_SUCCESS";
export const DELETE_BULK_GRADE_FAILED = "DELETE_BULK_GRADE_FAILED";
export const SET_GRADE_DETAILS = "SET_GRADE_DETAILS";
export const GRADE_CREATE_REQUEST = "GRADE_CREATE_REQUEST";
export const GRADE_CREATE_SUCCESS = "GRADE_CREATE_SUCCESS";
export const GRADE_CREATE_FAILED = "GRADE_CREATE_FAILED";
export const GRADE_UPDATE_REQUEST = "GRADE_UPDATE_REQUEST";
export const GRADE_UPDATE_SUCCESS = "GRADE_UPDATE_SUCCESS";
export const GRADE_UPDATE_FAILED = "GRADE_UPDATE_FAILED";
export const GRADE_PROFILE_UPDATE_REQUEST = "GRADE_PROFILE_UPDATE_REQUEST";
export const GRADE_PROFILE_UPDATE_SUCCESS = "GRADE_PROFILE_UPDATE_SUCCESS";
export const GRADE_PROFILE_UPDATE_FAILED = "GRADE_PROFILE_UPDATE_FAILED";
export const GRADE_PASSWORD_UPDATE_REQUEST = "GRADE_PASSWORD_UPDATE_REQUEST";
export const GRADE_PASSWORD_UPDATE_SUCCESS = "GRADE_PASSWORD_UPDATE_SUCCESS";
export const GRADE_PASSWORD_UPDATE_FAILED = "GRADE_PASSWORD_UPDATE_FAILED";
export const GRADE_DELETE_REQUEST = "GRADE_DELETE_REQUEST";
export const GRADE_DELETE_SUCCESS = "GRADE_DELETE_SUCCESS";
export const GRADE_DELETE_FAILED = "GRADE_DELETE_FAILED";
export const SET_GRADEELM_DETAILS = "SET_GRADEELM_DETAILS";
