const config = {
  //  REACT_APP_API_BASE_URL: process.env.REACT_APP_API_BASE_URL,

  // REACT_APP_API_BASE_URL:"http://localhost:3050/",
  REACT_APP_API_BASE_URL: "https://tpmbe.kdcstaging.in/",
  // REACT_APP_API_BASE_URL: "https://api.pipingmart.co.in/",
  // REACT_APP_API_BASE_URL: "https://api.thepipingmart.com/",

  NODE_ENV: process.env.NODE_ENV
};
export default config;
