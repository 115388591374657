import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import {
  isValidEmail,
  isValidPassword,
  isValidPhone,
} from '@jumbo/utils/commonHelper';
import {
  createProduct,
  updateProduct,
} from 'redux/actions/Manage/Products/Products';
import WysiwygEditorExample from 'components/wysiwygEditor';
import { Tooltip } from 'chart.js';
import { Chip, Divider, Paper } from '@material-ui/core';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { Add, Remove } from '@material-ui/icons';
import { PRODUCT_CREATE_SUCCESS } from 'redux/actions';
import { Typography } from '@mui/material';
import Dropzone from 'react-dropzone';
// import { DropzoneArea } from 'material-ui-dropzone';
import { uploadFile } from 'redux/actions/FileUpload/FileUpload';
import {
  createProductMaterial,
  setProductMaterialDetail,
  updateProductMaterial,
} from 'redux/actions/Manage/ProductMaterial/ProductMaterial';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import { fetchProductMaterialGradeList } from 'redux/actions/Manage/Suppliers/Suppliers';
import CustomEditor from 'components/CustomEditor';
import { useParams } from 'react-router';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  dropZone: {
    minHeight: '100px',
    minWidth: '100px',
    // fullWidth: 'true',
  },
  previewContainer: {
    container: 'true',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  preview: {
    xs: '12',
  },
  errorCk: {
    color: '#f44336',
    marginRight: '14px',
    marginTop: '4px',
    fontSize: '0.75rem',
  },
  editorLabelError: {
    color: '#f44336',
    fontSize: '16px',
    fontWeight: 500,
  },
  textarea: {
    width: '100%',
    height: '100%',
    padding: '8px',
    fontSize: '16px',
    fontFamily: 'inherit',
    fontWeight: 400,
    borderColor: 'lightGray',
    borderRadius: '4px',
    '&:focus': {
      borderColor: '#1C61CA',
      borderWidth: '2px',
      outline: 'none',
      borderRadius: 4,
    },
  },
  avatar: {
    width: '140px',
    height: '140px',
    objectFit: 'cover',
    marginTop: '14px',
  },
  label: {
    fontSize: '16px',
    fontFamily: 'inherit',
    fontWeight: 400,
    lineHeight: 1.43,
  },
  grid: {
    marginBottom: '-20px',
    marginTop: '-20px',
  },
  textarea_red: {
    width: '100%',
    height: '100%',
    padding: '8px',
    fontSize: '16px',
    fontFamily: 'inherit',
    fontWeight: 400,
    borderColor: '#f44336',
    borderRadius: '4px',
    '&:focus': {
      borderColor: '#1C61CA',
      borderWidth: '2px',
      outline: 'none',
      borderRadius: 4,
    },
  },
}));

const AddEditProductMaterial = ({
  history,
  open,
  onCloseDialog,
  setDataUpdated,
  productmaterial,
}) => {
  // const { id:productMaterialId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();

  //------------all use selectors
  const { user } = useSelector(({ auth }) => auth.authUser);
  // const { productmaterial } = useSelector((state) => state.SetProductMaterial);

  const { products, materials } = useSelector(
    state => state.SetProductMaterialGradeList,
  );

  const successAction = useSelector(state => state.Success.action);

  //-------------all use states
  const [keywordsArr, setkeywordsArr] = useState([]);
  const [parameterValue, setParameterValue] = useState("");
  const [descriptionHTML, setDescriptionHTML] = useState('');
  const [detail, setDetail] = useState({
    productId: '',
    materialId: '',
  });
  const [file, setFile] = useState([]);
  const [productDetail, setproductDetail] = useState({
    code: '',
    description: '',
    h1: '',
    h2: '',
    idOfProductCreator: '',
    keywords: '',
    // parameters:[],
    meta: '',
    displayName: '',
    AltImageName: '',
    nameOfProductCreator: '',
    title: '',
    url: '',
    image_path: '',
    preview: '',
    productId: '',
    materialId: '',
    subProductId: '',
    id: '',
  });
  const [errorText, setErrorText] = React.useState({});
  const [metaCount, setMetaCount] = useState({
    title: 0,
    meta: 0,
  });

  const countWords = str => {
    // Remove leading and trailing whitespaces
    str = str?.trim();

    // Remove consecutive whitespaces and replace them with a single space
    str = str?.replace(/\s+/g, ' ');

    // Split the string by spaces to get an array of words
    var words = str?.split(' ');

    // Return the number of words
    return words?.length;
  };

  //-------all handle functions
  const validate = () => {
    const errors = {};
    let isError = false;
    const {
      code,
      description,
      h1,
      h2,
      meta,
      displayName,
      title,
      url,
      parameters,
      image_path,
    } = productDetail;
    let tempDescription = descriptionHTML;
    let noSpaceData = tempDescription?.replace(/\&nbsp;/g, '');
    const noTagData = noSpaceData?.replace(/<[^>]*>/g, '');
    if (!displayName) {
      errors.displayName = 'Please enter name.';
      isError = true;
    }
    if (noTagData?.length < 2) {
      errors.descriptionHTML = 'Please enter description.';
      isError = true;
    }
    if (!meta) {
      errors.meta = 'Please enter meta.';
      isError = true;
    }
    if (countWords(meta) > 300) {
      errors.meta = 'Meta description must be less than 300 words.';
      isError = true;
    }
    if (!keywordsArr?.length) {
      errors.keywords = 'Please enter keyword.';
      isError = true;
    }
    if (!title) {
      errors.title = 'Please enter title.';
      isError = true;
    }
    if (!productmaterial && !detail.productId) {
      errors.productId = 'Please select Product.';
      isError = true;
    }
    if (!productmaterial && !detail.materialId) {
      errors.materialId = 'Please select Material.';
      isError = true;
    }
    if (!url) {
      errors.url = 'Please enter url.';
      isError = true;
    }
    if (!file?.length && !preview) {
      errors.file = 'Please upload image.';
      isError = true;
    }
    // if (parameters?.length < 4) {
    //   errors.parameters = 'Please enter minimum 4 parameters';
    //   isError = true;
    // }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };
  const validateKeyword = () => {
    let error = '';
    let isError = false;
    if (!keywords) {
      error = 'Please enter keywords.';
      isError = true;
    }
    setErrorText({ ...errorText, keywords: error });
    return {
      error,
      isError,
    };
  };
  const onKeywordsAdd = () => {
    let validateData = validateKeyword();
    if (!validateData.isError)
      setkeywordsArr(keywordsArr => [...keywordsArr, keywords]);
    setproductDetail({ ...productDetail, keywords: '' });
  };
  const onKeywordsDelete = (e, value) => {
    setkeywordsArr(keywordsArr => keywordsArr.filter(e => e != value));
  };
  const onLableChange1 = evt => {
    if (evt.target.name == 'productId') {
      setDetail({
        ...detail,
        productId: evt.target.value.productId,
      });
    } else if (evt.target.name == 'materialId') {
      setDetail({
        ...detail,
        materialId: evt.target.value.materialId,
      });
    }
  };
  const onLabelChange = evt => {
    const { name, value } = evt.target;
    const regex = /[\W_]+|\s+/g;
    if (name === 'displayName') {
      const nameValue = value ? value.replace(regex, '-').toLowerCase() : '';
      // productDetail.code = nameValue
      productDetail.url = nameValue;
    }
    setproductDetail({ ...productDetail, [name]: value });
    setMetaCount({ ...metaCount, [name]: value?.length });
  };
  const onSubmitClick = () => {
    // console.log("224")
    let validateData = validate();
    // console.log("226",validateData)
    if (!validateData.isError) {
      onproductSave();
    }
  };
  const onproductSave = () => {
    let keywordStr = '';
    keywordsArr.map(e => (keywordStr += ',' + e));
    let formData = new FormData();

    // formData.append('code', code);
    formData.append('description', descriptionHTML);
    // formData.append('h1', h1);
    // formData.append('h2', h2);
    // formData.append('idOfProductCreator', user?.userId);
    // formData.append('parameters', JSON.stringify(parameters));
    formData.append('keywords', keywordStr);
    formData.append('meta', meta);
    formData.append('displayName', displayName);
    // formData.append('nameOfProductCreator', user?.name);
    formData.append('title', title);
    formData.append('url', url);
    formData.append('logo', file[0]);
    formData.append('productId', productId);
    formData.append('materialId', materialId);
    formData.append('subProductId', subProductId);
    formData.append('AltImageName', AltImageName);
    productmaterial && formData.append('id', id);
    productmaterial && formData.append('paramsId', params.id);
    if (productmaterial) {
      let newProduct = { ...productmaterial, ...productDetail };
      const { _id, materials, ...data } = newProduct;
      dispatch(
        updateProductMaterial(formData, data => {
          if (data) window.reload();
        }),
      );
    } else {
      formData.set('productId', detail.productId);
      formData.set('materialId', detail.materialId);
      dispatch(
        // {...productDetail,productId:detail.productId,materialId:detail.materialId}
        createProductMaterial(formData, data => {
          if (data) history.push('/manage-productMaterial');
        }),
      );
    }
  };
  const handleFileUpload = files => {
    let formData = new FormData();
    formData.append('fileName', files[0]?.name);
    formData.append('fileCategory', 'logo');
    formData.append('uploadedFile', files[0]);
    dispatch(uploadFile(formData));
  };

  //------all useEffects

  useEffect(() => {
    if (productmaterial) {
      const {
        code,
        description,
        h1,
        h2,
        idOfProductCreator,
        // parameters,
        keywords,
        meta,
        displayName,
        AltImageName,
        nameOfProductCreator,
        title,
        logo,
        url,
        image_path,
        productId,
        materialId,
        subProductId,
        id,
      } = productmaterial;
      setproductDetail({
        code,
        description,
        h1,
        h2,
        idOfProductCreator,
        // parameters,
        keywords: '',
        meta,
        displayName,
        AltImageName,
        nameOfProductCreator,
        title,
        url,
        image_path,
        preview: logo,
        productId,
        materialId,
        subProductId,
        id,
      });
      setMetaCount({
        ...metaCount,
        title: title?.length,
        meta: meta?.length,
      });
      const x = keywords?.split(',') || [];
      let data = x?.filter((item, index) => item !== '');
      setkeywordsArr(data);
      setDescriptionHTML(description);
    }
  }, [productmaterial]);

  useEffect(() => {
    if (!productmaterial) {
      dispatch(fetchProductMaterialGradeList());
    }
    // if (productmaterial) {
    //   const data = productmaterial?.keywords.split(',')
    //   setkeywordsArr(data)
    // }
  }, []);

  // useEffect(()=>{
  //   if(productMaterialId){
  //     dispatch(setProductMaterialDetail(productMaterialId))
  //   }
  // },[productMaterialId])

  const {
    code,
    description,
    h1,
    h2,
    preview,
    keywords,
    // parameters,
    meta,
    displayName,
    title,
    url,
    image_path,
    productId,
    materialId,
    subProductId,
    AltImageName,
    id,
  } = productDetail;
  return (
    <PageContainer>
      <div>
        <Paper className={classes.paper}></Paper>
        <CmtCard>
          <CmtCardContent>
            <Typography variant="h6">
              {productmaterial ? 'Edit ProductMaterial' : 'Add ProductMaterial'}
            </Typography>
            <Divider></Divider>
            <Box
              mt={3}
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
              alignItems="center"
              mb={{ xs: 6, md: 5 }}
            >
              <GridContainer>
                {!productmaterial && products && (
                  <Grid item xs={12} sm={6}>
                    <AppSelectBox
                      required
                      name="productId"
                      variant="outlined"
                      label="Select Product"
                      // value={productName}
                      onChange={onLableChange1}
                      // helperText={errorText?.name}
                      fullWidth
                      data={products && products}
                      valueKey=""
                      labelKey="name"
                      // value={status}
                      // onChange={onLabelChange}
                      helperText={errorText?.productId}
                    />
                  </Grid>
                )}
                {!productmaterial && materials && (
                  <Grid item xs={12} sm={6}>
                    <AppSelectBox
                      required
                      name="materialId"
                      variant="outlined"
                      label="Select Material"
                      // value={productName}
                      onChange={onLableChange1}
                      // helperText={errorText?.name}
                      fullWidth
                      data={materials && materials}
                      valueKey=""
                      labelKey="name"
                      // value={status}
                      // onChange={onLabelChange}
                      helperText={errorText?.materialId}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    required
                    fullWidth
                    name="displayName"
                    variant="outlined"
                    label="ProductMaterial Name"
                    value={displayName}
                    onChange={onLabelChange}
                    helperText={errorText?.displayName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    required
                    fullWidth
                    name="title"
                    variant="outlined"
                    label={`Meta Title / ${metaCount.title || 0}`}
                    value={title}
                    onChange={onLabelChange}
                    helperText={errorText?.title}
                  />
                </Grid>
                <Grid className={classes.grid} item xs={12} sm={6}>
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <label className={classes.label}>Meta Description*</label>
                    <label className={classes.label}>{metaCount.meta}</label>
                  </Box>
                </Grid>
                <Grid className={classes.grid} item xs={12} sm={6}>
                  <label className={classes.label}></label>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <textarea
                    rows={3}
                    className={
                      errorText.meta ? classes.textarea_red : classes.textarea
                    }
                    name="meta"
                    variant="outlined"
                    // placeholder="Meta Description"
                    value={meta}
                    onChange={onLabelChange}
                    helperText={errorText?.meta}
                  />
                  {errorText.meta ? (
                    <p style={{ color: '#f44336', fontSize: '12px' }}>
                      {errorText?.meta}
                    </p>
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    required
                    fullWidth
                    name="url"
                    variant="outlined"
                    label="URL"
                    value={url}
                    onChange={onLabelChange}
                    helperText={errorText?.url}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    fullWidth
                    name="file"
                    type="file"
                    variant="outlined"
                    onChange={evt => {
                      setproductDetail({ ...productDetail, preview: '' });
                      setFile(evt.target.files);
                    }}
                    helperText={errorText?.file}
                  />
                  {preview && <img src={preview} alt={AltImageName} className={classes.avatar} />}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    fullWidth
                    name="AltImageName"
                    variant="outlined"
                    // required
                    label="Alt Image Name"
                    value={AltImageName}
                    onChange={onLabelChange}
                  />
                </Grid>
                {/* parameters */}
                {/* <Grid item xs={12} sm={11}>
                  <AppTextInput
                    fullWidth
                    required
                    name="parameterValue"
                    variant="outlined"
                    label="Parameters"
                    value={parameterValue}
                    onChange={(e) => setParameterValue(e.target.value)}
                    helperText={errorText?.parameters}
                  />
                </Grid>
                <Grid item sm={1}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setproductDetail((pre) => {
                        if (parameterValue) {
                          return { ...pre, parameters: [...pre.parameters, parameterValue] }
                        } else {
                          return pre
                        }
                      })
                      setParameterValue("")
                    }}
                  >
                    <Add />
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box>
                    {parameters &&
                      parameters.length > 0 &&
                      parameters.map((e, key) => {
                        return (
                          e.length !== 0 && (
                            <Chip
                              size="medium"
                              key={key}
                              label={e}
                              clickable
                              onDelete={evt => {
                                const deleteParameterValue = productDetail.parameters.filter((ele, i) => ele !== e)
                                setproductDetail((pre) => ({ ...pre, parameters: deleteParameterValue }))
                              }}
                              color="secondary"
                              variant="outlined"
                            />
                          )
                        );
                      })}
                  </Box>
                </Grid> */}
                {/* keywords */}
                <Grid item xs={12} sm={11}>
                  <AppTextInput
                    fullWidth
                    required
                    name="keywords"
                    variant="outlined"
                    label="Keywords"
                    value={keywords}
                    onChange={onLabelChange}
                    helperText={errorText?.keywords}
                  />
                </Grid>
                <Grid item sm={1}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={onKeywordsAdd}
                  >
                    <Add />
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box>
                    {keywordsArr &&
                      keywordsArr?.length > 0 &&
                      keywordsArr.map((e, key) => {
                        return (
                          e.length !== 0 && (
                            <Chip
                              size="medium"
                              key={e}
                              label={e}
                              clickable
                              onDelete={evt => onKeywordsDelete(evt, e)}
                              color="secondary"
                              variant="outlined"
                            />
                          )
                        );
                      })}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <span
                    className={
                      errorText?.descriptionHTML
                        ? classes?.editorLabelError
                        : ''
                    }
                  >
                    Description
                  </span>
                  <CustomEditor
                    editorData={descriptionHTML || ''}
                    setEditorData={setDescriptionHTML}
                    error={errorText?.descriptionHTML}
                  />
                  <span className={classes?.errorCk}>
                    {errorText?.descriptionHTML}
                  </span>
                </Grid>
              </GridContainer>
            </Box>
            <Box display="flex" justifyContent="flex-end" mb={4}>
              <Button onClick={() => history.push('/manage-productMaterial')}>
                Cancel
              </Button>
              <Box ml={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onSubmitClick}
                >
                  {productmaterial ? 'Update' : 'Save'}
                </Button>
              </Box>
            </Box>
          </CmtCardContent>
        </CmtCard>
      </div>
    </PageContainer>
    //   </DialogContent>
    // </Dialog >
  );
};

export default AddEditProductMaterial;

AddEditProductMaterial.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
