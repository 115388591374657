import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch} from 'react-redux';
import {
  Box,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Hidden,
} from '@material-ui/core';
import {
  Archive,
  Delete,
  Reply,
  Done,
  Cancel,
  Inbox,
  CancelScheduleSend,
  Schedule,
} from '@material-ui/icons';
import { alpha, makeStyles } from '@material-ui/core/styles';

import { folderList } from '@fake-db/apps/mail';
import { emailStatusChange } from 'redux/actions/Manage/Emails/Emails';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';

const useStyles = makeStyles(theme => ({
  borderLeft: {
    width: 1,
    height: 36,
    backgroundColor: alpha(theme.palette.common.dark, 0.12),
    marginLeft: 4,
  },
  iconBtn: {
    [theme.breakpoints.down('sm')]: {
      padding: 6,
      '& .MuiSvgIcon-root': {
        fontSize: 18,
      },
    },
  },
  menuItemsRoot: {
    fontSize: 16,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    '& .MuiSvgIcon-root': {
      display: 'block',
      fontSize: 20,
    },
  },
}));

const HeaderOptions = ({ onChangeMailFolder, onSelectLabel, labelsList, checkedMails, refresh, setRefresh }) => {
  const dispatch = useDispatch()
  const [showLabels, setShowLabels] = useState(null);
  const [showFolders, setShowFolders] = useState(null);
  const classes = useStyles();
  const { filterType } = useSelector(({ mailApp }) => mailApp);
  const [actionName, setActionName] = useState('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleAction = action => {
    setOpenConfirmDialog(true);
    setActionName(action);
  };

  const handleConfirmAction = () => {
    dispatch(emailStatusChange({ ids: checkedMails, status: actionName })).then(
      res => {
        if (res?.status === 200) {
          setOpenConfirmDialog(false);
          setRefresh(!refresh);
        }
      },
    );
  };

  return (
    <React.Fragment>
      {filterType?.selectedFolder === 'inbox' ? (
        <Box ml={1}>
          <Tooltip title="Disapprove">
            <IconButton
              className="icon-btn"
              onClick={() => handleAction('disapprove')}
            >
              <Cancel />
            </IconButton>
          </Tooltip>
        </Box>
      ) : null}

      {filterType?.selectedFolder === 'disapprove' ||
      filterType?.selectedFolder === 'trash' ||
      filterType?.selectedFolder === 'archive' ? (
        <Box ml={1}>
          <Tooltip title="Inbox">
            <IconButton
              className="icon-btn"
              onClick={() => handleAction('inbox')}
            >
              <Inbox />
            </IconButton>
          </Tooltip>
        </Box>
      ) : null}

      {filterType?.selectedFolder === 'disapprove' ||
      filterType?.selectedFolder === 'trash' ||
      filterType?.selectedFolder === 'inbox' ||
      filterType?.selectedFolder === 'approve' ? (
        <Box ml={1}>
          <Tooltip title="Archive">
            <IconButton
              className="icon-btn"
              onClick={() => handleAction('archive')}
            >
              <Archive />
            </IconButton>
          </Tooltip>
        </Box>
      ) : null}

      {filterType?.selectedFolder === 'disapprove' ||
      filterType?.selectedFolder === 'archive' ||
      filterType?.selectedFolder === 'inbox' ||
      filterType?.selectedFolder === 'approve' ? (
        <Box ml={1}>
          <Tooltip title="Trash">
            <IconButton
              className="icon-btn"
              onClick={() => handleAction('trash')}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </Box>
      ) : null}

      {filterType?.selectedFolder === 'scheduled' ? (
        <Box ml={1}>
          <Tooltip title="Cancel Schedule">
            <IconButton
              className="icon-btn"
              onClick={() => handleAction('cancel')}
            >
              <CancelScheduleSend />
            </IconButton>
          </Tooltip>
        </Box>
      ) : null}

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm ${actionName} `}
        content={
          actionName === 'cancel'
            ? 'Are you sure, you want to cancel this schedule email?'
            : actionName === 'send'
            ? 'Are you sure, you want to send this email immediately?'
            : actionName === 'inbox'
            ? 'Are you sure, you want to move this email  to the inbox?'
            : `Are you sure, you want to ${actionName} this email?`
        }
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={handleConfirmAction}
      />
    </React.Fragment>
  );
};

export default HeaderOptions;

HeaderOptions.prototype = {
  onChangeMailFolder: PropTypes.func,
  onSelectLabel: PropTypes.func,
  labelsList: PropTypes.array.isRequired,
};
