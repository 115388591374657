import { FETCH_ERROR, SEND_FORGET_PASSWORD_EMAIL, USER_LOGIN_SUCCESS } from '@jumbo/constants/ActionTypes';
import React from 'react';
import { MSG_ERR_RESPONDING_SERVER } from 'redux/actions/Common/Constant';
import { setError } from 'redux/actions/Common/ErrorAction';
import { setLoading } from 'redux/actions/Common/LoadingAction';
import { setSuccess } from 'redux/actions/Common/SuccessAction';
import AuthService from 'services/auth.service';
import { ErrorCatch } from 'utils/ErrorCatch';
import { setAuthUser, setForgetPassMailSent, updateFailedUser, updateLoadUser, userLoginSuccess, userLogoutSuccess, userSessionExpired } from '../../../redux/actions/Auth';

const BasicAuth = {
  onRegister: ({ name, email, password }) => {
    return dispatch => {
      dispatch(setLoading());
      setTimeout(() => {
        const user = { name: name, email: email, password: password };
        localStorage.setItem('user', JSON.stringify(user));
        // sessionStorage.setItem('user', JSON.stringify(user));
        // dispatch(setSuccess());
        dispatch(setAuthUser(user));
      }, 300);
    };
  },

  onLogin: ({ email, password }) => {
    return dispatch => {
      try {
        dispatch(setLoading());
        setTimeout(async () => {
          const response = await AuthService.login(email, password);
          if (response && response.data) {
            const { data } = response
            if (data && response.status === 200) {
              // sessionStorage.setItem('user', JSON.stringify(data.data));
              // let now = new Date().getTime();
              // localStorage.setItem('setupTime', now);
              const {name, email, permissions, token, userId} = data?.user
              const userData = {name, email, userId}
              localStorage.setItem('user', JSON.stringify(userData));
              localStorage.setItem('permissions', JSON.stringify(permissions));
              localStorage.setItem('auth-token', JSON.stringify(token));
              dispatch(setAuthUser(data.user));
              dispatch(setSuccess(data.message, USER_LOGIN_SUCCESS));
              dispatch(userLoginSuccess(data.message));
            }
            else {
              dispatch(setError(data.message));
            }
          } else
            ErrorCatch(MSG_ERR_RESPONDING_SERVER, FETCH_ERROR, dispatch)
        }, 300);
      } catch (error) {
        ErrorCatch(error, FETCH_ERROR, dispatch)
      }
    };
  },
  onLogout: () => {
    return dispatch => {
      dispatch(setLoading());
      // setTimeout(() => {
      // sessionStorage.removeItem('user');
      // localStorage.removeItem('user');
      localStorage.clear();
      dispatch(setAuthUser(null));
      dispatch(userLogoutSuccess());
      // }, 300);
    };
  },
  onSessionLogout: () => {
    return dispatch => {
      dispatch(setLoading());
      // setTimeout(() => {
      // sessionStorage.removeItem('user');
      // localStorage.removeItem('user');
      localStorage.clear();
      dispatch(setAuthUser(null));
      dispatch(userSessionExpired());
      // }, 300);
    };
  },

  getAuthUser: (loaded = false) => {
    return dispatch => {
      // dispatch(setLoading());
      dispatch(updateLoadUser(loaded));
      setTimeout(() => {
        if (JSON.parse(localStorage.getItem('user'))) {
          dispatch(setAuthUser(JSON.parse(localStorage.getItem('user'))));
          // dispatch(setSuccess(JSON.parse(localStorage.getItem('user'))));
        }
        else {
          dispatch(updateFailedUser("Invalid Request!"));
          // dispatch(setError("Invalid Request!"))
        }
      }, 300);
    };
  },
  // forgotPassword: (email, callbackFun) => {
  //   async (dispatch) => {
  //     dispatch(setLoading())
  //     try {
  //       const { data } = await AuthService.forgetPassword(email);
  //       if (data.statusCode === 200) {
  //         dispatch(setForgetPassMailSent(data.data));
  //         setSuccess(data.responseMsg, SEND_FORGET_PASSWORD_EMAIL)
  //         if (callbackFun) callbackFun(data.data.userDetail);
  //       }
  //       else {
  //         dispatch(setError(data.responseMsg));
  //       }
  //     } catch (error) {
  //       ErrorCatch(error, FETCH_ERROR, dispatch)
  //     }
  //   }
  // },
  onForgotPassword: (email) => {
    return dispatch => {
      try {
        dispatch(setLoading());
        setTimeout(async () => {
          const response = await AuthService.forgetPassword(email);
          if (response && response.data) {
            const { data } = response
            if (data && data.statusCode === 200) {
              dispatch(setForgetPassMailSent(data.data));
              setSuccess(data.responseMsg, SEND_FORGET_PASSWORD_EMAIL)
            }
            else {
              dispatch(setError(data.responseMsg));
            }
          } else
            ErrorCatch(MSG_ERR_RESPONDING_SERVER, FETCH_ERROR, dispatch)
        }, 300);
      } catch (error) {
        ErrorCatch(error, FETCH_ERROR, dispatch)
      }
    };
  },
  // onRefreshToken: (token) => {
  //   return dispatch => {
  //     try {
  //       dispatch(setLoading());
  //       setTimeout(async () => {
  //         const response = await AuthService.refreshAccessToken(token);
  //          if (data&&data.statusCode === 200) {
  //           dispatch(setRefreshToken(data.data.token));
  //         }
  //         else {
  //           dispatch(setError(data.responseMsg));
  //         }
  //       }, 300);
  //     } catch (error) {
  //       log.error(error.message)
  //       dispatch(setError(error.message));
  //     }
  //   };
  // },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default BasicAuth;
