import { VENDORS_LIST_SUCCESS, VENDOR_CREATE_SUCCESS, VENDOR_DELETE_SUCCESS, VENDOR_UPDATE_SUCCESS, SET_VENDOR_DETAILS } from "redux/actions/index";

const INIT_STATE =
{
    vendors: [],
    vendor: null,
    total: 0,
    perPage: 0
    // permissions: null,
}
const INIT_STATE_DETAIL =
{
    vendor: null,
}


export const VendorsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case VENDOR_CREATE_SUCCESS: {
            return {
                ...state,
                vendor: action.data,
                action: action.type,
                message: action.message,
                vendors: [action.data, ...state.vendors]
            };
        }
        case VENDORS_LIST_SUCCESS: {
            return {
                ...state,
                vendors: action.data,
                action: action.type,
                total: action.total,
                message: action.message,
                perPage: action.perPage
            };
        }
        //users: state.users.map(user => (user.id === action.payload.id ? action.payload : user))
        case VENDOR_UPDATE_SUCCESS: {
            return {
                ...state,
                vendor: action.data,
                action: action.type,
                message: action.message,
                vendors: state.vendors.map(vendor => (vendor._id === action.data._id ? action.data : vendor))
            };
        }
        case VENDOR_DELETE_SUCCESS: {
            return {
                ...state,
                vendor: action.data,
                action: action.type,
                message: action.message,
                vendors: state.vendors.filter(vendor => vendor._id !== action.data._id),
            };
        }
        // case DELETE_BULK_USERS_SUCCESS: {
        //     return {...state,
        //         ...state,
        //         action: action.type,
        //         message: action.message,
        //         users: state.users.filter(user => !action.data.includes(user.id)),
        //     };
        // }
        default:
            return state;
    }
};
export const SetVendorDetailReducer = (state = INIT_STATE_DETAIL, action) => {
    switch (action.type) {
        case SET_VENDOR_DETAILS:
            return {
                ...state,
                vendor: action.data,
                action: action.type,
            };
        default:
            return state;
    }
};