import Axios from "axios";
import { MSG_ERR_RESPONDING_SERVER } from "redux/actions/Common/Constant";
import { ErrorCatch } from "utils/ErrorCatch";
import { setError } from "../Common/ErrorAction";
import { setLoading } from "../Common/LoadingAction";
import { setSuccess } from "../Common/SuccessAction";
import { FILE_UPLOAD_URL, IMAGE_UPLOAD_FAILED, IMAGE_UPLOAD_REQUEST, IMAGE_UPLOAD_SUCCESS, SET_CUSTOMER_DETAILS } from "./Constant";
let RES_MSG = MSG_ERR_RESPONDING_SERVER

export const setCustomerDetail = customer => {
    return dispatch => {
        dispatch({
            type: SET_CUSTOMER_DETAILS,
            data: customer,
        });
    };
};
//PAYLOAD
// {uploadedFile:  (binary)
//  fileName:  dummy-profile-pic.jpg
//  fileCategory: log
// }
export const uploadFile = (payload, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(IMAGE_UPLOAD_REQUEST))
        try {
            const { data } = await Axios.post(`${FILE_UPLOAD_URL}`, payload)
            if (data.statusCode === 200) {
                dispatch(setSuccess(data.responseMsg, IMAGE_UPLOAD_SUCCESS))
                dispatch({
                    type: IMAGE_UPLOAD_SUCCESS,
                    data: data.data,
                    message: data.message,
                });
                if (callbackFun) callbackFun(data.data);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, IMAGE_UPLOAD_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, IMAGE_UPLOAD_FAILED, dispatch)
        }
    };
