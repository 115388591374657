import React, { useEffect, useState } from 'react';

import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from 'draft-js';

import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

let Editor = () => <React.Fragment />;

const WysiwygEditorExample = ({
  setDescriptionHTML,
  descriptionHTML,
  defaultDescription,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorDefaultState, setEditorDefaultState] = useState(
    EditorState.createEmpty(),
  );
  // useEffect(() => {
  //     Editor = require('react-draft-wysiwyg').Editor;
  //     setEditorState(EditorState.createEmpty());
  // }, []);
  // useEffect(() => {
  //     const blocksFromHTML = convertFromHTML(defaultDescription)
  //     const content = ContentState.createFromBlockArray(blocksFromHTML)
  //     setEditorState(EditorState.createWithContent(content))
  //     // setEditorDefaultState(EditorState.createWithContent(content))
  // }, [defaultDescription])
  useEffect(() => {
    if (defaultDescription) {
      const blocksFromHTML = convertFromHTML(defaultDescription);
      const content = ContentState.createFromBlockArray(blocksFromHTML);

      setEditorState(EditorState.createWithContent(content));
    } else {
      Editor = require('react-draft-wysiwyg').Editor;
      setEditorState(EditorState.createEmpty());
    }

    // setEditorDefaultState(EditorState.createWithContent(content))
  }, [defaultDescription]);


  return (
    <React.Fragment>
      <Editor
        editorStyle={{
          width: '100%',
          // minHeight: 100,
          minHeight: 200,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'lightgray',
        }}
        // editorState={editorDefaultState}

        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={editorState => {
          // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())))
          setEditorState(editorState);
          setDescriptionHTML(
            draftToHtml(convertToRaw(editorState.getCurrentContent())),
          );
        }}
      />
      {/* <textarea


                style={{ width: '100%', height: 200 }}
                disabled
                value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
            /> */}
    </React.Fragment>
  );
};

export default WysiwygEditorExample;
