import CmtDropdownMenu from "@coremat/CmtDropdownMenu";
import { Switch } from "@material-ui/core";
import { Edit, MoreHoriz } from "@material-ui/icons";
import CustomTable from "components/CustomeTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { editBlockEmail, fetchAllBlockEmail } from "redux/actions/Manage/EmailBlock/EmailBlock";

const ListBlockEmail = () => {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = React.useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();
  const history = useHistory()
  const emailBlock = useSelector((state) => state?.emailBlock);

  const onUserMenuClick = (menu, elm) => {
    if (menu.action === 'edit') {
        history.push(`/manage-edit-block-email/${elm?._id}`)
    }
  };

  const handleAddButton = function(){
    history.push('/manage-block-email')
  }

  const handleRequestSort = (event, property) => {
    const isAsc = order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeStatusSwitch = async (elm) => {
    let status;
    if (elm.isBlock === true) status = false;
    else status = true;
    dispatch(editBlockEmail(elm?._id,{
        isBlock:status
    })).then(()=>{
        dispatch(fetchAllBlockEmail({
          search: searchTerm,
          page: page + 1
        }));
    })
  };

  const getUserActions = user => {
    const actions = [];
    actions.push({ action: 'edit', label: 'Edit', icon: <Edit /> });
    return actions;
  };

  const userActions = getUserActions();


  const headCells = [
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'ID',
    },
    {
      id: 'blockType',
      numeric: false,
      disablePadding: false,
      label: 'Id Type',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Blocked Status',
    },
    {
      id: 'isBlock',
      numeric: false,
      disablePadding: false,
      sort: false,
      label: 'Blocked',
      render: elm => (
        <Switch
          // defaultChecked={elm.showInMiddle === true ? true : false}
          checked={elm.isBlock === true ? true : false}
          onChange={() => handleChangeStatusSwitch(elm)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      ),
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Actions',
      sort: false,
      render: elm => (
        <>
          {/* {!updatePermission && !deletePermission ? '-' : <CmtDropdownMenu items={userActions} onItemClick={(menu) => onUserMenuClick(menu, elm)} TriggerComponent={<MoreHoriz />} />} */}
          {
            <CmtDropdownMenu
              items={userActions}
              onItemClick={menu => onUserMenuClick(menu, elm)}
              TriggerComponent={<MoreHoriz />}
            />
          }
        </>
      ),
    },
  ]

  useEffect(() => {
    dispatch(fetchAllBlockEmail({
      search: searchTerm,
      page: page + 1
    }))
  }, [searchTerm, page])

  return (
    <>
      <CustomTable
        TableName="Blocked Email"
        addButtonText={'Block Email'}
        handleAddButton={handleAddButton}
        row={emailBlock?.blockedEmails}
        headCells={headCells}
        isDynamic={true}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        count={emailBlock?.total || 0}
        rowsPerPage={perPage}
        onPageChange={handlePageChange}
        page={page}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleSearch={(text) => setSearchTerm(text)}
        handleSearchSubmit={() => {
          setPage(0)
        }}
      />
    </>
  )
}

export default ListBlockEmail