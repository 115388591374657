import {
  MATERIAL_GRADE_LIST_BY_ID_FAILED,
  MATERIAL_GRADE_LIST_BY_ID_REQUEST,
  MATERIAL_GRADE_LIST_BY_ID_SUCCESS,
  MATERIAL_GRADE_LIST_SUCCESS,
  MATERIAL_GRADE_STATUS_UPDATE_SUCCESS,
  MATERIAL_GRADE_UPDATE_SUCCESS
} from 'redux/actions/Manage/MaterialGrade/Constant';

const INIT_STATE = {
  materialGrades: [],
  singleMaterialGrade: null,
  total: 0,
  perPage: 0,
};

export const MaterialGradeReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case MATERIAL_GRADE_LIST_SUCCESS: {
      return {
        ...state,
        materialGrades: action.data,
        total: action.total,
        action: action.type,
        message: action.message,
        perPage: action.perPage,
      };
    }
    case MATERIAL_GRADE_STATUS_UPDATE_SUCCESS: {
      return {
        ...state,
        materialGrades: action.data,
        action: action.type,
        message: action.message,
      };
    }
    case MATERIAL_GRADE_UPDATE_SUCCESS: {
      return {
        ...state,
        materialGrades: action.data,
        action: action.type,
        message: action.message,
      };
    }
    case MATERIAL_GRADE_LIST_BY_ID_REQUEST: {
      return {
        ...state,
        singleMaterialGrade: null,
      };
    }
    case MATERIAL_GRADE_LIST_BY_ID_SUCCESS: {
      return {
        ...state,
        singleMaterialGrade: action.data,
      };
    }
    case MATERIAL_GRADE_LIST_BY_ID_FAILED: {
      return {
        ...state,
        singleMaterialGrade: null,
      };
    }
    default:
      return state;
  }
};
