import CmtCardMedia from '@coremat/CmtCard/CmtCardMedia';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import { Box, Grid, makeStyles, Switch } from '@material-ui/core';
import { Add, Edit, FilterList, Link, MoreHoriz, Visibility } from '@material-ui/icons';
import CustomTable from 'components/CustomeTable';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import config from 'utils/Config.util';
import { PRODUCT_CREATE_SUCCESS, PRODUCT_MATERIAL_CREATE_SUCCESS, PRODUCT_UPDATE_SUCCESS } from 'redux/actions';
import { deleteProduct, getAllProducts, setProductDetail, setProductElmDetail, updateProduct } from 'redux/actions/Manage/Products/Products';
import { SUBPRODUCT_CREATE_SUCCESS, SUBPRODUCT_LIST_SUCCESS, SUBPRODUCT_UPDATE_SUCCESS } from 'redux/actions/Manage/SubProduct/Constant';
import { getAllSubProducts, setSubProductDetail, setSubProductElmDetail, updateSubProduct } from 'redux/actions/Manage/SubProduct/SubProduct';
import { fetchProductMaterialGradeList } from 'redux/actions/Manage/Suppliers/Suppliers';
import AddEditSubProduct from './AddEditProduct';
import ViewSubProductDetails from './ViewSubProductDetails';
// import AddEditProduct from './AddEditProduct';
// import AddMaterialToProduct from './AddMaterialToProduct';
// import ViewMaterialList from './VIewMaterialLIst';
// import ViewProductDetails from './ViewProductDetails';
const useStyles = makeStyles(theme => ({
    imageRoot: {
        // width: 150,
        // height: 150,
        width: 100,
        height: 100,
        objectFit: 'cover',
        objectPosition: 'center',
        padding: 0,
      },
}));
const SubProducts = ({ history }) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    //------------all use selectors
    const { user } = useSelector(({ auth }) => auth.authUser);
    const { subproducts, total, perPage } = useSelector((state) => state.SubProducts);
    const { products} = useSelector((state) => state.SetProductMaterialGradeList);

    const ProductData = useSelector((state) => state.setProductDetail);
    const productDetail = ProductData?.user
    // console.log("subProducts",subproducts,total,perPage)
    // console.log("products",products)
    //-------------all use states
    const [orderBy, setOrderBy] = React.useState('name');
    const [order, setOrder] = React.useState('asc');
    const [page, setPage] = React.useState(0);
    const [searchTerm, setSearchTerm] = useState('')
    const [selectedUser, setSelectedUser] = useState({ name: '' });
    const [productsFetched, setproductsFetched] = useState(false);
    const [openUserDialog, setOpenUserDialog] = useState(false);
    const [row, setRow] = useState([]);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [permissionData] = useState(JSON.parse(localStorage.getItem('permission')));
    const [createPermission, setCreatePermission] = useState(false);
    const [updatePermission, setUpdatePermission] = useState(false);
    const [deletePermission, setDeletePermission] = useState(false);
    const [exportPermission, setExportPermission] = useState(false);
    const [openViewDialog, setOpenViewDialog] = useState(false);
    const [openViewSubProductDetails, setOpenViewSubProductDetails] = useState(false);
    const [openViewMaterialList, setopenViewMaterialList] = useState(false);
    const [openViewMaterialAdd, setOpenViewMaterialAdd] = useState(false);
    // const [search, setSearch] = useState({ sort: null, field: null });
    const [fileData, setFileData] = useState([]);
    const [dataUpdated, setDataUpdated] = useState(false);
    const [downloadData, setDownloadData] = useState(false);
    const [isDownload, setIsDownload] = useState(false)
    const [newproductId,setnewProductId]=useState(1);
    const [productName,setProductName]=useState("Pipes");
    const successAction = useSelector(state => state.Success.action)

    // const [sort, setSort] = useState({
    //     orderBy: '',
    //     order: 'asc',
    // });

    //-------table config actions
    const getUserActions = user => {
        const actions = [

        ];
        actions.push({ action: 'view', label: 'View', icon: <Visibility /> })
        actions.push({ action: 'url', label: 'View Live', icon: <Link /> });
        actions.push({ action: 'edit', label: 'Edit', icon: <Edit /> })
        // actions.push({ action: 'list_material', label: 'Material List', icon: <FilterList /> })
        // actions.push({ action: 'add_material', label: 'Add Material', icon: <Add /> })
        return actions;
    };
    const userActions = getUserActions(row);
    const onUserMenuClick = (menu, elm) => {
        if (menu.action === 'view') {
            dispatch(setSubProductElmDetail(elm));
            setOpenViewSubProductDetails(true)
        } else if (menu.action === 'add_material') {
            dispatch(setSubProductElmDetail(elm));
            setOpenViewMaterialAdd(true)
            // onUserChangePassword(row);
        } else if (menu.action === 'edit') {
            // setOpenUserDialog(true)
            history.push(`/manage-add-subproduct/${elm._id}`)
        } else if (menu.action === 'list_material') {
            dispatch(setSubProductElmDetail(elm));
            setopenViewMaterialList(true)
        }
        else if (menu.action === 'url') {
            // window.open(`https://www.thepipingmart.com/products/${elm?.url}`);
            window.open( config.REACT_APP_API_BASE_URL === 'https://api.pipingmart.co.in' ? `http://pipingmart.co.in/products/${elm?.url}` : `https://www.thepipingmart.com/products/${elm?.url}`)
          }
        // } else if (menu.action === 'delete') {
        //     setSelectedUser(elm);
        //     setOpenConfirmDialog(true)
        // }
    };
    const headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: 'Name',
        },
        {
            id: 'logo',
            numeric: false,
            disablePadding: false,
            label: 'Image',
            sort: false,
            render: elm => (
              <Box>
                <CmtCardMedia className={classes.imageRoot} image={elm?.logo} />
              </Box>
            ),
          },
       
        {
            id: 'title',
            numeric: false,
            disablePadding: false,
            label: 'Title',
            sort: false,
        },
    
        {
            id: 'active',
            numeric: false,
            disablePadding: false,
            sort: false,
            label: 'Status',
            render: (elm) => (
                < Switch
                    // defaultChecked={elm.showInMiddle === true ? true : false}
                    checked={elm.active === true ? true : false}
                    onChange={() => handleChangeStatusSwitch(elm)}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            )
        },
        {
            id: 'actions',
            numeric: false,
            disablePadding: false,
            label: 'Actions',
            sort: false,
            render: elm => (
                <>
                    {/* {!updatePermission && !deletePermission ? '-' : <CmtDropdownMenu items={userActions} onItemClick={(menu) => onUserMenuClick(menu, elm)} TriggerComponent={<MoreHoriz />} />} */}
                    {<CmtDropdownMenu items={userActions} onItemClick={(menu) => onUserMenuClick(menu, elm)} TriggerComponent={<MoreHoriz />} />}
                </>
            ),
        },
    ];
    //download file setting
    var fileHeaders = [
        {
            key: 'firstName',
            label: 'Name',
        },
        {
            key: 'email',
            label: 'Email'
        },
        {
            key: 'roleName',
            label: 'Role'
        },
        {
            key: 'departmentName',
            label: 'Dept'
        },
        {
            key: 'status',
            label: 'Status'
        },
    ];

    //-------all handle functions
    const handleChangeStatusSwitch = (elm) => {
        let status
        if (elm.active === true)
        status = "false"
        else
        status = "true"
        const data = {
            ...elm,
            active: status,
        }
        dispatch(
            updateSubProduct(data, (data) => {
              if (data)
                history.push('/manage-subproducts')
            }),
          );
    };
    const handleChangeSwitch = (elm) => {
        let showInMiddle
        if (elm.showInMiddle === true)
            showInMiddle = "false"
        else
            showInMiddle = "true"
        const data = {
            "productId": elm.productId,
            "showInMiddle": showInMiddle,
            "idOfProductCreator": user?.userId,
            "nameOfProductCreator": user?.name
        }
        dispatch(
            updateProduct(data, (data) => {
                if (data)
                    history.push('/manage-subproducts')
            }),
        );
    };
    const handleCloseViewMaterialList = () => {
        setopenViewMaterialList(false);
        dispatch(setProductDetail(null));
    };
    const handleCloseViewMaterialAdd = () => {
        setOpenViewMaterialAdd(false);
        dispatch(setProductDetail(null));
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrderBy(property);
        setOrder(isAsc ? 'desc' : 'asc');
    };
    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };
    const handleViewDialog = user => {
        dispatch(setProductDetail(user));
        setOpenViewDialog(true);
    };
    const handleCloseViewDialog = () => {
        setOpenViewDialog(false);
        dispatch(setProductDetail(null));
    };
    const handleCloseUserDialog = () => {
        setOpenUserDialog(false);
        dispatch(setProductElmDetail(null));
    };
    const handleAddButton = () => {
        setOpenUserDialog(true);
        dispatch(setSubProductElmDetail(null));
        history.push('/manage-add-subproduct')
    };
    const handleCancelDelete = () => {
        setOpenConfirmDialog(false);
    };
    const handleConfirmDelete = () => {
        setOpenConfirmDialog(false);
        dispatch(deleteProduct(selectedUser._id, (data) => {
            if (data)
                setDataUpdated((data) => !data)
        }));
    };
    const handleCloseViewProductDetails = () => {
        setOpenViewSubProductDetails(false);
        dispatch(setProductElmDetail(null));
    };
    // const handleSearch = dateset_name => {
    //     setPage(0);
    //     setSearchTerm(dateset_name);
    // };
    const onLabelChange=(evt)=>{
        let productID;
        // console.log(evt.target)
        for (let i = 0; i < products.length; i++){
          // console.log(products[i].name===evt.target.value)
            if(products[i].name ===evt.target.value){
                 productID=products[i].productId
                //  return;
             }
        } 
        // console.log(productID)
          setnewProductId(productID)
          setProductName(evt.target.value)
    }
    // const downloadFn = async (params) => {
    //     dispatch(getAllProducts(params, (data) => {
    //         if (data) {
    //             let fileData = []
    //             data.forEach((item) => {
    //                 fileData.push({
    //                     firstName: item.firstName + " " + item.lastName,
    //                     email: item.email,
    //                     roleName: item.roleId?.roleName,
    //                     departmentName: item.roleId?.departmentId?.departmentName,
    //                     status: item.status === 1 ? 'Active' : 'Inactive',
    //                 });
    //             });
    //             setFileData(fileData)
    //         }
    //     }, true))
    // }
    //?to check permission function
    // const getPermission = useCallback(() => {
    //     permissionData &&
    //         permissionData.forEach(function (num) {
    //             if (user?.roleId?.roleSlug === 'admin' || num.name === 'user-create') {
    //                 setCreatePermission(true);
    //             }
    //             if (user?.roleId?.roleSlug === 'admin' || num.name === 'user-delete') {
    //                 setDeletePermission(true);
    //             }
    //             if (user?.roleId?.roleSlug === 'admin' || num.name === 'user-update') {
    //                 setUpdatePermission(true);
    //             }
    //             if (user?.roleId?.roleSlug === 'admin' || num.name === 'user-export') {
    //                 setExportPermission(true);
    //             }
    //         });
    // }, [permissionData, user]);

    //------all useEffects
    useEffect(() => {
        let params
        params = {
            search: searchTerm
        }
        // if (search) {
        //     params = {
        //         ...params,
        //         ...search
        //     };
        // }
        // if (products.length >= 0)
            dispatch(getAllSubProducts(newproductId,params))
            // dispatch(getAllProducts(params))
            
        // getPermission()
    }, [dispatch, dataUpdated,newproductId])

    // useEffect(() => { //to download data 
    //     let params
    //     params = {
    //         search: searchTerm
    //     }
    //     // if (search) {
    //     //     params = {
    //     //         ...params,
    //     //         ...search
    //     //     };
    //     // }
    //     downloadFn(params)
    // }, [isDownload])

    // useEffect(() => {
    //     setDownloadData(data => !data) //update state at custom toolbar component
    // }, [fileData])
    useEffect(()=>{
      dispatch(fetchProductMaterialGradeList())
    },[])
    useEffect(() => {
        if (successAction === SUBPRODUCT_UPDATE_SUCCESS || successAction === SUBPRODUCT_CREATE_SUCCESS || successAction === SUBPRODUCT_LIST_SUCCESS) {
            let params
            params = {
                search: searchTerm
            }
            // if (search) {
            //     params = {
            //         ...params,
            //         ...search
            //     };
            // }
            dispatch(getAllSubProducts(newproductId,params))
            
        }
    }, [successAction])
    return (
        <>
         <div style={{ marginBottom:"20px",width:"300px"}}>
         <AppSelectBox 
                  name='name'
                  variant="outlined"
                  label="Select Product"
                    value={productName}
                  //   onChange={onLabelChange1}
                  // helperText={errorText?.name}
                //   fullWidth
                  data={products && products}
                  valueKey="name"
                  labelKey="name"
                  // value={status}
                  onChange={onLabelChange}
                  //   helperText={errorText?.Pname}
                  />
        </div>
            <CustomTable
                TableName="subproducts"
                downloadTableBtn={exportPermission ? true : false}
                fileName="subproducts.xlsx"
                fileHeaders={fileHeaders}
                fileData={fileData}
                setIsDownload={setIsDownload}
                downloadData={downloadData}
                row={subproducts && subproducts}
                // addButtonText={createPermission ? "Add User" : ""}
                addButtonText={"Add SubProduct"}
                handleAddButton={handleAddButton}
                headCells={headCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                count={total}
                rowsPerPage={perPage}
                onPageChange={handlePageChange}
                page={page}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                // handleSearch={handleSearch}
                handleSearch={(text)=> setSearchTerm(text)}
                handleSearchSubmit={()=>{
                  setPage(0)
                  setDataUpdated(!dataUpdated)}}
                handleViewDialog={handleViewDialog}
                productDetailModal={
                    {
                        emailData: productDetail?.email,
                        modalHeading: productDetail?.name,
                        headerArray: ["MemberType: " + productDetail?.membertype, productDetail?.address && "Address: " + productDetail?.address],
                        sectionHeading: "Contact Details",
                        openViewDialog: openViewDialog,
                        handleCloseViewDialog: handleCloseViewDialog,
                        activeChip: productDetail?.active,
                        phoneData: productDetail?.mobileNo,
                    }}
            />
            {openUserDialog && <AddEditSubProduct setDataUpdated={setDataUpdated} open={openUserDialog} onCloseDialog={handleCloseUserDialog} />}
            {openViewSubProductDetails && <ViewSubProductDetails open={openViewSubProductDetails} onCloseDialog={handleCloseViewProductDetails} />}
            {/* {openViewMaterialList && <ViewMaterialList open={openViewMaterialList} onCloseDialog={handleCloseViewMaterialList} />} */}
            {/* {openViewMaterialAdd && <AddMaterialToProduct setDataUpdated={setDataUpdated} history={history} open={openViewMaterialAdd} onCloseDialog={handleCloseViewMaterialAdd} />} */}
            <ConfirmDialog
                open={openConfirmDialog}
                title={`Confirm delete`}
                content={'Are you sure, you want to  delete this user?'}
                onClose={handleCancelDelete}
                onConfirm={handleConfirmDelete}
            />
        </>
    )
}

export default SubProducts