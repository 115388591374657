import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuList,
  MenuItem,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import GridContainer from '@jumbo/components/GridContainer';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';

import {
  fetchCategoryBySearch,
  fetchEmailList,
  fetchSupplierByPlan,
  fetchSUpplierByPlan,
  setEmailListPopUp,
} from 'redux/actions/Manage/Emails/Emails';
import { getAllMaterials } from 'redux/actions/Manage/Materials/Materials';
import { getAllProducts } from 'redux/actions/Manage/Products/Products';
import { fetchAllGrades } from 'redux/actions/Manage/ProductMaterialGrades';
import CmtSearch from '@coremat/CmtSearch';
import { Search } from '@material-ui/icons';
import UpdateEmailLimit from '../UpdateEmailLimit';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  noDataFound: {
    display: 'flex',
    justifyContent: 'center',
    background: '#EBECF0',
    height: '40px',
    alignItems: 'center',
    borderRadius: '4px',
  },
}));
const labels = [
  // { title: 'Deleted', code: 0 },
  { title: 'Active', code: 1 },
  { title: 'Disable', code: 2 },
];
const EmailListPopUp = ({ open, onCloseDialog, setDataUpdated }) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(open);
  const { pop_up } = useSelector(state => state.Emails);
  const { materials } = useSelector(state => state.Materials);
  const { products } = useSelector(state => state.Products);
  const { grades } = useSelector(state => state.Grades);
  const { selectedMail, categoryList } = useSelector(({ Emails }) => Emails);

  const [planDetail, setPlanDetail] = useState({
    planType: false,
    count: '',
  });
  const [categoryDetail, setCategoryDetail] = useState({
    productId: '',
    materialId: '',
    gradeId:''
  });
  // const [gradeId, setGradeId]= useState('')
  const [valuePlan, setValuePlan] = useState('all');
  const [valueCount, setValueCount] = useState('all');
  const [emailList, setEmailList] = useState([]);
  const [newEmailList, setNewEmailList] = useState([]);
  const [supplierArr, setSupplierArr] = useState([]);
  const [productArr, setProductArr] = useState([]);
  const [materialArr, setMaterialArr] = useState([]);
  const [gradeArr, setGradeArr] = useState([]);
  const [emailManual, setEmailManual] = useState('');
  const [customValue, setCustomValue] = useState('');
  const [checkedFree, setCheckedFree] = useState(false);
  const [checkedPre, setCheckedPre] = useState(false);
  const dispatch = useDispatch();
  const [errorText, setErrorText] = useState({});
  const [searchText, setSearchText] = useState('');
  const [categoryListArr, setCategoryListArr] = useState([]);
  const [dataCategory, setDataCategory] = useState({});
  const [openCat, setOpenCat] = useState(false);
  const [isGetEmails, setIsGetEmails] = useState(false);
  const [openUpdateEmailLimitPopUp, setOpenUpdateEmailLimitPopUp] = useState(
    false,
  );
  const [updatableEmail, setUpdatableEmail] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const onCategoryChange = evt => {
    setOpenCat(false);
    setSearchText('');
    if (evt.target.name === 'productId' || evt.target.name === 'materialId'){
      setCategoryDetail({
        ...categoryDetail,
        [evt.target.name]: evt.target.value,
        gradeId: '',
      });
      // setGradeId('')
    } 
    else
      setCategoryDetail({
        ...categoryDetail,
        [evt.target.name]: evt.target.value,
      });
  };

  const validate = () => {
    const { productId, materialId } = categoryDetail;
    const { planType } = planDetail;
    const errors = {};
    let isError = false;
    if (!productId) {
      errors.productId = 'Please select product.';
      isError = true;
    }
    if (customValue === '0') {
      errors.custom = 'Please enter value > 0.';
      isError = true;
    }
    if (!materialId) {
      errors.materialId = 'Please select material.';
      isError = true;
    }
    // if (productId && materialId && gradeArr && gradeArr.length === 0) {
    //   errors.gradeId = 'No Grade Found.';
    //   isError = true;
    // }
    // if (!gradeId && gradeArr && gradeArr.length !== 0) {
    //   errors.gradeId = 'Please select grade.';
    //   isError = true;
    // }
    if (checkedFree === false && checkedPre === false) {
      errors.planError = 'Please select plan.';
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };

  const onSubmitClick = () => {
    const isValidate = validate();
    if (!isValidate.isError) onPlanSave();
  };

  const onPlanSave = () => {
    const planDetail = {
      // planType: valuePlan,
      free: checkedFree === true ? true : false,
      premium: checkedPre === true ? true : false,
      count: valueCount === 'custom' ? customValue : '',
      productId: categoryDetail.productId,
      materialId: categoryDetail.materialId,
      // gradeId: categoryDetail.gradeId,
      gradeId: gradeId,
    };
    dispatch(
      fetchSupplierByPlan(planDetail, () => {
        // setEmailList([])
      }),
    );
  };

  const handleVerifyEmailLimit = () => {
    dispatch(fetchEmailList({ email: emailList })).then(res => {
      if (res?.status === 200) {
        const { findExistingEmailLimit } = res?.data?.data;
        setUpdatableEmail(findExistingEmailLimit);
        if (findExistingEmailLimit.length) {
          setOpenConfirmDialog(true);
          setOpenDialog(false);
        } else {
          dispatch(
            setEmailListPopUp({ selectedEmails: emailList }, () => {
              onCloseDialog();
            }),
          );
        }
      }
    });
  };

  const handleSearchText = e => {
    setSearchText(e.target.value);
    dispatch(fetchCategoryBySearch({ search: e.target.value })).then(res => {
      if (res?.status === 200) {
        setCategoryListArr(res?.data?.data);
        if (!openCat) {
          setOpenCat(true);
        }
      }
    });
  };

  const handleSetCategorySearch = data => {
    setSearchText(data.name);
    setDataCategory(data);
    setOpenCat(false);
    setCategoryDetail({
      productId: +data?.productId,
      materialId: +data?.materialId,
      gradeId: +data?.gradeId,
    });
    // setGradeId(+data?.gradeId)
  };

  const onCloseConfirmModal = () => {
    setOpenConfirmDialog(false);
    setOpenDialog(true);
  };

  useEffect(() => {
    let dataArr = [];
    let newDataArr = [];
    if (pop_up) {
      pop_up &&
        pop_up.emailList &&
        pop_up.emailList.length > 0 &&
        pop_up.emailList.map(data => {
          dataArr = [...dataArr, data.email];
          newDataArr = [
            ...newDataArr,
            {
              email: data.email,
              currentEmailSent: data.emailPlan.currentEmailSent,
            },
          ];
        });
      if (valueCount !== 'manual') {
        setEmailList(dataArr);
        setNewEmailList(newDataArr);
        setSupplierArr([]);
      } else {
        setSupplierArr(pop_up.emailList);
        setEmailList([]);
        setNewEmailList([]);
      }
    }
  }, [pop_up]);

  useEffect(() => {
    if (materials) {
      setMaterialArr(materials);
      // categoryDetail.gradeId = ""
    }
    if (products) {
      setProductArr(products);
      // categoryDetail.gradeId = ""
    }
    if (grades) {
      setGradeArr(grades);
    }
  }, [materialArr, materials, products, productArr, gradeArr, grades]);

  
  useEffect(() => {
    dispatch(getAllMaterials());
    dispatch(getAllProducts());
  }, []);
  
  useEffect(() => {
    if (materialId && productId) {
      dispatch(fetchAllGrades(categoryDetail));
    }
  }, [categoryDetail]);

  useEffect(() => {
    if (selectedMail) {
      const { productInfo } = selectedMail;
      setCategoryDetail({
        productId: productInfo?.productId,
        materialId: productInfo?.materialId,
        gradeId: productInfo?.gradeId,
      }); 
      // setGradeId(productInfo?.gradeId)
    }
  }, [selectedMail]);

  const { productId, materialId, gradeId } = categoryDetail;
  return (
    <>
      <Dialog
        open={openDialog}
        onClose={onCloseDialog}
        className={classes.dialogRoot}
      >
        <DialogTitle className={classes.dialogTitleRoot}>
          {'Add Suppliers'}
        </DialogTitle>
        <DialogContent dividers>
          <Box
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            alignItems="center"
            mb={{ xs: 6, md: 5 }}
          >
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Select Category :
                  </FormLabel>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="Search Category..."
                  sx={{ m: 1, width: '25ch' }}
                  onChange={handleSearchText}
                  value={searchText}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
                {openCat && (
                  <MenuList>
                    {categoryListArr &&
                      categoryListArr.map((e, key) => (
                        <MenuItem
                          key={key}
                          onClick={() => handleSetCategorySearch(e)}
                        >
                          {e.displayName || e.name}
                        </MenuItem>
                      ))}
                  </MenuList>
                )}
              </Grid>
              {selectedMail?.productInfo?.productName &&
                 <GridContainer>
                  <Grid item sx={12} md={2}><Typography className='pl-2' variant="subtitle2" gutterBottom>Product :</Typography></Grid>
                  <Grid item sx={12} md={10}><Typography variant="body1" gutterBottom>{selectedMail?.productInfo?.productName}</Typography></Grid>
                 </GridContainer>
              }
              <Grid item xs={4} sm={4}>
                <AppSelectBox
                  required
                  data={productArr}
                  name="productId"
                  label="Product"
                  valueKey="productId"
                  variant="outlined"
                  labelKey="name"
                  value={productId}
                  displayName="name"
                  disabled={!openCat && searchText}
                  onChange={e => onCategoryChange(e)}
                  helperText={errorText?.productId}
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <AppSelectBox
                  required
                  data={materialArr}
                  name="materialId"
                  label="Material"
                  valueKey="materialId"
                  variant="outlined"
                  labelKey="name"
                  value={materialId}
                  displayName="name"
                  disabled={!openCat && searchText}
                  onChange={e => onCategoryChange(e)}
                  helperText={errorText?.materialId}
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <AppSelectBox
                  disabled={!materialId && !productId}
                  // required
                  data={gradeArr}
                  name="gradeId"
                  label="Grade"
                  valueKey="gradeId"
                  variant="outlined"
                  labelKey="name"
                  value={gradeId}
                  displayName="name"
                  onChange={e => onCategoryChange(e)}
                  // helperText={errorText?.gradeId}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Select Plan :
                </FormLabel>
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControlLabel
                  label="Free"
                  control={
                    <Checkbox
                      name="checkedFree"
                      helperText={errorText?.checkedFree}
                      checked={checkedFree}
                      onChange={() => setCheckedFree(data => !data)}
                    />
                  }
                />
                <FormControlLabel
                  label="Premium"
                  helperText={errorText?.planType}
                  name="planType"
                  control={
                    <Checkbox
                      checked={checkedPre}
                      onChange={() => {
                        setCheckedPre(data => !data);
                        setPlanDetail({ ...planDetail, planType: checkedPre });
                      }}
                    />
                  }
                />
                <Typography
                  style={{ color: 'red' }}
                  variant="caption"
                  display="block"
                  gutterBottom
                >
                  {errorText?.planError}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Fetch User :
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={valueCount}
                    onChange={e => setValueCount(e.target.value)}
                  >
                    <FormControlLabel
                      value="all"
                      control={<Radio />}
                      label="All"
                    />
                    <FormControlLabel
                      value="manual"
                      control={<Radio />}
                      label="Manual"
                    />
                    <FormControlLabel
                      value="custom"
                      control={<Radio />}
                      label="Custom"
                    />
                    {valueCount === 'custom' && (
                      <Box>
                        <AppTextInput
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          name="custom"
                          // fullWidth
                          variant="outlined"
                          label="Enter value"
                          value={customValue}
                          onChange={e => setCustomValue(e.target.value)}
                          helperText={errorText?.custom}
                        />
                      </Box>
                    )}
                     </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <AppSelectBox
                  disabled={valueCount === 'manual' ? false : true}
                  fullWidth
                  data={supplierArr}
                  name="supplierData"
                  label="Manually choose Supplier Email"
                  valueKey={''}
                  variant="outlined"
                  labelKey=""
                  secondLabelKey="emailPlan.currentEmailSent"
                  value={emailManual}
                  displayName="name"
                  onChange={e => {
                    setEmailManual(e.target.value.email);
                    setEmailList(() => [...emailList, e.target.value.email]);
                    setNewEmailList(() => [
                      ...newEmailList,
                      {
                        email: e.target.value.email,
                        currentEmailSent:
                          e.target.value.emailPlan.currentEmailSent,
                      },
                    ]);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                {newEmailList.length ? (
                  newEmailList?.map((e, key) => {
                    return ( 
                      e.length !== 0 && (
                        <Chip
                          size="medium"
                          key={key}
                          label={e.email + '  ' + e.currentEmailSent}
                          clickable
                          color="secondary"
                          variant="outlined"
                          className="mb-2 mr-2"
                        />
                      )
                    );
                  })
                ) : (
                  <span className={classes?.noDataFound}>No Data Found</span>
                )}
              </Grid>
            </GridContainer>
          </Box>
          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button onClick={onCloseDialog}>Cancel</Button>
            <Box ml={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={onSubmitClick}
              >
                Get Emails
              </Button>
            </Box>
            {
              <Box ml={2}>
                <Button
                  variant="contained"
                  disabled={emailList && emailList.length === 0}
                  color="primary"
                  onClick={handleVerifyEmailLimit}
                >
                  Select Emails
                </Button>
              </Box>
            }
          </Box>
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm user emails limit update`}
        content={'Are you sure, you want to update users email limit?'}
        onClose={onCloseConfirmModal}
        onConfirm={() => setOpenUpdateEmailLimitPopUp(true)}
      />
      {openUpdateEmailLimitPopUp && (
        <UpdateEmailLimit
          data={updatableEmail}
          open={openUpdateEmailLimitPopUp}
          onCloseDialog={() =>{
            setOpenUpdateEmailLimitPopUp(false)
            setOpenConfirmDialog(false)
            setOpenDialog(true)
          }}
        />
      )}
    </>
  );
};

export default EmailListPopUp;

EmailListPopUp.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
