import CmtCardMedia from '@coremat/CmtCard/CmtCardMedia';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import { Box, Button, makeStyles, Switch } from '@material-ui/core';
import { KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import {
  Add,
  Edit,
  FilterList,
  MoreHoriz,
  Visibility,
} from '@material-ui/icons';
import CustomTable from 'components/CustomeTable';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_MATERIAL_CREATE_SUCCESS,
  PRODUCT_UPDATE_SUCCESS,
} from 'redux/actions';
import {
  deleteProduct,
  getAllProducts,
  getProductList,
  setProductDetail,
  updateProduct,
} from 'redux/actions/Manage/Products/Products';
import ViewNewsDetails from './ViewNewsDetails';
import AddEditNews from './AddEditNews';
import { deleteNews, getAllNews, setNewsDetail, updateNews } from 'redux/actions/Manage/News/News';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import { Grid } from 'react-virtualized';
import { DatePicker } from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
  imageRoot: {
    width: 150,
    height: 150,
  },
}));
const News = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  //------------all use selectors
  const { user } = useSelector(({ auth }) => auth.authUser);
  const { news, total, perPage } = useSelector(state => state.News);
  const ProductData = useSelector(state => state.setProductDetail);
  const productDetail = ProductData?.user;

  //-------------all use states
  const [orderBy, setOrderBy] = React.useState('');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  // const [products, setProducts]= useState([])
  // const [total,setTotal] = useState(null)
  // const perPage =10
  // let demo =[]
  //   let demoo
  const [selectedUser, setSelectedUser] = useState({ name: '' });
  const [productsFetched, setproductsFetched] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [row, setRow] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [permissionData] = useState(
    JSON.parse(localStorage.getItem('permission')),
  );
  const [createPermission, setCreatePermission] = useState(false);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [exportPermission, setExportPermission] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openViewProductDetails, setOpenViewProductDetails] = useState(false);
  const [openViewMaterialList, setopenViewMaterialList] = useState(false);
  const [openViewMaterialAdd, setOpenViewMaterialAdd] = useState(false);
  // const [search, setSearch] = useState({ sort: null, field: null });
  const [fileData, setFileData] = useState([]);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [downloadData, setDownloadData] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const successAction = useSelector(state => state.Success.action);
    const [searchFlag, setSearchFlag] = useState(false)
    const [selectedDate, setSelectedDate] = React.useState(null);

  //-------table config actions
  const getUserActions = user => {
    const actions = [];
    actions.push({ action: 'view', label: 'View', icon: <Visibility /> });
    actions.push({ action: 'edit', label: 'Edit', icon: <Edit /> });
    return actions;
  };

  const userActions = getUserActions(row);

  const onUserMenuClick = (menu, elm) => {
    if (menu.action === 'view') {
      dispatch(setNewsDetail(elm));
      setOpenViewProductDetails(true);
    } else if (menu.action === 'edit') {
      dispatch(setNewsDetail(elm));
      setOpenUserDialog(true);
      history.push(`/manage-edit-news/${elm?._id}`);
    } 
    // } else if (menu.action === 'delete') {
    //     setSelectedUser(elm);
    //     setOpenConfirmDialog(true)
    // }
  };
  const headCells = [
   
    {
      id: 'logo',
      numeric: false,
      disablePadding: false,
      label: 'Image',
      sort: false,
      render: elm => (
        <Box>
          <CmtCardMedia className={classes.imageRoot} image={elm.logo} />
        </Box>
      ),
    },
    {
      id: 'title',
      numeric: false,
      disablePadding: false,
      label: 'Title',
    },
    {
      id: 'date',
      numeric: false,
      disablePadding: false,
      label: 'Date',
    },
   
    {
      id: 'active',
      numeric: false,
      disablePadding: false,
      sort: false,
      label: 'Status',
      render: elm => (
        <Switch
          // defaultChecked={elm.showInMiddle === true ? true : false}
          checked={elm.active === true ? true : false}
          onChange={() => handleChangeStatusSwitch(elm)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      ),
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Actions',
      sort: false,
      render: elm => (
        <>
          {/* {!updatePermission && !deletePermission ? '-' : <CmtDropdownMenu items={userActions} onItemClick={(menu) => onUserMenuClick(menu, elm)} TriggerComponent={<MoreHoriz />} />} */}
          {
            <CmtDropdownMenu
              items={userActions}
              onItemClick={menu => onUserMenuClick(menu, elm)}
              TriggerComponent={<MoreHoriz />}
            />
          }
        </>
      ),
    },
  ];
  //download file setting
  var fileHeaders = [
    {
      key: 'firstName',
      label: 'Name',
    },
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'roleName',
      label: 'Role',
    },
    {
      key: 'departmentName',
      label: 'Dept',
    },
    {
      key: 'status',
      label: 'Status',
    },
  ];

  //-------all handle functions
  const handleChangeSwitch = elm => {
    let showInMiddle;
    if (elm.showInMiddle === true) showInMiddle = 'false';
    else showInMiddle = 'true';
    const data = {
      productId: elm.productId,
      showInMiddle: showInMiddle,
      idOfProductCreator: user?.userId,
      nameOfProductCreator: user?.name,
    };
    dispatch(
      updateProduct(data, data => {
        if (data) history.push('/manage-products');
      }),
    );
  };

  const handleChangeStatusSwitch = elm => {
    let status;
    if (elm.active === true) status = 'false';
    else status = 'true';
    const data = {
      id: elm.id,
      active: status,
    };
    dispatch(
      updateNews(data, data => {
        if (data) history.push('/manage-news');
      }),
    );
  };

  const handleCloseViewMaterialList = () => {
    setopenViewMaterialList(false);
    dispatch(setNewsDetail(null));
  };

  const handleCloseViewMaterialAdd = () => {
    setOpenViewMaterialAdd(false);
    dispatch(setNewsDetail(null));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleViewDialog = user => {
    dispatch(setNewsDetail(user));
    setOpenViewDialog(true);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    dispatch(setNewsDetail(null));
  };

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    dispatch(setNewsDetail(null));
  };

  const handleAddButton = () => {
    setOpenUserDialog(true);
    dispatch(setNewsDetail(null));
    history.push('/manage-add-news');
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    dispatch(
      deleteNews(selectedUser._id, data => {
        if (data) setDataUpdated(data => !data);
      }),
    );
  };

  const handleCloseViewProductDetails = () => {
    setOpenViewProductDetails(false);
    dispatch(setNewsDetail(null));
  };
  const onClearFilterClick =()=>{
    setSelectedDate(null)
  }
  const handleDateChange = date => {
    setSelectedDate(date);
  };

  useEffect(() => {
    let params;
    params = {
      search: searchTerm,
      date:selectedDate
    };
    dispatch(getAllNews(params), () => {
      setproductsFetched(true);
    });
  }, [dispatch,  dataUpdated,selectedDate]);

  useEffect(() => {
    if (
      successAction === PRODUCT_UPDATE_SUCCESS ||
      successAction === PRODUCT_CREATE_SUCCESS ||
      successAction === PRODUCT_MATERIAL_CREATE_SUCCESS
    ) {
      let params;
      params = {
        search: searchTerm,
        date:selectedDate
      };
      dispatch(getAllNews(params), () => {
        setproductsFetched(true);
      });
    }
  }, [successAction]);

  return (
    <>
        <Box width="300px" marginLeft="auto" marginBottom="20px">
          <DatePicker
            label="Filter By Date"
            inputVariant="outlined"
            value={selectedDate}
            onChange={date => handleDateChange(date._d)}
            format="DD/MM/YYYY"
            fullWidth
            size="small"
          />
           <Box textAlign="right" mt="10px">
                <Button variant="contained" color="primary" onClick={onClearFilterClick}>
                  Clear Filter
                </Button>
              </Box>
        </Box>
      <CustomTable
        TableName="news"
        downloadTableBtn={exportPermission ? true : false}
        fileName="news.xlsx"
        fileHeaders={fileHeaders}
        fileData={fileData}
        setIsDownload={setIsDownload}
        downloadData={downloadData}
        row={news && news}
        addButtonText={'Add News'}
        handleAddButton={handleAddButton}
        headCells={headCells}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        count={total || 0}
        rowsPerPage={perPage}
        onPageChange={handlePageChange}
        page={page}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleSearch={text => setSearchTerm(text)}
        handleSearchSubmit={() => {
          setPage(0);
          setDataUpdated(!dataUpdated);
        }}
        handleViewDialog={handleViewDialog}
        productDetailModal={{
          emailData: productDetail?.email,
          modalHeading: productDetail?.name,
          headerArray: [
            'MemberType: ' + productDetail?.membertype,
            productDetail?.address && 'Address: ' + productDetail?.address,
          ],
          sectionHeading: 'Contact Details',
          openViewDialog: openViewDialog,
          handleCloseViewDialog: handleCloseViewDialog,
          activeChip: productDetail?.active,
          phoneData: productDetail?.mobileNo,
        }}
      />
      {openUserDialog && (
        <AddEditNews
          setDataUpdated={setDataUpdated}
          open={openUserDialog}
          onCloseDialog={handleCloseUserDialog}
        />
      )}
      {openViewProductDetails && (
        <ViewNewsDetails
          open={openViewProductDetails}
          onCloseDialog={handleCloseViewProductDetails}
        />
      )}
      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete`}
        content={'Are you sure, you want to  delete this user?'}
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};

export default News;
