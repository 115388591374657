import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  Grid,
  Divider,
  makeStyles,
  Typography,
  Button,
  CircularProgress,
  Box,
  styled,
  Switch,
  FormControlLabel,
} from '@material-ui/core';

import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import IntlMessages from '@jumbo/utils/IntlMessages';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '@jumbo/components/GridContainer';
import AppCheckbox from '@jumbo/components/Common/formElements/AppCheckBox';
import {
  getUserById,
  updatePermission,
} from 'redux/actions/Manage/Roles/Roles';

// import { GridContainer, IntlMessages, AppCheckbox, PageContainer } from '@jumbo/components/Common';
// import { getPermissionsList, updatePermissions, getAllowedPermissions } from '../../../redux/actions/Role';

const useStyles = makeStyles(() => ({
  dFlex: {
    display: 'inline-block',
    width: '270px',
  },
  marginLeftBtn: {
    marginLeft: 10,
  },
  moduleName: {
    alignSelf: 'baseline',
  },
  cmCheckBox: {
    '& label': {
      alignItems: 'flex-start',
      marginBottom: '10px',
      '& span.MuiCheckbox-root': {
        paddingTop: '0',
      },
    },
  },
  c_row: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  py_15: {
    paddingTop: '15px',
    paddingBottom: '15px',
  },
}));

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const breadcrumbs = [
  { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  { label: <IntlMessages id={'pages.role'} />, link: '/role' },
  { label: <IntlMessages id={'pages.permission'} />, isActive: true },
];

const Permissions = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id } = useParams();
  const { user } = useSelector(({ auth }) => auth);
  const permissionList = [
    {
      id: 1,
      module_name: 'Product',
      permission_value: [
        { id: 1, name: 'List' },
        { id: 2, name: 'Create' },
        { id: 3, name: 'Update' },
        { id: 4, name: 'Delete' },
      ],
    },
    {
      id: 2,
      module_name: 'Manage Admin',
      permission_value: [
        { id: 5, name: 'List' },
        { id: 6, name: 'Create' },
        { id: 7, name: 'Update' },
        { id: 8, name: 'Delete' },
      ],
    },
    {
      id: 3,
      module_name: 'Sub Product',
      permission_value: [
        { id: 9, name: 'List' },
        { id: 10, name: 'Create' },
        { id: 11, name: 'Update' },
        { id: 12, name: 'Delete' },
      ],
    },
    {
      id: 4,
      module_name: 'Grade',
      permission_value: [
        { id: 13, name: 'List' },
        { id: 14, name: 'Create' },
        { id: 15, name: 'Update' },
        { id: 16, name: 'Delete' },
      ],
    },
    {
      id: 5,
      module_name: 'Material',
      permission_value: [
        { id: 17, name: 'List' },
        { id: 18, name: 'Create' },
        { id: 19, name: 'Update' },
        { id: 20, name: 'Delete' },
      ],
    },
    {
      id: 6,
      module_name: 'Supplier',
      permission_value: [
        { id: 21, name: 'List' },
        { id: 22, name: 'Create' },
        { id: 23, name: 'Update' },
        { id: 24, name: 'Delete' },
      ],
    },
  ];
  const [permission, setPermission] = useState([]);
  const [rolesPermission, setRolesPermission] = useState([]);
  const [loader, setLoader] = useState(false);
  const [flag, setFlag] = useState(false);
  const checkedArray = [];
  const checkedArrayName = [];
  // const [checkedValue, setCheckedValue] = useState();
  console.log(permission)
  //------------------------------------------------------- 
const [orgPermission, setOrgPermission]= useState()
  



  const handleChange = (event, rec) => {
    if (event.target.checked === true) {
      setOrgPermission({...orgPermission, [rec?.name]: true})
      checkedArrayName.push(rec.name);
      setRolesPermission([...rolesPermission, ...checkedArrayName]);
    }
    if (event.target.checked === false) {
      setOrgPermission({...orgPermission, [rec?.name]: false})
      const filterData = rolesPermission.filter(function(data) {
        return data !== rec?.name;
      });
      setRolesPermission(filterData);
    }
  };

 const handleSubmit = () => {
    setFlag(true);
    const value ={userId:id, permissions:orgPermission}
    dispatch(updatePermission(value)).then(response => {
      if (response?.status === 200) {
        history.push('/manage-admin-users');
      } else {
        setFlag(false);
      }
    });
  }; 


  useEffect(() => {
    if (id) {
      dispatch(getUserById(id)).then(res => {
        const { permissions } = res?.data?.data;
        setOrgPermission(permissions)
        const list = [];
        const assignValue = [];
        let entriesData = Object.entries(permissions);
        entriesData?.length && entriesData.map(([key, val]) => {
          let camelLabel = key.charAt(0).toUpperCase() + key.slice(1);
          let splittedLabel = camelLabel.split(/(?=[A-Z])/);
          let label = splittedLabel.slice(0, -1);
          list.push({
            name: key,
            label: label.join(' '),
            value: val,
          });
          if (val) {
            assignValue.push(
              camelLabel.charAt(0).toLocaleLowerCase() + camelLabel.slice(1),
            );
          }
        });
        setRolesPermission(assignValue);
        setPermission(list);
      });
    }
  }, [dispatch, id]);


  // const handleChange = (event, rec) => {
  //   let tempData = checkedValue;
  //   if (event.target.checked === true) {
  //     let value = { [rec?.name]: event.target.checked };
  //     Object.assign(tempData, value);
  //     setCheckedValue(tempData);
  //     checkedArray.push({ [rec?.name]: !rec?.value });
  //     checkedArrayName.push(rec.name);
  //     setRolesPermission([...rolesPermission, ...checkedArrayName]);
  //   }
  //   if (event.target.checked === false) {
  //     const filter = rolesPermission.filter(function(data) {
  //       return data !== rec?.name;
  //     });
  //     Object.entries(checkedValue).reduce((acc, [key, val]) => {
  //       if (key === rec.name) {
  //         setCheckedValue({ ...checkedValue, [rec.name]: !val });
  //       }
  //     }, {});
  //     setRolesPermission(filter);
  //   }
  // };


   // const handleSubmit = () => {
  //   setFlag(true);
  //   const value ={userId:id, permissions:checkedValue}
  //   dispatch(updatePermission(value)).then(response => {
  //     if (response?.status === 200) {
  //       history.push('/manage-admin-users');
  //     } else {
  //       setFlag(false);
  //     }
  //   });
  // };


  // useEffect(() => {
  //   if (id) {
  //     dispatch(getUserById(id)).then(res => {
  //       const { permissions } = res?.data?.data;
  //       const list = [];
  //       const assignValue = [];
  //       const assignedPermission = [];
  //       let assignData = {};
  //       let entries = Object.entries(permissions);
  //       entries.map(([key, val]) => {
  //         let camelLabel = key.charAt(0).toUpperCase() + key.slice(1);
  //         let splittedLabel = camelLabel.split(/(?=[A-Z])/);
  //         let label = splittedLabel.slice(0, -1);
  //         list.push({
  //           name: key,
  //           label: label.join(' '),
  //           value: val,
  //         });
  //         if (val) {
  //           assignValue.push(
  //             camelLabel.charAt(0).toLocaleLowerCase() + camelLabel.slice(1),
  //           );
  //           assignedPermission.push({ [key]: val });
  //           Object.assign(assignData, { [key]: val });
  //         }
  //       });
  //       setCheckedValue(assignData);
  //       setRolesPermission(assignValue);
  //       setPermission(list);
  //     });
  //   }
  // }, [dispatch, id]);

  return (
    <PageContainer
      heading={<IntlMessages id="pages.permission" />}
      breadcrumbs={breadcrumbs}
    >
      <GridContainer>
        <Grid item xs={12}>
          <Divider className="mb-5" />
          <CmtCard>
            <CmtCardContent className={classes.c_row}>
              {loader ? (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {permission?.length
                    ? permission.map((single, index) => (
                        <Grid
                          item
                          md={6}
                          lg={4}
                          key={index}
                          className={classes.py_15}
                        >
                          <FormControlLabel
                            control={
                              <Android12Switch
                                checked={rolesPermission.includes(single?.name)}
                                onChange={evt =>
                                  handleChange(evt, single)
                                }
                              />
                            }
                            label={single?.label}
                          />
                        </Grid>
                      ))
                    : []}
                  <Grid item md={12}>
                    <Grid item xs={12} md={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={
                          !flag && rolesPermission.length ? false : true
                        }
                      >
                        Save
                      </Button>
                      <Button
                        className={classes?.marginLeftBtn}
                        variant="contained"
                        onClick={() => history.push('/manage-admin-users')}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </CmtCardContent>
          </CmtCard>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default Permissions;
