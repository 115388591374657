import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Chip, Divider, IconButton, Input, Paper } from '@material-ui/core';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { Typography } from '@mui/material';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import ClearIcon from '@material-ui/icons/Clear';
import { useHistory } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';


import {
  ImageAdd,
  ImageUpload,
  certificateAdd,
  certificateUpdate,
  deleteSupplierGalleryImage,
  updateSupplier,
} from 'redux/actions/Manage/Suppliers/Suppliers';
import { Category, Image, PictureAsPdf } from '@material-ui/icons';
import CmtCardMedia from '@coremat/CmtCard/CmtCardMedia';
import { useDispatch, useSelector } from 'react-redux';
import Axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { downloadFile } from '@jumbo/utils/fileHelper';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

const useStyles = makeStyles(() => ({
  paper: {
    padding: '20px',
  },
  btn: {
    cursor: 'pointer',
  },
}));

const EditCertificate = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { supplier } = useSelector((state) => state.SetSupplierDetail);
  console.log(supplier, 'certificate');
  console.log(supplier.certificates, 'images');

  const [imageUpdate, setImageUpdate] = useState({
    image: '',
    name: '',
  });

  const [productImages, setProductImages] = useState(supplier.certificates);
  const [loading,setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [num, setNum] = useState('');
  const [newName, setNewName] = useState('');
  const [showEdit, setShowEdit] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const EditImage = ({ index, name, category }) => {
    console.log(index, name, category);
    setNewCategory(category);
    setNewName(name);
    setShowEdit(true);
    setNum(index);
  };

  const handleDelete = ({ index, category }) => {
    dispatch(deleteSupplierGalleryImage(supplier.userId, index, category))
    .then(()=>{
      window.location.reload()
    })
    console.log(index, supplier.userId);
    // history.push(`/manage-suppliers`);
  };

  const handleAdd = () => {
    setLoading(true);
    const data = new FormData();
    data.append('addCertificate', imageUpdate.image);
    data.append('userId', supplier.userId);
    data.append('name', imageUpdate.name);
    dispatch(certificateAdd(data)).then(() => {
      setOpen(false)
      window.location.reload()
    })
    // history.push('/manage-suppliers');
  };

  const updateImage = ({ index, category }) => {
    const data = new FormData();
    data.append('index', index);
    data.append('updateCertificate', imageUpdate.image);
    data.append('userId', supplier.userId);
    data.append('category', category);
    data.append('name', newName);
    dispatch(certificateUpdate(data))
    .then(()=>{
      window.location.reload()
    })
    // history.push('/manage-suppliers');
  };

  const handlePdfClick = async (url) => {
    const response = await fetch(url);
    const pdfBuffer = await response.arrayBuffer();
    const pdfBlob = new Blob([pdfBuffer], { type: 'application/pdf' });
    const pdfBlobUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfBlobUrl, '_blank')
  };

  return (
    <PageContainer>
      <div>
        <Paper className={classes.paper}>
          <Box mt={2} mb={2} sx={{ display: 'flex' }}>
            <Typography sx={{ marginRight: 4 }} variant="h6">
              Certificate
            </Typography>
            <Button onClick={handleClickOpen} variant="contained" color="primary">
              Add Image
            </Button>
          </Box>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Box mt={2} sx={{ display: 'flex' }}>
              <DialogTitle id="alert-dialog-title">Add Image</DialogTitle>
              <Button component="label">
                <AddCircleIcon />
                <input
                  type="file"
                  style={{ display: 'none' }}
                  onChange={evt =>
                    setImageUpdate({
                      ...imageUpdate,
                      image: evt.target.files[0],
                    })
                  }
                />
              </Button>
            </Box>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Box mt={2}>
                  <Input
                    variant="outlined"
                    placeholder="Image Name"
                    onChange={evt =>
                      setImageUpdate({ ...imageUpdate, name: evt.target.value })
                    }
                    value={imageUpdate.name}
                  />
                </Box>
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button variant="contained" color="primary" onClick={handleClose}>
                Cancel
              </Button>
              {loading ? <h1 style={{margin:"0 10px"}}><ClipLoader
               color="#13438D" size={25} /></h1> : <Button
                disabled={!imageUpdate.name.trim().length > 0 ? true : false}
                onClick={handleAdd}
                variant="contained"
                color="primary"
                autoFocus
              >
                Add
              </Button>}
            </DialogActions>
          </Dialog>
          <Box>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {productImages &&
                productImages.map((item, index) => (
                  <Grid item xs={3} sm={3} md={2} key={index} >
                    {num !== index ? (
                      <Box mt={4}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <div
                            className={classes.btn}
                            onClick={() =>
                              EditImage({
                                index: index,
                                name: item.name,
                                category: 'certificates',
                              })
                            }
                          >
                            <EditIcon size={2} />
                          </div>
                          <div className="btn" style={{ cursor: "pointer" }}>
                            <ClearIcon
                              onClick={() =>
                                handleDelete({
                                  category: 'certificates',
                                  index,
                                })
                              }
                              color="error"
                            />
                          </div>
                        </div>

                        {item.image.toLowerCase().endsWith('.pdf') ? (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <PictureAsPdf
                              style={{
                                width: '140px',
                                height: '140px',
                                cursor: 'pointer',
                              }}
                              onClick={() => handlePdfClick(item.image)}
                            />
                          </div>
                        ) : (
                          <div
                            // onClick={() => downloadFile(item.image)}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <img
                              style={{ width: '140px', height: '140px' }}
                              src={item.image}
                              alt="image"
                            />
                          </div>
                        )}

                        <div style={{ textAlign: 'center' }}>
                          <h4>{item.name}</h4>
                        </div>
                      </Box>
                    ) : (
                      <Box >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Button onClick={() => setNum("")}>
                            <ClearIcon color="error" />
                          </Button>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <img
                            style={{ width: '140px', height: '140px' }}
                            src={item.image}
                            alt="image"
                          />
                          <Button
                            style={{
                              position: 'absolute',
                              width: '140px',
                              height: '140px',
                            }}
                            variant="outlined"
                            component="label"
                          >
                            Upload
                            <input
                              type="file"
                              hidden
                              onChange={evt =>
                                setImageUpdate({
                                  ...imageUpdate,
                                  image: evt.target.files[0],
                                })
                              }
                            />
                          </Button>
                        </div>
                        <div style={{ textAlign: 'center', margin: "10px 0" }}>
                          <AppTextInput
                            variant="outlined"
                            placeholder="Image Name"
                            onChange={evt => setNewName(evt.target.value)}
                            value={newName}
                            type="text"
                          />
                        </div>
                        <div style={{ textAlign: 'center' }}>
                          <Button
                            disabled={!newName.trim().length > 0 ? true : false}
                            onClick={() =>
                              updateImage({
                                index: num,
                                category: 'certificates',
                              })
                            }
                            variant="contained"
                            color="primary"
                          >
                            Update
                          </Button>
                        </div>
                      </Box>
                    )}
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Paper>
      </div>
    </PageContainer>
  );
};


export default EditCertificate;
