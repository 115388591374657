import React from 'react';
import { Paper, Table, TableContainer } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import CustomTableHead from './CustomTableHead';
import CustomTableRow from './CustomTableRow';
import DynamicRow from './CustomTableRow/DynamicRow';
import CustomTableToolbar from './CustomTableToolbar';
import CustomDetailView from './CustomDetailView';
import useStyles from './index.style';
const CustomTable = ({
  downloadData,
  setIsDownload,
  handleViewDialog,
  userDetailModal,
  TableName,
  downloadTableBtn,
  fileName,
  fileHeaders,
  fileData,
  filter,
  rowCenterClass,
  page,
  rowsPerPage,
  count,
  onChangePage,
  row,
  scroll,
  headCells,
  numSelected,
  order,
  orderBy,
  onSelectAllClick,
  onRequestSort,
  rowCount,
  addButtonText,
  handleAddButton,
  selected,
  setSelected,
  onUsedd,
  filterOptions,
  setFilterOptions,
  handleSearch,
  searchTerm,
  setSearchTerm,
  onPageChange,
  handleSearchSubmit,
  showToolbar = true,
  isDynamic=false,
}) => {
  const classes = useStyles();
  const isSelected = id =>
    selected && selected.length && selected.indexOf(id) !== -1;
  // const handleChangePage = (event, newPage) => {
  //   onPageChange(newPage);
  // };
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {showToolbar && (
          <CustomTableToolbar
            TableName={TableName}
            downloadData={downloadData}
            setIsDownload={setIsDownload}
            downloadTableBtn={downloadTableBtn}
            fileName={fileName}
            fileHeaders={fileHeaders}
            fileData={fileData}
            selected={selected}
            filter={filter}
            setSelected={setSelected}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            addButtonText={addButtonText}
            handleAddButton={handleAddButton}
            handleSearch={handleSearch}
            handleSearchSubmit={handleSearchSubmit}
          />
        )}
        {/* <TableContainer className={classes.container}> */}
        <TableContainer>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="sticky enhanced table"
          >
            <CustomTableHead
              headCells={headCells}
              classes={classes}
              numSelected={numSelected && numSelected}
              order={order}
              orderBy={orderBy}
              onRequestSort={onRequestSort}
              rowCount={row && row.length}
            />
            <TableBody>
              {isDynamic ? 
              <DynamicRow
              rowCenterClass={rowCenterClass}
              headCells={headCells}
              scroll={scroll}
              row={row}
              order={order}
              orderBy={orderBy}
              page={page}
              rowsPerPage={rowsPerPage}
              isSelected={isSelected}
            />  
            :
            <CustomTableRow
                rowCenterClass={rowCenterClass}
                headCells={headCells}
                scroll={scroll}
                row={row}
                order={order}
                orderBy={orderBy}
                page={page}
                rowsPerPage={rowsPerPage}
                isSelected={isSelected}
              />
            }
              {/* <CustomTableRow
                rowCenterClass={rowCenterClass}
                headCells={headCells}
                scroll={scroll}
                row={row}
                order={order}
                orderBy={orderBy}
                page={page}
                rowsPerPage={rowsPerPage}
                isSelected={isSelected}
              /> */}
            </TableBody>
          </Table>
        </TableContainer>
        {count !== 0 && (
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
          />
        )}
      </Paper>
      {userDetailModal?.openViewDialog && (
        <CustomDetailView userDetailModal={userDetailModal} />
      )}
    </div>
  );
};

export default CustomTable;
