import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import Box from '@material-ui/core/Box';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, Checkbox, Divider, FormControlLabel, Typography } from '@material-ui/core';
import Pagination from '@mui/material/Pagination';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { setHotProductDetails, setProductDetails, setSupplierDetail } from 'redux/actions/Manage/Suppliers/Suppliers';
import { setError } from 'redux/actions/Common/ErrorAction';
import { setSuccess } from 'redux/actions/Common/SuccessAction';
import Axios from 'axios';
const useStyles = makeStyles(theme => ({
    dialogRoot: {
        position: 'relative',
        '& .MuiDialog-paperWidthSm': {
            width: '100%',
        },
    },
    dialogTitleRoot: {
        '& .MuiTypography-h6': {
            fontSize: 16,
            color: theme.palette.common.dark,
        },
    },
    titleRoot: {
        fontSize: 16,
        marginLeft: 12,
        fontWeight: theme.typography.fontWeightBold,
    },
    tagListRoot: {
        color: theme.palette.text.disabled,
        padding: '0 3px 0 0',
        letterSpacing: 0.4,
        fontSize: 12,
        width: 'auto',
    },
    blockRoot: {
        display: 'block',
        color: theme.palette.text.disabled,
    },
    descriptionBlock: {
        '& p': {
            marginBottom: 16,
            fontSize: 14,
            color: theme.palette.text.secondary,
        },
    },
    linkBtn: {
        cursor: 'pointer',
        textTransform: 'uppercase',
    },
    imageRoot: {
        width: '100%',
        height: 250,
    },
    badge: {
        position: 'absolute',
        bottom: 15,
        left: 20,
        zIndex: 1,
        fontSize: 12,
        padding: '4px 16px',
        letterSpacing: 0.4,
        borderRadius: 16,
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    marginClass: {
        marginX: 5
    }
}));

const HotProduct = () => {
    const classes = useStyles();
    const history = useHistory()
    //------------all use selectors
    const { hotProducts, totalPages, isLoading, isError, errorMessage, totalHotProduct } = useSelector(state => state.SetSupplierHotProductReducer);
    console.log(totalPages)
    const { supplier } = useSelector(state => state.SetSupplierDetail);

    const dispatch = useDispatch();
    const { id } = useParams();
    console.log(id, "product view 88")

    const [productList, setProductList] = useState(null);
    const [page, setPage] = useState(1);
    const [totalpage, setTotalPage] = useState();
    const [removeCheckId, setRemoveCheckId] = useState([]);
    const [checkId, setCheckId] = useState([]);
    const [removeHotProduct, setRemoveHotProduct] = useState([]);
    const [hotProduct, setHotProduct] = useState([]);

    const handleclick = mat => {
        history.push('/manage-suppliers');
    };
    const handlePageChange = (event, value) => {
        setPage(value);
        dispatch(setHotProductDetails({ userId: id, page: value }));
    };
    const handleCheckHotProduct = (e, hotProd) => {
        const { checked } = e.target
        if (checked) {
            if (hotProd?.hotproducts && hotProd?.hotproducts?.length > 0) {
                const hotProductId = hotProd?.hotproducts?.[0]?._id
                setCheckId([...checkId, hotProductId])
                setRemoveCheckId((pre) => pre.filter((e) => e !== hotProductId))
            } else {
                setHotProduct((pre) => [...pre, { ...hotProd?._id }])
            }
        } else {
            if (hotProd?.hotproducts && hotProd?.hotproducts?.length > 0) {
                const hotProductId = hotProd?.hotproducts?.[0]?._id
                setCheckId((pre) => pre.filter((e) => e !== hotProductId))
                setRemoveCheckId([...removeCheckId, hotProductId])
            } else {
                console.log(hotProd)
                setHotProduct((pre) => pre.filter((e) => {
                    return e.productId !== hotProd?._id?.productId || e.materialId !== hotProd?._id?.materialId
                }))
            }
        }
    }
    const handleAddHotProduct = async function () {
        try {
            if(totalHotProduct + hotProduct?.length - removeCheckId?.length < 12){
                dispatch(setError("Minimum 12 Hot Products is required"));
                return;
            }
            if (hotProduct?.length > 0) {
                const addResponse = await Axios.post(`supplier/add-hotproduct/${id}`, {
                    hotProduct: hotProduct
                });
                if (addResponse.status === 200) {
                    setHotProduct([])
                    dispatch(setSuccess(addResponse?.message))
                    dispatch(setHotProductDetails({ userId: id, page: 1 }));
                    setPage(1)
                }
            }
            if (removeCheckId?.length > 0) {
                const removeResponse = await Axios.post(`supplier/remove-hotproduct/${id}`, {
                    hotProductId: removeCheckId
                });
                if (removeResponse.status === 200) {
                    setRemoveCheckId([])
                    dispatch(setSuccess(removeResponse?.message))
                    dispatch(setHotProductDetails({ userId: id, page: 1 }));
                    setPage(1)
                }
            }
        } catch (error) {
            dispatch(
                setError(error?.message),
            );
        }
    }

    useEffect(() => {
        dispatch(setSupplierDetail(id));
        dispatch(setHotProductDetails({ userId: id }));
    }, []);

    return (
        <PageContainer>
            <CmtCard>
                <Box mt={-2} mb={-2} display="flex" justifyContent="space-between">
                    <Box ml={-2}>
                        <DialogTitle className={classes.dialogTitleRoot}>
                            {supplier && `${supplier.name + ' Hot Product List'}`}
                        </DialogTitle>
                    </Box>
                </Box>
                <Divider></Divider>
                <Box ml={-2}>
                    <DialogTitle className={classes.dialogTitleRoot}>
                        {`${(totalHotProduct + hotProduct?.length - removeCheckId?.length) + ' Hot Product Selected'}`}
                    </DialogTitle>
                </Box>
                <Divider></Divider>
                <CmtCardContent>
                    {hotProducts && hotProducts.length > 0 ? (
                        hotProducts?.map((hotProd, key) => {
                            return (
                                <Box key={hotProd?.productMaterial?._id}>
                                    <FormControlLabel
                                        value="start"
                                        control={
                                            <Checkbox
                                                checked={
                                                    (hotProd?.hotproducts && hotProd?.hotproducts?.length > 0 &&
                                                        !removeCheckId.some((e) => e == hotProd?.hotproducts?.[0]?._id)) ||
                                                    checkId.some((e) => e == hotProd?.hotproducts?.[0]?._id) ||
                                                    hotProduct.some((e) => e.productId == hotProd?._id?.productId && e.materialId == hotProd?._id?.materialId)
                                                }
                                                id={hotProd?.hotproducts?.[0]?._id}
                                                onChange={(e) => handleCheckHotProduct(e, hotProd)}
                                            />
                                        }
                                        label={`${page === 1 ? key + 1 : (page - 1) * 25 + 1 + key} ${hotProd?.productMaterial?.displayName}`}
                                        labelPlacement="end"
                                    />
                                    {/* <Typography as="span" className="mb-2">
                                        {page === 1
                                            ? `${key + 1}. `
                                            : `${(page - 1) * 25 + 1 + key}. `}
                                        {hotProd?.materials?.name} {hotProd?.products?.name}
                                        {hotProd?.productMaterial?.displayName}
                                    </Typography> */}
                                </Box>
                            );
                        })
                    ) : (
                        <Typography>No Products</Typography>
                    )}
                    {
                        <Pagination
                            page={page || 1}
                            count={Math.ceil(totalPages / 25)}
                            variant="outlined"
                            shape="rounded"
                            onChange={handlePageChange}
                        />
                    }
                </CmtCardContent>
                <Box display="flex" justifyContent="flex-end" mb={4}>
                    <Button
                        onClick={() => handleclick()}
                        variant="contained"
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Box ml={2}>
                        <Button
                            onClick={() => handleAddHotProduct()}
                            variant="contained"
                            color="primary"
                            // disabled={(totalHotProduct + hotProduct?.length - removeCheckId?.length) < 12}
                        >
                            Update
                        </Button>
                    </Box>
                </Box>
            </CmtCard>
        </PageContainer>
    );
};

export default HotProduct;

