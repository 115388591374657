import { BLOCK_ALL_IP_FAILED, BLOCK_ALL_IP_REQUEST, BLOCK_ALL_IP_SUCCESS, BLOCK_SINGLE_IP_FAILED, BLOCK_SINGLE_IP_REQUEST, BLOCK_SINGLE_IP_SUCCESS } from "redux/actions/Manage/IPBlock/Constant";

  
  const INIT_STATE = {
    loading: false,
    singleBlockIp: {},
    blockedIp: [],
    total: 0,
    perPage: 0,
    errorMessage: null
  };
  
  
  export const BlockIpReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case BLOCK_SINGLE_IP_REQUEST: {
        return {
          ...state,
          loading: true
        }
      }
      case BLOCK_SINGLE_IP_SUCCESS: {
        return {
          ...state,
          loading: false,
          singleBlockIp: action.payload.singleBlockIp
        }
      }
      case BLOCK_SINGLE_IP_FAILED: {
        return {
          ...state,
          loading: false,
          singleBlockIp: {},
          errorMessage: action.payload.errorMessage
        }
      }
      case BLOCK_ALL_IP_REQUEST: {
        return {
          ...state,
          loading: true
        }
      }
      case BLOCK_ALL_IP_SUCCESS: {
        return {
          ...state,
          loading: false,
          blockedIp: action.payload?.blockedIp,
          total:action.payload?.total
        }
      }
      case BLOCK_ALL_IP_FAILED: {
        return {
          ...state,
          loading: false,
          blockedIp: [],
          errorMessage: action.payload.errorMessage
        }
      }
      default:
        return state;
    }
  };
  