// export const SUBSUBPRODUCT_ADD_REQUEST = "SUBSUBPRODUCT_ADD_REQUEST";
// export const SUBSUBPRODUCT_ADD_SUCCESS = "SUBSUBPRODUCT_ADD_SUCCESS";
// export const SUBSUBPRODUCT_ADD_FAILED = "SUBSUBPRODUCT_ADD_FAILED";
// export const SUBSUBPRODUCT_LIST_REQUEST = "SUBSUBPRODUCT_LIST_REQUEST";
// export const SUBSUBPRODUCT_LIST_SUCCESS = "SUBSUBPRODUCT_LIST_SUCCESS";
// export const SUBSUBPRODUCT_LIST_FAILED = "SUBSUBPRODUCT_LIST_FAILED";
// export const SUBSUBPRODUCT_UPDATE_REQUEST = "SUBSUBPRODUCT_UPDATE_REQUEST";
// export const SUBSUBPRODUCT_UPDATE_SUCCESS = "SUBSUBPRODUCT_UPDATE_SUCCESS";
// export const SUBSUBPRODUCT_UPDATE_FAILED = "SUBSUBPRODUCT_UPDATE_FAILED";


export const SUBPRODUCT_LIST_REQUEST = "SUBPRODUCT_LIST_REQUEST";
export const SUBPRODUCT_LIST_SUCCESS = "SUBPRODUCT_LIST_SUCCESS";
export const SUBPRODUCT_LIST_FAILED = "SUBPRODUCT_LIST_FAILED";
export const DELETE_BULK_SUBPRODUCT_REQUEST = "DELETE_BULK_SUBPRODUCT_REQUEST";
export const DELETE_BULK_SUBPRODUCT_SUCCESS = "DELETE_BULK_SUBPRODUCT_SUCCESS";
export const DELETE_BULK_SUBPRODUCT_FAILED = "DELETE_BULK_SUBPRODUCT_FAILED";
export const SET_SUBPRODUCT_DETAILS = "SET_SUBPRODUCT_DETAILS";
export const SUBPRODUCT_CREATE_REQUEST = "SUBPRODUCT_CREATE_REQUEST";
export const SUBPRODUCT_CREATE_SUCCESS = "SUBPRODUCT_CREATE_SUCCESS";
export const SUBPRODUCT_CREATE_FAILED = "SUBPRODUCT_CREATE_FAILED";
export const SUBPRODUCT_MATERIAL_CREATE_REQUEST = "SUBPRODUCT_MATERIAL_CREATE_REQUEST";
export const SUBPRODUCT_MATERIAL_CREATE_SUCCESS = "SUBPRODUCT_MATERIAL_CREATE_SUCCESS";
export const SUBPRODUCT_MATERIAL_CREATE_FAILED = "SUBPRODUCT_MATERIAL_CREATE_FAILED";
export const SUBPRODUCT_UPDATE_REQUEST = "SUBPRODUCT_UPDATE_REQUEST";
export const SUBPRODUCT_UPDATE_SUCCESS = "SUBPRODUCT_UPDATE_SUCCESS";
export const SUBPRODUCT_UPDATE_FAILED = "SUBPRODUCT_UPDATE_FAILED";
export const SUBPRODUCT_PROFILE_UPDATE_REQUEST = "SUBPRODUCT_PROFILE_UPDATE_REQUEST";
export const SUBPRODUCT_PROFILE_UPDATE_SUCCESS = "SUBPRODUCT_PROFILE_UPDATE_SUCCESS";
export const SUBPRODUCT_PROFILE_UPDATE_FAILED = "SUBPRODUCT_PROFILE_UPDATE_FAILED";
export const SUBPRODUCT_PASSWORD_UPDATE_REQUEST = "SUBPRODUCT_PASSWORD_UPDATE_REQUEST";
export const SUBPRODUCT_PASSWORD_UPDATE_SUCCESS = "SUBPRODUCT_PASSWORD_UPDATE_SUCCESS";
export const SUBPRODUCT_PASSWORD_UPDATE_FAILED = "SUBPRODUCT_PASSWORD_UPDATE_FAILED";
export const SUBPRODUCT_DELETE_REQUEST = "SUBPRODUCT_DELETE_REQUEST";
export const SUBPRODUCT_DELETE_SUCCESS = "SUBPRODUCT_DELETE_SUCCESS";
export const SUBPRODUCT_DELETE_FAILED = "SUBPRODUCT_DELETE_FAILED";
export const SET_SUBPRODUCTELM_DETAILS = "SET_SUBPRODUCTELM_DETAILS"