//-------------MATERIAL constants------------------
export const MATERIAL_LIST_REQUEST = "MATERIAL_LIST_REQUEST";
export const MATERIAL_LIST_SUCCESS = "MATERIAL_LIST_SUCCESS";
export const MATERIAL_LIST_FAILED = "MATERIAL_LIST_FAILED";
export const DELETE_BULK_MATERIAL_REQUEST = "DELETE_BULK_MATERIAL_REQUEST";
export const DELETE_BULK_MATERIAL_SUCCESS = "DELETE_BULK_MATERIAL_SUCCESS";
export const DELETE_BULK_MATERIAL_FAILED = "DELETE_BULK_MATERIAL_FAILED";
export const SET_MATERIAL_DETAILS = "SET_MATERIAL_DETAILS";
export const MATERIAL_CREATE_REQUEST = "MATERIAL_CREATE_REQUEST";
export const MATERIAL_CREATE_SUCCESS = "MATERIAL_CREATE_SUCCESS";
export const MATERIAL_CREATE_FAILED = "MATERIAL_CREATE_FAILED";
export const MATERIAL_UPDATE_REQUEST = "MATERIAL_UPDATE_REQUEST";
export const MATERIAL_UPDATE_SUCCESS = "MATERIAL_UPDATE_SUCCESS";
export const MATERIAL_UPDATE_FAILED = "MATERIAL_UPDATE_FAILED";
export const MATERIAL_PROFILE_UPDATE_REQUEST = "MATERIAL_PROFILE_UPDATE_REQUEST";
export const MATERIAL_PROFILE_UPDATE_SUCCESS = "MATERIAL_PROFILE_UPDATE_SUCCESS";
export const MATERIAL_PROFILE_UPDATE_FAILED = "MATERIAL_PROFILE_UPDATE_FAILED";
export const MATERIAL_PASSWORD_UPDATE_REQUEST = "MATERIAL_PASSWORD_UPDATE_REQUEST";
export const MATERIAL_PASSWORD_UPDATE_SUCCESS = "MATERIAL_PASSWORD_UPDATE_SUCCESS";
export const MATERIAL_PASSWORD_UPDATE_FAILED = "MATERIAL_PASSWORD_UPDATE_FAILED";
export const MATERIAL_DELETE_REQUEST = "MATERIAL_DELETE_REQUEST";
export const MATERIAL_DELETE_SUCCESS = "MATERIAL_DELETE_SUCCESS";
export const MATERIAL_DELETE_FAILED = "MATERIAL_DELETE_FAILED";
export const MATERIAL_GRADE_REQUEST = "MATERIAL_GRADE_REQUEST";
export const MATERIAL_GRADE_SUCCESS = "MATERIAL_GRADE_SUCCESS";
export const MATERIAL_GRADE_FAILED = "MATERIAL_GRADE_FAILED";
export const SET_SINGLEMATERAIL_DETAILS = "SET_SINGLEMATERAIL_DETAILS";