//-------------Customers constants------------------
export const CUSTOMERS_LIST_REQUEST = "CUSTOMERS_LIST_REQUEST";
export const CUSTOMERS_LIST_SUCCESS = "CUSTOMERS_LIST_SUCCESS";
export const CUSTOMERS_LIST_FAILED = "CUSTOMERS_LIST_FAILED";
// export const DELETE_BULK_USERS_REQUEST = "DELETE_BULK_USERS_REQUEST";
// export const DELETE_BULK_USERS_SUCCESS = "DELETE_BULK_USERS_SUCCESS";
// export const DELETE_BULK_USERS_FAILED = "DELETE_BULK_USERS_FAILED";
export const SET_CUSTOMER_DETAILS = "SET_CUSTOMER_DETAILS";
export const CUSTOMER_CREATE_REQUEST = "CUSTOMER_CREATE_REQUEST";
export const CUSTOMER_CREATE_SUCCESS = "CUSTOMER_CREATE_SUCCESS";
export const CUSTOMER_CREATE_FAILED = "CUSTOMER_CREATE_FAILED";
export const CUSTOMER_UPDATE_REQUEST = "CUSTOMER_UPDATE_REQUEST";
export const CUSTOMER_UPDATE_SUCCESS = "CUSTOMER_UPDATE_SUCCESS";
export const CUSTOMER_UPDATE_FAILED = "CUSTOMER_UPDATE_FAILED";
export const CUSTOMER_DELETE_REQUEST = "CUSTOMER_DELETE_REQUEST";
export const CUSTOMER_DELETE_SUCCESS = "CUSTOMER_DELETE_SUCCESS";
export const CUSTOMER_DELETE_FAILED = "CUSTOMER_DELETE_FAILED";
