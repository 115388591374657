import React from 'react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { makeStyles } from '@material-ui/core';
// import Heading from '@ckeditor/ckeditor5-heading/src/heading';
const useStyles = makeStyles(() => ({
  textarea: {
    width: '100%',
    height: '100%',
    padding: '8px',
    marginTop: "8px",
    fontSize: '17px',
    fontFamily: 'inherit',
    fontWeight: 300,
    borderColor: 'lightgray',
    borderRadius: '4px',

    '&:focus': {
      borderColor: '#1C61CA',
      borderWidth: '2px',
      outline: 'none',
      borderRadius: 4,
    },
  },
  textarea_red: {
    width: '100%',
    height: '100%',
    padding: '8px',
    fontSize: '16px',
    fontFamily: 'inherit',
    fontWeight: 400,
    borderColor: '#f44336',
    borderRadius: '4px',
    '&:focus': {
      borderColor: '#1C61CA',
      borderWidth: '2px',
      outline: 'none',
      borderRadius: 4,
    },
  },
}));

const CustomEditor = ({ editorData, setEditorData, error = null }) => {
  const classes = useStyles();

  const handleEditor = function (e) {
    const { value } = e.target;
    setEditorData(value)
  }
  return (
    <div>
      <textarea
        className={
          error ? classes.textarea_red : classes.textarea
        }
        rows={10}
        fullWidth
        name="meta"
        width={100}
        required
        variant="outlined"
        label="editor Description"
        value={editorData}
        onChange={handleEditor}
      />
      {/* <div>
        <CKEditor
          editor={Editor}
          data={editorData}
          onChange={(event, editor) => {
            const data = editor.getData();
            setEditorData(data);
          }}
          classEditor
          onReady={(editor) => {
            // editor.plugins.get('Paragraph').allowedContent = 'h1 h2 h3 p blockquote';
            // editor.config.define('heading', {
            //   options: [
            //     { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            //     { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            //     { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            //     { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
            //   ]
            // });
            editor.editing.view.change((writer) => {
              writer.setStyle(
                //use max-height(for scroll) or min-height(static)
                "min-height",
                "200px",
                editor.editing.view.document.getRoot()
              );
              writer.setStyle(
                //use max-height(for scroll) or min-height(static)
                "max-height",
                "395px",
                editor.editing.view.document.getRoot()
              );
              writer.setStyle(
                "padding-left",
                "27px",
                editor.editing.view.document.getRoot()
              );
            });
          }}


        // onReady={(editor)=>{
        //     console.log("onReady editor",editor)
        // }}
        // onBlur={(event, editor)=>{
        //     console.log("onBlur editor :", editor)
        // }}
        // onFocus={(event, editor)=>{
        //     console.log("onFocus editor :", editor)
        // }}
        />
      </div> */}
    </div>
  );
};
export const HtmlEditor = ({ editorData, setEditorData }) => {
  return (
    <div>
      <div>
        <CKEditor
          editor={Editor}
          data={editorData}
          onChange={(event, editor) => {
            const data = editor.getData();
            setEditorData(data);
          }}
          classEditor
          onReady={(editor) => {
            // editor.plugins.get('Paragraph').allowedContent = 'h1 h2 h3 p blockquote';
            // editor.config.define('heading', {
            //   options: [
            //     { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            //     { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            //     { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            //     { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
            //   ]
            // });
            editor.editing.view.change((writer) => {
              writer.setStyle(
                //use max-height(for scroll) or min-height(static)
                "min-height",
                "200px",
                editor.editing.view.document.getRoot()
              );
              writer.setStyle(
                //use max-height(for scroll) or min-height(static)
                "max-height",
                "395px",
                editor.editing.view.document.getRoot()
              );
              writer.setStyle(
                "padding-left",
                "27px",
                editor.editing.view.document.getRoot()
              );
            });
          }}


        // onReady={(editor)=>{
        //     console.log("onReady editor",editor)
        // }}
        // onBlur={(event, editor)=>{
        //     console.log("onBlur editor :", editor)
        // }}
        // onFocus={(event, editor)=>{
        //     console.log("onFocus editor :", editor)
        // }}
        />
      </div>
    </div>
  );
};

export default CustomEditor;
