import Axios from 'axios';
import {
  MSG_ERR_RESPONDING_SERVER,
  PER_PAGE_DEFAULT,
} from 'redux/actions/Common/Constant';
import { ErrorCatch } from 'utils/ErrorCatch';
import { setError } from '../../Common/ErrorAction';
import { setLoading } from '../../Common/LoadingAction';
import { setSuccess } from '../../Common/SuccessAction';
import {
  BULK_EMAIL_FAILED,
  BULK_EMAIL_REQUEST,
  BULK_EMAIL_SUCCESS,
  SET_EMAIL_DETAILS,
  EMAIL_LIST_FAILED,
  EMAIL_LIST_REQUEST,
  EMAIL_LIST_SUCCESS,
  EMAIL_CREATE_FAILED,
  EMAIL_CREATE_REQUEST,
  EMAIL_CREATE_SUCCESS,
  EMAIL_DELETE_FAILED,
  EMAIL_DELETE_REQUEST,
  EMAIL_DELETE_SUCCESS,
  EMAIL_PASSWORD_UPDATE_FAILED,
  EMAIL_PASSWORD_UPDATE_REQUEST,
  EMAIL_PASSWORD_UPDATE_SUCCESS,
  EMAIL_UPDATE_FAILED,
  EMAIL_UPDATE_REQUEST,
  EMAIL_UPDATE_SUCCESS,
  // EMAIL_TYPE_REQUEST,
  // EMAIL_TYPE_SUCCESS,
  // EMAIL_TYPE_FAILED,
  EMAIL_DETAIL_REQUEST,
  EMAIL_DETAIL_SUCCESS,
  EMAIL_DETAIL_FAILED,
  EMAIL_UPDATE_STATUS_SUCCESS,
  EMAIL_UPDATE_STATUS_REQUEST,
  EMAIL_UPDATE_STATUS_FAILED,
  SET_FILTER_TYPE,
  SET_SELECTED_MAIL,
  EMAIL_APPROVE_FAILED,
  EMAIL_APPROVE_SUCCESS,
  EMAIL_APPROVE_REQUEST,
  SET_EMAIL_LIST_POPUP,
  EMAIL_FETCH_SUPPLIER_PLAN_FAILED,
  EMAIL_FETCH_SUPPLIER_PLAN_SUCCESS,
  EMAIL_FETCH_SUPPLIER_PLAN_REQUEST,
  // EMAIL_FETCH_CATEGORY_REQUEST,
  EMAIL_FETCH_CATEGORY_SUCCESS,
  EMAIL_FETCH_CATEGORY_FAILED,
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAILED,
} from './Constant';
let RES_MSG = MSG_ERR_RESPONDING_SERVER;

export const getAllEmails = (
  params,
  callbackFun,
  isDownload = false,
) => async dispatch => {
  dispatch(setLoading(EMAIL_LIST_REQUEST));
  try {
    const response = await Axios.get(`/notify/fetch-all-emails-by-query`, {
      params,
    });
    const { data } = response;
    const total = data?.total;
    if (response.status === 200) {
      if (isDownload) dispatch(setSuccess(null, EMAIL_LIST_SUCCESS));
      else {
        dispatch({
          type: EMAIL_LIST_SUCCESS,
          data: data.data,
          total: total,
          message: data.message,
          perPage: PER_PAGE_DEFAULT,
        });
      }
      if (callbackFun) callbackFun(data.data);
    } else {
      dispatch(setError(RES_MSG, EMAIL_LIST_FAILED));
    }
  } catch (error) {
    ErrorCatch(error, EMAIL_LIST_FAILED, dispatch);
  }
};

//------------------------- Get All Mail Test ------------

export const getEmailsList = params => {
  return dispatch => {
    return Axios.get('/api/v1/admin/email/list', { params })
      .then(response => {
        if (response?.status === 200) {
          const { emailList, total, responseMessage, perPage } = response?.data?.data;
          dispatch({
            type: EMAIL_LIST_SUCCESS,
            data: emailList,
            total: total,
            message: responseMessage,
            perPage: perPage,
          });
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        dispatch( setError(err?.data?.responseMessage, EMAIL_LIST_FAILED))
        // ErrorCatch(err, EMAIL_LIST_FAILED, dispatch);
        return Promise.resolve(err);
      });
  };
};

export const emailStatusChange = (data)=>{
  return dispatch => {
    return Axios.put('/api/v1/admin/email/change-status', data)
      .then(response => {
        if (response?.status === 200) {
          dispatch(setSuccess(data.message, EMAIL_UPDATE_STATUS_SUCCESS));
          dispatch({
            type: EMAIL_UPDATE_STATUS_SUCCESS,
            data: data.data,
            message: data.message,
            perPage: PER_PAGE_DEFAULT,
          });
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        dispatch( setError(err?.data?.responseMessage, EMAIL_UPDATE_STATUS_FAILED))
        // ErrorCatch(err, EMAIL_LIST_FAILED, dispatch);
        return Promise.resolve(err);
      });
  };
}

export const getEmailById = id => {
  return dispatch => {
    return Axios.get(`api/v1/admin/email/${id}`)
      .then(response => {
        if (response?.status === 200) {
          const {responseMessage, emailDetail}= response?.data?.data
          dispatch({
            type: EMAIL_DETAIL_SUCCESS,
            data: emailDetail,
            message: responseMessage,
            perPage: PER_PAGE_DEFAULT,
          });
          
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        dispatch( setError(err?.data?.responseMessage, EMAIL_DETAIL_FAILED))
        // ErrorCatch(err, EMAIL_LIST_FAILED, dispatch);
        return Promise.resolve(err);
      });
  };
};

export const sendMail = data => {
  
  return dispatch => {
    dispatch(setLoading(SEND_EMAIL_REQUEST));
    return Axios.post('api/v1/admin/email', data)
      .then(response => {
        if (response?.status === 200) {
          dispatch(
            setSuccess(response?.data?.responseMessage, SEND_EMAIL_SUCCESS),
          );
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        dispatch( setError(err?.data?.responseMessage, SEND_EMAIL_FAILED))
       
        // ErrorCatch(err?.data?.responseMessage, SEND_EMAIL_FAILED, dispatch);
        return Promise.resolve(err);
      });
  };
};

export const sendIt = id => {
  return dispatch => {
    return Axios.post(`api/v1/admin/email/send/${id}`)
      .then(response => {
        if (response?.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        dispatch( setError(err?.data?.responseMessage, EMAIL_LIST_FAILED))
        // setError(err?.data?.responseMessage, EMAIL_LIST_FAILED)
        // ErrorCatch(err?.data?.responseMessage, EMAIL_LIST_FAILED,);
        return Promise.resolve(err);
      });
  };
};

//-------------------------------------------------------

export const fetchMailById = (
  mailId,
  callbackFun,
  isDownload = false,
) => async dispatch => {
  dispatch(setLoading(EMAIL_DETAIL_REQUEST));
  try {
    const response = await Axios.get(`/notify/fetch-all-emails/${mailId}`);
    const { data } = response;
    if (response.status === 200) {
      if (isDownload) dispatch(setSuccess(null, EMAIL_DETAIL_SUCCESS));
      else {
        dispatch({
          type: EMAIL_DETAIL_SUCCESS,
          data: data.data,
          // total: total,
          message: data.message,
          perPage: PER_PAGE_DEFAULT,
        });
      }
      if (callbackFun) callbackFun(data.data);
    } else {
      dispatch(setError(RES_MSG, EMAIL_DETAIL_FAILED));
    }
  } catch (error) {
    ErrorCatch(error, EMAIL_DETAIL_FAILED, dispatch);
  }
};

export const changeEmailStatus = (
  payload,
  callbackFun,
  isDownload = false,
) => async dispatch => {
  dispatch(setLoading(EMAIL_UPDATE_STATUS_REQUEST));
  try {
    const response = await Axios.post(`/notify/change-email-status`, payload);
    const { data } = response;
    if (response.status === 200) {
      dispatch(setSuccess(data.message, EMAIL_UPDATE_STATUS_SUCCESS));
      dispatch({
        type: EMAIL_UPDATE_STATUS_SUCCESS,
        data: data.data,
        // total: total,
        message: data.message,
        perPage: PER_PAGE_DEFAULT,
      });

      if (callbackFun) callbackFun(data.data);
      return Promise.resolve(response);
    } else {
      dispatch(setError(RES_MSG, EMAIL_UPDATE_STATUS_FAILED));
    }
  } catch (error) {
    ErrorCatch(error, EMAIL_UPDATE_STATUS_FAILED, dispatch);
  }
};

export const approveEmailAndSend = (
  payload,
  callbackFun,
  isDownload = false,
) => async dispatch => {
  dispatch(setLoading(EMAIL_APPROVE_REQUEST));
  try {
    const response = await Axios.post(`/notify/approve-email`, payload);
    const { data } = response;
    // const total = data?.data?.length
    if (response.status === 200) {
      dispatch(setSuccess(data.message, EMAIL_APPROVE_SUCCESS));
      dispatch({
        type: EMAIL_APPROVE_SUCCESS,
        data: data.data,
        // total: total,
        message: data.message,
        perPage: PER_PAGE_DEFAULT,
      });
      if (callbackFun) callbackFun(data.data);
    } else {
      dispatch(setError(RES_MSG, EMAIL_APPROVE_FAILED));
    }
  } catch (error) {
    ErrorCatch(error, EMAIL_APPROVE_FAILED, dispatch);
  }
};

export const adminEmailSend = (
  payload,
  callbackFun,
  isDownload = false,
) => async dispatch => {
  dispatch(setLoading(EMAIL_CREATE_REQUEST));
  try {
    const response = await Axios.post(`/notify/send-admin-email`, payload);
    const { data } = response;
    // const total = data?.data?.length
    if (response.status === 200) {
      dispatch(setSuccess(data.message, EMAIL_CREATE_SUCCESS));
      dispatch({
        type: EMAIL_CREATE_SUCCESS,
        data: data.data,
        // total: total,
        message: data.message,
        perPage: PER_PAGE_DEFAULT,
      });
      if (callbackFun) callbackFun(data.data);
    } else {
      dispatch(setError(RES_MSG, EMAIL_CREATE_FAILED));
    }
  } catch (error) {
    ErrorCatch(error, EMAIL_CREATE_FAILED, dispatch);
  }
};

// export const fetchSupplierByPlan = (
//   payload,
//   callbackFun,
//   isDownload = false,
// ) => async dispatch => {
//   dispatch(setLoading(EMAIL_FETCH_SUPPLIER_PLAN_REQUEST));
//   try {
//     const response = await Axios.post(`/auth/fetch_users_by_plan`, payload);
//     const { data } = response;
//     // const total = data?.data?.length
//     if (response.status === 200) {
//       dispatch(setSuccess(data.message, EMAIL_FETCH_SUPPLIER_PLAN_SUCCESS));
//       dispatch({
//         type: EMAIL_FETCH_SUPPLIER_PLAN_SUCCESS,
//         data: data.data,
//         // total: total,
//         message: data.message,
//         perPage: PER_PAGE_DEFAULT,
//       });
//       if (callbackFun) callbackFun(data.data);
//     } else {
//       dispatch(setError(RES_MSG, EMAIL_FETCH_SUPPLIER_PLAN_FAILED));
//     }
//   } catch (error) {
//     ErrorCatch(error, EMAIL_CREATE_FAILED, dispatch);
//   }
// };

// ----------------------------------------------------------

export const fetchSupplierByPlan = (payload) => {
  return dispatch => {
    dispatch(setLoading(EMAIL_FETCH_SUPPLIER_PLAN_REQUEST));
    return Axios.post('/auth/fetch_users_by_plan', payload)
      .then(response => {
        if (response?.status === 200) {
          const { data } = response;
          dispatch(setSuccess(data.message, EMAIL_FETCH_SUPPLIER_PLAN_SUCCESS));
          dispatch({
            type: EMAIL_FETCH_SUPPLIER_PLAN_SUCCESS,
            data: data.data,
            // total: total,
            message: data.message,
            perPage: PER_PAGE_DEFAULT,
          });
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        ErrorCatch(err, EMAIL_FETCH_SUPPLIER_PLAN_FAILED, dispatch);
        return Promise.resolve(err);
      });
  };
};


// ---------------------------------------------------------

export const fetchCategoryBySearch = ({ search }) => {
  return dispatch => {
    return Axios.get('/auth/fetch_category_by_search', { params: { search } })
      .then(response => {
        if (response?.status === 200) {
          const { data } = response;
          dispatch({
            type: EMAIL_FETCH_CATEGORY_SUCCESS,
            data: data.data,
            message: data.message,
            perPage: PER_PAGE_DEFAULT,
          });
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        ErrorCatch(err, EMAIL_FETCH_CATEGORY_FAILED, dispatch);
        return Promise.resolve(err);
      });
  };
};

export const fetchEmailList = data => {
  return dispatch => {
    return Axios.post('/notify/fetch-email-limit', data)
      .then(response => {
        if (response?.status === 200) {
          const { data } = response;
          dispatch({
            type: EMAIL_FETCH_CATEGORY_SUCCESS,
            data: data.data,
            message: data.message,
            perPage: PER_PAGE_DEFAULT,
          });
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        ErrorCatch(err, EMAIL_FETCH_CATEGORY_FAILED, dispatch);
        return Promise.resolve(err);
      });
  };
};

export const UpgradeEmailList = data => {
  return dispatch => {
    return Axios.put('notify/update-email-limit', data)
      .then(response => {
        if (response?.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        ErrorCatch(err, EMAIL_FETCH_CATEGORY_FAILED, dispatch);
        return Promise.resolve(err);
      });
  };
};

//For setting pop
export const setEmailListPopUp = status => async dispatch => {
  dispatch({
    type: SET_EMAIL_LIST_POPUP,
    data: status,
  });
};

//For setting Filtertype
export const setFilterType = filterType => async dispatch => {
  dispatch({
    type: SET_FILTER_TYPE,
    data: filterType,
  });
};

export const setSelectedMail = data => async dispatch => {
  dispatch({
    type: SET_SELECTED_MAIL,
    data: data,
  });
};

export const getReScheduleEmails = ({ page }) => {
  return dispatch => {
    return Axios.get('notify/schedule-email-list', { params: { page } })
      .then(response => {
        if (response?.status === 200) {
          dispatch({
            type: EMAIL_LIST_SUCCESS,
            data: response?.data.data,
            total: response?.data.data.length,
            message: '',
            perPage: PER_PAGE_DEFAULT,
          });
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        return Promise.resolve(err);
      });
  };
};

export const cancelReScheduleEmails = id => {
  return dispatch => {
    return Axios.put(`notify/cancel-schedule-email/${id}`)
      .then(response => {
        if (response?.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        return Promise.resolve(err);
      });
  };
};

export const sandItEmail = id => {
  return dispatch => {
    return Axios.post(`notify/send-email/${id}`)
      .then(response => {
        if (response?.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        return Promise.resolve(err);
      });
  };
};

export const forwardEmail = data => {
  return dispatch => {
    return Axios.post('notify/forward-email', data)
      .then(response => {
        if (response?.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        return Promise.resolve(err);
      });
  };
};

export const reScheduleEmail = (id, data) => {
  return dispatch => {
    return Axios.put(`notify/reschedule-email/${id}`, data)
      .then(response => {
        if (response?.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        return Promise.resolve(err);
      });
  };
};

export const bulkUpdateStatus = (payload, callbackFun) => async dispatch => {
  dispatch(setLoading(BULK_EMAIL_REQUEST));
  try {
    const response = await Axios.post(`/notify/bulk-update-status`, payload);
    const { data } = response;
    if (response.status === 200) {
      dispatch(setSuccess(data.message, BULK_EMAIL_SUCCESS));
      dispatch({
        type: BULK_EMAIL_SUCCESS,
        data: data.data,
        message: data.message,
      });
      if (callbackFun) callbackFun(data.data);
    } else {
      dispatch(setError(MSG_ERR_RESPONDING_SERVER, BULK_EMAIL_FAILED));
    }
  } catch (error) {
    ErrorCatch(error, BULK_EMAIL_FAILED, dispatch);
  }
};

export const updateUserPassword = (payload, callbackFun) => async dispatch => {
  dispatch(setLoading(EMAIL_PASSWORD_UPDATE_REQUEST));
  try {
    const { data } = await Axios.put(`/user/change-password`, payload);
    if (data.statusCode === 200) {
      dispatch(setSuccess(data.responseMsg, EMAIL_PASSWORD_UPDATE_SUCCESS));
      dispatch({
        type: EMAIL_PASSWORD_UPDATE_SUCCESS,
        data: data.data.userUpdatedPassword,
        message: data.responseMsg,
      });
      if (callbackFun) callbackFun(data.data.userUpdatedPassword);
    } else {
      dispatch(
        setError(MSG_ERR_RESPONDING_SERVER, EMAIL_PASSWORD_UPDATE_FAILED),
      );
    }
  } catch (error) {
    ErrorCatch(error, EMAIL_PASSWORD_UPDATE_FAILED, dispatch);
  }
};

export const changePassword = (
  roleId,
  payload,
  callbackFun,
) => async dispatch => {
  dispatch(setLoading(EMAIL_UPDATE_REQUEST));
  try {
    const { data } = await Axios.put(`/user/change-password`, payload);
    if (data.statusCode === 200) {
      dispatch(setSuccess(data.responseMsg, EMAIL_UPDATE_SUCCESS));
      dispatch({
        type: EMAIL_UPDATE_SUCCESS,
        data: data.user,
        message: data.message,
      });
      if (callbackFun) callbackFun(data.data.userUpdatedPassword);
    } else {
      dispatch(setError(MSG_ERR_RESPONDING_SERVER, EMAIL_UPDATE_FAILED));
    }
  } catch (error) {
    ErrorCatch(error, EMAIL_UPDATE_FAILED, dispatch);
  }
};

export const setUserDetail = user => {
  return dispatch => {
    dispatch({
      type: SET_EMAIL_DETAILS,
      data: user,
    });
  };
};

export const createUser = (payload, callbackFun) => async dispatch => {
  dispatch(setLoading(EMAIL_CREATE_REQUEST));
  try {
    const response = await Axios.post(`/auth/add_admin_user`, payload);
    const { data } = response;
    if (response.status === 200) {
      dispatch(setSuccess(data.message, EMAIL_CREATE_SUCCESS));
      dispatch({
        type: EMAIL_CREATE_SUCCESS,
        data: data.user,
        message: data.message,
      });
      if (callbackFun) callbackFun(data.user);
    } else {
      dispatch(setError(MSG_ERR_RESPONDING_SERVER, EMAIL_CREATE_FAILED));
    }
  } catch (error) {
    ErrorCatch(error, EMAIL_CREATE_FAILED, dispatch);
  }
};

//take user id for delete api request
export const deleteUser = (userId, callbackFun) => async dispatch => {
  dispatch(setLoading(EMAIL_DELETE_REQUEST));
  try {
    const { data } = await Axios.delete(`/user/delete/${userId}`);
    if (data.statusCode === 200) {
      dispatch(setSuccess(data.responseMsg, EMAIL_DELETE_SUCCESS));
      dispatch({
        type: EMAIL_DELETE_SUCCESS,
        data: data.data.userDelete,
        message: data.responseMsg,
      });
      if (callbackFun) callbackFun(data.data.userDelete);
    } else {
      dispatch(setError(MSG_ERR_RESPONDING_SERVER, EMAIL_DELETE_FAILED));
    }
  } catch (error) {
    ErrorCatch(error, EMAIL_DELETE_FAILED, dispatch);
  }
};
