import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { folderList } from '@fake-db/apps/mail';
// import { folderList } from '../../../../@fake-db/apps/mail';
import { alpha, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ArchiveIcon from '@material-ui/icons/Archive';
import DeleteIcon from '@material-ui/icons/Delete';
import LabelIcon from '@material-ui/icons/Label';
import FolderIcon from '@material-ui/icons/Folder';
import ReportIcon from '@material-ui/icons/Report';
import Hidden from '@material-ui/core/Hidden';
import DoneIcon from '@material-ui/icons/Done';
import {
  Cancel,
  Archive,
  Delete,
  Inbox,
  Reply,
  CancelScheduleSend,
  Send
} from '@material-ui/icons';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import { emailStatusChange, sendIt } from 'redux/actions/Manage/Emails/Emails';
import { onNullifyMail } from 'redux/actions/MailApp';

const useStyles = makeStyles(theme => ({
  borderLeft: {
    width: 1,
    height: 36,
    backgroundColor: alpha(theme.palette.common.dark, 0.12),
    marginLeft: 4,
  },
  iconBtn: {
    [theme.breakpoints.down('sm')]: {
      padding: 6,
      '& .MuiSvgIcon-root': {
        fontSize: 18,
      },
    },
  },
  menuItemsRoot: {
    fontSize: 16,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    '& .MuiSvgIcon-root': {
      display: 'block',
      fontSize: 20,
    },
  },
}));

const HeaderOptions = ({
  selectedMail,
  onChangeMailFolder,
  onSelectLabel,
  labelsList,
}) => {
  const [showLabels, setShowLabels] = useState(null);
  const [showFolders, setShowFolders] = useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { filterType } = useSelector(({ mailApp }) => mailApp);
  const [actionName, setActionName] = useState('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const onShowLabels = event => {
    setShowLabels(event.currentTarget);
  };

  const onHideLabels = () => {
    setShowLabels(null);
  };

  const onShowFolders = event => {
    setShowFolders(event.currentTarget);
  };

  const onHideFolders = () => {
    setShowFolders(null);
  };

  const onClickLabelOption = label => {
    onSelectLabel(label.id);
    onHideLabels();
  };

  const onClickFolderOption = folder => {
    onChangeMailFolder(folder.slug);
    onHideFolders();
  };

  const handleAction = action => {
    setOpenConfirmDialog(true);
    setActionName(action);
  };

  const handleConfirmAction = () => {
    dispatch(
      emailStatusChange({ ids: [selectedMail?._id], status: actionName }),
    ).then(res => {
      if (res?.status === 200) {
        dispatch(onNullifyMail());
        setOpenConfirmDialog(false);
      }
    });
  };

  const handleSendItConfirm = () => {
    dispatch(sendIt(selectedMail?._id)).then(res => {
      if (res?.status === 200) {
        setOpenConfirmDialog(false);
        dispatch(onNullifyMail());
      }
    });
  };

  return (
    <React.Fragment>
      {filterType?.selectedFolder === 'inbox' ? (
        <Box ml={1}>
          <Tooltip title="Disapprove">
            <IconButton
              className="icon-btn"
              onClick={() => handleAction('disapprove')}
            >
              <Cancel />
            </IconButton>
          </Tooltip>
        </Box>
      ) : null}

      {filterType?.selectedFolder === 'disapprove' ||
      filterType?.selectedFolder === 'trash' ||
      filterType?.selectedFolder === 'archive' ? (
        <Box ml={1}>
          <Tooltip title="Inbox">
            <IconButton
              className="icon-btn"
              onClick={() => handleAction('inbox')}
            >
              <Inbox />
            </IconButton>
          </Tooltip>
        </Box>
      ) : null}

      {filterType?.selectedFolder === 'disapprove' ||
      filterType?.selectedFolder === 'trash' ||
      filterType?.selectedFolder === 'inbox' ||
      filterType?.selectedFolder === 'approve' ? (
        <Box ml={1}>
          <Tooltip title="Archive">
            <IconButton
              className="icon-btn"
              onClick={() => handleAction('archive')}
            >
              <Archive />
            </IconButton>
          </Tooltip>
        </Box>
      ) : null}

      {filterType?.selectedFolder === 'disapprove' ||
      filterType?.selectedFolder === 'archive' ||
      filterType?.selectedFolder === 'inbox' ||
      filterType?.selectedFolder === 'approve' ? (
        <Box ml={1}>
          <Tooltip title="Trash">
            <IconButton
              className="icon-btn"
              onClick={() => handleAction('trash')}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </Box>
      ) : null}

      {!selectedMail?.isCanceled && filterType?.selectedFolder === 'scheduled' ? (
        <Box ml={1}>
          <Tooltip title="Cancel Schedule">
            <IconButton
              className="icon-btn"
              onClick={() => handleAction('cancel')}
            >
              <CancelScheduleSend />
            </IconButton>
          </Tooltip>
        </Box>
      ) : null}

      {selectedMail?.isCanceled &&  filterType?.selectedFolder === 'scheduled' ? (
        <Tooltip title="Send">
          <IconButton
            className="icon-btn"
            onClick={() => {
              handleAction('send');
            }}
          >
            <Send />
          </IconButton>
        </Tooltip>
      ) : null}

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm ${actionName} `}
        content={
          actionName === 'cancel'
            ? 'Are you sure, you want to cancel this schedule email?'
            : actionName === 'send'
            ? 'Are you sure, you want to send this email immediately?'
            : actionName === 'inbox'
            ? 'Are you sure, you want to move this email  to the inbox?'
            : `Are you sure, you want to ${actionName} this email?`
        }
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={
          actionName === 'send' ? handleSendItConfirm : handleConfirmAction
        }
      />

      
    </React.Fragment>
  );
};

export default HeaderOptions;

HeaderOptions.prototype = {
  onChangeMailFolder: PropTypes.func,
  onSelectLabel: PropTypes.func,
  labelsList: PropTypes.array.isRequired,
};
