import CmtCardMedia from '@coremat/CmtCard/CmtCardMedia';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import { Box, makeStyles, Switch, Typography } from '@material-ui/core';
import {
  Add,
  Edit,
  FilterList,
  MoreHoriz,
  Visibility,
  Link,
} from '@material-ui/icons';
import Delete from '@material-ui/icons/Delete';
import CustomTable from 'components/CustomeTable';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_MATERIAL_CREATE_SUCCESS,
  PRODUCT_UPDATE_SUCCESS,
  SUPPLIER_CREATE_SUCCESS,
  SUPPLIER_DELETE_SUCCESS,
  SUPPLIER_UPDATE_SUCCESS,
} from 'redux/actions';
import {
  deleteProduct,
  getAllProducts,
  setProductDetail,
  setProductElmDetail,
  updateProduct,
} from 'redux/actions/Manage/Products/Products';
import {
  getAllSupplier,
  deleteSupplier,
  setSupplierDetail,
  fetchProductList,
  deleteSupplierDetail,
  downloadSupplierDataCsv,
} from 'redux/actions/Manage/Suppliers/Suppliers';
import AddEditSupplier from './AddContact';
import AddProductToSupplier from './AddProductToSupplier';
import FilterForm from './Filter';
import ViewProductList from './VIewProductLIst';
import ViewSupplierDetails from './ViewSupplierDetails';
import CategoryIcon from '@mui/icons-material/Category';
import DeleteIcon from '@mui/icons-material/Delete';
import Axios from 'axios';
import { Button } from '@mui/material';

const useStyles = makeStyles(theme => ({
  imageRoot: {
    width: '140px',
    height: '140px',
    backgroundSize: 'contain',
    objectFit: 'contain',
  },
}));
const Supplier = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  //------------all use selectors
  const { user } = useSelector(({ auth }) => auth.authUser);
  const userData = user;
  const { suppliers, count, total, perPage } = useSelector(
    state => state.Suppliers,
  );
  const SupplierData = useSelector(state => state.Suppliers);
  const productDetail = SupplierData?.suppliers;

  //-------------all use states
  const [orderBy, setOrderBy] = React.useState('');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [productsFetched, setproductsFetched] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [row, setRow] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [permissionData] = useState(
    JSON.parse(localStorage.getItem('permission')),
  );

  const [updatePermission, setUpdatePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [exportPermission, setExportPermission] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openViewProductDetails, setOpenViewProductDetails] = useState(false);
  const [openViewProductList, setopenViewProductList] = useState(false);
  const [openViewMaterialAdd, setOpenViewMaterialAdd] = useState(false);
  const [filterData, setFilterData] = useState();
  // const [search, setSearch] = useState({ sort: null, field: null });
  const [fileData, setFileData] = useState([]);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [downloadData, setDownloadData] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const successAction = useSelector(state => state.Success.action);

  // const [sort, setSort] = useState({
  //     orderBy: '',
  //     order: 'asc',
  // });

  //-------table config actions
  // filter

  //
  // console.log(suppliers)
  const getUserActions = user => {
    const actions = [];
    actions.push({ action: 'url', label: 'View Live', icon: <Link /> });
    actions.push({ action: 'view', label: 'View', icon: <Visibility /> });
    actions.push({ action: 'edit', label: 'Edit', icon: <Edit /> });
    (userData?.userId === "2092" && userData?.email === "palak@rathinfotech.com") && actions.push({ action: 'delete', label: 'Delete', icon: <DeleteIcon sx={{ color: "red" }} /> });
    actions.push({
      action: 'product_list',
      label: 'Product List',
      icon: <FilterList />,
    });

    actions.push({
      action: 'add_product',
      label: 'Add Product',
      icon: <Add />,
    });
    actions.push({
      action: 'hot_product',
      label: 'Hot Product',
      icon: <CategoryIcon />,
    });
    // actions.push({ action: 'delete', label: 'Delete', icon: <Delete /> });
    return actions;
  };
  const userActions = getUserActions(row);
  const onUserMenuClick = (menu, elm) => {
    if (menu.action === 'url') {
      window.open(`https://www.thepipingmart.com/supplier/${elm.url}`);
      //  window.open(
      //    config.REACT_APP_API_BASE_URL === 'https://api.pipingmart.co.in'
      //      ? `http://pipingmart.co.in/supplier/${elm.url}`
      //      : `https://www.thepipingmart.com/supplier/${elm.url}`,
      //  );
    } else if (menu.action === 'view') {
      dispatch(setSupplierDetail(elm.userId));
      setOpenViewProductDetails(true);
    } else if (menu.action === 'add_supplier') {
      dispatch(setSupplierDetail(elm));
      setOpenViewMaterialAdd(true);
      // onUserChangePassword(row);
    } else if (menu.action === 'edit') {
      // dispatch(setSupplierDetail(elm));
      setOpenUserDialog(true);
      // history.push(`/manage-edit-supplier/${elm.userId}`);
      history.push(`/manage-edit-supplier/${elm.userId}`);
    } else if (menu.action === 'product_list') {
      history.push(`/view-supplier-products/${elm.userId}`);
      // dispatch(fetchProductList(elm.userId));
      // dispatch(setSupplierDetail(elm));
      // setopenViewProductList(true);
    } else if (menu.action === 'add_product') {
      // dispatch(setSupplierDetail(elm));
      history.push(`/manage-add-productsupplier/${elm.userId}`);
    } else if (menu.action === 'hot_product') {
      // dispatch(setSupplierDetail(elm));
      history.push(`/manage-hot-productsupplier/${elm.userId}`);
    }
    else if (menu.action === 'delete') {
      dispatch(deleteSupplierDetail(elm?.userId)).then(() => {
        let params;
        params = {
          search: searchTerm,
        };
        dispatch(getAllSupplier(params), () => {
          setproductsFetched(true);
        });
      })
    }
  };

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'logo',
      numeric: false,
      disablePadding: false,
      label: 'Supplier Logo',
      sort: false,
      render: elm => (
        <>
          {' '}
          <Box>
            <img className={classes.imageRoot} src={elm.logo} />
          </Box>
        </>
      ),
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      sort: true,
      label: 'Registration Date',
      render: elm => {
        let dt = new Date(elm.createdAt)
          .toISOString()
          .substring(0, 10)
          .split('-');
        return <Typography>{`${dt[2]}-${dt[1]}-${dt[0]}`}</Typography>;
      },
    },
    {
      id: 'city',
      numeric: false,
      disablePadding: false,
      sort: false,
      label: 'City',
      render: elm => <Typography>{elm.city}</Typography>,
    },
    {
      id: 'count',
      numeric: false,
      disablePadding: false,
      sort: true,
      label: 'Email Received',
      // render: elm => {
      //   SupplierData.count.map(el => {
      //     return el._id == elm.userId ? (
      //       <Typography>{el.count}</Typography>
      //     ) : (
      //       <Typography>avch</Typography>
      //     );
      //   });
      // },
      render: elm => <Typography>{elm.count}</Typography>,
    },
    {
      id: 'membertype',
      numeric: false,
      disablePadding: false,
      sort: true,
      label: 'Member Type',
      render: elm => <Typography>{elm?.planId?.name}</Typography>,
    },
    {
      id: 'select_pcount',
      numeric: false,
      disablePadding: false,
      sort: true,
      label: 'Selected Products',
      render: elm => <Typography>{elm.select_pcount}</Typography>,
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Actions',
      sort: false,
      render: elm => (
        <>
          {
            <CmtDropdownMenu
              items={userActions}
              onItemClick={menu => onUserMenuClick(menu, elm)}
              TriggerComponent={<MoreHoriz />}
            />
          }
        </>
      ),
    },
  ];

  var fileHeaders = [
    {
      key: 'firstName',
      label: 'Name',
    },
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'roleName',
      label: 'Role',
    },
    {
      key: 'departmentName',
      label: 'Dept',
    },
    {
      key: 'status',
      label: 'Status',
    },
  ];

  const handleCloseViewProductList = () => {
    setopenViewProductList(false);
    // dispatch(setProductDetail(null));
  };
  const handleCloseViewMaterialAdd = () => {
    setOpenViewMaterialAdd(false);
    dispatch(setProductDetail(null));
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleViewDialog = user => {
    dispatch(setProductDetail(user));
    setOpenViewDialog(true);
  };
  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    dispatch(setProductDetail(null));
  };
  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    dispatch(setProductDetail(null));
  };
  const handleAddButton = () => {
    setOpenUserDialog(true);
    history.push('/manage-add-supplier');
  };
  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };
  // const handleConfirmDelete = () => {
  //   setOpenConfirmDialog(false);
  //   // dispatch(
  //   //   deleteSupplier(selectedUser, data => {
  //   //     if (data) setDataUpdated(data => !data);
  //   //   }),
  //   // );
  //   dispatch(deleteSupplier(selectedUser)).then(() => {
  //     let params;
  //     params = {
  //       search: searchTerm,
  //     };

  //     dispatch(getAllSupplier(params), () => {
  //       setproductsFetched(true);
  //     });
  //   });
  // };
  const handleCloseViewProductDetails = () => {
    setOpenViewProductDetails(false);
    dispatch(setProductElmDetail(null));
  };
  const handleSearch = dateset_name => {
    setPage(1);
    setSearchTerm(dateset_name);
  };
  const handleSubmit = (
    event,
    {
      name,
      startDate,
      endDate,
      cities,
      membertype,
      emailsReceived,
      selectedProducts,
      inc,
      productDetails,
    },
  ) => {
    event.preventDefault();

    const filters = {
      name: name,
      inc,
      startDate: startDate ? startDate : '',
      endDate: endDate ? endDate : '',
      cities,
      membertype: membertype.name,
      emailsReceived,
      selectedProducts,
      productDetails,
    };

    dispatch(getAllSupplier(filters), () => {
      // console.log('done');
    });
  };
  const handleClearFilter = () => {
    let params;
    params = {
      search: searchTerm,
    };

    dispatch(getAllSupplier(params), () => {
      setproductsFetched(true);
    });
  };
  //

  const handleDownloadCsv = function(){
    dispatch(downloadSupplierDataCsv())
  }

  //------all useEffects
  useEffect(() => {
    let params;
    params = {
      search: searchTerm,
    };

    dispatch(getAllSupplier(params), () => {
      setproductsFetched(true);
    });
    // getPermission()
  }, [dispatch, searchTerm, dataUpdated]);

  // useEffect(() => { //to download data
  //     let params
  //     params = {
  //         search: searchTerm
  //     }
  //     // if (search) {
  //     //     params = {
  //     //         ...params,
  //     //         ...search
  //     //     };
  //     // }
  //     downloadFn(params)
  // }, [isDownload])

  // useEffect(() => {
  //     setDownloadData(data => !data) //update state at custom toolbar component
  // }, [fileData])
  useEffect(() => {
    if (
      successAction === SUPPLIER_UPDATE_SUCCESS ||
      successAction === SUPPLIER_CREATE_SUCCESS ||
      successAction === SUPPLIER_DELETE_SUCCESS
    ) {
      let params;
      params = {
        search: searchTerm,
      };

      dispatch(getAllSupplier(params), () => {
        setproductsFetched(true);
      });
    }
  }, [successAction]);

  return (
    <>
      <FilterForm
        setFilterData={setFilterData}
        handleSubmit={handleSubmit}
        handleClearFilter={handleClearFilter}
      />
      <Box my={3} textAlign={"end"}>
        <Button variant='contained' onClick={handleDownloadCsv}>Download CSV</Button>
      </Box>
      <CustomTable
        TableName="suppliers"
        downloadTableBtn={exportPermission ? true : false}
        fileName="suppliers.xlsx"
        fileHeaders={fileHeaders}
        fileData={fileData}
        setIsDownload={setIsDownload}
        downloadData={downloadData}
        row={suppliers && suppliers}
        addButtonText={'Add Supplier'}
        handleAddButton={handleAddButton}
        headCells={headCells}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        count={total}
        rowsPerPage={perPage}
        // filter={filter}
        onPageChange={handlePageChange}
        page={page}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleSearch={handleSearch}
        handleViewDialog={handleViewDialog}
        productDetailModal={{
          emailData: productDetail?.email,
          modalHeading: productDetail?.name,
          headerArray: [
            'MemberType: ' + productDetail?.membertype,
            productDetail?.address && 'Address: ' + productDetail?.address,
          ],
          sectionHeading: 'Contact Details',
          openViewDialog: openViewDialog,
          handleCloseViewDialog: handleCloseViewDialog,
          activeChip: productDetail?.active,
          phoneData: productDetail?.mobileNo,
        }}
      />
      {openUserDialog && (
        <AddEditSupplier
          setDataUpdated={setDataUpdated}
          open={openUserDialog}
          onCloseDialog={handleCloseUserDialog}
        />
      )}
      {openViewProductDetails && (
        <ViewSupplierDetails
          open={openViewProductDetails}
          onCloseDialog={handleCloseViewProductDetails}
        />
      )}
      {openViewProductList && (
        <ViewProductList
          open={openViewProductList}
          onCloseDialog={handleCloseViewProductList}
        />
      )}
      {openUserDialog && (
        <AddProductToSupplier
          setDataUpdated={setDataUpdated}
          open={openUserDialog}
          onCloseDialog={handleCloseUserDialog}
        />
      )}
      {/* {openViewGradeList && (
        <ViewGradeList
          open={openProductList}
          onCloseDialog={handleCloseViewProductist}
        />
      )} */}
      {/* <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete`}
        content={'Are you sure, you want to  delete this Supplier?'}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      /> */}
      {/* <FilterForm/> */}
    </>
  );
};

export default Supplier;
