import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import Auth from "./Auth";
import Common from "./Common";
import { ErrorReducer } from "./Common/ErrorReducer";
import { LoadingReducer } from "./Common/LoadingReducer";
import { SuccessReducer } from "./Common/SuccessReducer";
import Emails from "./Manage/Emails";
import { SetProductMaterialGradeListReducer, SetsupplierDetailReducer, SetSupplierHotProductReducer, SetSupplierProductDetail, SuppliersReducer } from "./Manage/Suppliers";
import MailApp from './MailApp';
import { uploadFileReducer } from './FileUpload';
import { CustomersReducer, SetCustomerDetailReducer } from './Manage/Customers';
import { GradesReducer, SetGradeDetailReducer } from './Manage/ProductMaterialGrades';
import { MaterialsReducer, SetMaterialDetailReducer } from './Manage/Materials';
import { PermissionsReducer } from './Manage/Permissions';
import { ProductsReducer, SetProductDetailReducer } from './Manage/Products';
import { RolesReducer, SetRoleDetailReducer } from './Manage/Roles';
import { SetUserDetailReducer, UsersReducer } from './Manage/Users';
import { SetVendorDetailReducer, VendorsReducer } from './Manage/Vendors';
import { BlockEmailReducer } from './Manage/EmailBlock';
import { SetSubProductDetailReducer, SubProductReducer } from "./Manage/SubProduct";
import { EqGradeReducer, SetEqGradeDetailReducer, SetGradeListForEqGrade } from "./Manage/EqGrades";
import { ProductMaterialsReducer, SetProductMaterialDetailReducer } from "./Manage/ProductMaterial";
import { NewsReducer, SetNewsDetailReducer } from "./Manage/News";
import { GradeModalReducer } from "./Manage/GradeModal";
import { MaterialGradeReducer } from "./Manage/MaterialGrade";
import { ProductMaterialSubProductReducer } from "./Manage/ProductMaterialSubProduct";
import { DashBoardReducer } from "./Manage/DashBoard";
import { PlanReducer } from "./Manage/Plan";
import { RfqReducer } from "./Manage/Rfq";
import { BlockIpReducer } from "./Manage/IpAddressBlock";
import { freeSupplierReducer, sendRfqEmailReducer } from "./Manage/SendRfqEmail";

export default history =>
  combineReducers({
    Roles: RolesReducer,
    SetRoleDetail: SetRoleDetailReducer,
    Permissions: PermissionsReducer,
    Users: UsersReducer,
    SetUserDetail: SetUserDetailReducer,
    Products: ProductsReducer,
    SetProductDetail: SetProductDetailReducer,
    SubProducts: SubProductReducer,
    SetSubProductDetail: SetSubProductDetailReducer,
    Materials: MaterialsReducer,
    SetMaterialDetail: SetMaterialDetailReducer,
    ProductMaterials: ProductMaterialsReducer,
    SetProductMaterial: SetProductMaterialDetailReducer,
    Grades: GradesReducer,
    SetGradeDetail: SetGradeDetailReducer,
    EqGrades: EqGradeReducer,
    SetEqGradeDetail: SetEqGradeDetailReducer,
    SetGradeListForEqGrade: SetGradeListForEqGrade,
    imageUpload: uploadFileReducer,
    Emails: Emails,
    Customers: CustomersReducer,
    SetCustomerDetail: SetCustomerDetailReducer,
    Suppliers: SuppliersReducer,
    SetSupplierDetail: SetsupplierDetailReducer,
    SetProductMaterialGradeList: SetProductMaterialGradeListReducer,
    Vendors: VendorsReducer,
    SetVendorDetail: SetVendorDetailReducer,
    SetSupplierProdDetail: SetSupplierProductDetail,
    router: connectRouter(history),
    Loading: LoadingReducer,
    Error: ErrorReducer,
    Success: SuccessReducer,
    auth: Auth,
    common: Common,
    mailApp: MailApp,
    emailBlock: BlockEmailReducer,
    ipBlock: BlockIpReducer,
    freeSupplier:freeSupplierReducer,
    sendRfqEmail:sendRfqEmailReducer,
    
    News:NewsReducer,
    SetNewsDetail:SetNewsDetailReducer,
    GradeModel:GradeModalReducer,
    MaterialGrade:MaterialGradeReducer,
    ProductMaterialSubProduct:ProductMaterialSubProductReducer,
    DashBoard: DashBoardReducer,
    Plan: PlanReducer,
    Rfq:RfqReducer,
    SetSupplierHotProductReducer:SetSupplierHotProductReducer
  });

// export default history => {
//   if (history.location.pathname === '/signin')
//     combineReducers({
//       default: (state = undefined, action = undefined) => { return state; },
//       router: connectRouter(history),
//     })
//   else
//     appReducer(history)
// }
// const rootReducer = (state, action) => {
//   console.log(state, action, "ok")
//   // Clear all data in redux store to initial.
//   if (action.type === USER_LOGOUT_SUCCESS)
//     state = undefined;

//   return appReducer(history);
// };
// export default rootReducer;
// const rootReducer = (state, action) => {
//   console.log("state", state)
//   console.log("action", action)
//   if (action.type === 'USER_LOGOUT_SUCCESS') return appReducer(undefined, action);
//   return appReducer(state, action);
// };
// export default rootReducer

// const resetEnhancer = appReducer => (state, action) => {
//   if (action.type !== 'USER_LOGOUT_SUCCESS') return appReducer(state, action);
//   const newState = appReducer(undefined, {});
//   newState.router = state.router;
//   return newState;
// };
// export default resetEnhancer
