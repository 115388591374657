import { RESET_PASSWORD_SUCCESS, SEND_FORGET_PASSWORD_EMAIL, SESSION_EXPIRED, UPDATE_AUTH_USER, UPDATE_FAILED_USER, UPDATE_LOAD_USER, USER_LOGIN_SUCCESS, USER_LOGOUT_SUCCESS, VERIFIED_OTP } from '@jumbo/constants/ActionTypes';

const INIT_STATE = {
  authUser: null,
  loadUser: false,
  send_forget_password_email: false,
  user_reset_password: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_AUTH_USER: {
      return {
        ...state,
        authUser: action.payload,
        loadUser: true,
      };
    }
    case UPDATE_LOAD_USER: {
      return {
        ...state,
        loadUser: action.payload,
      };
    }
    case UPDATE_FAILED_USER: {
      return {
        ...state,
        authUser: action.payload,
        loadUser: true,
      };
    }
    case USER_LOGIN_SUCCESS: {
      return {
        ...state,
      };
    }
    case USER_LOGOUT_SUCCESS: {
      return {
        ...state,
      };
    }
    case SESSION_EXPIRED: {
      return {
        ...state,
      };
    }
    case SEND_FORGET_PASSWORD_EMAIL: {
      return {
        ...state,
        send_forget_password_email: action.payload,
      };
    }
    case VERIFIED_OTP: {
      return {
        ...state,
        user_reset_password: action.payload
      };
    }
    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        user_reset_password: false,
        send_forget_password_email: false
      };
    }
    default:
      return state;
  }
};
