import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Chip, Divider, Paper } from '@material-ui/core';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { Typography } from '@mui/material';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextareaAutosize from '@mui/base/TextareaAutosize';

import { useDispatch,useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { updateSupplier } from 'redux/actions/Manage/Suppliers/Suppliers';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },

  textarea: {
    width: '100%',
    height: '100%',
    padding: '8px',
    fontSize: ' 0.875rem',
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontWeight: 400,
    borderRadius: '12px 12px 0 12px',
  },
  //   TextareaAutosize:{
  //      width: "100%",
  //     fontFamily: "IBM Plex Sans, sans-serif",
  //     fontSize:" 0.875rem",
  //     // font-weight: 400;
  //     // line-height: 1.5;
  //     // padding: 12px;
  //     // border-radius: 12px 12px 0 12px;
  //   },
}));

const EditQuality = () => {
  const classes = useStyles();
  const history = useHistory();  
  const dispatch = useDispatch();

  const { supplier } = useSelector(state => state.SetSupplierDetail);
  console.log(supplier);

  const onLabelChange = evt => {
    setQualityPolicy({
      ...QualityPolicy,
      [evt.target.name]: evt.target.value,
    });
  };

 const onSubmitClick = () => {
    let data = "test"
    const { _id } = supplier;
    dispatch(updateSupplier(QualityPolicy, _id));
 };

 const [QualityPolicy, setQualityPolicy] = useState({
   qualityPolicy:'',
 });
 console.log(QualityPolicy);
  return (
    <PageContainer>
      <div>
        <Paper className={classes.paper}></Paper>
        <CmtCard>
          <CmtCardContent>
            <Typography variant="h6">Edit Quality Policy</Typography>
            <Divider></Divider>
            <Box
              mt={3}
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
              alignItems="center"
              mb={{ xs: 6, md: 5 }}
            >
              <GridContainer>
                <Grid item xs={12} sm={6}>
                  <textarea
                    className={classes.textarea}
                    defaultValue={supplier.qualityPolicy}
                    border="1px solid black"
                    rows={5}
                    width="100%"
                    name="qualityPolicy"
                    variant="outlined"
                    placeholder="Quality Policy"
                    onChange={onLabelChange}
                  />
                </Grid>
              </GridContainer>
            </Box>
            <Box display="flex" justifyContent="flex-end" mb={4}>
              <Button onClick={() => history.push('/manage-suppliers')}>
                Cancel
              </Button>
              <Box ml={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onSubmitClick}
                >
                  Update
                </Button>
              </Box>
            </Box>
          </CmtCardContent>
        </CmtCard>
      </div>
    </PageContainer>
  );
};

export default EditQuality;
