import CmtCardMedia from '@coremat/CmtCard/CmtCardMedia';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import { Box, makeStyles, Switch, Typography, Button } from '@material-ui/core';
import {
  Add,
  Edit,
  FilterList,
  MoreHoriz,
  Visibility,
  Link
} from '@material-ui/icons';
import Delete from '@material-ui/icons/Delete';
import CustomTable from 'components/CustomeTable';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GRADE_CREATE_SUCCESS,
  GRADE_UPDATE_SUCCESS,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_MATERIAL_CREATE_SUCCESS,
  PRODUCT_UPDATE_SUCCESS,
} from 'redux/actions';
import {
  deleteProduct,
  getAllProducts,
  setProductDetail,
  updateProduct,
} from 'redux/actions/Manage/Products/Products';
import { getAllSupplier, setSupplierDetail } from 'redux/actions/Manage/Suppliers/Suppliers';
import AddMaterialToProduct from './AddEditFaqs';
import ViewGradeDetails from './ViewGradeDetails';
import { getAllGrades, setGradeDetail, setProductGradeElmDetail, updateGrade } from '../../../../redux/actions/Manage/ProductMaterialGrades';
import EditGrade from './EditGrade';
import AddEditFaqs from './AddEditFaqs';
// import ViewEqGradeList from './ViewEqGradeList';
// import AddEqGradeToGrade from './AddEqGradeToGrade';
// import ViewGradeList from './ViewGradeList';
const useStyles = makeStyles(theme => ({
  imageRoot: {
    width: "100px",
    height: "100px",
    backgroundSize: "contain",
    backgroundSize: "cover"
  },
}));
const Grade = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  //------------all use selectors
  const { user } = useSelector(({ auth }) => auth.authUser);
  const { grades, suppliers, count, total, perPage } = useSelector(
    state => state.Grades,
  );
  const SupplierData = useSelector(state => state.Grades);
  const productDetail = SupplierData?.grades;
  //-------------all use states
  const [orderBy, setOrderBy] = React.useState('name');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState({ name: '' });
  const [productsFetched, setproductsFetched] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [row, setRow] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [permissionData] = useState(
    JSON.parse(localStorage.getItem('permission')),
  );
  const [createPermission, setCreatePermission] = useState(false);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [exportPermission, setExportPermission] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openViewProductDetails, setOpenViewProductDetails] = useState(false);
  const [openViewMaterialList, setopenViewMaterialList] = useState(false);
  const [openViewMaterialAdd, setOpenViewMaterialAdd] = useState(false);
  const [openViewAddEqGrade, setOpenViewAddEqGrade] = useState(false);
  // const [openViewEqGradeList, setOpenViewEqGradeList] = useState(false);
  // const [search, setSearch] = useState({ sort: null, field: null });
  const [fileData, setFileData] = useState([]);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [downloadData, setDownloadData] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const successAction = useSelector(state => state.Success.action);

  // const [sort, setSort] = useState({
  //     orderBy: '',
  //     order: 'asc',
  // });

  //-------table config actions

  const getUserActions = user => {
    const actions = [];
    actions.push({ action: 'url', label: 'View Live', icon: <Link /> });
    actions.push({ action: 'view', label: 'View', icon: <Visibility /> });
    actions.push({ action: 'edit', label: 'Edit', icon: <Edit /> });
    // actions.push({action: 'add_edit_faqs',label: 'Add/Edit FAQs',icon: <Add />});
    // actions.push({ action: 'list_eq.grade', label: 'Eq.Grade List', icon: <FilterList /> });
    // actions.push({ action: 'add_eq.grade', label: 'Add Eq.Grade', icon: <Add /> });
    return actions;
  };

  const userActions = getUserActions(row);
  const onUserMenuClick = (menu, elm) => {
    if (menu.action === "url") {
      window.open(`https://www.thepipingmart.com/product/${elm.url}`);
    }
    else if (menu.action === 'view') {
      dispatch(setProductGradeElmDetail(elm));
      setOpenViewProductDetails(true);
    } else if (menu.action === 'edit') {
      // history.push(`/manage-edit-grade/${elm._id}`);
      window.open(`/manage-edit-grade/${elm._id}`,'_blank');
    }
    // } else if (menu.action === 'add_edit_faqs') {
    //   dispatch(setGradeDetail(elm));
    //   setOpenViewMaterialAdd(true);
    // onUserChangePassword(row);
    // }
    // else if (menu.action === 'list_eq.grade') {
    //   dispatch(setGradeDetail(elm));
    //   setopenViewMaterialList(true);
    // } else if (menu.action === 'add_eq.grade') {
    //     // setSelectedUser(elm);
    //     setOpenViewAddEqGrade(true)
    //     history.push('/manage-add-grade-equivalent-grade');
    // }
  };
  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'logo',
      numeric: false,
      disablePadding: false,
      label: 'Grade Image',
      sort: false,
      render: elm => (
        <>
          {' '}
          <Box>
            <img className={classes.imageRoot} src={elm.logo} />
          </Box>
        </>
      ),
    },
    {
      id: 'active',
      numeric: false,
      disablePadding: false,
      sort: false,
      label: 'Status',
      render: (elm) => (
        < Switch
          // defaultChecked={elm.showInMiddle === true ? true : false}
          checked={elm.active === true ? true : false}
          onChange={() => handleChangeStatusSwitch(elm)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      )
    },
    //   {
    //     id: 'showInMiddle',
    //     numeric: false,
    //     disablePadding: false,
    //     sort: false,
    //     label: 'Show in Middle',
    //     render: (elm) => (
    //         < Switch
    //             // defaultChecked={elm.showInMiddle === true ? true : false}
    //             checked={elm.showInMiddle === true ? true : false}
    //             onChange={() => handleChangeSwitch(elm)}
    //             inputProps={{ 'aria-label': 'controlled' }}
    //         />
    //     )
    // },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Actions',
      sort: false,
      render: elm => (
        <>
          {/* {!updatePermission && !deletePermission ? '-' : <CmtDropdownMenu items={userActions} onItemClick={(menu) => onUserMenuClick(menu, elm)} TriggerComponent={<MoreHoriz />} />} */}
          {
            <CmtDropdownMenu
              items={userActions}
              onItemClick={menu => onUserMenuClick(menu, elm)}
              TriggerComponent={<MoreHoriz />}
            />
          }
        </>
      ),
    },
    // {
    //   id: 'url',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Link',
    //   render: elm => (
    //    <Button><a href={`https://www.thepipingmart.com/products/${elm.url}`}>Link</a></Button>
    // )}
  ];
  //download file setting
  var fileHeaders = [
    {
      key: 'firstName',
      label: 'Name',
    },
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'roleName',
      label: 'Role',
    },
    {
      key: 'departmentName',
      label: 'Dept',
    },
    {
      key: 'status',
      label: 'Status',
    },
  ];

  //-------all handle functions

  const handleChangeStatusSwitch = (elm) => {
    let status
    if (elm.active === true)
      status = "false"
    else
      status = "true"
    const data = {
      "gradeId": elm.gradeId,
      "active": status,
      // "idOfProductCreator": user?.userId,
      // "nameOfProductCreator": user?.name    
    }
    dispatch(
      updateGrade(data, (data) => {
        if (data)
          history.push('/manage-product-material-grade')
      }),
    );
  };
  const handleChangeSwitch = elm => {
    console.log(elm.gradeId)
    let showInMiddle;
    if (elm.showInMiddle === true) showInMiddle = 'false';
    else showInMiddle = 'true';
    const data = {
      gradeId: elm.gradeId,
      showInMiddle: showInMiddle,
      idOfProductCreator: user?.userId,
      nameOfProductCreator: user?.name,
    };
    dispatch(
      updateGrade(data, data => {
        if (data) history.push('/manage-product-material-grade');
      }),
    );
  };
  const handleCloseViewMaterialList = () => {
    setopenViewMaterialList(false);
    dispatch(setProductDetail(null));
  };
  const handleCloseViewMaterialAdd = () => {
    setOpenViewMaterialAdd(false);
    dispatch(setProductDetail(null));
  };
  const handleCloseViewAddEqGradeList = () => {
    setOpenViewAddEqGrade(false);
    // dispatch(setProductDetail(null));
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleViewDialog = user => {
    dispatch(setProductDetail(user));
    setOpenViewDialog(true);
  };
  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    dispatch(setProductDetail(null));
  };
  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    dispatch(setProductDetail(null));
  };
  // const handleAddButton = () => {
  //   setOpenUserDialog(true);
  //   dispatch(setProductDetail(null));
  //   history.push('/manage-add-product');
  // };
  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };
  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    dispatch(
      deleteProduct(selectedUser._id, data => {
        if (data) setDataUpdated(data => !data);
      }),
    );
  };
  const handleCloseViewProductDetails = () => {
    setOpenViewProductDetails(false);
    dispatch(setProductGradeElmDetail(null));
  };


  //------all useEffects
  useEffect(() => {
    let params;
    params = {
      search: searchTerm,
      page: page + 1,
      perPage: perPage || 10
    };
    dispatch(getAllGrades(params), () => {
      setproductsFetched(true);
    });
  }, [dispatch, dataUpdated, page]);

  useEffect(() => {
    if (
      successAction === GRADE_UPDATE_SUCCESS ||
      successAction === GRADE_CREATE_SUCCESS
      // || successAction === GRADE_MATERIAL_CREATE_SUCCESS
    ) {
      let params;
      params = {
        search: searchTerm,
        page: page + 1,
        perPage: perPage || 10
      };

      dispatch(getAllGrades(params), () => {
        setproductsFetched(true);
      });
    }
  }, [successAction, page]);
  return (
    <>
      <CustomTable
        TableName="Product Material Grade"
        downloadTableBtn={exportPermission ? true : false}
        fileName="grades.xlsx"
        fileHeaders={fileHeaders}
        fileData={fileData}
        setIsDownload={setIsDownload}
        downloadData={downloadData}
        row={grades && grades}
        isDynamic={true}
        // addButtonText={createPermission ? "Add User" : ""}
        addButtonText={'Add Product Material Grade'}
        handleAddButton={() => history.push('/manage-add-product-material-grade')}
        headCells={headCells}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        count={total}
        rowsPerPage={perPage}
        onPageChange={handlePageChange}
        page={page}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleSearch={text => setSearchTerm(text)}
        handleSearchSubmit={() => {
          setPage(0);
          setDataUpdated(!dataUpdated);
        }}
        handleViewDialog={handleViewDialog}
        productDetailModal={{
          emailData: productDetail?.email,
          modalHeading: productDetail?.name,
          headerArray: [
            'MemberType: ' + productDetail?.membertype,
            productDetail?.address && 'Address: ' + productDetail?.address,
          ],
          sectionHeading: 'Contact Details',
          openViewDialog: openViewDialog,
          handleCloseViewDialog: handleCloseViewDialog,
          activeChip: productDetail?.active,
          phoneData: productDetail?.mobileNo,
        }}
      />
      {/* {openUserDialog && (
        <EditGrade
          setDataUpdated={setDataUpdated}
          open={openUserDialog}
          onCloseDialog={handleCloseUserDialog}
        />
      )} */}
      {openViewProductDetails && (
        <ViewGradeDetails
          open={openViewProductDetails}
          onCloseDialog={handleCloseViewProductDetails}
        />
      )}
      {/* {openViewMaterialList && (
        <ViewEqGradeList
          open={openViewMaterialList}
          onCloseDialog={handleCloseViewMaterialList}
        />
      )} */}
      {/* {openViewMaterialAdd && (
        <AddEditFaqs
          setDataUpdated={setDataUpdated}
          // history={history}
          open={openViewMaterialAdd}
          onCloseDialog={handleCloseViewMaterialAdd}
        />
      )} */}
      {/* {openViewAddEqGrade && (
        <AddEqGradeToGrade
          // setDataUpdated={setDataUpdated}
          history={history}
          open={openViewAddEqGrade}
          onCloseDialog={handleCloseViewAddEqGradeList}
        />
      )} */}
      {/* <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete`}
        content={'Are you sure, you want to  delete this user?'}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      /> */}
    </>
  );
};

export default Grade;
