import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import WysiwygEditorExample from 'components/wysiwygEditor';
import {
  Chip,
  Divider,
  MenuItem,
  Paper,
  Select,
  TextareaAutosize,
  TextField,
} from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';

import {
  clearSupplierDetail,
  createSupplier,
  setSupplierDetail,
  updateSupplier,
} from 'redux/actions/Manage/Suppliers/Suppliers';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import moment from "moment";
import { Redirect, useParams } from 'react-router';
import { includes } from 'lodash';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import CustomEditor from 'components/CustomEditor';
const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  previewContainer: {
    container: 'true',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  preview: {
    xs: '12',
  },
  errorCk: {
    color: '#f44336',
    marginRight: '14px',
    marginTop: '4px',
    fontSize: '0.75rem',
  },
  avatar: {
    borderRadius: '0',
    width: '140px',
    height: '140px',
    backgroundSize: 'contain',
    objectFit: 'contain',
  },
  errorText: {
    color: 'red',
  },
  grid: {
    marginBottom: '-20px',
    marginTop: '-10px',
    display: 'flex',
    flexDirection: 'column',
  },
  textarea: {
    width: '100%',
    height: '80%',
    padding: '8px',
    fontSize: '17px',
    fontFamily: 'inherit',
    fontWeight: 300,
    borderColor: 'lightgray',
    borderRadius: '4px',

    '&:focus': {
      borderColor: '#1C61CA',
      borderWidth: '2px',
      outline: 'none',
      borderRadius: 4,
    },
  },
  textarea_red: {
    width: '100%',
    height: '80%',
    padding: '8px',
    fontSize: '19px',
    fontFamily: 'Normal',
    fontWeight: 200,
    borderColor: '#f44336',
    borderRadius: '4px',
    '&:focus': {
      borderColor: '#1C61CA',
      borderWidth: '2px',
      outline: 'none',
      borderRadius: 4,
    },
  },
}));


const EditContactSupplier = ({ open, onCloseDialog, setDataUpdated }) => {
  const history = useHistory();

  // const plan = [{ name: 'free' }, { name: 'premium' }];
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  //------------all use selectors
  const { user } = useSelector(({ auth }) => auth.authUser);
  const { supplier } = useSelector(state => state.SetSupplierDetail);
  const successAction = useSelector(state => state.Success.action);
  // console.log('supplier: on edit ', supplier);
  //-------------all use states

  const [descriptionHTML, setDescriptionHTML] = useState('');
  const [keywordsArr, setkeywordsArr] = useState([]);
  const [metaCount, setMetaCount] = useState({
    title: 0,
    meta: 0,
  });
  const [file, setFile] = useState([]);
  const [memberType, setMemberType] = useState([{ name: "free" }, { name: "premium" }]);
  const [contact, setContact] = useState({
    companyName: '',
    address: '',
    contactPerson: '',
  });
  const [plan, setPlan] = useState([]);
  const [flag, setFlag] = useState(1);

  //   const [memberType, setMemberType] = useState('');
  const [supplierDetail, setsupplierDetail] = useState({
    idOfSupplierCreator: user?.userId,
    userId: '',
    nameOfSupplierCreator: user?.name,
    url: "",
    telephone: '',
    logo: '',
    mobileNo: '',
    email: '',
    country: '',
    about: '',
    website: '',
    city: '',
    state: '',
    zipCode: '',
    companyName: '',
    address: '',
    AltImageName: '',
    title: "",
    meta: '',
    keywords: null,
    contactPerson: '',
    maxProductListing: '',
    membertype: '',
    preview: '',
    totalProductLimit: '',
    totalEmailLimit: '',
    remainingEmailLimit: '0',
    remainingProductLimit: '',
    planActivationDate: '',
    planName: '',
    planId: '',
    planExpiryDate: '',
  });
  const [errorText, setErrorText] = React.useState({});


  //-------all handle functions
  const getRemainingEmailLimit = async () => {
    let registrationDate = moment(planActivationDate).format("YYYY-MM-DD");
    try {
      let res = await Axios.get(`api/v1/admin/plan/email-limit-calculation?registrationDate=${registrationDate}&emailLimit=${totalEmailLimit}&availableLimit=${remainingEmailLimit}`);
      let data = res.data.data.remainingEmailLimit;
      console.log(data);
      setsupplierDetail({
        ...supplierDetail,
        remainingEmailLimit: data
      })
    } catch (error) {
      console.log(error);
    }
  }

  const getPlan = async () => {
    try {
      let res = await Axios.get('api/v1/admin/plan/list');
      setPlan(res.data.data.planList);
    } catch (error) {
      console.log(error);
    }
  }
  const handleCancel = () => {
    // console.log(history)
    history.push('/manage-suppliers');
    dispatch(clearSupplierDetail());

  };
  const validate = () => {
    const errors = {};
    let isError = false;
    const {
      name,
      telephone,
      mobileNo,
      email,
      url,
      password,
      country,
      website,
      address,
      contactPerson,
      companyName,
      membertype,
      city,
      state,
      zipCode,
      title,
      meta,
      keywords,
    } = supplierDetail;
    if (!companyName) {
      errors.companyName = 'Please enter Company Name.';
      isError = true;
    } else if (!/^[a-zA-Z0-9 ]*$/.test(name)) {
      errors.companyName = 'Only letters and numbers are allowed.';
      isError = true;
    }
    if (!mobileNo) {
      errors.mobileNo = 'Please enter Mobile Number.';
      isError = true;
    } else if (!/^[0-9 +]*$/.test(mobileNo)) {
      errors.mobileNo = 'Only numbers are allowed.';
      isError = true;
    }
    if (!website) {
      errors.website = 'Please enter website.';
      isError = true;
    } else if (!website.includes('www.')) {
      errors.website = 'Invalid website';
      isError = true;
    }
    if (!country) {
      errors.country = 'Please enter country.';
      isError = true;
    }
    if (!keywordsArr.length) {
      errors.keywords = 'Please enter keyword.';
      isError = true;
    }
    if (!meta) {
      errors.meta = 'Please enter meta.';
      isError = true;
    }
    if (!title) {
      errors.title = 'Please enter meta title.';
      isError = true;
    }
    if (!address) {
      errors.address = 'Please enter address.';
      isError = true;
    }
    // if (!telephone ) {
    //   errors.telephone = 'Please enter Telephone.';
    //   isError = true;
    // }
    if (!membertype) {
      errors.membertype = 'Please select';
      isError = true;
    }
    if (!email) {
      errors.email = 'Please enter Email Id.';
      isError = true;
    } else if (!email.includes('@')) {
      errors.email = 'Invalid Email';
      isError = true;
    }
    if (!city) {
      errors.city = 'Please enter City.';
      isError = true;
    }
    if (!state) {
      errors.state = 'Please enter State.';
      isError = true;
    }
    if (!zipCode) {
      errors.zipCode = 'Please enter zipCode.';
      isError = true;
    }
    if (!contactPerson) {
      errors.contactPerson = 'Please enter contactPerson.';
      isError = true;
    }

    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };
  const onLabelChange = evt => {
    setsupplierDetail({
      ...supplierDetail,
      [evt.target.name]: evt.target.value,
    });
    setMetaCount({ ...metaCount, [evt.target.name]: evt.target.value.length })
  };

  const validateKeyword = () => {
    let error = '';
    let isError = false;
    if (!keywords) {
      error = 'Please enter keywords.';
      isError = true;
    }
    setErrorText({ ...errorText, keywords: error });
    return {
      error,
      isError,
    };
  };
  const onKeywordsAdd = () => {
    let validateData = validateKeyword();
    if (!validateData.isError) {
      console.log("------------------------------------------")
      setkeywordsArr(keywordsArr => [...keywordsArr, keywords]);
      setsupplierDetail({ ...supplierDetail, keywords: '' });
    }
  };
  const onKeywordsDelete = (e, value) => {
    setkeywordsArr(keywordsArr => keywordsArr.filter(e => e != value));
  };

  const onTotalEmailLimit = async (e) => {
    setsupplierDetail({
      ...supplierDetail,
      totalEmailLimit: e.target.value,
      remainingEmailLimit: supplier.remainingEmailLimit
    })
    setFlag(pre => pre + 1);
  }
  const onProductLimitChange = async (e) => {
    setsupplierDetail({
      ...supplierDetail,
      totalProductLimit: e.target.value,
      maxProductListing: e.target.value
    })
  }

  // Expiry date function 
  const handleExpiryDateChange = event => {
    setsupplierDetail({ ...supplierDetail, planExpiryDate: event.target.value });
  };


  const onPlanChange = async (evt) => {
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() - 1);
    expiryDate.setMonth(expiryDate.getMonth() + 12);
    let expiryDateString = expiryDate.toISOString()

    let plan_detail = plan.filter((e, index) => e._id === evt.target.value);
    let pre_plan_detail = plan.filter((e, index) => e._id === supplierDetail.planId);

    if (pre_plan_detail[0]?.priority > plan_detail[0]?.priority) {
      console.log(plan_detail)
      setsupplierDetail({
        ...supplierDetail,
        planId: plan_detail[0]?._id,
        membertype: plan_detail[0]?.name,
        planName: plan_detail[0]?.name,
        // totalProductLimit :plan_detail[0].productLimit,
        maxProductListing: plan_detail[0]?.productLimit,
        totalEmailLimit: plan_detail[0]?.emailLimit,
        remainingEmailLimit: supplier?.remainingEmailLimit,
        planActivationDate: new Date(),
        planExpiryDate: expiryDateString,
      });
    } else {
      setsupplierDetail({
        ...supplierDetail,
        planId: plan_detail[0]?._id,
        membertype: plan_detail[0]?.name,
        planName: plan_detail[0]?.name,
        totalProductLimit: plan_detail[0]?.productLimit,
        maxProductListing: plan_detail[0]?.productLimit,
        totalEmailLimit: plan_detail[0]?.emailLimit,
        remainingEmailLimit: supplier?.remainingEmailLimit,
        planActivationDate: new Date(),
        planExpiryDate: expiryDateString,
      });
    }
    // console.log(expiryDate)
    setFlag(pre => pre + 1);
  }
  //   const handleMembertype = evt => {
  //     console.log(evt.target.value,"201")

  //     // setMemberType(evt.target.value);
  //   };
  const onSubmitClick = () => {
    console.log(descriptionHTML, "line 210");
    let check = validate();
    //   setsupplierDetail({
    //     ...supplierDetail,
    //     // membertype: memberType,
    //     about: descriptionHTML,
    //     // logo: file[0],
    //   });
    const { isError } = check
    // console.log(check)
    if (isError != true) {

      onproductSave();
    }
  };

  const onproductSave = () => {
    let keywordStr = '';
    keywordsArr.map(e => (keywordStr += ',' + e))
    let formData = new FormData();
    formData.append('userId', userId);
    formData.append('url', url)
    formData.append('idOfProductCreator', user?.userId);
    formData.append('nameOfProductCreator', user?.name);
    formData.append('telephone', telephone);
    formData.append('logo', file[0]);
    formData.append('mobileNo', mobileNo);
    formData.append('email', email);
    // formData.append('password', password);
    formData.append('country', country);
    if (descriptionHTML == "") {
      formData.append('about', about);
    } else {
      formData.append('about', descriptionHTML);
    }
    formData.append('website', website);
    formData.append('city', city);
    formData.append('state', state);
    formData.append('zipCode', zipCode);
    formData.append('companyName', companyName);
    formData.append('membertype', membertype);
    formData.append('address', address);
    formData.append('AltImageName', AltImageName);
    formData.append('contactPerson', contactPerson);
    formData.append('maxProductListing', maxProductListing);
    formData.append('totalProductLimit', totalProductLimit);
    formData.append('remainingProductLimit', remainingProductLimit);
    formData.append('totalEmailLimit', totalEmailLimit);
    formData.append('remainingEmailLimit', remainingEmailLimit);
    formData.append('planActivationDate', planActivationDate);
    formData.append('planName', planName);
    formData.append('planId', planId);
    formData.append('planExpiryDate', planExpiryDate);
    formData.append('keywords', keywordStr);
    formData.append('title', title);
    formData.append('meta', meta);
    const { _id } = supplier;
    dispatch(
      updateSupplier(formData, _id
        // ,data => {
        // if (data) history.push('/manage-suppliers');}
      ),
    );
  };

  // useEffect(()=>{
  //   console.log("rnsjdnf")
  //   const trial = async() =>{
  //     await getRemainingEmailLimit()
  //   }
  //   trial()
  // },[onPlanChange,totalEmailLimit,setsupplierDetail])
  // --------useEffect
  useEffect(() => {
    getRemainingEmailLimit()
  }, [flag])


  useEffect(() => {
    dispatch(setSupplierDetail(id))
    getPlan()
  }, [])


  useEffect(() => {
    if (supplier) {
      const {
        userId,
        url,
        about,
        membertype,
        mobileNo,
        telephone,
        website,
        email,
        city,
        state,
        country,
        zipCode,
        contactUs,
        plan,
        logo,
        AltImageName,
        logo_path,
        planActivationDate,
        planExpiryDate,
        planName,
        totalProductLimit,
        remainingProductLimit,
        totalEmailLimit,
        remainingEmailLimit,
        planId,
        title,
        meta,
        keywords
      } = supplier;
      setsupplierDetail({
        ...supplierDetail,
        userId,
        url,
        about,
        membertype,
        mobileNo,
        telephone,
        website,
        email,
        city,
        state,
        country,
        zipCode,
        logo,
        AltImageName,
        logo_path,
        address: contactUs?.address,
        contactPerson: contactUs?.contactPerson,
        companyName: contactUs?.companyName,
        maxProductListing: plan?.maxProductListing,
        preview: logo,
        planActivationDate,
        planExpiryDate,
        planName,
        totalProductLimit,
        remainingProductLimit,
        totalEmailLimit,
        remainingEmailLimit,
        planId,
        title,
        meta,
        keywords: ''
      });
      setDescriptionHTML(about)
      setMetaCount({ ...metaCount, title: title?.length, meta: meta?.length });
      // const data = keywords?.split(',');
      // setkeywordsArr(data);
      console.log(keywords)
    }
  }, [supplier]);
  useEffect(() => {
    if (supplier) {
      const data = supplier?.keywords.split(',');
      setkeywordsArr(data);
      console.log(keywords)
    }
  }, [supplier]);
  const {
    telephone,
    userId,
    url,
    logo,
    mobileNo,
    email,
    password,
    country,
    about,
    website,
    city,
    state,
    zipCode,
    companyName,
    address,
    contactPerson,
    membertype,
    maxProductListing,
    preview,
    AltImageName,
    contactUs,
    totalProductLimit,
    remainingProductLimit,
    totalEmailLimit,
    remainingEmailLimit,
    planActivationDate,
    planName,
    planId,
    planExpiryDate,
    title,
    meta,
    keywords
  } = supplierDetail;
  //  console.log(supplierDetail.keywords,keywordsArr);
  return (
    <PageContainer>
      <div>
        <Paper className={classes.paper}></Paper>
        <CmtCard>
          <CmtCardContent>
            <Typography variant="h6">Edit Supplier</Typography>
            <Divider></Divider>
            <Box
              mt={3}
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
              alignItems="center"
              mb={{ xs: 6, md: 5 }}
            >
              <GridContainer>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    required
                    fullWidth
                    name="companyName"
                    variant="outlined"
                    label="Company Name"
                    value={companyName}
                    onChange={onLabelChange}
                    helperText={errorText?.companyName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    required
                    fullWidth
                    name="url"
                    variant="outlined"
                    label="URL"
                    value={url}
                    onChange={onLabelChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    required
                    fullWidth
                    name="website"
                    variant="outlined"
                    label="website"
                    value={website}
                    onChange={onLabelChange}
                    helperText={errorText?.website}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    required
                    fullWidth
                    name="mobileNo"
                    // type="number"
                    variant="outlined"
                    label="Mobile Number"
                    value={mobileNo}
                    onChange={onLabelChange}
                    helperText={errorText?.mobileNo}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    fullWidth
                    name="telephone"
                    // type="number"
                    variant="outlined"
                    label="Telephone"
                    value={telephone}
                    onChange={onLabelChange}
                  // helperText={errorText?.Telephone}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    required
                    fullWidth
                    name="contactPerson"
                    variant="outlined"
                    label="Contact Person"
                    value={contactPerson}
                    onChange={onLabelChange}
                    helperText={errorText?.contactPerson}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    fullWidth
                    required
                    name="address"
                    variant="outlined"
                    label="Address"
                    value={address}
                    onChange={onLabelChange}
                    helperText={errorText?.address}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    fullWidth
                    required
                    name="state"
                    variant="outlined"
                    label="State"
                    value={state}
                    onChange={onLabelChange}
                    helperText={errorText?.state}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    required
                    fullWidth
                    name="city"
                    variant="outlined"
                    label="City"
                    value={city}
                    onChange={onLabelChange}
                    helperText={errorText?.city}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    required
                    fullWidth
                    name="zipCode"
                    variant="outlined"
                    type="number"
                    label="Zip Code"
                    value={zipCode}
                    onChange={onLabelChange}
                    helperText={errorText?.zipCode}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    required
                    fullWidth
                    name="country"
                    variant="outlined"
                    label="Country"
                    value={country}
                    onChange={onLabelChange}
                    helperText={errorText?.country}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  {/* <AppSelectBox
                    name="membertype"
                    variant="outlined"
                    label="Select Plan"
                    value={membertype}
                    fullWidth
                    data={plan && plan}
                    valueKey=""
                    labelKey="name"
                    onChange={e => setMembertype(e.target.value)}
                  /> */}
                  <AppSelectBox
                    fullWidth
                    required
                    name="membertype"
                    variant="outlined"
                    label="Select Plan"
                    value={planId}
                    data={plan}
                    valueKey="_id"
                    labelKey="name"
                    onChange={onPlanChange}
                  // helperText={errorText.membertype}
                  />
                  {/* <AppSelectBox
                    fullWidth
                    name="membertype"
                    variant="outlined"
                    label="Member Type"
                    value={membertype}
                    data={plan}
                    valueKey="name"
                    labelKey="name"
                    onChange={onLabelChange}
                    helperText={errorText.membertype}
                  /> */}
                  {/* <Select
                    name="membertype"
                    fullWidth
                    required
                    variant="outlined"
                    value={membertype}
                    label="Select Plan"
                    onChange={onLabelChange}
                  >
                    <MenuItem value={''} disabled>
                      Select Plan
                    </MenuItem>
                    <MenuItem value={'free'}>Free</MenuItem>
                    <MenuItem value={'premium'}>Premium</MenuItem>
                  </Select> */}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    required
                    fullWidth
                    name="email"
                    variant="outlined"
                    label="Email"
                    value={email}
                    onChange={onLabelChange}
                    helperText={errorText?.email}
                  />
                </Grid>

                {/* <Grid item xs={12} sm={6}>
                    <AppTextInput
                      fullWidth
                      name="password"
                      variant="outlined"
                      label="Password"
                      value={supplierDetail.password}
                      onChange={onLabelChange}
                      helperText={errorText?.password}
                      type="password"
                    />
                  </Grid> */}

                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    fullWidth
                    required
                    name="maxProductListing"
                    variant="outlined"
                    label="Max Product Listing"
                    value={totalProductLimit}
                    onChange={onProductLimitChange}
                    helperText={errorText?.productListing}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    fullWidth
                    required
                    name="totalEmailLimit"
                    variant="outlined"
                    label="Total Email Limit"
                    value={totalEmailLimit}
                    onChange={onTotalEmailLimit}
                    helperText={errorText?.productListing}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    fullWidth
                    required
                    name="remainingEmailLimit"
                    variant="outlined"
                    label="Balance Email Limit"
                    value={remainingEmailLimit}
                    onChange={onLabelChange}
                    helperText={errorText?.productListing}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    fullWidth
                    required
                    // disabled
                    type="date"
                    name="planExpiryDate"
                    variant="outlined"
                    label="Plan Expiry Date"
                    value={planExpiryDate?.split('T')[0]}
                    onChange={handleExpiryDateChange}
                    helperText={errorText?.productListing}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    fullWidth
                    name="AltImageName"
                    variant="outlined"
                    // required
                    label="Alt Image Name"
                    value={AltImageName}
                    onChange={onLabelChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    fullWidth
                    required
                    name="file"
                    type="file"
                    variant="outlined"
                    onChange={evt => {
                      //   console.log(evt.target.files);
                      setsupplierDetail({ ...supplierDetail, preview: '' });
                      setFile(evt.target.files);
                    }}
                    helperText={errorText?.file}
                  />
                  {preview && <img src={preview} alt={AltImageName} className={classes?.avatar} />}
                </Grid>


                <Grid className={classes.grid} item xs={6} sm={6}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <label className={classes.label}>Meta Description*</label>
                    <label className={classes.label}>{metaCount.meta}</label>
                  </Box>
                  <textarea
                    className={
                      errorText.meta ? classes.textarea_red : classes.textarea
                    }
                    name="meta"
                    rows={4}
                    width={100}
                    variant="outlined"
                    value={meta}
                    onChange={onLabelChange}
                  />
                  {errorText.meta ? (
                    <p style={{ color: '#f44336', fontSize: '12px' }}>
                      {errorText?.meta}
                    </p>
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    required
                    fullWidth
                    name="title"
                    variant="outlined"
                    label={`Meta Title / ${metaCount.title}`}
                    value={title}
                    onChange={onLabelChange}
                    className="mb-2"
                    helperText={errorText?.title}
                  />
                </Grid>
                <Grid item xs={12} sm={11}>
                  <AppTextInput
                    fullWidth
                    required
                    name="keywords"
                    variant="outlined"
                    label="Keywords"
                    value={keywords}
                    onChange={onLabelChange}
                    helperText={errorText?.keywords}
                  />
                </Grid>
                <Grid item sm={1}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={onKeywordsAdd}
                  >
                    <Add />
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box>
                    {keywordsArr &&
                      keywordsArr.length > 0 &&
                      keywordsArr.map((e, key) => {
                        return (
                          e.length !== 0 && (
                            <Chip
                              size="medium"
                              key={key}
                              label={e}
                              clickable
                              onDelete={evt => onKeywordsDelete(evt, e)}
                              color="secondary"
                              variant="outlined"
                            />
                          )
                        );
                      })}
                  </Box>
                </Grid>


                <Grid item xs={12} sm={12}>
                  <Typography component="h3" variant="p" className="mb-2">
                    About
                  </Typography>
                  {/* <WysiwygEditorExample
                    defaultDescription={about}
                    setDescriptionHTML={setDescriptionHTML}
                    descriptionHTML={about}
                  /> */}
                  <CustomEditor
                    editorData={descriptionHTML || ""}
                    setEditorData={setDescriptionHTML}
                  // descriptionHTML={about}
                  />
                </Grid>
              </GridContainer>
            </Box>
            <Box display="flex" justifyContent="flex-end" mb={4}>
              <Button onClick={() => handleCancel()}>Cancel</Button>
              <Box ml={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onSubmitClick}
                >
                  Update
                </Button>
              </Box>
            </Box>
          </CmtCardContent>
        </CmtCard>
      </div>
    </PageContainer>
  );
};
export default EditContactSupplier;

EditContactSupplier.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
