// import { DELETE_BULK_EQGRADE_SUCCESS, EQGRADE_CREATE_SUCCESS, EQGRADE_DELETE_SUCCESS, EQGRADE_LIST_SUCCESS, EQGRADE_UPDATE_SUCCESS, SET_EQGRADE_DETAILS } from "redux/actions/index";

import { DELETE_BULK_EQGRADE_SUCCESS, EQGRADE_CREATE_SUCCESS, EQGRADE_DELETE_SUCCESS, EQGRADE_LIST_SUCCESS, EQGRADE_UPDATE_SUCCESS, GRADE_EQGRADE_LIST_SUCCESS, SET_EQGRADE_DETAILS } from "redux/actions/Manage/EqGrades/Constant";

const INIT_STATE =
{
    eqGrades: [],
    eqGrade: null,
    total: 0,
    perPage: 0
    // permissions: null,
}
const INIT_STATE_DETAIL =
{
    eqGrade: null,
}

const INIT_STATE_GRADE_LIST={
    gradeListForEqGrade:null
}
export const EqGradeReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case EQGRADE_CREATE_SUCCESS: {
            return {
                ...state,
                eqGrade: action?.data,
                action: action?.type,
                message: action?.message,
                eqGrades: [action.data, ...state?.eqGrades]
            };
        }
        case EQGRADE_LIST_SUCCESS: {
            return {
                ...state,
                eqGrades: action?.data,
                total: action?.total,
                action: action?.type,
                message: action?.message,
                perPage: action?.perPage,
            };
        }
        //products: state.products.map(product => (product.id === action.payload.id ? action.payload : product))
        case EQGRADE_UPDATE_SUCCESS: {
            return {
                ...state,
                eqGrade: action.data,
                action: action.type,
                message: action.message,
                eqGrades: state.eqGrades.map(item => (item._id === action.data._id ? action.data : item))
            };
        }
        case EQGRADE_DELETE_SUCCESS: {
            return {
                ...state,
                eqGrade: action.data,
                action: action.type,
                message: action.message,
                eqGrades: state.eqGrades.filter(item => item?._id !== action.data._id),
            };
        }
        case DELETE_BULK_EQGRADE_SUCCESS: {
            return {
                ...state,
                ...state,
                action: action.type,
                message: action.message,
                eqGrades: state.eqGrades.filter(item => !action.data.includes(item?.id)),
            };
        }
        default:
            return state;
    }
};
export const SetEqGradeDetailReducer = (state = INIT_STATE_DETAIL, action) => {
    switch (action.type) {
        case SET_EQGRADE_DETAILS:
            return {
                ...state,
                eqGrade: action.data,
                action: action.type,
            };
        default:
            return state;
    }
};

export const SetGradeListForEqGrade=(state = INIT_STATE_GRADE_LIST, action)=>{
    switch (action.type) {
        case GRADE_EQGRADE_LIST_SUCCESS:
            return {
                ...state,
                gradeListForEqGrade: action.data,
                action: action.type,
            };
        default:
            return state;
    }
}