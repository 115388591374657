import Axios from 'axios';
import { setError } from 'redux/actions/Common/ErrorAction';
import { setLoading } from 'redux/actions/Common/LoadingAction';
import { setSuccess } from 'redux/actions/Common/SuccessAction';
import {
  MATERIAL_SUB_PRODUCT_PRODUCT_LIST_REQUEST,
  MATERIAL_SUB_PRODUCT_PRODUCT_LIST_SUCCESS,
  MATERIAL_SUB_PRODUCT_PRODUCT_LIST_FAILED,
  MATERIAL_SUB_PRODUCT_PRODUCT_CREATE_REQUEST,
  MATERIAL_SUB_PRODUCT_PRODUCT_CREATE_SUCCESS,
  MATERIAL_SUB_PRODUCT_PRODUCT_CREATE_FAILED,
  MATERIAL_SUB_PRODUCT_PRODUCT_UPDATE_REQUEST,
  MATERIAL_SUB_PRODUCT_PRODUCT_UPDATE_SUCCESS,
  MATERIAL_SUB_PRODUCT_PRODUCT_UPDATE_FAILED,
  MATERIAL_SUB_PRODUCT_PRODUCT_STATUS_UPDATE_REQUEST,
  MATERIAL_SUB_PRODUCT_PRODUCT_STATUS_UPDATE_SUCCESS,
  MATERIAL_SUB_PRODUCT_PRODUCT_STATUS_UPDATE_FAILED,
  MATERIAL_SUB_PRODUCT_PRODUCT_LIST_BY_ID_REQUEST,
  MATERIAL_SUB_PRODUCT_PRODUCT_LIST_BY_ID_SUCCESS,
  MATERIAL_SUB_PRODUCT_PRODUCT_LIST_BY_ID_FAILED,
  MATERIAL_SUB_PRODUCT_PRODUCT_UPDATE_STATUS_REQUEST,
  MATERIAL_SUB_PRODUCT_PRODUCT_UPDATE_STATUS_SUCCESS,
  MATERIAL_SUB_PRODUCT_PRODUCT_UPDATE_STATUS_FAILED
} from './Constant';

export const getProductMaterialSubProductList = params => {
  return dispatch => {
    dispatch(setLoading(MATERIAL_SUB_PRODUCT_PRODUCT_LIST_REQUEST));
    return Axios.get('api/v1/admin/material-sub-product-product/list', { params })
      .then(response => {
        if (response?.status === 200) {
          const { data } = response;
          dispatch({
            type: MATERIAL_SUB_PRODUCT_PRODUCT_LIST_SUCCESS,
            data: data?.data?.gradeList,
            total: data?.data?.total,
            message: data.responseMessage,
            perPage: data?.data?.perPage,
          });
          // dispatch(setSuccess(data.responseMsg, MATERIAL_GRADE_LIST_SUCCESS))
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        dispatch(setError(err?.data?.responseMessage, MATERIAL_SUB_PRODUCT_PRODUCT_LIST_FAILED));
        return Promise.resolve(err);
      });
  };
};

export const crateProductMaterialSubProduct = data => {
  return dispatch => {
    dispatch(setLoading(MATERIAL_SUB_PRODUCT_PRODUCT_CREATE_REQUEST));
    return Axios.post('api/v1/admin/material-sub-product-product', data)
      .then(response => {
        if (response?.status === 200) {
          dispatch(setSuccess(data.responseMsg, MATERIAL_SUB_PRODUCT_PRODUCT_CREATE_SUCCESS));
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        dispatch(setError(err?.data?.responseMessage, MATERIAL_SUB_PRODUCT_PRODUCT_CREATE_FAILED));
        return Promise.resolve(err);
      });
  };
};

export const updateProductMaterialSubProduct = (id, data) => {
  return dispatch => {
    dispatch(setLoading(MATERIAL_SUB_PRODUCT_PRODUCT_UPDATE_REQUEST));
    return Axios.put(`api/v1/admin/material-sub-product-product/${id}`, data)
      .then(response => {
        if (response?.status === 200) {
          const { data } = response;
          // dispatch({
          //   type: MATERIAL_GRADE_UPDATE_SUCCESS,
          //   data: data?.data?.gradeList,
          //   message: data.responseMessage,
          // });
          dispatch(setSuccess(data.responseMessage, MATERIAL_SUB_PRODUCT_PRODUCT_UPDATE_SUCCESS));
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        dispatch(setError(err?.data?.responseMessage, MATERIAL_SUB_PRODUCT_PRODUCT_UPDATE_FAILED));
        return Promise.resolve(err);
      });
  };
};

export const updateProductMaterialSubProductStatus = (id, data) => {
  return dispatch => {
    dispatch(setLoading(MATERIAL_SUB_PRODUCT_PRODUCT_UPDATE_STATUS_REQUEST));
    return Axios.put(`api/v1/admin/material-sub-product-product/update-status/${id}`, data)
      .then(response => {
        if (response?.status === 200) {
          const { data } = response;
          dispatch(
            setSuccess(data.responseMessage, MATERIAL_SUB_PRODUCT_PRODUCT_UPDATE_STATUS_SUCCESS),
          );
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        dispatch(
          setError(err?.data?.responseMessage, MATERIAL_SUB_PRODUCT_PRODUCT_UPDATE_STATUS_FAILED),
        );
        return Promise.resolve(err);
      });
  };
};

export const getProductMaterialSubProductListById = id => {
  return dispatch => {
    dispatch(setLoading(MATERIAL_SUB_PRODUCT_PRODUCT_LIST_BY_ID_REQUEST));
    return Axios.get(`api/v1/admin/material-sub-product-product/${id}`)
      .then(response => {
        if (response?.status === 200) {
          dispatch({
            type:MATERIAL_SUB_PRODUCT_PRODUCT_LIST_BY_ID_SUCCESS,
            data: response.data.data?.materialSubProductProductDetail,
          })
          // dispatch(setSuccess(response?.responseMsg, MATERIAL_SUB_PRODUCT_PRODUCT_LIST_BY_ID_SUCCESS))
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        dispatch(setError(err?.data?.responseMessage, MATERIAL_SUB_PRODUCT_PRODUCT_LIST_BY_ID_FAILED));
        return Promise.resolve(err);
      });
  };
};

export const updateProductMaterialSubproductFaqs = (id,data) =>{
  return async dispatch => {
      try {
          await Axios.post(`/product/updateFaqs-ProductMaterialSubproduct/${id}`, data);
          dispatch(setSuccess("Updated"))
          return Promise.resolve();
      } catch (error) {
          dispatch(setError(error.message));
      }
  };
}
