import Axios from "axios";
import { MSG_ERR_RESPONDING_SERVER, PER_PAGE_DEFAULT } from 'redux/actions/Common/Constant';
import { ErrorCatch } from "utils/ErrorCatch";
import { setError } from "../../Common/ErrorAction";
import { setLoading } from "../../Common/LoadingAction";
import { setSuccess } from "../../Common/SuccessAction";
import { DELETE_BULK_PRODUCTMATERIAL_FAILED, DELETE_BULK_PRODUCTMATERIAL_REQUEST, DELETE_BULK_PRODUCTMATERIAL_SUCCESS, PRODUCTMATERIAL_CREATE_FAILED, PRODUCTMATERIAL_CREATE_REQUEST, PRODUCTMATERIAL_CREATE_SUCCESS, PRODUCTMATERIAL_DELETE_FAILED, PRODUCTMATERIAL_DELETE_REQUEST, PRODUCTMATERIAL_DELETE_SUCCESS, PRODUCTMATERIAL_LIST_FAILED, PRODUCTMATERIAL_LIST_REQUEST, PRODUCTMATERIAL_LIST_SUCCESS, PRODUCTMATERIAL_MATERIAL_CREATE_FAILED, PRODUCTMATERIAL_MATERIAL_CREATE_REQUEST, PRODUCTMATERIAL_MATERIAL_CREATE_SUCCESS, PRODUCTMATERIAL_UPDATE_FAILED, PRODUCTMATERIAL_UPDATE_REQUEST, PRODUCTMATERIAL_UPDATE_SUCCESS, SET_PRODUCTMATERIALELM_DETAILS, SET_PRODUCTMATERIAL_DETAILS } from "./Constant";

let RES_MSG = MSG_ERR_RESPONDING_SERVER

// export const getAllUsers = (filterOptions = [],params, searchTerm = '', callbackFun) =>
export const getAllProductMaterials = (params, callbackFun, isDownload = false) =>
    async (dispatch) => {
        dispatch(setLoading(PRODUCTMATERIAL_LIST_REQUEST))
        try {
            // const { data } = await Axios.get(`/user/list,?page=${1}`)
            // const { data } = await Axios.get(`/auth/fetch_admins`, { params })
            // const { data } = await Axios.get(`/auth/fetch_admins`)
            const response = await Axios.post(`/product/fetch-product-material`, { params })
            const { data } = response
            const total = data?.data?.length
            if (response.status === 200) {
                if (isDownload)
                    dispatch(setSuccess(null, PRODUCTMATERIAL_LIST_SUCCESS))
                else {
                    dispatch({
                        type: PRODUCTMATERIAL_LIST_SUCCESS,
                        data: data.data,
                        total: total,
                        message: data.message,
                        perPage: PER_PAGE_DEFAULT,
                    });
                }
                if (callbackFun) callbackFun(data.data.page);
            }
            else {
                dispatch(setError(RES_MSG, PRODUCTMATERIAL_LIST_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, PRODUCTMATERIAL_LIST_FAILED, dispatch)
        }
    };
;
export const updateProductMaterial = (payload, callbackFun) =>
    async (dispatch) => {
       
        dispatch(setLoading(PRODUCTMATERIAL_UPDATE_REQUEST))
        try {
            const response = await Axios.post(`/product/update-product-material-page`, payload)
            const { data } = response
            if (response.status === 200) {
                dispatch(setSuccess(data.message, PRODUCTMATERIAL_UPDATE_SUCCESS))
                dispatch({
                    type: PRODUCTMATERIAL_UPDATE_SUCCESS,
                    data: data.data,
                    message: data.message,
                });
                if (callbackFun) callbackFun(data.data);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, PRODUCTMATERIAL_UPDATE_FAILED));
            }
        } catch (error) {
            dispatch(setError(error?.data?.message, PRODUCTMATERIAL_CREATE_FAILED));

        }
    };

export const createProductMaterial = (payload, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(PRODUCTMATERIAL_CREATE_REQUEST))
        try {
            const response = await Axios.post(`/product/add-product-material-page`, payload)
            const { data } = response
            if (response.status === 200) {
                dispatch(setSuccess(data.message, PRODUCTMATERIAL_CREATE_SUCCESS))
                dispatch({
                    type: PRODUCTMATERIAL_CREATE_SUCCESS,
                    data: data.data,
                    message: data.message,
                });
                if (callbackFun) callbackFun(data.data);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, PRODUCTMATERIAL_CREATE_FAILED));
            }
        } catch (error) {
            dispatch(setError(error?.data?.message, PRODUCTMATERIAL_CREATE_FAILED));
        }
    };
export const addMaterialToProduct = (payload, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(PRODUCTMATERIAL_MATERIAL_CREATE_REQUEST))
        try {
            const response = await Axios.post(`/product/addMaterialsToProduct`, payload)
            const { data } = response
            if (response.status === 200) {
                dispatch(setSuccess(data.message, PRODUCTMATERIAL_MATERIAL_CREATE_SUCCESS))
                dispatch({
                    type: PRODUCTMATERIAL_MATERIAL_CREATE_SUCCESS,
                    data: data.data,
                    message: data.message,
                });
                if (callbackFun) callbackFun(data.data);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, PRODUCTMATERIAL_MATERIAL_CREATE_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, PRODUCTMATERIAL_MATERIAL_CREATE_FAILED, dispatch)
        }
    };
//take user id for delete api request
export const deleteProductMaterial = (userId, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(PRODUCTMATERIAL_DELETE_REQUEST))
        try {
            const { data } = await Axios.delete(`/user/delete/${userId}`)
            if (data.statusCode === 200) {
                dispatch(setSuccess(data.responseMsg, PRODUCTMATERIAL_DELETE_SUCCESS))
                dispatch({
                    type: PRODUCTMATERIAL_DELETE_SUCCESS,
                    data: data.data.userDelete,
                    message: data.responseMsg,
                });
                if (callbackFun) callbackFun(data.data.userDelete);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, PRODUCTMATERIAL_DELETE_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, PRODUCTMATERIAL_DELETE_FAILED, dispatch)
        }
    };
;
export const deleteBulkProductMaterial = (userIds, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(DELETE_BULK_PRODUCTMATERIAL_REQUEST))
        try {
            // const { data } = await Axios.delete(`/admin/roles/delete/${roleId}`)
            const data = {
                statusCode: 200,
                data: userIds,
                responseMsg: "Selected Users were deleted successfully!",
            }
            if (data.statusCode === 200) {
                dispatch(setSuccess(data.responseMsg, DELETE_BULK_PRODUCTMATERIAL_SUCCESS))
                dispatch({
                    type: DELETE_BULK_PRODUCTMATERIAL_SUCCESS,
                    data: data.data,
                    message: data.message,
                });
                if (callbackFun) callbackFun(data.data);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, DELETE_BULK_PRODUCTMATERIAL_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, DELETE_BULK_PRODUCTMATERIAL_FAILED, dispatch)
        }
    };
;

export const setProductMaterialDetail = id => {
    return async dispatch => {
        try {
            const data = await Axios.get(`/product/fetchSingleProductMaterial/${id}`);
            dispatch({
                type:SET_PRODUCTMATERIAL_DETAILS,
                data:data?.data.data,
                message:data?.message
            })
        } catch (error) {
            dispatch(setError(error.message, PRODUCTMATERIAL_UPDATE_FAILED));
        }
    };
};

export const setProductMaterialDetailElm = elm => {
    return async dispatch => {
        try {
            dispatch({
                type:SET_PRODUCTMATERIALELM_DETAILS,
                data:elm,
            })
        } catch (error) {
            dispatch(setError(error.message, PRODUCTMATERIAL_UPDATE_FAILED));
        }
    };
};

export const updateProductMaterialFaqs = (id,data) =>{
    return async dispatch => {
        try {
            await Axios.post(`/product/updateFaqs-ProductMaterial/${id}`, data);
            dispatch(setSuccess("Updated", PRODUCTMATERIAL_UPDATE_SUCCESS))
            return Promise.resolve();
        } catch (error) {
            dispatch(setError(error.message, PRODUCTMATERIAL_UPDATE_FAILED));
        }
    };
}