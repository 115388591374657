import React, { useState, useEffect } from 'react';
import {
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { Search as SearchIcon } from '@material-ui/icons';
import { format } from 'date-fns';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import { consoleTransport } from 'react-native-logs';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  fetchGrade,
  fetchProductMaterialGradeList
} from 'redux/actions/Manage/Suppliers/Suppliers';
import Axios from 'axios';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import { WidthFull } from '@mui/icons-material';
const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(3),
    minWidth: '80%',
  },
  input: {
    borderBottom: '1px solid gray',
    border:"1px solid black",
    zIndex: 10,
  },
  email: {
    display: 'flex',
    
  },
  searchAutocomplete: {
    position: 'relative',
    border:"1px solid red",
    width:"100%",
    height:"20vh",
    overflow:"auto",
    zIndex:"999",

  },
  // autocompleteContainer: {
  //   position: 'absolute',
  //   display: 'block',
  //   background:"red",
  //   // border:"1px solid red"

  // },

}));

const FilterForm = ({ setFilterData, handleSubmit, handleClearFilter }) => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [cities, setCities] = useState('');
  const [membertype, setMembertype] = useState('');
  const [emailsReceived, setEmailsReceived] = useState('');
  const [selectedProducts, setSelectedProducts] = useState('');
  const supplierData = useSelector(state => state.Suppliers);
  // console.log(supplierData);
  const  [inc, setInc] = useState("")
  const {products,materials,grades} = useSelector(state => state.SetProductMaterialGradeList);
  const {gradesList}=useSelector(state => state.SetSupplierDetail);
  const [productDetails, setProductDetails] = useState({
    productId:"",
    materialId:"",
    gradeId:""
  })
  const [sum,setSum]=useState(0)
  // const [materialName, setMaterialName] = useState('')
  const [gradeArray, setGradeArray] = useState([])
  const [newgradeName, setNewGradeName] = useState("")
  const [plan,setPlan]=useState("")
  const [supplierList,setsupplierList] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  // console.log(productName,materialName,gradeName);
  
  //   console.log(format(date, 'yyyy-mm-dd'));
  // }
  // const handleEndDate = date => {
  //   console.log(date);
  //   console.log(date._d);
  //   console.log(typeof date._d);
  // };
  const handleClearField = () => {
    setName('');
    setInc("")
    setStartDate(null);
    setEndDate(null);
    setCities('');
    setEmailsReceived('');
    setSelectedProducts('');
    setMembertype('');
    setProductDetails({
    productId:"",
    materialId:"",
    gradeId:""
    })
    setPlan("")
    getPlan()
    setNewGradeName("")
    setSum(pre=>pre+1)
  };
  const getPlan=async()=>{
    try {
      let res = await Axios.get('api/v1/admin/plan/list');
      setPlan(res.data.data.planList)
    } catch (error) {
      console.log(error);
    }
  }

  // useEffect(() => {
  //   if (selectedProducts === "products") {
      
  //     console.log("products selected");
  //   }
  //   if (selectedProducts === "material") {
      
  //     console.log("material selected");
  //   }
  //   if (selectedProducts === "grade") {
      
  //     console.log("grade selected");
  //   }
  // }, [selectedProducts])

  // const handleMaterialChange = event => {
  //   setMaterialName(event.target.value);
  //   let arr = grades.filter((el, index) => {
  //     return event.target.value == el._id;
  //   });
  //   setNewGradeName(arr[0].grades)
  // };
  
  // const handleMaterialChange = event => {
  //   setMaterialName(event.target.value);
  //   let arr = grades.filter((el, index) => {
  //     return event.target.value == el._id;
  //   });
  //   setNewGradeName(arr[0].grades)
  // };

  const defaultProps = {
    options: supplierData.suppliers,
    getOptionLabel: option => option.name,
    
  };
  const onProductChange = (obj,name) =>{
    setProductDetails({
      ...productDetails,
      [name]:obj[name]
    })  
    // console.log(productDetails)
  }

  const handleOnSelect = value => {
    // console.log(value,"selected")
    //  if (value && value.name) {
      //  setName(value.name); //new autocomplete
       setName(value);
    //  } else {
    //    setName('');
    //  }
  };
  


// new function
const handleOnChange = e => {
  let res = e.target.value;
  setName(res);
  console.log(res, 'resresresresresresresres');
};


  const handleOnSelect2 = (e) => {
    setEmailsReceived(e.target.value);
  };

  useEffect(()=>{
    dispatch(fetchProductMaterialGradeList())
    getPlan()
    },[])
    
  useEffect(()=>{
    if(supplierData){
      setsupplierList(supplierData.suppliers)
    }
    },[supplierData])

    useEffect(() => {
      // if(productDetails.product && productDetails.material || productDetails.grade){
        let productId=productDetails.productId;
        let materialId=productDetails.materialId;
        let gradeId=productDetails.gradeId;
        if(productId && materialId && !gradeId){
          dispatch(fetchGrade(productId, materialId));
        }
      // }
    }, [productDetails]);

    useEffect(() => {
      if(gradesList){
        setGradeArray(gradesList)
      }
    },[gradesList])
  // useEffect(() => {
  //   if (inc == "more") {
  //     const data = defaultProps.options.filter((ele)=>ele.count > emailsReceived)
  //     console.log(data,"suu");
      
  //   }
  //   if (inc == "less") {
  //     const data = defaultProps.options.filter((ele)=>ele.count > emailsReceived)
  //     console.log(data,"suu");
      
  //   }
    
  // }, [inc])

  // const filterOptions = (options, { inputValue }) => {
  //   const matchingOptions = options.filter(option =>
  //     option.toLowerCase().startsWith(inputValue.toLowerCase())
  //   );
  //   return matchingOptions.sort((a, b) => {
  //     const aLower = a.toLowerCase();
  //     const bLower = b.toLowerCase();
  //     const inputLower = inputValue.toLowerCase();
  //     const aIndex = aLower.indexOf(inputLower);
  //     const bIndex = bLower.indexOf(inputLower);
  //     if (aIndex !== bIndex) {
  //       return aIndex - bIndex;
  //     }
  //     return aLower.localeCompare(bLower);
  //   });
  // };

// console.log(supplierList)
  return (
    <form
      onSubmit={e =>
        handleSubmit(e, {
          inc,
          name,
          startDate,
          endDate,
          cities,
          membertype,
          emailsReceived,
          selectedProducts,
          productDetails,
        })
      }
      style={{ backgroundColor: 'white', padding: '2rem' }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Autocomplete
              fullWidth
              id="combo-box-demo"
              options={supplierList}
              getOptionLabel={option => option.name} // Extracts the "name" field
              renderInput={params => (
                <TextField
                  value={name}
                  {...params}
                  onChange={handleOnSelect(params.inputProps.value)}
                  label="Name"
                />
              )}
            />
          

          {/* <div>
            <div
              className={classes.autocompleteContainer}
              style={{ border: '1px solid green', width: '100%' }}
            >
              <div className={classes.searchAutocomplete}>
                <ReactSearchAutocomplete
                  items={supplierList}
                  onSearch={handleOnSelect}
                  onSelect={handleOnSelect}
                  placeholder="Name"
                  inputProps={{ className: classes.input }}
                  styling={{
                    backgroundColor: 'white',
                    color: 'black',
                    border: '1px solid #dfe1e5',
                  }}
                />
              </div>
            </div>
          </div> */}
        </Grid>
        <Grid item xs={12} sm={3}>
          <DatePicker
            label="Registration Start Date"
            inputVariant="outlined"
            value={startDate}
            onChange={date => setStartDate(date._d)}
            format="DD/MM/YYYY"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DatePicker
            label="Registration End Date"
            inputVariant="outlined"
            value={endDate}
            onChange={date => setEndDate(date._d)}
            format="DD/MM/YYYY"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="City"
            value={cities}
            onChange={event => setCities(event.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AppSelectBox
            name="membertype"
            variant="outlined"
            label="Select Plan"
            value={membertype}
            fullWidth
            data={plan && plan}
            valueKey=""
            labelKey="name"
            onChange={e => setMembertype(e.target.value)}
          />
        </Grid>
        <Grid className={classes.email} item xs={12} sm={6}>
          <TextField
            label="Number of Emails Received"
            value={emailsReceived}
            onChange={handleOnSelect2}
            fullWidth
          />
          {emailsReceived ? (
            <>
              <FormControl fullWidth>
                <InputLabel id="member-type-label">Type</InputLabel>
                <Select
                  labelId="member-type-label"
                  value={inc}
                  onChange={event => setInc(event.target.value)}
                >
                  <MenuItem value="">Select</MenuItem>
                  <MenuItem value="more">
                    <ArrowForwardIosIcon sx={{ fontSize: 16 }} /> Greater than
                  </MenuItem>
                  <MenuItem value="less">
                    <ArrowBackIosIcon sx={{ fontSize: 16 }} />
                    Less than
                  </MenuItem>
                  <MenuItem value="same">= Equal to</MenuItem>
                </Select>
              </FormControl>
            </>
          ) : null}
        </Grid>
        <Grid item className={classes.email} xs={12} sm={6}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              disablePortal
              value={newgradeName?.name}
              key={sum}
              disabled={products.length ? false : true}
              fullWidth
              options={products.length && products}
              getOptionLabel={option => option.name} // Extracts the "name" field
              sx={{ paddingLeft: 0.5, paddingTop: 0.5 }}
              onChange={(e, value) => onProductChange(value, 'productId')}
              renderInput={params => (
                <TextField {...params} label="Select Product" />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              key={sum}
              disabled={materials.length ? false : true}
              fullWidth
              options={materials.length && materials}
              getOptionLabel={option => option.name} // Extracts the "name" field
              onChange={(e, value) => onProductChange(value, 'materialId')}
              sx={{ paddingLeft: 1, paddingTop: 0.5 }}
              renderInput={params => (
                <TextField {...params} label="Select Material" />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              disabled={gradeArray.length ? false : true}
              fullWidth
              key={sum}
              id="combo-box-demo"
              options={gradeArray.length && gradeArray}
              getOptionLabel={option => option.displayName} // Extracts the "name" field
              sx={{ paddingLeft: 1, paddingTop: 0.5 }}
              onChange={(e, value) =>
                setProductDetails({ ...productDetails, gradeId: value.gradeId })
              }
              renderInput={params => (
                <TextField {...params} label="Select Grade" />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" type="submit">
            Submit
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="reset"
            style={{ marginLeft: '2.1rem' }}
            onClick={() => {
              handleClearFilter();
              handleClearField();
            }}
          >
            Clear
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default FilterForm;
