import { Box, Checkbox } from '@mui/material';
import CustomTable from 'components/CustomeTable';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFreeSupplier, updateFreeSupplierStatus } from 'redux/actions/Manage/SendRfqEmail';

const ListFreeSupplier = () => {
    const sendRfqEmail = useSelector((state) => state?.sendRfqEmail)
    const freeSupplier = useSelector((state) => state?.freeSupplier)
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const dispatch = useDispatch();

    const handleChange = function(e,userId){
        const value = e.target.checked
        dispatch(updateFreeSupplierStatus(userId,value)).then(()=>{
            dispatch(fetchFreeSupplier({
                page: page,
                search: searchTerm
            }));
        })
    }

    const headCells = [
        {
            id: 'shouldSendRfqEmail',
            numeric: false,
            disablePadding: false,
            sort: false,
            label: '',
            render: elm => {
                return <Checkbox checked={elm?.shouldSendRfqEmail} onChange={(e) => handleChange(e,elm?.userId)} />
            },
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: 'Name',
        },
        {
            id: 'email',
            numeric: false,
            disablePadding: false,
            label: 'Email',
        },
    ]

    const handleRequestSort = (event, property) => {
        const isAsc = order === 'asc';
        setOrderBy(property);
        setOrder(isAsc ? 'desc' : 'asc');
      };
    
      const handlePageChange = (event, newPage) => {
        setPage(newPage);
      };

    useEffect(() => {
        dispatch(fetchFreeSupplier({
            page: page + 1,
            search: searchTerm
        }));
    }, [page,searchTerm])
    return (
        <Box>
            <CustomTable
                TableName="Free Supplier List"
                row={freeSupplier?.freeSupplierList}
                headCells={headCells}
                isDynamic={true}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                count={freeSupplier?.total || 0}
                rowsPerPage={perPage}
                onPageChange={handlePageChange}
                page={page}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                handleSearch={(text) => setSearchTerm(text)}
                handleSearchSubmit={(text) => {
                    setSearchTerm(text)
                    setPage(0)
                }}
            />
        </Box>
    )
}

export default ListFreeSupplier