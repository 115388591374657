import { FETCH_ERROR, RESET_PASSWORD_SUCCESS, SEND_FORGET_PASSWORD_EMAIL, VERIFIED_OTP } from "@jumbo/constants/ActionTypes";
import Axios from "axios";
import { ErrorCatch } from "utils/ErrorCatch";
import { setForgetPassMailSent, setOtpVerified } from "./Auth";
import { setError } from "./Common/ErrorAction";
import { setLoading } from "./Common/LoadingAction";
import { setSuccess } from "./Common/SuccessAction";

export const forgotPassword = (payload, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading())
        try {
            const { data } = await Axios.post(`/user/send-forgot-password-otp`, payload)
            if (data.statusCode === 200) {
                dispatch(setForgetPassMailSent(data.data.userDetail));
                dispatch(setSuccess(data.responseMsg, SEND_FORGET_PASSWORD_EMAIL))
                if (callbackFun) callbackFun(data.data.userDetail);
            }
            else {
                dispatch(setError(data.responseMsg));
            }
        } catch (error) {
            ErrorCatch(error, FETCH_ERROR, dispatch)
        }
    }
export const verifyOtp = (payload, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading())
        try {
            const { data } = await Axios.post(`/user/verify-otp`, payload)
            if (data.statusCode === 200) {
                dispatch(setOtpVerified(data.data.userData));
                dispatch(setSuccess(data.responseMsg, VERIFIED_OTP))
                if (callbackFun) callbackFun(data.data.userData);
            }
            else {
                dispatch(setError(data.responseMsg));
            }
        } catch (error) {
            ErrorCatch(error, FETCH_ERROR, dispatch)
        }
    }
export const ResetUserPassword = (payload, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading())
        try {
            const { data } = await Axios.put(`/user/resetpassword`, payload)
            if (data.statusCode === 200) {
                // dispatch(setResetPassword(data.data));
                dispatch(setSuccess(data.responseMsg, RESET_PASSWORD_SUCCESS))
                if (callbackFun) callbackFun(data.data.userDetail);
            }
            else {
                dispatch(setError(data.responseMsg));
            }
        } catch (error) {
            ErrorCatch(error, FETCH_ERROR, dispatch)
        }
    }
