
import { RECEIVE_INBOX_EMAIL_LIST_SUCCESS, SENT_APPROVE_EMAIL_LIST_SUCCESS, SUPPLER_REGISTRATION_EMAIL_LIST_SUCCESS, TOP_10_SUPPLIER_EMAIL_LIST_SUCCESS } from "redux/actions";


const INIT_STATE = {
    total: 0,
    perpage: 10,
    supplierList: [],
    listOfTop10Supplier: [],
    sendEmailCount: {},
    inboxEmailCount: 0,
};

export const DashBoardReducer = (state = INIT_STATE, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
        case SUPPLER_REGISTRATION_EMAIL_LIST_SUCCESS: {
            return {
                ...state,
                total: action?.total,
                perpage: action?.perpage,
                supplierList: action?.data
            }
        }
        case TOP_10_SUPPLIER_EMAIL_LIST_SUCCESS: {
            return {
                ...state,
                listOfTop10Supplier: action?.data
            }
        }
        case SENT_APPROVE_EMAIL_LIST_SUCCESS: {
            return {
                ...state,
                sendEmailCount: action?.data
            }
        }
        case RECEIVE_INBOX_EMAIL_LIST_SUCCESS: {
            return {
                ...state,
                inboxEmailCount: action?.data
            }
        }
        default:
            return state;
    }
}