import { HIDE_SUCCESS, SET_SUCCESS } from "redux/actions/Common/Constant"
import log from "utils/Logger.util"

//success msg action
export function setSuccess(message = "Success", actionType) {
    log.debug(message, actionType)
    return {
        type: SET_SUCCESS,
        action: actionType ? actionType : SET_SUCCESS,
        message,
        success: true,
        error: false,
        loading: false,
    }
}

export function hideSuccess() {
    return {
        type: HIDE_SUCCESS
    }
}
