import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { alpha } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import CmtImage from '../../../../@coremat/CmtImage';

import { SEND_FORGET_PASSWORD_EMAIL } from '@jumbo/constants/ActionTypes';
import { isValidEmail } from '@jumbo/utils/commonHelper';
import { forgotPassword, verifyOtp } from 'redux/actions/UserAuth';
import { setForgetPassMailSent } from '../../../../redux/actions/Auth';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import IntlMessages from '../../../utils/IntlMessages';
import ContentLoader from '../../ContentLoader';
import AppTextInput from '../formElements/AppTextInput';
import AuthWrapper from './AuthWrapper';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
}));

//variant = 'default', 'standard', 'bgColor'
const ForgotPassword = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const { send_forget_password_email } = useSelector(({ auth }) => auth);
  const [open, setOpen] = React.useState(false);
  // const [email, setEmail] = useState('demo@example.com');
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const history = useHistory();
  const { error, isOpen } = useSelector(state => state.Error)
  const { success } = useSelector(state => state.Success)
  const successAction = useSelector(state => state.Success.action)

  const [Success, setSuccess] = useState(false)
  const [successMsg, setSuccessMsg] = useState("")

  const [otpInput, setotpInput] = useState(false)
  const [errorText, setErrorText] = useState({});
  const [forgetPassword, setForgetPassword] = useState({
    email: '',
    otp: '',
  });
  const validate = () => {
    const { email, otp } = forgetPassword;
    const errors = {};
    let isError = false;

    if (!otpInput && !email) {
      errors.email = 'Please enter email.';
      isError = true;
    } else if (!isValidEmail(email)) {
      errors.email = 'Please enter valid email.';
      isError = true;
    }
    if (otpInput && !otp) {
      errors.otp = 'Please enter OTP.';
      isError = true;
    } else if (otpInput && !/^\d*[0-9]\d*$/.test(otp)) {
      errors.otp = 'Please enter valid OTP.';
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };
  useEffect(() => {
    if (successAction === SEND_FORGET_PASSWORD_EMAIL)
      setSuccessMsg(success)
  }, [successAction])
  useEffect(() => {
    if (!success)
      setSuccess(false)
  }, [success])
  useEffect(() => {
    let timeOutStopper = null;
    if (send_forget_password_email) {
      setOpen(true);

      timeOutStopper = setTimeout(() => {
        dispatch(setForgetPassMailSent(false));
        setOpen(false)
        // history.push('/');
      }, 3000);
    }

    return () => {
      if (timeOutStopper) clearTimeout(timeOutStopper);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [send_forget_password_email]);
  const onSubmitClick = () => {
    let validateData = validate();
    if (!validateData.isError) {
      onUserSave();
    }
  };

  const handleInputChange = evt => {
    setForgetPassword({ ...forgetPassword, [evt.target.name]: evt.target.value });
  };

  const onUserSave = () => {
    const { email, otp } = forgetPassword;
    if (otp) {
      dispatch(verifyOtp(forgetPassword, (userData) => {
        if (userData)
          history.push('/reset-password');
      }
      ))
    } else {
      dispatch(forgotPassword(forgetPassword, (userDetail) => {
        localStorage.setItem('email', JSON.stringify(userDetail.email))
        setotpInput(true)
      }
      ))
    }
  };
  const onClickLogin = () => {
    history.push('/signin')
  }
  const onClickResend = () => {
    const email = JSON.parse(localStorage.getItem('email'))
    const forgetPassword = { email }
    dispatch(forgotPassword(forgetPassword, (userDetail) => {
      setotpInput(true)
    }
    ))
  }
  return (
    <>
      {/* {error && isOpen && <AlertBox type='error' style="filled" >{error}</AlertBox>} */}
      {/* {Success && <AlertBox type='success' style="filled" >{successMsg}</AlertBox>} */}
      <AuthWrapper variant={wrapperVariant}>
        {variant === 'default' ? (
          <div className={classes.authThumb}>
            <CmtImage src={'/images/auth/forgot-img.png'} />
          </div>
        ) : null}
        <div className={classes.authContent}>
          <div className={'mb-7'}>
            <CmtImage src={'/images/piping-logo.png'} />
          </div>
          <Typography component="div" variant="h1" className={classes.titleRoot}>
            {otpInput ? 'Verify OTP' : "Forgot Password"}
          </Typography>
          {/* <Collapse in={open}>
            <Alert
              variant="outlined"
              severity="success"
              className={classes.alertRoot}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>
              OTP has been sent to your email!
            </Alert>
          </Collapse> */}
          <form>
            {/* <div className={'mb-5'}> */}
            {otpInput ?
              <div>
                <AppTextInput
                  name="otp"
                  label="OTP"
                  required
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  variant="outlined"
                  className="mb-5"
                  onChange={handleInputChange}
                  helperText={errorText.otp}
                />
              </div> :
              <div>
                <AppTextInput
                  name="email"
                  label={<IntlMessages id="appModule.email" />}
                  required
                  InputProps={{ inputProps: { min: 0 } }}
                  variant="outlined"
                  className="mb-5"
                  onChange={handleInputChange}
                  helperText={errorText.email}
                />
              </div>
            }
            {otpInput ? <Box display="flex" justifyContent="flex-start" mb={4}>
              <Button onClick={onSubmitClick} variant="contained" color="primary">
                Verify OTP
              </Button>
              <Box ml={2}>
                <Button onClick={onClickResend} variant="contained" color="primary">
                  Resend OTP
                </Button>
              </Box>
            </Box> :
              <Box display="flex" justifyContent="flex-start" mb={4}>
                <Button onClick={onSubmitClick} variant="contained" color="primary">
                  <IntlMessages id="appModule.send" />
                </Button>
                <Box ml={2}>
                  <Button onClick={onClickLogin} variant="contained" color="primary">
                    <IntlMessages id="sidebar.appModule.login" />
                  </Button>
                </Box>
              </Box>
            }
          </form>
          <ContentLoader />
        </div>
      </AuthWrapper>
    </>
  );
};

export default ForgotPassword;
