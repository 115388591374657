
export const PLAN_LIST_SUCCESS = "PLAN_LIST_SUCCESS ";
export const PLAN_LIST_FAILED = "PLAN_LIST_FAILED ";


export const ADD_PLAN_LIST_SUCCESS = "ADD_PLAN_LIST_SUCCESS";
export const ADD_PLAN_LIST_FAILED = "ADD_PLAN_LIST_FAILED";

// export const EDIT_PLAN_LIST_SUCCESS = "EDIT_PLAN_LIST_SUCCESS";
// export const EDIT_PLAN_LIST_FAILED = "EDIT_PLAN_LIST_FAILED";
