import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { alpha } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import CmtImage from '../../../../@coremat/CmtImage';

import { VERIFIED_OTP } from '@jumbo/constants/ActionTypes';
import { isValidPassword } from '@jumbo/utils/commonHelper';
import { ResetUserPassword } from 'redux/actions/UserAuth';
import ContentLoader from '../../ContentLoader';
import AppTextInput from '../formElements/AppTextInput';
import AuthWrapper from './AuthWrapper';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
}));

//variant = 'default', 'standard', 'bgColor'
const ResetPassword = ({ variant = 'default', wrapperVariant = 'default' }) => {
  const { user_reset_password } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const history = useHistory();
  const { success } = useSelector(state => state.Success)
  const successAction = useSelector(state => state.Success.action)

  const [, setSuccess] = useState(false)
  const [, setSuccessMsg] = useState("")

  const [errorText, setErrorText] = useState({});
  const [resetPassword, setResetPassword] = useState({
    id: '',
    email: '',
    password: '',
    conformPassword: '',
  });
  const validate = () => {
    const { password, conformPassword } = resetPassword;
    const errors = {};
    let isError = false;

    if (!password) {
      errors.password = 'Please enter password.';
      isError = true;
    }
    else if (isValidPassword(password)) {
      errors.password =
        'Password must include at least 8 characters and contain at least 1 lowercase & 1 uppercase letter, a number and special symbols.';
      isError = true;
    }
    if (!conformPassword) {
      errors.conformPassword = 'Please enter confirm password.';
      isError = true;
    }
    else if (password !== conformPassword) {
      errors.conformPassword =
        'Passwords does not match'
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };

  const handleInputChange = evt => {
    setResetPassword({ ...resetPassword, [evt.target.name]: evt.target.value });
  };

  const onUserSave = () => {
    if (user_reset_password)
      dispatch(ResetUserPassword(resetPassword, (userDetail) => {
        if (userDetail)
          history.push('/signin')
      }))
    else history.push('/signin')
  }
  const onClickLogin = () => {
    history.push('/signin')
  }
  // useEffect(() => {
  //   let timeOutStopper = null;
  //   if (successAction === VERIFIED_OTP) {
  //     setOpen(true);

  //     timeOutStopper = setTimeout(() => {
  //       dispatch(setForgetPassMailSent(false));
  //       localStorage.removeItem('email')
  //       setOpen(false)
  //     }, 3000);
  //   }

  //   return () => {
  //     if (timeOutStopper) clearTimeout(timeOutStopper);
  //   };
  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [successAction]);
  useEffect(() => {
    if (successAction === VERIFIED_OTP)
      setSuccessMsg(success)
  }, [successAction])
  useEffect(() => {
    if (!success)
      setSuccess(false)
  }, [success])

  const onSubmitClick = () => {
    let validateData = validate();
    if (!validateData.isError) {
      onUserSave();
    }
  };
  useEffect(() => {
    if (user_reset_password) {
      const { _id, email } = user_reset_password
      setResetPassword({ id: _id, email })
    }
  }, [user_reset_password]);
  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <div className={classes.authThumb}>
          <CmtImage src={'/images/auth/forgot-img.png'} />
        </div>
      ) : null}
      {/* {error && isOpen && <AlertBox type='error' style="filled" >{error}</AlertBox>} */}
      {/* {Success && <AlertBox type='success' style="filled" >{successMsg}</AlertBox>} */}
      <div className={classes.authContent}>
        <div className={'mb-7'}>
          <CmtImage src={'/images/piping-logo.png'} />
        </div>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Reset Password
        </Typography>
        {/* <Collapse in={open}>
          <Alert
            variant="outlined"
            severity="success"
            className={classes.alertRoot}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            OTP verified successfully !
          </Alert>
        </Collapse> */}
        <form>
          {/* <div className={'mb-5'}> */}

          <div>
            <AppTextInput
              name="password"
              type="password"
              eyeBtn={true}
              label="New Password"
              required
              InputProps={{ inputProps: { min: 0 } }}
              variant="outlined"
              className="mb-5"
              onChange={handleInputChange}
              helperText={errorText.password}
            />
          </div>
          <div>
            <AppTextInput
              type="password"
              eyeBtn={true}
              name="conformPassword"
              label="Confirm New Password"
              required
              InputProps={{ inputProps: { min: 0 } }}
              variant="outlined"
              className="mb-5"
              onChange={handleInputChange}
              helperText={errorText.conformPassword}
            />
          </div>
          <Box display="flex" justifyContent="flex-start" mb={4}>
            <Button onClick={onSubmitClick} variant="contained" color="primary">
              Confirm
            </Button>
            <Box ml={2}>
              <Button onClick={onClickLogin} variant="contained" color="primary">
                Login
              </Button>
            </Box>
          </Box>
        </form>
        <ContentLoader />
      </div>
    </AuthWrapper>
  );
};

export default ResetPassword;
