import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import {
  isValidEmail,
  isValidPassword,
  isValidPhone,
} from '@jumbo/utils/commonHelper';
import {
  createProduct,
  updateProduct,
} from 'redux/actions/Manage/Products/Products';
import WysiwygEditorExample from 'components/wysiwygEditor';
import { Tooltip } from 'chart.js';
import { Chip, Divider, Paper } from '@material-ui/core';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { Add, Remove } from '@material-ui/icons';
import { PRODUCT_CREATE_SUCCESS } from 'redux/actions';
import { Typography } from '@mui/material';
import Dropzone from 'react-dropzone';
// import { DropzoneArea } from 'material-ui-dropzone';
import { uploadFile } from 'redux/actions/FileUpload/FileUpload';
import {
  createGrade,
  updateGrade,
} from '../../../../../redux/actions/Manage/ProductMaterialGrades';
import CustomEditor from 'components/CustomEditor';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  dropZone: {
    minHeight: '100px',
    minWidth: '100px',
    // fullWidth: 'true',
  },
  previewContainer: {
    container: 'true',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  preview: {
    xs: '12',
  },
  errorCk: {
    color: '#f44336',
    marginRight: '14px',
    marginTop: '4px',
    fontSize: '0.75rem',
  },
  avatar: {
    borderRadius: '0',
    width: "140px",
    height: "140px",
    objectFit: "cover",
    marginTop: "14px",
  },
  textarea: {
    width: '100%',
    height: '90%',
    padding: "8px",
    fontSize: "19px",
    fontFamily: "sans-serif",
    fontWeight: 200,
    borderColor: "lightgray",
    borderRadius: "4px",
    '&:focus': {
      borderColor: "#1C61CA",
      borderWidth: "2px",
      outline: "none",
      borderRadius: 4
    },
  },
  label: {
    fontSize: '16px',
    fontFamily: "inherit",
    fontWeight: 400,
    lineHeight: 1.43,
  },
  grid: {
    marginBottom: "-20px",
    marginTop: "-20px"
  }
}));

const EditGrade = ({ history, open, onCloseDialog, setDataUpdated, grade }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  //------------all use selectors
  const { user } = useSelector(({ auth }) => auth.authUser);
  // const { grade } = useSelector(state => state.SetGradeDetail);
  const successAction = useSelector(state => state.Success.action);

  //-------------all use states
  const [keywordsArr, setkeywordsArr] = useState([]);
  const [parameterValue, setParameterValue] = useState("");
  const [descriptionHTML, setDescriptionHTML] = useState('');
  const [file, setFile] = useState([]);
  const [productDetail, setproductDetail] = useState({
    code: '',
    description: '',
    h1: '',
    h2: '',
    idOfProductCreator: '',
    keywords: '',
    parameters:[],
    meta: '',
    name: '',
    nameOfProductCreator: '',
    title: '',
    url: '',
    AltImageName: '',
    image_path: '',
    preview: '',
    gradeId: '',
    productId: null,
    materialId: null,
    subproductId: null,
  });

  const [errorText, setErrorText] = React.useState({});
  const [metaCount, setMetaCount] = useState({
    title: 0,
    meta: 0
  })

  const countWords = str => {
    // Remove leading and trailing whitespaces
    str = str?.trim();

    // Remove consecutive whitespaces and replace them with a single space
    str = str?.replace(/\s+/g, ' ');

    // Split the string by spaces to get an array of words
    var words = str?.split(' ');

    // Return the number of words
    return words.length;
  };

  //-------all handle functions
  // const validate = () => {
  //   const errors = {};
  //   let isError = false;
  //   const {
  //     code,
  //     description,
  //     h1,
  //     h2,
  //     meta,
  //     name,
  //     title,
  //     url,
  //     image_path,
  //   } = productDetail;
  //   if (!name) {
  //     errors.name = 'Please enter name.';
  //     isError = true;
  //   }
  //   // else if (!/^[a-zA-Z0-9 ]*$/.test(name)) {
  //   //   errors.name = 'Only letters and numbers are allowed.';
  //   //   isError = true;
  //   // }
  //   if (!code) {
  //     errors.code = 'Please enter code.';
  //     isError = true;
  //   }
  //   //  else if (!/^[a-z0-9-]*$/.test(code)) {
  //   //   errors.code = 'Only small letters and numbers with - are allowed.';
  //   //   isError = true;
  //   // }
  //   // if (!description) {
  //   //   errors.description = 'Please enter name.';
  //   //   isError = true;
  //   // }
  //   // if (!h1) {
  //   //   errors.h1 = 'Please enter h1.';
  //   //   isError = true;
  //   // } 
  //   // else if (!/^[a-zA-Z0-9 ]*$/.test(h1)) {
  //   //   errors.h1 = 'Only letters and numbers are allowed.';
  //   //   isError = true;
  //   // }
  //   // if (!h2) {
  //   //   errors.h2 = 'Please enter h2.';
  //   //   isError = true;
  //   // } else if (!/^[a-zA-Z0-9 ]*$/.test(h2)) {
  //   //   errors.h2 = 'Only letters and numbers are allowed.';
  //   //   isError = true;
  //   // }
  //   if (!meta) {
  //     errors.meta = 'Please enter meta.';
  //     isError = true;
  //   }
  //   if (countWords(meta) > 300) {
  //     errors.meta = 'Meta description must be less than 300 words.';
  //     isError = true;
  //   }
  //   // if (!name) {
  //   //   errors.name = 'Please enter name.';
  //   //   isError = true;
  //   // }
  //   if (!title) {
  //     errors.title = 'Please enter title.';
  //     isError = true;
  //   }
  //   if (!url) {
  //     errors.url = 'Please enter url.';
  //     isError = true;
  //   }
  //   // else if (!/^[a-z0-9-.]*$/.test(url)) {
  //   //   errors.url = 'Only small letters with - or . are allowed.';
  //   //   isError = true;
  //   // }
  //   // if (!image_path) {
  //   //   errors.image_path = 'Please upload image.';
  //   //   isError = true;
  //   // }
  //   setErrorText(errors);
  //   return {
  //     errors,
  //     isError,
  //   };
  // };//previous code
  const validate = () => {
    let errors = {};
    let isError = false;
    if (!productId) {
      errors.productId = 'Please select product.';
      isError = true;
    }
    if (!materialId) {
      errors.materialId = 'Please select material.';
      isError = true;
    }
    // if (!subProductId) {
    //   errors.subProductId = 'Please select sub-product.';
    //   isError = true;
    // }
    // if (!gradeId) {
    //   errors.gradeId = 'Please select grade.';
    //   isError = true;
    // }
    if (!keywordsArr.length) {
      errors.keywords = 'Please enter keyword.';
      isError = true;
    }
    if (!name) {
      errors.name = 'Please enter name.';
      isError = true;
    }
    if (!url) {
      errors.url = 'Please enter url.';
      isError = true;
    }
    if (!meta) {
      errors.meta = 'Please enter meta.';
      isError = true;
    }
    if (countWords(meta) > 300) {
      errors.meta = 'Meta description must be less than 300 words.';
      isError = true;
    }
    if (!title) {
      errors.title = 'Please enter meta title.';
      isError = true;
    }
    if (!descriptionHTML) {
      errors.descriptionHTML = 'Please enter description.';
      isError = true;
    }
    if (!gradeId) {
      errors.gradeId = 'Please select Grade';
      isError = true;
    }
    if (parameters?.length < 4) {
      errors.parameters = 'Please enter minimum 4 parameters';
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };
  const validateKeyword = () => {
    const errors = {};
    let isError = false;
    const { keywords } = productDetail;
    if (!keywords) {
      errors.keywords = 'Please enter keywords.';
      isError = true;
    }
    // else if (!/^[a-zA-Z-]*$/.test(keywords)) {
    //   errors.keywords = 'Only letters and - are allowed.';
    //   isError = true;
    // }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };
  const onKeywordsAdd = () => {
    let validateData = validateKeyword();
    if (!validateData.isError)
      setkeywordsArr(keywordsArr => [...keywordsArr, keywords]);
    setproductDetail({ ...productDetail, keywords: '' });
  };
  const onKeywordsDelete = (e, value) => {
    setkeywordsArr(keywordsArr => keywordsArr.filter(e => e != value));
  };
  const onLabelChange = evt => {
    const { name, value } = evt.target;
    const regex = /[\W_]+|\s+/g;
    if (name === 'name') {
      const nameValue = value ? value.replace(regex, '-').toLowerCase() : '';
      console.log(nameValue);
      productDetail.code = nameValue
      productDetail.url = nameValue
    }
    setproductDetail({ ...productDetail, [evt.target.name]: evt.target.value });
    setMetaCount({ ...metaCount, [name]: value.length })
  };

  const onSubmitClick = () => {
    let validateData = validate();
    if (!validateData.isError) {
      onproductSave();
    }
  };
  const onproductSave = () => {
    let keywordStr = '';
    keywordsArr.map(e => (keywordStr += ',' + e));
    const productDetail = {
      code,
      description: descriptionHTML,
      h1,
      h2,
      idOfProductCreator: user?.userId,
      keywords: keywordStr,
      meta,
      name,
      AltImageName,
      nameOfProductCreator: user?.name,
      title,
      url,
      image_path,
      productId,
      materialId,
      subproductId,
    };

    let formData = new FormData();

    formData.append('code', code);
    formData.append('description', descriptionHTML);
    formData.append('h1', h1);
    formData.append('h2', h2);
    formData.append('idOfProductCreator', user?.userId);
    formData.append('keywords', keywordStr);
    formData.append('parameters', JSON.stringify(parameters));
    formData.append('meta', meta);
    formData.append('name', name);
    formData.append('nameOfProductCreator', user?.name);
    formData.append('title', title);
    formData.append('url', url);
    formData.append('logo', file[0]);
    formData.append('productId', productId);
    formData.append('materialId', materialId);
    formData.append('subproductId', subproductId)
    AltImageName && formData.append('AltImageName', AltImageName)
    gradeId && formData.append('gradeId', gradeId);

    if (grade) {
      let newProduct = { ...grade, ...productDetail };
      const { _id, materials, ...data } = newProduct;
      dispatch(
        updateGrade(formData, data => {
          window.reload();
        }),
      );
    } else {
      dispatch(
        createGrade(formData, data => {
          if (data) history.push('/manage-product-material-grade');
        }),
      );
    }
  };

  //------all useEffects

  useEffect(() => {
    if (grade) {
      const {
        code,
        description,
        h1,
        h2,
        idOfProductCreator,
        keywords,
        parameters,
        meta,
        name,
        nameOfProductCreator,
        title,
        url,
        AltImageName,
        image_path,
        logo,
        gradeId,
        productId,
        materialId,
        subproductId,
      } = grade;
      setproductDetail({
        code,
        description,
        h1,
        h2,
        idOfProductCreator,
        keywords: '',
        parameters,
        meta,
        name,
        nameOfProductCreator,
        title,
        url,
        image_path,
        AltImageName,
        preview: logo,
        gradeId,
        productId,
        materialId,
        subproductId,
      });
      setDescriptionHTML(description)
      setMetaCount({ ...metaCount, title: title?.length, meta: meta?.length })
    }
  }, [grade]);
  useEffect(() => {
    if (grade) {
      const data = grade?.keywords.split(',');
      setkeywordsArr(data);
    }
  }, [grade]);

  const {
    code,
    description,
    h1,
    h2,
    meta,
    keywords,
    parameters,
    name,
    title,
    url,
    image_path,
    preview,
    gradeId,
    productId,
    materialId,
    subproductId,
    AltImageName
  } = productDetail;
  return (
    <PageContainer>
      <div>
        <Paper className={classes.paper}></Paper>
        <CmtCard>
          <CmtCardContent>
            <Typography variant="h6">Edit Product Material Grade</Typography>
            <Divider></Divider>
            <Box
              mt={3}
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
              alignItems="center"
              mb={{ xs: 6, md: 5 }}
            >
              <GridContainer>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    fullWidth
                    name="name"
                    variant="outlined"
                    label="Grade Name*"
                    value={name}
                    onChange={onLabelChange}
                    helperText={errorText?.name}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <AppTextInput
                    fullWidth
                    name="code"
                    variant="outlined"
                    label="Code*"
                    value={code}
                    onChange={onLabelChange}
                    helperText={errorText?.code}
                  />
                </Grid> */}
                {/* <Grid item xs={12} sm={6}>
                  <AppTextInput
                    fullWidth
                    name="h1"
                    variant="outlined"
                    label="H1"
                    value={h1}
                    onChange={onLabelChange}
                    helperText={errorText?.h1}
                  />
                </Grid> */}
                {/* <Grid item xs={12} sm={6}>
                  <AppTextInput
                    fullWidth
                    name="h2"
                    variant="outlined"
                    label="H2"
                    value={h2}
                    onChange={onLabelChange}
                    helperText={errorText?.h2}
                  />
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    fullWidth
                    name="title"
                    variant="outlined"
                    label={`Meta Title* / ${metaCount.title}`}
                    value={title}
                    onChange={onLabelChange}
                    helperText={errorText?.title}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    required
                    fullWidth
                    name="url"
                    variant="outlined"
                    label="URL"
                    value={url}
                    onChange={onLabelChange}
                    helperText={errorText?.url}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    fullWidth
                    name="AltImageName"
                    variant="outlined"
                    // required
                    label="Alt Image Name"
                    value={AltImageName}
                    onChange={onLabelChange}
                  />
                </Grid>
                {/* <Grid className={classes.grid} item xs={12} sm={6}>
                  <label className={classes.label}></label>
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  {/* <Typography component="h3" variant="p" className="mb-2">
                    Upload Logo
                  </Typography> */}
                  {/* <DropzoneArea
                    initialFiles={[file[0]?.url]}
                    required
                    dropzoneClass={classes.dropZone}
                    previewGridClasses={{
                      container: classes.previewContainer,
                      item: classes.preview,
                    }}
                    filesLimit={1}
                    acceptedFiles={['image/*']}
                    dropzoneText={'Upload Logo | Drag and drop an image here or click'}
                    // onChange={files => setFile(files)}
                    onChange={files => handleFileUpload(files)}
                    showAlerts={false}
                    onDelete={() => setFile([])}
                  />
                  <p className={classes.errorCk}>{errorText.file ? errorText.file : ''}</p> */}
                  {/* <AppTextInput
                    fullWidth
                    name='image_path'
                    variant="outlined"
                    label="Upload Image"
                    value={image_path}
                    onChange={onLabelChange}
                    helperText={errorText?.image_path}
                  /> */}

                  <AppTextInput
                    fullWidth
                    name="file"
                    type="file"
                    variant="outlined"
                    onChange={evt => {
                      setproductDetail({ ...productDetail, preview: '' });
                      setFile(evt.target.files);
                    }}
                    helperText={errorText?.file}
                  />
                  {preview && <img src={preview} alt={AltImageName} className={classes?.avatar} />}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <label className={classes.label}>Meta Description*</label>
                    <label className={classes.label}>{metaCount.meta}</label>
                  </Box>
                  <textarea
                    className={classes.textarea}
                    rows={2}
                    fullWidth
                    name="meta"
                    variant="outlined"
                    // placeholder="Meta Description*"
                    value={meta}
                    onChange={onLabelChange}
                  // helperText={errorText?.meta}
                  />
                  {errorText.meta ? (
                    <p style={{ color: '#f44336', fontSize: '12px' }}>
                      {errorText?.meta}
                    </p>
                  ) : (
                    ''
                  )}
                </Grid>
                {/* parameters */}
                <Grid item xs={12} sm={11}>
                  <AppTextInput
                    fullWidth
                    required
                    name="parameterValue"
                    variant="outlined"
                    label="Parameters"
                    value={parameterValue}
                    onChange={(e) => setParameterValue(e.target.value)}
                    helperText={errorText?.parameters}
                  />
                </Grid>
                <Grid item sm={1}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setproductDetail((pre) => {
                        if (parameterValue) {
                          return { ...pre, parameters: [...pre.parameters, parameterValue] }
                        } else {
                          return pre
                        }
                      })
                      setParameterValue("")
                    }}
                  >
                    <Add />
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box>
                    {parameters &&
                      parameters.length > 0 &&
                      parameters.map((e, key) => {
                        return (
                          e.length !== 0 && (
                            <Chip
                              size="medium"
                              key={key}
                              label={e}
                              clickable
                              onDelete={evt => {
                                const deleteParameterValue = productDetail.parameters.filter((ele, i) => ele !== e)
                                setproductDetail((pre) => ({ ...pre, parameters: deleteParameterValue }))
                              }}
                              color="secondary"
                              variant="outlined"
                            />
                          )
                        );
                      })}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={11}>
                  <AppTextInput
                    fullWidth
                    name="keywords"
                    variant="outlined"
                    label="Keywords*"
                    value={keywords}
                    onChange={onLabelChange}
                    helperText={errorText?.keywords}
                  />
                </Grid>
                <Grid item sm={1}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={onKeywordsAdd}
                  >
                    <Add />
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box>
                    {keywordsArr &&
                      keywordsArr.length > 0 &&
                      keywordsArr.map((e, key) => {
                        return (
                          e.length !== 0 && (
                            <Chip
                              size="medium"
                              key={key}
                              label={e}
                              clickable
                              onDelete={evt => onKeywordsDelete(evt, e)}
                              color="secondary"
                              variant="outlined"
                            />
                          )
                        );
                      })}
                  </Box>
                </Grid>
                <p
                  style={{ marginLeft: "12px" }}
                  className={
                    errorText?.descriptionHTML ? classes?.errorText : ''
                  }
                >
                  Description *
                </p>
                <Grid item xs={12} sm={12}>
                  {/* <WysiwygEditorExample
                    defaultDescription={description}
                    setDescriptionHTML={setDescriptionHTML}
                    descriptionHTML={description}
                  /> */}
                  <CustomEditor
                    editorData={descriptionHTML || ''}
                    setEditorData={setDescriptionHTML}
                    error={errorText?.descriptionHTML}
                  />
                </Grid>
              </GridContainer>
            </Box>
            <Box display="flex" justifyContent="flex-end" mb={4}>
              <Button
                onClick={() => history.push('/manage-product-material-grade')}
              >
                Cancel
              </Button>
              <Box ml={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onSubmitClick}
                >
                  {grade ? 'Update' : 'Save'}
                </Button>
              </Box>
            </Box>
          </CmtCardContent>
        </CmtCard>
      </div>
    </PageContainer>
  );
};

export default EditGrade;

EditGrade.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
