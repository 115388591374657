//-------------EMAIL constants------------------
export const EMAIL_LIST_REQUEST = "EMAIL_LIST_REQUEST";
export const EMAIL_LIST_SUCCESS = "EMAIL_LIST_SUCCESS";
export const EMAIL_LIST_FAILED = "EMAIL_LIST_FAILED";
export const EMAIL_TYPE_REQUEST = "EMAIL_TYPE_REQUEST";
export const EMAIL_TYPE_SUCCESS = "EMAIL_TYPE_SUCCESS";
export const EMAIL_TYPE_FAILED = "EMAIL_TYPE_FAILED";
export const BULK_EMAIL_REQUEST = "BULK_EMAIL_REQUEST";
export const BULK_EMAIL_SUCCESS = "BULK_EMAIL_SUCCESS";
export const BULK_EMAIL_FAILED = "BULK_EMAIL_FAILED";
export const SET_EMAIL_DETAILS = "SET_EMAIL_DETAILS";
export const EMAIL_CREATE_REQUEST = "EMAIL_CREATE_REQUEST";
export const EMAIL_CREATE_SUCCESS = "EMAIL_CREATE_SUCCESS";
export const EMAIL_CREATE_FAILED = "EMAIL_CREATE_FAILED";
export const EMAIL_UPDATE_REQUEST = "EMAIL_UPDATE_REQUEST";
export const EMAIL_UPDATE_SUCCESS = "EMAIL_UPDATE_SUCCESS";
export const EMAIL_UPDATE_FAILED = "EMAIL_UPDATE_FAILED";
export const EMAIL_UPDATE_STATUS_REQUEST = "EMAIL_UPDATE_STATUS_REQUEST";
export const EMAIL_UPDATE_STATUS_SUCCESS = "EMAIL_UPDATE_STATUS_SUCCESS";
export const EMAIL_UPDATE_STATUS_FAILED = "EMAIL_UPDATE_STATUS_FAILED";
export const EMAIL_DETAIL_REQUEST = "EMAIL_DETAIL_REQUEST";
export const EMAIL_DETAIL_SUCCESS = "EMAIL_DETAIL_SUCCESS";
export const EMAIL_DETAIL_FAILED = "EMAIL_DETAIL_FAILED";
export const EMAIL_PASSWORD_UPDATE_REQUEST = "EMAIL_PASSWORD_UPDATE_REQUEST";
export const EMAIL_PASSWORD_UPDATE_SUCCESS = "EMAIL_PASSWORD_UPDATE_SUCCESS";
export const EMAIL_PASSWORD_UPDATE_FAILED = "EMAIL_PASSWORD_UPDATE_FAILED";
export const EMAIL_DELETE_REQUEST = "EMAIL_DELETE_REQUEST";
export const EMAIL_DELETE_SUCCESS = "EMAIL_DELETE_SUCCESS";
export const EMAIL_DELETE_FAILED = "EMAIL_DELETE_FAILED";
export const EMAIL_APPROVE_REQUEST = "EMAIL_APPROVE_REQUEST";
export const EMAIL_APPROVE_SUCCESS = "EMAIL_APPROVE_SUCCESS";
export const EMAIL_APPROVE_FAILED = "EMAIL_APPROVE_FAILED";
export const EMAIL_FETCH_SUPPLIER_PLAN_REQUEST = "EMAIL_FETCH_SUPPLIER_PLAN_REQUEST";
export const EMAIL_FETCH_SUPPLIER_PLAN_SUCCESS = "EMAIL_FETCH_SUPPLIER_PLAN_SUCCESS";
export const EMAIL_FETCH_SUPPLIER_PLAN_FAILED = "EMAIL_FETCH_SUPPLIER_PLAN_FAILED";
export const EMAIL_FETCH_CATEGORY_REQUEST = "EMAIL_FETCH_CATEGORY_REQUEST";
export const EMAIL_FETCH_CATEGORY_SUCCESS = "EMAIL_FETCH_CATEGORY_SUCCESS";
export const EMAIL_FETCH_CATEGORY_FAILED = "EMAIL_FETCH_CATEGORY_FAILED";
export const SET_FILTER_TYPE = "SET_FILTER_TYPE";
export const SET_SELECTED_MAIL = "SET_SELECTED_MAIL";
export const SET_EMAIL_LIST_POPUP = "SET_EMAIL_LIST_POPUP";

export const SEND_EMAIL_REQUEST = "SEND_EMAIL_REQUEST";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAILED = "SEND_EMAIL_FAILED";
