import Axios from "axios";
import { ErrorCatch } from "utils/ErrorCatch";
import { ADD_PLAN_LIST_FAILED, ADD_PLAN_LIST_SUCCESS, PLAN_LIST_FAILED, PLAN_LIST_SUCCESS } from "./Constant";
import { setSuccess } from "redux/actions/Common/SuccessAction";
import { setError } from "redux/actions/Common/ErrorAction";


export const getPlanList = params => {
    return dispatch => {
        return Axios.get('api/v1/admin/plan/list', { params })
            .then(response => {
                if (response?.status === 200) {
                    const { planList, total, perPage } = response?.data?.data;
                    dispatch({
                        type: PLAN_LIST_SUCCESS,
                        data: planList,
                        total: total,
                        perPage: perPage,
                    });
                    return Promise.resolve(response);
                }
            })
            .catch(err => {
                ErrorCatch(err, PLAN_LIST_FAILED, dispatch);
                return Promise.resolve(err);
            });
    };
};

export const createPlanList = (payload) => {
    return dispatch => {
        return Axios.post('api/v1/admin/plan', payload)
            .then(response => {
                if (response?.status === 200) {
                    const { createPlan } = response?.data?.data;
                    dispatch(setSuccess(response?.data?.responseMessage, ADD_PLAN_LIST_SUCCESS))
                    dispatch({
                        type: ADD_PLAN_LIST_SUCCESS,
                        data: createPlan,
                    });
                    return Promise.resolve(response);
                }
            })
            .catch(err => {
                dispatch(setError(err?.data?.responseMessage, ADD_PLAN_LIST_FAILED));
                return Promise.resolve(err);
            });
    };
};

export const editPlanList = (id) => {
    return dispatch => {
        return Axios.get(`api/v1/admin/plan/${id}`)
            .then(response => {
                if (response?.status === 200) {

                    return Promise.resolve(response);
                }
            })
            .catch(err => {
                ErrorCatch(err, dispatch);
                return Promise.resolve(err);
            });
    };
};


export const updatePlanList = (id, payload) => {
    return dispatch => {
        return Axios.put(`api/v1/admin/plan/${id}`, payload)
            .then(response => {
                if (response?.status === 200) {
                    dispatch(setSuccess(response?.data?.responseMessage, ADD_PLAN_LIST_SUCCESS))
                    const { planDetail } = response?.data?.data;
                    dispatch({
                        type: ADD_PLAN_LIST_SUCCESS,
                        data: planDetail,
                    });
                    return Promise.resolve(response);
                }
            })
            .catch(err => {
                dispatch(setError(err?.data?.responseMessage, ADD_PLAN_LIST_FAILED));
                return Promise.resolve(err);
            });
    };
};