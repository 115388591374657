import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Typography,
  IconButton,
  withWidth,
  Chip,
} from '@material-ui/core';
import { Photo, GetApp, Reply, Done, Schedule } from '@material-ui/icons';
import { Shortcut } from '@mui/icons-material';

import MailLabels from '../MailsList/MailLabels';
import useStyles from '../index.style';
import ReplyMailForm from './ReplyMailForm';
import DetailHeader from './DetailHeader';
import MailReply from './MailReply';
import config from 'utils/Config.util';

import CmtObjectSummary from '@coremat/CmtObjectSummary';
import CmtAvatar from '@coremat/CmtAvatar';
import { getDateInDesiredFormat, isToday } from '@jumbo/utils/dateHelper';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import { linkify } from '@jumbo/utils/commonHelper';
import { getMailContainerHeight } from '@jumbo/constants/AppConstants';

import { onReplyToMail, onUpdateMail } from 'redux/actions/MailApp';

const MailDetail = ({ width, onClickForwardMail }) => {
  const { showFooter } = useContext(AppContext);
  const dispatch = useDispatch();
  const { selectedMail, labelsList, filterType } = useSelector(
    ({ Emails }) => Emails,
  );
  const classes = useStyles({
    height: getMailContainerHeight(width, showFooter),
    imageRoot: {
      width: 2,
      height: 2,
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    // messageItemInner:{
    //   '& p, figure, table tr td':{
    //     border:"1px solid black"
    //   }
    // }
  });

  const getSenderInfo = () => (
    <Box component="span" display="flex" alignItems="center">
      <Box component="span" fontSize={16}>
        {selectedMail.senderDetails?.name}
      </Box>
      <Box
        component="span"
        fontSize={12}
        ml={2}
        color="text.secondary"
      >{`<${selectedMail?.senderEmail}>`}</Box>
    </Box>
  );

  // const onClickReplyMail = mail => {
  //   dispatch(onReplyToMail(selectedMail._id, mail));
  // };

  const getMailDate = date => {
    return isToday(date) ? 'Today' : getDateInDesiredFormat(date, 'DD/MM/YYYY');
    // : status === 'scheduled'
    // ? getDateInDesiredFormat(date, 'DD/MM/YYYY')
    // : getDateInDesiredFormat(date, 'MMM DD YYYY');
  };

  const downloadAttachment = item => {
    // window.location.href(encodeURI(config.REACT_APP_API_BASE_URL + '/' + item.filename))
  };

  const onShowAttachments = attachments => {
    return (
      <Box className={classes.attachRoot}>
        {attachments.map((item, index) => {
          let filePath =
            process.env.NODE_ENV === 'production'
              ? item?.path.split('/')
              : item?.path.split('/');
          return (
            <>
              {/* <Link to={encodeURI(config.REACT_APP_API_BASE_URL + '/' + item.filename)} className="btn btn-primary"> */}
              <a
                href={encodeURI(
                  config.REACT_APP_API_BASE_URL + '/' + filePath[1],
                )}
                target="_blank"
                rel="noreferrer"
              >
                <Chip
                  label={filePath[1]}
                  key={index}
                  icon={<Photo />}
                  deleteIcon={<GetApp />}
                  onDelete={() => downloadAttachment(item)}
                />
              </a>
              {/* </Link> */}
              {/* <Box  > */}
              {/* <CmtCardMedia className={classes.imageRoot} image={encodeURI(config.REACT_APP_API_BASE_URL + '/' + item.filename)} /> */}
              {/* </Box> */}
            </>
          );
        })}
      </Box>
    );
  };

  const {
    senderEmail,
    otherReceivers,
    receiverEmail,
    replyThread,
    senderDetails,
    emailContent,
    read,
    emailType,
    date,
    createdAt,
    trashDate,
    companyName,
    emailStatus,
    status,
    sentDate,
    updatedAt,
    scheduleOn,
  } = selectedMail;

  // const { subject, labels, from, to, date, message, replyThread, attachments } = selectedMail;
  const labels = [emailType];
  return (
    <Box className={classes.inBuildAppMainContent}>
      <DetailHeader
        classes={classes}
        selectedMail={selectedMail}
        labelsList={labelsList}
      />

      <PerfectScrollbar className={classes.perfectScrollbarMailCon}>
        <Box flex={1} display="flex" flexDirection="column">
          <Box className={classes.detailHeader}>
            <Box className={classes.detailHeaderSub}>
              <Typography
                component="div"
                variant="h1"
                className={classes.subjectTitle}
              >
                {emailContent?.subject}
              </Typography>
              <Box ml={{ xs: -1, sm: 'auto' }}>
                {labels?.length > 0 && labelsList?.length > 0 && (
                  <MailLabels mailLabels={labels} labelsList={labelsList} />
                )}
              </Box>
            </Box>
            <CmtObjectSummary
              avatar={
                <CmtAvatar
                  size={40}
                  src={senderDetails?.profile_pic}
                  alt={senderDetails?.name}
                />
              }
              title={getSenderInfo()}
              subTitle={receiverEmail?.map(
                (e, index) => index + 1 + '-' + e + '  ',
              )}
              showItemBadge={false}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              align={'horizontal'}
            />
          </Box>
          <Box className={classes.messageDetail}>
            <Box className={classes.messageItem}>
              <Box className={classes.messageItemInner}>
                {status === 'scheduled' ? (
                  <Box className={classes.scheduleDateRoot}>
                    <span>Received in Inbox - {getMailDate(createdAt)}</span>
                    <br />
                    <span>
                      Scheduled Time & Date -{' '}
                      {getDateInDesiredFormat(scheduleOn, 'DD/MM/YYYY hh:mm A')}
                    </span>
                  </Box>
                ) : status === 'trash' ? (
                  <Box className={classes.scheduleDateRoot}>
                    <span>Received in Inbox - {getMailDate(createdAt)}</span>
                    <br />
                    <span>
                      Trash Time & Date -{' '}
                      {getDateInDesiredFormat(trashDate, 'DD/MM/YYYY hh:mm A')}
                    </span>
                  </Box>
                ) : (
                  <Box className={classes.dateRoot}>
                    {/* {getMailDate(createdAt)} */}
                    {status === 'sent'
                      ? getMailDate(sentDate)
                      : status === 'inbox'
                      ? getMailDate(createdAt)
                      : getMailDate(updatedAt)}
                  </Box>
                )}

                {status === 'inbox' ||
                status === 'scheduled' ||
                status === 'approve' ||
                status === 'sent' ? (
                  <Box
                    className={
                      status === 'scheduled' || status === 'approve'
                        ? classes?.scheduleReplyRoot
                        : classes.replyRoot
                    }
                    style={{ display: 'flex', justifyContent: 'end' }}
                  >
                    <IconButton
                      onClick={() => {
                        if (status === 'sent' || status === 'approve') {
                          localStorage.setItem('forward', true);
                        }
                        onClickForwardMail(
                          emailContent?.html,
                          selectedMail?._id,
                        );
                      }}
                    >
                      {status === 'scheduled' ? (
                        <Schedule />
                      ) : status === 'sent' || status === 'approve' ? (
                        <Shortcut />
                      ) : (
                        <Done />
                      )}
                    </IconButton>
                  </Box>
                ) : null}
                {emailType === 'enquiry' && (
                  <Box style={{ display: 'flex', flexDirection: 'column' }}>
                    {senderDetails?.name && (
                      <span>
                        <strong>Name : </strong>
                        {senderDetails?.name}
                      </span>
                    )}
                    {senderDetails?.mobileNo && (
                      <span>
                        <strong>Contact No. : </strong>
                        {senderDetails?.mobileNo}
                      </span>
                    )}
                    {senderDetails?.country && (
                      <span>
                        <strong>Country : </strong>
                        {senderDetails?.country}
                      </span>
                    )}
                    {companyName && (
                      <span>
                        <strong>Company : </strong>
                        {companyName}
                      </span>
                    )}
                    <span className="mt-4">
                      <strong>Requirement : </strong>
                    </span>
                  </Box>
                )}
                {/* {console.log("emailContent =====> ",emailContent.html.replace(/(?:\r\n|\r|\n)/g,'<br />',))} */}
                <Box
                  mb={
                    emailContent?.attachments &&
                    emailContent?.attachments?.length > 0
                      ? 8
                      : 0
                  }
                  component="p"
                  className={classes?.tableStyle}
                  dangerouslySetInnerHTML={{
                    __html: emailContent?.html && emailContent?.html,
                  }}

                  // ------- Old Code -----------------
                  // dangerouslySetInnerHTML={{
                  //   __html:  emailContent?.html && emailContent?.html.replace(
                  //   /(?:\r\n|\r|\n)/g,
                  //   '<br />',
                  // ),
                  // }}

                  // dangerouslySetInnerHTML={{
                  //   __html: linkify(
                  //     emailContent?.raw?.text
                  //       ? emailContent?.raw?.text.replace(
                  //           /(?:\r\n|\r|\n)/g,
                  //           '<br />',
                  //         )
                  //       : emailContent.html.replace(
                  //           /(?:\r\n|\r|\n)/g,
                  //           '<br />',
                  //         ),
                  //   ),
                  // }}
                />
                {emailContent?.attachments &&
                  emailContent?.attachments?.length > 0 &&
                  onShowAttachments(emailContent?.attachments)}
              </Box>
            </Box>
            {/* 
            {replyThread.map((reply, index) => (
              <MailReply
                key={index}
                classes={classes}
                reply={reply}
                getMailDate={getMailDate}
                onShowAttachments={onShowAttachments}
                onClickForwardMail={onClickForwardMail}
              />
            ))} */}
          </Box>

          {/* <ReplyMailForm onClickReplyMail={onClickReplyMail} /> */}
        </Box>
      </PerfectScrollbar>
    </Box>
  );
};

export default withWidth()(MailDetail);

MailDetail.prototype = {
  onClickForwardMail: PropTypes.func,
};
