import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductMaterialGradeList, setProductDetails } from 'redux/actions/Manage/Suppliers/Suppliers';
import { useParams } from 'react-router';
import PageLoader from '@jumbo/components/PageComponents/PageLoader';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
const useStyles = makeStyles(theme => ({
  circularProgressRoot: {
    // position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 999999,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // border:'1px solid red'
  },
}));

const Products = ({onProductSelect,supplierProducts }) => {
  const classes = useStyles();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { supplier } = useSelector(state => state.SetSupplierDetail);
    const {products} = useSelector(state => state.SetProductMaterialGradeList);
    // const { supplierProductList } = useSelector(state => state.SetSupplierDetail);
    // console.log(products,"12 Product.js")
// useState
const [selected, setSelected] = useState(null);


//  Funtions
const selectProduct = (data) => {
    setSelected(data.productId); 
    onProductSelect(data.productId); // Call the callback function from parent component
  };
  // console.log(selected)
// product count
const productCounts = supplierProducts?.reduce((counts, product) => {
  counts[product.productId] = (counts[product.productId] || 0) + 1;
  return counts;
}, {});

// products present to highlight

const productIdsSet = new Set(supplierProducts?.map(product => product.productId));

//  all useEffect
useEffect(()=>{
  
    dispatch(fetchProductMaterialGradeList())
    dispatch(setProductDetails(id));
    },[])
  
//  console.log(selected,"Child component")
if (!(products && supplierProducts)){
    return (
      <Box className={classes.circularProgressRoot}>
        <CircularProgress />
      </Box>
    );
}
  return (
    <Box >
      <Box bgcolor={'black'} color={'white'} textAlign={'center'} p={3}   zIndex='1000'>
        Products
      </Box>
      <Box p={2}   height={'68vh'} overflow={'auto'}>
        {products &&
          products?.map((data, index) => (
            <Box
              key={index}
              style={{
                cursor: 'pointer',
                backgroundColor:
                  selected === data.productId
                    ? '#D9F7FF'
                    : productIdsSet?.has(data.productId)
                    ? '#D9F7FF'
                    : 'white',
              }}
              onClick={() => selectProduct(data)}
            >
              <Box
                display={'flex'}
                justifyContent="space-between"
                alignItems="center"
              >
                <Box p={2}>
                  {data.name}{' '}
                  {/* {productCounts[data.productId] && (
      <span>{productCounts[data.productId] || ''}</span>
    )} */}
                </Box>
                <Box>
                  {productCounts[data.productId] && (
                    <span>{productCounts[data.productId] || ''}</span>
                  )}
                </Box>
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
}

export default Products
