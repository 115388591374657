import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';

import CmtAvatar from '@coremat/CmtAvatar';
import { makeStyles } from '@material-ui/core/styles';
import NoRecordFound from 'components/NoRecordFound';
import CmtObjectSummary from '@coremat/CmtObjectSummary';
import { getComparator, stableSort } from '@jumbo/utils/tableHelper';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
  },
  ellipsis: {
    display: 'block',
    width: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  rowCenter: {
    textAlign: "center"
  },
  actionTd: {
    whiteSpace: 'nowrap',
  },
}));

const CustomTableRow = ({ rowsPerPage, page, rowCenterClass, scroll, multiSelect, isFilterApplied, usersFetched, headCells, order, orderBy, row, isSelected, onRowClick, onUserEdit, onUserDelete, onUserChangePassword, onUserView }) => {
  const { loading } = useSelector((state) => state.Loading);
  const classes = useStyles();
  let selectedColumn = null;
  // if (orderBy) {
  //   selectedColumn = headCells.filter(data => data.id === orderBy && data);
  // }
  let rec = row;

  return (
    <>
      {row.length === 0 && (
        <TableRow style={{ margin: '0 auto' }}>
          {/* <TableCell colSpan={12} align="center">
            No Data Found
          </TableCell> */}
          <TableCell colSpan={7} rowSpan={10}>
            {isFilterApplied ? (
              <NoRecordFound>No records found with your filter.</NoRecordFound>
            ) : (
              <NoRecordFound>{loading ? 'Loading...' : 'No records found.'}</NoRecordFound>
            )}
          </TableCell>
        </TableRow>
      )}
        {rec && stableSort(rec, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((data, index) => {
          const isItemSelected = isSelected && isSelected(data?.id);
          const labelId = `enhanced-table-checkbox-${data.id}`;
          return (
            <React.Fragment key={data?.id || index}>
              <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={`row-${index}`}
                selected={isItemSelected}>
                {multiSelect && (
                  <TableCell padding="checkbox" onClick={event => onRowClick(event, data?.id)}>
                    <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                  </TableCell>
                )}
                {headCells &&
                  headCells.length &&
                  headCells.map((sinCol, colIndex) => {
                    return (
                      <React.Fragment key={`all-col-${colIndex}`}>
                        <TableCell className={classes} key={colIndex}>
                          {sinCol.image ? (
                            <CmtObjectSummary
                              avatar={<CmtAvatar src={''} alt={data[sinCol?.id]} />}
                              title={data[sinCol.id]}
                              titleProps={{ className: classes.titleRoot }}
                              showItemBadge={false}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              align={'horizontal'}
                            />
                          ) : (
                            <div className={sinCol.ellipsis ?
                              [classes.ellipsis, rowCenterClass && classes.rowCenter]
                                .filter(e => !!e)
                                .join(' ')
                              : scroll ? classes.actionTd : ''}>
                              {sinCol.render ? sinCol.render(data) : data[sinCol?.id]}
                            </div>
                          )}
                          {/* {data[sinCol?.id]} */}
                        </TableCell>
                      </React.Fragment>
                    );
                  })}
              </TableRow>
            </React.Fragment>
          );
        })}
    </>
  );
};
CustomTableRow.defaultProps = {
  row: [],

};
export default React.memo(CustomTableRow);
