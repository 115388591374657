import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { folderList } from '@fake-db/apps/mail';
import { alpha, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ArchiveIcon from '@material-ui/icons/Archive';
import DeleteIcon from '@material-ui/icons/Delete';
import LabelIcon from '@material-ui/icons/Label';
import FolderIcon from '@material-ui/icons/Folder';
import ReportIcon from '@material-ui/icons/Report';
import Hidden from '@material-ui/core/Hidden';
import DoneIcon from '@material-ui/icons/Done';
import {
  Cancel,
  CancelScheduleSend,
  Done,
  Inbox,
  Schedule,
  Send,
} from '@material-ui/icons';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import { useDispatch, useSelector } from 'react-redux';
import {
  cancelReScheduleEmails,
  changeEmailStatus,
  getAllEmails,
  getReScheduleEmails,
  sandItEmail,
} from 'redux/actions/Manage/Emails/Emails';

const useStyles = makeStyles(theme => ({
  borderLeft: {
    width: 1,
    height: 36,
    backgroundColor: alpha(theme.palette.common.dark, 0.12),
    marginLeft: 4,
  },
  iconBtn: {
    [theme.breakpoints.down('sm')]: {
      padding: 6,
      '& .MuiSvgIcon-root': {
        fontSize: 18,
      },
    },
  },
  menuItemsRoot: {
    fontSize: 16,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    '& .MuiSvgIcon-root': {
      display: 'block',
      fontSize: 20,
    },
  },
}));

const HeaderOptions = ({
  selectedMail,
  onChangeMailFolder,
  onSelectLabel,
  labelsList,
  canceled,
}) => {
  
  const [showLabels, setShowLabels] = useState(null);
  const [showFolders, setShowFolders] = useState(null);
  const classes = useStyles();
  const [emailAction, setEmailAction] = useState('');
  const { filterType } = useSelector(({ Emails }) => Emails);
  const dispatch = useDispatch();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const onShowLabels = event => {
    setShowLabels(event.currentTarget);
  };

  const onHideLabels = () => {
    setShowLabels(null);
  };

  const onShowFolders = event => {
    setShowFolders(event.currentTarget);
  };

  const onHideFolders = () => {
    setShowFolders(null);
  };

  const onClickLabelOption = label => {
    onSelectLabel(label.id);
    onHideLabels();
  };

  const onClickFolderOption = folder => {
    onChangeMailFolder(folder.slug);
    onHideFolders();
  };

  const handleTakeAction = action => {
    setOpenConfirmDialog(true);
    setEmailAction(action);
  };

  const handleConfirmAction = user => {
    setOpenConfirmDialog(false);
    let params = {
      id: selectedMail._id,
      status: emailAction,
    };
    // dispatch(changeEmailStatus(params))
    // let newParams = {
    //   status: filterType.selectedFolder,
    //   emailType: filterType.selectedLabel
    // }
    // dispatch(getAllEmails(newParams))
    dispatch(changeEmailStatus(params)).then(res => {
      if (res?.status === 200) {
        let newParams = {
          status: filterType.selectedFolder,
          emailType: filterType.selectedLabel,
        };
        dispatch(getAllEmails(newParams));
      }
    });
  };

  const handleConfirmCancelSchedule = () => {
    dispatch(cancelReScheduleEmails(selectedMail._id)).then(res => {
      if (res?.status === 200) {
        setOpenConfirmDialog(false);
        dispatch(getReScheduleEmails({ page: filterType.page }));
      }
    });
  };

  const handleConfirmSendIt = () => {
    dispatch(sandItEmail(selectedMail._id)).then(res => {
      if (res?.status === 200) {
        setOpenConfirmDialog(false);
        dispatch(getReScheduleEmails({ page: filterType.page }));
      }
    });
  };

  const handleCancelAction = user => {
    setOpenConfirmDialog(false);
  };

  return (
    <React.Fragment>
      <Hidden xsDown>
        {filterType &&
          filterType.selectedFolder !== 'disapprove' &&
          filterType.selectedFolder !== 're-schedule' &&
          filterType.selectedFolder !== 'approve' &&
          filterType.selectedFolder !== 'archive' &&
          filterType.selectedFolder !== 'trash' &&
          filterType.selectedFolder !== 'sent' &&
          selectedMail.status !== 'approve' &&
          selectedMail.status !== 'disapprove' && (
            <Tooltip title="Disapprove">
              <IconButton
                className="icon-btn"
                onClick={() => handleTakeAction('disapprove')}
              >
                <Cancel />
              </IconButton>
            </Tooltip>
          )}
        {/* <Tooltip title="Block">
            <IconButton className="icon-btn" onClick={() => onMoveMail('block')}>
              <Block />
            </IconButton>
          </Tooltip> */}
        {filterType &&
          filterType.selectedLabel?.length === 0 &&
          filterType.selectedFolder !== 'inbox' &&
          filterType.selectedFolder !== 're-schedule' &&
          filterType.selectedFolder !== 'approve' &&
          filterType.selectedFolder !== 'sent' && (
            <Tooltip title="Inbox">
              <IconButton
                className="icon-btn"
                onClick={() => handleTakeAction('inbox')}
              >
                <Inbox />
              </IconButton>
            </Tooltip>
          )}
        {filterType &&
          selectedMail.status !== 'archive' &&
          filterType.selectedFolder !== 're-schedule' &&
          filterType.selectedFolder !== 'archive' &&
          filterType.selectedFolder !== 'sent' && (
            <Tooltip title="Archive">
              <IconButton
                className="icon-btn"
                onClick={() => handleTakeAction('archive')}
              >
                <ArchiveIcon />
              </IconButton>
            </Tooltip>
          )}
        {filterType &&
          selectedMail.status !== 'trash' &&
          filterType.selectedFolder !== 're-schedule' &&
          filterType.selectedFolder !== 'trash' &&
          filterType.selectedFolder !== 'sent' && (
            <Tooltip title="Trash">
              <IconButton
                className="icon-btn"
                onClick={() => handleTakeAction('trash')}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        {filterType &&
          filterType.selectedFolder === 're-schedule' &&
          !selectedMail?.isCanceled && (
            <Tooltip title="Cancel Schedule">
              <IconButton
                className="icon-btn"
                onClick={() => handleTakeAction('cancel schedule')}
              >
                <CancelScheduleSend />
              </IconButton>
            </Tooltip>
          )}

        {canceled && filterType && filterType.selectedFolder === 're-schedule' && (
          <Tooltip title="Send">
            <IconButton
              className="icon-btn"
              onClick={() => {
                handleTakeAction('send');
              }}
            >
              <Send />
            </IconButton>
          </Tooltip>
        )}

        {/* {canceled && filterType &&
          filterType.selectedFolder === 're-schedule' &&
          (
            <Tooltip title="Send">
              <IconButton
                className="icon-btn"
                onClick={() => {
                  handleTakeAction('send');
                }}
              >
                <Send />
              </IconButton>
            </Tooltip>,
          )} */}

        <Box className={classes.borderLeft} />
      </Hidden>
      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm ${emailAction}`}
        content={
          emailAction === 'cancel schedule'
            ? 'Are you sure, you want to cancel this schedule email'
            : emailAction === 'send'
            ? 'Are you sure, you want to send this email immediately?'
            : `Are you sure, you want to ${emailAction} this email?`
        }
        onClose={handleCancelAction}
        onConfirm={
          emailAction === 'cancel schedule'
            ? handleConfirmCancelSchedule
            : emailAction === 'send'
            ? handleConfirmSendIt
            : handleConfirmAction
        }
      />
    </React.Fragment>
  );
};

export default HeaderOptions;

HeaderOptions.prototype = {
  onChangeMailFolder: PropTypes.func,
  onSelectLabel: PropTypes.func,
  labelsList: PropTypes.array.isRequired,
};
