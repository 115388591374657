import SubProduct from './Pages/Manage/SubProduct';

import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';
import IdleTimerModal from 'components/IdleTimerModal';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { useLocation } from 'react-router-dom';
import { AuhMethods } from 'services/auth';
import ForgotPasswordPage from './Auth/ForgotPassword';
import Login from './Auth/Login';
import Register from './Auth/Register';
import ResetPasswordPage from './Auth/ResetPassword';
import Error404 from './Pages/404';
import Dashboard from './Pages/Manage/Dashboard';

import IPBlock from './Pages/Manage/IPBlock';

import EmailBlock from './Pages/Manage/EmailBlock';

import Email from './Pages/Manage/Email';

import Products from './Pages/Manage/Products';
import AddEditProduct from './Pages/Manage/Products/AddEditProduct';

import Users from './Pages/Manage/Users';
import EditPermissions from './Pages/Manage/Users/EditPermissions';
import SamplePage from './Pages/SamplePage';
import Materials from './Pages/Manage/Materials';
import AddEditMaterial from './Pages/Manage/Materials/AddEditMaterial';
import AddGradeToMaterial from './Pages/Manage/Materials/AddGradeToMaterial';
import EmailPanel from './Pages/Manage/EmailPanel';
// Supplier
import Supplier from './Pages/Manage/Supplier';
// import AddEditSupplier from './Pages/Manage/Supplier/AddContact';

import News from './Pages/Manage/News';
import Grade from './Pages/Manage/Grade';
import EditGrade from './Pages/Manage/Grade/EditGrade';
import ProductMaterial from './Pages/Manage/ProductMaterial';
import AddEditSubProduct from './Pages/Manage/SubProduct/AddEditProduct';
import AddProductToSupplier from './Pages/Manage/Supplier/AddProductToSupplier';
import EqGrade from './Pages/Manage/EqGrade';
import AddEditEqGrade from './Pages/Manage/EqGrade/AddEditEqGrade';
import EditProductSupplier from './Pages/Manage/Supplier/EditProductSupplier';
import Mapping from './Pages/Manage/Mapping';
import AddEditMapping from './Pages/Manage/Mapping/AddEditMapping';
import SubProducts from './Pages/Manage/SubProduct';
import AddEditProductMaterial from './Pages/Manage/ProductMaterial/AddEditProductMaterial';
import AddEditMetarialAndFaqs from './Pages/Manage/ProductMaterial/AddEditMetarialAndFaqs'
import AddEditNews from './Pages/Manage/News/AddEditNews';
import EditSupplier from './Pages/Manage/Supplier/EditQuailityPolicy';
import AddSupplier from './Pages/Manage/Supplier/AddContact';
import ViewProductList from './Pages/Manage/Supplier/VIewProductLIst';
import Plan from './Pages/Manage/Plan';
import ProductMaterialSubProduct from './Pages/Manage/ProductMaterialSubProduct';
import AddEditProductMaterialSubProduct from './Pages/Manage/ProductMaterialSubProduct/AddEditProductMaterialSubProduct';
import MailApp from './Pages/Manage/MailApp';
import MaterialGrade from './Pages/Manage/MaterialGrade';
import AddEditMaterialGrade from './Pages/Manage/MaterialGrade/AddEditMaterialGrade';
import GradeModal from './Pages/Manage/GradeModal';
import AddProductMaterialGrade from './Pages/Manage/Grade/AddGrade';
import EditProductFnqs from './Pages/Manage/Products/EditProductFnqs/EditProductFnqs';
import EditProductGradeAndFnqs from './Pages/Manage/Grade/AddEditGradeAndFaqs';
import AllModules from './Pages/Manage/AllModules';
import Rfqs from './Pages/Manage/RAQs';
import HotProduct from './Pages/Manage/Supplier/AddHotProduct';
import AddEditSubproductAndFaqs from './Pages/Manage/SubProduct/AddEditSubproductAndFaqs';
import AddEditMaterialsAndFnqs from './Pages/Manage/Materials/AddEditMaterialsAndFaqs';
import AddEditProductMetarialSubproductAndFaqs from './Pages/Manage/ProductMaterialSubProduct/AddEditProductMetarialSubproductAndFaqs';
import AddEditMaterialGradeAndFaqs from './Pages/Manage/MaterialGrade/AddEditMaterialGradeAndFaqs';
import AddEditEmailBlock from './Pages/Manage/EmailBlock/AddEditEmailBlock';
import ListBlockEmail from './Pages/Manage/EmailBlock/ListBlockEmail';
import ListBlockIp from './Pages/Manage/IPBlock/ListBlockIp';
import AddEditIpBlock from './Pages/Manage/IPBlock/AddEditIpBlock';
import SendRfqEmail from './Pages/Manage/SendRfqEmail';
// // import AddEditSupplier from './Pages/Manage/Supplier/AddSupplier/SupplierAdd';
// import AddSupplier from './Pages/Manage/Supplier/AddSupplier';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const authUser = JSON.parse(localStorage.getItem('auth-token'));
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <>
            {/* <IdleTimerModal /> */}
            <Component {...props} />
          </>
        ) : (
          <React.Fragment>
            <Redirect
              to={{
                pathname: '/signin',
                state: { from: props.location },
              }}
            />
          </React.Fragment>
        )
      }
    />
  );
};

const Routes = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  //-----all use selectors

  const { error, isOpen } = useSelector(state => state.Error);
  const { success } = useSelector(state => state.Success);
  const successAction = useSelector(state => state.Success.action);
  const authUser = JSON.parse(localStorage.getItem('auth-token'));

  // ----------test time 20 secs
  // var hours = 0.005;
  //-------------clear local history after 27 mins and logout user
  // var hours = 0.48;
  // var now = new Date().getTime();
  // var setupTime = localStorage.getItem('setupTime');

  // if (setupTime && now - setupTime > hours * 60 * 60 * 1000) {
  //   dispatch(AuhMethods[CurrentAuthMethod].onSessionLogout());
  // }
  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/signin'} />;
  } else if (authUser && location.pathname === '/signin') {
    return <Redirect to={'/dashboard'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <Route path="/signin" component={Login} />
        {/* <Route path="/reset-password" component={ResetPasswordPage} /> */}
        {/* <Route path="/forgot-password" component={ForgotPasswordPage} /> */}
        {/* manage routes */}
        <RestrictedRoute path="/dashboard" component={Dashboard} />
        {/* <RestrictedRoute path="/manage-profile" component={EditProfile} /> */}
        <RestrictedRoute
          path="/manage-permissions/:id"
          component={EditPermissions}
        />
        {/* <RestrictedRoute path="/manage-admin-users" component={SamplePage} /> */}
        <RestrictedRoute path="/manage-admin-users" component={Users} />

        {/* <RestrictedRoute path="/manage-emails" component={EmailPanel} /> */}

        <RestrictedRoute path="/manage-products" component={Products} />
        <RestrictedRoute
          path="/add-manage-products"
          component={AddEditProduct}
        />
        <RestrictedRoute
          path="/edit-manage-products/:id"
          component={EditProductFnqs}
        />

        <RestrictedRoute
          path="/manage-productMaterial"
          component={ProductMaterial}
        />
        <RestrictedRoute
          path="/manage-edit-productMaterial/:id"
          component={AddEditMetarialAndFaqs}
        />
        <RestrictedRoute
          path="/manage-add-productMaterial"
          component={AddEditProductMaterial}
        />

        <RestrictedRoute
          path="/manage-edit-grade/:id"
          component={EditProductGradeAndFnqs}
        />

        <RestrictedRoute path="/manage-subproducts" component={SubProducts} />
        <RestrictedRoute
          path="/manage-add-subproduct/:id"
          component={AddEditSubproductAndFaqs}
        />
        <RestrictedRoute
          path="/manage-add-subproduct"
          component={AddEditSubProduct}
        />
        <RestrictedRoute path="/manage-grade" component={GradeModal} />
        <RestrictedRoute path="/manage-product-material-grade" component={Grade} />
        <RestrictedRoute path="/manage-add-product-material-grade" component={AddProductMaterialGrade} />
        <RestrictedRoute path="/manage-edit-grade" component={EditGrade} />
        <RestrictedRoute
          path="/manage-add-equivalent-grade"
          component={AddEditEqGrade}
        />
        <RestrictedRoute path="/manage-eqgrades" component={EqGrade} />
        <RestrictedRoute path="/manage-suppliers" component={Supplier} />
        <RestrictedRoute path="/manage-add-supplier" component={AddSupplier} />
        <RestrictedRoute
          path="/manage-edit-supplier/:id"
          component={EditSupplier}
        />
        <RestrictedRoute
          path="/manage-add-productsupplier/:id"
          component={AddProductToSupplier}
        />
        <RestrictedRoute
          path="/manage-hot-productsupplier/:id"
          component={HotProduct}
        />
        {/* <RestrictedRoute
          path="/manage-add-product/:id"
          component={EditProductFnqs}
        /> */}
        <RestrictedRoute
          path="/view-supplier-products/:id"
          component={ViewProductList}
        />
        <RestrictedRoute
          path="/manage-edit-productsupplier/:id"
          component={EditProductSupplier}
        />
        <RestrictedRoute path="/manage-email" component={MailApp} />
        {/* <RestrictedRoute path="/test-email" component={MailApp} />
        <RestrictedRoute path="/manage-emails" component={EmailPanel} /> */}
        <RestrictedRoute
          path="/manage-material-grade"
          component={MaterialGrade}
        />
        <RestrictedRoute
          path="/add-manage-material-grade"
          component={AddEditMaterialGrade}
        />
        <RestrictedRoute
          path="/edit-manage-material-grade/:id"
          component={AddEditMaterialGradeAndFaqs}
        />

        <RestrictedRoute path="/block-email" component={ListBlockEmail} />
        <RestrictedRoute path="/manage-block-email" component={AddEditEmailBlock} />
        <RestrictedRoute path="/manage-edit-block-email/:id" component={AddEditEmailBlock} />
        <RestrictedRoute path="/block-ip" component={ListBlockIp} />
        <RestrictedRoute path="/manage-block-ip" component={AddEditIpBlock} />
        <RestrictedRoute path="/manage-edit-block-ip/:id" component={AddEditIpBlock} />
        <RestrictedRoute path="/manage-news" component={News} />
        <RestrictedRoute path="/manage-edit-news/:Id" component={AddEditNews} />
        <RestrictedRoute path="/manage-add-news" component={AddEditNews} />
        <RestrictedRoute path="/manage-mapping" component={Mapping} />
        <RestrictedRoute
          path="/manage-add-mapping"
          component={AddEditMapping}
        />
        <RestrictedRoute
          path="/manage-edit-mapping/:id"
          component={AddEditMapping}
        />

        <RestrictedRoute path="/manage-materials" component={Materials} />
        <RestrictedRoute
          path="/add-manage-materials"
          component={AddEditMaterial}
        />
        {/* <RestrictedRoute
          path="/manage-edit-material/:id"
          component={AddEditMaterial}
        /> */}
        <RestrictedRoute
          path="/edit-manage-materials/:id"
          component={AddEditMaterialsAndFnqs}
        />
        <RestrictedRoute
          path="/manage-add-material-to-grade"
          component={AddGradeToMaterial}
        />
        <RestrictedRoute path="/manage-plan" component={Plan} />
        <RestrictedRoute
          path="/manage-material-sub-product-product"
          component={ProductMaterialSubProduct}
        />
        <RestrictedRoute
          path="/add-manage-material-sub-product-product"
          component={AddEditProductMaterialSubProduct}
        />
        <RestrictedRoute
          path="/edit-manage-material-sub-product-product/:id"
          component={AddEditProductMetarialSubproductAndFaqs}
        />
        <RestrictedRoute
          path="/manage-all-module"
          component={AllModules}
        />
        <RestrictedRoute
          path="/manage-rfqs"
          component={Rfqs}
        />
        <RestrictedRoute
          path="/manage-sendEmail-rfqs"
          component={SendRfqEmail}
        />
        {/* <RestrictedRoute path="/manage-customers" component={Customers} /> */}
        {/* <RestrictedRoute path="/manage-products" component={Vendors} /> */}
        {/* support routes */}
        <RestrictedRoute path="/support" component={SamplePage} />
        {authUser ? (
          <Route component={Error404} />
        ) : (
          <Redirect to={'/signin'} />
        )}
        <Route path="/signup" component={Register} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
