import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Chip, Divider, IconButton, Input, Paper } from '@material-ui/core';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { Typography } from '@mui/material';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import ClearIcon from '@material-ui/icons/Clear';
import { useHistory } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import EditIcon from '@mui/icons-material/Edit';
import { experimentalStyled as styled } from '@mui/material/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ClipLoader } from 'react-spinners';

import {
  ImageAdd,
  ImageUpload,
  deleteSupplierGalleryImage,
  updateSupplier,
} from 'redux/actions/Manage/Suppliers/Suppliers';
import { Category, Image } from '@material-ui/icons';
import CmtCardMedia from '@coremat/CmtCard/CmtCardMedia';
import { useDispatch, useSelector } from 'react-redux';
import Axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  imageRoot: {
    height: '190px',
    width: '300px',
    backgroundSize: 'contain',
    objectFit: 'contain',
    // boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px;',
  },
  textarea: {
    width: '100%',
    height: '100%',
    padding: '8px',
    fontSize: ' 0.875rem',
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontWeight: 400,
    borderRadius: '12px 12px 0 12px',
  },
  //   TextareaAutosize:{
  //      width: "100%",
  //     fontFamily: "IBM Plex Sans, sans-serif",
  //     fontSize:" 0.875rem",
  //     // font-weight: 400;
  //     // line-height: 1.5;
  //     // padding: 12px;
  //     // border-radius: 12px 12px 0 12px;
  //   },
  box: {
    display: 'flex',
    justifyContent: 'center',
  },
  headerName: {
    width: '100%',
    borderBottom: '1px solid lightgray',
  },
  flexContainer: {
    display: 'flex',
  },
  flexGrow: {
    flexGrow: 1,
  },
  uploadButton: {
    width: '100px',
    marginTop: '16px',
  },

}));

const EditFactoryImages = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { supplier } = useSelector(state => state.SetSupplierDetail);
  console.log(supplier, 'gallery');
  console.log(supplier.productImages, 'images');

  const [imageUpdate, setImageUpdate] = useState({
    image: '',
    name: '',
  });
  const [newName, setNewName] = useState('');
  const [loading,setLoading] = useState(false);
  console.log(imageUpdate.image, 'image');
  console.log(imageUpdate.name, 'name');

  const [productImages, setProductImages] = useState(supplier);
  const [showEdit, setShowEdit] = useState(false);
  console.log(productImages.productImages);
  const [open, setOpen] = React.useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [num, setNum] = useState('');
  const handleClickOpen = (item) => {
    setOpen(true);
    setNewCategory(item.category)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const EditImage = ({ index, name, category }) => {
    console.log(index, name, category);
    setNewCategory(category);
    setNewName(name);
    setShowEdit(true);
    setNum(index);
  };

  const handleDelete = ({ index, category }) => {
    if (category === 'productImages') {
      dispatch(deleteSupplierGalleryImage(supplier.userId, index, category))
      .then(()=>{
        window.location.reload()
      });
      console.log(index, supplier.userId);
      // history.push(`/manage-suppliers`);
    }
    if (category === 'factoryImages') {
      dispatch(deleteSupplierGalleryImage(supplier.userId, index, category))
      .then(()=>{
        window.location.reload()
      });
      console.log(index, supplier.userId);
      // history.push(`/manage-suppliers`);
    }
  };
  console.log(newCategory);
  const handleAdd = ({ category }) => {
    console.log(category);
    console.log(newCategory);
    if (newCategory === 'productImages') {
      setLoading(true);
      const data = new FormData();
      data.append('image', imageUpdate.image);
      data.append('userId', supplier.userId);
      data.append('category', newCategory);
      data.append('name', imageUpdate.name);
      dispatch(ImageAdd(data))
      .then(()=>{
        setLoading(false)
        window.location.reload()
      })
      // history.push(`/manage-suppliers`);
    }
    if (newCategory === 'factoryImages') {
      setLoading(true);
      const data = new FormData();
      data.append('image', imageUpdate.image);
      data.append('userId', supplier.userId);
      data.append('category', newCategory);
      data.append('name', imageUpdate.name);
      dispatch(ImageAdd(data))
      .then(()=>{
        setLoading(false)
        window.location.reload()
      })
      // history.push(`/manage-suppliers`);
    }
  };

  const updateImage = ({ index, category }) => {
    console.log(category);
    if (category === 'productImages') {
      const data = new FormData();
      data.append('index', index);
      data.append('image', imageUpdate.image);
      data.append('userId', supplier.userId);
      data.append('category', category);
      data.append('name', newName);
      dispatch(ImageUpload(data))
      .then(()=>{
        window.location.reload()
      })
      // history.push(`/manage-suppliers`);
    }
    if (category === 'factoryImages') {
      const data = new FormData();
      data.append('index', index);
      data.append('image', imageUpdate.image);
      data.append('userId', supplier.userId);
      data.append('category', category);
      data.append('name', newName);
      dispatch(ImageUpload(data))
      .then(()=>{
        window.location.reload()
      })
      // history.push(`/manage-suppliers`);
    }
  };

  return (
    <>
      <PageContainer>
        <div>
          <Paper className={classes.paper}></Paper>
          <CmtCard>
            <CmtCardContent>
              <Box mt={2} mb={2} sx={{ display: 'flex' }}>
                <Typography sx={{ marginRight: 4 }} variant="h6">
                  Factory
                </Typography>
                <Button
                  onClick={() => handleClickOpen({ category: 'factoryImages' })}
                  mt={2}
                  variant="contained"
                  color="primary"
                >
                  Add Image
                </Button>
              </Box>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

              >
                <Box mt={2} sx={{ display: "flex" }}>
                  <DialogTitle id="alert-dialog-title">Add Image</DialogTitle>

                  <Button component="label">
                    <AddCircleIcon />
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      onChange={evt =>
                        setImageUpdate({
                          ...imageUpdate,
                          image: evt.target.files[0],
                        })
                      }
                    />
                  </Button>
                </Box>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">

                    <Box mt={2}>
                      <Input
                        variant="outlined"
                        placeholder="Image Name"
                        onChange={evt =>
                          setImageUpdate({
                            ...imageUpdate,
                            name: evt.target.value,
                          })
                        }
                        value={imageUpdate?.name}
                      />
                    </Box>
                  </DialogContentText>
                </DialogContent>

                <DialogActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  {loading ? <h1 style={{margin:"0 10px"}}><ClipLoader color="#13438D" size={25} /></h1> : <Button
                    disabled={!imageUpdate?.name?.trim()?.length > 0 ? true : false}
                    onClick={() => handleAdd({ category: 'factoryImages' })}
                    variant="contained"
                    color="primary"
                    autoFocus
                  >
                    Add
                  </Button>}
                </DialogActions>
              </Dialog>

              <Box>
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  {productImages.factoryImages &&
                    productImages.factoryImages.map((item, index) => (
                      <Grid item xs={3} sm={3} md={2} key={index}>
                        {/* {!showEdit ? (
                          <Box mt={4}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <div onClick={() => EditImage({index:index,name:item.name,category:'factoryImages'})}>
                                <EditIcon size={2} />
                              </div>
                              <div>
                                <ClearIcon
                                  onClick={() =>
                                    handleDelete({
                                      category:'factoryImages',
                                      index,
                                    })
                                  }
                                  color="error"
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <img
                                style={{ width: '140px', height: '140px' }}
                                src={item.image}
                                alt="image"
                              />
                            </div>
                            <div style={{ textAlign: 'center' }}>
                              <h4>{item.name}</h4>
                            </div>
                          </Box>
                        ) : (
                          <Box style={{ width: '200px', padding: '4px' }}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <div>
                                <ClearIcon color="error" />
                              </div>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <img
                                style={{ width: '140px', height: '140px' }}
                                src={item.image}
                                alt="image"
                              />
                              <Button
                                style={{
                                  position: 'absolute',
                                  width: '140px',
                                  height: '140px',
                                }}
                                variant="outlined"
                                component="label"
                              >
                                <input
                                  type="file"
                                  style={{ display: 'none' }}
                                  onChange={evt =>
                                    setImageUpdate({
                                      ...imageUpdate,
                                      image: evt.target.files[0],
                                    })
                                  }
                                />
                              </Button>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <div style={{ width: '140px', marginTop: '8px' }}>
                                <AppTextInput
                                  name="Name"
                                  type="text"
                                  onChange={e => setNewName(e.target.value)}
                                  value={num === index && newCategory=== 'factoryImages' ? newName : item.name}
                                  variant="outlined"
                                  label="Name"
                                />
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '8px',
                                  }}
                                >
                                  <Button
                                    onClick={() => updateImage({index:index,category:'factoryImages'})}
                                    variant="contained"
                                    color="primary"
                                    sx={{ textAlign: 'center' }}
                                  >
                                    Update
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Box>
                        )} */}
                        {num !== index ? (
                          <Box mt={4}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <div
                                style={{ cursor: "pointer" }}
                                className={classes.btn}
                                onClick={() =>
                                  EditImage({
                                    index: index,
                                    name: item?.name,
                                    category: 'factoryImages',
                                  })
                                }
                              >
                                <EditIcon size={2} />
                              </div>
                              <div className="btn" style={{ cursor: "pointer" }}>
                                <ClearIcon
                                  onClick={() =>
                                    handleDelete({
                                      category: 'factoryImages',
                                      index,
                                    })
                                  }
                                  color="error"
                                />
                              </div>
                            </div>


                            <div
                              // onClick={() => downloadFile(item.image)}
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <img
                                style={{ width: '140px', height: '140px' }}
                                src={item.image}
                                alt="image"
                              />
                            </div>

                            <div style={{ textAlign: 'center' }}>
                              <h4>{item?.name}</h4>
                            </div>
                          </Box>
                        ) : (
                          <Box >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Button onClick={() => setNum("")}>
                                <ClearIcon color="error" />
                              </Button>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <img
                                style={{ width: '140px', height: '140px' }}
                                src={item.image}
                                alt="image"
                              />
                              <Button
                                style={{
                                  position: 'absolute',
                                  width: '140px',
                                  height: '140px',
                                }}
                                variant="outlined"
                                component="label"
                              >
                                Upload
                                <input
                                  type="file"
                                  hidden
                                  onChange={evt =>
                                    setImageUpdate({
                                      ...imageUpdate,
                                      image: evt.target.files[0],
                                    })
                                  }
                                />
                              </Button>
                            </div>
                            <div style={{ textAlign: 'center', margin: "10px 0" }}>
                              <AppTextInput
                                variant="outlined"
                                placeholder="Image Name"
                                onChange={evt => setNewName(evt.target.value)}
                                value={newName}
                                type="text"
                              />
                            </div>
                            <div style={{ textAlign: 'center' }}>
                              <Button
                                disabled={!newName?.trim()?.length > 0 ? true : false}
                                onClick={() =>
                                  updateImage({
                                    index: num,
                                    category: 'factoryImages',
                                  })
                                }
                                variant="contained"
                                color="primary"
                              >
                                Update
                              </Button>
                            </div>
                          </Box>
                        )}
                      </Grid>
                    ))}
                </Grid>
              </Box>
            </CmtCardContent>
          </CmtCard>
        </div>
      </PageContainer>
    </>
  );
};

export default EditFactoryImages;
