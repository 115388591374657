import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { isValidEmail, isValidPassword, isValidPhone } from '@jumbo/utils/commonHelper';
import { createProduct, updateProduct } from 'redux/actions/Manage/Products/Products';
import WysiwygEditorExample from 'components/wysiwygEditor';
import { Tooltip } from 'chart.js';
import { Chip, Divider, Paper } from '@material-ui/core';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { Add, Description, Details, Remove } from '@material-ui/icons';
import { PRODUCT_CREATE_SUCCESS } from 'redux/actions';
import { Typography } from '@mui/material';
import Dropzone from 'react-dropzone';
// import { DropzoneArea } from 'material-ui-dropzone';
import { uploadFile } from 'redux/actions/FileUpload/FileUpload';
import { createGrade, updateGrade } from 'redux/actions/Manage/ProductMaterialGrades';
import { createEqGrades, getGradesListForEqGrade, updateEqGrades } from 'redux/actions/Manage/EqGrades/EqGrade';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import CustomEditor from 'components/CustomEditor';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  dropZone: {
    minHeight: '100px',
    minWidth: '100px',
    // fullWidth: 'true',
  },
  previewContainer: {
    container: 'true',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  preview: {
    xs: '12',
  },
  errorCk: {
    color: '#f44336',
    marginRight: '14px',
    marginTop: '4px',
    fontSize:'0.75rem',
  },
  avatar :{
    width: "140px",
    height: "140px",
    backgroundSize:"contain",
    
  },
  textarea:{
    width: '100%',
    height: '100%',
    padding:"8px",
    fontSize:"16px",
    fontFamily:'inherit',
    fontWeight:400,
    borderColor:"lightgray",
    borderRadius:"4px",
    '&:focus': {
      borderColor:"#1C61CA",
      borderWidth:"2px",
      outline:"none",
      borderRadius:4
    },
  },
  
label:{
  fontSize:'16px',
  fontFamily:"inherit",
  fontWeight:400,
  
  marginTop:"-22px"
},
grid:{
    marginBottom:"-15px",
    marginTop:"-15px"
},
textarea_red:{
  width: '100%',
  height: '100%',
  padding:"8px",
  fontSize:"16px",
  fontFamily:'inherit',
  fontWeight:400,
  borderColor:"#f44336",
  borderRadius:"4px",
  '&:focus': {
    borderColor:"#1C61CA",
    borderWidth:"2px",
    outline:"none",
    borderRadius:4
  },
},
}));

const AddEditEqGrade = ({ history, open, onCloseDialog, setDataUpdated }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  //------------all use selectors
  const { user } = useSelector(({ auth }) => auth.authUser);
  const { eqgrade } = useSelector((state) => state.SetEqGradeDetail);
  const { gradeListForEqGrade } = useSelector((state) => state.SetGradeListForEqGrade);
  const successAction = useSelector(state => state.Success.action)
  // console.log(eqgrade);
  //-------------all use states
  const [keywordsArr, setkeywordsArr] = useState([])
  const [descriptionHTML, setDescriptionHTML] = useState("")
  const [file, setFile] = useState([]);
  const [detail, setDetail] = useState(null);
  const [productDetail, setproductDetail] = useState({
    code: '',
    description: '',
    h1: '',
    h2: '',
    // idOfProductCreator: '',
    keywords: '',
    meta: '',
    name: '',
    // nameOfProductCreator: '',
    title: '',
    url: '',
    image_path: '',
    preview: '',
    productId:'',
    materialId:'',
    subproductId:'',
    equivalentId:'',
    gradeId:''
  });
  const [errorText, setErrorText] = React.useState({});

  //-------all handle functions
  const validate = () => {
    const errors = {};
    let isError = false;
    const { code, description, h1, h2, meta, name, title, url, image_path,preview } = productDetail
    let tempDescription = descriptionHTML;
    let noSpaceData = tempDescription.replace(/\&nbsp;/g, '');
    const noTagData = noSpaceData.replace(/<[^>]*>/g, '');
    if(!eqgrade && !detail){
      errors.grade = 'Please Select Grade.';
      isError = true;
    }
    if (!name.trim()) {
      errors.name = 'Please enter Equivalent Grade name.';
      isError = true;
    } 
    if (noTagData.length < 2) {
      errors.descriptionHTML = 'Please enter description.';
      isError = true;
    }
    if (!code) {
      errors.code = 'Please enter code.';
      isError = true;
    }
    if (!meta) {
      errors.meta = 'Please enter meta.';
      isError = true;
    }
    if (!title) {
      errors.title = 'Please enter title.';
      isError = true;
    }
    
    if (!url) {
      errors.url = 'Please enter url.';
      isError = true;
    }
    if (!keywordsArr.length) {
      errors.keywords = 'Please enter keyword.';
      isError = true;
    }
    if (!file.length && !preview) {
      errors.file = 'Please upload image.';
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };
  const validateKeyword = () => {
    let error = '';
    let isError = false;
    // const { keywords } = productDetail
    if (!keywords) {
      error = 'Please enter keywords.';
      isError = true;
    } 
    setErrorText({...errorText,keywords:error});
    return {
      error,
      isError,
    };
  };
  const onKeywordsAdd = () => {
    let validateData = validateKeyword();
    if (!validateData.isError)
      setkeywordsArr(keywordsArr => [...keywordsArr, keywords])
      setproductDetail({ ...productDetail, keywords: '' });
  }
  const onKeywordsDelete = (e, value) => {
    setkeywordsArr(keywordsArr => keywordsArr.filter(e => e != value))
  }
  const onLabelChange = evt => {
    const { name, value } = evt.target;
    const regex = /[\W_]+|\s+/g;
    if(eqgrade){
      let gradeName=eqgrade.name;
      let gradeDisplayName = eqgrade.displayName;
      var arr = gradeDisplayName.replace(gradeName,"").replace(/\s/g, "-").toLowerCase().split('--');
      if (name === 'name') {
        const nameValue = value ? arr[0]+'-'+ value.replace(regex, '-').toLowerCase()+'-'+ arr[1] : '';
        let newNameValue= nameValue.replace('--', '-');
        productDetail.code = newNameValue
        productDetail.url = newNameValue
      }
    }else{
      if (name === 'name') {
        // if(!detail){
        //   setErrorText({...errorText,name:'Please Select Grade for code and url.'})
        // }else{
          let gradeName=detail.name;
          let gradeDisplayName = detail.displayName;
          var arr = gradeDisplayName.replace(gradeName,"").replace(/\s/g, "-").toLowerCase().split('--');
          const nameValue = value ? arr[0]+'-'+ value.replace(regex, '-').toLowerCase()+'-'+ arr[1] : '';
          let newNameValue= nameValue.replace('--', '-');
          productDetail.code = newNameValue
          productDetail.url = newNameValue
        // }
      }
    }
    setproductDetail({ ...productDetail, [evt.target.name]: evt.target.value });
  };
  const onLabelChange1=(evt)=>{
    setDetail(evt.target.value);
    // console.log('onLabelChange',evt.target.value)
  }
  const onSubmitClick = () => {
    let validateData = validate();
    if (!validateData.isError) {
      onproductSave();
    }
  };
  const onproductSave = () => {
    let keywordStr = ""
    keywordsArr.map(e => (keywordStr += "," + e))
    // const productDetail = {
    //   code,
    //   description: descriptionHTML,
    //   h1,
    //   h2,
    //   // idOfProductCreator: user?.userId,
    //   keywords: keywordStr,
    //   meta,
    //   name,
    //   // nameOfProductCreator: user?.name,
    //   title,
    //   url,
    //   image_path,
    //   productId,
    //   materialId,
    //   subproductId,
    //   equivalentId,
    //   gradeId
    // };
    // console.log(productId,materialId)

    let formData = new FormData();

    formData.append('code', code);
    formData.append('description', descriptionHTML);
    // formData.append('h1', h1);
    // formData.append('h2', h2);
    formData.append('idOfProductCreator', user?.userId);
    formData.append('keywords', keywordStr);
    formData.append('meta', meta);
    formData.append('name', name);
    formData.append('nameOfProductCreator', user?.name);
    formData.append('title', title);
    formData.append('url', url);
    formData.append('logo', file[0]);
    formData.append('productId', productId);
    formData.append('materialId', materialId);
    formData.append('equivalentId', equivalentId);
    formData.append('subproductId', subproductId)
    eqgrade && formData.append('gradeId', gradeId);
    if (eqgrade) {
      let newProduct = { ...eqgrade, ...productDetail }
      const { _id, materials, ...data } = newProduct;
      dispatch(
        updateEqGrades(formData, (data) => {
          if (data)
            history.push('/manage-eqgrades')
        }),
      );
    } else {
      formData.set('productId',detail.productId);
    formData.set('materialId', detail.materialId);
    formData.set('equivalentId', detail.gradeId);
    formData.set('subproductId', detail.subProductId);
    // {
    //   ...productDetail,
    //   productId: detail.productId,
    //   materialId: detail.materialId,
    //   equivalentId:detail.gradeId,
    //   subProductId:detail.subProductId,
    // }
      dispatch(
        createEqGrades(formData,
          data => {
            if (data) history.push('/manage-eqgrades');
          },
        ),
      );
    }
  };
  const handleFileUpload = (files) => {
    // console.log(files, "file")
    let formData = new FormData();
    formData.append('fileName', files[0]?.name);
    formData.append('fileCategory', 'logo');
    formData.append('uploadedFile', files[0]);
    dispatch(uploadFile(formData))
    // const payload = {
    //   fileName: files?.name,
    //   fileCategory: 'logo'
    // }
  }

  //------all useEffects

  useEffect(() => {
    if (eqgrade) {
      const { code, description, h1, h2, idOfProductCreator, keywords, meta, name, nameOfProductCreator, title, url, image_path,productId,
        materialId,subproductId,logo,
        equivalentId,gradeId } = eqgrade
      setproductDetail({
        code, description, h1, h2, idOfProductCreator, keywords: "", meta, name, nameOfProductCreator, title, url, image_path,productId,
        materialId,subproductId,equivalentId,gradeId,preview:logo
      });
        const x = keywords.split(',');
        let data=x.filter((item,index) => item !== '')
        setkeywordsArr(data)
        setDescriptionHTML(description);
    }
  }, [eqgrade]);
  useEffect(() => {
    if(!eqgrade){
        dispatch(getGradesListForEqGrade())
    }
  }, [])
  // console.log(gradeListForEqGrade)
  // console.log(eqgrade)

  const { code, description, h1, h2, keywords, meta, name, title, url, image_pathm, productId,preview,
    materialId,subproductId,equivalentId,image_path,gradeId} = productDetail
  return (
    // <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
    //   <DialogTitle className={classes.dialogTitleRoot}>{product ? 'Edit product Details' : 'Create New product'}</DialogTitle>
    //   <DialogContent dividers>
    <PageContainer>
      <div>
        <Paper className={classes.paper}>
        </Paper>
        <CmtCard>
          <CmtCardContent>
            <Typography variant="h6">{eqgrade ? "Edit Equivalent Grade" :"Add Equivalent Grade"}</Typography>
            <Divider></Divider>
            <Box mt={3} display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
              <GridContainer>
               
                {!eqgrade &&  gradeListForEqGrade &&
                 <Grid item xs={12} sm={6}>
                  <AppSelectBox 
                  required
                  name='name'
                  variant="outlined"
                  label="Select Grade"
                  // value={productName}
                  onChange={onLabelChange1}
                  // helperText={errorText?.name}
                  fullWidth
                  data={gradeListForEqGrade && gradeListForEqGrade}
                  valueKey=""
                  labelKey="displayName"
                  // value={status}
                  // onChange={onLabelChange}
                  helperText={errorText?.grade}
                  />
                </Grid>
                  }
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    fullWidth
                    required
                    disabled ={!eqgrade && !detail ? true : false}
                    name='name'
                    variant="outlined"
                    label="Equivalent Grade Name"
                    value={name}
                    onChange={onLabelChange}
                    helperText={errorText?.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                  required
                    fullWidth
                    name='code'
                    variant="outlined"
                    label="Code"
                    value={code}
                    onChange={onLabelChange}
                    helperText={errorText?.code}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <AppTextInput
                    fullWidth
                    name='h1'
                    variant="outlined"
                    label="H1"
                    value={h1}
                    onChange={onLabelChange}
                    helperText={errorText?.h1}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    fullWidth
                    name='h2'
                    variant="outlined"
                    label="H2"
                    value={h2}
                    onChange={onLabelChange}
                    helperText={errorText?.h2}
                  />
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    fullWidth
                    required
                    name='title'
                    variant="outlined"
                    label="Meta Title"
                    value={title}
                    onChange={onLabelChange}
                    helperText={errorText?.title}
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    required
                    fullWidth
                    name='url'
                    variant="outlined"
                    label="URL"
                    value={url}
                    onChange={onLabelChange}
                    helperText={errorText?.url}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/* <Typography component="h3" variant="p" className="mb-2">
                    Upload Logo
                  </Typography> */}
                  {/* <DropzoneArea
                    initialFiles={[file[0]?.url]}
                    required
                    dropzoneClass={classes.dropZone}
                    previewGridClasses={{
                      container: classes.previewContainer,
                      item: classes.preview,
                    }}
                    filesLimit={1}
                    acceptedFiles={['image/*']}
                    dropzoneText={'Upload Logo | Drag and drop an image here or click'}
                    // onChange={files => setFile(files)}
                    onChange={files => handleFileUpload(files)}
                    showAlerts={false}
                    onDelete={() => setFile([])}
                  />
                  <p className={classes.errorCk}>{errorText.file ? errorText.file : ''}</p> */}
                  {/* <AppTextInput
                    fullWidth
                    name='image_path'
                    variant="outlined"
                    label="Upload Image"
                    value={image_path}
                    onChange={onLabelChange}
                    helperText={errorText?.image_path}
                  /> */}
                  <AppTextInput
                  required
                    fullWidth
                    name="file"
                    type="file"
                    variant="outlined"
                    onChange={evt => {
                      setproductDetail({ ...productDetail, preview: '' });
                      setFile(evt.target.files);
                    }}
                    helperText={errorText?.file}
                  />
                </Grid>
              
              <Grid style={{display:"flex",flexDirection:"column"}} item xs={12} sm={6}>
              <label className={classes.label}>Meta Description*</label>
              <textarea
              className={errorText.meta?classes.textarea_red:classes.textarea}
                    
                  rows={3}
                   required
                    fullWidth
                    name='meta'
                    variant="outlined"
                    // placeholder="Meta Description"
                    value={meta}
                    onChange={onLabelChange}
                    helperText={errorText?.meta}
                  />
                  {errorText.meta?<p style={{color:"#f44336",fontSize:"12px"}}>Please enter Description</p>:""}
              </Grid>
                
                <Grid item xs={12} sm={6}>
                
                {preview && <img src={preview} className={classes?.avatar} />}
                </Grid>
                <Grid item xs={12} sm={11}>
                  <AppTextInput
                    fullWidth
                    required
                    name='keywords'
                    variant="outlined"
                    label="Keywords"
                    value={keywords}
                    onChange={onLabelChange}
                    helperText={errorText?.keywords}
                  />
                </Grid>
                <Grid item sm={1}>
                  <Button variant="contained" color="secondary" onClick={onKeywordsAdd}>
                    <Add />
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box>
                    {keywordsArr && keywordsArr.length  > 0 && keywordsArr.map((e, key) => {
                      return e.length !== 0 &&
                        < Chip
                          size="medium"
                          key={e}
                          label={e}
                          clickable
                          onDelete={evt => onKeywordsDelete(evt, e)}
                          color="secondary"
                          variant="outlined"
                        />
                    }
                    )
                    }
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                <span
                  className={errorText?.descriptionHTML ? classes?.editorLabelError : ''}
                >
                  Description *
                </span>
                  {/* <WysiwygEditorExample defaultDescription={description} setDescriptionHTML={setDescriptionHTML} descriptionHTML={description} /> */}
                  <CustomEditor
                  editorData={descriptionHTML || ''}
                  setEditorData={setDescriptionHTML}
                />
                  <span className={classes?.errorCk}>
                  {errorText?.descriptionHTML}
                </span>
                </Grid>
              </GridContainer>
            </Box>
            <Box display="flex" justifyContent="flex-end" mb={4}>
              <Button onClick={() => history.push('/manage-eqgrades')}>Cancel</Button>
              <Box ml={2}>
                <Button variant="contained" color="primary" onClick={onSubmitClick}>
                  {eqgrade ? "Update" : "Save"}
                </Button>
              </Box>
            </Box>
          </CmtCardContent>
        </CmtCard>
      </div>
    </PageContainer>
    //   </DialogContent>
    // </Dialog >
  );
};

export default AddEditEqGrade;

AddEditEqGrade.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
