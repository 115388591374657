import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  FormControl,
  MenuItem,
  Select,
  ListItemText,
  FormHelperText,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formControlRoot: {
    '& .MuiOutlinedInput-input': {
      paddingTop: 10.5,
      paddingBottom: 10.5,
      backgroundColor: 'transparent',
      borderRadius: 4,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[400],
    },
    
  },
}));

const AppGroupSelect = ({
  data,
  variant,
  size,
  fullWidth,
  required,
  name,
  value,
  onChange,
  error,
  helperText,
  id,
  displayEmpty,
  renderRow,
  placeholder,
  parentLabel,
  labelKey,
  valueKey,
  backgroundColor,
  childArray,
  ...rest
}) => {
  const classes = useStyles();

  const [limit, setLimit] = React.useState(50);

  const loadMoreItems = event => {
    if (
      event.target.scrollTop ===
      event.target.scrollHeight - event.target.offsetHeight
    ) {
      //user is at the end of the list so load more items
      setLimit(limit + 15);
    }
  };

  return (
    <FormControl
      variant={variant}
      size={size}
      fullWidth={fullWidth}
      required={required}
      className={clsx(classes.formControlRoot, 'form-control')}
    >
      <Select
        {...rest}
        name={name}
        labelId={'label-' + id || name}
        id={id || name}
        value={value}
        onScroll={loadMoreItems}
        onChange={onChange}
        error={error || helperText !== ''}
        displayEmpty={displayEmpty}
        defaultValue=""
        native
      >
        {placeholder && displayEmpty && (
          <MenuItem value="">{placeholder}</MenuItem>
        )}

        <option aria-label="None" value="">
          Select Email
        </option>
        {data?.length &&
          data.slice(0, limit).map((item, index) => (
            <optgroup key={index} label={item[parentLabel]}>
              {item[childArray].map((valueData, i) => (
                <option key={i} value={valueData[valueKey]} style={valueData?.isHotProduct ? {color:"#1C61CA"} : {}}>
                  {labelKey
                    ? valueData[labelKey]
                    : `${valueData?.email}   ${valueData?.emailPlan?.currentEmailSent}`}
                </option>
              ))}
            </optgroup>
          ))}
      </Select>
      {helperText && (
        <FormHelperText error={error || helperText !== ''}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

AppGroupSelect.prototype = {
  data: PropTypes.array.isRequired,
  renderRow: PropTypes.node,
  placeholder: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

AppGroupSelect.defaultProps = {
  data: [],
  fullWidth: true,
  displayEmpty: true,
  required: false,
  size: 'small',
  error: false,
  helperText: '',
  valueKey: 'id',
  labelKey: 'title',
  backgroundColor: 'transparent',
};

export default AppGroupSelect;
