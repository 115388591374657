import React, { useContext, useState, useEffect } from 'react';

import clsx from 'clsx';

import { List } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import NavMenuItem from './NavMenuItem';
import NavCollapse from './NavCollapse';
import SidebarThemeContext from '../../CmtLayouts/SidebarThemeContext/SidebarThemeContext';

const useStyles = makeStyles(theme => ({
  navHeader: {
    position: 'relative',
    padding: '24px 16px 20px 16px',
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 10,
    letterSpacing: 1.5,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      display: 'none',
    },
  },
  navSection: {
    position: 'relative',
    fontWeight: theme.typography.fontWeightRegular,
    '&:not(:first-child) .Cmt-navHeader': {
      borderTop: props => `solid 1px ${props.sidebarTheme.borderColor}`,
      marginTop: 10,
    },
    '&:not(:last-child)': {
      '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
        borderBottom: props => `solid 1px ${props.sidebarTheme.borderColor}`,
        paddingTop: 10,
        paddingBottom: 10,
      },
    },
  },
}));

const NavSection = props => {
  const { name, children = [] } = props;
  // const permissions = JSON.parse(localStorage.getItem('permissions'));
  const permissions = localStorage.getItem('permissions') === null ? [] : JSON.parse(localStorage.getItem('permissions'));
  const isExpandable = children && children.length > 0;
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useStyles({ sidebarTheme });

  const list = [];
  let entries = Object.entries(permissions);
  entries && entries.length && entries.map(([key, val]) => {
    if (val) {
      list.push(key);
    }
  });

  const MenuCollapse = (
    <List
      component="div"
      className={clsx(name ? classes.navHeader : '', 'Cmt-navHeader')}
    >
      {name}
    </List>
  );

  const renderMenu = (item, index) => {
    if (item?.showAll) {
      switch (item.type) {
        case 'section':
          return <NavSection {...item} key={index} />;
        case 'collapse':
          return <NavCollapse {...item} key={index} />;
        default:
          return <NavMenuItem {...item} key={index} />;
      }
    } else if (list.includes(item?.permission)) {
      switch (item.type) {
        case 'section':
          return <NavSection {...item} key={index} />;
        case 'collapse':
          return <NavCollapse {...item} key={index} />;
        default:
          return <NavMenuItem {...item} key={index} />;
      }
    }
  };

  const MenuItemChildren = isExpandable ? (
    <List component="div" disablePadding>
      {children.map((item, index) => renderMenu(item, index))}
    </List>
  ) : null;

  return (
    <div className={classes.navSection}>
      {MenuCollapse}
      {MenuItemChildren}
    </div>
  );

  // const MenuCollapse = (
  //   <List component="div" className={clsx(classes.navHeader, 'Cmt-navHeader')}>
  //     {name}
  //   </List>
  // );
  // const renderMenu = (item, index) => {
  //   if (item.showAll) {
  //     switch (item.type) {
  //       case 'section':
  //         return <NavSection {...item} key={index} />;
  //       case 'collapse':
  //         return <NavCollapse {...item} key={index} />;
  //       default:
  //         return <NavMenuItem {...item} key={index} />;
  //     }
  //   }
  // };

  // const MenuItemChildren = isExpandable ? (
  //   <List component="div" disablePadding>
  //     {children.map((item, index) => renderMenu(item, index))}
  //   </List>
  // ) : null;

  // //default jumbo func
  // // const MenuItemChildren = isExpandable ? (
  // //   <List component="div" disablePadding>
  // //     {children.map((item, index) => {
  // //       switch (item.type) {
  // //         case 'section':
  // //           return <NavSection {...item} key={index} />;
  // //         case 'collapse':
  // //           return <NavCollapse {...item} key={index} />;
  // //         default:
  // //           return <NavMenuItem {...item} key={index} />;
  // //       }
  // //     })}
  // //   </List>
  // // ) : null;

  // return (
  //   <div className={classes.navSection}>
  //     {MenuCollapse}
  //     {MenuItemChildren}
  //   </div>
  // );
};

export default NavSection;
