import { NEWS_CREATE_SUCCESS, NEWS_DELETE_SUCCESS, NEWS_LIST_SUCCESS, NEWS_UPDATE_SUCCESS, SET_NEWS_DETAILS } from "redux/actions/Manage/News/Constant";

;

const INIT_STATE =
{
    news: [],
    singleNews: null,
    total: 0,
    perPage: 0
    // permissions: null,
}
const INIT_STATE_DETAIL =
{
    singleNews: null,
}

export const NewsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case NEWS_CREATE_SUCCESS: {
            return {
                ...state,
                singleNews: action.data,
                action: action.type,
                message: action.message,
                news: [action.data, ...state.news]
            };
        }
        case NEWS_LIST_SUCCESS: {
            return {
                ...state,
                news: action.data,
                total: action.total,
                action: action.type,
                message: action.message,
                perPage: action.perPage,
            };
        }
        //products: state.products.map(product => (product.id === action.payload.id ? action.payload : product))
        case NEWS_UPDATE_SUCCESS: {
            return {
                ...state,
                singleNews: action.data,
                action: action.type,
                message: action.message,
                news: state.news.map(product => (product._id === action.data._id ? action.data : product))
            };
        }
        case NEWS_DELETE_SUCCESS: {
            return {
                ...state,
                singleNews: action.data,
                action: action.type,
                message: action.message,
                news: state.news.filter(product => product._id !== action.data._id),
            };
        }
        // case DELETE_BULK_NEWS_SUCCESS: {
        //     return {
        //         ...state,
        //         ...state,
        //         action: action.type,
        //         message: action.message,
        //         news: state.news.filter(product => !action.data.includes(product.id)),
        //     };
        // }
        default:
            return state;
    }
};
export const SetNewsDetailReducer = (state = INIT_STATE_DETAIL, action) => {
    switch (action.type) {
        case SET_NEWS_DETAILS:
            return {
                ...state,
                singleNews: action.data,
                action: action.type,
            };
        default:
            return state;
    }
};