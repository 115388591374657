import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';

import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import WysiwygEditorExample from 'components/wysiwygEditor';
import { Add } from '@material-ui/icons';
import { getAllProducts, getProductsDropdown } from 'redux/actions/Manage/Products/Products';
import { getAllSubProducts } from 'redux/actions/Manage/SubProduct/SubProduct';
import { getMaterialList } from 'redux/actions/Manage/MaterialGrade';
import {
  crateProductMaterialSubProduct,
  getProductMaterialSubProductListById,
  updateProductMaterialSubProduct,
} from 'redux/actions/Manage/ProductMaterialSubProduct';
import CustomEditor from 'components/CustomEditor';

const useStyles = makeStyles(() => ({
  errorCk: {
    color: '#f44336',
    marginRight: '14px',
    marginTop: '4px',
    fontSize: '0.75rem',
  },
  avatar: {
    borderRadius: '0',
    width: 140,
    height: 140,
    objectFit: 'cover',
    objectPosition: 'center',
    padding: 5,
    marginTop: '14px',
  },
  typoMarginBottom: {
    marginBottom: '10px',
  },
  editorLabelError: {
    color: '#f44336',
    fontSize: '16px',
    fontWeight: 500,
  },
  textarea: {
    width: '100%',
    // height: '100%',
    padding: '8px',
    fontSize: '16px',
    fontFamily: 'inherit',
    fontWeight: 300,
    borderColor: 'lightgray',
    borderRadius: '4px',

    '&:focus': {
      borderColor: '#1C61CA',
      borderWidth: '2px',
      outline: 'none',
      borderRadius: 4,
    },
  },
  textarea_red: {
    width: '100%',
    height: '100%',
    padding: '8px',
    fontSize: '19px',
    fontFamily: 'Normal',
    fontWeight: 200,
    borderColor: '#f44336',
    borderRadius: '4px',
    '&:focus': {
      borderColor: '#1C61CA',
      borderWidth: '2px',
      outline: 'none',
      borderRadius: 4,
    },
  },

  label: {
    fontSize: '16px',
    fontFamily: 'inherit',
    fontWeight: 400,
    lineHeight: 1.43,
  },
  errorLabel: {
    color: "#f44336",
    fontSize: '16px',
    fontFamily: 'inherit',
    fontWeight: 400,
    lineHeight: 1.43,
  },
  grid: {
    marginBottom: '-20px',
    marginTop: '-9px',
  },
}));

const AddEditProductMaterialSubProduct = ({ history,singleProductMaterialSubProduct }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const productData = useSelector(state => state?.Products?.products);
  const subProductData = useSelector(state => state?.SubProducts?.subproducts);
  // const { singleProductMaterialSubProduct } = useSelector(state => state.ProductMaterialSubProduct);
  const { id } = useParams();
  const [descriptionHTML, setDescriptionHTML] = useState('');
  const [errorText, setErrorText] = useState({});
  const [file, setFile] = useState([]);
  const [keywordsArr, setKeywordsArr] = useState([]);
  const [materialData, setMaterialData] = useState([]);
  const [metaCount, setMetaCount] = useState({
    metaTitle: 0,
    metaDescription: 0
  })
  // const [productId, setProductId]= useState('')
  const [parameterValue, setParameterValue] = useState("");
  const [data, setData] = useState({
    productId: '',
    subProductId: '',
    materialId: '',
    metaDescription: '',
    name: '',
    code: '',
    url: '',
    metaTitle: '',
    AltImageName: '',
    keywords: '',
    // parameters:[],
    preview: '',
  });

  const handleChange = evt => {
    const { name, value } = evt.target;
    const regex = /[\W_]+|\s+/g;
    if (name === 'name') {
      const nameValue = value ? value.replace(regex, '-').toLowerCase() : '';
      data.code = nameValue;
      data.url = nameValue;
    }
    setData({ ...data, [name]: value });
    setMetaCount({ ...metaCount, [name]: value.length })
  };

  const countWords = (str) => {
    // Remove leading and trailing whitespaces
    str = str.trim();

    // Remove consecutive whitespaces and replace them with a single space
    str = str.replace(/\s+/g, ' ');

    // Split the string by spaces to get an array of words
    var words = str.split(' ');

    // Return the number of words
    return words.length;
  }

  const validate = () => {
    let errors = {};
    let isError = false;
    const {
      productId,
      subProductId,
      materialId,
      code,
      metaDescription,
      name,
      metaTitle,
      url,
      // parameters
    } = data;
    let tempDescription = descriptionHTML;
    let noSpaceData = tempDescription.replace(/\&nbsp;/g, '');
    const noTagData = noSpaceData.replace(/<[^>]*>/g, '');

    if (!productId) {
      errors.productId = 'Please select product.';
      isError = true;
    }
    if (!subProductId) {
      errors.subProductId = 'Please select sub-product.';
      isError = true;
    }
    if (!materialId) {
      errors.materialId = 'Please select material.';
      isError = true;
    }
    if (!name.trim()) {
      errors.name = 'Please enter name.';
      isError = true;
    }
    if (!code) {
      errors.code = 'Please enter code.';
      isError = true;
    }
    if (noTagData.length < 2) {
      errors.descriptionHTML = 'Please enter description.';
      isError = true;
    } // ([1-9]|1\d|20)
    if (!metaDescription) {
      errors.metaDescription = 'Please enter meta description.';
      isError = true;
    }
    if (countWords(metaDescription) > 300) {
      errors.metaDescription = 'Meta description must be less than 300 words.';
      isError = true;
    }
    if (!metaTitle) {
      errors.metaTitle = 'Please enter meta title.';
      isError = true;
    }
    if (!url) {
      errors.url = 'Please enter URL.';
      isError = true;
    }
    if (!keywordsArr.length) {
      errors.keywords = 'Please enter keyword.';
      isError = true;
    }
    if (!file.length && !preview) {
      errors.file = 'Please upload image.';
      isError = true;
    }
    // if (parameters?.length < 4) {
    //   errors.parameters = 'Please enter minimum 4 parameters';
    //   isError = true;
    // }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };

  const handleSubmit = () => {
    let validateData = validate();
    if (!validateData.isError) {
      let formData = new FormData();
      formData.append('productId', productId);
      formData.append('subProductId', subProductId);
      formData.append('materialId', materialId);
      formData.append('name', name);
      formData.append('metaTitle', metaTitle);
      formData.append('code', code);
      formData.append('url', url);
      formData.append('metaDescription', metaDescription);
      keywordsArr.map(e => formData.append('keywords', e))
      // formData.append('parameters', JSON.stringify(parameters));
      formData.append('description', descriptionHTML);
      formData.append('AltImageName', AltImageName);
      formData.append('logo', file[0] ? file[0] : preview);
      id && formData.append('id', id);
      if(id){
        dispatch(updateProductMaterialSubProduct(id, formData)).then((res)=>{
          if (res?.status === 200) {
            dispatch(getProductMaterialSubProductListById(id))
          }
        })
      }else{
        dispatch(crateProductMaterialSubProduct(formData)).then(res => {
          if (res?.status === 200) {
            history.push('/manage-material-sub-product-product');
          }
        });
      }
    }
  };

  const validateKeyword = () => {
    let error = '';
    let isError = false;
    if (!keywords) {
      error = 'Please enter keywords.';
      isError = true;
    }
    setErrorText({ ...errorText, keywords: error });
    return {
      error,
      isError,
    };
  };

  const onKeywordsAdd = () => {
    let validateData = validateKeyword();
    if (!validateData.isError) {
      setKeywordsArr(keywordsArr => [...keywordsArr, keywords]);
      setData({ ...data, keywords: '' });
    }
  };

  const onKeywordsDelete = (e, value) => {
    setKeywordsArr(keywordsArr =>
      keywordsArr.filter(e => {
        if (e.length) {
          return e != value;
        }
      }),
    );
  };

  const handleProductChange = evt => {
    setData({ ...data, productId: evt.target.value, subProductId: '' });
    dispatch(getAllSubProducts(evt.target.value))
    // setData({ ...data, productId: evt.target.value });
    // const selectedProduct = productData?.find(
    //   item => item?._id === evt.target.value,
    // );
    // dispatch(getAllSubProducts(selectedProduct?.productId));
  };

  const fetchData = async () => {
    dispatch(getProductsDropdown({}));
    dispatch(getMaterialList()).then(res => {
      if (res?.status === 200) {
        setMaterialData(res?.data?.data?.findExistingMaterial);
      }
    });
  };

  useEffect(()=>{
    fetchData();
  },[])

  useEffect(() => {
    if (id && singleProductMaterialSubProduct) {
      const {
        productId,
        subProductId,
        materialId,
        name,
        code,
        url,
        description,
        AltImageName,
        keywords,
            // parameters,
        logo,
        metaDescription,
        metaTitle,
      } = singleProductMaterialSubProduct;
      dispatch(getAllSubProducts(productId));
      setData({
        productId,
        subProductId,
        materialId,
        name,
        code,
        url,
        AltImageName,
        keywords: '',
            // parameters,
        description,
        preview: logo,
        metaDescription,
        metaTitle,
      });
      setKeywordsArr(keywords);
      setDescriptionHTML(description);
      setMetaCount({ ...metaCount, metaTitle: metaTitle.length, metaDescription: metaDescription.length })
    }
  }, [singleProductMaterialSubProduct]);

  const {
    productId,
    subProductId,
    materialId,
    metaDescription,
    name,
    code,
    metaTitle,
    AltImageName,
    keywords,
    // parameters,
    url,
    description,
    preview,
  } = data;
  return (
    <PageContainer>
      <CmtCard>
        <CmtCardContent>
          <Typography variant="h2" className="mb-2">
            {id
              ? 'Edit Material Sub-Product Product'
              : 'Add Material Sub-Product Product'}
          </Typography>
          <Divider />
          <GridContainer className="mt-2">
            <Grid item md={6} sx={12}>
              <AppSelectBox
                required
                data={productData}
                name="productId"
                label="Product"
                valueKey="productId"
                fullObject={true}
                variant="outlined"
                labelKey="name"
                value={productId}
                onChange={handleProductChange}
                helperText={errorText?.productId}
              />
              <br />
              <br />

              <AppSelectBox
                required
                data={materialData}
                name="materialId"
                label="Material"
                valueKey="materialId"
                variant="outlined"
                labelKey="name"
                value={materialId}
                onChange={handleChange}
                helperText={errorText?.materialId}
              />
              <br />
              <br />

              <AppTextInput
                required
                fullWidth
                name="name"
                variant="outlined"
                label="Name"
                value={name}
                onChange={handleChange}
                className="mb-5"
                helperText={errorText?.name}
              />
              {/* <AppTextInput
                required
                fullWidth
                name="code"
                variant="outlined"
                label="Code"
                value={code}
                onChange={handleChange}
                className="mb-5"
                helperText={errorText?.code}
              /> */}

              <Grid className={classes.grid} item xs={12} sm={12}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <label className={errorText.metaDescription ? classes.errorLabel : classes.label}>Meta Description*</label>
                  <label className={classes.label}>{metaCount.metaDescription}</label>
                </Box>
                <textarea
                  className={
                    errorText.metaDescription
                      ? classes.textarea_red
                      : classes.textarea
                  }
                  rows={3}
                  fullWidth
                  name="metaDescription"
                  width={100}
                  required
                  variant="outlined"
                  label="Meta Description"
                  value={metaDescription}
                  onChange={handleChange}
                />
                {errorText.metaDescription ? (
                  <p style={{ color: '#f44336', fontSize: '12px' }}>
                    {errorText?.metaDescription}
                  </p>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>

            <Grid item md={6} sx={12}>
              <AppSelectBox
                required
                data={subProductData}
                name="subProductId"
                label="Sub-Product"
                valueKey="subProductId"
                variant="outlined"
                labelKey="name"
                value={subProductId}
                onChange={handleChange}
                helperText={errorText?.subProductId}
              />

              <br />
              <br />
              <AppTextInput
                required
                fullWidth
                name="metaTitle"
                variant="outlined"
                label={`Meta Title / ${metaCount.metaTitle}`}
                value={metaTitle}
                onChange={handleChange}
                className="mb-5"
                helperText={errorText?.metaTitle}
              />

              <AppTextInput
                required
                fullWidth
                name="url"
                variant="outlined"
                label="URL"
                value={url}
                onChange={handleChange}
                className="mb-5"
                helperText={errorText?.url}
              />
              <AppTextInput
                // required
                fullWidth
                name="AltImageName"
                variant="outlined"
                label="Alt Image Name"
                value={AltImageName}
                onChange={handleChange}
                className="mb-5"
              />
              <AppTextInput
                fullWidth
                name="file"
                variant="outlined"
                type="file"
                onChange={evt => setFile(evt.target.files)}
                className="mb-5"
                helperText={errorText?.file}
              />
              {preview && <img src={preview} alt={AltImageName} className={classes?.avatar} />}
            </Grid>
            {/* parameters */}
            {/* <Grid item xs={12} sm={11}>
              <AppTextInput
                fullWidth
                required
                name="parameterValue"
                variant="outlined"
                label="Parameters"
                value={parameterValue}
                onChange={(e) => setParameterValue(e.target.value)}
                helperText={errorText?.parameters}
              />
            </Grid>
            <Grid item sm={1}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setData((pre) => {
                    if (parameterValue) {
                      return { ...pre, parameters: [...pre.parameters, parameterValue] }
                    } else {
                      return pre
                    }
                  })
                  setParameterValue("")
                }}
              >
                <Add />
              </Button>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box>
                {parameters &&
                  parameters.length > 0 &&
                  parameters.map((e, key) => {
                    return (
                      e.length !== 0 && (
                        <Chip
                          size="medium"
                          key={key}
                          label={e}
                          clickable
                          onDelete={evt => {
                            const deleteParameterValue = data.parameters.filter((ele, i) => ele !== e)
                            setData((pre) => ({ ...pre, parameters: deleteParameterValue }))
                          }}
                          color="secondary"
                          variant="outlined"
                        />
                      )
                    );
                  })}
              </Box>
            </Grid> */}
            <Grid item xs={12} sm={11} md={11}>
              <AppTextInput
                fullWidth
                name="keywords"
                variant="outlined"
                required
                label="Keywords"
                value={keywords}
                className="mt-5"
                onChange={handleChange}
                helperText={errorText?.keywords}
              />
            </Grid>
            <Grid item sm={1} md={1}>
              <Button
                variant="contained"
                color="secondary"
                className="mt-5"
                onClick={onKeywordsAdd}
              >
                <Add />
              </Button>
            </Grid>

            {keywordsArr && keywordsArr.length > 0 && (
              <Grid item xs={12} sm={12}>
                <Box>
                  {keywordsArr.map((e, key) => {
                    return (
                      e.length !== 0 && (
                        <Chip
                          size="medium"
                          key={e}
                          label={e}
                          clickable
                          onDelete={evt => onKeywordsDelete(evt, e)}
                          color="secondary"
                          variant="outlined"
                          className="mb-2 mr-2"
                        />
                      )
                    );
                  })}
                </Box>
              </Grid>
            )}

            <span
              className={
                errorText?.descriptionHTML ? classes?.editorLabelError : ''
              }
            >
              Description *
            </span>
            <Grid item xs={12} sm={12}>
              {/* <WysiwygEditorExample
                defaultDescription={description}
                setDescriptionHTML={setDescriptionHTML}
                descriptionHTML={description}
              /> */}

              <CustomEditor
                editorData={descriptionHTML || ''}
                setEditorData={setDescriptionHTML}
                error={errorText?.descriptionHTML}
              />

              <span className={classes?.errorCk}>
                {errorText?.descriptionHTML}
              </span>
            </Grid>
            <Box display="flex" justifyContent="flex-end" mb={4}>
              <Button
                onClick={() =>
                  history.push('/manage-material-sub-product-product')
                }
              >
                Cancel
              </Button>
              <Box ml={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  {id ? 'Update' : 'Save'}
                </Button>
              </Box>
            </Box>
          </GridContainer>
        </CmtCardContent>
      </CmtCard>
    </PageContainer>
  );
};

export default AddEditProductMaterialSubProduct;
