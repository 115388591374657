import CustomTable from "components/CustomeTable";
import React, { useEffect, useState } from "react";
import { Edit, MoreHoriz, Visibility } from "@material-ui/icons";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterListIcon from '@mui/icons-material/FilterList';
import CmtDropdownMenu from "@coremat/CmtDropdownMenu";
import Axios from "axios";
import FilterData from "./FilterData";
import { Box, Button, Checkbox, Switch, TableCell, TableRow } from "@material-ui/core";

const AllModules = () => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [module, setModule] = useState([]);
    const [perPage, setPerPage] = useState(15);
    const [total, setTotal] = useState(0);
    const [page, setPage] = React.useState(0);
    const [row, setRow] = useState([]);
    const [filterOne, setFilterOne] = useState('');
    const [filterData, setFilterData] = useState({
        products: [],
        subproduct: [],
        material: [],
        grade: [],
        equivalentGrade: []
    });
    const [renderStatus,setRenderStatus] = useState(true);
    const [newFilterData, setNewFilterData] = useState("");

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrderBy(property);
        setOrder(isAsc ? 'desc' : 'asc');
    };
    const getUserActions = user => {
        const actions = [];
        actions.push({ action: 'Edit', label: 'Edit', icon: <Edit /> });
        actions.push({ action: 'View', label: 'View', icon: <Visibility /> });
        return actions;
    };

    const userActions = getUserActions(row);
    const onUserMenuClick = (menu, elm) => {
        if (menu.action === "Edit") {
            if(elm?.data?.manageEdit !== null){
                window.open(`/${elm?.data?.manageEdit}/${elm?.data?._id}`);
            }
        } else if (menu.action === "View") {
            if(elm?.data?.url?.slug !== null){
                window.open(`https://www.thepipingmart.com/${elm?.data?.url?.slug}/${elm?.data?.url?.urlpath}`);
            }
        }
    };
    const handleLabelClick = (headerId) => {
        setFilterOne(headerId);
    };
    const handlePageChange = (event, newPage) => {
        console.log(newPage)
        setPage(newPage);
    };

    const handleChangeStatusSwitch = async (elm) => {
        let status = !elm?.data?.active
        const updatedStatus = await Axios.patch(`/api/v1/admin/allModule/updateStatus/${elm._id}/${elm.data._id}/${status}`);
        console.log(updatedStatus.data)
        if(updatedStatus?.data?.product?.modifiedCount > 0 && updatedStatus?.data?.allModule?.modifiedCount){
            setRenderStatus((prev)=> !prev)
        }
    };


    const handleDisplayValue = (e) => {
        if (e === "action") {
            return elm => (
                <CmtDropdownMenu
                    items={userActions}
                    onItemClick={menu => onUserMenuClick(menu, elm)}
                    TriggerComponent={<MoreHoriz />}
                />
            )
        } else if (e === "Display Name") {
            return elm => elm?.data?.displayName ? elm?.data?.displayName : "--"
        } else if (e === "products") {
            return elm => elm?.data?.productId?.name ? elm?.data?.productId?.name : "--"
        } else if (e === "subproduct") {
            return elm => elm?.data?.subproductId?.name ? elm?.data?.subproductId?.name : "--"
        } else if (e === "material") {
            return elm => elm?.data?.materialId?.name ? elm?.data?.materialId?.name : "--"
        } else if (e === "grade") {
            return elm => elm?.data?.gradeModelId?.name ? elm?.data?.gradeModelId?.name : "--"
        } else if (e === "equivalentGrade") {
            return elm => elm?.data?.eqGradeId?.name ? elm?.data?.eqGradeId?.name : "--"
        }else if (e === 'status'){
            return elm => (
        <Switch
          // defaultChecked={elm.showInMiddle === true ? true : false}
          checked={elm?.data?.active === true ? true : false}
          onChange={() => handleChangeStatusSwitch(elm)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      )
        } else {
            return 0
        }
    }

    const headCells = ["products", "subproduct", "material", "grade", "equivalentGrade", "status", "Display Name", "action"].map((e) => ({
        id: e,
        numeric: false,
        disablePadding: false,
        // sort: e === "Display Name" || e === "action" ? false : true,
        sort: false,
        label: (
            <>
                {<h4>{e.toUpperCase()}
                    {e === "Display Name" || e === "action" || e === "status" ? "" : <box>
                        <button
                            onClick={(evt) => {
                                evt.stopPropagation()
                                handleLabelClick(e)
                            }}
                            style={{ border: "none", position: "absolute", cursor: "pointer" }}
                        >
                            <FilterListIcon fontSize="small" style={{ margin: "0 10px", background: "#f4f4f7" }} />

                        </button>
                        {filterOne === e ? <FilterData
                            header={e}
                            setHeader={setFilterOne}
                            filterData={filterData}
                            setFilterData={setFilterData}
                            setNewFilterData={setNewFilterData}
                        /> : null}
                    </box>
                    }</h4>}
            </>
        ),
        render: handleDisplayValue(e)
    }))

    useEffect(() => {
        (async function () {
            const data = await Axios.post(`/api/v1/admin/allModule/fetchAllModules?page=${page + 1}&limit=${perPage}`, newFilterData);
            setModule(data?.data?.allmodules)
            console.log(data.data, "sjhdqjiwqhwd", page)
            setTotal(data?.data?.total[0]?.total)
        }())
    }, [page, newFilterData,renderStatus])
    // console.log(module)

    return (
        <>
            <Box display={"flex"} justifyContent={"end"} sx={{ margin: "10px 20px" }}>
                <Button
                    onClick={() => {
                        setFilterOne("")
                        setFilterData({
                            products: [],
                            subproduct: [],
                            material: [],
                            grade: [],
                            equivalentGrade: []
                        })
                        setNewFilterData("")
                        console.log(filterData, newFilterData)
                    }}
                    color="secondary"
                    variant="contained"
                >
                    Clear All Filter
                </Button>
            </Box>
            <CustomTable
                TableName="All Modules"
                row={module && module}
                headCells={headCells}
                isDynamic={true}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                count={total || 0}
                rowsPerPage={perPage}
                onPageChange={handlePageChange}
                page={page}
                showToolbar={false}
            />
        </>
    )
}

export default AllModules