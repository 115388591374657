import { HIDE_LOADER, SET_LOADER } from "redux/actions/Common/Constant"

// Loaderactions.js
export function setLoading(actionType) {
    return {
        type: SET_LOADER,
        action: actionType,
        loading: true,
        // payload: null
    }
}

export function hideLoading() {
    return {
        type: HIDE_LOADER
    }
}
