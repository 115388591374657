import { USERS_LIST_SUCCESS, USER_CREATE_SUCCESS, USER_DELETE_SUCCESS, SET_USER_DETAILS, DELETE_BULK_USERS_SUCCESS, USER_UPDATE_SUCCESS, USER_PROFILE_UPDATE_SUCCESS } from "redux/actions/index";

const INIT_STATE =
{
    users: [],
    user: null,
    total: 0,
    perPage: 0
    // permissions: null,
}
const INIT_STATE_DETAIL =
{
    user: null,
}

export const UsersReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case USER_CREATE_SUCCESS: {
            return {
                ...state,
                user: action.data,
                action: action.type,
                message: action.message,
                users: [action.data, ...state.users]
            };
        }
        case USERS_LIST_SUCCESS: {
            return {
                ...state,
                users: action.data,
                total: action.total,
                action: action.type,
                message: action.message,
                perPage: action.perPage,
            };
        }
        //users: state.users.map(user => (user.id === action.payload.id ? action.payload : user))
        case USER_UPDATE_SUCCESS: {
            return {
                ...state,
                user: action.data,
                action: action.type,
                message: action.message,
                users: state.users.map(user => (user._id === action.data._id ? action.data : user))
            };
        }
        case USER_PROFILE_UPDATE_SUCCESS: {
            return {
                ...state,
                user: action.data,
                action: action.type,
                message: action.message,
                users: state.users.map(user => (user._id === action.data._id ? action.data : user))
            };
        }
        case USER_DELETE_SUCCESS: {
            return {
                ...state,
                user: action.data,
                action: action.type,
                message: action.message,
                users: state.users.filter(user => user._id !== action.data._id),
            };
        }
        case DELETE_BULK_USERS_SUCCESS: {
            return {
                ...state,
                ...state,
                action: action.type,
                message: action.message,
                users: state.users.filter(user => !action.data.includes(user.id)),
            };
        }
        default:
            return state;
    }
};
export const SetUserDetailReducer = (state = INIT_STATE_DETAIL, action) => {
    switch (action.type) {
        case SET_USER_DETAILS:
            return {
                ...state,
                user: action.data,
                action: action.type,
            };
        default:
            return state;
    }
};