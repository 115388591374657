import { HIDE_ERROR, SET_ERROR } from "redux/actions/Common/Constant"

// errorActions.js
export function setError(message, actionType) {
    return {
        type: SET_ERROR,
        action: actionType ? actionType : SET_ERROR,
        message,
        loading: false,
        error: true,
        success: false,
    }
}

export function hideError() {
    return {
        type: HIDE_ERROR
    }
}
