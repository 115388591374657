import Axios from "axios";
import { setError } from "redux/actions/Common/ErrorAction";
import { hideLoading, setLoading } from "redux/actions/Common/LoadingAction";
import { FAILED_FREE_SUPPLIER_LIST, REQUEST_FREE_SUPPLIER_LIST, SEND_RFQ_EMAIL_FAILED, SEND_RFQ_EMAIL_REQUEST, SEND_RFQ_EMAIL_SUCCESS, SUCCESS_FREE_SUPPLIER_LIST } from "./constant";

export const fetchRfqEmailStatus = function(){
  return async function(dispatch){
    try {
      dispatch(setLoading());
      dispatch({type:SEND_RFQ_EMAIL_REQUEST});
      const response = await Axios.get('/api/v1/admin/sendRfqEmail/list');
      if(response?.status === 200){
        dispatch({type: SEND_RFQ_EMAIL_SUCCESS, payload: {
          data:response.data?.data,
        }});
        dispatch(hideLoading())
      }
    } catch (error) {
      dispatch(setError(error?.data?.error));
      dispatch({type:SEND_RFQ_EMAIL_FAILED,payload:{error:error?.data?.message}})
    }
  }
}

export const updateRfqEmailStatus = function(id,status){
  return async function(dispatch){
    try {
      dispatch(setLoading());
      const response = await Axios.put(`/api/v1/admin/sendRfqEmail/update/${id}`,{
        isRfqEmailSend:status
      });
      if(response?.status === 200){
        dispatch(hideLoading())
        return Promise.resolve();
      }
    } catch (error) {
      dispatch(setError(error?.data?.error));
    }
  }
}

export const fetchFreeSupplier = function(queryData){
  return async function(dispatch){
    try {
      dispatch(setLoading());
      dispatch({type:REQUEST_FREE_SUPPLIER_LIST});
      const response = await Axios.get(`/api/v1/admin/freeSupplier/list?search=${queryData?.search || ""}&page=${queryData?.page  || 1}`);
      if(response?.status === 200){
        dispatch({type: SUCCESS_FREE_SUPPLIER_LIST, payload: {
          data:response.data?.data,
          total:response?.data?.total
        }});
        dispatch(hideLoading())
      }
    } catch (error) {
      dispatch(setError(error?.data?.error));
      dispatch({type:FAILED_FREE_SUPPLIER_LIST,payload:{error:error?.data?.message}})
    }
  }
}

export const updateFreeSupplierStatus = function(id,status){
  return async function(dispatch){
    try {
      dispatch(setLoading());
      const response = await Axios.put(`/api/v1/admin/freeSupplier/update/${id}`,{
        status:status
      });
      if(response?.status === 200){
        dispatch(hideLoading())
        return Promise.resolve();
      }
    } catch (error) {
      dispatch(setError(error?.data?.error));
    }
  }
}