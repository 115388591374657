//-------------EQGRADE constants------------------
export const EQGRADE_LIST_REQUEST = "EQGRADE_LIST_REQUEST";
export const EQGRADE_LIST_SUCCESS = "EQGRADE_LIST_SUCCESS";
export const EQGRADE_LIST_FAILED = "EQGRADE_LIST_FAILED";
export const DELETE_BULK_EQGRADE_REQUEST = "DELETE_BULK_EQGRADE_REQUEST";
export const DELETE_BULK_EQGRADE_SUCCESS = "DELETE_BULK_EQGRADE_SUCCESS";
export const DELETE_BULK_EQGRADE_FAILED = "DELETE_BULK_EQGRADE_FAILED";
export const SET_EQGRADE_DETAILS = "SET_EQGRADE_DETAILS";
export const EQGRADE_CREATE_REQUEST = "EQGRADE_CREATE_REQUEST";
export const EQGRADE_CREATE_SUCCESS = "EQGRADE_CREATE_SUCCESS";
export const EQGRADE_CREATE_FAILED = "EQGRADE_CREATE_FAILED";
export const EQGRADE_MATERIAL_CREATE_REQUEST = "EQGRADE_MATERIAL_CREATE_REQUEST";
export const EQGRADE_MATERIAL_CREATE_SUCCESS = "EQGRADE_MATERIAL_CREATE_SUCCESS";
export const EQGRADE_MATERIAL_CREATE_FAILED = "EQGRADE_MATERIAL_CREATE_FAILED";
export const EQGRADE_UPDATE_REQUEST = "EQGRADE_UPDATE_REQUEST";
export const EQGRADE_UPDATE_SUCCESS = "EQGRADE_UPDATE_SUCCESS";
export const EQGRADE_UPDATE_FAILED = "EQGRADE_UPDATE_FAILED";
export const EQGRADE_PROFILE_UPDATE_REQUEST = "EQGRADE_PROFILE_UPDATE_REQUEST";
export const EQGRADE_PROFILE_UPDATE_SUCCESS = "EQGRADE_PROFILE_UPDATE_SUCCESS";
export const EQGRADE_PROFILE_UPDATE_FAILED = "EQGRADE_PROFILE_UPDATE_FAILED";
export const EQGRADE_PASSWORD_UPDATE_REQUEST = "EQGRADE_PASSWORD_UPDATE_REQUEST";
export const EQGRADE_PASSWORD_UPDATE_SUCCESS = "EQGRADE_PASSWORD_UPDATE_SUCCESS";
export const EQGRADE_PASSWORD_UPDATE_FAILED = "EQGRADE_PASSWORD_UPDATE_FAILED";
export const EQGRADE_DELETE_REQUEST = "EQGRADE_DELETE_REQUEST";
export const EQGRADE_DELETE_SUCCESS = "EQGRADE_DELETE_SUCCESS";
export const EQGRADE_DELETE_FAILED = "EQGRADE_DELETE_FAILED";
export const GRADE_EQGRADE_LIST_REQUEST = "GRADE_EQGRADE_LIST_REQUEST";
export const GRADE_EQGRADE_LIST_SUCCESS = "GRADE_EQGRADE_LIST_SUCCESS";
export const GRADE_EQGRADE_LIST_FAILED = "GRADE_EQGRADE_LIST_FAILED";