import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { isValidEmail, isValidPassword } from '@jumbo/utils/commonHelper';
import { createUser, updateUser } from 'redux/actions/Manage/Users/Users';
// import { getAllDepts } from 'redux/actions/Manage/Department/Department';
import { getAllRoles } from 'redux/actions/Manage/Roles/Roles';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
}));
const labels = [
  // { title: 'Deleted', code: 0 },
  { title: 'Temporary', code: 1 },
  { title: 'Permanent', code: 2 },
];
const AddEditUser = ({ open, onCloseDialog, setDataUpdated }) => {
  const classes = useStyles();
  const { user } = useSelector(state => state.SetUserDetail);
  const { roles } = useSelector(state => state.Roles);
  const [userDetail, setUserDetail] = useState({
    email: '',
    roleId: '',
    status: '',
  });
  const [rolesArr, setRolesArr] = useState([]);
  const [errorText, setErrorText] = React.useState({});
  const validate = () => {
    const errors = {};
    let isError = false;
    const { firstName, lastName, status, roleId, password, email } = userDetail;
    if (!firstName) {
      errors.firstName = 'Please enter first name.';
      isError = true;
    } else if (!/^[A-Za-z]+$/.test(firstName)) {
      errors.firstName = 'Only letters and no spaces are allowed.';
      isError = true;
    }
    if (!lastName) {
      errors.lastName = 'Please enter last name.';
      isError = true;
    } else if (!/^[A-Za-z]+$/.test(lastName)) {
      errors.lastName = 'Only letters and no spaces are allowed';
      isError = true;
    }
    if (!email) {
      errors.email = 'Please enter email.';
      isError = true;
    } else if (!isValidEmail(email)) {
      errors.email = 'Invalid email address.';
      isError = true;
    }
    if (!user && !password) {
      errors.password = 'Please enter password.';
      isError = true;
    } else if (!user && isValidPassword(password)) {
      errors.password =
        'Password must include at least 8 characters and contain at least 1 lowercase & 1 uppercase letter, a number and special symbols.';
      isError = true;
    }
    if (!roleId) {
      errors.roleId = 'Please select role.';
      isError = true;
    }
    if (!status) {
      errors.status = 'Please select status.';
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      const { firstName, lastName, status, email, roleId } = user;
      setUserDetail({
        firstName,
        lastName,
        status,
        email,
        roleId: roleId._id,
      });
    }
  }, [user]);
  useEffect(() => {
    if (roles) {
      setRolesArr(roles);
    }
  }, [roles, rolesArr]);

  const onLabelChange = evt => {
    setUserDetail({ ...userDetail, [evt.target.name]: evt.target.value });
  };

  const onSubmitClick = () => {
    let validateData = validate();
    if (!validateData.isError) {
      onUserSave();
    }
  };

  const onUserSave = () => {
    const userDetail = {
      firstName,
      lastName,
      email,
      password,
      roleId,
      status,
    };
    if (user) {
      dispatch(
        updateUser(user._id, { ...user, ...userDetail }, () => {
          onCloseDialog();
          setDataUpdated(data => !data);
        }),
      );
    } else {
      dispatch(
        createUser(userDetail, () => {
          onCloseDialog();
          setDataUpdated(data => !data);
        }),
      );
    }
  };

  useEffect(() => {
    // dispatch(getAllDepts())
    dispatch(getAllRoles());
  }, [dispatch]);

  const { firstName, lastName, status, roleId, password, email } = userDetail;
  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {user ? 'Edit User Details' : 'Block User'}
      </DialogTitle>
      <DialogContent dividers>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            name="email"
            variant="outlined"
            label="Email Address"
            value={email}
            onChange={onLabelChange}
            helperText={errorText?.email}
          />
        </Box>

        <Box mb={{ xs: 6, md: 5 }}></Box>
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={12}>
            <AppSelectBox
              fullWidth
              name="status"
              data={labels}
              label="Status"
              valueKey="code"
              variant="outlined"
              labelKey="title"
              value={status}
              onChange={onLabelChange}
              helperText={errorText?.status}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <AppSelectBox
              fullWidth
              data={rolesArr}
              name="roleId"
              label="Role Name"
              valueKey="_id"
              variant="outlined"
              labelKey="roleName"
              value={roleId}
              onChange={onLabelChange}
              helperText={errorText?.roleId}
            />
          </Grid> */}
        </GridContainer>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog}>Cancel</Button>
          <Box ml={2}>
            <Button variant="contained" color="primary" onClick={onSubmitClick}>
              {user ? 'Update' : 'Save'}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
