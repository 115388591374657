import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { alpha } from '@material-ui/core/styles';
import {
  StarBorder,
  Star,
  Block,
  Cancel,
  Done,
  Inbox,
  CancelScheduleSend,
  Schedule,
  Reply,
  Delete,
  Archive,
  Send,
} from '@material-ui/icons';
import {
  Box,
  Checkbox,
  Typography,
  IconButton,
  Tooltip,
  Chip,
} from '@material-ui/core';

import { getDateInDesiredFormat } from '@jumbo/utils/dateHelper';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import CmtAvatar from '@coremat/CmtAvatar';
import MailLabels from './MailLabels';
import SenderInfo from './SenderInfo';
import useStyles from './MailCell.style';

import {
  cancelReScheduleEmails,
  changeEmailStatus,
  fetchMailById,
  getAllEmails,
  getReScheduleEmails,
  sandItEmail,
} from 'redux/actions/Manage/Emails/Emails';

const MailCell = ({
  mail,
  labelsList,
  checkedMails,
  onChangeCheckedMails,
  onClickSendMail,
  onClickForwardMail,
  viewMode,
  setRefresh,
  refresh,
  canceled,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [showSenderInfo, setShowSenderInfo] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [emailAction, setEmailAction] = useState('');
  const { filterType } = useSelector(({ Emails }) => Emails);
  const onShowSenderInfo = event => {
    setShowSenderInfo(event.currentTarget);
  };

  const onHideSenderInfo = () => {
    setShowSenderInfo(null);
  };

  const onGetMailDetail = () => {
    dispatch(fetchMailById(mail._id)); 
  };

  const handleTakeAction = action => {
    setOpenConfirmDialog(true);
    setEmailAction(action);
  };

  const handleConfirmAction = user => {
    setOpenConfirmDialog(false);
    let params = {
      id: mail._id,
      status: emailAction,
    };

    dispatch(changeEmailStatus(params)).then(res => {
      if (res?.status === 200) {
        let newParams = {
          status: filterType.selectedFolder,
          emailType: filterType.selectedLabel,
        };
        dispatch(getAllEmails(newParams));
      }
    });
  };

  const handleConfirmCancelSchedule = () => {
    dispatch(cancelReScheduleEmails(mail?._id)).then(res => {
      if (res?.status === 200) {
        setOpenConfirmDialog(false);
        setRefresh(!refresh);
      }
    });
  };

  const handleConfirmSendIt = () => {
    dispatch(sandItEmail(mail?._id)).then(res => {
      if (res?.status === 200) {
        setOpenConfirmDialog(false);
        setRefresh(!refresh);
      }
    });
  };

  const handleCancelAction = user => {
    setOpenConfirmDialog(false);
  };

  const {
    _id,
    senderEmail,
    senderDetails,
    emailContent,
    read,
    emailType,
    status,
    date,
    scheduleOn,
    isCanceled,
  } = mail;
  const labels = isCanceled ? ['Cancelled'] : [emailType];
  return (
    <Box
      className={clsx(classes.mailCellItem, {
        itemRead: read,
        selected: checkedMails.includes(mail._id),
      })}
      onClick={!openConfirmDialog && onGetMailDetail}
    >
      <Box className={classes.mailCellContent}>
        <Box mr={{ xs: 2, md: 4 }} onClick={event => event.stopPropagation()}>
          <Checkbox
            color="primary"
            checked={checkedMails.includes(mail._id)}
            onChange={event => onChangeCheckedMails(event.target.checked, _id)}
          />
        </Box>

        <Box className={classes.mailCellContentAction}>
          <Box
            className={classes.avatarRoot}
            mr={{ xs: 4, md: 6 }}
            onMouseEnter={onShowSenderInfo}
            onMouseLeave={onHideSenderInfo}
          >
            <CmtAvatar
              size={viewMode === 'detail' ? 56 : 40}
              src={senderDetails?.profile_pic}
              alt={senderDetails?.name}
            />
            <Box onClick={event => event.stopPropagation()}>
              <SenderInfo
                showSenderInfo={showSenderInfo}
                from={senderEmail}
                onHideSenderInfo={onHideSenderInfo}
                onClickSendMail={onClickSendMail}
              />
            </Box>
          </Box>

          <Box className={classes.mailCellContentRoot}>
            {viewMode === 'detail' && (
              <Typography className={classes.mailSenderName}>
                {senderDetails?.name}
              </Typography>
            )}
            <Typography
              className={classes.titleRoot}
              component="div"
              variant="h4"
            >
              {emailContent?.subject ? emailContent?.subject : 'No subject'}
            </Typography>
            <Typography className={classes.subTitleRoot}>
              {emailContent.body}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.mailCellAction}>
        <Box className={classes.mailCellTimeAction}>
          {filterType.selectedLabel?.length > 0 && (
            <Chip label={status} size="small" color="secondary" />
          )}

          {filterType.selectedLabel?.length === 0 &&
          labels.length > 0 &&
          labelsList.length > 0 &&
          labels[0] === 'Cancelled' &&
          mail.emailStatus === 'scheduled' ? (
            <Box
              className={classes.badgeRoot}
              component="span"
              style={{
                backgroundColor: `${alpha('#FF0000', 0.1)}`,
                color: '#FF0000',
              }}
            >
              {labels[0]}
            </Box>
          ) : (
            <MailLabels mailLabels={labels} labelsList={labelsList} />
          )}
          <Box component="span" className={classes.mailTimeRoot}>
            {filterType.selectedFolder === 're-schedule'
              ? getDateInDesiredFormat(scheduleOn, 'MMM DD kk:mm')
              : getDateInDesiredFormat(date, 'MMM DD')}
          </Box>
        </Box>
        <Box
          className={classes.mailCellBtnAction}
          onClick={e => e.stopPropagation()}
        >
          {/* {filterType && (filterType.selectedFolder !== 'disapprove' && filterType.selectedFolder !== 'approve' && filterType.selectedFolder !== 'archive' && filterType.selectedFolder !== 'trash') &&
            <Tooltip title="Approve">
              <IconButton className="icon-btn" onClick={() => handleTakeAction("approve")}>
                <Done />
              </IconButton>
            </Tooltip>
          } */}
          {filterType &&
            filterType.selectedFolder !== 'trash' &&
            filterType.selectedFolder !== 're-schedule' &&
            filterType.selectedFolder !== 'archive' &&
            filterType.selectedFolder !== 'disapprove' &&
            filterType.selectedFolder !== 'approve' &&
            filterType.selectedFolder !== 'sent' &&
            !canceled &&
            status !== 'approve' &&
            status !== 'disapprove' &&
            status !== 'archive' &&
            status !== 'trash' && (
              <Tooltip title="Approve">
                <IconButton
                  className="icon-btn"
                  onClick={() => {
                    localStorage.removeItem('schedule');
                    onClickForwardMail(emailContent.html, mail._id);
                  }}
                >
                  <Done />
                </IconButton>
              </Tooltip>
            )}
          {filterType &&
            filterType.selectedFolder !== 'disapprove' &&
            filterType.selectedFolder !== 're-schedule' &&
            filterType.selectedFolder !== 'approve' &&
            filterType.selectedFolder !== 'archive' &&
            filterType.selectedFolder !== 'trash' &&
            filterType.selectedFolder !== 'sent' &&
            !canceled &&
            status !== 'approve' &&
            status !== 'disapprove' && (
              <Tooltip title="Disapprove">
                <IconButton
                  className="icon-btn"
                  onClick={() => handleTakeAction('disapprove')}
                >
                  <Cancel />
                </IconButton>
              </Tooltip>
            )}
          {/* <Tooltip title="Block">
            <IconButton className="icon-btn" onClick={() => onMoveMail('block')}>
              <Block />
            </IconButton>
          </Tooltip> */}
          {filterType &&
            filterType.selectedLabel?.length === 0 &&
            filterType.selectedFolder !== 'inbox' &&
            filterType.selectedFolder !== 're-schedule' &&
            filterType.selectedFolder !== 'approve' &&
            !canceled &&
            filterType.selectedFolder !== 'sent' && (
              <Tooltip title="Inbox">
                <IconButton
                  className="icon-btn"
                  onClick={() => handleTakeAction('inbox')}
                >
                  <Inbox />
                </IconButton>
              </Tooltip>
            )}
          {filterType &&
            status !== 'archive' &&
            filterType.selectedFolder !== 'archive' &&
            filterType.selectedFolder !== 're-schedule' &&
            !canceled &&
            filterType.selectedFolder !== 'sent' && (
              <Tooltip title="Archive">
                <IconButton
                  className="icon-btn"
                  onClick={() => handleTakeAction('archive')}
                >
                  <Archive />
                </IconButton>
              </Tooltip>
            )}
          {filterType &&
            filterType.selectedFolder === 're-schedule' &&
            !canceled && (
              <Tooltip title="Cancel Schedule">
                <IconButton
                  className="icon-btn"
                  onClick={() => handleTakeAction('cancel schedule')}
                >
                  <CancelScheduleSend />
                </IconButton>
              </Tooltip>
            )}

          {filterType && filterType.selectedFolder === 're-schedule' && (
            <Tooltip title="Re-Schedule">
              <IconButton
                className="icon-btn"
                onClick={() => {
                  localStorage.setItem('schedule', true);
                  onClickForwardMail(emailContent.html, mail._id);
                }}
              >
                <Schedule />
              </IconButton>
            </Tooltip>
          )}
          {filterType &&
            status !== 'trash' &&
            filterType.selectedFolder !== 'trash' &&
            filterType.selectedFolder !== 're-schedule' &&
            filterType.selectedFolder !== 'sent' &&
            !canceled && (
              <Tooltip title="Trash">
                <IconButton
                  className="icon-btn"
                  onClick={() => handleTakeAction('trash')}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            )}
          {(filterType && filterType?.selectedFolder === 'sent') ||
          filterType.selectedFolder === 'approve' ? (
            <Tooltip title="Forward">
              <IconButton
                className="icon-btn"
                onClick={() => {
                  localStorage.setItem('forward', true);
                  onClickForwardMail(emailContent.html, mail._id);
                }}
              >
                <Reply />
              </IconButton>
            </Tooltip>
          ) : null}

          {canceled &&
            filterType &&
            filterType.selectedFolder === 're-schedule' && (
              <Tooltip title="Send">
                <IconButton
                  className="icon-btn"
                  onClick={() => {
                    handleTakeAction('send');
                  }}
                >
                  <Send />
                </IconButton>
              </Tooltip>
            )}

          {/* {filterType && (filterType.selectedFolder !== 'trash' && filterType.selectedFolder !== 'archive' && filterType.selectedFolder !== 'disapprove' && filterType.selectedFolder !== 'approve') &&
            <Tooltip title="Forward/Approve">
              <IconButton className="icon-btn" onClick={() => onClickForwardMail(emailContent.html, mail._id)}>
                <ReplyIcon />
              </IconButton>
            </Tooltip>
          } */}
          {/* <Tooltip title="Favorite">
            <IconButton className="icon-btn">
              <Checkbox
                icon={<StarBorder />}
                checkedIcon={<Star style={{ color: '#FF8C00' }} />}
                checked={favorite}
                onChange={e => onClickFavoriteIcon(e.target.checked)}
              />
            </IconButton>
          </Tooltip> */}
        </Box>
      </Box>
      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm ${emailAction} `}
        content={
          emailAction === 'cancel schedule'
            ? 'Are you sure, you want to cancel this schedule email?'
            : emailAction === 'send'
            ? 'Are you sure, you want to send this email immediately?'
            : `Are you sure, you want to ${emailAction} this email?`
        }
        onClose={handleCancelAction}
        onConfirm={
          emailAction === 'cancel schedule'
            ? handleConfirmCancelSchedule
            : emailAction === 'send'
            ? handleConfirmSendIt
            : handleConfirmAction
        }
      />
    </Box>
  );
};

export default MailCell;

MailCell.prototype = {
  mail: PropTypes.object.isRequired,
  labelsList: PropTypes.array.isRequired,
  checkedMails: PropTypes.array.isRequired,
  onChangeCheckedMails: PropTypes.func,
  onClickSendMail: PropTypes.func,
  onClickForwardMail: PropTypes.func,
  viewMode: PropTypes.string,
};

MailCell.defaultProps = {
  viewMode: 'detail',
};
