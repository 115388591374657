import { ErrorOutline } from "@material-ui/icons";
import Axios from "axios";
import { MSG_ERR_RESPONDING_SERVER } from 'redux/actions/Common/Constant';
import { setError } from "redux/actions/Common/ErrorAction";
import { setLoading } from "redux/actions/Common/LoadingAction";
import { setSuccess } from "redux/actions/Common/SuccessAction";
import { setDownload } from "redux/actions/Common/UtilityAction";
import { ErrorCatch } from "utils/ErrorCatch";
import { ROLES_CREATE_FAILED, ROLES_CREATE_REQUEST, ROLES_CREATE_SUCCESS, ROLES_DELETE_FAILED, ROLES_DELETE_REQUEST, ROLES_DELETE_SUCCESS, ROLES_LIST_FAILED, ROLES_LIST_REQUEST, ROLES_LIST_SUCCESS, ROLES_UPDATE_FAILED, ROLES_UPDATE_REQUEST, ROLES_UPDATE_SUCCESS, ROLE_FETCH_FAILED, ROLE_FETCH_REQUEST, ROLE_FETCH_SUCCESS, SET_ROLES_DETAILS } from "./Constant";
export const getAllRoles = (params, callbackFun, isDownload = false) =>
    async (dispatch) => {
        dispatch(setLoading(ROLES_LIST_REQUEST))
        try {
            const { data } = await Axios.get(`/role/list`, { params })
            if (data.statusCode === 200) {
                if (isDownload)
                    dispatch(setDownload())
                else {
                    dispatch({
                        type: ROLES_LIST_SUCCESS,
                        data: data.data.roleList,
                        message: data.responseMsg,
                        total: data.data.total,
                        perPage: data.data.perPage
                    });
                }
                if (callbackFun) callbackFun(data.data.roleList);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, ROLES_LIST_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, ROLES_LIST_FAILED, dispatch)

        }
    };
;
// export const getAllSalesUsers = (params, callbackFun) =>
//     async (dispatch) => {
//         dispatch(setLoading(ROLES_USERS_LIST_REQUEST))
//         try {
//             const { data } = await Axios.get(`/user/list-sales-rep`, { params })
//             if (data.statusCode === 200) {
//                 dispatch(setSuccess(data.responseMsg, ROLES_USERS_LIST_SUCCESS))
//                 dispatch({
//                     type: ROLES_USERS_LIST_SUCCESS,
//                     data: data.data.userList,
//                     total: data.data.total,
//                     message: data.responseMsg,
//                 });
//                 if (callbackFun) callbackFun(data.data);
//             }
//             else {
//                 dispatch(setError(MSG_ERR_RESPONDING_SERVER, ROLES_USERS_LIST_FAILED));
//             }
//         } catch (error) {
//             ErrorCatch(error, ROLES_USERS_LIST_FAILED, dispatch)
//         }
//     };
// ;
export const updateRoles = (roleId, payload, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(ROLES_UPDATE_REQUEST))
        try {
            const { data } = await Axios.put(`/role/update/${roleId}`, payload)
            if (data.statusCode === 200) {
                dispatch(setSuccess(data.responseMsg, ROLES_UPDATE_SUCCESS))
                dispatch({
                    type: ROLES_UPDATE_SUCCESS,
                    data: data.data.roleUpdate,
                    message: data.responseMsg,
                });
                if (callbackFun) callbackFun(data.data.roleUpdate);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, ROLES_UPDATE_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, ROLES_UPDATE_FAILED, dispatch)

        }
    };
export const setRolesDetail = user => {
    return dispatch => {
        dispatch({
            type: SET_ROLES_DETAILS,
            data: user,
        });
    };
};
export const createRoles = (payload, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(ROLES_CREATE_REQUEST))
        try {
            const { data } = await Axios.post(`/role/create`, payload)
            if (data.statusCode === 200) {
                dispatch(setSuccess(data.responseMsg, ROLES_CREATE_SUCCESS))
                dispatch({
                    type: ROLES_CREATE_SUCCESS,
                    data: data.data.roleCreate,
                    message: data.responseMsg,
                });
                if (callbackFun) callbackFun(data.data.roleCreate);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, ROLES_CREATE_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, ROLES_CREATE_FAILED, dispatch)

        }
    };
export const getRoleById = (roleId, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(ROLE_FETCH_REQUEST))
        try {
            const { data } = await Axios.get(`/role/list/${roleId}`)
            if (data.statusCode === 200) {
                dispatch(setSuccess(null, ROLE_FETCH_SUCCESS))
                dispatch({
                    type: ROLE_FETCH_SUCCESS,
                    data: data.data.roleDetail,
                    message: data.responseMsg,
                });
                if (callbackFun) callbackFun(data.data.roleDetail);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, ROLE_FETCH_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, ROLE_FETCH_FAILED, dispatch)

        }
    };
//take user id for delete api request
export const deleteRoles = (roleId, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(ROLES_DELETE_REQUEST))
        try {
            const { data } = await Axios.delete(`/role/delete/${roleId}`)
            if (data.statusCode === 200) {
                dispatch(setSuccess(data.responseMsg, ROLES_DELETE_SUCCESS))
                dispatch({
                    type: ROLES_DELETE_SUCCESS,
                    data: data.data.roleDelete,
                    message: data.responseMsg,
                });
                if (callbackFun) callbackFun(data.data.roleDelete);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, ROLES_DELETE_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, ROLES_DELETE_FAILED, dispatch)

        }
    };
;


export const getUserById = id => {
    return dispatch => {
      return Axios
        .get(`auth/view/${id}`)
        .then(response => {
          if (response?.status === 200) {
            return Promise.resolve(response);
          }
        })
        .catch(err => {
            ErrorCatch(ErrorOutline, ROLES_LIST_FAILED, dispatch)
          return Promise.resolve(err);
        });
    };
  };


  export const updatePermission = data => {
    return dispatch => {
      return Axios
        // .post('auth/update',data)  
        .post('auth/updatepermission',data)
        .then(response => {
          if (response?.status === 200) {
            return Promise.resolve(response);
          }
        })
        .catch(err => {
            ErrorCatch(ErrorOutline, ROLES_LIST_FAILED, dispatch)
          return Promise.resolve(err);
        });
    };
  };