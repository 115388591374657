// import { Box } from '@material-ui/core';
// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchProductMaterialGradeList, setProductDetails } from 'redux/actions/Manage/Suppliers/Suppliers';

// import { useParams } from 'react-router';




// const Material = ({onMaterialSelect,productId}) => {
//   console.log(productId,"material.js")
//   const { id } = useParams();
//   const dispatch = useDispatch();

//   // useSelector
//   const {materials } = useSelector(
//     state => state.SetProductMaterialGradeList,
//   );
//   const { supplierProductList } = useSelector(state => state.SetSupplierDetail);
//   // console.log(supplierProductList,"material.js 21") 
//   // useState
//   const [selectedMaterial, setSelectedMaterial] = useState(null);
//     // console.log(selectedMaterial,"Child component")
  

//   //  Funtions
//   const selectMaterial = data => {
//     setSelectedMaterial(data.materialId);
//     onMaterialSelect(data.materialId)
//   };
  
//   // materials which are already present
//   const isMaterialInResponse = (materialId) => {
//     return supplierProductList?.some(product => product.productId === productId && product.materialId === materialId);
//   };
// // Grade count
// const countGrades = (materialId) => {
//   return supplierProductList?.reduce((count, product) => {
//     if (product.productId === productId && product.materialId === materialId) {
//       return count + 1;
//     }
//     return count;
//   }, 0);
// };

// //  all useEffect
//   useEffect(() => {
//     dispatch(fetchProductMaterialGradeList());
//     dispatch(setProductDetails(id));
//   }, []);
//   return (
//     <>
//       <Box>
//         <Box p={2}>
//           {materials && materials?.map((data, index) => {
//             const materialId = data.materialId;
//             const gradeCount = countGrades(materialId);
//             return (
//               <Box
//                 key={index}
//                 style={{
//                   cursor: 'pointer',
//                   backgroundColor:selectedMaterial===data.materialId ? '#D9F7FF':isMaterialInResponse(materialId)
//                     ? '#D9F7FF'
//                     : 'white',
//                 }}
//                 onClick={() => selectMaterial(data)}
//               >
//                 <Box
//                   display={'flex'}
//                   justifyContent="space-between"
//                   alignItems="center"
//                 >
//                   <Box p={2}>{data.name}</Box>
//                   <Box>{gradeCount == 0 ? '' : gradeCount}</Box>
//                 </Box>
//               </Box>
//             );
//           })}
//         </Box>
//       </Box>
//     </>
//   );
// };

// export default Material;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { fetchProductMaterialGradeList, setProductDetails } from 'redux/actions/Manage/Suppliers/Suppliers';
import { useParams } from 'react-router';

const Material = ({ onMaterialSelect, productId, supplierProducts }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { materials } = useSelector(state => state.SetProductMaterialGradeList);
  // const { supplierProductList } = useSelector(state => state.SetSupplierDetail);

  const [selectedMaterial, setSelectedMaterial] = useState(null);

  const selectMaterial = data => {
    setSelectedMaterial(data.materialId);
    onMaterialSelect(data.materialId);
  };

  const isMaterialInResponse = materialId => {
    return supplierProducts?.some(
      product => product.productId === productId && product.materialId === materialId
    );
  };

  const countGrades = materialId => {
    return supplierProducts?.reduce((count, product) => {
      if (product.productId === productId && product.materialId === materialId) {
        return count + 1;
      }
      return count;
    }, 0);
  };

  useEffect(() => {
    dispatch(fetchProductMaterialGradeList());
    dispatch(setProductDetails(id));
  }, []);

  return (
    <Box >
      <Box bgcolor={'black'} color={'white'} textAlign={'center'} p={3}  zIndex='1000'>
        Materials
      </Box>
      <Box p={2} height={'68vh'} overflow={'auto'} >
        {materials &&
          materials.map((data, index) => {
            const materialId = data.materialId;
            const gradeCount = countGrades(materialId);
            return (
              <Box
                key={index}
                style={{
                  cursor: 'pointer',
                  backgroundColor:
                    selectedMaterial === data.materialId
                      ? '#D9F7FF'
                      : isMaterialInResponse(materialId)
                      ? '#D9F7FF'
                      : 'white',
                }}
                onClick={() => selectMaterial(data)}
              >
                <Box
                  display={'flex'}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box p={2}>{data.name}</Box>
                  <Box>{gradeCount === 0 ? '' : gradeCount}</Box>
                </Box>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default Material;
