import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Chip, Dialog, DialogContent, DialogTitle, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import GridContainer from '@jumbo/components/GridContainer';
import { isValidPassword } from '@jumbo/utils/commonHelper';
import IntlMessages from '@jumbo/utils/IntlMessages';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import CustomInput from 'components/CustomInput';
import CustomModal from 'components/CustomModal';
import { useHistory } from 'react-router';
import { USER_PASSWORD_UPDATE_SUCCESS } from 'redux/actions';
import { getRoleById } from 'redux/actions/Manage/Roles/Roles';
import { setUserDetail, updateUserProfile } from 'redux/actions/Manage/Users/Users';
import ChangePassword from '../Users/ChangePassword';
import TokenService from "services/token.service";

const useStyles = makeStyles(theme => ({
    marginBtn: {
        marginLeft: 10,
        marginTop: 15,
    },
    dropZone: {
        minHeight: '140px',
        fullWidth: 'true',
    },
    previewContainer: {
        container: 'true',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    preview: {
        xs: '12',
    },
}));

const breadcrumbs = [
    { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
    { label: <IntlMessages id={'pages.updateProfile'} />, isActive: true },
];

const EditProfile = ({ open, onCloseDialog }) => {

    const { user } = useSelector(({ auth }) => auth.authUser);
    const { role } = useSelector(({ SetRoleDetail }) => SetRoleDetail);
    const classes = useStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [profileData, setProfileData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        active: '',
        salesRepCode: '',
        roleId: '',
        openUploadModal: false,
    });
    const [passwordData, setPasswordData] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    });
    const [roleName, setRoleName] = useState("")
    const [errorText, setErrorText] = useState({});
    const [passwordErrorText, setPasswordErrorText] = useState({});
    const [updateFlag, setUpdateFlag] = useState(false);
    const history = useHistory()
    const [openChgPassDialog, setOpenChgPassDialog] = useState(false);
    const { error, isOpen } = useSelector(state => state.Error)
    const [permissionData] = useState(JSON.parse(localStorage.getItem('permission')));
    const [updatePermission, setUpdatePermission] = useState(false);
    const [chgPassword, setChgPassword] = useState(false);
    const { success } = useSelector(state => state.Success)
    const successAction = useSelector(state => state.Success.action)
    const [Success, setSuccess] = useState(false)
    const [successMsg, setSuccessMsg] = useState("")
    // const { user } = useSelector(({ auth }) => auth);
    const getPermission = useCallback(() => {
        permissionData &&
            permissionData.forEach(function (num) {
                if (user?.roleId?.roleSlug === 'admin' || num.name === 'user-profile-update') {
                    setUpdatePermission(true);
                }
                if (user?.roleId?.roleSlug === 'admin' || num.name === 'user-change-password') {
                    setChgPassword(true);
                }
            });
    }, [permissionData, user]);
    const handleChange = evt => {
        setProfileData({ ...profileData, [evt.target.name]: evt.target.value });
    };

    const handleChangePassword = evt => {
        setPasswordData({ ...passwordData, [evt.target.name]: evt.target.value });
    };

    const validatePassword = () => {
        const { oldPassword, newPassword, confirmPassword } = passwordData;
        const errors = {};
        let isError = false;

        if (!oldPassword) {
            errors.oldPassword = 'Please enter current password.';
            isError = true;
        } else if (isValidPassword(oldPassword)) {
            errors.oldPassword =
                'Password must include at least 8 characters and contain at least 1 lowercase & 1 uppercase letter, a number and special symbols.';
            isError = true;
        }
        if (!newPassword) {
            errors.newPassword = 'Please enter new  password.';
            isError = true;
        } else if (isValidPassword(newPassword)) {
            errors.newPassword =
                'Password must include at least 8 characters and contain at least 1 lowercase & 1 uppercase letter, a number and special symbols.';
            isError = true;
        } else if (newPassword === oldPassword) {
            errors.newPassword = 'New password and current password must be different.';
            isError = true;
        }
        if (!confirmPassword) {
            errors.confirmPassword = 'Please enter confirm password.';
            isError = true;
        } else if (isValidPassword(confirmPassword)) {
            errors.confirmPassword =
                'Password must include at least 8 characters and contain at least 1 lowercase & 1 uppercase letter, a number and special symbols.';
            isError = true;
        } else if (confirmPassword !== newPassword) {
            errors.confirmPassword = 'Passwords do not match.';
            isError = true;
        }
        setPasswordErrorText(errors);
        return {
            errors,
            isError,
        };
    };

    const handleSubmitPassword = async () => {
        const { oldPassword, newPassword, confirmPassword } = passwordData;
        let validateData = validatePassword();
        if (!validateData.isError) {
            const newPasswordData = {
                current_password: oldPassword,
                new_password: newPassword,
                confirm_password: confirmPassword,
            };
        }
    };
    const validate = () => {
        const { firstName, lastName } = profileData;
        const errors = {};
        let isError = false;


        if (!firstName) {
            errors.firstName = 'Please enter first name.';
            isError = true;
        } else if (!/^[A-Za-z]+$/.test(firstName)) {
            errors.firstName = 'Only letters and no spaces are allowed.';
            isError = true;
        }
        if (!lastName) {
            errors.lastName = 'Please enter last name.';
            isError = true;
        } else if (!/^[A-Za-z]+$/.test(lastName)) {
            errors.lastName = 'Only letters and no spaces are allowed';
            isError = true;
        }
        setErrorText(errors);
        return {
            errors,
            isError,
        };
    };

    const handleSubmit = () => {
        const profileData = { firstName, lastName, roleId, email, active }
        if (user) {
            dispatch(
                updateUserProfile(user?._id, profileData, (data) => {
                    if (data) {
                        TokenService.setUser(data);
                        onCloseDialog()
                        setTimeout(() => {
                            window.location.reload(true);
                        }, 2000);
                    }
                }),
            );
        }
    };
    const handleUserChangePassword = () => {
        dispatch(setUserDetail(user));
        setOpenChgPassDialog(true);
    };
    const handleCloseChgPassDialog = () => {
        dispatch(setUserDetail(null));
        setOpenChgPassDialog(false);
    };
    const toggleModal = () => {
        setPasswordErrorText({});
        setProfileData({ ...profileData, openUploadModal: !profileData.openUploadModal });
    };
    useEffect(() => {
        if (user) {
            const { firstName, lastName, email, roleId, active, salesRepCode } = user
            if (roleId?._id) {
                dispatch(getRoleById(roleId?._id))
                setProfileData({ firstName, lastName, email, roleId: roleId._id, active, salesRepCode })
            }
            else {
                dispatch(getRoleById(roleId))
                setProfileData({ firstName, lastName, email, roleId: roleId, active, salesRepCode })
            }
        }
    }, [user]);
    useEffect(() => {
        if (role) {
            setRoleName(role?.roleName)
        }

    }, [role]);
    useEffect(() => {
        getPermission()

    }, [])
    useEffect(() => {
        if (successAction === USER_PASSWORD_UPDATE_SUCCESS) {
            // dispatch(setAuthUser(user))
            // tokenService.setUser(user)
            window.location.reload(true);
            // setSuccess(true)
            // setSuccessMsg(success)
        }
    }, [successAction])
    // useEffect(() => {
    //     if (!success)
    //         setSuccess(false)
    // }, [success])


    const { firstName, lastName, email, roleId, active, salesRepCode } = profileData
    return (
        <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
            <DialogTitle className={classes.dialogTitleRoot}>{user && 'Edit Profile '}</DialogTitle>
            <DialogContent dividers>
                <>
                    <GridContainer>
                        <Grid item xs={12} md={6}>
                            <Box mb={{ xs: 6, md: 5 }}>
                                <Box component="span" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography>Status:</Typography>
                                    <Tooltip title={active === true ? 'Active' : 'Disable'}>
                                        <Chip
                                            size="small"
                                            label={active === true ? 'Active' : 'Disable'}
                                            // clickable
                                            color="primary"
                                        />
                                    </Tooltip>
                                </Box>
                            </Box>
                            {roleName && <Box mb={{ xs: 6, md: 5 }}>
                                <Box component="span" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography>Role Name :</Typography>
                                    <Tooltip title={roleName}>
                                        <Chip
                                            size="small"
                                            label={roleName}
                                            // clickable
                                            color="primary"
                                        />
                                    </Tooltip>
                                </Box>
                            </Box>}
                            <Box mb={{ xs: 6, md: 5 }}>
                                {salesRepCode && <Box mb={{ xs: 6, md: 5 }}>
                                    <Box component="span" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography>Sales Rep Code :</Typography>
                                        <Tooltip title={salesRepCode}>
                                            <Chip
                                                size="small"
                                                label={salesRepCode}
                                                // clickable
                                                color="primary"
                                            />
                                        </Tooltip>
                                    </Box>
                                </Box>}
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box mb={{ xs: 6, md: 5 }}>
                                <AppTextInput
                                    fullWidth
                                    name="firstName"
                                    variant="outlined"
                                    label="First name"
                                    required
                                    value={firstName}
                                    onChange={handleChange}
                                    helperText={errorText?.firstName}
                                />
                            </Box>
                            <Box mb={{ xs: 6, md: 5 }}>
                                <AppTextInput
                                    fullWidth
                                    name="lastName"
                                    variant="outlined"
                                    label="Last name"
                                    required
                                    value={lastName}
                                    onChange={handleChange}
                                    helperText={errorText?.lastName}
                                />
                            </Box>
                            <Box mb={{ xs: 6, md: 5 }}>
                                <AppTextInput
                                    fullWidth
                                    name="email"
                                    variant="outlined"
                                    label="Email"
                                    disabled={true}
                                    value={email}
                                    onChange={handleChange}
                                    helperText={errorText.email}
                                />
                            </Box>
                        </Grid>
                    </GridContainer>
                    {profileData.openUploadModal && (
                        <CustomModal open={profileData.openUploadModal} onClose={toggleModal} title={'Change Password'}>
                            <CustomInput
                                fullWidth
                                type="password"
                                name="oldPassword"
                                variant="outlined"
                                label="Current password"
                                required
                                onChange={handleChangePassword}
                                helperText={passwordErrorText.oldPassword}
                            />
                            <CustomInput
                                fullWidth
                                type="password"
                                name="newPassword"
                                variant="outlined"
                                label="New password"
                                required
                                onChange={handleChangePassword}
                                helperText={passwordErrorText.newPassword}
                            />
                            <CustomInput
                                fullWidth
                                type="password"
                                name="confirmPassword"
                                variant="outlined"
                                label="Confirm password"
                                required
                                onChange={handleChangePassword}
                                helperText={passwordErrorText.confirmPassword}
                            />
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="btn customer-filter-button"
                                    onClick={handleSubmitPassword}>
                                    Update
                                </Button>
                                <Button variant="contained" color="inherit" className="ml-3" onClick={toggleModal}>
                                    Cancel
                                </Button>
                            </Grid>
                        </CustomModal>
                    )}
                    <Grid item xs={12}>
                        {chgPassword && <Button
                            variant="contained"
                            color="primary"
                            // className="btn customer-filter-button mt-1"
                            className={classes.marginBtn}
                            onClick={handleUserChangePassword}>
                            {/* onClick={toggleModal}> */}
                            Change Password
                        </Button>}
                        {updatePermission && <Button variant="contained" color="primary"
                            // className="btn customer-filter-button mt-1" 
                            className={classes.marginBtn} onClick={handleSubmit}>
                            Update
                        </Button>}
                        <Button
                            variant="contained"
                            color="inherit"
                            className={classes.marginBtn}
                            onClick={() => {
                                // history.push('/dashboard')
                                onCloseDialog()
                            }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </>
                {openChgPassDialog && <ChangePassword currentPasswordBlock={true} open={openChgPassDialog} onCloseDialog={handleCloseChgPassDialog} />}
                {/* {error && isOpen && <AlertBox type='error' style="filled" >{error}</AlertBox>} */}
                {/* {Success && <AlertBox type='success' style="filled" >{successMsg}</AlertBox>} */}

            </DialogContent>
        </Dialog >
    );

};

export default EditProfile;
