import CmtCardMedia from '@coremat/CmtCard/CmtCardMedia';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import { Box, makeStyles, Switch } from '@material-ui/core';
import {
  Add,
  Edit,
  FilterList,
  MoreHoriz,
  Visibility,
  Link
} from '@material-ui/icons';
import CustomTable from 'components/CustomeTable';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MATERIAL_CREATE_SUCCESS,
  MATERIAL_UPDATE_SUCCESS,
} from 'redux/actions';
import {
  deleteMaterial,
  getAllMaterials,
  setMaterialDetail,
  updateMaterial,
} from 'redux/actions/Manage/Materials/Materials';
import config from 'utils/Config.util';
import AddEditMaterial from './AddEditMaterial';
import AddGradeToMaterial from './AddGradeToMaterial';
import ViewGradeList from './ViewGradeList';
import ViewMaterialDetails from './ViewMaterialDetails';
const useStyles = makeStyles(theme => ({
  imageRoot: {
    // width: 150,
    // height: 150,
    width: 100,
    height: 100,
    objectFit: 'cover',
    objectPosition: 'center',
    padding: 0,
  },
}));
const Materials = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  //------------all use selectors
  const { user } = useSelector(({ auth }) => auth.authUser);
  const { materials, total, perPage } = useSelector(state => state.Materials);
  const ProductData = useSelector(state => state.setMaterialDetail);
  const productDetail = ProductData?.user;

  //-------------all use states
  const [orderBy, setOrderBy] = React.useState('name');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState({ name: '' });
  const [materialsFetched, setmaterialsFetched] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [row, setRow] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [permissionData] = useState(
    JSON.parse(localStorage.getItem('permission')),
  );
  const [createPermission, setCreatePermission] = useState(false);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [exportPermission, setExportPermission] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openViewMaterialDetails, setOpenViewMaterialDetails] = useState(false);
  const [openViewGradeList, setopenViewGradeList] = useState(false);
  const [openViewMaterialAdd, setOpenViewMaterialAdd] = useState(false);
  // const [search, setSearch] = useState({ sort: null, field: null });
  const [fileData, setFileData] = useState([]);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [downloadData, setDownloadData] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const successAction = useSelector(state => state.Success.action);

  //-------table config actions
  const getUserActions = user => {
    const actions = [];
    actions.push({ action: 'url', label: 'View Live', icon: <Link /> });
    actions.push({ action: 'view', label: 'View', icon: <Visibility /> });
    actions.push({ action: 'edit', label: 'Edit', icon: <Edit /> });
    // actions.push({
    //   action: 'list_grades',
    //   label: 'Grade List',
    //   icon: <FilterList />,
    // });
    // actions.push({ action: 'add_grades', label: 'Add Grade', icon: <Add /> });
    return actions;
  };
  const userActions = getUserActions(row);
  const onUserMenuClick = (menu, elm) => {
    if (menu.action === "url") {
      // window.open(`https://www.thepipingmart.com/material/${elm.url}`);
      window.open( config.REACT_APP_API_BASE_URL === 'https://api.pipingmart.co.in' ? `http://pipingmart.co.in/material/${elm.code}` : `https://www.thepipingmart.com/material/${elm.code}`)
    }
    if (menu.action === 'view') {
      dispatch(setMaterialDetail(elm));
      setOpenViewMaterialDetails(true);
    } else if (menu.action === 'add_grades') {
      dispatch(setMaterialDetail(elm));
      setOpenViewMaterialAdd(true);
      history.push('/manage-add-material-to-grade');
    } else if (menu.action === 'edit') {
      console.log(elm)
      setOpenUserDialog(true);
      history.push(`/edit-manage-materials/${elm?._id}`);
    } else if (menu.action === 'list_grades') {
      dispatch(setMaterialDetail(elm));
      setopenViewGradeList(true);
    }
  };
  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'logo',
      numeric: false,
      disablePadding: false,
      label: 'Image',
      sort: false,
      render: elm => (
        <Box>
          <CmtCardMedia className={classes.imageRoot} image={elm.logo} />
        </Box>
      ),
    },
    {
      id: 'title',
      numeric: false,
      disablePadding: false,
      label: 'Title',
      sort: false,
    },
    {
      id: 'active',
      numeric: false,
      disablePadding: false,
      sort: false,
      label: 'Status',
      render: elm => (
        <Switch
          checked={elm.active === true ? true : false}
          onChange={() => handleChangeStatusSwitch(elm)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      ),
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Actions',
      sort: false,
      render: elm => (
        <CmtDropdownMenu
          items={userActions}
          onItemClick={menu => onUserMenuClick(menu, elm)}
          TriggerComponent={<MoreHoriz />}
        />
      ),
    },
  ];
  //download file setting
  var fileHeaders = [
    {
      key: 'firstName',
      label: 'Name',
    },
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'roleName',
      label: 'Role',
    },
    {
      key: 'departmentName',
      label: 'Dept',
    },
    {
      key: 'status',
      label: 'Status',
    },
  ];

  //-------all handle functions

  const handleChangeStatusSwitch = elm => {
    let status;
    if (elm.active === true) status = 'false';
    else status = 'true';
    const data = {
      materialId: elm.materialId,
      active: status,
    };

    dispatch(
      updateMaterial(data, data => {
        if (data) history.push('/manage-materials');
      }),
    );
  };

  const handleCloseViewGradeList = () => {
    setopenViewGradeList(false);
    dispatch(setMaterialDetail(null));
  };

  const handleCloseViewMaterialAdd = () => {
    setOpenViewMaterialAdd(false);
    dispatch(setMaterialDetail(null));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleViewDialog = user => {
    dispatch(setMaterialDetail(user));
    setOpenViewDialog(true);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    dispatch(setMaterialDetail(null));
  };

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    dispatch(setMaterialDetail(null));
  };

  const handleAddButton = () => {
    setOpenUserDialog(true);
    dispatch(setMaterialDetail(null));
    history.push('/add-manage-materials');
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    dispatch(
      deleteMaterial(selectedUser._id, data => {
        if (data) setDataUpdated(data => !data);
      }),
    );
  };

  const handleCloseViewMaterialDetails = () => {
    setOpenViewMaterialDetails(false);
    dispatch(setMaterialDetail(null));
  };
  // const handleSearch = dateset_name => {
  //   setPage(0);
  //   setSearchTerm(dateset_name);
  // };

  //------all useEffects
  useEffect(() => {
    let params;
    params = {
      search: searchTerm,
    };

    dispatch(getAllMaterials(params), () => {
      setmaterialsFetched(true);
    });
  }, [dispatch, dataUpdated]);

  useEffect(() => {
    if (
      successAction === MATERIAL_UPDATE_SUCCESS ||
      successAction === MATERIAL_CREATE_SUCCESS
    ) {
      let params;
      params = {
        search: searchTerm,
      };

      dispatch(getAllMaterials(params), () => {
        setmaterialsFetched(true);
      });
    }
  }, [successAction]);
  return (
    <>
      <CustomTable
        TableName="materials"
        downloadTableBtn={exportPermission ? true : false}
        fileName="materials.xlsx"
        fileHeaders={fileHeaders}
        fileData={fileData}
        setIsDownload={setIsDownload}
        downloadData={downloadData}
        row={materials && materials}
        addButtonText={'Add Material'}
        handleAddButton={handleAddButton}
        headCells={headCells}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        count={total || 0}
        rowsPerPage={perPage}
        onPageChange={handlePageChange}
        page={page}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleSearch={(text) => setSearchTerm(text)}
        handleSearchSubmit={() => {
          setPage(0)
          setDataUpdated(!dataUpdated)
        }}
        handleViewDialog={handleViewDialog}
        productDetailModal={{
          emailData: productDetail?.email,
          modalHeading: productDetail?.name,
          headerArray: [
            'MemberType: ' + productDetail?.membertype,
            productDetail?.address && 'Address: ' + productDetail?.address,
          ],
          sectionHeading: 'Contact Details',
          openViewDialog: openViewDialog,
          handleCloseViewDialog: handleCloseViewDialog,
          activeChip: productDetail?.active,
          phoneData: productDetail?.mobileNo,
        }}
      />
      {openUserDialog && (
        <AddEditMaterial
          setDataUpdated={setDataUpdated}
          open={openUserDialog}
          onCloseDialog={handleCloseUserDialog}
        />
      )}
      {openViewMaterialDetails && (
        <ViewMaterialDetails
          open={openViewMaterialDetails}
          onCloseDialog={handleCloseViewMaterialDetails}
        />
      )}
      {openViewGradeList && (
        <ViewGradeList
          open={openViewGradeList}
          onCloseDialog={handleCloseViewGradeList}
        />
      )}
      {openViewMaterialAdd && (
        <AddGradeToMaterial
          setDataUpdated={setDataUpdated}
          history={history}
          open={openViewMaterialAdd}
          onCloseDialog={handleCloseViewMaterialAdd}
        />
      )}
      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete`}
        content={'Are you sure, you want to  delete this user?'}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};

export default Materials;
