import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle, IconButton, withMobileDialog, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles({
  modalTitle: {
    paddingRight: '48px',
  },
  modalCloseBtn: {
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
  cmDialog: {
    '& .MuiDialog-paper': {
      minHeight: '180px',
    },
  },
});

const CustomModal = ({ title, fullScreen, onClose, open, children, className = '', autoClose = true }) => {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        open={open}
        onClose={autoClose ? onClose : null}
        className={`responsive-dialog ${classes.cmDialog} ${className} `}
        sx={{ width: '100%' }}>
        <DialogTitle className={`responsive-dialog-title ${classes.modalTitle}`}>
          {title}
          <IconButton onClick={onClose} className={`close-btn ${classes.modalCloseBtn}`}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className="responsive-dialog-content">{children || 'children'}</DialogContent>
      </Dialog>
    </div>
  );
};

CustomModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  autoClose: PropTypes.bool,
  fullScreen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default withMobileDialog({ breakpoint: 'xs' })(CustomModal);
