import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import {
  Checkbox,
  FormControlLabel,
  Tooltip,
  Typography,
  Box,
  IconButton,
  Button,
  InputAdornment,
  Dialog,
} from '@material-ui/core';
import {
  Edit,
  Remove,
  Clear,
  Delete,
  Launch,
  Send,
  Photo,
  GetApp,
} from '@material-ui/icons';
import { KeyboardDateTimePicker } from '@material-ui/pickers';

import config from 'utils/Config.util';
import FilePicker from '../FilePicker';
import useStyles from './index.style';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import Chip from '@material-ui/core/Chip';
import { emailNotValid } from '@jumbo/constants/ErrorMessages';
import { isValidEmail, linkify } from '@jumbo/utils/commonHelper';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import WysiwygEditorExample from 'components/wysiwygEditor';

import {
  adminEmailSend,
  approveEmailAndSend,
  forwardEmail,
  getAllEmails,
  getReScheduleEmails,
  reScheduleEmail,
  setEmailListPopUp,
} from 'redux/actions/Manage/Emails/Emails';

const ComposeMail = ({
  openDialog,
  onCloseComposeDialog,
  mailTo,
  mailContent,
  setReloadMailBox,
  reloadMailBox,
}) => {
  const dispatch = useDispatch();

  const { isSideBarCollapsed, pop_up, selectedMail } = useSelector(
    ({ Emails }) => Emails,
  );
  // const { suppliers } = useSelector(({ Suppliers }) => Suppliers);
  // const [addOtherReceivers, setAddOtherReceivers] = useState(false);
  const [scheduleMail, setScheduleMail] = useState(false);
  const [ack, setAck] = useState(false);
  const [email, setEmail] = useState('');
  const [fromEmail, setFromEmail] = useState('');
  const [to, setTo] = useState([]);
  const [from, setFrom] = useState([]);
  const [message, setMessage] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [forwardAttachment, setForwardAttachment] = useState([]);
  const [minimize, setMinimize] = useState(false);

  const [toError, setToError] = useState('');
  const [fromError, setFromError] = useState('');
  const [maximize, setMaximize] = useState(false);
  const [selectedDate, setSelectedDate] = React.useState(Date.now());

  const handleDateChange = date => {
    setSelectedDate(date);
  };

  const [descriptionHTML, setDescriptionHTML] = useState();
  const [mailDetail, setMailDetail] = useState({
    emailUniqueId: '',
    receiverEmail: '',
    subject: '',
    html: '',
    body: '',
    senderEmail: '',
    setAcknowledge: '',
    scheduleMailStatus: '',
    date: '',
    other_receivers: '',
    emailContent: '',
    emailStatus: '',
    // files:''
  });

  const [errorText, setErrorText] = React.useState({});
  let storageData =
    localStorage.getItem('schedule') !== null
      ? (storageData = localStorage.getItem('schedule'))
      : '';

  let forward =
    localStorage.getItem('forward') !== null
      ? (forward = localStorage.getItem('forward'))
      : '';

  const validate = () => {
    const errors = {};
    let isError = false;
    const scheduleDate = moment(selectedDate).format('DD/MM/YYYY hh:mm:ss');
    const currentTime = moment().format('DD/MM/YYYY hh:mm:ss');
    const {
      subject,
      body,
      // html,
      setAcknowledge,
      scheduleMailStatus,
      date,
      other_receivers,
    } = mailDetail;
    if (scheduleDate < currentTime && scheduleMail) {
      errors.schedule = 'Invalid date and time.';
      isError = true;
    }
    if (storageData && scheduleDate < currentTime) {
      errors.schedule = 'Please select date and time.';
      isError = true;
    }

    if (subject.length === 0) {
      errors.subject = 'Please enter subject.';
      isError = true;
    }
    if (to.length === 0) {
      setToError('Please enter to field.');
      isError = true;
    } else if (!to.every(item => isValidEmail(item))) {
      setToError(emailNotValid);
      isError = true;
    }
    if (from.length === 0) {
      setFromError('Please enter from field.');
      isError = true;
    } else if (!from.every(item => isValidEmail(item))) {
      setFromError(emailNotValid);
      isError = true;
    }
    // if (body.length === 0) {
    //   errors.body = 'Please enter mail body.';
    //   isError = true;
    // }
    // if (scheduleMailStatus && !date) {
    //   errors.date = 'Please select a date to schedule email.';
    //   isError = true;
    // }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };

  const getHeaderTitle = () => (
    <Box display="flex" alignItems="center">
      <Edit />
      <Box component="span" fontSize={{ xs: 14, sm: 16 }} ml={{ xs: 4, xl: 6 }}>
        Compose Message
      </Box>
    </Box>
  );

  const onAddAttachments = files => {
    const newAttachments = files.map(item => {
      return {
        id: item.id,
        originalFile: item.file,
        file: {
          name: item.file.name,
          type: item.file.type,
          size: item.file.size,
          path: item.file.path,
        },
      };
    });

    setAttachments([...attachments, ...newAttachments]);
  };

  const onDeleteAttachments = index => {
    const updatedAttachments = attachments.filter((item, i) => i !== index);
    setAttachments(updatedAttachments);
  };

  const addTo = () => {
    if (email.trim()) {
      if (!to.length) {
        setTo(to => [...to, email]);
        setEmail('');
      } else {
        if (to.indexOf(email) !== -1) {
          setToError('Email id is already exist');
        } else {
          setTo(to => [...to, email]);
          setEmail('');
        }
      }
    }
  };

  const addFrom = () => {
    if (fromEmail.trim()) {
      setFrom(from => [...from, fromEmail]);
      setFromEmail('');
    }
  };

  // const onKeyPress = e => {
  //   if (e.keyCode === 32 || e.keyCode === 13) {
  //     addTo();
  //   }
  // };

  // const onKeyPressFrom = e => {
  //   if (e.keyCode === 32 || e.keyCode === 13) {
  //     addFrom();
  //   }
  // };

  const filterTo = email => {
    const updatedList = to.filter(item => item !== email);
    setTo(updatedList);
    setToError('');
  };

  const filterFrom = email => {
    const updatedList = from.filter(item => item !== email);
    setFrom(updatedList);
    setFromError('');
  };

  // const onPickEmoji = emoji => {
  //   setMessage(message + emoji);
  // };

  const onDiscardMail = () => {
    localStorage.removeItem('forward');
    localStorage.removeItem('schedule');
    onCloseComposeDialog();
  };

  const handleMaximize = () => {
    if (!maximize) {
      setMaximize(true);
      setMinimize(false);
    } else {
      setMaximize(false);
    }
  };

  const handleMinimize = () => {
    if (!minimize) {
      setMaximize(false);
      setMinimize(true);
    } else {
      setMinimize(false);
    }
  };

  const checkValidations = () => {
    let isValidate = validate();
    if (!isValidate.isError && !toError) handleSubmit();
  };

  useEffect(() => {
    if (pop_up && pop_up.selectedEmails && pop_up.selectedEmails.length > 0) {
      if (selectedMail) {
        if (!to.length) {
          let supplierEmails = [];
          pop_up.selectedEmails &&
            pop_up.selectedEmails.map(item => {
              if (!supplierEmails.includes(item)) {
                supplierEmails.push(item);
              }
            });
          setTo(to => [...to, ...supplierEmails]);
        } else {
          let concatValue = to.concat(pop_up.selectedEmails);
          const supplierEmail = concatValue.filter(
            (item, index) => concatValue.indexOf(item) === index,
          );
          setTo(supplierEmail);
        }
      } else {
        setTo(pop_up.selectedEmails);
      }
    }
  }, [pop_up]);

  useEffect(() => {
    if (selectedMail) {
      const {
        emailContent,
        _id,
        receiverEmail,
        senderEmail,
        emailType,
        emailStatus,
      } = selectedMail;

      if (localStorage.getItem('forward') !== null) {
        setForwardAttachment(emailContent?.attachments);
      }
      if (
        (emailStatus === 'scheduled' && !forward) ||
        (emailType !== 'enquiry' && !forward)
      ) {
        setTo(to => [...to, receiverEmail]);
        setForwardAttachment([]);
      }

      setFrom(to => [...to, senderEmail]);
      setMailDetail({
        emailUniqueId: _id,
        senderEmail,
        receiverEmail:
          emailType !== 'enquiry' && receiverEmail && receiverEmail.length > 0
            ? receiverEmail
            : '',
        subject: emailContent.subject,
        html: emailContent.html,
        body: emailContent.body,
        emailContent,
        emailStatus,
      });
      setDescriptionHTML(emailContent.html);
    }
  }, [selectedMail]);

  const onLabelChange = evt => {
    setMailDetail({ ...mailDetail, [evt.target.name]: evt.target.value });
  };

  const handleOnAddSupplier = () => {
    dispatch(
      setEmailListPopUp({
        status: true,
        emailList: [],
      }),
    );
  };

  const convertHtmlToText = html => {
    const htmlBlock = convertFromHTML(html);
    const text = ContentState.createFromBlockArray(htmlBlock);
    const newText = EditorState.createWithContent(text);
    return newText.getCurrentContent().getPlainText('\u0001');
  };

  const handleGetData = () => {
    dispatch(getReScheduleEmails({ page: 0 }));
    dispatch(getAllEmails({ page: 0 }));
  };

  const handleSubmit = () => {
    const body = convertHtmlToText(message);
    let otherReceiversIds;
    const [firstElement, ...restArr] = to;
    otherReceiversIds = restArr;
    const newMailDetail = {
      emailUniqueId,
      receiverEmail:
        selectedMail &&
          selectedMail.emailType !== 'enquiry' &&
          receiverEmail.length > 0
          ? receiverEmail
          : firstElement,
      subject,
      html: message,
      body: body,
      scheduleMailStatus: scheduleMail,
      date: scheduleMail ? selectedDate : '',
      other_receivers: otherReceiversIds,
      setAcknowledge: ack,
      attachments,
    };

    let formData = new FormData();
    formData.append('emailUniqueId', emailUniqueId);
    formData.append('receiverEmail', newMailDetail.receiverEmail);
    formData.append('subject', subject);
    // formData.append('html', emailUniqueId ? mailDetail?.html : message);
    formData.append('html', descriptionHTML);
    formData.append('body', mailDetail ? mailDetail?.body : body);
    formData.append('scheduleMailStatus', scheduleMail);
    formData.append('date', newMailDetail.date);
    for (let i = 0; i < otherReceiversIds.length; i++) {
      formData.append('other_receivers', otherReceiversIds[i]);
    }
    formData.append('senderEmail', from[0]);
    formData.append('setAcknowledge', ack);
    for (let i = 0; i < attachments.length; i++) {
      formData.append('files', attachments[i]?.originalFile);
    }

    for (let ii = 0; ii < forwardAttachment.length; ii++) {
      formData.append('files', forwardAttachment[ii]?.fieldname);
      formData.append('files', forwardAttachment[ii]?.originalname);
      formData.append('files', forwardAttachment[ii]?.encoding);
      formData.append('files', forwardAttachment[ii]?.mimetype);
      formData.append('files', forwardAttachment[ii]?.destination);
      formData.append('files', forwardAttachment[ii]?.filename);
      formData.append('files', forwardAttachment[ii]?.path);
      formData.append('files', forwardAttachment[ii]?.size);
    }

    if (storageData) {
      localStorage.removeItem('schedule');
      const paramData = { nextExecutionTime: selectedDate?._d };
      dispatch(reScheduleEmail(newMailDetail?.emailUniqueId, paramData)).then(
        res => {
          if (res?.status === 200) {
            setReloadMailBox(!reloadMailBox);
            onDiscardMail();
          }
        },
      );
    } else {
      if (forward) {
        dispatch(forwardEmail(formData)).then(responseData => {
          if (responseData?.status === 200) {
            setReloadMailBox(!reloadMailBox);
            onDiscardMail();
          }
        });
      } else if (selectedMail) {
        dispatch(
          approveEmailAndSend(formData, data => {
            if (data) {
              setReloadMailBox(!reloadMailBox);
              onDiscardMail();
            }
          }),
        );
      } else {
        dispatch(
          adminEmailSend(formData, data => {
            if (data) {
              setReloadMailBox(!reloadMailBox);
              onDiscardMail();
            }
          }),
        );
      }
    }
  };

  const downloadAttachment = () => { };

  const onShowAttachments = attachments => {
    return (
      <Box className={classes.attachRoot}>
        {attachments.map((item, index) => {
          let filePath =
            process.env.NODE_ENV === 'production'
              ? item?.path.split('/')
              : item?.path.split('\\');
          return (
            <>
              {/* <Link to={encodeURI(config.REACT_APP_API_BASE_URL + '/' + item.filename)} className="btn btn-primary"> */}
              <a
                href={encodeURI(
                  config.REACT_APP_API_BASE_URL + '/' + filePath[1],
                )}
                target="_blank"
                rel="noreferrer"
              >
                <Chip
                  label={filePath[1]}
                  key={index}
                  icon={<Photo />}
                  deleteIcon={<GetApp />}
                  onDelete={() => downloadAttachment(item)}
                />
              </a>
              {/* </Link> */}
              {/* <Box  > */}
              {/* <CmtCardMedia className={classes.imageRoot} image={encodeURI(config.REACT_APP_API_BASE_URL + '/' + item.filename)} /> */}
              {/* </Box> */}
            </>
          );
        })}
      </Box>
    );
  };

  const classes = useStyles();
  const {
    emailUniqueId,
    body,
    html,
    subject,
    receiverEmail,
    date,
    setAcknowledge,
    scheduleMailStatus,
    other_receivers,
    senderEmail,
    emailType,
    emailStatus,
    emailContent,
  } = mailDetail;
  return (
    <Dialog
      fullScreen
      open={openDialog}
      onClose={() => onDiscardMail()}
      className={clsx(
        classes.composeMail,
        openDialog ? `open ${maximize ? 'card-full-screen' : ''}` : '',
      )}
    >
      <CmtCard
        className={clsx(classes.componseCard, minimize ? 'card-minimise' : '')}
      >
        <CmtCardHeader
          className={classes.composeHeader}
          title={getHeaderTitle()}
        >
          <Box display="flex" alignItems="center">
            <IconButton
              className={classes.composeHeaderBtn}
              onClick={handleMinimize}
            >
              <Remove />
            </IconButton>
            <IconButton
              className={classes.composeHeaderBtn}
              onClick={handleMaximize}
            >
              <Launch />
            </IconButton>
            <IconButton
              className={classes.composeHeaderBtn}
              // onClick={onDiscardMail}
              onClick={onDiscardMail}
            >
              <Clear />
            </IconButton>
          </Box>
        </CmtCardHeader>

        <CmtCardContent>
          <PerfectScrollbar className={classes.scrollbarRoot}>
            <Box height={1} display="flex" flexDirection="column">
              <Box>
                <Box mb={3} ml={0} width="1">
                  <Box ml={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleOnAddSupplier}
                    >
                      Add Supplier
                    </Button>
                  </Box>
                </Box>
                <Box
                  mb={3}
                  width="1"
                  display={'flex'}
                  justifyContent={'space-between'}
                >
                  <FormControlLabel
                    style={{
                      marginRight: '1rem',
                      marginLeft: 0,
                      fontSize: '14 !important',
                    }}
                    value="ack"
                    control={
                      <Checkbox
                        checked={ack}
                        onChange={() => setAck(prev => !prev)}
                      />
                    }
                    label="Send Acknowledge"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    style={{ marginRight: '1rem', marginLeft: 0 }}
                    value="scheduleMail"
                    control={
                      <Checkbox
                        checked={scheduleMail}
                        onChange={() => setScheduleMail(prev => !prev)}
                      />
                    }
                    label="Schedule Email"
                    labelPlacement="start"
                  />
                  <Box className={classes?.dateTimePickerContainer}>
                    <KeyboardDateTimePicker
                      disabled={scheduleMail ? false : true}
                      variant="inline"
                      ampm={false}
                      label="With keyboard"
                      value={selectedDate}
                      onChange={handleDateChange}
                      // onError={console.log}
                      disablePast
                      format="YYYY/MM/DD HH:mm"
                    />
                    {errorText?.schedule && (
                      <span className={classes?.errorCk}>
                        {errorText?.schedule}
                      </span>
                    )}
                  </Box>
                </Box>

                <Box mb={3} width="1">
                  <Box className={classes.toTextRoot} component="span">
                    From
                  </Box>
                  {from.length > 0 &&
                    from.map((item, key) => (
                      <Chip
                        // disabled={selectedMail && key === 0 ? true : false}
                        label={item}
                        color={isValidEmail(item) ? '' : 'secondary'}
                        onDelete={() => filterFrom(item)}
                        className="mb-2 mr-2"
                      />
                    ))}
                  {from && from.length < 1 && (
                    <AppTextInput
                      className={clsx(classes.textField, 'to-input-type')}
                      value={fromEmail}
                      onChange={e => {
                        setFromEmail(e.target.value);
                        setFromError('');
                      }}
                      helperText={fromError}
                      required
                      // onKeyDown={onKeyPressFrom}
                      onKeyDown={e =>
                        e.keyCode === 32 || (e.keyCode === 13 && addFrom())
                      }
                      onBlur={() => addFrom()}
                    />
                  )}
                </Box>
                <Box mb={3} width="1">
                  <Box className={classes.toTextRoot} component="span">
                    To
                  </Box>
                  {to.length > 0 &&
                    to.map((item, key) => (
                      <Chip
                        // disabled={
                        //   selectedMail &&
                        //   selectedMail.emailType !== 'enquiry' &&
                        //   key === 0
                        //     ? true
                        //     : false
                        // }
                        label={item}
                        color={isValidEmail(item) ? '' : 'secondary'}
                        onDelete={() => filterTo(item)}
                        className="mb-2 mr-2"
                      />
                    ))}
                  <AppTextInput
                    className={clsx(classes.textField, 'to-input-type')}
                    value={email}
                    onChange={e => {
                      setEmail(e.target.value);
                      setToError('');
                    }}
                    helperText={toError}
                    required
                    // onKeyDown={onKeyPress}
                    onKeyDown={e =>
                      e.keyCode === 32 || (e.keyCode === 13 && addTo())
                    }
                    onBlur={() => addTo()}
                  />
                </Box>
                <Box mb={3} width="1">
                  <AppTextInput
                    fullWidth
                    name="subject"
                    variant="outlined"
                    label="Subject"
                    value={subject}
                    onChange={onLabelChange}
                    helperText={errorText?.subject}
                  />
                </Box>
                <Box mb={3} className={classes.textField}>
                  <WysiwygEditorExample
                    defaultDescription={html}
                    descriptionHTML={descriptionHTML}
                    setDescriptionHTML={setDescriptionHTML}
                    component="p"
                    dangerouslySetInnerHTML={{
                      __html: linkify(
                        emailContent?.raw?.text
                          ? emailContent?.raw?.text.replace(/(?:\r\n|\r|\n)/g, '<br />')
                          : emailContent?.html.replace(/(?:\r\n|\r|\n)/g, '<br />')
                      ),
                    }}
                  />

                </Box>
              </Box>

              {emailContent.attachments &&
                emailContent.attachments.length > 0 &&
                onShowAttachments(emailContent.attachments)}
              {attachments.length > 0 && (
                <Box display="flex" alignItems="center" flexWrap="wrap" mb={2}>
                  {attachments.map((item, index) => (
                    <Box mr={2} mb={2}>
                      <Chip
                        label={item.file.name}
                        key={index}
                        onDelete={() => onDeleteAttachments(index)}
                        className="mb-2 mr-2"
                      />
                    </Box>
                  ))}
                </Box>
              )}

              <Box mt="auto" display="flex" alignItems="center">
                <Button
                  className={classes.btnRoot}
                  variant="contained"
                  color="primary"
                  onClick={checkValidations}
                >
                  <Send /> Send
                </Button>

                {emailStatus !== 'scheduled' && (
                  <Box ml={2} className={classes.filePickerRoot}>
                    <FilePicker onAddAttachments={onAddAttachments} />
                  </Box>
                )}
                <Box ml="auto">
                  <Tooltip title="Delete">
                    {/* <IconButton onClick={onDiscardMail}> */}
                    <IconButton onClick={onDiscardMail}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          </PerfectScrollbar>
        </CmtCardContent>
      </CmtCard>
    </Dialog>
  );
};

export default ComposeMail;

ComposeMail.prototype = {
  openDialog: PropTypes.bool.isRequired,
  onCloseComposeDialog: PropTypes.func,
  mailTo: PropTypes.object,
  mailContent: PropTypes.string,
};

ComposeMail.defaultProps = {
  mailTo: null,
  mailContent: '',
};
