import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, withWidth } from '@material-ui/core';
import useStyles from '../index.style';
import MailCell from './MailCell';
import CmtList from '@coremat/CmtList';
import {
  getAllEmails,
  getReScheduleEmails,
} from 'redux/actions/Manage/Emails/Emails';
import ListHeader from './ListHeader';
import PropTypes from 'prop-types';
import { getMailContainerHeight } from '@jumbo/constants/AppConstants';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import ListEmptyResult from '@coremat/CmtList/ListEmptyResult';
import EmptyMailsResult from './EmptyMailsResult';

const MailsList = ({
  labelsList,
  width,
  onClickSendMail,
  onClickForwardMail,
  viewMode,
  reloadMailBox,
}) => {
  const { showFooter } = useContext(AppContext);
  const dispatch = useDispatch();
  const { mailsList, filterType } = useSelector(({ Emails }) => Emails);

  const { loading } = useSelector(({ common }) => common);
  const [checkedMails, setCheckedMails] = useState([]);
  const [scheduleEmailList, setScheduleEmailList] = useState([]);
  const totalMails = useMemo(() => mailsList.length, [mailsList]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    let params = {
      status: filterType.selectedFolder,
      emailType: filterType.selectedLabel,
      search: filterType.searchText,
      page: filterType.page,
    };
    if (filterType.selectedFolder === 'sent') {
      params = {
        ...params,
        emailStatus: filterType.selectedFolder,
        status: '',
        page: filterType.page,
      };
    }
    if (params.status === 're-schedule') {
      dispatch(getReScheduleEmails({ page: filterType.page })).then(res => {
        if (res?.status === 200) {
          setScheduleEmailList(res?.data?.data);
        }
      });
    } else {
      dispatch(getAllEmails(params));
      setScheduleEmailList([]);
    }
  }, [filterType, dispatch, refresh, reloadMailBox]);

  const onChangeCheckedMails = (isChecked, id) => {
    if (isChecked) {
      setCheckedMails(checkedMails.concat(id));
    } else {
      setCheckedMails(checkedMails.filter(mailId => mailId !== id));
    }
  };

  const classes = useStyles({
    height: getMailContainerHeight(width, showFooter),
  });

  return (
    <Box className={classes.inBuildAppMainContent}>
      {totalMails > 0 && (
        <ListHeader
          checkedMails={checkedMails}
          setCheckedMails={setCheckedMails}
        />
      )}
      {totalMails === 0 && <Box style={{ height: 39 }} />}
      <PerfectScrollbar className={classes.perfectScrollbarMailCon}>
        <CmtList
          data={scheduleEmailList.length ? scheduleEmailList : mailsList}
          renderRow={(item, index) => {
            return (
              <MailCell
                key={index}
                mail={item}
                labelsList={labelsList}
                checkedMails={checkedMails}
                onChangeCheckedMails={onChangeCheckedMails}
                onClickSendMail={onClickSendMail}
                onClickForwardMail={onClickForwardMail}
                viewMode={viewMode}
                setRefresh={setRefresh}
                refresh={refresh}
                canceled={item?.isCanceled ? true : false}
              />
            );
          }}
          ListEmptyComponent={
            <ListEmptyResult loader={loading}>
              <EmptyMailsResult />
            </ListEmptyResult>
          }
        />

        {/* <CmtList
          data={scheduleEmailList.length ? scheduleEmailList : mailsList}
          renderRow={(item, index) => (
            <MailCell
              key={index}
              mail={item}
              labelsList={labelsList}
              checkedMails={checkedMails}
              onChangeCheckedMails={onChangeCheckedMails}
              onClickSendMail={onClickSendMail}
              onClickForwardMail={onClickForwardMail}
              viewMode={viewMode}
              setRefresh={setRefresh}
            />
          )}
          ListEmptyComponent={
            <ListEmptyResult loader={loading}>
              <EmptyMailsResult />
            </ListEmptyResult>
          }
        /> */}
      </PerfectScrollbar>
    </Box>
  );
};

export default withWidth()(MailsList);

MailsList.prototype = {
  onClickSendMail: PropTypes.func,
  onClickForwardMail: PropTypes.func,
  viewMode: PropTypes.string,
};

MailsList.defaultProps = {
  viewMode: 'detail',
};
