import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CmtCard from '@coremat/CmtCard';
import CmtCardMedia from '@coremat/CmtCard/CmtCardMedia';
import { Chip, Divider, Grid, IconButton, Tooltip } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import About from 'components/About';
import Contact from 'components/Contact';
import { Title } from '@material-ui/icons';
import Biography from 'components/Biography';
import Gallery from 'components/Gallery';
import Certificate from 'components/Certificate';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
    '& .MuiDialog-paperWidthLg': {
      width: '100%',
    },
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,

      color: theme.palette.common.dark,
    },
  },
  titleRoot: {
    fontSize: 16,
    marginLeft: 12,
    fontWeight: theme.typography.fontWeightBold,
  },
  tagListRoot: {
    color: theme.palette.text.disabled,
    padding: '0 3px 0 0',
    letterSpacing: 0.4,
    fontSize: 12,
    width: 'auto',
  },
  blockRoot: {
    display: 'block',
    color: theme.palette.text.disabled,
  },
  descriptionBlock: {
    '& p': {
      marginBottom: 16,
      fontSize: 14,
      color: theme.palette.text.secondary,
    },
  },
  linkBtn: {
    cursor: 'pointer',
    textTransform: 'uppercase',
  },
  imageRoot: {
    width: '100%',
    height: 250,
  },
  badge: {
    position: 'absolute',
    bottom: 15,
    left: 20,
    zIndex: 1,
    fontSize: 12,
    padding: '4px 16px',
    letterSpacing: 0.4,
    borderRadius: 16,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  marginClass: {
    marginX: 5,
  },
}));

const ViewProductDetails = ({ open, onCloseDialog }) => {
  const classes = useStyles();

  //------------all use selectors
  const { supplier } = useSelector(state => state.SetSupplierDetail);
  console.log(supplier,"selected 87")

  //-------------all use states
  const [productData, setproductData] = useState({});
  const [keywords, setkeywords] = useState([]);
  let createDate =""
  if(supplier && supplier?.createdAt){
    createDate = (supplier?.createdAt).split("T")[0];
    console.log(createDate,"createDatecreateDatecreateDate")
  }
  //------all useEffects
  useEffect(() => {
    if (supplier) {
      setproductData(supplier);
    }
  }, []);
  console.log(supplier?.createdAt);
  const getSubTitle = () => (
    <Box
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      color="text.disabled"
      fontSize={12}
      mb={3}
    >
      <Box component="span" mr={2} color="primary.main">
        {supplier.name}
      </Box>
      <Divider orientation="vertical" flexItem />
      <Divider orientation="vertical" flexItem />
      <Box ml={2}>
        <Box component="span" color="text.primary" mr={2}></Box>
        <Box component="span">Description</Box>
      </Box>
    </Box>
  );
  return (
    <Dialog
      open={open}
      onClose={onCloseDialog}
      className={classes.dialogRoot}
      maxWidth="lg"
    >
      <Box mt={1} display="flex" justifyContent="space-between">
        <Box ml={-2}>
          <DialogTitle className={classes.dialogTitleRoot}>
            {supplier && `${supplier?.name}`}
          </DialogTitle>
        </Box>
        <Box ml={2}>
          <IconButton onClick={onCloseDialog}>
            <ClearIcon />
          </IconButton>
        </Box>
      </Box>
      <DialogContent dividers>
       
        {supplier && (
          <Box>
            <Contact
              email={supplier?.email}
              website={supplier?.website}
              phone={supplier?.mobileNo}
            />
            <About
              name={supplier?.name}
              regDate={createDate}
              about={supplier?.about}
              contact={supplier?.contactUs}
              country={supplier?.country}
            />
            <Biography policy={supplier?.qualityPolicy} />
            <Gallery
              gallery={supplier?.productImages}
              factory={supplier?.factoryImages}
            />
            <Certificate certificate={supplier?.certificates} />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ViewProductDetails;

ViewProductDetails.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
