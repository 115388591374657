import Axios from "axios";

import { MAPPING_CREATE_FAILED,MAPPING_CREATE_REQUEST,MAPPING_CREATE_SUCCESS } from "./Constant";
import { MSG_ERR_RESPONDING_SERVER } from "redux/actions/Common/Constant";
import { setLoading } from "redux/actions/Common/LoadingAction";
import { setSuccess } from "redux/actions/Common/SuccessAction";
import { setError } from "redux/actions/Common/ErrorAction";

export const addMapping = data => {
    return dispatch => {
      dispatch(setLoading(MAPPING_CREATE_REQUEST))
      return Axios
        .post('product/mapProductGradeMaterial', data)
        .then(response => {
          if (response?.status === 200) {
            dispatch(setSuccess(data.message, MAPPING_CREATE_SUCCESS))
            return Promise.resolve(response);
          }
        })
        .catch(err => {
            dispatch(setError(err?.data?.message, MAPPING_CREATE_FAILED));
          return Promise.resolve(err);
        });
    };
  };