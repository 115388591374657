import { styled, Switch } from '@material-ui/core';
import { Box, FormControlLabel } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchRfqEmailStatus, updateRfqEmailStatus } from 'redux/actions/Manage/SendRfqEmail';
import ListFreeSupplier from './ListFreeSupplier';

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const SendRfqEmail = () => {
  const sendRfqEmail = useSelector((state) => state?.sendRfqEmail)


  const dispatch = useDispatch();

  const handleChange = function (evt) {
    const status = evt?.target?.checked;
    dispatch(updateRfqEmailStatus(sendRfqEmail?.sendRfqDataStatus?._id, status)).then(() => {
      dispatch(fetchRfqEmailStatus())
    })
  }

  useEffect(() => {
    dispatch(fetchRfqEmailStatus())
  }, [])

  return (
    <Box key={sendRfqEmail?.sendRfqDataStatus?.isRfqEmailSend}>
      <Box sx={{my:2}}>
        <FormControlLabel
          control={
            <Android12Switch
              checked={sendRfqEmail?.sendRfqDataStatus?.isRfqEmailSend}
              onChange={evt =>
                handleChange(evt)
              }
            />
          }
          sx={{ fontWeight: 600 }}
          label={"Sent RFQs Mail"}
        />
      </Box>
      {sendRfqEmail?.sendRfqDataStatus?.isRfqEmailSend && <ListFreeSupplier />}
    </Box>
  )
}

export default SendRfqEmail