import { Box, Button, Checkbox, FormControlLabel, FormGroup, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Axios from 'axios';
import FilterTable from './FilterTable'
// import CustomTable from 'components/CustomeTable'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCard from '@coremat/CmtCard';

const useStyles = makeStyles(theme => ({
    filterTable: {
        width: "300px",
        position: "absolute",
        top: 65,
    },
    root: {
        '& .MuiSvgIcon-root': {
            width: "0.7rem",
            heigth: "0.7rem"
        },
        '& .MuiTableCell-root': {
            padding: 0, // Change the padding value as needed
        },

    }
}))

function FilterData({ header, setHeader, setFilterData, filterData, setNewFilterData }) {
    const [list, setList] = useState([]);
    const [searchTerm, setSearchTerm] = useState("")
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [limit,setlimit] = useState(7);
    const [filterType, setFilterType] = useState('_id');

    const classes = useStyles();

    const handleChange = (e) => {
        const { checked, value } = e.target;
        // console.log(e.target.checked)
        if (!checked) {
            setFilterData({ ...filterData, [header]: filterData[header].filter(e => e !== value) })
            // setIds(ids.filter(e => e !== value))
        } else {
            setFilterData({ ...filterData, [header]: [...filterData[header], value] })
            // setIds([...ids, value])
        }
    }

    const filterTypeFun = () => {
        if (header === "products") {
            setFilterType("productId")
        }
        else if (header === "subproduct") {
            setFilterType("subProductId")
        }
        else if (header === "material") {
            setFilterType("materialId")
        }
    }

    const headCells = [
        {
            id: "name",
            numeric: false,
            disablePadding: false,
            sort: false,
            label: header,
            render: (ele) => {
                return (
                    <FormControlLabel
                        control={
                            <Checkbox name={ele?.name}
                                size="small"
                                value={ele[filterType]}
                                checked={filterData[header].includes(ele[filterType])}
                                onChange={(e) => handleChange(e)} />
                        }
                        label={ele.name}
                    />
                )
            }
        },
    ]

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrderBy(property);
        setOrder(isAsc ? 'desc' : 'asc');
    };

    const handlePageChange = (event, newPage) => {
        console.log(newPage)
        setPage(newPage);
    };

    const onSubmitClick = () => {
        console.log(filterData)
        setNewFilterData(filterData)
        setHeader("")
    }

    useLayoutEffect(() => {
        (
            async () => {
                const data = await Axios.get(`api/v1/admin/allModule/data/${header}?page=${page + 1}&search=${searchTerm}`);
                // const data = await Axios.get(`api/v1/admin/allModule/data/${header}?search=${searchTerm}`);
                setList(data?.data)
                filterTypeFun();
            }
        )()
    }, [header, page])
    return (

        <>
            {/* <PageContainer> */}
            <CmtCard className={classes.filterTable}>
                <Box >
                    <FilterTable
                        // TableName={header}
                        row={list?.data && list?.data}
                        headCells={headCells}
                        isDynamic={true}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        handleSearch={text => setSearchTerm(text)}
                        handleSearchSubmit={async () => {
                            const data = await Axios.get(`api/v1/admin/allModule/data/${header}?page=${page + 1}&limit=${limit}&search=${searchTerm}`);
                            setList(data?.data)
                            setPage(0)
                        }}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        count={list?.length || 0}
                        rowsPerPage={limit}
                        onPageChange={handlePageChange}
                        page={page}
                    />
                    <Box display="flex" justifyContent="flex-end" mb={4}>
                        <Button onClick={() => {
                            setHeader("")
                            setFilterData({...filterData,[header]:[]})
                            console.log(filterData)
                        }} color="secondary"
                            variant="contained">Cancel</Button>
                        <Box ml={2}>
                            <Button variant="contained" color="primary" onClick={onSubmitClick}>
                                Ok
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </CmtCard>
            {/* </PageContainer > */}
        </>
    )
}

export default FilterData