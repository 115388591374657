// errorReducer.js

import { HIDE_ERROR } from "redux/actions/Common/Constant";

const initState = {
    error: null,
    isOpen: false
};

export function ErrorReducer(state = initState, action) {
    const { error } = action;

    if (error) {
        return {
            action: action.action,
            error: action.message,
            isOpen: true
        }
    } else if (action.type === HIDE_ERROR) {
        return initState
    }

    return state;
}