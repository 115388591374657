//-------------users constants------------------
export const USERS_LIST_REQUEST = "USERS_LIST_REQUEST";
export const USERS_LIST_SUCCESS = "USERS_LIST_SUCCESS";
export const USERS_LIST_FAILED = "USERS_LIST_FAILED";
export const DELETE_BULK_USERS_REQUEST = "DELETE_BULK_USERS_REQUEST";
export const DELETE_BULK_USERS_SUCCESS = "DELETE_BULK_USERS_SUCCESS";
export const DELETE_BULK_USERS_FAILED = "DELETE_BULK_USERS_FAILED";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const USER_CREATE_REQUEST = "USER_CREATE_REQUEST";
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_CREATE_FAILED = "USER_CREATE_FAILED";
export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAILED = "USER_UPDATE_FAILED";
export const USER_PROFILE_UPDATE_REQUEST = "USER_PROFILE_UPDATE_REQUEST";
export const USER_PROFILE_UPDATE_SUCCESS = "USER_PROFILE_UPDATE_SUCCESS";
export const USER_PROFILE_UPDATE_FAILED = "USER_PROFILE_UPDATE_FAILED";
export const USER_PASSWORD_UPDATE_REQUEST = "USER_PASSWORD_UPDATE_REQUEST";
export const USER_PASSWORD_UPDATE_SUCCESS = "USER_PASSWORD_UPDATE_SUCCESS";
export const USER_PASSWORD_UPDATE_FAILED = "USER_PASSWORD_UPDATE_FAILED";
export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAILED = "USER_DELETE_FAILED";

export const USER_UPDATE_STATUS_REQUEST = "USER_UPDATE_STATUS_REQUEST";
export const USER_UPDATE_STATUS_SUCCESS = "USER_UPDATE_STATUS_SUCCESS";
export const USER_UPDATE_STATUS_FAILED = "USER_UPDATE_STATUS_FAILED";