import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import PropTypes from 'prop-types';

const CustomInput = ({
  type,
  name,
  id,
  fullWidth,
  size,
  value,
  onChange,
  helperText,
  variant,
  error,
  classes,
  required,
  handleClickShowPassword,
  showPassword,
  multiline,
  onKeyDown,
  onKeyUp,
  rows,
  ...rest
}) => {
  const [show, setShow] = useState(false);

  return (
    <TextField
      type={type === 'password' && show ? 'text' : type}
      name={name}
      required={required}
      {...rest}
      id={id || name}
      size={size}
      fullWidth={fullWidth}
      value={value}
      multiline={multiline}
      rows={rows}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      variant={variant}
      onChange={onChange}
      error={error || helperText !== ''}
      helperText={helperText}
      className={classes}
      InputProps={
        type === 'password'
          ? {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={() => (type === 'password' ? setShow(!show) : null)}>
                  {show ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }
          : { ...rest.InputProps }
      }
    />
  );
};

CustomInput.prototype = {
  type: PropTypes.string,
  name: PropTypes.string,
  classes: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  multiline: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  handleClickShowPassword: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.bool,
  required: PropTypes.bool,
  showPassword: PropTypes.bool,
  rows: PropTypes.number,
};

CustomInput.defaultProps = {
  type: 'text',
  fullWidth: true,
  size: 'small',
  error: false,
  helperText: '',
  required: false,
  classes: 'mb-5',
  showPassword: false,
  multiline: false,
  rows: 4,
};

export default CustomInput;
