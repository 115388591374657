import { DELETE_BULK_PRODUCT_SUCCESS, PRODUCT_CREATE_SUCCESS, PRODUCT_DELETE_SUCCESS, PRODUCT_LIST_SUCCESS, PRODUCT_UPDATE_SUCCESS, SET_PRODUCTELM_DETAILS, SET_PRODUCT_DETAILS } from "redux/actions/index";

const INIT_STATE =
{
    products: [],
    product: null,
    total: 0,
    perPage: 0
    // permissions: null,
}
const INIT_STATE_DETAIL =
{
    productElm:null,
    product: null,
    message:''
}

export const ProductsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case PRODUCT_CREATE_SUCCESS: {
            return {
                ...state,
                product: action.data,
                action: action.type,
                message: action.message,
                products: [action.data, ...state.products]
            };
        }
        case PRODUCT_LIST_SUCCESS: {
            return {
                ...state,
                products: action.data,
                total: action.total,
                action: action.type,
                message: action.message,
                perPage: action.perPage,
            };
        }
        //products: state.products.map(product => (product.id === action.payload.id ? action.payload : product))
        case PRODUCT_UPDATE_SUCCESS: {
            return {
                ...state,
                product: action.data,
                action: action.type,
                message: action.message,
                products: state.products.map(product => (product._id === action.data._id ? action.data : product))
            };
        }
        case PRODUCT_DELETE_SUCCESS: {
            return {
                ...state,
                product: action.data,
                action: action.type,
                message: action.message,
                products: state.products.filter(product => product._id !== action.data._id),
            };
        }
        case DELETE_BULK_PRODUCT_SUCCESS: {
            return {
                ...state,
                ...state,
                action: action.type,
                message: action.message,
                products: state.products.filter(product => !action.data.includes(product.id)),
            };
        }
        default:
            return state;
    }
};
export const SetProductDetailReducer = (state = INIT_STATE_DETAIL, action) => {
    switch (action.type) {
        case SET_PRODUCT_DETAILS:
            return {
                ...state,
                product: action.data,
                message:action.message,
                action: action.type,
            };
        case SET_PRODUCTELM_DETAILS:
            return {
                ...state,
                productElm:action.data
            }
        default:
            return state;
    }
};