import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import ClearIcon from '@material-ui/icons/Clear';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { useHistory } from 'react-router';

import {
  Chip,
  Divider,
  Paper,
  Select,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { Add, Remove } from '@material-ui/icons';
// import { PRODUCT_CREATE_SUCCESS } from 'redux/actions';
import { Typography } from '@mui/material';
// import Dropzone from 'react-dropzone';
// import { DropzoneArea } from 'material-ui-dropzone';
import { uploadFile } from 'redux/actions/FileUpload/FileUpload';
import {
  clearSupplierDetail,
  createSupplier,
  fetchProductMaterialGradeList,
  updateSupplier,
  addproducttosupplier,
  setProductDetails,
  setSupplierDetail,
  deleteproductfromsupplier,
} from 'redux/actions/Manage/Suppliers/Suppliers';
import { Column } from 'react-virtualized';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import Products from './Products';
import Material from './Matreial';
import { useParams } from 'react-router';
import Grades from './Grades';
import Axios from 'axios';
// import { setSupplierDetail } from "../../../../../redux/actions/Manage/Suppliers/Suppliers"

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  formControl: {
    minWidth: 100,
    minHeight:200
  },
  previewContainer: {
    container: 'true',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  preview: {
    xs: '12',
  },
  errorCk: {
    color: '#f44336',
    marginRight: '14px',
    marginTop: '4px',
    fontSize: '0.75rem',
  },
}));

const AddProductToSupplier = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory()
  const {id} = useParams();
  //------------all use selectors
  const { user } = useSelector(({ auth }) => auth.authUser);
  const { supplier } = useSelector(state => state.SetSupplierDetail);
  const { supplierProductList } = useSelector(state => state.SetSupplierDetail);
  const successAction = useSelector(state => state.Success.action);
  const {products,materials,grades} = useSelector(state => state.SetProductMaterialGradeList);
  // console.log(supplierProductList?.length,"products");
  // console.log('supplier:', supplier);

// useState for model
  const [open, setOpen] = useState(false);



  // useState
  const [product, setProduct] = useState("");
  const [material, setMaterial] = useState("");
  const [grade, setGrade] = useState([]);
  const [gradeId, setGradeId] = useState([]);

// all the products of the supplier
  const [supplierProducts,setsupplierProducts] = useState([]);

  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedMaterialId, setSelectedMaterialId] = useState(null);
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [productDetail,setproductDetail] = useState({product:product,material:material,grade:grade})
  const [addEditSelectedCount,setaddEditSelectedCount] = useState(0)
  const [addProduct,setAddProduct] = useState([])
   const [newAddedProducts, setNewAddedProducts] = useState([]);
   const [removedProducts, setRemovedProducts] = useState([]);
   const [maxProductLimitReached, setMaxProductLimitReached] = useState(false);


  console.log(selectedProductId,"Parent component")
  console.log(selectedMaterialId,"Parent component")
  // ##############################################################################################
  
  //-------all handle functions
  // To get the selected product
const handleProductSelect = (productId) => {
  setSelectedProductId(productId);
};
const handleMaterialSelect = (materialId) => {
  setSelectedMaterialId(materialId);
};
const handleAddProduct = ()=>{
  // For adding products
  if(newAddedProducts?.length>0){
    let products = [];
    let userId = supplier?.userId
    
    for(let i=0;i<newAddedProducts.length;i++){
      let productId = newAddedProducts[i]?.productId
      let materialId = newAddedProducts[i]?.materialId
      let gradeId = newAddedProducts[i]?.gradeId

     let productExists = false;
     for (let j = 0; j < products.length; j++) {
       if (
         products[j].productId === productId &&
         products[j].materialId === materialId &&
         products[j].userId === userId
       ) {
         products[j].gradeId.push(gradeId);
         productExists = true;
         break;
       }
     }

     // If the combination doesn't exist, create a new object and push it to the products array
     if (!productExists) {
       products.push({
         productId: productId,
         materialId: materialId,
         gradeId: [gradeId],
         userId: userId
       });
     }
   
    }
    console.log(products, 'productsproductsproductsproductsproductsproductsproducts');
    if (products.length>0){
      for(let i=0;i<products.length;i++){
      dispatch(addproducttosupplier(products[i]));
    } 
    }
  }

  if(removedProducts?.length>0){
    let removed = [];
    let userId = supplier?.userId
    for(let i=0;i<removedProducts.length;i++){
      let productId = removedProducts[i]?.productId
      let materialId = removedProducts[i]?.materialId
      let gradeId = removedProducts[i]?.gradeId
       let productExists = false;
       for (let j = 0; j < removed.length; j++) {
         if (
           removed[j].productId === productId &&
           removed[j].materialId === materialId &&
           removed[j].userId === userId
         ) {
           removed[j].gradeId.push(gradeId);
           productExists = true;
           break;
         }
       }

       // If the combination doesn't exist, create a new object and push it to the products array
       if (!productExists) {
            removed.push({
           productId: productId,
           materialId: materialId,
           gradeId: [gradeId],
           userId: userId,
         });
       }
     
    }
    if (removed.length > 0) {
      for (let i = 0; i < removed.length; i++) {
        dispatch(deleteproductfromsupplier(removed[i]));
      }
    }
  }
  //   // setAddProduct([...addProduct,grade]);
  // setaddEditSelectedCount(addEditSelectedCount+1)
  // console.log("dafsfsfsfsdfsdf")
  // window.location.reload();

}

  const handleCancel = () => {
    history.push('/manage-suppliers');
    dispatch(clearSupplierDetail());
  };

  const onSubmitClick = () => {
    // console.log(material,product,gradeId,supplier?.userId)
     dispatch(addproducttosupplier(supplier?.userId,material,gradeId, product, ));
    history.push('/manage-suppliers');
  };

  // console.log(supplier?.userId)
  //------all useEffects

  // Model functions

  const handleClickOpen = (item) => {
    const updatedRemovedProducts = [];
    const updatedNewAddedProducts = [];
    // removed products
    if (supplierProductList && supplierProducts) {
      supplierProductList.forEach((product) => {
        if (!supplierProducts.some((p) => p.gradeId === product.gradeId)) {
          updatedRemovedProducts.push(product);
        }
      });
    }
    setRemovedProducts(updatedRemovedProducts);

    // new added products
    if (supplierProducts && supplierProductList) {
      supplierProducts.forEach((product) => {
        if (!supplierProductList.some((p) => p.gradeId === product.gradeId)) {
          updatedNewAddedProducts.push(product);
        }
      });
    }
  
    setNewAddedProducts(updatedNewAddedProducts);
  
    setOpen(true);

  };
  const handleClose = () => {
    setOpen(false);
  };

 useEffect(()=>{

  dispatch(fetchProductMaterialGradeList())
  dispatch(setProductDetails(id));
  dispatch(setSupplierDetail(id))
  
  },[])
// useEffect to set supplierProducts 
useEffect(()=>{
  if(supplierProductList){
    setsupplierProducts(supplierProductList)
  }
},[supplierProductList])

  useEffect(()=>{
    setaddEditSelectedCount(supplierProducts?.length)
    if (supplier?.plan?.maxProductListing < addEditSelectedCount) {
      setMaxProductLimitReached(true);
    } else {
      setMaxProductLimitReached(false);
    }
  
  },[supplierProducts,addEditSelectedCount])

  
// console.log(supplierProducts,"supplierProductssupplierProductssupplierProductssupplierProducts")
// alert

  return (
    <PageContainer>
      <Box bgcolor={'#F0F8FF'} p={3} height={'86vh'}>
        <Box display={'flex'} justifyContent={'space-between'}>
        <Box m={2} >
          <Typography margin="1rem">{supplier?.name} Products</Typography>
          {/* <span>
            Selected:{addEditSelectedCount} /{supplier?.plan?.maxProductListing}
          </span> */}
        </Box>
        <Box m={2}>
        {maxProductLimitReached && (
              <Box mt={2} color="red">
                Product  Limit reached
              </Box>
            )}
        </Box>
        <Box  m={2}> <Typography margin="1rem" color={maxProductLimitReached ? 'red' : 'initial'}>Selected:{addEditSelectedCount} /{supplier?.plan?.maxProductListing}</Typography></Box>
        </Box>
        <Box display={'flex'}>
          <Box m={1}  width={'33%'}>
              <Products onProductSelect={handleProductSelect} supplierProducts={supplierProducts} />
          </Box>
          <Box m={1}  width={'33%'}>
              <Material
                onMaterialSelect={handleMaterialSelect}
                productId={selectedProductId}
                supplierProducts={supplierProducts}
              />
          </Box>
          <Box m={1}  width={'33%'}>
           
            {/* <Box bgcolor={'white'} m={1} height={'68vh'} overflow={'auto'}> */}
              {/* Products component */}
              <Grades
                productId={selectedProductId}
                materialId={selectedMaterialId}
                supplierProducts={supplierProducts}
                handleAddProduct={handleAddProduct}
                // setNewAddedProducts={setNewAddedProducts}
                // setRemovedProducts={setRemovedProducts}
                setsupplierProducts={setsupplierProducts}
                supplierProductList={supplierProductList}
              />
            {/* </Box> */}
          </Box>
        </Box>
        
        <Box display="flex" justifyContent="flex-end" mt={4} p={5}>
          <Button onClick={() => history.push('/manage-suppliers')}>
            Cancel
          </Button>
          <Box ml={2}>
            <Button variant="contained" color="primary" onClick={handleClickOpen} disabled={maxProductLimitReached}>
              Update
            </Button>
            
          </Box>
        </Box>
      </Box>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
  <Box mt={2} textAlign="center">
    <DialogTitle id="alert-dialog-title">Confirm Products</DialogTitle>
  </Box>
  <Divider />
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
      <Box mt={2} sx={{
        display: 'flex',
        height: '60vh',
        width: '95vw',
        maxWidth: '95%',
        maxHeight: '70%',
        justifyContent: "space-between"
      }}>
        <Box border="1px solid green" width="48%" textAlign="center" overflow="auto">
          <Typography bgcolor="green">New Added Products-{newAddedProducts?.length}</Typography>
          {/* <div style={{ maxHeight: '100%', overflow: 'auto' }}> */}
            {newAddedProducts?.map((product) => (
              <p key={product.gradeId}>{product.displayName}</p>
            ))}
          {/* </div> */}
        </Box>
        <Box border="1px solid red" width="48%" textAlign="center" overflow="auto">
          <Typography bgcolor="red">Removed Products-{removedProducts?.length}</Typography>
          {/* <Box maxHeight="100%" overflow="auto"> */}
            {removedProducts?.map((product) => (
              <Box textAlign="left" key={product.gradeId}>
                <p>{product.displayName}</p>
              </Box>
            ))}
          {/* </Box> */}
        </Box>
      </Box>
    </DialogContentText>
  </DialogContent>
  <Divider />
  <DialogActions>
    <Box p={3} display="flex">
      <Box p={2}>
        <Button variant="contained" color="primary" onClick={handleClose} autoFocus>
          Cancel
        </Button>
      </Box>
      <Box p={2}>
        <Button variant="contained" color="primary" autoFocus onClick={handleAddProduct}>
          Confirm
        </Button>
      </Box>
    </Box>
  </DialogActions>
</Dialog>
    </PageContainer>
    
  );
};
export default AddProductToSupplier;

AddProductToSupplier.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
