import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import CmtAvatar from '@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import useStyles from './index.style';
import { Chip, Tooltip } from '@material-ui/core';

const CustomDetailView = ({ userDetailModal }) => {
  const classes = useStyles();
  const { avatar, activeChip, profile_pic, emailData, phoneData, sectionHeading, modalHeading, headerArray, children, openViewDialog, handleCloseViewDialog } = userDetailModal
  return (
    <Dialog open={openViewDialog} onClose={handleCloseViewDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          {avatar && <Box className={classes.avatarView} mr={{ xs: 4, md: 6 }}>
            <CmtAvatar size={70} src={profile_pic} alt={avatar} />
          </Box>}
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>{modalHeading}</Typography>
            </Box>
            <Box mt={-1}>
              {/* array as prop */}
              {headerArray && headerArray.length && headerArray.map(elm => <Typography className={classes.subTitleRoot}>{elm}</Typography>)}
              {/* <Typography className={classes.subTitleRoot}>{customerGeneratedId}</Typography>
              <Typography className={classes.subTitleRoot}>{customerRep}</Typography> */}
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          {activeChip && <Box ml={1}>
            <Tooltip title={activeChip === true && 'Active' || activeChip === false && 'Inactive'}>
              {/* <IconButton aria-label="filter list"> */}
              <Chip
                size="small"
                label={activeChip === true ? "Active" : "Inactive"}
                // clickable
                color="primary"
              />
              {/* </IconButton> */}
            </Tooltip>
          </Box>
          }
          <Box ml={1}>
            <IconButton onClick={handleCloseViewDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box mb={5} component="p" color="common.dark">
          {sectionHeading}
        </Box>
        {emailData && <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <EmailIcon />
          <Box ml={5} color="primary.main" component="p" >
            {emailData}
          </Box>
        </Box>}
        {phoneData && <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <PhoneIcon />
          <Box ml={5} color="primary.main" component="p" >
            {phoneData}
          </Box>
        </Box>}
        {/* component as children */}
        {/* <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <PhoneIcon />
          <Box ml={5} color="primary.main" component="p" className="pointer">
            {phoneData}
          </Box>
        </Box> */}
        {children}
      </Box>
    </Dialog>
  );
};

export default CustomDetailView;

CustomDetailView.prototype = {
  openViewDialog: PropTypes.bool.isRequired,
  handleCloseViewDialog: PropTypes.func,
};
