import Axios from 'axios';
import {
  MSG_ERR_RESPONDING_SERVER,
  PER_PAGE_DEFAULT,
} from 'redux/actions/Common/Constant';
import { ErrorCatch } from 'utils/ErrorCatch';
import { setError } from '../../Common/ErrorAction';
import { setLoading } from '../../Common/LoadingAction';
import { setSuccess } from '../../Common/SuccessAction';
import {

  BLOCK_ALL_USER_FAILED,
  BLOCK_ALL_USER_REQUEST,
  BLOCK_ALL_USER_SUCCESS,
  BLOCK_SINGLE_USER_FAILED,
  BLOCK_SINGLE_USER_REQUEST,
  BLOCK_SINGLE_USER_SUCCESS,
  BLOCKED_USERS_LIST_FAILED,
  BLOCKED_USERS_LIST_REQUEST,
  BLOCKED_USERS_LIST_SUCCESS,

} from './Constant';
let RES_MSG = MSG_ERR_RESPONDING_SERVER;


export const getBlockedUsers = (
  params,
  callbackFun,
  isDownload = false,
) => async dispatch => {
  dispatch(setLoading(BLOCKED_USERS_LIST_REQUEST));
  try {

    const response = await Axios.get(`/notify/fetch-blockedemails`);
    const { data } = response;

    if (response.status === 200) {
      if (isDownload) dispatch(setSuccess(null, BLOCKED_USERS_LIST_SUCCESS));
      else {
        dispatch({
          type: BLOCKED_USERS_LIST_SUCCESS,
          data: data.data,
          total: data && data.length,
          message: data.message,
          perPage: PER_PAGE_DEFAULT,
        });
      }
      if (callbackFun) callbackFun(data.data.userList);
    } else {
      dispatch(setError(RES_MSG, BLOCKED_USERS_LIST_FAILED));
    }
  } catch (error) {
    ErrorCatch(error, BLOCKED_USERS_LIST_FAILED, dispatch);
  }
};


export const addBlockEmail = function (data) {
  return async function (dispatch) {
    try {
      dispatch(setLoading());
      const response = await Axios.post('/api/v1/admin/blockEmail/add', data);
      if (response.status === 201) {
        dispatch(setSuccess('Blocked Successfully'));
      }
    } catch (error) {
      console.error(error)
      dispatch(setError(error?.data?.message));
    }
  }
}

export const editBlockEmail = function (id,data) {
  return async function (dispatch) {
    try {
      dispatch(setLoading());
      const response = await Axios.put(`/api/v1/admin/blockEmail/edit/${id}`, data);
      if (response.status === 200) {
        dispatch(setSuccess('Updated Successfully'));
      }
    } catch (error) {
      console.error(error)
      dispatch(setError(error?.data?.message));
    }
  }
}

export const fetchBlockEmail = function (id) {
  return async function (dispatch) {
    try {
      dispatch(setLoading(BLOCK_SINGLE_USER_REQUEST));
      dispatch({ type: BLOCK_SINGLE_USER_REQUEST })
      const response = await Axios.get(`/api/v1/admin/blockEmail/single-list/${id}`);
      if (response.status === 200) {
        dispatch({ type: BLOCK_SINGLE_USER_SUCCESS, payload: { singleBlockEmail: response?.data?.data } })
        return response?.data?.data
      }
    } catch (error) {
      dispatch(setError(error?.data?.message));
      dispatch({ type: BLOCK_SINGLE_USER_FAILED, payload: { errorMessage: error } })
    }
  }
}
export const fetchAllBlockEmail = function (data) {
  return async function (dispatch) {
    try {
      dispatch(setLoading(BLOCK_ALL_USER_REQUEST));
      dispatch({ type: BLOCK_ALL_USER_REQUEST })
      const response = await Axios.get(`/api/v1/admin/blockEmail/list?search=${data?.search || ''}&&page=${data?.page}`);
      if (response.status === 200) {
        dispatch({ type: BLOCK_ALL_USER_SUCCESS, payload: { blockedEmails: response?.data?.data,total:response?.data?.total } })
      }
    } catch (error) {
      dispatch(setError(error?.data?.message));
      dispatch({ type: BLOCK_ALL_USER_FAILED, payload: { errorMessage: error } })
    }
  }
}