import Axios from 'axios';
import { MSG_ERR_RESPONDING_SERVER } from 'redux/actions/Common/Constant';
import { setError } from 'redux/actions/Common/ErrorAction';
import { setLoading } from 'redux/actions/Common/LoadingAction';
import { setSuccess } from 'redux/actions/Common/SuccessAction';

import {
  GRADE_MODAL_LIST_REQUEST,
  GRADE_MODAL_LIST_FAILED,
  GRADE_MODAL_LIST_SUCCESS,
  GRADE_MODAL_CREATE_REQUEST,
  GRADE_MODAL_CREATE_FAILED,
  GRADE_MODAL_CREATE_SUCCESS,
  GRADE_MODAL_UPDATE_REQUEST,
  GRADE_MODAL_UPDATE_FAILED,
  GRADE_MODAL_UPDATE_SUCCESS,
} from './Constant';

export const getGradeModalList = params => {
  return dispatch => {
    dispatch(setLoading(GRADE_MODAL_LIST_REQUEST));
    return Axios.get('api/v1/admin/grade-model/list', { params })
      .then(response => {
        if (response?.status === 200) {
          const { data } = response;
          dispatch({
            type: GRADE_MODAL_LIST_SUCCESS,
            data: data?.data?.gradeList,
            total: data?.data?.total,
            message: data.responseMessage,
            perPage: data?.data?.perPage,
          });
          // dispatch(setSuccess(data.responseMsg, ROLES_DELETE_SUCCESS))
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        dispatch(setError(err?.data?.responseMessage, GRADE_MODAL_LIST_FAILED));
        return Promise.resolve(err);
      });
  };
};
export const getGradeModalDropdown = params => {
  return dispatch => {
    dispatch(setLoading(GRADE_MODAL_LIST_REQUEST));
    return Axios.post('product/grade-from-materialGrade-dropdown', { ...params })
      .then(response => {
        if (response?.status === 200) {
          const { data } = response;
          dispatch({
            type: GRADE_MODAL_LIST_SUCCESS,
            data: data?.data,
            message: data.responseMessage,
          });
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        dispatch(setError(err?.data?.responseMessage, GRADE_MODAL_LIST_FAILED));
        return Promise.resolve(err);
      });
  };
};

export const crateGradeModalList = data => {
  return dispatch => {
    dispatch(setLoading(GRADE_MODAL_CREATE_REQUEST));
    return Axios.post('api/v1/admin/grade-model', data)
      .then(response => {
        if (response?.status === 200) {
          dispatch(setSuccess(data.responseMsg, GRADE_MODAL_CREATE_SUCCESS));
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        dispatch(setError(err?.data?.responseMessage, GRADE_MODAL_CREATE_FAILED));
        return Promise.resolve(err);
      });
  };
};

export const updateGradeModalList = (id, data) => {
  return dispatch => {
    dispatch(setLoading(GRADE_MODAL_UPDATE_REQUEST));
    return Axios.put(`api/v1/admin/grade-model/${id}`, data)
      .then(response => {
        if (response?.status === 200) {
          const { data } = response;
          dispatch({
            type: GRADE_MODAL_UPDATE_SUCCESS,
            data: data?.data?.gradeList,
            message: data.responseMessage,
          });
          dispatch(setSuccess(data.responseMsg, GRADE_MODAL_UPDATE_SUCCESS));
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        dispatch(setError(err?.data?.responseMessage, GRADE_MODAL_UPDATE_FAILED));
        return Promise.resolve(err);
      });
  };
};
