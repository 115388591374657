
//-------------roles constants------------------
export const ROLES_LIST_REQUEST = "ROLES_LIST_REQUEST";
export const ROLES_LIST_SUCCESS = "ROLES_LIST_SUCCESS";
export const ROLES_LIST_FAILED = "ROLES_LIST_FAILED";
// export const ROLES_DETAILS_REQUEST = "ROLES_DETAILS_REQUEST";
// export const ROLES_DETAILS_SUCCESS = "ROLES_DETAILS_SUCCESS";
// export const ROLES_DETAILS_FAILED = "ROLES_DETAILS_FAILED";
export const SET_ROLES_DETAILS = "SET_ROLES_DETAILS";
export const ROLES_CREATE_REQUEST = "ROLES_CREATE_REQUEST";
export const ROLES_CREATE_SUCCESS = "ROLES_CREATE_SUCCESS";
export const ROLES_CREATE_FAILED = "ROLES_CREATE_FAILED";
export const ROLES_UPDATE_REQUEST = "ROLES_UPDATE_REQUEST";
export const ROLES_UPDATE_SUCCESS = "ROLES_UPDATE_SUCCESS";
export const ROLES_UPDATE_FAILED = "ROLES_UPDATE_FAILED";
export const ROLES_DELETE_REQUEST = "ROLES_DELETE_REQUEST";
export const ROLES_DELETE_SUCCESS = "ROLES_DELETE_SUCCESS";
export const ROLES_DELETE_FAILED = "ROLES_DELETE_FAILED";
export const ROLE_FETCH_FAILED = "ROLE_FETCH_FAILED";
export const ROLE_FETCH_SUCCESS = "ROLE_FETCH_SUCCESS";
export const ROLE_FETCH_REQUEST = "ROLE_FETCH_REQUEST";


