import React from 'react';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  bioTitle: {
    marginBottom: 16,
  },
}));

const Biography = (prop) => {
  const {policy} = prop;
  const classes = useStyles();
  return (
    <CmtCard>
      <CmtCardHeader title="Quality Policy" />
      <CmtCardContent>
        <Box mb={3} component="p" color="text.secondary">
          {policy}
        </Box>
        
      </CmtCardContent>
    </CmtCard>
  );
};

export default Biography;
