import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import {
  Box,
  Grid,
  Button,
  Chip,
  Divider,
  Paper,
  Typography,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';

import GridContainer from '@jumbo/components/GridContainer';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import WysiwygEditorExample from 'components/wysiwygEditor';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';

import {
  createProduct,
  setProductDetail,
  updateProduct,
} from 'redux/actions/Manage/Products/Products';
import { createNews, updateNews } from 'redux/actions/Manage/News/News';

const useStyles = makeStyles(() => ({
  errorCk: {
    color: '#f44336',
    marginRight: '14px',
    marginTop: '4px',
    fontSize: '0.75rem',
  },
  avatar: {
    borderRadius: '0',
    height: '50vh',
    width: '20vw',
  },
  typoMarginBottom: {
    marginBottom: '10px',
  },
  editorLabelError: {
    color: '#f44336',
    fontSize: '16px',
    fontWeight: 500,
  },
}));

const AddEditNews = ({ history, open, onCloseDialog, setDataUpdated }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { Id } = useParams();

  //------------all use selectors
  const { user } = useSelector(({ auth }) => auth.authUser);
  const { singleNews } = useSelector(state => state.SetNewsDetail);
  const successAction = useSelector(state => state.Success.action);

  //-------------all use states
  const [keywordsArr, setKeywordsArr] = useState([]);
  const [descriptionHTML, setDescriptionHTML] = useState('');
  const [file, setFile] = useState([]);
  const [productDetail, setProductDetail] = useState({
    news,
    title: '',
    url: '',
    preview: '',
    id: '',
    date: '',
  });
  const [errorText, setErrorText] = React.useState({});

  //-------all handle functions
  const validate = () => {
    let errors = {};
    let isError = false;
    const { code, h1, h2, meta, name, title, url } = productDetail;
    
    if (!descriptionHTML) {
      errors.descriptionHTML = 'Please enter description.';
      isError = true;
    }
    
    if (!title) {
      errors.title = 'Please enter title.';
      isError = true;
    }
    if (Id && !url) {
      errors.url = 'Please enter url.';
      isError = true;
    } 
    // else if (!/^[a-z-.]*$/.test(url)) {
    //   errors.url = 'Only small letters with - or . are allowed.';
    //   isError = true;
    // }
    if (!file.length && !preview) {
      errors.file = 'Please upload image.';
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };

  const onLabelChange = evt => {
    setProductDetail({ ...productDetail, [evt.target.name]: evt.target.value });
  };

  const onSubmitClick = () => {
    let validateData = validate();
    if (!validateData.isError) {
      handleProductSave();
    }
  };

  const handleProductSave = async () => {
    // let keywordStr = '';
    // keywordsArr.map(e => (keywordStr += ',' + e));
    let formData = new FormData();

    formData.append('news', descriptionHTML);
    formData.append('date', date);
    formData.append('title', title);
    formData.append('url', url);
    formData.append('logo', file[0]);
    id && formData.append('id', id);
    dispatch(
      Id
        ? updateNews(formData, data => {
            if (data) history.push('/manage-news');
          })
        : createNews(formData, data => {
            if (data) history.push('/manage-news');
          }),
    );
  };

  //------all useEffects

  useEffect(() => {
    if (singleNews) {
      const {
        news,
        title,
        url,
        logo,
        date,
        id
      } = singleNews;
      setProductDetail({
        news,
        title,
        date,
        url,
        preview: logo,
        id
      });
      // const data = keywords.split(',');
      // setKeywordsArr(data);
      setDescriptionHTML(news)
    }
  }, [singleNews]);

  const {
    news,
    title,
    url,
    preview,
    id,
    date
  } = productDetail;
  return (
    <PageContainer>
      <Paper className={classes.paper}></Paper>
      <CmtCard>
        <CmtCardContent>
          <Typography variant="h3" className={classes?.typoMarginBottom}>
            {Id ? 'Edit News' : 'Add News'}
          </Typography>
          <Divider />
          <Box
            mt={3}
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            alignItems="center"
            mb={{ xs: 6, md: 5 }}
          >
            <GridContainer>
              <Grid item xs={12} sm={6}>
                <AppTextInput
                
                  fullWidth
                  name="title"
                  required
                  variant="outlined"
                  label="Title"
                  value={title}
                  onChange={onLabelChange}
                  helperText={errorText?.title}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <AppTextInput
                  fullWidth
                  name="date"
                  required
                  type="date"
                  variant="outlined"
                  label="Date"
                  value={date}
                  onChange={onLabelChange}
                  helperText={errorText?.meta}
                />
              </Grid> */}
              {Id && <Grid item xs={12} sm={6}>
                <AppTextInput
                  fullWidth
                  name="url"
                  required
                  variant="outlined"
                  label="URL"
                  value={url}
                  onChange={onLabelChange}
                  helperText={errorText?.url}
                />
              </Grid>}
              <Grid item xs={12} sm={6}>
                <AppTextInput
                  fullWidth
                  name="file"
                  type="file"
                  required
                  variant="outlined"
                  onChange={evt => {
                    setProductDetail({ ...productDetail, preview: '' });
                    setFile(evt.target.files);
                  }}
                  helperText={errorText?.file}
                />
                {preview && <img src={preview} className={classes?.avatar} />}
              </Grid>
             
              <Grid item xs={12} sm={12}>
                <span
                  className={
                    errorText?.descriptionHTML ? classes?.editorLabelError : ''
                  }
                >
                  News *
                </span>
                <WysiwygEditorExample
                  defaultDescription={news}
                  setDescriptionHTML={setDescriptionHTML}
                  descriptionHTML={news}
                />
                <span className={classes?.errorCk}>
                  {errorText?.descriptionHTML}
                </span>
              </Grid>
            </GridContainer>
          </Box>
          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button onClick={() => history.push('/manage-news')}>
              Cancel
            </Button>
            <Box ml={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={onSubmitClick}
              >
                {Id ? 'Update' : 'Save'}
              </Button>
            </Box>
          </Box>
        </CmtCardContent>
      </CmtCard>
    </PageContainer>
  );
};

export default AddEditNews;

AddEditNews.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
