import Axios from 'axios';
import {
  MSG_ERR_RESPONDING_SERVER,
  PER_PAGE_DEFAULT,
} from 'redux/actions/Common/Constant';
import { ErrorCatch } from 'utils/ErrorCatch';
import { setError } from '../../Common/ErrorAction';
import { setLoading } from '../../Common/LoadingAction';
import { setSuccess } from '../../Common/SuccessAction';
import {
  DELETE_BULK_USERS_FAILED,
  DELETE_BULK_USERS_REQUEST,
  DELETE_BULK_USERS_SUCCESS,
  SET_USER_DETAILS,
  USERS_LIST_FAILED,
  USERS_LIST_REQUEST,
  USERS_LIST_SUCCESS,
  USER_CREATE_FAILED,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_DELETE_FAILED,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_PASSWORD_UPDATE_FAILED,
  USER_PASSWORD_UPDATE_REQUEST,
  USER_PASSWORD_UPDATE_SUCCESS,
  USER_PROFILE_UPDATE_FAILED,
  USER_PROFILE_UPDATE_REQUEST,
  USER_PROFILE_UPDATE_SUCCESS,
  USER_UPDATE_FAILED,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_STATUS_REQUEST,
  USER_UPDATE_STATUS_SUCCESS,
  USER_UPDATE_STATUS_FAILED,
} from './Constant';
let RES_MSG = MSG_ERR_RESPONDING_SERVER;

// export const getAllUsers = (filterOptions = [],params, searchTerm = '', callbackFun) =>
export const getAllUsers = (
  params,
  callbackFun,
  isDownload = false,
) => async dispatch => {
  dispatch(setLoading(USERS_LIST_REQUEST));
  try {
    // const { data } = await Axios.get(`/user/list,?page=${1}`)
    // const { data } = await Axios.get(`/auth/fetch_admins`, { params })
    // const { data } = await Axios.get(`/auth/fetch_admins`)
    const response = await Axios.get(`/auth/fetch_admins`, { params });
    const { data } = response;
    const total = data?.data?.length;
    if (response.status === 200) {
      if (isDownload) dispatch(setSuccess(null, USERS_LIST_SUCCESS));
      else {
        dispatch({
          type: USERS_LIST_SUCCESS,
          data: data.data,
          total: total,
          message: data.message,
          perPage: PER_PAGE_DEFAULT,
        });
      }
      if (callbackFun) callbackFun(data.data.userList);
    } else {
      dispatch(setError(RES_MSG, USERS_LIST_FAILED));
    }
  } catch (error) {
    ErrorCatch(error, USERS_LIST_FAILED, dispatch);
  }
};
export const updateUser = (payload, callbackFun) => async dispatch => {
  dispatch(setLoading(USER_UPDATE_REQUEST));
  try {
    const response = await Axios.post(`/auth/update`, payload);
    const { data } = response;
    if (response.status === 200) {
      dispatch(setSuccess(data.message, USER_UPDATE_SUCCESS));
      dispatch({
        type: USER_UPDATE_SUCCESS,
        data: data.data,
        message: data.message,
      });
      if (callbackFun) callbackFun(data.data);
    } else {
      dispatch(setError(MSG_ERR_RESPONDING_SERVER, USER_UPDATE_FAILED));
    }
  } catch (error) {
    ErrorCatch(error, USER_UPDATE_FAILED, dispatch);
  }
};
export const updateUserPassword = (payload, callbackFun) => async dispatch => {
  dispatch(setLoading(USER_PASSWORD_UPDATE_REQUEST));
  try {
    const { data } = await Axios.put(`/user/change-password`, payload);
    if (data.statusCode === 200) {
      dispatch(setSuccess(data.responseMsg, USER_PASSWORD_UPDATE_SUCCESS));
      dispatch({
        type: USER_PASSWORD_UPDATE_SUCCESS,
        data: data.data.userUpdatedPassword,
        message: data.responseMsg,
      });
      if (callbackFun) callbackFun(data.data.userUpdatedPassword);
    } else {
      dispatch(
        setError(MSG_ERR_RESPONDING_SERVER, USER_PASSWORD_UPDATE_FAILED),
      );
    }
  } catch (error) {
    ErrorCatch(error, USER_PASSWORD_UPDATE_FAILED, dispatch);
  }
};
export const updateUserProfile = (
  roleId,
  payload,
  callbackFun,
) => async dispatch => {
  dispatch(setLoading(USER_PROFILE_UPDATE_REQUEST));
  try {
    const { data } = await Axios.put(`/user/update/${roleId}`, payload);
    if (data.statusCode === 200) {
      dispatch(setSuccess(data.responseMsg, USER_PROFILE_UPDATE_SUCCESS));
      dispatch({
        type: USER_PROFILE_UPDATE_SUCCESS,
        data: data.data.userUpdate,
        message: data.responseMsg,
      });
      if (callbackFun) callbackFun(data.data.userUpdate);
    } else {
      dispatch(setError(MSG_ERR_RESPONDING_SERVER, USER_PROFILE_UPDATE_FAILED));
    }
  } catch (error) {
    ErrorCatch(error, USER_PROFILE_UPDATE_FAILED, dispatch);
  }
};
export const adminUpdatePassword = (payload, callbackFun) => async dispatch => {
  dispatch(setLoading(USER_PROFILE_UPDATE_REQUEST));
  try {
    const { data } = await Axios.put(`/user/admin-change-password`, payload);
    if (data.statusCode === 200) {
      dispatch(setSuccess(data.responseMsg, USER_PROFILE_UPDATE_SUCCESS));
      dispatch({
        type: USER_PROFILE_UPDATE_SUCCESS,
        data: data.data,
        message: data.responseMsg,
      });
      if (callbackFun) callbackFun(data.data);
    } else {
      dispatch(setError(MSG_ERR_RESPONDING_SERVER, USER_PROFILE_UPDATE_FAILED));
    }
  } catch (error) {
    ErrorCatch(error, USER_PROFILE_UPDATE_FAILED, dispatch);
  }
};
export const changePassword = (
  roleId,
  payload,
  callbackFun,
) => async dispatch => {
  dispatch(setLoading(USER_UPDATE_REQUEST));
  try {
    const { data } = await Axios.put(`/user/change-password`, payload);
    if (data.statusCode === 200) {
      dispatch(setSuccess(data.responseMsg, USER_UPDATE_SUCCESS));
      dispatch({
        type: USER_UPDATE_SUCCESS,
        data: data.user,
        message: data.message,
      });
      if (callbackFun) callbackFun(data.data.userUpdatedPassword);
    } else {
      dispatch(setError(MSG_ERR_RESPONDING_SERVER, USER_UPDATE_FAILED));
    }
  } catch (error) {
    ErrorCatch(error, USER_UPDATE_FAILED, dispatch);
  }
};
export const setUserDetail = user => {
  return dispatch => {
    dispatch({
      type: SET_USER_DETAILS,
      data: user,
    });
  };
};
// export const createUser = (payload, callbackFun) =>
//     async (dispatch) => {
//         dispatch(setLoading(USER_CREATE_REQUEST))
//         try {
//             const response = await Axios.post(`/auth/add_admin_user`, payload)
//             const { data } = response
//             console.log("😃 ~ file: Users.js:164 ~ data", data)
//             if (response.status === 200) {
//                 dispatch(setSuccess(data.message, USER_CREATE_SUCCESS))
//                 dispatch({
//                     type: USER_CREATE_SUCCESS,
//                     data: data.user,
//                     message: data.message,
//                 });
//                 if (callbackFun) callbackFun(data.user);
//             }
//             else {
//                 dispatch(setError(MSG_ERR_RESPONDING_SERVER, USER_CREATE_FAILED));
//             }
//         } catch (error) {
//             ErrorCatch(error, USER_CREATE_FAILED, dispatch)
//         }
//     };

export const createUser = data => {
  return dispatch => {
    return Axios.post('/auth/add_admin_user', data)
      .then(response => {
        const { data } = response
        if (response?.status === 200) {
          dispatch(setSuccess(data?.message, USER_CREATE_SUCCESS));
          dispatch({
            type: USER_CREATE_SUCCESS,
            data: data.user,
            message: data.message,
          });
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        dispatch(setError(err?.data?.message, USER_CREATE_FAILED));
        return Promise.resolve(err);
      });
  };
};

//take user id for delete api request
export const deleteUser = (userId, callbackFun) => async dispatch => {
  dispatch(setLoading(USER_DELETE_REQUEST));
  try {
    const { data } = await Axios.delete(`/user/delete/${userId}`);
    if (data.statusCode === 200) {
      dispatch(setSuccess(data.responseMsg, USER_DELETE_SUCCESS));
      dispatch({
        type: USER_DELETE_SUCCESS,
        data: data.data.userDelete,
        message: data.responseMsg,
      });
      if (callbackFun) callbackFun(data.data.userDelete);
    } else {
      dispatch(setError(MSG_ERR_RESPONDING_SERVER, USER_DELETE_FAILED));
    }
  } catch (error) {
    ErrorCatch(error, USER_DELETE_FAILED, dispatch);
  }
};
export const deleteBulkUser = (userIds, callbackFun) => async dispatch => {
  dispatch(setLoading(DELETE_BULK_USERS_REQUEST));
  try {
    // const { data } = await Axios.delete(`/admin/roles/delete/${roleId}`)
    const data = {
      statusCode: 200,
      data: userIds,
      responseMsg: 'Selected Users were deleted successfully!',
    };
    if (data.statusCode === 200) {
      dispatch(setSuccess(data.responseMsg, DELETE_BULK_USERS_SUCCESS));
      dispatch({
        type: DELETE_BULK_USERS_SUCCESS,
        data: data.data,
        message: data.message,
      });
      if (callbackFun) callbackFun(data.data);
    } else {
      dispatch(setError(MSG_ERR_RESPONDING_SERVER, DELETE_BULK_USERS_FAILED));
    }
  } catch (error) {
    ErrorCatch(error, DELETE_BULK_USERS_FAILED, dispatch);
  }
};


export const updateUserStatus = (id, data) => {
  return dispatch => {
    dispatch(setLoading(USER_UPDATE_STATUS_REQUEST));
    return Axios.put(`api/v1/admin/user/update-status/${id}`, data)
      .then(response => {
        if (response?.status === 200) {
          const { data } = response;
          dispatch(
            setSuccess(data.responseMessage, USER_UPDATE_STATUS_SUCCESS),
          );
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        dispatch(
          setError(err?.data?.responseMessage, USER_UPDATE_STATUS_FAILED),
        );
        return Promise.resolve(err);
      });
  };
};