import AlertBox from 'components/AlertBox';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PageLoader from '../PageComponents/PageLoader';


const ContentLoader = () => {
  // const { error, loading, message } = useSelector(({ common }) => common);
  const { loading } = useSelector((state) => state.Loading);

  const { error, isOpen } = useSelector(state => state.Error)
  const { success } = useSelector(state => state.Success)
  // const successAction = useSelector(state => state.Success.action)
  const [Success, setSuccess] = useState(false)
  const [successMsg, setSuccessMsg] = useState("")
  // useEffect(() => {
  //   setTimeout(() => {
  //     dispatch(fetchError(''));
  //   }, 3000);
  // }, [dispatch, error, message]);
  // useEffect(() => {
  //   if (success) {
  //     setSuccess(true)
  //     setSuccessMsg(success)
  //   }
  // }, [success])
  // useEffect(() => {
  //   if (!success)
  //     setSuccess(false)
  // }, [success])
  return (
    <React.Fragment>
      {loading && <PageLoader />}
      {success && <AlertBox type='success' style="filled" >{success}</AlertBox>}
      {error && isOpen && <AlertBox type='error' style="filled" >{error}</AlertBox>}

      {/* {
        <Snackbar
          open={Boolean(error)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          TransitionComponent={SlideTransition}>
          <Alert variant="filled" severity="error">
            {error}
          </Alert>
        </Snackbar>
      } */}
      {/* {
        <Snackbar
          open={Boolean(message)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          TransitionComponent={SlideTransition}>
          <Alert variant="filled" severity="success">
            {message}
          </Alert>
        </Snackbar>
      } */}
    </React.Fragment>
  );
};

export default ContentLoader;
