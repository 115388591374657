import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MoreHoriz, Visibility } from '@material-ui/icons';

import CustomTable from 'components/CustomeTable';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import ViewGradeDetails from '../Grade/ViewGradeDetails';

import {
  getAllGrades,
  setGradeDetail,
} from 'redux/actions/Manage/ProductMaterialGrades';
import { setProductDetail } from 'redux/actions/Manage/Products/Products';

const Mapping = ({ history }) => {
  const dispatch = useDispatch();
  const gradesData = useSelector(state => state?.Grades);
  const { grades, total, perPage } = gradesData;

  const headCells = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'title', numeric: false, disablePadding: false, label: 'Title',  sort: false },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Actions',
      sort: false,
      render: elm => (
        <CmtDropdownMenu
          items={userActions}
          onItemClick={menu => onUserMenuClick(menu, elm)}
          TriggerComponent={<MoreHoriz />}
        />
      ),
    },
  ];

  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [dataUpdated, setDataUpdated]= useState(false)
  const [openViewProductDetails, setOpenViewProductDetails] = useState(false);
  const getUserActions = () => {
    const actions = [];
    actions.push({ action: 'view', label: 'View', icon: <Visibility /> });
    return actions;
  };

  const userActions = getUserActions();
  const onUserMenuClick = (menu, elm) => {
    if (menu.action === 'view') {
      dispatch(setGradeDetail(elm));
      setOpenViewProductDetails(true);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearch = text => {
    // setPage(1);
    setSearchTerm(text);
  };

  const handleCloseViewProductDetails = () => {
    setOpenViewProductDetails(false);
    dispatch(setProductDetail(null));
  };

  useEffect(() => {
   
      let value ={}
      if(searchTerm){
        value.search=searchTerm
      }
    dispatch(getAllGrades(value && value));
  }, [dataUpdated]);

  return (
    <>
      <CustomTable
        headCells={headCells}
        row={grades || []}
        count={total || 0}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        rowsPerPage={perPage}
        onPageChange={handlePageChange}
        page={page}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleSearch={(text)=> setSearchTerm(text)}
        handleSearchSubmit={()=>{
          setPage(0)
          setDataUpdated(!dataUpdated)
        }}
        addButtonText={'Add Mapping'}
        handleAddButton={() => history.push('/manage-add-mapping')}
      />

      {openViewProductDetails && (
        <ViewGradeDetails
          showKeyword={false}
          showImage={false}
          open={openViewProductDetails}
          onCloseDialog={handleCloseViewProductDetails}
        />
      )}
    </>
  );
};

export default Mapping;
