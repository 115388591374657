import { PERMISSIONS_CREATE_SUCCESS, PERMISSIONS_DELETE_SUCCESS, PERMISSIONS_LIST_SUCCESS, PERMISSIONS_UPDATE_SUCCESS, SET_PERMISSIONS_DETAILS } from "redux/actions/index";

const INIT_STATE =
{
    permissions: [],
    permission: null,
    // permissions_users: [],
    total: 0,
    perPage: 0
    // permissions: null,
}
const INIT_STATE_DETAIL =
{
    permission: null,
}


export const PermissionsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case PERMISSIONS_CREATE_SUCCESS: {
            return {
                ...state,
                permission: action.data,
                action: action.type,
                message: action.message,
                permissions: [action.data, ...state.permissions]
            };
        }
        case PERMISSIONS_LIST_SUCCESS: {
            return {
                ...state,
                permissions: action.data,
                action: action.type,
                // total: action.total,
                // perPage: action.perPage,
                // message: action.message
            };
        }
        // case ROLES_PERMISSIONS_LIST_SUCCESS: {
        //     return {...state,
        //         permissions: action.data,
        //         action: action.type,
        //         total: action.total,
        //         perPage: action.perPage,
        //         message: action.message
        //     };
        // }
        // case PERMISSIONS_USERS_LIST_SUCCESS: {
        //     return {...state,
        //         permissions: state.permissions,
        //         permissions_users: action.data,
        //         action: action.type,
        //         total: state.total,
        //         perPage: state.perPage,
        //         message: action.message
        //     };
        // }
        //permissions: state.permissions.map(permission => (permission._id === action.payload.id ? action.payload : permission))
        case PERMISSIONS_UPDATE_SUCCESS: {
            return {
                ...state,
                permission: action.data,
                action: action.type,
                message: action.message,
                permissions: state.permissions.map(permission => (permission._id === action.data._id ? action.data : permission))
            };
        }
        case PERMISSIONS_DELETE_SUCCESS: {
            return {
                ...state,
                permission: action.data,
                action: action.type,
                message: action.message,
                permissions: state.permissions.filter(permission => permission._id !== action.data),
            };
        }
        // case DELETE_BULK_PERMISSIONSS_SUCCESS: {
        //     return {...state,
        //         ...state,
        //         action: action.type,
        //         message: action.message,
        //         permissions: state.permissions.filter(permission => !action.data.includes(permission._id)),
        //     };
        // }
        default:
            return state;
    }
};
export const SetPermissionsReducer = (state = INIT_STATE_DETAIL, action) => {
    switch (action.type) {
        case SET_PERMISSIONS_DETAILS:
            return {
                ...state,
                permission: action.data,
                action: action.type,
            };
        default:
            return state;
    }
};