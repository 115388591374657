import Axios from "axios";
import { MSG_ERR_RESPONDING_SERVER, PER_PAGE_DEFAULT } from 'redux/actions/Common/Constant';
import { ErrorCatch } from "utils/ErrorCatch";
import { setError } from "../../Common/ErrorAction";
import { setLoading } from "../../Common/LoadingAction";
import { setSuccess } from "../../Common/SuccessAction";
import { DELETE_BULK_EQGRADE_FAILED, DELETE_BULK_EQGRADE_REQUEST, DELETE_BULK_EQGRADE_SUCCESS, EQGRADE_CREATE_FAILED, EQGRADE_CREATE_REQUEST, EQGRADE_CREATE_SUCCESS, EQGRADE_DELETE_FAILED, EQGRADE_DELETE_REQUEST, EQGRADE_DELETE_SUCCESS, EQGRADE_LIST_FAILED, EQGRADE_LIST_REQUEST, EQGRADE_LIST_SUCCESS, EQGRADE_MATERIAL_CREATE_FAILED, EQGRADE_MATERIAL_CREATE_REQUEST, EQGRADE_MATERIAL_CREATE_SUCCESS, EQGRADE_UPDATE_FAILED, EQGRADE_UPDATE_REQUEST, EQGRADE_UPDATE_SUCCESS, GRADE_EQGRADE_LIST_FAILED, GRADE_EQGRADE_LIST_REQUEST, GRADE_EQGRADE_LIST_SUCCESS, SET_EQGRADE_DETAILS } from "./Constant";

let RES_MSG = MSG_ERR_RESPONDING_SERVER

// export const getAllUsers = (filterOptions = [],params, searchTerm = '', callbackFun) =>
// export const getAllEqGrades = (params, callbackFun, isDownload = false) =>
//     async (dispatch) => {
//         dispatch(setLoading(EQGRADE_LIST_REQUEST))
//         try {
//             // const { data } = await Axios.get(`/user/list,?page=${1}`)
//             // const { data } = await Axios.get(`/auth/fetch_admins`, { params })
//             // const { data } = await Axios.get(`/auth/fetch_admins`)
//             const response = await Axios.post(`/product/eqGrades`, { params })
//             const { data } = response
//             const total = data?.data?.length
//             if (response.status === 200) {
//                 if (isDownload)
//                     dispatch(setSuccess(null, EQGRADE_LIST_SUCCESS))
//                 else {
//                     dispatch({
//                         type: EQGRADE_LIST_SUCCESS,
//                         data: data.data,
//                         total: total,
//                         message: data.message,
//                         perPage: PER_PAGE_DEFAULT,
//                     });
//                 }
//                 if (callbackFun) callbackFun(data.data.userList);
//             }
//             else {
//                 dispatch(setError(RES_MSG, EQGRADE_LIST_FAILED));
//             }
//         } catch (error) {
//             ErrorCatch(error, EQGRADE_LIST_FAILED, dispatch)
//         }
//     };
;

// export const updateEqGrades = (payload, callbackFun) =>
//     async (dispatch) => {
//         dispatch(setLoading(EQGRADE_UPDATE_REQUEST))
//         try {
//             const response = await Axios.post(`/product/updateGrade`, payload)
//             const { data } = response
//             if (response.status === 200) {
//                 dispatch(setSuccess(data.message, EQGRADE_UPDATE_SUCCESS))
//                 dispatch({
//                     type: EQGRADE_UPDATE_SUCCESS,
//                     data: data.data,
//                     message: data.message,
//                 });
//                 if (callbackFun) callbackFun(data.data);
//             }
//             else {
//                 dispatch(setError(MSG_ERR_RESPONDING_SERVER, EQGRADE_UPDATE_FAILED));
//             }
//         } catch (error) {
//             dispatch(setError(error?.data?.message, EQGRADE_UPDATE_FAILED))
//             // ErrorCatch(error, EQGRADE_UPDATE_FAILED, dispatch)
//         }
//     };

export const setEqGradesDetail = user => {
    return dispatch => {
        dispatch({
            type: SET_EQGRADE_DETAILS,
            data: user,
        });
    };
};
// export const createEqGrades = (payload, callbackFun) =>
//     async (dispatch) => {
//         dispatch(setLoading(EQGRADE_CREATE_REQUEST))
//         try {
//             const response = await Axios.post(`/product/addGrade`, payload)
//             const { data } = response
//             if (response.status === 200) {
//                 dispatch(setSuccess(data.message, EQGRADE_CREATE_SUCCESS))
//                 dispatch({
//                     type: EQGRADE_CREATE_SUCCESS,
//                     data: data.data,
//                     message: data.message,
//                 });
//                 if (callbackFun) callbackFun(data.data);
//             }
//             else {
//                 dispatch(setError(MSG_ERR_RESPONDING_SERVER, EQGRADE_CREATE_FAILED));
//             }
//         } catch (error) {
//             // ErrorCatch(error, EQGRADE_CREATE_FAILED, dispatch)
//             dispatch(setError(error?.data?.message, EQGRADE_CREATE_FAILED))

//         }
//     };


export const getAllEqGrades = params => {
    return dispatch => {
      dispatch(setLoading(EQGRADE_LIST_REQUEST));
      return Axios.get('api/v1/admin/equivalent-grade/list', { params })
        .then(response => {
          if (response?.status === 200) {
            const { data } = response;
            dispatch({
                type: EQGRADE_LIST_SUCCESS,
                data: data?.data,
                total: data?.total,
                message: data?.message,
                perPage: PER_PAGE_DEFAULT,
            });
            return Promise.resolve(response);
          }
        })
        .catch(err => {
          dispatch(setError(err?.data?.responseMessage, EQGRADE_LIST_FAILED));
          return Promise.resolve(err);
        });
    };
  };

export const createEqGrades = data => {
    return dispatch => {
      dispatch(setLoading(EQGRADE_CREATE_REQUEST));
      return Axios.post('api/v1/admin/equivalent-grade', data)
        .then(response => {
          if (response?.status === 200) {
            
            dispatch(setSuccess(data.responseMsg, EQGRADE_CREATE_SUCCESS))
            return Promise.resolve(response);
          }
        })
        .catch(err => {
          dispatch(setError(err?.data?.responseMessage, EQGRADE_CREATE_FAILED));
          return Promise.resolve(err);
        });
    };
  };
  
  
  export const updateEqGrades = (id, data) => {
    return dispatch => {
      dispatch(setLoading(EQGRADE_UPDATE_REQUEST));
      return Axios.put(`api/v1/admin/equivalent-grade/${id}`, data)
        .then(response => {
          if (response?.status === 200) {
            const { data } = response;
            dispatch(setSuccess(data.responseMessage, EQGRADE_UPDATE_SUCCESS))
            return Promise.resolve(response);
          }
        })
        .catch(err => {
          dispatch(setError(err?.data?.responseMessage, EQGRADE_UPDATE_FAILED));
          return Promise.resolve(err);
        });
    };
  };


export const addEqGradesToGrades = (payload, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(EQGRADE_MATERIAL_CREATE_REQUEST))
        try {
            const response = await Axios.post(`/product/addMaterialsToProduct`, payload)
            const { data } = response
            if (response.status === 200) {
                dispatch(setSuccess(data.message, EQGRADE_MATERIAL_CREATE_SUCCESS))
                dispatch({
                    type: EQGRADE_MATERIAL_CREATE_SUCCESS,
                    data: data.data,
                    message: data.message,
                });
                if (callbackFun) callbackFun(data.data);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, EQGRADE_MATERIAL_CREATE_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, EQGRADE_MATERIAL_CREATE_FAILED, dispatch)
        }
    };
//take user id for delete api request
export const deleteEqGrades = (userId, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(EQGRADE_DELETE_REQUEST))
        try {
            const { data } = await Axios.delete(`/user/delete/${userId}`)
            if (data.statusCode === 200) {
                dispatch(setSuccess(data.responseMsg, EQGRADE_DELETE_SUCCESS))
                dispatch({
                    type: EQGRADE_DELETE_SUCCESS,
                    data: data.data.userDelete,
                    message: data.responseMsg,
                });
                if (callbackFun) callbackFun(data.data.userDelete);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, EQGRADE_DELETE_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, EQGRADE_DELETE_FAILED, dispatch)
        }
    };
;
export const deleteBulkEqGrades = (userIds, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(DELETE_BULK_EQGRADE_REQUEST))
        try {
            // const { data } = await Axios.delete(`/admin/roles/delete/${roleId}`)
            const data = {
                statusCode: 200,
                data: userIds,
                responseMsg: "Selected Users were deleted successfully!",
            }
            if (data.statusCode === 200) {
                dispatch(setSuccess(data.responseMsg, DELETE_BULK_EQGRADE_SUCCESS))
                dispatch({
                    type: DELETE_BULK_EQGRADE_SUCCESS,
                    data: data.data,
                    message: data.message,
                });
                if (callbackFun) callbackFun(data.data);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, DELETE_BULK_EQGRADE_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, DELETE_BULK_EQGRADE_FAILED, dispatch)
        }
    };
;

export const getGradesListForEqGrade = (params, callbackFun, isDownload = false) =>
    async (dispatch) => {
        dispatch(setLoading(GRADE_EQGRADE_LIST_REQUEST))
        try {
            // const { data } = await Axios.get(`/user/list,?page=${1}`)
            // const { data } = await Axios.get(`/auth/fetch_admins`, { params })
            // const { data } = await Axios.get(`/auth/fetch_admins`)
            const response = await Axios.post(`/product/gradesForEqGrades`, { params })
            const { data } = response
          
            const total = data?.data?.length
            if (response.status === 200) {
                if (isDownload)
                    dispatch(setSuccess(null, GRADE_EQGRADE_LIST_SUCCESS))
                else {
                    dispatch({
                        type: GRADE_EQGRADE_LIST_SUCCESS,
                        data: data.data,
                        total: total,
                        message: data.message,
                        perPage: PER_PAGE_DEFAULT,
                    });
                }
                if (callbackFun) callbackFun(data.data.userList);
            }
            else {
                dispatch(setError(RES_MSG, GRADE_EQGRADE_LIST_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, GRADE_EQGRADE_LIST_FAILED, dispatch)
        }
    };


    export const getGradesEqGrades = _id => {
        console.log(_id)
      return dispatch => {
        dispatch(setLoading(EQGRADE_LIST_REQUEST));
        return Axios.post('product/eqGrades', {_id})
          .then(response => {
            console.log(response.data.data,"redux response")
            if (response?.status === 200) {
              const { data } = response;
              dispatch({
                type: EQGRADE_LIST_SUCCESS,
                data: data?.data,
                total: data?.total,
                message: data?.message,
                // perPage: PER_PAGE_DEFAULT,
              });
              return Promise.resolve(response);
            }
          })
          .catch(err => {
            dispatch(setError(err?.data?.responseMessage, EQGRADE_LIST_FAILED));
            return Promise.resolve(err);
          });
      };
    };
    export const getGradesEqGradesDropdown = params => {
      return dispatch => {
        dispatch(setLoading(EQGRADE_LIST_REQUEST));
        return Axios.post('product/eqGrade-from-materialGrade-dropdown', {...params})
          .then(response => {
            console.log(response.data.data,"redux response")
            if (response?.status === 200) {
              const { data } = response;
              dispatch({
                type: EQGRADE_LIST_SUCCESS,
                data: data?.data,
                message: data?.message,
                // perPage: PER_PAGE_DEFAULT,
              });
              return Promise.resolve(response);
            }
          })
          .catch(err => {
            dispatch(setError(err?.data?.responseMessage, EQGRADE_LIST_FAILED));
            return Promise.resolve(err);
          });
      };
    };
