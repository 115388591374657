import React, { useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Visibility, VisibilityOff } from '@material-ui/icons';
const AppTextInput = ({
  eyeBtn = false,
  onKeyPress,
  onKeyUp,
  type,
  name,
  id,
  fullWidth,
  size,
  value,
  onChange,
  helperText,
  variant,
  error,
  ...rest
}) => {
  const [show, setShow] = useState(false);

  const onKeyPressNumber = event => {
    if (type === 'number') {
      let needPrevent = false;
      if (event.key === '-') {
        needPrevent = true;
      } else if (event.key === '.') {
        if (value.toString().includes('.')) {
          needPrevent = true;
        }
      } else if (!new RegExp('^[0-9]$').test(event.key)) {
        needPrevent = true;
      }
      if (needPrevent) {
        event.preventDefault();
      }
    }
  };

  return (
    <TextField
      {...rest}
      inputProps={{
        autoComplete: 'off',
      }}
      onKeyPress={onKeyPress || onKeyPressNumber}
      onKeyUp={onKeyUp}
      type={type === 'password' && show ? 'text' : type}
      name={name}
      id={id || name}
      size={size}
      fullWidth={fullWidth}
      value={value}
      variant={variant}
      onChange={onChange}
      error={error || helperText !== ''}
      helperText={helperText}
      InputProps={
        type === 'password' && eyeBtn
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={() =>
                      type === 'password' ? setShow(!show) : null
                    }
                  >
                    {show ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }
          : { ...rest.InputProps }
      }
    />
  );
};

AppTextInput.prototype = {
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.bool,
};

AppTextInput.defaultProps = {
  type: 'text',
  fullWidth: true,
  size: 'small',
  error: false,
  helperText: '',
};

export default AppTextInput;
