import log from "utils/Logger.util"
import { DOWNLOAD_ACTION } from "./Constant"

//success msg action
export function setDownload() {
    log.debug(DOWNLOAD_ACTION)
    return {
        type: DOWNLOAD_ACTION,
        action: DOWNLOAD_ACTION,
        error: false,
        loading: false,
    }
}