import { ADD_PLAN_LIST_SUCCESS, PLAN_LIST_SUCCESS } from "redux/actions";

const INIT_STATE = {
    total: 0,
    perpage: 10,
    planList: [],
    createPlan: [],
};


export const PlanReducer = (state = INIT_STATE, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
        case ADD_PLAN_LIST_SUCCESS: {
            return {
                ...state,

                createPlan: [action.data, ...state.createPlan]
            };
        }
        case PLAN_LIST_SUCCESS: {
            return {
                ...state,
                total: action?.total,
                perpage: action?.perpage,
                planList: action?.data
            }
        }

        default:
            return state;
    }
}