import Axios from 'axios';
import { setError } from 'redux/actions/Common/ErrorAction';
import { setLoading } from 'redux/actions/Common/LoadingAction';
import { setSuccess } from 'redux/actions/Common/SuccessAction';

import {
  MATERIAL_GRADE_LIST_REQUEST,
  MATERIAL_GRADE_LIST_FAILED,
  MATERIAL_GRADE_LIST_SUCCESS,
  MATERIAL_GRADE_CREATE_REQUEST,
  MATERIAL_GRADE_CREATE_FAILED,
  MATERIAL_GRADE_CREATE_SUCCESS,
  MATERIAL_GRADE_UPDATE_REQUEST,
  MATERIAL_GRADE_UPDATE_FAILED,
  MATERIAL_GRADE_UPDATE_SUCCESS,
  MATERIAL_GRADE_STATUS_UPDATE_REQUEST,
  MATERIAL_GRADE_STATUS_UPDATE_FAILED,
  MATERIAL_GRADE_STATUS_UPDATE_SUCCESS,
  MATERIAL_GRADE_LIST_BY_ID_REQUEST,
  MATERIAL_GRADE_LIST_BY_ID_FAILED,
  MATERIAL_GRADE_LIST_BY_ID_SUCCESS,
  MATERIAL_LIST_REQUEST,
  MATERIAL_LIST_FAILED,
  MATERIAL_LIST_SUCCESS,
  MATERIAL_GRADE_UPDATE_STATUS_REQUEST,
  MATERIAL_GRADE_UPDATE_STATUS_SUCCESS,
  MATERIAL_GRADE_UPDATE_STATUS_FAILED,
} from './Constant';
import { MSG_ERR_RESPONDING_SERVER } from 'redux/actions/Common/Constant';

export const getMaterialGradeList = params => {
  return dispatch => {
    dispatch(setLoading(MATERIAL_GRADE_LIST_REQUEST));
    return Axios.get('api/v1/admin/material-grade/list', { params })
      .then(response => {
        if (response?.status === 200) {
          const { data } = response;
          dispatch({
            type: MATERIAL_GRADE_LIST_SUCCESS,
            data: data?.data?.gradeList,
            total: data?.data?.total,
            message: data.responseMessage,
            perPage: data?.data?.perPage,
          });
          // dispatch(setSuccess(data.responseMessage, MATERIAL_GRADE_LIST_SUCCESS))
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        dispatch(setError(err?.data?.responseMessage, MATERIAL_GRADE_LIST_FAILED));
        return Promise.resolve(err);
      });
  };
};

export const crateMaterialGrade = data => {
  return dispatch => {
    dispatch(setLoading(MATERIAL_GRADE_CREATE_REQUEST));
    return Axios.post('api/v1/admin/material-grade', data)
      .then(response => {
        if (response?.status === 200) {
          dispatch(setSuccess(data.responseMsg, MATERIAL_GRADE_CREATE_SUCCESS));
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        dispatch(setError(err?.data?.responseMessage, MATERIAL_GRADE_CREATE_FAILED));
        return Promise.resolve(err);
      });
  };
};

export const updateMaterialGrade = (id, data) => {
  return dispatch => {
    dispatch(setLoading(MATERIAL_GRADE_UPDATE_REQUEST));
    return Axios.put(`api/v1/admin/material-grade/${id}`, data)
      .then(response => {
        if (response?.status === 200) {
          const { data } = response;
          dispatch({
            type: MATERIAL_GRADE_UPDATE_SUCCESS,
            data: data?.data?.gradeList,
            message: data.responseMessage,
          });
          dispatch(setSuccess(data.responseMessage, MATERIAL_GRADE_UPDATE_SUCCESS));
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        dispatch(setError(err?.data?.responseMessage, MATERIAL_GRADE_UPDATE_FAILED));
        return Promise.resolve(err);
      });
  };
};

export const updateMaterialGradeStatus = (id, data) => {
  return dispatch => {
    dispatch(setLoading(MATERIAL_GRADE_UPDATE_STATUS_REQUEST));
    return Axios.put(`api/v1/admin/material-grade/update-status/${id}`, data)
      .then(response => {
        if (response?.status === 200) {
          const { data } = response;
          dispatch(
            setSuccess(data.responseMessage, MATERIAL_GRADE_UPDATE_STATUS_SUCCESS),
          );
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        dispatch(
          setError(err?.data?.responseMessage, MATERIAL_GRADE_STATUS_UPDATE_FAILED),
        );
        return Promise.resolve(err);
      });
  };
};

export const getMaterialGradeListById = id => {
  return dispatch => {
    dispatch(setLoading(MATERIAL_GRADE_LIST_BY_ID_REQUEST));
    return Axios.get(`api/v1/admin/material-grade/${id}`)
      .then(response => {
        if (response?.status === 200) {
          dispatch({
            type:MATERIAL_GRADE_LIST_BY_ID_SUCCESS,
            data:response?.data?.data?.materialGradeDetail
          });
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        dispatch(setError(err?.data?.responseMessage, MATERIAL_GRADE_LIST_BY_ID_FAILED));
        return Promise.resolve(err);
      });
  };
};



export const getMaterialList = () => {
  return dispatch => {
    return Axios.get('api/v1/admin/material/list')
      .then(response => {
        if (response?.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch(err => {
        dispatch(setError(err?.data?.error, MATERIAL_LIST_FAILED));
        return Promise.resolve(err);
      });
  };
};

export const updateMaterialGradeFaqDetail = (id, data) => {
  return async dispatch => {
      try {
          await Axios.post(`/product/updateFaqs-materialGrade-Eq/${id}`, data);
          dispatch(setSuccess("Updated"))
          return Promise.resolve();
      } catch (error) {
          dispatch(setError(MSG_ERR_RESPONDING_SERVER));
      }
  };
};