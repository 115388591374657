// successTypes.js
export const SET_SUCCESS = "SET_SUCCESS";
export const HIDE_SUCCESS = "HIDE_SUCCESS";

// loaderTypes.js
export const SET_ERROR = "SET_ERROR";
export const HIDE_ERROR = "HIDE_ERROR";

// errorTypes.js
export const SET_LOADER = "SET_LOADER";
export const SET_IDLE_TIMER = "SET_IDLE_TIMER";
export const HIDE_LOADER = "HIDE_LOADER";

//general error message
export const MSG_ERR_RESPONDING_SERVER = "Error responding from server!"

export const DOWNLOAD_ACTION = "DOWNLOAD_ACTION"

export const PER_PAGE_DEFAULT = 10
export const PERMISSION_LIST = ["viewProductAccess",
    "viewEmailAccess",
    "viewGradesAccess",
    "viewManageAdminAccess",
    "viewMappingAccess",
    "viewMaterialAccess",
    "viewNewsAccess",
    "viewProductMaterialAccess",
    "viewProfileAccess",
    "viewSubProductAccess",
    "viewBlockingEmail"]
