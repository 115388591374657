import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Checkbox,
  Typography,
  IconButton,
  Tooltip,
  Chip,
  alpha,
} from '@material-ui/core';
import {
  StarBorder,
  Star,
  Archive,
  Delete,
  Reply,
  Done,
  Cancel,
  Inbox,
  CancelScheduleSend,
  Schedule,
  Send,
} from '@material-ui/icons';
import { Shortcut } from '@mui/icons-material';

import MailLabels from './MailLabels';
import useStyles from './MailCell.style';
import SenderInfo from './SenderInfo';
import CmtAvatar from '@coremat/CmtAvatar';
import { getDateInDesiredFormat } from '@jumbo/utils/dateHelper';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import {
  onGetSelectedMail,
  onNullifyMail,
  updateFvrtStatus,
  updateMailsFolder,
} from 'redux/actions/MailApp';
import {
  emailStatusChange,
  fetchMailById,
  getEmailById,
  sendIt,
} from 'redux/actions/Manage/Emails/Emails';

const MailCell = ({
  mail,
  labelsList,
  checkedMails,
  onChangeCheckedMails,
  onClickSendMail,
  onClickForwardMail,
  viewMode,
  setRefresh,
  refresh,
  canceled,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [actionName, setActionName] = useState('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [showSenderInfo, setShowSenderInfo] = useState(null);
  const { filterType } = useSelector(({ mailApp }) => mailApp);

  const onShowSenderInfo = event => {
    setShowSenderInfo(event.currentTarget);
  };

  const onHideSenderInfo = () => {
    setShowSenderInfo(null);
  };

  const onGetMailDetail = () => {
    dispatch(getEmailById(mail?._id));
  };

  const handleAction = action => {
    setOpenConfirmDialog(true);
    setActionName(action);
  };

  const handleConfirmAction = () => {
    dispatch(emailStatusChange({ ids: [mail?._id], status: actionName })).then(
      res => {
        if (res?.status === 200) {
          setOpenConfirmDialog(false);
          setRefresh(!refresh);
          dispatch(onNullifyMail());
        }
      },
    );
  };

  const handleSendItConfirm = () => {
    dispatch(sendIt(mail?._id)).then(res => {
      if (res?.status === 200) {
        setOpenConfirmDialog(false);
        setRefresh(!refresh);
        dispatch(onNullifyMail());
      }
    });
  };

  const {
    _id,
    senderEmail,
    senderDetails,
    emailContent,
    read,
    emailType,
    status,
    date,
    scheduleOn,
    isCanceled,
    sentDate,
    createdAt,
    updatedAt,
    trashDate,
  } = mail;

  const labels = isCanceled ? ['Cancelled'] : [emailType];
  return (
    <Box
      className={clsx(classes.mailCellItem, {
        itemRead: read,
        selected: checkedMails.includes(mail?._id),
      })}
      onClick={onGetMailDetail}
    >
      <Box className={classes.mailCellContent}>
        <Box mr={{ xs: 2, md: 4 }} onClick={event => event.stopPropagation()}>
          <Checkbox
            color="primary"
            checked={checkedMails.includes(mail?._id)}
            onChange={event => onChangeCheckedMails(event.target.checked, _id)}
          />
        </Box>

        <Box className={classes.mailCellContentAction}>
          <Box
            className={classes.avatarRoot}
            mr={{ xs: 4, md: 6 }}
            onMouseEnter={onShowSenderInfo}
            onMouseLeave={onHideSenderInfo}
          >
            {/* <CmtAvatar size={viewMode === 'detail' ? 56 : 40} src={senderDetails?.profile_pic} alt={senderDetails?.name} /> */}
            <Box onClick={event => event.stopPropagation()}>
              <SenderInfo
                showSenderInfo={showSenderInfo}
                from={senderDetails}
                onHideSenderInfo={onHideSenderInfo}
                onClickSendMail={onClickSendMail}
              />
            </Box>
          </Box>

          <Box className={classes.mailCellContentRoot}>
            {viewMode === 'detail' && (
              <Typography className={classes.mailSenderName}>
                {senderDetails?.name}
              </Typography>
            )}
            <Typography
              className={classes.titleRoot}
              component="div"
              variant="h4"
            >
              {/* {subject ? subject : 'No subject'} */}
              {emailContent?.subject ? emailContent?.subject : 'No subject'}
            </Typography>
            <Typography className={classes.subTitleRoot}>
              {' '}
              {emailContent?.body}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box className={classes.mailCellAction}>
        <Box className={classes.mailCellTimeAction}>
          {filterType?.selectedLabel?.length > 0 && (
            <Chip label={status} size="small" color="secondary" />
          )}

          {filterType.selectedLabel?.length === 0 &&
          labels.length > 0 &&
          labelsList.length > 0 &&
          labels[0] === 'Cancelled' &&
          status === 'scheduled' ? (
            <Box
              className={classes.badgeRoot}
              component="span"
              style={{
                backgroundColor: `${alpha('#FF0000', 0.1)}`,
                color: '#FF0000',
              }}
            >
              {labels[0]}
            </Box>
          ) : (
            <MailLabels mailLabels={labels} labelsList={labelsList} />
          )}
          <Box component="span" className={classes.mailTimeRoot}>
            {status === 'scheduled'
              ? getDateInDesiredFormat(scheduleOn, 'DD/MM/YYYY hh:mm A')
              : status === 'sent'
              ? getDateInDesiredFormat(sentDate, 'DD/MM/YYYY')
              : status === 'inbox'
              ? getDateInDesiredFormat(createdAt, 'DD/MM/YYYY')
              : status === 'trash' ?
                getDateInDesiredFormat(trashDate, 'DD/MM/YYYY hh:mm A')
              : getDateInDesiredFormat(updatedAt, 'DD/MM/YYYY')}
          </Box>
        </Box>
        <Box
          className={classes.mailCellBtnAction}
          onClick={e => e.stopPropagation()}
        >
          {status === 'inbox' ? (
            <Tooltip title="Approve">
              <IconButton
                className="icon-btn"
                onClick={() =>
                  onClickForwardMail(emailContent?.html, mail?._id)
                }
              >
                <Done />
              </IconButton>
            </Tooltip>
          ) : null}

          {status === 'inbox' ? (
            <Tooltip title="Disapprove">
              <IconButton
                className="icon-btn"
                onClick={() => handleAction('disapprove')}
              >
                <Cancel />
              </IconButton>
            </Tooltip>
          ) : null}

          {status === 'disapprove' ||
          status === 'trash' ||
          status === 'archive' ? (
            <Tooltip title="Inbox">
              <IconButton
                className="icon-btn"
                onClick={() => handleAction('inbox')}
              >
                <Inbox />
              </IconButton>
            </Tooltip>
          ) : null}

          {status === 'inbox' ||
          status === 'approve' ||
          status === 'disapprove' ||
          status === 'trash' ? (
            <Tooltip title="Archive">
              <IconButton
                className="icon-btn"
                onClick={() => handleAction('archive')}
              >
                <Archive />
              </IconButton>
            </Tooltip>
          ) : null}

          {status === 'inbox' ||
          status === 'approve' ||
          status === 'disapprove' ||
          status === 'archive' ? (
            <Tooltip title="Trash">
              <IconButton
                className="icon-btn"
                onClick={() => handleAction('trash')}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          ) : null}

          {status === 'sent' || status === 'approve' ? (
            <Tooltip title="forward">
              <IconButton
                className="icon-btn"
                onClick={() => {
                  localStorage.setItem('forward', true);
                  onClickForwardMail(emailContent?.html, mail?._id);
                }}
              >
                <Shortcut />
              </IconButton>
            </Tooltip>
          ) : null}

          {status === 'scheduled' && !canceled ? (
            <Tooltip title="Cancel Schedule">
              <IconButton
                className="icon-btn"
                onClick={() => handleAction('cancel')}
              >
                <CancelScheduleSend />
              </IconButton>
            </Tooltip>
          ) : null}

          {status === 'scheduled' ? (
            <Tooltip title="Reschedule">
              <IconButton
                className="icon-btn"
                onClick={() => {
                  localStorage.setItem('re-schedule', true);
                  onClickForwardMail(emailContent?.html, mail?._id);
                }}
              >
                <Schedule />
              </IconButton>
            </Tooltip>
          ) : null}

          {canceled && status === 'scheduled' ? (
            <Tooltip title="Send">
              <IconButton
                className="icon-btn"
                onClick={() => {
                  handleAction('send');
                }}
              >
                <Send />
              </IconButton>
            </Tooltip>
          ) : null}
        </Box>
      </Box>

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm ${actionName} `}
        content={
          actionName === 'cancel'
            ? 'Are you sure, you want to cancel this schedule email?'
            : actionName === 'send'
            ? 'Are you sure, you want to send this email immediately?'
            : actionName === 'inbox'
            ? 'Are you sure, you want to move this email to the inbox?'
            : `Are you sure, you want to ${actionName} this email?`
        }
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={
          actionName === 'send' ? handleSendItConfirm : handleConfirmAction
        }
      />
    </Box>
  );
};

export default MailCell;

MailCell.prototype = {
  mail: PropTypes.object.isRequired,
  labelsList: PropTypes.array.isRequired,
  checkedMails: PropTypes.array.isRequired,
  onChangeCheckedMails: PropTypes.func,
  onClickSendMail: PropTypes.func,
  onClickForwardMail: PropTypes.func,
  viewMode: PropTypes.string,
};

MailCell.defaultProps = {
  viewMode: 'detail',
};
