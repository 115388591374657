
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router';
import Axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { addBlockIp, editBlockIp, fetchBlockIp } from 'redux/actions/Manage/IPBlock/IpBlock';

const AddEditIpBlock = () => {
    const { id } = useParams();

    const [blockData, setBlockData] = useState({
        ipAddress: '',
        status: 'permanentBlock',
        duration: new Date()
    })
    const [error, setError] = useState({
        ipAddress: {
            status: false,
            message: null
        },
        duration: {
            status: false,
            message: null
        }
    })
    const history = useHistory();
    const dispatch = useDispatch();


    const handleChange = function (event) {
        const { name, value } = event.target
        setBlockData((prev) => ({ ...prev, [name]: value }))
    }

    const valiDateField = function () {
        let errorData = { ...error }
        let isError = false;
        const ipv4Pattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        if (!ipv4Pattern.test(blockData?.ipAddress)) {
            errorData.ipAddress = {
                status: true,
                message: 'Please Enter valid IP Address id'
            }
            isError = true;
        } else if (blockData.status === 'temporaryBlock' && !blockData.duration) {
            errorData.duration = {
                status: true,
                message: "Duration is required"
            }
            isError = true;
        } else {
            errorData = {
                ipAddress: {
                    status: false,
                    message: null
                },
                duration: {
                    status: false,
                    message: null
                }
            }
            isError = false
        }
        setError(errorData)
        return isError
    }

    const handleSubmit = async function (event) {
        event.preventDefault();
        const ipBlockData = {
            ...blockData,
            duration:blockData?.status === "permanentBlock" ? null : blockData?.duration
        } 
        const isError = valiDateField(ipBlockData)
        if (isError) return;
        if(!id){
            dispatch(addBlockIp(ipBlockData)).then(() => {
                setBlockData({
                    ipAddress: '',
                    status: 'permanentBlock',
                    duration: new Date()
                });
                history.push('/block-ip');
            })
        }else{
            dispatch(editBlockIp(id,ipBlockData))
        }
    }

    useEffect(() => {
        if (id) {
            dispatch(fetchBlockIp(id)).then((data)=>{
                setBlockData(data)
            })
        }
    }, [id])

    return (
        <div>
            <Box component={Paper} sx={{ padding: "20px 30px" }}>
                <Typography variant='h6' sx={{ px: "10px", borderBottom: "1px solid" }}>Block IP Address</Typography>
                <Box sx={{ my: 3 }}>
                    <form action="">
                        <Grid container justifyContent={"space-between"} rowSpacing={2} columnSpacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    required
                                    size='small'
                                    label={'Enter IP Address'}
                                    name='ipAddress'
                                    value={blockData?.ipAddress}
                                    onChange={handleChange}
                                    error={error?.ipAddress?.status}
                                    helperText={error?.ipAddress?.status && error?.ipAddress?.message}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth size='small'>
                                    <InputLabel id="demo-simple-select-label">Block Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        size='small'
                                        label="Block Status"
                                        name='status'
                                        value={blockData?.status}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={'temporaryBlock'}>Temparory</MenuItem>
                                        <MenuItem value={'permanentBlock'}>Permanent</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    size='small'
                                    fullWidth
                                    type='datetime-local'
                                    label="Select date and time"
                                    value={new Date(new Date(blockData?.duration || new Date()).getTime() + (5.5 * 60 * 60 * 1000))
                                        .toISOString()
                                        .slice(0, 16)}
                                    disabled={blockData?.status !== 'temporaryBlock'}
                                    onChange={(event) => {
                                        const newValue = event.target.value;
                                        if (blockData?.status === 'temporaryBlock') {
                                            setBlockData((prev) => ({ ...prev, duration: new Date(newValue) }));
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Stack justifyContent={"center"} direction={'row'} rowGap={2} columnGap={2} my={3}>
                            <Button variant='outlined' onClick={() => history.push('/block-ip')}>Cancel</Button>
                            <Button variant='contained' type='submit' onClick={handleSubmit}>Submit</Button>
                        </Stack>
                    </form>
                </Box>
            </Box>
        </div>
    )
}

export default AddEditIpBlock