import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import styled from 'styled-components';

import {
  isValidEmail,
  isValidPassword,
  isValidPhone,
} from '@jumbo/utils/commonHelper';
import {
  createProduct,
  getAllProducts,
  updateProduct,
} from 'redux/actions/Manage/Products/Products';
import WysiwygEditorExample from 'components/wysiwygEditor';
import { Tooltip } from 'chart.js';
import { Chip, Divider, Paper } from '@material-ui/core';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { Add, Label, Remove } from '@material-ui/icons';
import { PRODUCT_CREATE_SUCCESS } from 'redux/actions';
import { Typography } from '@mui/material';
import Dropzone from 'react-dropzone';
// import { DropzoneArea } from 'material-ui-dropzone';
import { uploadFile } from 'redux/actions/FileUpload/FileUpload';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import Products from '../../Products';

import {
  createSubProduct,
  setSubProductDetail,
  updateSubProduct,
} from 'redux/actions/Manage/SubProduct/SubProduct';
import { fetchProductMaterialGradeList } from 'redux/actions/Manage/Suppliers/Suppliers';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
import { blue } from '@material-ui/core/colors';
import CustomEditor from 'components/CustomEditor';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  dropZone: {
    minHeight: '100px',
    minWidth: '100px',
    // fullWidth: 'true',
  },
  previewContainer: {
    container: 'true',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  preview: {
    xs: '12',
  },
  errorCk: {
    color: '#f44336',
    marginRight: '14px',
    marginTop: '4px',
    fontSize: '0.75rem',
  },
  textarea: {
    width: '100%',
    height: '100%',
    padding: '8px',
    fontSize: '16px',
    fontFamily: 'inherit',
    fontWeight: 400,
    borderColor: 'lightgray',
    borderRadius: '4px',
    '&:focus': {
      borderColor: '#1C61CA',
      borderWidth: '2px',
      outline: 'none',
      borderRadius: 4,
    },
  },
  textarea_red: {
    width: '100%',
    height: '100%',
    padding: '8px',
    fontSize: '16px',
    fontFamily: 'inherit',
    fontWeight: 400,
    borderColor: '#f44336',
    borderRadius: '4px',
    '&:focus': {
      borderColor: '#1C61CA',
      borderWidth: '2px',
      outline: 'none',
      borderRadius: 4,
    },
  },
  label: {
    fontSize: '16px',
    fontFamily: 'inherit',
    fontWeight: 400,
    lineHeight: 1.43,
  },
  errorLabel: {
    fontSize: '16px',
    fontFamily: 'inherit',
    fontWeight: 400,
    lineHeight: 1.43,
    color: '#f44336',
  },
  grid: {
    marginBottom: '30px',
    marginTop: '-10px',
  },
  gridTitle: {
    marginTop: '13px',
  },
  fileMargin: {
    marginTop: '22px',
  },
  avatar: {
    borderRadius: '0',
    width: 140,
    height: 140,
    objectFit: 'contain',
    objectPosition: 'center',
    padding: 5,
    marginTop: '14px',
  },
}));

const AddEditSubProduct = ({
  history,
  open,
  onCloseDialog,
  setDataUpdated,
  subproduct
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();

  //------------all use selectors
  const { user } = useSelector(({ auth }) => auth.authUser);
  const { products } = useSelector(state => state.SetProductMaterialGradeList);
  // const { subproduct } = useSelector(state => state?.SetSubProductDetail);
  const successAction = useSelector(state => state.Success.action);
  // console.log(subproduct)
  // console.log(products);
  //-------------all use states
  const [keywordsArr, setkeywordsArr] = useState([]);
  const [descriptionHTML, setDescriptionHTML] = useState('');
  const [file, setFile] = useState([]);
  const [newproductId, setnewProductId] = useState('');
  const [productName, setProductName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [productDetail, setproductDetail] = useState({
    // productId:'',
    // code: '',
    description: '',
    // h1: '',
    // h2: '',
    // idOfProductCreator: '',
    keywords: null,
    meta: '',
    name: '',
    // nameOfProductCreator: '',
    title: '',
    AltImageName: '',
    url: '',
    // image_path: '',
    preview: '',
  });
  const [errorText, setErrorText] = React.useState({});
  const [metaCount, setMetaCount] = useState({
    title: 0,
    meta: 0
  })

  //-------all handle functions
  const countWords = str => {
    // Remove leading and trailing whitespaces
    str = str.trim();

    // Remove consecutive whitespaces and replace them with a single space
    str = str.replace(/\s+/g, ' ');

    // Split the string by spaces to get an array of words
    var words = str.split(' ');

    // Return the number of words
    return words.length;
  };




  const validate = () => {
    const errors = {};
    let isError = false;
    const {
      // code,
      description,
      h1,
      h2,
      meta,
      name,
      title,
      url,
      AltImageName,
      image_path,
      preview,
    } = productDetail;

    let tempDescription = descriptionHTML;
    let noSpaceData = tempDescription.replace(/\&nbsp;/g, '');
    const noTagData = noSpaceData.replace(/<[^>]*>/g, '');

    if (!name.trim()) {
      errors.name = 'Please enter name.';
      isError = true;
    }
    // else if (!/^[a-zA-Z0-9 ]*$/.test(name)) {
    //   errors.name = 'Only letters and numbers are allowed.';
    //   isError = true;
    // }
    // if (!code) {
    //   errors.code = 'Please enter code.';
    //   isError = true;
    // }
    // else if (!/^[a-z0-9-]*$/.test(code)) {
    //   errors.code = 'Only small letters with - are allowed.';
    //   isError = true;
    // }
    // if (!description) {
    //   errors.description = 'Please enter name.';
    //   isError = true;
    // }
    // if (!h1) {
    //   errors.h1 = 'Please enter h1.';
    //   isError = true;
    // } else if (!/^[a-zA-Z0-9 ]*$/.test(h1)) {
    //   errors.h1 = 'Only letters and numbers are allowed.';
    //   isError = true;
    // }
    // if (!h2) {
    //   errors.h2 = 'Please enter h2.';
    //   isError = true;
    // } else if (!/^[a-zA-Z0-9 ]*$/.test(h2)) {
    //   errors.h2 = 'Only letters and numbers are allowed.';
    //   isError = true;
    // }
    if (!meta) {
      errors.meta = 'Please enter meta.';
      isError = true;
    }
    if (countWords(meta) > 300) {
      errors.meta = 'Meta description must be less than 300 words.';
      isError = true;
    }
    if (!title) {
      errors.title = 'Please enter title.';
      isError = true;
    }

    if (!subproduct && !productName) {
      errors.Pname = 'Please select atleast 1 product.';
      isError = true;
    }
    if (!url) {
      errors.url = 'Please enter URL.';
      isError = true;
    }
    if (!keywordsArr.length) {
      errors.keywords = 'Please enter keyword.';
      isError = true;
    }

    if (!file.length && !preview) {
      errors.file = 'Please upload image.';
      isError = true;
    }
    if (!displayName) {
      errors.displayName = 'please enter display name.';
      isError = true;
    }
    if (noTagData.length < 2) {
      errors.descriptionHTML = 'Please enter description.';
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };
  const validateKeyword = () => {
    let error = '';
    let isError = false;
    // const { keywords } = productDetail;
    if (!keywords) {
      error = 'Please enter keywords.';
      isError = true;
    }
    setErrorText({ ...errorText, keywords: error });
    return {
      error,
      isError,
    };
  };

  const onKeywordsAdd = () => {
    let validateData = validateKeyword();
    if (!validateData.isError) {
      setkeywordsArr(keywordsArr => [...keywordsArr, keywords]);
      setproductDetail({ ...productDetail, keywords: '' });
    }
  };

  const onKeywordsDelete = (e, value) => {
    setkeywordsArr(keywordsArr => keywordsArr.filter(e => e != value));
  };
  const onLabelChange = evt => {
    setproductDetail({ ...productDetail, [evt.target.name]: evt.target.value });
    setMetaCount({ ...metaCount, [evt.target.name]: evt.target.value.length })
    console.log(productDetail)
  };
  const onLabelChange1 = evt => {
    setnewProductId(evt.target.value.productId);
    setProductName(evt.target.value.name);
    setDisplayName(`${evt.target.value.name} ${productDetail?.name}`);
    // setproductDetail({ ...productDetail,[evt.target.name]: evt.target.value });
  };
  const onLabelChange2 = evt => {
    const { name, value } = evt.target;
    const regex = /[\W_]+|\s+/g;
    if (subproduct) {
      const selectedProduct = products.find(item => item?.productId === subproduct?.productId)
      setDisplayName(`${value} ${selectedProduct?.name}`)
    }
    else if (name === 'name') {
      setDisplayName(`${value} ${productName && productName}`);
    }
    if (subproduct) {
      let PDetail = products.find(e => {
        return e.productId == subproduct.productId;
      });
      const nameValue = value
        ? value.replace(regex, '-').toLowerCase() +
        '-' +
        PDetail.name.replace(regex, '-').toLowerCase()
        : '';
      let newNameValue = nameValue.replace('--', '-');
      // productDetail.code = newNameValue;
      productDetail.url = newNameValue;
      setproductDetail({ ...productDetail, [name]: value });
    }
    // if (name === 'name') {
    else {
      const nameValue = value
        ? value.replace(regex, '-').toLowerCase() +
        '-' +
        productName.replace(regex, '-').toLowerCase()
        : '';
      let newNameValue = nameValue.replace('--', '-');
      // console.log(newNameValue);
      // productDetail.code = newNameValue;
      productDetail.url = newNameValue;
      // }
      setproductDetail({ ...productDetail, [name]: value });
    }
  };
  const onSubmitClick = () => {
    let validateData = validate();
    if (!validateData.isError) {
      onproductSave();
    }
  };

  const onproductSave = () => {
    let keywordStr = '';
    keywordsArr.map(e => (keywordStr += ',' + e));
    const productDetail = {
      // code,
      description: descriptionHTML,
      // h1,
      // h2,
      // idOfProductCreator: user?.userId,
      keywords: keywordStr,
      meta,
      name,
      AltImageName,
      // nameOfProductCreator: user?.name,
      title,
      // productId:newproductId
      url,
      // image_path,
      logo: file[0] ? file[0] : preview,
      displayName
    };


    let formData = new FormData();
    // formData.append('code', code); 
    formData.append('description', descriptionHTML);
    formData.append('displayName', displayName);
    formData.append('keywords', keywordsArr);
    formData.append('logo', file[0] ? file[0] : preview);
    formData.append('meta', meta);
    formData.append('name', name);
    formData.append('title', title);
    formData.append('url', url);
    formData.append('AltImageName', AltImageName);




    if (id) {
      formData.append('status', subproduct?.status);
      formData.append('active', subproduct?.active);
      formData.append('subProductId', subproduct?.subProductId);
      formData.append('productId', subproduct?.productId);
      formData.append('id', id);
      let newProduct = { ...subproduct, ...productDetail };
      const { _id, ...data } = newProduct;
      dispatch(
        updateSubProduct(formData, data => {
          if (data) window.reload()
        }),
      );
    } else {
      formData.append('productId', newproductId);
      dispatch(
        createSubProduct(
          formData,
          // { ...productDetail, productId: newproductId },
          data => {
            if (data) history.push('/manage-subproducts');
          },
        ),
      );
    }
  };


  // const onproductSave = () => {
  //   let keywordStr = '';
  //   keywordsArr.map(e => (keywordStr += ',' + e));
  //   const productDetail = {
  //     code,
  //     // description: descriptionHTML,
  //     // h1,
  //     // h2,
  //     // idOfProductCreator: user?.userId,
  //     keywords: keywordStr,
  //     meta,
  //     name,
  //     // nameOfProductCreator: user?.name,
  //     title,
  //     // productId:newproductId
  //     url,
  //     // image_path
  //   };
  //   if (subproduct) {
  //     let newProduct = { ...subproduct, ...productDetail };
  //     const { _id, ...data } = newProduct;
  //     dispatch(
  //       updateSubProduct(data, data => {
  //         if (data) history.push('/manage-subproducts');
  //       }),
  //     );
  //   } else {
  //     dispatch(
  //       createSubProduct(
  //         { ...productDetail, productId: newproductId },
  //         data => {
  //           if (data) history.push('/manage-subproducts');
  //         },
  //       ),
  //     );
  //   }
  // };

  const handleFileUpload = files => {
    // console.log(files, "file")
    let formData = new FormData();
    formData.append('fileName', files[0]?.name);
    formData.append('fileCategory', 'logo');
    formData.append('uploadedFile', files[0]);
    dispatch(uploadFile(formData));
    // const payload = {
    //   fileName: files?.name,
    //   fileCategory: 'logo'
    // }
  };

  //------all useEffects

  useEffect(() => {
    if (subproduct && id) {
      const {
        // code,
        description,
        h1,
        h2,
        idOfProductCreator,
        keywords,
        meta,
        name,
        nameOfProductCreator,
        title,
        url,
        AltImageName,
        image_path,
        logo,
      } = subproduct;
      setproductDetail({
        // code,
        description,
        h1,
        h2,
        idOfProductCreator,
        keywords: '',
        meta,
        name,
        nameOfProductCreator,
        title,
        AltImageName,
        url,
        image_path,
        preview: logo
      });
      setMetaCount({ ...metaCount, title: title.length, meta: meta.length })
      setDisplayName(subproduct?.displayName);
      setDescriptionHTML(description)
      const x = keywords.split(',');
      let data = x.filter((item, index) => item !== '');
      setkeywordsArr(data);
    }
  }, [subproduct]);

  // useEffect(() => {
  //   if (id) {
  //     dispatch(setSubProductDetail(id));
  //   }
  // }, [id])

  useEffect(() => {
    if (!subproduct) {
      dispatch(fetchProductMaterialGradeList());
    }
  }, []);

  const {
    // code,
    description,
    h1,
    h2,
    keywords,
    meta,
    name,
    title,
    AltImageName,
    url,
    image_path,
    preview,
  } = productDetail;
  return (
    // <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
    //   <DialogTitle className={classes.dialogTitleRoot}>{product ? 'Edit product Details' : 'Create New product'}</DialogTitle>
    //   <DialogContent dividers>
    <PageContainer>
      <div>
        <Paper className={classes.paper}></Paper>
        <CmtCard>
          <CmtCardContent>
            <Typography variant="h6">
              {id ? 'Edit SubProduct' : 'Add SubProduct'}
            </Typography>
            <Divider></Divider>
            <Box
              mt={3}
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
              alignItems="center"
              mb={{ xs: 6, md: 5 }}
            >
              <GridContainer>
                <Grid item xs={12} sm={6}>
                  {subproduct ? (
                    <AppTextInput
                      required
                      fullWidth
                      name="name"
                      variant="outlined"
                      label="SubProduct Name"
                      value={name}
                      onChange={onLabelChange2}
                      helperText={errorText?.name}
                    />
                  ) : (
                    <AppSelectBox
                      name="productId"
                      required
                      variant="outlined"
                      label="Select Product"
                      // value={productName}
                      onChange={onLabelChange1}
                      // helperText={errorText?.name}
                      fullWidth
                      data={products && products}
                      valueKey=""
                      labelKey="name"
                      // value={status}
                      // onChange={onLabelChange}
                      helperText={errorText?.Pname}
                    />
                  )}
                </Grid>

                {subproduct ? (
                  ''
                ) : (
                  <Grid item xs={12} sm={6}>
                    <AppTextInput
                      fullWidth
                      required
                      name="name"
                      variant="outlined"
                      label="SubProduct Name"
                      value={name}
                      onChange={onLabelChange2}
                      helperText={errorText?.name}
                    />
                  </Grid>
                )}
                {/* <Grid item xs={12} sm={6}>
                  <AppTextInput
                    required
                    fullWidth
                    name="code"
                    variant="outlined"
                    label="Code"
                    value={code}
                    onChange={onLabelChange}
                    helperText={errorText?.code}
                  />
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    required
                    fullWidth
                    name="url"
                    variant="outlined"
                    label="URL"
                    value={url}
                    onChange={onLabelChange}
                    helperText={errorText?.url}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <AppTextInput
                    fullWidth
                    name='h1'
                    variant="outlined"
                    label="H1"
                    value={h1}
                    onChange={onLabelChange}
                    helperText={errorText?.h1}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    fullWidth
                    name='h2'
                    variant="outlined"
                    label="H2"
                    value={h2}
                    onChange={onLabelChange}
                    helperText={errorText?.h2}
                  />
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    required
                    fullWidth
                    name="displayName"
                    variant="outlined"
                    label="Display Name"
                    value={displayName}
                    // className={classes.gridTitle}
                    onChange={evt => setDisplayName(evt.target.value)}
                    helperText={errorText?.displayName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    // required
                    fullWidth
                    name="AltImageName"
                    variant="outlined"
                    label="Alt Image Name"
                    value={AltImageName}
                    // className={classes.gridTitle}
                    onChange={onLabelChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    required
                    fullWidth
                    name="title"
                    variant="outlined"
                    label={`Meta Title / ${metaCount.title}`}
                    value={title}
                    // className={classes.gridTitle}
                    onChange={onLabelChange}
                    helperText={errorText?.title}
                  />
                </Grid>

                {/* <Grid className={classes.grid} item xs={12} sm={6}>
                  <label className={classes.label}>Meta Description*</label>
                  <textarea
                    required
                    className={
                      errorText.meta ? classes.textarea_red : classes.textarea
                    }
                    // className="root"
                    name="meta"
                    rows={3}
                    width={100}
                    variant="outlined"
                    value={meta}
                    onChange={onLabelChange}
                    helperText={errorText?.meta}
                  />
                  {errorText.meta ? (
                    <p style={{ color: '#f44336', fontSize: '12px' }}>
                      Please enter Description
                    </p>
                  ) : (
                    ''
                  )}
                </Grid> */}

                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    fullWidth
                    name="file"
                    variant="outlined"
                    type="file"
                    // className={classes.fileMargin}
                    onChange={evt => setFile(evt.target.files)}
                    helperText={errorText?.file}
                  />
                </Grid>

                <Grid className={classes.grid} item xs={12} sm={6}>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <label className={errorText?.meta ? classes.errorLabel : classes.label}>Meta Description*</label>
                    <label className={classes.label}>{metaCount.meta}</label>
                  </Box>
                  <textarea
                    required
                    className={
                      errorText.meta ? classes.textarea_red : classes.textarea
                    }
                    // className="root"
                    name="meta"
                    rows={3}
                    width={100}
                    variant="outlined"
                    value={meta}
                    onChange={onLabelChange}
                    helperText={errorText?.meta}
                  />
                  {errorText.meta ? (
                    <p style={{ color: '#f44336', fontSize: '12px' }}>
                      {errorText?.meta}
                    </p>
                  ) : (
                    ''
                  )}
                </Grid>

                {preview && <img src={preview} alt={AltImageName} className={classes?.avatar} />}

                {/* <Grid item xs={12} sm={6}> */}
                {/* <Typography component="h3" variant="p" className="mb-2">
                    Upload Logo
                  </Typography> */}
                {/* <DropzoneArea
                    initialFiles={[file[0]?.url]}
                    required
                    dropzoneClass={classes.dropZone}
                    previewGridClasses={{
                      container: classes.previewContainer,
                      item: classes.preview,
                    }}
                    filesLimit={1}
                    acceptedFiles={['image/*']}
                    dropzoneText={'Upload Logo | Drag and drop an image here or click'}
                    // onChange={files => setFile(files)}
                    onChange={files => handleFileUpload(files)}
                    showAlerts={false}
                    onDelete={() => setFile([])}
                  /> */}
                {/* <p className={classes.errorCk}>{errorText.file ? errorText.file : ''}</p> */}
                {/* <AppTextInput
                    fullWidth
                    name='image_path'
                    variant="outlined"
                    label="Upload Image"
                    value={image_path}
                    onChange={onLabelChange}
                    helperText={errorText?.image_path}
                  /> */}
                {/* </Grid> */}
                <Grid item xs={12} sm={11}>
                  <AppTextInput
                    required
                    fullWidth
                    name="keywords"
                    variant="outlined"
                    label="Keywords"
                    value={keywords}
                    onChange={onLabelChange}
                    helperText={errorText?.keywords}
                  />
                </Grid>
                <Grid item sm={1}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={onKeywordsAdd}
                  >
                    <Add />
                  </Button>
                </Grid>
                {keywordsArr && keywordsArr.length > 0 && (
                  <Grid item xs={12} sm={12}>
                    <Box>
                      {keywordsArr.map((e, key) => {
                        return (
                          e.length !== 0 && (
                            <Chip
                              size="medium"
                              key={e}
                              label={e}
                              clickable
                              onDelete={evt => onKeywordsDelete(evt, e)}
                              color="secondary"
                              variant="outlined"
                            />
                          )
                        );
                      })}
                    </Box>
                  </Grid>
                )}
                {/* <Grid item xs={12} sm={12}>
                  <WysiwygEditorExample defaultDescription={description} setDescriptionHTML={setDescriptionHTML} descriptionHTML={description} />
                </Grid> */}
              </GridContainer>
            </Box>
            <span
              className={
                errorText?.descriptionHTML ? classes?.errorLabel : classes?.label
              }
            >
              Description *
            </span>
            <Grid item xs={12} sm={12}>
              {/* <WysiwygEditorExample
                defaultDescription={description}
                setDescriptionHTML={setDescriptionHTML}
                descriptionHTML={description}
              /> */}

              <CustomEditor
                editorData={descriptionHTML || ' '}
                setEditorData={setDescriptionHTML}
                error={errorText?.descriptionHTML}
              />
              <span className={classes?.errorCk}>
                {errorText?.descriptionHTML}
              </span>
            </Grid>
            <Box display="flex" justifyContent="flex-end" mb={4}>
              <Button onClick={() => history.push('/manage-subproducts')}>
                Cancel
              </Button>
              <Box ml={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onSubmitClick}
                >
                  {id ? 'Update' : 'Save'}
                </Button>
              </Box>
            </Box>
          </CmtCardContent>
        </CmtCard>
      </div>
    </PageContainer>
    //   </DialogContent>
    // </Dialog >
  );
};

export default AddEditSubProduct;

AddEditSubProduct.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
