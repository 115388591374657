//-------------SUPPLIER constants------------------
export const SUPPLIER_LIST_REQUEST = "SUPPLIER_LIST_REQUEST";
export const SUPPLIER_LIST_SUCCESS = "SUPPLIER_LIST_SUCCESS";
export const SUPPLIER_LIST_FAILED = "SUPPLIER_LIST_FAILED";
export const DELETE_BULK_SUPPLIER_REQUEST = "DELETE_BULK_SUPPLIER_REQUEST";
export const DELETE_BULK_SUPPLIER_SUCCESS = "DELETE_BULK_SUPPLIER_SUCCESS";
export const DELETE_BULK_SUPPLIER_FAILED = "DELETE_BULK_SUPPLIER_FAILED";
export const SET_SUPPLIER_DETAILS = "SET_SUPPLIER_DETAILS";
export const CLEAR_SUPPLIER_DETAILS = "CLEAR_SUPPLIER_DETAILS";
export const SUPPLIER_CREATE_REQUEST = "SUPPLIER_CREATE_REQUEST";
export const SUPPLIER_CREATE_SUCCESS = "SUPPLIER_CREATE_SUCCESS";
export const SUPPLIER_CREATE_FAILED = "SUPPLIER_CREATE_FAILED";
export const SUPPLIER_PRODUCTLIST_REQUEST = 'SUPPLIER_PRODUCTLIST_REQUEST';
export const SUPPLIER_PRODUCTLIST_SUCCESS = 'SUPPLIER_PRODUCTLIST_SUCCESS';
export const SUPPLIER_PRODUCTLIST_FAILED = 'SUPPLIER_PRODUCTLIST_FAILED';
export const SUPPLIER_PRODUCT_CREATE_REQUEST = "SUPPLIER_PRODUCT_CREATE_REQUEST";
export const SUPPLIER_PRODUCT_CREATE_SUCCESS = "SUPPLIER_PRODUCT_CREATE_SUCCESS";
export const SUPPLIER_PRODUCT_CREATE_FAILED = "SUPPLIER_PRODUCT_CREATE_FAILED";
export const SUPPLIER_UPDATE_REQUEST = "SUPPLIER_UPDATE_REQUEST";
export const SUPPLIER_UPDATE_SUCCESS = "SUPPLIER_UPDATE_SUCCESS";
export const SUPPLIER_UPDATE_FAILED = "SUPPLIER_UPDATE_FAILED";
export const SUPPLIER_PROFILE_UPDATE_REQUEST = "SUPPLIER_PROFILE_UPDATE_REQUEST";
export const SUPPLIER_PROFILE_UPDATE_SUCCESS = "SUPPLIER_PROFILE_UPDATE_SUCCESS";
export const SUPPLIER_PROFILE_UPDATE_FAILED = "SUPPLIER_PROFILE_UPDATE_FAILED";
export const SUPPLIER_PASSWORD_UPDATE_REQUEST = "SUPPLIER_PASSWORD_UPDATE_REQUEST";
export const SUPPLIER_PASSWORD_UPDATE_SUCCESS = "SUPPLIER_PASSWORD_UPDATE_SUCCESS";
export const SUPPLIER_PASSWORD_UPDATE_FAILED = "SUPPLIER_PASSWORD_UPDATE_FAILED";
export const SUPPLIER_DELETE_REQUEST = "SUPPLIER_DELETE_REQUEST";
export const SUPPLIER_DELETE_SUCCESS = "SUPPLIER_DELETE_SUCCESS";
export const SUPPLIER_DELETE_FAILED = "SUPPLIER_DELETE_FAILED";
export const SUPPLIER_ADDPRODUCT_REQUEST = "SUPPLIER_ADDPRODUCT_REQUEST";
export const SUPPLIER_ADDPRODUCT_SUCCESS = "SUPPLIER_ADDPRODUCT_SUCCESS";
export const SUPPLIER_ADDPRODUCT_FAILED = "SUPPLIER_ADDPRODUCT_FAILED";
export const SUPPLIER_EDITPRODUCT_REQUEST = 'SUPPLIER_EDITPRODUCT_REQUEST';
export const SUPPLIER_EDITPRODUCT_SUCCESS = 'SUPPLIER_EDITPRODUCT_SUCCESS';
export const SUPPLIER_EDITPRODUCT_FAILED = 'SUPPLIER_EDITPRODUCT_FAILED';
export const SUPPLIER_SETPRODUCT_SUCCESS = "SUPPLIER_SETPRODUCT_SUCCESS";
export const SUPPLIER_SETPRODUCT_REQUEST = "SUPPLIER_SETPRODUCT_REQUEST";
export const SUPPLIER_SETPRODUCT_FAILED = "SUPPLIER_SETPRODUCT_FAILED";
export const PRODUCT_MATERIAL_GRADE_LIST_REQUEST = "PRODUCT_MATERIAL_GRADE_LIST_REQUEST";
export const PRODUCT_MATERIAL_GRADE_LIST_SUCCESS = "PRODUCT_MATERIAL_GRADE_LIST_SUCCESS";
export const PRODUCT_MATERIAL_GRADE_LIST_FAILED = "PRODUCT_MATERIAL_GRADE_LIST_FAILED";
export const DELETE_PRODUCTIMAGE_REQUEST = 'DELETE_PRODUCTIMAGE_REQUEST';
export const DELETE_PRODUCTIMAGE_SUCCESS = 'DELETE_PRODUCTIMAGE_SUCCESS';
export const DELETE_PRODUCTIMAGE_FAILED = 'DELETE_PRODUCTIMAGE_FAILED';
export const UPDATE_PRODUCTIMAGE_REQUEST = 'UPDATE_PRODUCTIMAGE_REQUEST';
export const UPDATE_PRODUCTIMAGE_SUCCESS = 'UPDATE_PRODUCTIMAGE_SUCCESS';
export const UPDATE_PRODUCTIMAGE_FAILED = 'UPDATE_PRODUCTIMAGE_FAILED';
//  supplier products 

export const SUPPLIER_SETPRODUCTLIST_REQUEST = 'SUPPLIER_SETPRODUCTLIST_REQUEST';
export const SUPPLIER_SETPRODUCTLIST_SUCCESS = 'SUPPLIER_SETPRODUCTLIST_SUCCESS';
export const SUPPLIER_SETPRODUCTLIST_FAILED = 'SUPPLIER_SETPRODUCTLIST_FAILED';

// Fecth grades
export const FETCH_GRADE_REQUEST = 'FETCH_GRADE_REQUEST';
export const FETCH_GRADE_SUCCESS = 'FETCH_GRADE_SUCCESS';
export const FETCH_GRADE_FAILED = 'FETCH_GRADE_FAILED';

//Hot product
export const FETCH_HOT_PRODUCT_REQUEST = 'FETCH_HOT_PRODUCT_REQUEST';
export const FETCH_HOT_PRODUCT_SUCCESS = 'FETCH_HOT_PRODUCT_SUCCESS';
export const FETCH_HOT_PRODUCT_FAILED = 'FETCH_HOT_PRODUCT_FAILED';
