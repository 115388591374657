import { CUSTOMERS_LIST_SUCCESS, CUSTOMER_CREATE_SUCCESS, CUSTOMER_DELETE_SUCCESS, CUSTOMER_UPDATE_SUCCESS, SET_CUSTOMER_DETAILS } from "redux/actions/index";

const INIT_STATE =
{
    customers: [],
    customer: null,
    total: 0,
    perPage: 0
    // permissions: null,
}
const INIT_STATE_DETAIL =
{
    customer: null,
}


export const CustomersReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CUSTOMER_CREATE_SUCCESS: {
            return {
                ...state,
                customer: action.data,
                action: action.type,
                message: action.message,
                customers: [action.data, ...state.customers]
            };
        }
        case CUSTOMERS_LIST_SUCCESS: {
            return {
                // ...state,
                customers: action.data,
                action: action.type,
                total: action.total,
                message: action.message,
                perPage: action.perPage
            };
        }
        //customers: state.customers.map(customer => (customer._id === action.payload.id ? action.payload : customer))
        case CUSTOMER_UPDATE_SUCCESS: {
            return {
                ...state,
                customer: action.data,
                action: action.type,
                message: action.message,
                customers: state.customers.map(customer => (customer._id === action.data._id ? action.data : customer))
            };
        }
        case CUSTOMER_DELETE_SUCCESS: {
            return {
                ...state,
                customer: action.data,
                action: action.type,
                message: action.message,
                customers: state.customers.filter(customer => customer._id !== action.data._id),
            };
        }
        // case DELETE_BULK_USERS_SUCCESS: {
        //     return {
        //         ...state,
        //         action: action.type,
        //         message: action.message,
        //         customers: state.customers.filter(customer => !action.data.includes(customer._id)),
        //     };
        // }
        default:
            return state;
    }
};
export const SetCustomerDetailReducer = (state = INIT_STATE_DETAIL, action) => {
    switch (action.type) {
        case SET_CUSTOMER_DETAILS:
            return {
                customer: action.data,
                action: action.type,
            };
        default:
            return state;
    }
};