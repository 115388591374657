import { DELETE_BULK_MATERIAL_SUCCESS, MATERIAL_CREATE_SUCCESS, MATERIAL_DELETE_SUCCESS, MATERIAL_GRADE_SUCCESS, MATERIAL_LIST_SUCCESS, MATERIAL_UPDATE_SUCCESS, SET_MATERIAL_DETAILS, SET_SINGLEMATERAIL_DETAILS } from "redux/actions/index";

const INIT_STATE =
{
    materials: [],
    material: null,
    material_grade: [],
    total: 0,
    perPage: 0
    // permissions: null,
}
const INIT_STATE_DETAIL =
{
    singleMaterial:null,
    material: null,
}

export const MaterialsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case MATERIAL_CREATE_SUCCESS: {
            return {
                ...state,
                material: action.data,
                action: action.type,
                message: action.message,
                materials: [action.data, ...state.materials]
            };
        }
        case MATERIAL_LIST_SUCCESS: {
            return {
                ...state,
                materials: action.data,
                total: action.total,
                action: action.type,
                message: action.message,
                perPage: action.perPage,
            };
        }
        //materials: state.materials.map(material => (material.id === action.payload.id ? action.payload : material))
        case MATERIAL_UPDATE_SUCCESS: {
            return {
                ...state,
                material: action.data,
                action: action.type,
                message: action.message,
                materials: state.materials.map(material => (material._id === action.data._id ? action.data : material))
            };
        }
        case MATERIAL_DELETE_SUCCESS: {
            return {
                ...state,
                material: action.data,
                action: action.type,
                message: action.message,
                materials: state.materials.filter(material => material._id !== action.data._id),
            };
        }
        case MATERIAL_GRADE_SUCCESS: {
            return {
                ...state,
                material_grade: action.data,
                // total: action.total,
                action: action.type,
                message: action.message,
                // perPage: action.perPage,
            };
        }
        case DELETE_BULK_MATERIAL_SUCCESS: {
            return {
                ...state,
                ...state,
                action: action.type,
                message: action.message,
                materials: state.materials.filter(material => !action.data.includes(material.id)),
            };
        }
        default:
            return state;
    }
};
export const SetMaterialDetailReducer = (state = INIT_STATE_DETAIL, action) => {
    switch (action.type) {
        case SET_MATERIAL_DETAILS:
            return {
                ...state,
                material: action.data,
                action: action.type,
            };
        case SET_SINGLEMATERAIL_DETAILS:
            return {
                ...state,
                singleMaterial:action.data
            }
        default:
            return state;
    }
};