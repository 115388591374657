
class TokenService {
    getLocalRefreshToken() {
        const user = JSON.parse(localStorage.getItem("user"));
        return user?.refreshToken;
    }

    getLocalAccessToken() {
        const authToken = JSON.parse(localStorage.getItem("auth-token"));
        return authToken;
    }

    updateLocalAccessToken(token) {
        localStorage.setItem("auth-token", JSON.stringify(token));
        // let user = JSON.parse(sessionStorage.getItem("user"));
        let user = JSON.parse(localStorage.getItem("user"));
        user.token = token
        // sessionStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("user", JSON.stringify(user));
    }

    getUser() {
        // return JSON.parse(sessionStorage.getItem("user"));
        return JSON.parse(localStorage.getItem("user"));
    }

    setUser(user) {
        // sessionStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("user", JSON.stringify(user));
        if (user?.token)
            localStorage.setItem("auth-token", JSON.stringify(user.token));
    }

    removeUser() {
        // sessionStorage.removeItem("user");
        localStorage.removeItem("user");
        localStorage.removeItem("auth-token");
    }
}

export default new TokenService();