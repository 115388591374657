//-------------users constants------------------
// FETCH BLOCKED LIST
export const BLOCKED_USERS_LIST_REQUEST = "BLOCKED_USERS_LIST_REQUEST";
export const BLOCKED_USERS_LIST_SUCCESS = "BLOCKED_USERS_LIST_SUCCESS";
export const BLOCKED_USERS_LIST_FAILED = "BLOCKED_USERS_LIST_FAILED";
// ------------------------------------
export const SET_BLOCKED_USER_DETAILS = "SET_USER_DETAILS";

// POST UNBLOCK USER
export const UNBLOCK_USER_REQUEST = 'UNBLOCK_USER_REQUEST';
export const UNBLOCK_USER_SUCCESS = 'UNBLOCK_USER_SUCCESS';
export const UNBLOCK_USER_FAILED = 'UNBLOCK_USER_FAILED';
// -------------------------

// POST BLOCK USER
export const BLOCK_USER_REQUEST = 'BLOCK_USER_REQUEST';
export const BLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS';
export const BLOCK_USER_FAILED = 'BLOCK_USER_FAILED';

export const BLOCK_SINGLE_USER_REQUEST = 'BLOCK_SINGLE_USER_REQUEST';
export const BLOCK_SINGLE_USER_SUCCESS = 'BLOCK_SINGLE_USER_SUCCESS';
export const BLOCK_SINGLE_USER_FAILED = 'BLOCK_SINGLE_USER_FAILED';

export const BLOCK_ALL_USER_REQUEST = 'BLOCK_ALL_USER_REQUEST';
export const BLOCK_ALL_USER_SUCCESS = 'BLOCK_ALL_USER_SUCCESS';
export const BLOCK_ALL_USER_FAILED = 'BLOCK_ALL_USER_FAILED';