import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import { Delete, Edit, Lock, MoreHoriz, Visibility } from '@material-ui/icons';
import CustomTable from 'components/CustomeTable';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteUser,
  getAllUsers,
  setUserDetail,
  updateUserStatus,
} from 'redux/actions/Manage/Users/Users';
import { history } from 'redux/store';
import AddEditUser from './AddEditUser';
import ChangePassword from './ChangePassword';
import EditPermissions from './EditPermissions';
import { Switch } from '@material-ui/core';

const Users = () => {
  const dispatch = useDispatch();

  //------------all use selectors
  const { user } = useSelector(({ auth }) => auth.authUser);
  const { users, total, perPage } = useSelector(state => state.Users);
  const UserData = useSelector(state => state.SetUserDetail);
  const userDetail = UserData?.user;

  //-------------all use states
  const [orderBy, setOrderBy] = React.useState('name');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState({ name: '' });
  const [usersFetched, setUsersFetched] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [row, setRow] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [permissionData] = useState(
    JSON.parse(localStorage.getItem('permission')),
  );
  const [createPermission, setCreatePermission] = useState(false);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [exportPermission, setExportPermission] = useState(false);
  const [chgPasswordPermission, setChgPasswordPermission] = useState(false);
  const [openChgPassDialog, setOpenChgPassDialog] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  // const [search, setSearch] = useState({ sort: null, field: null });
  const [fileData, setFileData] = useState([]);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [downloadData, setDownloadData] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [openRolePermissionDialog, setOpenRolePermissionDialog] = useState(
    false,
  );
  // const [sort, setSort] = useState({
  //     orderBy: '',
  //     order: 'asc',
  // });

  //-------table config actions
  const getUserActions = user => {
    const actions = [];
    actions.push({ action: 'view', label: 'View', icon: <Visibility /> });
    actions.push({
      action: 'permissions',
      label: 'Permissions',
      icon: <Lock />,
    });
    // chgPasswordPermission && actions.push({ action: 'change_password', label: 'Change Password', icon: <Lock /> })
    // updatePermission && actions.push({ action: 'edit', label: 'Edit', icon: <Edit /> })
    // actions.push({ action: 'edit', label: 'Edit', icon: <Edit /> })
    // deletePermission && actions.push({ action: 'delete', label: 'Delete', icon: <Delete /> });
    // actions.push({ action: 'delete', label: 'Delete', icon: <Delete /> });
    return actions;
  };
  const userActions = getUserActions(row);
  const onUserMenuClick = (menu, elm) => {
    if (menu.action === 'view') {
      dispatch(setUserDetail(elm));
      setOpenViewDialog(true);
    } else if (menu.action === 'change_password') {
      dispatch(setUserDetail(elm));
      setOpenChgPassDialog(true);
      // onUserChangePassword(row);
    } else if (menu.action === 'permissions') {
      dispatch(setUserDetail(elm));
      setOpenRolePermissionDialog(true);
      history.push(`/manage-permissions/${elm?._id}`);
    } else if (menu.action === 'edit') {
      dispatch(setUserDetail(elm));
      setOpenUserDialog(true);
    } else if (menu.action === 'delete') {
      setSelectedUser(elm);
      setOpenConfirmDialog(true);
    }
  };
  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'mobileNo',
      numeric: false,
      disablePadding: false,
      label: 'Contact',
    },
    {
      id: 'active',
      numeric: false,
      disablePadding: false,
      sort: false,
      label: 'Status',
      render: elm => (
        <Switch
          checked={elm.active === true ? true : false}
          onChange={() => handleChangeStatusSwitch(elm)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      ),
    },
    // {
    //   id: 'active',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Status',
    //   render: elm => {
    //     return elm?.active ? 'Active' : 'Inactive';
    //   },
    // },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Actions',
      sort: false,
      render: elm => (
        <>
          {/* {!updatePermission && !deletePermission ? '-' : <CmtDropdownMenu items={userActions} onItemClick={(menu) => onUserMenuClick(menu, elm)} TriggerComponent={<MoreHoriz />} />} */}
          {
            <CmtDropdownMenu
              items={userActions}
              onItemClick={menu => onUserMenuClick(menu, elm)}
              TriggerComponent={<MoreHoriz />}
            />
          }
        </>
      ),
    },
  ];
  //download file setting
  var fileHeaders = [
    {
      key: 'firstName',
      label: 'Name',
    },
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'roleName',
      label: 'Role',
    },
    {
      key: 'departmentName',
      label: 'Dept',
    },
    {
      key: 'status',
      label: 'Status',
    },
  ];

  const getPermission = useCallback(() => {
    permissionData &&
      permissionData.forEach(function(num) {
        if (user?.roleId?.roleSlug === 'admin' || num.name === 'user-create') {
          setCreatePermission(true);
        }
        if (user?.roleId?.roleSlug === 'admin' || num.name === 'user-delete') {
          setDeletePermission(true);
        }
        if (user?.roleId?.roleSlug === 'admin' || num.name === 'user-update') {
          setUpdatePermission(true);
        }
        if (user?.roleId?.roleSlug === 'admin' || num.name === 'user-export') {
          setExportPermission(true);
        }
        if (user?.roleId?.roleSlug === 'admin') {
          setChgPasswordPermission(true);
        }
      });
  }, [permissionData, user]);
  //-------all handle functions

  const handleCloseRolePermissionDialog = () => {
    setOpenRolePermissionDialog(false);
    dispatch(setUserDetail(null));
  };

  const handleChangeStatusSwitch = elm => {
    let status;
    if (elm.active === true) status = false;
    else status = true;
    const data = { isActive: status };
    dispatch(updateUserStatus(elm?._id, data)).then(res => {
      if (res?.status === 200) {
        setDataUpdated(!dataUpdated)
      }
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleViewDialog = user => {
    dispatch(setUserDetail(user));
    setOpenViewDialog(true);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    dispatch(setUserDetail(null));
  };

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    dispatch(setUserDetail(null));
  };

  const handleAddButton = () => {
    setOpenUserDialog(true);
    dispatch(setUserDetail(null));
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    dispatch(
      deleteUser(selectedUser._id, data => {
        if (data) setDataUpdated(data => !data);
      }),
    );
  };

  const handleCloseChgPassDialog = () => {
    setOpenChgPassDialog(false);
    dispatch(setUserDetail(null));
  };
 
  // const onChangePage = query => {
  //     // localStorage.setItem('page', query + 1);
  //     setPage(query + 1);
  // };
  // const onRequestSort = (event, property) => {
  //     const isAsc = sort.order === 'desc';
  //     setSort({ ...sort, orderBy: property, order: isAsc ? 'asc' : 'desc' });
  //     if (sort.order === 'desc')
  //         setSearch({ ...search, sort: 1, field: property })
  //     else if (sort.order === 'asc')
  //         setSearch({ ...search, sort: -1, field: property })
  // };
  // const handleSearch = dateset_name => {
  //   setPage(1);
  //   setSearchTerm(dateset_name);
  // };
  
  const downloadFn = async params => {
    dispatch(
      getAllUsers(
        params,
        data => {
          if (data) {
            let fileData = [];
            data.forEach(item => {
              fileData.push({
                firstName: item.firstName + ' ' + item.lastName,
                email: item.email,
                roleName: item.roleId?.roleName,
                departmentName: item.roleId?.departmentId?.departmentName,
                status: item.status === 1 ? 'Active' : 'Inactive',
              });
            });
            setFileData(fileData);
          }
        },
        true,
      ),
    );
  };

  //------all useEffects
  useEffect(() => {
    let params;
    params = {
      search: searchTerm,
    };
    dispatch(getAllUsers(params), () => {
      setUsersFetched(true);
    });
    getPermission();
  }, [dispatch,  dataUpdated]);






  // useEffect(() => {
  //   //to download data
  //   let params;
  //   params = {
  //     search: searchTerm,
  //   };
  //   downloadFn(params);
  // }, [isDownload]);

  useEffect(() => {
    setDownloadData(data => !data); //update state at custom toolbar component
  }, [fileData]);
  return (
    <>
      <CustomTable
        TableName="Users"
        downloadTableBtn={exportPermission ? true : false}
        fileName="Users.xlsx"
        fileHeaders={fileHeaders}
        fileData={fileData}
        setIsDownload={setIsDownload}
        downloadData={downloadData}
        row={users && users}
        // addButtonText={createPermission ? "Add User" : ""}
        addButtonText={'Add User'}
        handleAddButton={handleAddButton}
        headCells={headCells}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        count={total|| 0}
        rowsPerPage={perPage}
        onPageChange={handlePageChange}
        page={page}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleSearch={(text)=> setSearchTerm(text)}
        handleSearchSubmit={()=> {
          setPage(0)
          setDataUpdated(!dataUpdated)
        }}
        handleViewDialog={handleViewDialog}
        userDetailModal={{
          emailData: userDetail?.email,
          modalHeading: userDetail?.name,
          headerArray: [
            // 'MemberType: ' + userDetail?.membertype,
            userDetail?.address && 'Address: ' + userDetail?.address,
          ],
          sectionHeading: 'Contact Details',
          openViewDialog: openViewDialog,
          handleCloseViewDialog: handleCloseViewDialog,
          activeChip: userDetail?.active,
          phoneData: userDetail?.mobileNo,
        }}
      />
      {openUserDialog && (
        <AddEditUser
          setDataUpdated={setDataUpdated}
          open={openUserDialog}
          onCloseDialog={handleCloseUserDialog}
        />
      )}
      {openChgPassDialog && (
        <ChangePassword
          open={openChgPassDialog}
          onCloseDialog={handleCloseChgPassDialog}
        />
      )}
      {openRolePermissionDialog && (
        <EditPermissions
          open={openRolePermissionDialog}
          onCloseDialog={handleCloseRolePermissionDialog}
        />
      )}
      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete`}
        content={'Are you sure, you want to  delete this user?'}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};

export default Users;
