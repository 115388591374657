import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';

import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import GridContainer from '@jumbo/components/GridContainer';

import { getAllMaterials } from 'redux/actions/Manage/Materials/Materials';
import { getAllProducts } from 'redux/actions/Manage/Products/Products';
import { getAllSubProducts } from 'redux/actions/Manage/SubProduct/SubProduct';
import { getAllGrades } from 'redux/actions/Manage/ProductMaterialGrades';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import WysiwygEditorExample from 'components/wysiwygEditor';
import { addMapping } from 'redux/actions/Manage/Mapping';
import CustomEditor from 'components/CustomEditor';

const useStyles = makeStyles(theme => ({
  avatar: {
    borderRadius: '0',
    height: '50vh',
    width: '20vw',
  },
  errorText: {
    color: 'red'
  },
  grid: {
    marginBottom: "-20px",
    marginTop: "-10px",
    display: "flex",
    flexDirection: "column",
  },
  textarea: {
    width: '100%',
    height: '100%',
    padding: "8px",
    fontSize: "17px",
    fontFamily: 'inherit',
    fontWeight: 300,
    borderColor: "lightgray",
    borderRadius: "4px",

    '&:focus': {
      borderColor: "#1C61CA",
      borderWidth: "2px",
      outline: "none",
      borderRadius: 4
    }
  },
  textarea_red: {
    width: '100%',
    height: '100%',
    padding: "8px",
    fontSize: "19px",
    fontFamily: 'Normal',
    fontWeight: 200,
    borderColor: "#f44336",
    borderRadius: "4px",
    '&:focus': {
      borderColor: "#1C61CA",
      borderWidth: "2px",
      outline: "none",
      borderRadius: 4
    },
  },
}));

const AddEditMapping = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const productData = useSelector(state => state?.Products?.products);
  const materialData = useSelector(state => state?.Materials?.materials);
  const gradeData = useSelector(state => state?.Grades?.grades);
  const [descriptionHTML, setDescriptionHTML] = useState('');
  const subProductData = useSelector(state => state?.SubProducts?.subproducts);

  const [file, setFile] = useState([]);
  const [errorText, setErrorText] = useState({});
  const [mapping, setMapping] = useState({
    productId: '',
    materialId: '',
    gradeId: '',
    subProductId: '',
    name: '',
    meta: '',
    code: '',
    title: '',
    preview: '',
    description: '',
  });

  const handleChange = evt => {
    setMapping({ ...mapping, [evt.target.name]: evt.target.value });
    if (evt.target.name === 'productId') {
      let params = {};
      dispatch(getAllSubProducts(evt.target.value, params));
    }
  };

  const onLabelChange = evt => {
    const { name, value } = evt.target;
    const regex = /[\W_]+|\s+/g;
    if (name === 'name') {
      const nameValue = value ? value.replace(regex, '-').toLowerCase() : '';
      mapping.code = nameValue;
    }
    setMapping({ ...mapping, [name]: value });
  };

  const countWords = str => {
    // Remove leading and trailing whitespaces
    str = str.trim();

    // Remove consecutive whitespaces and replace them with a single space
    str = str.replace(/\s+/g, ' ');

    // Split the string by spaces to get an array of words
    var words = str.split(' ');

    // Return the number of words
    return words.length;
  };

  const validate = () => {
    let errors = {};
    let isError = false;
    // if (!productId) {
    //   errors.productId = 'Please select product.';
    //   isError = true;
    // }
    // if (!materialId) {
    //   errors.materialId = 'Please select material.';
    //   isError = true;
    // }
    // if (!subProductId) {
    //   errors.subProductId = 'Please select sub-product.';
    //   isError = true;
    // }
    // if (!gradeId) {
    //   errors.gradeId = 'Please select grade.';
    //   isError = true;
    // }
    if (!name) {
      errors.name = 'Please enter name.';
      isError = true;
    }
    if (!code) {
      errors.code = 'Please enter code.';
      isError = true;
    }
    if (!meta) {
      errors.meta = 'Please enter meta.';
      isError = true;
    }
    if (countWords(meta) > 300) {
      errors.meta = 'Meta description must be less than 300 words.';
      isError = true;
    }
    if (!title) {
      errors.title = 'Please enter meta title.';
      isError = true;
    }
    if (!descriptionHTML) {
      errors.descriptionHTML = 'Please enter description.';
      isError = true;
    }
    if (!file.length) {
      errors.file = 'Please upload image.';
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };

  const handleSubmit = () => {
    const validation = validate();
    if (!validation.isError) {
      let formData = new FormData();

      formData.append('productId', productId);
      formData.append('gradeId', gradeId);
      formData.append('materialId', materialId);
      formData.append('subProductId', subProductId);
      formData.append('name', name);
      formData.append('code', code);
      // formData.append('code', name.replace(/\s+/g, '-').toLowerCase());
      formData.append('title', title);
      formData.append('meta', meta);
      formData.append('description', descriptionHTML);
      formData.append('logo', file[0]);

      dispatch(addMapping(formData)).then(res => {
        if (res?.status === 201 || res?.status === 200) {
          history.push('manage-mapping');
        }
      });
    }
  };

  useEffect(() => {
    dispatch(getAllProducts({}));
    dispatch(getAllMaterials({}));
    dispatch(getAllGrades({}));
  }, []);

  const {
    productId,
    materialId,
    subProductId,
    gradeId,
    title,
    code,
    name,
    meta,
    preview,
    description,
  } = mapping;

  return (
    <PageContainer>
      <CmtCard>
        <CmtCardContent>
          <Typography variant="h2" className="mb-2">
            Add Mapping
          </Typography>
          <Divider />
          <GridContainer className="mt-2">
            <Grid item md={6} sx={12}>
              <AppSelectBox
                required
                data={productData}
                name="productId"
                label="Product"
                valueKey="productId"
                variant="outlined"
                labelKey="name"
                value={productId}
                displayName="name"
                onChange={handleChange}
                helperText={errorText?.productId}
              />
              <br />
              <br />
              <AppSelectBox
                required
                data={materialData}
                name="materialId"
                label="Material"
                valueKey="materialId"
                variant="outlined"
                labelKey="name"
                value={materialId}
                displayName="name"
                onChange={handleChange}
                helperText={errorText?.materialId}
              />
              <br />
              <br />
              <AppTextInput
                required
                fullWidth
                name="title"
                variant="outlined"
                label="Meta Title"
                value={title}
                onChange={handleChange}
                className="mb-5"
                helperText={errorText?.title}
              />
              <AppTextInput
                required
                fullWidth
                name="name"
                variant="outlined"
                label="Name"
                value={name}
                // onChange={handleChange}
                onChange={onLabelChange}
                className="mt-3"
                helperText={errorText?.name}
              />
              <AppTextInput
                fullWidth
                name="file"
                variant="outlined"
                type="file"
                // value={title}
                onChange={evt => setFile(evt.target.files)}
                className="mt-5"
                helperText={errorText?.file}
              />
            </Grid>
            <Grid item md={6} sx={12}>
              <AppSelectBox
                required
                data={subProductData}
                name="subProductId"
                label="Sub Product"
                valueKey="productId"
                variant="outlined"
                labelKey="name"
                value={subProductId}
                displayName="name"
                onChange={handleChange}
                helperText={errorText?.subProductId}
              />
              <br />
              <br />

              <AppSelectBox
                required
                data={gradeData}
                name="gradeId"
                label="Grade"
                valueKey="gradeId"
                variant="outlined"
                labelKey="name"
                value={gradeId}
                displayName="name"
                onChange={handleChange}
                helperText={errorText?.gradeId}
              />
              <br />
              <br />
              <AppTextInput
                required
                fullWidth
                name="code"
                variant="outlined"
                label="Code"
                value={code}
                // onChange={handleChange}
                onChange={onLabelChange}
                className="mb-5"
                helperText={errorText?.code}
              />
              <Grid className={classes.grid} item xs={12} sm={12}>
                <label className={classes.label}>Meta Description*</label>
                <textarea
                  className={
                    errorText.meta ? classes.textarea_red : classes.textarea
                  }
                  name="meta"
                  rows={4}
                  width={100}
                  variant="outlined"
                  value={meta}
                  onChange={onLabelChange}
                />
                {errorText.meta ? (
                  <p style={{ color: '#f44336', fontSize: '12px' }}>
                    {errorText?.meta}
                  </p>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
            <span
            // className={
            //   errorText?.descriptionHTML ? classes?.editorLabelError : ''
            // }
            >
              Description *
            </span>
            <Grid item xs={12} sm={12}>
              {/* <WysiwygEditorExample
                defaultDescription={description}
                setDescriptionHTML={setDescriptionHTML}
                descriptionHTML={descriptionHTML}
              /> */}

              <CustomEditor
                editorData={descriptionHTML || ''}
                setEditorData={setDescriptionHTML}
              />

              <span className={classes?.errorText}>
                {errorText?.descriptionHTML}
              </span>
            </Grid>
            <Box display="flex" justifyContent="flex-end" mb={4}>
              <Button onClick={() => history.push('/manage-mapping')}>
                Cancel
              </Button>
              <Box ml={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </GridContainer>
        </CmtCardContent>
      </CmtCard>
    </PageContainer>
  );
};

export default AddEditMapping;
