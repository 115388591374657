import React from "react";

import {
  Block,
  ContactMail,
  EmojiTransportation,
  Grade,
  Group,
  LiveTv,
  LocationOff,
  MenuBook,
  PostAdd,
  Widgets,
  AddShoppingCart,
  MonetizationOn,
  MergeType,
  Email,
  Ballot,
  FeaturedPlayList,
  ChildFriendly,
  HighQuality
} from "@material-ui/icons";
import AppsIcon from "@mui/icons-material/Apps";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import IntlMessages from "../../../utils/IntlMessages";

export const sidebarNavs = [
  {
    name: <IntlMessages id={"sidebar.main"} />,
    type: "section",
    children: [
      {
        name: <IntlMessages id={"navSideBar.dashboard"} />,
        type: "item",
        icon: <Widgets />,

        link: "/dashboard",
        showAll: true
      },
      {
        name: <IntlMessages id={"navSideBar.Products"} />,
        icon: <ContactMail />,
        type: "item",
        link: "/manage-products",
        permission: "productAccess"
      },
      {
        name: <IntlMessages id={"navSideBar.AllModule"} />,
        icon: <AppsIcon />,
        type: "item",
        link: "/manage-all-module",
        permission: "allModuleAccess"
      },

      // {
      //   name: <IntlMessages id={'navSideBar.Products'} />,
      //   icon: <ContactMail />,
      //   type: 'collapse',
      //   // link: '/manage-products',
      //   permission: 'productAccess',
      //   children:[
      //     {
      //       name: <IntlMessages id={'navSideBar.Products'} />,
      //       icon: <ContactMail />,
      //       type: 'item',
      //       link: '/manage-products',
      //       permission: 'productAccess',
      //     },
      //     {
      //       name: <IntlMessages id={'navSideBar.SubProducts'} />,
      //       type: 'item',
      //       icon: <Widgets />,
      //       link: '/manage-subproducts',
      //       // permission: 'subProductAccess',
      //       permission: 'subProductAccess',
      //     },
      //   ]
      // },
      {
        name: <IntlMessages id={"navSideBar.SubProducts"} />,
        type: "item",
        icon: <Widgets />,
        link: "/manage-subproducts",
        // permission: 'subProductAccess',
        permission: "subProductAccess"
      },
      {
        name: <IntlMessages id={"navSideBar.Material"} />,
        type: "item",
        icon: <MenuBook />,
        link: "/manage-materials",
        permission: "materialAccess"
      },

      {
        name: <IntlMessages id={"navSideBar.ProductMaterial"} />,
        type: "item",
        icon: <MenuBook />,
        link: "/manage-productMaterial",
        permission: "productMaterialAccess"
      },
      {
        name: <IntlMessages id={"navSideBar.MaterialProductSubProduct"} />,
        icon: <MergeType />,
        type: "item",
        link: "/manage-material-sub-product-product",
        permission: "materialSubProductProductAccess"
      },
      {
        name: <IntlMessages id={"navSideBar.Grade"} />,
        type: "item",
        icon: <HighQuality />,
        link: "/manage-grade",
        permission: "gradeAccess"
      },
      {
        name: <IntlMessages id={"navSideBar.ProductMaterialGrade"} />,
        type: "item",
        icon: <Grade />,
        link: "/manage-product-material-grade",
        permission: "productMaterialGradeAccess"
      },
      {
        name: <IntlMessages id={"navSideBar.MaterialGrade"} />,
        type: "item",
        icon: <Ballot />,
        link: "/manage-material-grade",
        permission: "materialGradeAccess"
      },
      {
        name: <IntlMessages id={"navSideBar.EqGrade"} />,
        type: "item",
        icon: <Grade />,
        link: "/manage-eqgrades",
        permission: "eqGradeAccess"
        // showAll: true
      },

      {
        name: <IntlMessages id={"navSideBar.Suppliers"} />,
        type: "item",
        icon: <EmojiTransportation />,
        link: "/manage-suppliers",
        permission: "supplierAccess"
      },

      {
        name: <IntlMessages id={"navSideBar.Email"} />,
        type: "item",
        icon: <Email />,
        link: "/manage-email",
        permission: "emailAccess"
      },

      // {
      //   name: <IntlMessages id={'navSideBar.Email'} />,
      //   type: 'item',
      //   icon: <Email />,
      //   link: '/test-email',
      //   permission: 'emailAccess',
      //   // permission:'productAccess'
      // },
      // {
      //   name: <IntlMessages id={'navSideBar.Email'} />,
      //   type: 'item',
      //   icon: <ContactMail />,
      //   link: '/manage-emails',
      //   permission: 'emailAccess',
      //   // permission:'productAccess'
      // },

      {
        name: <IntlMessages id={"navSideBar.ManageAdmin"} />,
        icon: <Group />,
        type: "item",
        link: "/manage-admin-users",
        permission: "manageAdminAccess"
      },
      {
        name: <IntlMessages id={"navSideBar.EmailBlock"} />,
        icon: <Block />,
        type: "item",
        link: "/block-email",
        permission: "blockEmailAccess"
      },
      {
        name: <IntlMessages id={"navSideBar.IPBlock"} />,
        icon: <LocationOff />,
        type: "item",
        link: "/block-ip",
        permission: "blockIpAccess"
      },
      {
        name: <IntlMessages id={"navSideBar.Mapping"} />,
        type: "item",
        icon: <AddShoppingCart />,
        link: "/manage-mapping",
        permission: "mappingAccess"
      },
      {
        name: <IntlMessages id={"navSideBar.News"} />,
        type: "item",
        icon: <LiveTv />,
        link: "/manage-news",
        permission: "newsAccess"
      },
      {
        name: <IntlMessages id={"navSideBar.Plan"} />,
        type: "item",
        icon: <MonetizationOn />,
        link: "/manage-plan",
        permission: "planAccess"
      },
      {
        name: <IntlMessages id={"navSideBar.RFQ"} />,
        icon: <LiveHelpIcon />,
        type: "item",
        link: "/manage-rfqs",
        permission: "rfqAccess"
      },
      {
        name: <IntlMessages id={"navSideBar.freeSupplierList"} />,
        icon: <FormatListBulletedIcon />,
        type: "item",
        link: "/manage-sendEmail-rfqs",
        permission: "rfqAccess"
      }
    ]
  }
];

export const horizontalDefaultNavs = [
  {
    name: <IntlMessages id={"sidebar.main"} />,
    type: "collapse",
    children: [
      {
        name: <IntlMessages id={"pages.samplePage"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/sample-page"
      }
    ]
  }
];

export const minimalHorizontalMenus = [
  {
    name: <IntlMessages id={"sidebar.main"} />,
    type: "collapse",
    children: [
      {
        name: <IntlMessages id={"pages.samplePage"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/sample-page"
      }
    ]
  }
];
