import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControlLabel,
  Tooltip,
  Box,
  IconButton,
  Button,
  Dialog,
  Grid,
} from '@material-ui/core';
import {
  Edit,
  Remove,
  Clear,
  Delete,
  Launch,
  Send,
  Photo,
  GetApp,
} from '@material-ui/icons';
import { KeyboardDateTimePicker } from '@material-ui/pickers';

import config from 'utils/Config.util';
import FilePicker from '../FilePicker';
import useStyles from './index.style';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import Chip from '@material-ui/core/Chip';
import { emailNotValid } from '@jumbo/constants/ErrorMessages';
import { isValidEmail } from '@jumbo/utils/commonHelper';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';

import {
  sendMail,
  setEmailListPopUp,
} from 'redux/actions/Manage/Emails/Emails';
import CustomEditor, { HtmlEditor } from 'components/CustomEditor';
import GridContainer from '@jumbo/components/GridContainer';
// import { country } from './CountryData';
import AppAutocomplete from '@jumbo/components/Common/formElements/AppAutocomplete';

const ComposeMail = ({
  openDialog,
  onCloseComposeDialog,
  mailTo,
  mailContent,
  setReload,
  reload,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { pop_up, selectedMail } = useSelector(({ Emails }) => Emails);
  const { filterType } = useSelector(({ mailApp }) => mailApp);
  const [scheduleMail, setScheduleMail] = useState(false);
  const [ack, setAck] = useState(false);
  const [email, setEmail] = useState('');
  const [fromEmail, setFromEmail] = useState('');
  const [to, setTo] = useState([]);
  const [from, setFrom] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [forwardAttachment, setForwardAttachment] = useState([]);
  const [minimize, setMinimize] = useState(false);
  const [toError, setToError] = useState('');
  const [fromError, setFromError] = useState('');
  const [maximize, setMaximize] = useState(false);
  const [selectedDate, setSelectedDate] = React.useState(Date.now());

  const [descriptionHTML, setDescriptionHTML] = useState();
  const [mailDetail, setMailDetail] = useState({
    emailUniqueId: '',
    receiverEmail: '',
    subject: '',
    html: '',
    body: '',
    senderEmail: '',
    setAcknowledge: '',
    scheduleMailStatus: '',
    date: '',
    other_receivers: '',
    emailContent: '',
    emailStatus: '',
    // files:''
  });

  const [senderDetailsData, setSenderDetailsData] = useState({
    name: '',
    // countryId: [],
    country: '',
    contact: '',
    company: '',
  });

  const [errorText, setErrorText] = React.useState({});
  let storageData =
    localStorage.getItem('schedule') !== null
      ? (storageData = localStorage.getItem('schedule'))
      : '';

  let forward =
    localStorage.getItem('forward') !== null
      ? (forward = localStorage.getItem('forward'))
      : '';

  let isCompose =
    localStorage.getItem('compose') !== null
      ? (storageData = localStorage.getItem('compose'))
      : '';

  let isReschedule =
    localStorage.getItem('re-schedule') !== null
      ? (storageData = localStorage.getItem('re-schedule'))
      : '';

  const handleDateChange = date => {
    setSelectedDate(date);
  };

  const handleChange = evt => {
    setSenderDetailsData({
      ...senderDetailsData,
      [evt.target.name]: evt.target.value,
    });
  };

  const validate = () => {
    const errors = {};
    let isError = false;
    const currentTime = moment();

    if (selectedDate?._d < currentTime?._d && scheduleMail) {
      errors.schedule = 'Invalid date and time.';
      isError = true;
    } else if (
      filterType?.selectedFolder === 'scheduled' &&
      selectedDate?._d < currentTime?._d
    ) {
      errors.schedule = 'Please select date and time.';
      isError = true;
    }
    if (mailDetail?.subject.length === 0) {
      errors.subject = 'Please enter subject.';
      isError = true;
    }
    if (to.length === 0) {
      setToError('Please enter to field.');
      isError = true;
    } else if (!to.every(item => isValidEmail(item))) {
      setToError(emailNotValid);
      isError = true;
    }
    if (from.length === 0) {
      setFromError('Please enter from field.');
      isError = true;
    } else if (!from.every(item => isValidEmail(item))) {
      setFromError(emailNotValid);
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };

  const getHeaderTitle = () => (
    <Box display="flex" alignItems="center">
      <Edit />
      <Box component="span" fontSize={{ xs: 14, sm: 16 }} ml={{ xs: 4, xl: 6 }}>
        Compose Message
      </Box>
    </Box>
  );

  const onAddAttachments = files => {
    const newAttachments = files.map(item => {
      return {
        id: item?.id,
        originalFile: item?.file,
        file: {
          name: item?.file?.name,
          type: item?.file?.type,
          size: item?.file?.size,
          path: item?.file?.path,
        },
      };
    });

    setAttachments([...attachments, ...newAttachments]);
  };

  const onDeleteAttachments = index => {
    const updatedAttachments = attachments.filter((item, i) => i !== index);
    setAttachments(updatedAttachments);
  };

  const addTo = () => {
    if (email.trim()) {
      if (!to.length) {
        setTo(to => [...to, email]);
        setEmail('');
      } else {
        if (to.indexOf(email) !== -1) {
          setToError('Email id is already exist');
        } else {
          setTo(to => [...to, email]);
          setEmail('');
        }
      }
    }
  };

  const addFrom = () => {
    if (fromEmail.trim()) {
      setFrom(from => [...from, fromEmail]);
      setFromEmail('');
    }
  };

  const filterTo = email => {
    const updatedList = to.filter(item => item !== email);
    setTo(updatedList);
    setToError('');
  };

  const filterFrom = email => {
    const updatedList = from.filter(item => item !== email);
    setFrom(updatedList);
    setFromError('');
  };

  const onDiscardMail = () => {
    localStorage.removeItem('compose');
    localStorage.removeItem('re-schedule');
    localStorage.removeItem('forward');
    onCloseComposeDialog();
    localStorage.setItem('isTo', false);
  };

  const handleMaximize = () => {
    if (!maximize) {
      setMaximize(true);
      setMinimize(false);
    } else {
      setMaximize(false);
    }
  };

  const handleMinimize = () => {
    if (!minimize) {
      setMaximize(false);
      setMinimize(true);
    } else {
      setMinimize(false);
    }
  };

  const checkValidations = () => {
    let isValidate = validate();
    if (!isValidate.isError && !toError) handleSubmit();
  };

  const onLabelChange = evt => {
    setMailDetail({ ...mailDetail, [evt.target.name]: evt.target.value });
  };

  const handleOnAddSupplier = () => {
    dispatch(
      setEmailListPopUp({
        status: true,
        emailList: [],
      }),
    );
  };

  const handleSubmit = () => {
    let formData = new FormData();
    !isCompose && mailDetail && formData.append('id', emailUniqueId);
    formData.append('emailType', isCompose ? 'admin_email' : emailType);
    formData.append(
      'isApproved',
      !isCompose && filterType?.selectedFolder === 'inbox' ? true : false,
    );
    formData.append('isReschedule', isReschedule ? true : false);
    formData.append('isForwarded', forward ? true : false);

     forward && name && formData.append('name', name); 
     forward && country && formData.append('country', country); 
     forward && contact && formData.append('mobileNo', contact); 
     forward && company && formData.append('companyName', company); 


    formData.append('setAcknowledge', ack);
    formData.append('isSchedule', scheduleMail ? true : false);
    formData.append('scheduleOn', scheduleMail ? selectedDate : null);
    formData.append('senderEmail', from[0]);
    for (let i = 0; i < to?.length; i++) {
      formData.append('receiverEmail', to[i]);
    }
    if (attachments?.length) {
      for (let i = 0; i < attachments.length; i++) {
        formData.append('files', attachments[i]?.originalFile);
      }
    }
    if (forwardAttachment?.length) {
      for (let ii = 0; ii < forwardAttachment?.length; ii++) {
        formData.append('files', forwardAttachment[ii]?.fieldname);
        formData.append('files', forwardAttachment[ii]?.originalname);
        formData.append('files', forwardAttachment[ii]?.encoding);
        formData.append('files', forwardAttachment[ii]?.mimetype);
        formData.append('files', forwardAttachment[ii]?.destination);
        formData.append('files', forwardAttachment[ii]?.filename);
        formData.append('files', forwardAttachment[ii]?.path);
        formData.append('files', forwardAttachment[ii]?.size);
      }
    }
    formData.append('subject', subject);

    const manualTableFormattedData =
      descriptionHTML &&
      descriptionHTML.replace(
        /<table>/g,
        '<table border="1" cellpadding="3" style="border-collapse: collapse;width:100%">',
      );

    const formattedData =
      manualTableFormattedData &&
      manualTableFormattedData.replace(
        /<table class="ck-table-resized">/g,
        '<table border="1" cellpadding="3" style="border-collapse: collapse; width:100%">',
      );

    const descriptionData =
      formattedData &&
      formattedData.replace(
        /<table class="ck-table-resized" style=";">/g,
        '<table border="1" cellpadding="3" style="border-collapse: collapse; width:100%">',
      );

    formData.append('html', descriptionData ? descriptionData : '');
    dispatch(sendMail(formData)).then(res => {
      if (res?.status === 200) {
        setReload(!reload);
        onDiscardMail();
      }
    });
  };

  const downloadAttachment = () => {};

  const onShowAttachments = attachments => {
    return (
      <Box className={classes.attachRoot}>
        {attachments.map((item, index) => {
          let filePath =
            process.env.NODE_ENV === 'production'
              ? item?.path.split('/')
              : item?.path.split('/');
          return (
            <a
              href={encodeURI(
                config.REACT_APP_API_BASE_URL + '/' + filePath[1],
              )}
              target="_blank"
              rel="noreferrer"
            >
              <Chip
                label={filePath[1]}
                key={index}
                icon={<Photo />}
                deleteIcon={<GetApp />}
                onDelete={() => downloadAttachment(item)}
              />
            </a>
          );
        })}
      </Box>
    );
  };

  useEffect(() => {
    if (localStorage.getItem('isTo') !== null) {
      const localStorageData = JSON.parse(localStorage.getItem('isTo'));

      if (
        localStorageData &&
        pop_up &&
        pop_up?.selectedEmails &&
        pop_up?.selectedEmails.length > 0
      ) {
        if (selectedMail) {
          if (!to.length) {
            let supplierEmails = [];
            pop_up.selectedEmails &&
              pop_up.selectedEmails.map(item => {
                if (!supplierEmails.includes(item)) {
                  supplierEmails.push(item);
                }
              });
            setTo(to => [...to, ...supplierEmails]);
          } else {
            let concatValue = to.concat(pop_up?.selectedEmails);
            const supplierEmail = concatValue.filter(
              (item, index) => concatValue.indexOf(item) === index,
            );
            setTo(supplierEmail);
          }
        } else {
          if (!to.length) {
            let supplierEmails = [];
            pop_up.selectedEmails &&
              pop_up.selectedEmails.map(item => {
                if (!supplierEmails.includes(item)) {
                  supplierEmails.push(item);
                }
              });
            setTo(to => [...to, ...supplierEmails]);
          } else {
            let concatValue = to.concat(pop_up?.selectedEmails);
            const supplierEmail = concatValue.filter(
              (item, index) => concatValue.indexOf(item) === index,
            );
            setTo(supplierEmail);
          }
        }
      }
    }
  }, [pop_up]);

  useEffect(() => {
    if (selectedMail) {
      const {
        emailContent,
        _id,
        receiverEmail,
        senderEmail,
        emailType,
        status,
        senderDetails,
        companyName,
      } = selectedMail;

      if (localStorage.getItem('forward') !== null) {
        setForwardAttachment(emailContent?.attachments);
      }
      if (
        (status === 'scheduled' && !forward) ||
        (emailType !== 'enquiry' && !forward)
      ) {
        if (receiverEmail?.length) {
          setTo(receiverEmail);
        }
        setForwardAttachment(emailContent?.attachments);
      }

      const existString = emailContent?.subject.includes('Inquiry from ThePipingMart.com |')
      setFrom([senderEmail]);
      setMailDetail({
        emailUniqueId: _id,
        senderEmail,
        receiverEmail,
        subject: forward && !existString ? `Inquiry from ThePipingMart.com | ${emailContent?.subject}` : emailContent?.subject,
        html: emailContent?.html,
        emailContent,
        emailStatus,
        emailType,
      });
      setSenderDetailsData({
        name: senderDetails?.name,
        country: senderDetails?.country,
        contact: senderDetails?.mobileNo,
        company: companyName,
      });
      setDescriptionHTML(emailContent?.html);
    }
  }, [selectedMail]);

  const {
    emailUniqueId,
    // body,
    // html,
    subject,
    // receiverEmail,
    // date,
    // setAcknowledge,
    // scheduleMailStatus,
    // other_receivers,
    // senderEmail,
    emailType,
    emailStatus,
    emailContent,
  } = mailDetail;

  const { name, country, company, contact } = senderDetailsData;

  const formattedDescription = emailContent?.html
    ? emailContent?.html?.replace(/(?:\r\n|\r|\n)/g, '<br />') ?? ''
    : '';

  return (
    <Dialog
      fullScreen
      open={openDialog}
      onClose={() => onDiscardMail()}
      className={clsx(
        classes.composeMail,
        openDialog ? `open ${maximize ? 'card-full-screen' : ''}` : '',
      )}
    >
      <CmtCard
        className={clsx(classes.componseCard, minimize ? 'card-minimise' : '')}
      >
        <CmtCardHeader
          className={classes.composeHeader}
          title={getHeaderTitle()}
        >
          <Box display="flex" alignItems="center">
            <IconButton
              className={classes.composeHeaderBtn}
              onClick={handleMinimize}
            >
              <Remove />
            </IconButton>
            <IconButton
              className={classes.composeHeaderBtn}
              onClick={handleMaximize}
            >
              <Launch />
            </IconButton>
            <IconButton
              className={classes.composeHeaderBtn}
              // onClick={onDiscardMail}
              onClick={onDiscardMail}
            >
              <Clear />
            </IconButton>
          </Box>
        </CmtCardHeader>

        <CmtCardContent>
          <PerfectScrollbar className={classes.scrollbarRoot}>
            <Box height={1} display="flex" flexDirection="column">
              <Box>
                <Box mb={3} ml={0} width="1">
                  <Box ml={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleOnAddSupplier}
                    >
                      Add Supplier
                    </Button>
                  </Box>
                </Box>
                <Box
                  mb={3}
                  width="1"
                  display={'flex'}
                  justifyContent={'space-between'}
                >
                  <FormControlLabel
                    style={{
                      marginRight: '1rem',
                      marginLeft: 0,
                      fontSize: '14 !important',
                    }}
                    value="ack"
                    control={
                      <Checkbox
                        checked={ack}
                        onChange={() => setAck(prev => !prev)}
                      />
                    }
                    label="Send Acknowledge"
                    labelPlacement="start"
                  />

                  <FormControlLabel
                    style={{ marginRight: '1rem', marginLeft: 0 }}
                    value="scheduleMail"
                    control={
                      <Checkbox
                        checked={scheduleMail}
                        onChange={() => setScheduleMail(prev => !prev)}
                      />
                    }
                    label="Schedule Email"
                    labelPlacement="start"
                  />

                  <Box className={classes?.dateTimePickerContainer}>
                    <KeyboardDateTimePicker
                      disabled={scheduleMail ? false : true}
                      variant="inline"
                      ampm={true}
                      label="Schedule Time"
                      value={selectedDate}
                      onChange={handleDateChange}
                      disablePast
                      InputProps={{ readOnly: true }}
                      format="YYYY/MM/DD hh:mm"
                    />
                    {errorText?.schedule && (
                      <span className={classes?.errorCk}>
                        {errorText?.schedule}
                      </span>
                    )}
                  </Box>
                </Box>

                <Box mb={3} width="1">
                  <Box className={classes.toTextRoot} component="span">
                    From
                  </Box>
                  {from.length > 0 &&
                    from.map((item, index) => (
                      <Chip
                        key={index}
                        // disabled={forward ? true : false}
                        label={item}
                        color={isValidEmail(item) ? '' : 'secondary'}
                        onDelete={() => filterFrom(item)}
                        className="mb-2 mr-2"
                      />
                    ))}
                  {from && from.length < 1 && (
                    <AppTextInput
                      className={clsx(classes.textField, 'to-input-type')}
                      value={fromEmail}
                      onChange={e => {
                        setFromEmail(e.target.value);
                        setFromError('');
                      }}
                      helperText={fromError}
                      required
                      onKeyDown={e =>
                        e.keyCode === 32 || (e.keyCode === 13 && addFrom())
                      }
                      onBlur={() => addFrom()}
                    />
                  )}
                </Box>

                <Box mb={3} width="1">
                  <Box className={classes.toTextRoot} component="span">
                    To
                  </Box>
                  {to.length > 0 &&
                    to.map((item, index) => (
                      <Chip
                        key={index}
                        disabled={
                          (to?.length && selectedMail?.length) ||
                          (selectedMail?.emailType === 'supplier_email' &&
                            index === 0)
                            ? true
                            : false
                        }
                        label={item}
                        color={isValidEmail(item) ? '' : 'secondary'}
                        onDelete={() => filterTo(item)}
                        className="mb-2 mr-2"
                      />
                    ))}
                  <AppTextInput
                    className={clsx(classes.textField, 'to-input-type')}
                    value={email}
                    onChange={e => {
                      setEmail(e.target.value);
                      setToError('');
                    }}
                    helperText={toError}
                    required
                    onKeyDown={e =>
                      e.keyCode === 32 || (e.keyCode === 13 && addTo())
                    }
                    onBlur={() => addTo()}
                  />
                </Box>

                {/* ---------------------------------------------------------------------------------------- */}
                {forward ? (
                  <GridContainer>
                    <Grid item md={6} sm={12}>
                      <AppTextInput
                        fullWidth
                        name="name"
                        variant="outlined"
                        label="Name"
                        value={name}
                        onChange={handleChange}
                      />
                      <br />
                      <br />
                      <AppTextInput
                        fullWidth
                        name="contact"
                        variant="outlined"
                        label="Contact"
                        value={contact}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <AppTextInput
                        fullWidth
                        name="country"
                        variant="outlined"
                        label="Country"
                        value={country}
                        onChange={handleChange}
                      />
                      <br />
                      <br />
                      {/* <AppAutocomplete
                      variant="outlined"
                      options={country}
                      name="countryId"
                      onChange={(evt, value) =>
                        setSenderDetailsData({
                          ...senderDetailsData,
                          countryId: value,
                        })
                      }
                      label="Country"
                      getOptionLabel={option => option?.name}
                      value={countryId}
                    />
                    <br /> */}
                      <AppTextInput
                        fullWidth
                        name="company"
                        variant="outlined"
                        label="Company"
                        value={company}
                        onChange={handleChange}
                      />
                      <br />
                      <br />
                    </Grid>
                  </GridContainer>
                 ) : null}

                {/* ---------------------------------------------------------------------------------------- */}

                <Box mb={3} width="1">
                  <AppTextInput
                    fullWidth
                    name="subject"
                    variant="outlined"
                    label="Subject"
                    value={subject}
                    onChange={onLabelChange}
                    helperText={errorText?.subject}
                  />
                </Box>

                {/* <Box
                  mb={emailContent?.attachments && emailContent?.attachments?.length > 0 ? 8 : 0}
                  component="p"
                  dangerouslySetInnerHTML={{
                    __html: emailContent?.html ? emailContent?.html.replace(/(?:\r\n|\r|\n)/g, '<br />') : '',
                  }}
                /> */}

                <Box mb={3} className={classes.textField}>
                  {/* <WysiwygEditorExample
                    defaultDescription={formattedDescription}
                    descriptionHTML={descriptionHTML}
                    setDescriptionHTML={setDescriptionHTML}
                    mb={emailContent?.attachments && emailContent?.attachments.length > 0 ? 8 : 0}
                    component="p"
                  /> */}

                  <HtmlEditor
                    // editorData={formattedDescription}
                    editorData={descriptionHTML || ""}
                    setEditorData={setDescriptionHTML}
                  />
                </Box>
              </Box>
              {emailContent?.attachments &&
                emailContent?.attachments.length > 0 &&
                onShowAttachments(emailContent?.attachments)}
              {attachments.length > 0 && (
                <Box display="flex" alignItems="center" flexWrap="wrap" mb={2}>
                  {attachments.map((item, index) => (
                    <Box mr={2} mb={2}>
                      <Chip
                        label={item.file.name}
                        key={index}
                        onDelete={() => onDeleteAttachments(index)}
                        className="mb-2 mr-2"
                      />
                    </Box>
                  ))}
                </Box>
              )}

              <Box mt="auto" display="flex" alignItems="center">
                <Button
                  className={classes.btnRoot}
                  variant="contained"
                  color="primary"
                  onClick={checkValidations}
                >
                  <Send /> Send
                </Button>

                {emailStatus !== 'scheduled' && (
                  <Box ml={2} className={classes.filePickerRoot}>
                    <FilePicker onAddAttachments={onAddAttachments} />
                  </Box>
                )}
                <Box ml="auto">
                  <Tooltip title="Delete">
                    <IconButton onClick={onDiscardMail}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          </PerfectScrollbar>
        </CmtCardContent>
      </CmtCard>
    </Dialog>
  );
};

export default ComposeMail;

ComposeMail.prototype = {
  openDialog: PropTypes.bool.isRequired,
  onCloseComposeDialog: PropTypes.func,
  mailTo: PropTypes.object,
  mailContent: PropTypes.string,
};

ComposeMail.defaultProps = {
  mailTo: null,
  mailContent: '',
};
