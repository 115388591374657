import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import GridContainer from '@jumbo/components/GridContainer';
import { Chip, Divider, IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Add } from '@material-ui/icons';
import ClearIcon from '@material-ui/icons/Clear';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMaterials } from 'redux/actions/Manage/Materials/Materials';
import { addMaterialToProduct } from 'redux/actions/Manage/Products/Products';

const useStyles = makeStyles(theme => ({
    dialogRoot: {
        position: 'relative',
    },
    dialogTitleRoot: {
        '& .MuiTypography-h6': {
            fontSize: 16,
            color: theme.palette.common.dark,
            minWidth: '400px'
        },
    },
}));

const AddMaterialToProduct = ({ history, open, onCloseDialog, setDataUpdated }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    //------------all use selectors
    const { productElm:product } = useSelector((state) => state.SetProductDetail);
    const { materials } = useSelector((state) => state.Materials);

    //-------------all use states
    const [productDetail, setproductDetail] = useState({
        productId: '',
        materialId: []
    });
    const [productDetailNew, setproductDetailNew] = useState({
        materialIdNew: ''
    });
    const [errorText, setErrorText] = React.useState({});
    const [selectedMaterial, setSelectedMaterial] = React.useState({});
    const [materialArr, setMaterialArr] = useState([])
    const [productMaterialArr, setProductMaterialArr] = useState([])

    //-------all handle functions
    const validate = () => {
        const errors = {};
        let isError = false;
        if (Object.keys(selectedMaterial).length === 0) {
            errors.materialId = 'Please select material.';
            isError = true;
        }
        setErrorText(errors);
        return {
            errors,
            isError,
        };
    };
    const validateBeforeSubmit = () => {
        const errors = {};
        let isError = false;
        if (productDetail?.materialId?.length === 0) {
            errors.materialId = 'Please select atleast one material.';
            isError = true;
        }
        setErrorText(errors);
        return {
            errors,
            isError,
        };
    };
    const onLabelChange = (evt, value) => {
        setSelectedMaterial({ displayName: value.props.children, [evt.target.name]: evt.target.value })
    };
    const onPdtMatDelete = (e, value) => {
        setProductMaterialArr(productMaterialArr => productMaterialArr.filter(e => e != value))
        let data = productDetail?.materialId?.filter(item => item?.materialId != value.materialId)
        setproductDetail({ ...productDetail, materialId: data });

    }
    const onPdtMatAdd = () => {
        let validateData = validate();
        if (!validateData.isError) {
            let existMaterial = productMaterialArr.filter(mat => mat.materialId === selectedMaterial.materialId)
            if (existMaterial && existMaterial.length === 0) {
                const regex = /[\W_]+|\s+/g;
                const {materialId, displayName} = selectedMaterial
                setProductMaterialArr([...productMaterialArr, selectedMaterial]);
                // setproductDetail(productDetail => ({ ...productDetail, materialId: [...productDetail.materialId, selectedMaterial?.materialId] }));
                const urlName = displayName.concat(" ", product?.name)
                const finalValue = {materialId, displayName:urlName, url: urlName.replace(regex, '-').toLowerCase()}
                setproductDetail(productDetail => ({ ...productDetail, materialId: [...productDetail.materialId, finalValue] }));

            }
        }
    }
    const onSubmitClick = () => {
        let validateData = validateBeforeSubmit();
        if (!validateData.isError)
            onproductSave();
    };
    const onproductSave = () => {
        if (product) {
            const {productId, materialId}=productDetail
            const data = {productId, materials:materialId}
            dispatch(
                addMaterialToProduct(data, (data) => {
                    if (data)
                        onCloseDialog()
                }),
            );
        }
    };

    //------all useEffects
    // useEffect(() => {
    //     if (product) {
    //         const { productId } = product
    //         let materials = []
    //         let data = product?.materials?.map(e => materials = [...materials, e])
    //         setProductMaterialArr(materials)
    //         let materialIds = []
    //         let val = product?.materials?.map(e => materialIds = [...materialIds, e?.materialId])
    //         setproductDetail({
    //             productId,
    //             materialId: materialIds
    //         })
    //     }
    // }
    //     , [product]);

    useEffect(() => {
        if (product) {
            const { productId } = product
            let materials = []
            let data = product?.materials?.map(e => materials = [...materials, e])
            const regex = /[\W_]+|\s+/g;
            const res = product?.materials && product?.materials?.map(item =>{
                return{
                    materialId:item?.materialId,
                    // displayName:`${item?.displayName} ${product?.name}`,
                    displayName: item?.displayName,
                    url: item?.displayName.replace(regex, '-').toLowerCase()
                }
            })
            setProductMaterialArr(materials)
            // let materialIds = []
            // let val = product?.materials?.map(e => materialIds = [...materialIds, e?.materialId])
            setproductDetail({
                productId,
                materialId: res
            })
        }
    }
        , [product]);

    useEffect(() => {
        if (materials) {
            setMaterialArr(materials)
        }
    }, [materialArr, materials])

    useEffect(() => {
        dispatch(getAllMaterials())
    }, [])
    const { materialIdAdd } = productDetailNew

    return (
        <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
            {/* <DialogTitle className={classes.dialogTitleRoot}>{product ? `Add Material to ${product.name}` : 'Create New product'}</DialogTitle> */}
            <Box mt={1} display="flex" justifyContent="space-between">
                <Box ml={-2}>
                    <DialogTitle className={classes.dialogTitleRoot}>{product && `${product.name}`}</DialogTitle>
                </Box>
                <Box ml={2}>
                    <IconButton onClick={onCloseDialog}>
                        <ClearIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContent dividers>
                <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
                    <GridContainer>
                        {materialArr && materialArr.length && <Grid item xs={12} sm={10}>
                            <AppSelectBox
                                fullWidth
                                data={materialArr}
                                name='materialId'
                                label="Materials"
                                valueKey="materialId"
                                variant="outlined"
                                labelKey="name"
                                value={materialIdAdd}
                                displayName="name"
                                onChange={onLabelChange}
                                helperText={errorText?.materialId}
                            />
                        </Grid>
                        }
                        <Grid item sm={2}>
                            <Button variant="contained" color="secondary" onClick={onPdtMatAdd}>
                                <Add />
                            </Button>
                        </Grid>
                        <Divider />
                        {productMaterialArr && productMaterialArr.length && productMaterialArr.map((e, key) => {
                            // <Grid item xs={4} sm={4}>
                            return e.length !== 0 && <Box m={1}>
                                {/* <Tooltip key={key} title={e}> */}
                                <Chip
                                    size="medium"
                                    key={e.displayName}
                                    label={e.displayName}
                                    clickable
                                    onDelete={evt => onPdtMatDelete(evt, e)}
                                    color="secondary"
                                    variant="outlined"
                                />
                                {/* </Tooltip> */}
                            </Box>
                        }
                            // </Grid>
                        )
                        }
                    </GridContainer>
                </Box>
                <Box display="flex" justifyContent="flex-end" mb={4}>
                    <Button onClick={onCloseDialog}>Cancel</Button>
                    <Box ml={2}>
                        <Button variant="contained" color="primary" onClick={onSubmitClick}>
                            {product ? "Update" : "Save"}
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog >
    );
};

export default AddMaterialToProduct;

AddMaterialToProduct.prototype = {
    open: PropTypes.bool.isRequired,
    onCloseDialog: PropTypes.func,
};
