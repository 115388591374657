import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonBase, Divider, IconButton, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { fetchProductList, setProductDetails, setSupplierDetail, setSupplierProductDetail } from 'redux/actions/Manage/Suppliers/Suppliers';
import { useHistory } from 'react-router';
import Pagination from '@mui/material/Pagination';
import { Redirect, useParams } from 'react-router';
const useStyles = makeStyles(theme => ({
    dialogRoot: {
        position: 'relative',
        '& .MuiDialog-paperWidthSm': {
            width: '100%',
        },
    },
    dialogTitleRoot: {
        '& .MuiTypography-h6': {
            fontSize: 16,
            color: theme.palette.common.dark,
        },
    },
    titleRoot: {
        fontSize: 16,
        marginLeft: 12,
        fontWeight: theme.typography.fontWeightBold,
    },
    tagListRoot: {
        color: theme.palette.text.disabled,
        padding: '0 3px 0 0',
        letterSpacing: 0.4,
        fontSize: 12,
        width: 'auto',
    },
    blockRoot: {
        display: 'block',
        color: theme.palette.text.disabled,
    },
    descriptionBlock: {
        '& p': {
            marginBottom: 16,
            fontSize: 14,
            color: theme.palette.text.secondary,
        },
    },
    linkBtn: {
        cursor: 'pointer',
        textTransform: 'uppercase',
    },
    imageRoot: {
        width: '100%',
        height: 250,
    },
    badge: {
        position: 'absolute',
        bottom: 15,
        left: 20,
        zIndex: 1,
        fontSize: 12,
        padding: '4px 16px',
        letterSpacing: 0.4,
        borderRadius: 16,
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    marginClass: {
        marginX: 5
    }
}));

// const ViewProductList = ({history, open, onCloseDialog }) => {
const ViewProductList = () => {
  // const { open, onCloseDialog } = props;
  const classes = useStyles();
  const history = useHistory()
  //------------all use selectors
  const { total, supplierproduct } = useSelector(state => state.Suppliers);
  const { supplier,supplierProductList } = useSelector(state => state.SetSupplierDetail);
  // console.log(supplier,"product view  84")
  // const { supplierProductList } = useSelector(state => state.SetSupplierDetail);
  // console.log(supplierProductList, 'product view 86');
  const dispatch = useDispatch();
  const { id } = useParams();
  console.log(id,"product view 88")
  //-------------all use states
  // const [productData, setproductData] = useState({});
  const [productList, setProductList] = useState(null);
  const [page, setPage] = useState(1);
  const [totalpage, setTotalPage] = useState();
  // const [keywords, setkeywords] = useState([])

  const handleclick = mat => {
    // history.push('/manage-add-productsupplier');

    // console.log(mat);
    // dispatch(setSupplierProductDetail(mat));
    history.push('/manage-suppliers');
  };
  const handlePageChange = (event, value) => {
    console.log(`Page ${value}`);
    setPage(value);
    getPageData(value);
  };
  //------all useEffects
  // useEffect(() => {
  //   dispatch(fetchProductList(supplier.userId, page));
  // }, [dispatch, page]);

  
 
  const getPageData=(page)=>{
    let start = ((page-1)*25);
    let end = (start+25);
    // console.log(start,"start", end,'product view 124');
    let products = supplierProductList.slice(start,end);
    // console.log(products, 'product view 124');
    setProductList(products);
    
  }
 useEffect(() => {
   if (supplierProductList) {
    let totalpage = Math.ceil(supplierProductList.length/25)
      setTotalPage(totalpage);
     getPageData(page);
   }
 }, [supplierProductList]);
  useEffect(() => {
    dispatch(setSupplierDetail(id));
    dispatch(setProductDetails(id));
    // console.log(data,"product view useEffect");
    // if(supplierProductList){
    //     getPageData(1)
    // } 
  }, []);



  return (
    <PageContainer>
      <CmtCard>
        <Box mt={-2} mb={-2} display="flex" justifyContent="space-between">
          <Box ml={-2}>
            <DialogTitle className={classes.dialogTitleRoot}>
              {supplier && `${supplier.name + ' Product List'}`}
            </DialogTitle>
          </Box>
        </Box>
        <Divider></Divider>
        <CmtCardContent>
          {/* <CmtCard> */}
          {/* <GridContainer> */}
          {supplierProductList && productList && productList.length > 0 ? (
            productList?.map((mat, key) => {
              return (
                <Box display="flex" justifyContent="space-between">
                  <Typography className="mb-2">
                    {page === 1
                      ? `${key + 1}. `
                      : `${(page - 1) * 25 + 1 + key}. `}
                    {mat.displayName}
                  </Typography>
                </Box>
              );
            })
          ) : (
            <Typography>No Products</Typography>
          )}
          {/* </GridContainer> */}
          {/* </CmtCard> */}
          {
            <Pagination
              count={totalpage}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />
          }
        </CmtCardContent>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button
            onClick={() => handleclick()}
            variant="contained"
            color="primary"
          >
            Cancel
          </Button>
          <Box ml={2}></Box>
        </Box>
      </CmtCard>
    </PageContainer>
  );
};

export default ViewProductList;

ViewProductList.prototype = {
  open: PropTypes.bool.isRequired,
  // onCloseDialog: PropTypes.func,
};
