import axios from 'axios';
import { history } from 'redux/store';
import config from "utils/Config.util";
import tokenService from './token.service';


const axiosUtil = {
    initalise: store => {
        axios.defaults.baseURL = config.REACT_APP_API_BASE_URL;
        // Request Interceptor. All Request pass from here
        axios.interceptors.request.use(
            axiosConfig => {
                const token = tokenService.getLocalAccessToken();
                if (token) {
                    axiosConfig.headers['authorization'] = `${token}`;
                    axiosConfig.headers['auth'] = `${token}`;
                }
                axiosConfig.headers['Content-Type'] = 'application/json';

                // axiosConfig.headers['Access-Control-Allow-Origin'] = '*';
                // axiosConfig.headers['Access-Control-Allow-Credentials'] = 'true';

                return axiosConfig;
            },
            error => {
                Promise.reject(error.response);
            },
        );

        /*
                Response Interceptor
                Responsibilities:
                1- If api sends new token then change the store auth token with new token
                2- If api sends 401 token then send user to login page
            */

        // Response interceptor for API calls
        axios.interceptors.response.use((response) => {
            return response
        },
            async function (error) {
                const originalRequest = error.config;
                if (error.response.status === 401 && !originalRequest._retry) {
                    localStorage.clear();
                    history.push('/signin')
                    // const token = tokenService.getLocalAccessToken();
                    // originalRequest._retry = true;
                    // const { data } = await AuthService.refreshAccessToken(token);
                    // const refreshToken = data.refreshToken
                    // axios.defaults.headers.common['Authorization'] = `Bearer ${refreshToken}`;
                    // return axios(originalRequest);
                }
                return Promise.reject(error.response);
            });
        // axios.interceptors.response.use(
        //     response => {
        //         log.debug("set")
        //         // const newToken = get(response, 'headers.x-token', '')
        //         // if(newToken) {
        //         //     localStorage.setItem("AUTH_TOKEN", newToken);
        //         // }
        //         return response;
        //     },
        //     function (error) {
        //         // const originalRequest = error.config
        //         // if(error.response.data.is_logout === 1){
        //         //     localStorage.clear();
        //         //     store.dispatch({
        //         //         type: SIGNOUT_SUCCESS,
        //         //     });
        //         // }
        //         return Promise.reject(error.response);
        //     },
        // );
    },
};

export default axiosUtil;

