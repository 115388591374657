import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import PropTypes from 'prop-types';
import React from 'react';

function CustomTableHead({ headCells, classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort }) {

  const onSortOrderChange = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead >
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            style={{ zIndex: 3 }}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            {headCell.sort === false ? (
              headCell.label
            ) : (
              <TableSortLabel
                hideSortIcon={headCell.id !== 'actions' ? false : true}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={onSortOrderChange(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                ) : null}
              </TableSortLabel>)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

CustomTableHead.propTypes = {
  classes: PropTypes.object,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headCells: PropTypes.array,
};
CustomTableHead.defaultProps = {
  headCells: [],

};

export default React.memo(CustomTableHead);
