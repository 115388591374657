import log from "utils/Logger.util";
import api from "./api.service";
import TokenService from "./token.service";
import axios from 'axios';

const register = (username, email, password) => {
    return api.post("/auth/signup", {
        username,
        email,
        password
    });
};
const login = (email, password) => {
    return axios
        .post("/auth/login", {
            email,
            password
        })
        .then((response) => {
            if (response?.data?.user?.token) {
                TokenService.setUser(response.data.user);
            }
            return response;
        }).catch((error) => {
            if (error && error.data)
                log.error(error.data);
            else
                log.error((error))
            return error
        })
};
const refreshAccessToken = async (token) => {
    try {
        // const { data } = await axios.put(`/admin/roles/update/${roleId}`, payload)
        const response = {
            data: {
                statusCode: 200,
                data:
                {
                    refreshToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzRkNTdiM2NmOWVjMGZiZTc2ODFhNTYiLCJlbWFpbCI6InN1cGVyYWRtaW5sb2NhbEB5b3BtYWlsLmNvbSIsInBhc3N3b3JkIjoiJDJiJDA2JEI2Sk43eldhV2NoMWE4U3BHMVlhSWVQRDFvTnhRb0MwMllWMzhrMjRLakpVakpoZGs5WWpLIiwiaWF0IjoxNjY4Nzc0MzI5LCJleHAiOjE2Njg3ODE1Mjl9.z2hc-RGNzLFTdn8xlg3TsUNZtYBEc-Jcuv2AgLUETUs"
                }
            }
        }
        if (response.data.statusCode === 200) {
            TokenService.updateLocalAccessToken(response.data.data.refreshToken)
            return response
        }
    } catch (error) {
        if (error && error.data)
            log.error(error.data);
        else
            log.error((error))
        return error
    }
};
const forgetPassword = async (email) => {
    try {
        const response = await axios.post(`/user/send-forgot-password-otp`, email)
        if (response.data.statusCode === 200) {
            return response
        }
    } catch (error) {
        if (error && error.data)
            log.error(error.data);
        else
            log.error((error))
        return error
    }
    // return axios
    //     .post("/admin/users/forget_password", {
    //         email,
    //         password
    //     })
    //     .then((response) => {
    //         if (response?.data?.payload?.token) {
    //             TokenService.setUser(response.data.payload);
    //         }
    //         return response;
    //     }).catch((error) => {
    //         log.error(error.data.message);
    //         return error
    //     })
};

const logout = () => {
    TokenService.removeUser();
};

const getCurrentUser = () => {
    // return JSON.parse(sessionStorage.getItem("user"));
    return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
    register,
    login,
    logout,
    getCurrentUser,
    forgetPassword,
    refreshAccessToken
};

export default AuthService;