import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Switch, makeStyles } from '@material-ui/core';
import { MoreHoriz, Edit, Link, Visibility } from '@material-ui/icons';

import CustomTable from 'components/CustomeTable';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import {
  getProductMaterialSubProductList,
  updateProductMaterialSubProductStatus,
} from 'redux/actions/Manage/ProductMaterialSubProduct';
import config from 'utils/Config.util';
import ViewProductMaterialSubProduct from './ViewProductMaterialSubProduct';
import CmtCardMedia from '@coremat/CmtCard/CmtCardMedia';

const useStyles = makeStyles(theme => ({
  imageRoot: {
    // width: 150,
    // height: 150,
    width: 100,
    height: 100,
    objectFit: 'cover',
    objectPosition: 'center',
    padding: 0,
  },
}));


const ProductMaterialSubProduct = ({ history }) => {
  const classes = useStyles()
  const dispatch = useDispatch();
  const data = useSelector(state => state?.ProductMaterialSubProduct);
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [id, setId] = useState('');
  const [flag, setFlag] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'logo',
      numeric: false,
      disablePadding: false,
      label: 'Image',
      sort: false,
      render: elm => (
        <Box>
          <CmtCardMedia className={classes.imageRoot} image={elm?.logo} />
        </Box>
      ),
    },
    {
      id: 'metaTitle',
      numeric: false,
      disablePadding: false,
      label: 'Title',
      sort: false,
    },
    {
      id: 'isActive',
      numeric: false,
      disablePadding: false,
      sort: false,
      label: 'Status',
      render: elm => (
        <Switch
          checked={elm?.isActive === true ? true : false}
          onChange={() => handleChangeStatusSwitch(elm)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      ),
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Actions',
      sort: false,
      render: elm => (
        <>
          {
            <CmtDropdownMenu
              items={userActions}
              onItemClick={menu => onUserMenuClick(menu, elm)}
              TriggerComponent={<MoreHoriz />}
            />
          }
        </>
      ),
    },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const getUserActions = () => {
    const actions = [];
    actions.push({ action: 'url', label: 'View Live', icon: <Link /> });
    actions.push({ action: 'edit', label: 'Edit', icon: <Edit /> });
    actions.push({ action: 'view', label: 'View', icon: <Visibility /> });
    return actions;
  };

  const userActions = getUserActions();
  const onUserMenuClick = (menu, elm) => {
    if (menu.action === 'edit') {
      history.push(`/edit-manage-material-sub-product-product/${elm?._id}`);
    } else if (menu.action === 'view') {
      setId(elm?._id);
      setOpenDetailsModal(true);
    }
    else if (menu.action === 'url') {
      // window.open(`https://www.thepipingmart.com/product/${elm?.url}`);
      window.open( config.REACT_APP_API_BASE_URL === 'https://api.pipingmart.co.in' ? `http://pipingmart.co.in/product/${elm?.url}` : `https://www.thepipingmart.com/product/${elm?.url}`)
    }
  };

  const handleChangeStatusSwitch = elm => {
    let status;
    if (elm?.isActive === true) status = false;
    else status = true;
    const data = { isActive: status };
    dispatch(updateProductMaterialSubProductStatus(elm?._id, data)).then(
      res => {
        if (res?.status === 200) {
          setFlag(!flag);
        }
      },
    );
  };

  useEffect(() => {
    let value = { page: page + 1, perPage: 10 };
    if (searchTerm) {
      value.search = searchTerm;
    }
    dispatch(getProductMaterialSubProductList(value));
  }, [flag, page]);

  return (
    <>
      <CustomTable
        headCells={headCells}
        row={data?.productMaterialSubProducts || []}
        count={data?.total || 0}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        rowsPerPage={data?.perPage}
        onPageChange={handlePageChange}
        page={page}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleSearch={text => setSearchTerm(text)}
        handleSearchSubmit={() => {
          setPage(0);
          setFlag(!flag);
        }}
        addButtonText={'Add Material Sub-Product Product'}
        handleAddButton={() =>
          history.push('/add-manage-material-sub-product-product')
        }
        isDynamic={true}
      />

      {openDetailsModal && (
        <ViewProductMaterialSubProduct
          open={openDetailsModal}
          onCloseDialog={() => setOpenDetailsModal(false)}
          id={id}
        />
      )}
    </>
  );
};

export default ProductMaterialSubProduct;
