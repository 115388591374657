import React from 'react';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCard from '@coremat/CmtCard';
import Box from '@material-ui/core/Box';
import CmtGridView from '@coremat/CmtGridView';
import CmtImage from '@coremat/CmtImage';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography } from '@material-ui/core';

const statusColors = {
  online: '#8DCD03',
  away: '#FF8C00',
  offline: '#D9D9D9',
};

const useStyles = makeStyles(theme => ({
  textUppercase: {
    textTransform: 'uppercase',
  },
  thumbRoot: {
    position: 'relative',
    borderRadius: 2,
    overflow: 'hidden',
    cursor: 'pointer',
    '& img': {
      width: '100px',
      height: '100px',
      display: 'block',
    },
  },
  thumbContent: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    backgroundImage: 'linear-gradient(180deg,rgba(0,0,0,.01) 0,rgba(0,0,0,.65))',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: 2,
  },
  dotRoot: {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 1,
    width: 14,
    height: 14,
    // border: `solid 2px ${theme.palette.common.white}`,
    border: `solid 2px red`,
    borderRadius: '50%',
  },
  thumbTitle: {
    fontSize: 12,
    letterSpacing: 0.4,
    color: theme.palette.common.white,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const Gallery = ({ gallery, factory }) => {
  console.log(gallery);
  const classes = useStyles();
  return (
    <>
      <CmtCard>
        <CmtCardHeader title="Gallery"></CmtCardHeader>
        <CmtCardHeader title="Product Images"></CmtCardHeader>
        <CmtCardContent>
          <CmtGridView
            itemPadding={24}
            responsive={{
              xs: 2,
              sm: 3,
              md: 6,
              lg: 6,
              xl: 6,
            }}
            data={gallery}
            renderRow={(item, index) => (
              <Box key={index} className={classes.thumbRoot}>
                <CmtImage src={item.image} alt={item} />
                <Typography>{item.name}</Typography>
              </Box>
            )}
          />
        </CmtCardContent>
      </CmtCard>
      <CmtCard>
        {/* <CmtCardHeader title="Gallery"></CmtCardHeader> */}
        <CmtCardHeader title="Factory Images"></CmtCardHeader>
        <CmtCardContent>
          <CmtGridView
            itemPadding={24}
            responsive={{
              xs: 2,
              sm: 3,
              md: 6,
              lg: 6,
              xl: 6,
            }}
            data={factory}
            renderRow={(item, index) => (
              <Box key={index} className={classes.thumbRoot}>
                <CmtImage src={item.image} alt={item} />
                <Typography>{item.name}</Typography>
              </Box>
            )}
          />
        </CmtCardContent>
      </CmtCard>
    </>
  );
};

export default Gallery;

Gallery.prototype = {
  gallery: PropTypes.object.isRequired,
};
