import React, { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Email, Send, MoveToInbox } from '@material-ui/icons';

import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import GridContainer from '@jumbo/components/GridContainer';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import CounterCard from '@jumbo/components/Common/CounterCard';
import CustomTable from 'components/CustomeTable';
import {
  receiveInboxEmailCount,
  sentApprovEmailCount,
  supplierRegistrationEmailId,
  topSupplierEmailId,
} from 'redux/actions/Manage/Dashboard/Dashboard';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    '& .Cmt-content-head': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
    },
    '& .Cmt-header-root': {
      justifyContent: 'space-between',
    },
    '& .Cmt-action-default-menu': {
      width: '50%',
    },
    '& .Cmt-card-content': {
      marginTop: -15,
    },
    '& .card-icon': {
      '& .MuiSvgIcon-root': {
        fontSize: '3rem',
      },
    },
  },
  titleSpace: {
    marginBottom: 20,
    fontWeight: theme.typography.fontWeightBold,
  },
  order2: {
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  order3: {
    [theme.breakpoints.up('md')]: {
      order: 3,
    },
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const dateWiseFilterOption = [
    { id: 'today', label: 'Today' },
    { id: 'week', label: 'Last Week' },
    { id: 'month', label: 'Last Month' },
  ];

  const topSupplierOption = [
    { id: 'today', label: 'Today' },
    { id: 'week', label: 'Last Week' },
    { id: 'month', label: 'Last Month' },
    { id: 'all', label: 'All' },
  ];

  const emailFilterOption = [
    { id: 'all', label: 'All' },
    { id: 'approve', label: 'Approved Email' },
    { id: 'sent', label: 'Sent Email' },
  ];

  const topSupplierHeadCells = [
    {
      id: 'supplierName',
      numeric: false,
      disablePadding: false,
      label: 'Supplier Name',
      visible: true,
    },
    {
      id: 'supplierEmail',
      numeric: false,
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'count',
      numeric: false,
      disablePadding: true,
      label: 'Email count',
    },
  ];

  const newSupplierHeadCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Supplier Name',
      visible: true,
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: true,
      label: 'Registered Data',
      render: elm => moment(elm.createdAt).format('DD/MM/YYYY'),
    },
  ];

  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState('asc');
  const [page, setPage] = React.useState(0);
  const [pagesupplier, setPageSupplier] = React.useState(0);
  const [filter, setFilter] = useState('today');
  const [sentFilter, setSentFilter] = useState('today');
  const [receiveFilter, setReceiveFilter] = useState('today');
  const [supplierFilter, setSupplierFilter] = useState('today');
  const [emailFilter, setEmailFilter] = useState('all');
  const {
    supplierList,
    total,
    listOfTop10Supplier,
    inboxEmailCount,
    sendEmailCount,
  } = useSelector(({ DashBoard }) => DashBoard);

  const dispatch = useDispatch();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const handlePageSupplierChange = (event, newPage) => {
    setPageSupplier(newPage);
  };

  useEffect(() => {
    let sentparams = {
      type: 'send',
      filterBy: sentFilter,
    };

    dispatch(sentApprovEmailCount(sentparams));
  }, [dispatch, sentFilter]);

  useEffect(() => {
    let receiveparams = {
      type: 'receive',
      filterBy: receiveFilter,
    };

    dispatch(receiveInboxEmailCount(receiveparams));
  }, [dispatch, receiveFilter]);

  useEffect(() => {
    let params = {
      type: emailFilter,
      filterBy: supplierFilter,
    };
    dispatch(topSupplierEmailId(params));
  }, [dispatch, emailFilter, supplierFilter]);

  useEffect(() => {
    let params = {
      filterBy: filter,
      page: pagesupplier + 1,
      perPage: 10,
    };

    dispatch(supplierRegistrationEmailId(params));
  }, [dispatch, filter, pagesupplier]);

  return (
    // <h1>Dashboard</h1>
    <GridContainer>
      <Grid item xs={12} sm={12} md={7}>
        <CmtCard className={classes.cardRoot}>
          <CmtCardHeader
            title="Sent Emails"
            titleProps={{
              variant: 'h2',
              component: 'div',
            }}
          >
            <AppSelectBox
              data={dateWiseFilterOption}
              label="Date wise filter"
              valueKey="id"
              value={sentFilter}
              onChange={e => setSentFilter(e.target.value)}
              variant="outlined"
              labelKey="label"
            />
          </CmtCardHeader>

          <CmtCardContent>
            <GridContainer>
              <Grid item xs={12} sm={6} md={6}>
                <CounterCard
                  icon={<Send />}
                  className="card-icon"
                  number={`${sendEmailCount?.sentEmailCount} / ${sendEmailCount?.receiverCount}`}
                  label="Sent Email"
                  labelProps={{
                    fontSize: 16,
                  }}
                  backgroundColor={['#1372B7 -18.96%', '#09157E 108.17%']}
                  gradientDirection="180deg"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CounterCard
                  icon={<Email />}
                  number={`${sendEmailCount?.approveEmailCount}`}
                  className="card-icon"
                  label="Approved Email"
                  labelProps={{
                    fontSize: 16,
                  }}
                  backgroundColor={['#1372B7 -18.96%', '#09157E 108.17%']}
                  // backgroundColor={['#5AB9FE -18.96%', '#1372B7 108.17%']}
                  gradientDirection="180deg"
                />
              </Grid>
            </GridContainer>
          </CmtCardContent>
        </CmtCard>
      </Grid>

      <Grid item xs={12} sm={12} md={5}>
        <CmtCard className={classes.cardRoot}>
          <CmtCardHeader
            title="Received Emails"
            titleProps={{
              variant: 'h2',
              component: 'div',
            }}
          >
            <AppSelectBox
              data={dateWiseFilterOption}
              label="Date wise filter"
              valueKey="id"
              value={receiveFilter}
              onChange={e => setReceiveFilter(e.target.value)}
              variant="outlined"
              labelKey="label"
            />
          </CmtCardHeader>
          <CmtCardContent>
            <GridContainer>
              <Grid item xs={12} sm={12} md={12}>
                <CounterCard
                  icon={<MoveToInbox />}
                  number={inboxEmailCount}
                  label="Inbox"
                  className="card-icon"
                  labelProps={{
                    fontSize: 16,
                  }}
                  backgroundColor={['#1372B7 -18.96%', '#09157E 108.17%']}
                  gradientDirection="180deg"
                />
              </Grid>
            </GridContainer>
          </CmtCardContent>
        </CmtCard>
      </Grid>

      {/* TOP 10 */}
      <Grid item xs={12} sm={12} md={12}>
        <CmtCard className={classes.cardRoot}>
          <CmtCardHeader
            title="Top Suppliers"
            titleProps={{
              variant: 'h2',
              component: 'div',
            }}
          >
            <AppSelectBox
              data={topSupplierOption}
              label="Date wise filter"
              valueKey="id"
              value={supplierFilter}
              onChange={e => setSupplierFilter(e.target.value)}
              variant="outlined"
              labelKey="label"
              className="mr-3"
            />
            <AppSelectBox
              data={emailFilterOption}
              label="Email wise filter"
              valueKey="id"
              value={emailFilter}
              onChange={e => setEmailFilter(e.target.value)}
              variant="outlined"
              labelKey="label"
            />
          </CmtCardHeader>
          <CmtCardContent>
            {/* {console.log("supplierData :", supplierData)} */}

            <CustomTable
              headCells={topSupplierHeadCells}
              row={listOfTop10Supplier}
              count={listOfTop10Supplier?.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              onPageChange={handlePageChange}
              rowsPerPage={10}
              showToolbar={false}
              page={page}
            />
          </CmtCardContent>
        </CmtCard>
      </Grid>

      {/* SUPPLER */}
      <Grid item xs={12} sm={12} md={12}>
        <CmtCard className={classes.cardRoot}>
          <CmtCardHeader
            title="New suppliers"
            titleProps={{
              variant: 'h2',
              component: 'div',
            }}
          >
            <AppSelectBox
              data={dateWiseFilterOption}
              label="Date wise filter"
              valueKey="id"
              value={filter}
              onChange={e => setFilter(e.target.value)}
              variant="outlined"
              labelKey="label"
            />
          </CmtCardHeader>
          <CmtCardContent>
            <CustomTable
              headCells={newSupplierHeadCells}
              row={supplierList}
              count={total}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              onPageChange={handlePageSupplierChange}
              showToolbar={false}
              page={pagesupplier}
              rowsPerPage={10}
            />
          </CmtCardContent>
        </CmtCard>
      </Grid>
    </GridContainer>
  );
};

export default Dashboard;
