import {
  BLOCK_ALL_USER_FAILED,
  BLOCK_ALL_USER_REQUEST,
  BLOCK_ALL_USER_SUCCESS,
  BLOCK_SINGLE_USER_FAILED,
  BLOCK_SINGLE_USER_REQUEST,
  BLOCK_SINGLE_USER_SUCCESS,
  BLOCKED_USERS_LIST_FAILED,
  BLOCKED_USERS_LIST_REQUEST,
  BLOCKED_USERS_LIST_SUCCESS,
} from 'redux/actions/Manage/EmailBlock/Constant';

const INIT_STATE = {
  loading: false,
  singleBlockEmail: {},
  blockedEmails: [],
  total: 0,
  perPage: 0,
  errorMessage: null
};


export const BlockEmailReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case BLOCKED_USERS_LIST_SUCCESS: {
      return {
        ...state,
        blockedEmails: action.data,
        action: action.type,
        total: action.total,
        message: action.message,
        perPage: action.perPage,
      };
    }
    case BLOCK_SINGLE_USER_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case BLOCK_SINGLE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        singleBlockEmail: action.payload.singleBlockEmail
      }
    }
    case BLOCK_SINGLE_USER_FAILED: {
      return {
        ...state,
        loading: false,
        singleBlockEmail: {},
        errorMessage: action.payload.errorMessage
      }
    }
    case BLOCK_ALL_USER_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case BLOCK_ALL_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        blockedEmails: action.payload?.blockedEmails,
        total:action.payload?.total
      }
    }
    case BLOCK_ALL_USER_FAILED: {
      return {
        ...state,
        loading: false,
        blockedEmails: [],
        errorMessage: action.payload.errorMessage
      }
    }
    default:
      return state;
  }
};
