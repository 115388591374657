//-------------PRODUCT constants------------------
export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAILED = "PRODUCT_LIST_FAILED";
export const DELETE_BULK_PRODUCT_REQUEST = "DELETE_BULK_PRODUCT_REQUEST";
export const DELETE_BULK_PRODUCT_SUCCESS = "DELETE_BULK_PRODUCT_SUCCESS";
export const DELETE_BULK_PRODUCT_FAILED = "DELETE_BULK_PRODUCT_FAILED";
export const SET_PRODUCT_DETAILS = "SET_PRODUCT_DETAILS";
export const PRODUCT_CREATE_REQUEST = "PRODUCT_CREATE_REQUEST";
export const UPDATE_PRODUCT_DETAILS = "UPDATE_PRODUCT_DETAILS"
export const PRODUCT_CREATE_SUCCESS = "PRODUCT_CREATE_SUCCESS";
export const PRODUCT_CREATE_FAILED = "PRODUCT_CREATE_FAILED";
export const PRODUCT_MATERIAL_CREATE_REQUEST = "PRODUCT_MATERIAL_CREATE_REQUEST";
export const PRODUCT_MATERIAL_CREATE_SUCCESS = "PRODUCT_MATERIAL_CREATE_SUCCESS";
export const PRODUCT_MATERIAL_CREATE_FAILED = "PRODUCT_MATERIAL_CREATE_FAILED";
export const PRODUCT_UPDATE_REQUEST = "PRODUCT_UPDATE_REQUEST";
export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS";
export const PRODUCT_UPDATE_FAILED = "PRODUCT_UPDATE_FAILED";
export const PRODUCT_PROFILE_UPDATE_REQUEST = "PRODUCT_PROFILE_UPDATE_REQUEST";
export const PRODUCT_PROFILE_UPDATE_SUCCESS = "PRODUCT_PROFILE_UPDATE_SUCCESS";
export const PRODUCT_PROFILE_UPDATE_FAILED = "PRODUCT_PROFILE_UPDATE_FAILED";
export const PRODUCT_PASSWORD_UPDATE_REQUEST = "PRODUCT_PASSWORD_UPDATE_REQUEST";
export const PRODUCT_PASSWORD_UPDATE_SUCCESS = "PRODUCT_PASSWORD_UPDATE_SUCCESS";
export const PRODUCT_PASSWORD_UPDATE_FAILED = "PRODUCT_PASSWORD_UPDATE_FAILED";
export const PRODUCT_DELETE_REQUEST = "PRODUCT_DELETE_REQUEST";
export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS";
export const PRODUCT_DELETE_FAILED = "PRODUCT_DELETE_FAILED";
export const SET_PRODUCTELM_DETAILS = "SET_PRODUCTELM_DETAILS";
