import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import WysiwygEditorExample from 'components/wysiwygEditor';
import { Chip, Divider, Paper } from '@material-ui/core';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { Add } from '@material-ui/icons';
import { Typography } from '@mui/material';
import { uploadFile } from 'redux/actions/FileUpload/FileUpload';
import { createGrade } from 'redux/actions/Manage/ProductMaterialGrades';
import CustomEditor from 'components/CustomEditor';

const useStyles = makeStyles(theme => ({
  preview: {
    xs: '12',
  },
  errorCk: {
    color: '#f44336',
    marginRight: '14px',
    marginTop: '4px',
    fontSize: '0.75rem',
  },
  typoMarginBottom: {
    marginBottom: '10px',
  },
  editorLabelError: {
    color: '#f44336',
    fontSize: '16px',
    fontWeight: 500,
  },
  label: {
    fontSize: '16px',
    fontFamily: 'inherit',
    fontWeight: 400,
    lineHeight: 1.43,
  },
  textarea: {
    width: '100%',
    // height: '100%',
    padding: '8px',
    fontSize: '19px',
    fontFamily: 'Normal',
    fontWeight: 200,
    '&:focus': {
      borderColor: '#1C61CA',
      borderWidth: '2px',
      outline: 'none',
      borderRadius: 4,
    },
  },
}));

const AddGradeToMaterial = ({
  history,
  open,
  onCloseDialog,
  setDataUpdated,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  //------------all use selectors
  const { user } = useSelector(({ auth }) => auth.authUser);
  const { material } = useSelector(state => state.SetMaterialDetail);
  const successAction = useSelector(state => state.Success.action);

  //-------------all use states
  const [keywordsArr, setKeywordsArr] = useState([]);
  const [descriptionHTML, setDescriptionHTML] = useState('');
  const [file, setFile] = useState([]);
  const [materialDetail, setMaterialDetail] = useState({
    code: '',
    description: '',
    keywords: '',
    meta: '',
    name: '',
    title: '',
    url: '',
    logo: '',
    materialId: '',
  });
  const [errorText, setErrorText] = React.useState({});

  //-------all handle functions

  const validate = () => {
    const errors = {};
    let isError = false;
    const { code, meta, name, title, url } = materialDetail;
    if (!name) {
      errors.name = 'Please enter name.';
      isError = true;
    }
    // else if (!/^[a-zA-Z0-9 ]*$/.test(name)) {
    //   errors.name = 'Only letters and numbers are allowed.';
    //   isError = true;
    // }
    if (!code) {
      errors.code = 'Please enter code.';
      isError = true;
    }
    // else if (!/^[a-z-]*$/.test(code)) {
    //   errors.code = 'Only small letters with - are allowed.';
    //   isError = true;
    // }
    if (!descriptionHTML) {
      errors.descriptionHTML = 'Please enter description.';
      isError = true;
    }
    if (!meta) {
      errors.meta = 'Please enter meta.';
      isError = true;
    }
    if (!title) {
      errors.title = 'Please enter title.';
      isError = true;
    }
    if (!url) {
      errors.url = 'Please enter url.';
      isError = true;
    }
    // else if (!/^[a-z-.]*$/.test(url)) {
    //   errors.url = 'Only small letters with - or . are allowed.';
    //   isError = true;
    // }
    if (!file.length) {
      errors.file = 'Please upload image.';
      isError = true;
    }
    if (!keywordsArr.length) {
      errors.keywords = 'Please enter keyword.';
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };

  const validateKeyword = () => {
    let error = '';
    let isError = false;
    const { keywords } = materialDetail;
    if (!keywords) {
      error = 'Please enter keywords.';
      isError = true;
    }
    // else if (!/^[a-zA-Z-]*$/.test(keywords)) {
    //   error = 'Only letters and - are allowed.';
    //   isError = true;
    // }
    setErrorText({ ...errorText, keywords: error });
    return {
      error,
      isError,
    };
  };

  const onKeywordsAdd = () => {
    let validateData = validateKeyword();
    if (!validateData.isError)
      setKeywordsArr(keywordsArr => [...keywordsArr, keywords]);
    setMaterialDetail({ ...materialDetail, keywords: '' });
  };

  const onKeywordsDelete = (e, value) => {
    setKeywordsArr(keywordsArr => keywordsArr.filter(e => e != value));
  };

  // const onLabelChange = evt => {
  //   setMaterialDetail({
  //     ...materialDetail,
  //     [evt.target.name]: evt.target.value,
  //   });
  // };

  const onLabelChange = evt => {
    const { name, value } = evt.target;
    const regex = /[\W_]+|\s+/g;
    if (name === 'name') {
      const nameValue = value ? value.replace(regex, '-').toLowerCase() : '';
      materialDetail.code = nameValue;
      materialDetail.url = nameValue;
    }
    setMaterialDetail({ ...materialDetail, [name]: value });
  };

  const onSubmitClick = () => {
    let validateData = validate();
    if (!validateData.isError) {
      onGradeMaterialSave();
    }
  };

  const onGradeMaterialSave = () => {
    let keywordStr = '';
    keywordsArr.map(e => (keywordStr += ',' + e));

    let formData = new FormData();
    formData.append('code', code);
    // formData.append('code', name.replace(/\s+/g, '-').toLowerCase());
    formData.append('description', descriptionHTML);
    formData.append('materialId', material?.materialId);
    formData.append('keywords', keywordStr);
    formData.append('meta', meta);

    formData.append('name', name);
    formData.append('title', title);
    formData.append('url', url);
    // formData.append('url', name.replace(/\s+/g, '-').toLowerCase());
    formData.append('logo', file[0]);

    if (material) {
      dispatch(
        createGrade(formData, data => {
          if (data) history.push('/manage-materials');
        }),
      );
    }
  };

  //------all useEffects

  useEffect(() => {
    if (material) {
      const { materialId } = material;
      setMaterialDetail({
        materialId,
      });
    }
  }, [material]);

  const {
    code,
    description,
    keywords,
    meta,
    name,
    title,
    url,
  } = materialDetail;
  return (
    <PageContainer>
      <div>
        <Paper className={classes.paper}></Paper>
        <CmtCard>
          <CmtCardContent>
            <Typography variant="h6">
              Add Grade to Material {material && '- ' + material?.name}
            </Typography>
            <Divider></Divider>
            <Box
              mt={3}
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
              alignItems="center"
              mb={{ xs: 6, md: 5 }}
            >
              <GridContainer>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    required
                    fullWidth
                    name="name"
                    variant="outlined"
                    label="Grade Name"
                    value={name}
                    onChange={onLabelChange}
                    helperText={errorText?.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    required
                    fullWidth
                    name="code"
                    variant="outlined"
                    label="Code"
                    value={code}
                    onChange={onLabelChange}
                    helperText={errorText?.code}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    required
                    fullWidth
                    name="title"
                    variant="outlined"
                    label="Meta Title"
                    value={title}
                    onChange={onLabelChange}
                    helperText={errorText?.title}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    required
                    fullWidth
                    name="url"
                    variant="outlined"
                    label="URL"
                    value={url}
                    onChange={onLabelChange}
                    helperText={errorText?.url}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label className={classes.label}>Meta Description*</label>
                  <textarea
                    className={classes.textarea}
                    required
                    fullWidth
                    rows={3}
                    width={100}
                    name="meta"
                    variant="outlined"
                    placeholder="Meta Description"
                    value={meta}
                    onChange={onLabelChange}
                    // helperText={errorText?.meta}
                  />
                   {errorText.meta?<p style={{color:"#f44336",fontSize:"12px"}}>Please enter Description</p>:""}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppTextInput
                    fullWidth
                    name="file"
                    variant="outlined"
                    type="file"
                    // value={title}
                    onChange={evt => setFile(evt.target.files)}
                    className="mb-5"
                    helperText={errorText?.file}
                  />
                </Grid>
                <Grid item xs={12} sm={11}>
                  <AppTextInput
                    required
                    fullWidth
                    name="keywords"
                    variant="outlined"
                    label="Keywords"
                    value={keywords}
                    onChange={onLabelChange}
                    helperText={errorText?.keywords}
                  />
                </Grid>
                <Grid item sm={1}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={onKeywordsAdd}
                  >
                    <Add />
                  </Button>
                </Grid>
                {keywordsArr && keywordsArr.length > 0 && (
                  <Grid item xs={12} sm={12}>
                    <Box>
                      {keywordsArr.map((e, key) => {
                        return (
                          e.length !== 0 && (
                            <Chip
                              size="medium"
                              key={e}
                              label={e}
                              clickable
                              onDelete={evt => onKeywordsDelete(evt, e)}
                              color="secondary"
                              variant="outlined"
                              className="mb-2 mr-2"
                            />
                          )
                        );
                      })}
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12} sm={12}>
                  <span
                    className={
                      errorText?.descriptionHTML
                        ? classes?.editorLabelError
                        : ''
                    }
                  >
                    Description *
                  </span>
                  {/* <WysiwygEditorExample
                    defaultDescription={description}
                    setDescriptionHTML={setDescriptionHTML}
                    descriptionHTML={description}
                  /> */}

                  <CustomEditor
                    editorData={descriptionHTML || ''}
                    setEditorData={setDescriptionHTML}
                  />

                  <span className={classes?.errorCk}>
                    {errorText?.descriptionHTML}
                  </span>
                </Grid>
              </GridContainer>
            </Box>
            <Box display="flex" justifyContent="flex-end" mb={4}>
              <Button onClick={() => history.push('/manage-materials')}>
                Cancel
              </Button>
              {material && (
                <Box ml={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onSubmitClick}
                  >
                    Save
                  </Button>
                </Box>
              )}
            </Box>
          </CmtCardContent>
        </CmtCard>
      </div>
    </PageContainer>
  );
};

export default AddGradeToMaterial;

AddGradeToMaterial.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
