import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box,
  Checkbox,
  MenuItem,
  TablePagination,
  Typography,
} from '@material-ui/core';

import MoreOptions from './MoreOptions';
import HeaderOptions from './HeaderOptions';
import useStyles from '../../index.style';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';

import {
  setFilterType,
  updateMailsFolder,
  updateMailsLabel,
} from 'redux/actions/MailApp';

const selectCategories = [
  { id: 12, label: 'None', slug: 'none' },
  { id: 11, label: 'All', slug: 'all' },
  { id: 1, label: 'Supplier', slug: 'supplier_email' },
  { id: 2, label: 'Read', slug: 'read' },
  { id: 3, label: 'Unread', slug: 'unread' },
  { id: 4, label: 'Enquiry', slug: 'enquiry' },
];

const pageSize = [
  { id: 10, label: '10', value: 10 },
  { id: 15, label: '15', value: 15 },
  { id: 20, label: '20', value: 20 },
  { id: 25, label: '25', value: 25 },
  { id: 50, label: '50', value: 50 },
  { id: 100, label: '100', value: 100 },
];

const onSelectMails = (category, mails) => {
  let selectedMails = [];
  switch (category) {
    case 'all': {
      selectedMails = mails;
      break;
    }
    case 'supplier_email': {
      selectedMails = mails.filter(
        mail => mail?.emailType === 'supplier_email',
      );
      break;
    }
    case 'read': {
      selectedMails = mails.filter(mail => mail?.seenStatus === 'read');
      break;
    }
    case 'unread': {
      selectedMails = mails.filter(mail => mail?.seenStatus === 'unread');
      break;
    }
    case 'enquiry': {
      selectedMails = mails.filter(mail => mail?.emailType === 'enquiry');
      break;
    }
    default:
  }
  return selectedMails.map(mail => mail?._id);
};

const ListHeader = ({ checkedMails, setCheckedMails, refresh, setRefresh }) => {
  const dispatch = useDispatch();
  const [category, setCategory] = useState(selectCategories[0]?.slug);
  const [perPage, setPerPage] = useState(pageSize[0]?.id);
  const classes = useStyles();

  const { filterType } = useSelector(({ mailApp }) => mailApp);
  const { mailsList, labelsList, totalMailCount } = useSelector(
    ({ Emails }) => Emails,
  );

  useEffect(() => {
    const selectedMails = onSelectMails(category, mailsList);
    setCheckedMails(selectedMails);
  }, [category, dispatch, setCheckedMails, mailsList]);

  useEffect(() => {
    setCategory(selectCategories[0]?.slug);
  }, [refresh]);

  const handleCheckBox = event => {
    if (event.target.checked) {
      const mailIds = mailsList.map(mail => mail?._id);
      setCheckedMails(mailIds);
    } else {
      setCheckedMails([]);
      setCategory('none');
    }
  };

  const onChangeMailFolder = folder => {
    dispatch(updateMailsFolder(checkedMails, folder));
    setCheckedMails([]);
  };

  const onSelectLabel = label => {
    dispatch(updateMailsLabel(checkedMails, label));
    setCheckedMails([]);
  };

  const onPageChange = (event, value) => {
    dispatch(
      setFilterType({
        ...filterType,
        page: value,
      }),
    );
  };

 const onChangePageSize =(evt)=>{
  // setPerPage(evt.target.value)
    dispatch(
      setFilterType({
        ...filterType,
        page: filterType?.page,
        perPage:evt.target.value
      }),
    );
  }

  return (
    <Box className={classes.appContentHeader}>
      <Checkbox
        color="primary"
        indeterminate={
          checkedMails.length > 0 && checkedMails.length < mailsList.length
        }
        checked={
          checkedMails.length > 0 && checkedMails.length === mailsList.length
        }
        onChange={handleCheckBox}
      />

      <AppSelectBox
        id="mail-app"
        data={selectCategories}
        value={category}
        fullWidth={false}
        onChange={e => setCategory(e.target.value)}
        className={classes.selectBoxRoot}
        renderRow={(item, index) => (
          <MenuItem key={index} value={item?.slug}>
            {item?.label}
          </MenuItem>
        )}
      />
      <Box ml="auto" display="flex" alignItems="center">
        <Typography variant="subtitle2" gutterBottom>
          Show
        </Typography>
        <AppSelectBox
        id="mail-apps"
        data={pageSize}
        value={filterType?.perPage}
        defaultValue={pageSize[0]?.id}
        fullWidth={false}
        onChange={onChangePageSize}
        // onChange={e => setPerPage(e.target.value)}
        className={classes.selectBoxRoot}
        renderRow={(item, index) => (
          <MenuItem key={index} value={item?.id}>
            {item?.label}
          </MenuItem>
        )}
      />
        <Typography variant="subtitle2" gutterBottom>
          Conversations per page
        </Typography>
      </Box>

      {checkedMails.length > 0 ? (
        <Box ml="auto" display="flex" alignItems="center">
          <HeaderOptions
            onChangeMailFolder={onChangeMailFolder}
            onSelectLabel={onSelectLabel}
            labelsList={labelsList}
            checkedMails={checkedMails}
            setRefresh={setRefresh}
            refresh={refresh}
          />
          {/* <MoreOptions
            checkedMails={checkedMails}
            setCheckedMails={setCheckedMails}
            mailsList={mailsList}
          /> */}
        </Box>
      ) : (
        <Box ml="auto" display="flex" alignItems="center">
          {totalMailCount ? (
            <TablePagination
              component="div"
              count={totalMailCount}
              rowsPerPage={filterType?.perPage}
              page={filterType?.page}
              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
              onChangePage={onPageChange}
              rowsPerPageOptions={[]}
            />
          ) : null}
        </Box>
      )}
    </Box>
  );
};

export default ListHeader;

ListHeader.prototype = {
  checkedMails: PropTypes.array.isRequired,
  setCheckedMails: PropTypes.func,
};
