import { logger, consoleTransport } from "react-native-logs";
import config from "./Config.util";

const defaultConfig = {
    levels: {
        debug: 0,
        http: 1,
        info: 2,
        warn: 3,
        error: 4,
    },
    severity: config.NODE_ENV === 'development' ? 'debug' : 'error',
    transport: consoleTransport,
    transportOptions: {
        colors: {
            debug: "blueBright",
            http: 'magenta',
            info: "green",
            warn: "yellow",
            error: "redBright",
        },
        extensionColors: {
            root: "magenta",
            home: "green",
        },
    },
    async: true,
    dateFormat: "time",
    printLevel: true,
    printDate: true,
    enabled: true,
};
var log = logger.createLogger(defaultConfig);
export default log

