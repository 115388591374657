import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, IconButton, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { getAllGrades } from 'redux/actions/Manage/ProductMaterialGrades';
import { getMaterialGrades } from 'redux/actions/Manage/Materials/Materials';
import Pagination from '@mui/material/Pagination';


const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
    },
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  titleRoot: {
    fontSize: 16,
    marginLeft: 12,
    fontWeight: theme.typography.fontWeightBold,
  },
  tagListRoot: {
    color: theme.palette.text.disabled,
    padding: '0 3px 0 0',
    letterSpacing: 0.4,
    fontSize: 12,
    width: 'auto',
  },
  blockRoot: {
    display: 'block',
    color: theme.palette.text.disabled,
  },
  descriptionBlock: {
    '& p': {
      marginBottom: 16,
      fontSize: 14,
      color: theme.palette.text.secondary,
    },
  },
  linkBtn: {
    cursor: 'pointer',
    textTransform: 'uppercase',
  },
  imageRoot: {
    width: '100%',
    height: 250,
  },
  badge: {
    position: 'absolute',
    bottom: 15,
    left: 20,
    zIndex: 1,
    fontSize: 12,
    padding: '4px 16px',
    letterSpacing: 0.4,
    borderRadius: 16,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  marginClass: {
    marginX: 5,
  },
}));

const ViewGradeList = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  //------------all use selectors
  const { material } = useSelector(state => state.SetMaterialDetail);
  const { material_grade } = useSelector(state => state.Materials);

  //-------------all use states
  const [materialData, setMaterialData] = useState({});
  const [gradeList, setGradeList] = useState([]);
  const [keywords, setkeywords] = useState([]);
  const [page, setPage] = useState(1);
  console.log(page);
  const [totalPage, setTotalPage] = useState(0);

  //------all useEffects
  useEffect(() => {
    if (material) {
      let params = {
        materialId: material?.materialId,
      };
      dispatch(getMaterialGrades(params));
      setMaterialData(material);
    }
  }, []);
  console.log(material_grade);
  useEffect(() => {
    if (material_grade) {
      let count = material_grade.length;
      setTotalPage(Math.ceil(count /25));
      if (page < 2) {
        setGradeList(material_grade.slice(0, 25));
      } else {
        setGradeList(material_grade.slice((page - 1) * 25 , page * 25 ));
      }
    }
  }, [material_grade, page]);

  const handlePageChange = (event, value) => {
    console.log(`Page ${value}`);
    setPage(value);
  };

  const getSubTitle = () => (
    <Box
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      color="text.disabled"
      fontSize={12}
      mb={3}
    >
      <Box component="span" mr={2} color="primary.main">
        {materialData.name}
      </Box>
      <Divider orientation="vertical" flexItem />
      <Divider orientation="vertical" flexItem />
      <Box ml={2}>
        <Box component="span" color="text.primary" mr={2}></Box>
        <Box component="span">Description</Box>
      </Box>
    </Box>
  );

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box mt={-2} mb={-2} display="flex" justifyContent="space-between">
        <Box ml={-2}>
          <DialogTitle className={classes.dialogTitleRoot}>
            {materialData && `${materialData.name + ' Grades List'}`}
          </DialogTitle>
        </Box>
        <Box ml={2}>
          <IconButton onClick={onCloseDialog}>
            <ClearIcon />
          </IconButton>
        </Box>
      </Box>
      <DialogContent dividers>
        {gradeList && gradeList.length > 0 ? (
          gradeList.map((mat, key) => {
            return (
              <Box key={key} display="flex" justifyContent="space-between">
                <Typography className="mb-2">
                  {page === 1
                    ? `${key + 1}. `
                    : `${(page - 1) * 25 + 1 + key}. `}
                  {mat.displayName}
                </Typography>
              </Box>
            );
          })
        ) : (
          <Typography>No Grades</Typography>
        )}
        {/* </GridContainer> */}
        {/* </CmtCard> */}

        {
            <Pagination
              count={totalPage}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />
        }
      </DialogContent>
    </Dialog>
  );
};

export default ViewGradeList;

ViewGradeList.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
