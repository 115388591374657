export const SENT_APPROVE_EMAIL_LIST_SUCCESS = "SENT_APPROVE_EMAIL_LIST_SUCCESS";
export const SENT_APPROVE_EMAIL_LIST_FAILED = "SENT_APPROVE_EMAIL_LIST_FAILED";

export const RECEIVE_INBOX_EMAIL_LIST_SUCCESS = "RECEIVE_INBOX_EMAIL_LIST_SUCCESS";
export const RECEIVE_INBOX_EMAIL_LIST_FAILED = "RECEIVE_INBOX_EMAIL_LIST_FAILED";



export const TOP_10_SUPPLIER_EMAIL_LIST_SUCCESS = "TOP_10_SUPPLIER_EMAIL_LIST_SUCCESS ";
export const TOP_10_SUPPLIER_EMAIL_LIST_FAILED = "TOP_10_SUPPLIER_EMAIL_LIST_FAILED ";


export const SUPPLER_REGISTRATION_EMAIL_LIST_SUCCESS = "SUPPLER_REGISTRATION_EMAIL_LIST_SUCCESS";
export const SUPPLER_REGISTRATION_EMAIL_LIST_FAILED = "SUPPLER_REGISTRATION_EMAIL_LIST_FAILED";


