import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { Box } from '@material-ui/core';

import useStyles from './index.style';
import AppHeader from './AppHeader';
import MailSidebar from './MailSidebar';
import MailsList from './MailsList';
import MailDetail from './MailDetail';
import ComposeMail from './ComposeMail';
import EmailListPopUp from './EmailListPopUp';

import { onNullifyMail, toggleExpandSidebar } from 'redux/actions/MailApp';
import { getEmailById, setEmailListPopUp } from 'redux/actions/Manage/Emails/Emails';

const MailApp = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [openComposeDialog, setOpenComposeDialog] = useState(false);
  // const { isSideBarCollapsed, selectedMail, pop_up } = useSelector(
  //   ({ mailApp }) => mailApp,
  // );
  const { isSideBarCollapsed, selectedMail, pop_up } = useSelector(
    ({ Emails }) => Emails,
  );
  const [mailTo, setMailTo] = useState('');
  const [mailContent, setMailContent] = useState('');
  const [viewMode, setViewMode] = useState('detail');
  const [openEmailPopDialog, setOpenEmailPopDialog] = useState(false);
  const [reload, setReload]= useState(false)
  const labelsList = [
    { id: 'enquiry', name: 'Enquiry', slug: 'enquiry', color: '#00C4B4' },
    { id: 'supplier_email', name: 'Supplier', slug: 'supplier_email', color: '#FF8C00' },
  ];

  const onClickSendMail = to => {
    setMailTo(to);
    onOpenComposeDialog();
  };

  const onClickForwardMail = (message, id) => {
    setMailContent(message);
    if (id) dispatch(getEmailById(id));
    onOpenComposeDialog();
  };

  const onOpenComposeDialog = () => {
    setOpenComposeDialog(true);
    dispatch(onNullifyMail());
  };

  const onCloseComposeDialog = () => {
    setOpenComposeDialog(false);
    setMailContent('');
    setMailTo('');
    dispatch(onNullifyMail());
  };

  const handleViewModeChange = mode => {
    setViewMode(mode);
    if (mode === 'detail') {
      dispatch(toggleExpandSidebar(false));
    } else {
      dispatch(toggleExpandSidebar(true));
    }
  };

  useEffect(() => {
    if (pop_up.status === true) setOpenEmailPopDialog(true);
    else setOpenEmailPopDialog(false);
  }, [pop_up]);

  return (
    <Box className={classes.inBuildAppCard}>
      <AppHeader
        viewMode={viewMode}
        handleViewModeChange={handleViewModeChange}
      />
      <Box
        className={clsx(
          classes.inBuildAppContainer,
          isSideBarCollapsed ? 'collapsed' : '',
        )}
      >
        <MailSidebar
          labelsList={labelsList}
          onClickSendMail={onClickSendMail}
          onOpenComposeDialog={onOpenComposeDialog}
          openComposeDialog={openComposeDialog}
        />
        {selectedMail ? (
          <MailDetail onClickForwardMail={onClickForwardMail} />
        ) : (
          <MailsList
            labelsList={labelsList}
            onClickSendMail={onClickSendMail}
            onClickForwardMail={onClickForwardMail}
            viewMode={viewMode}
            reload={reload}
          
          />
        )}
        {openComposeDialog && (
          <ComposeMail
            openDialog={openComposeDialog}
            onCloseComposeDialog={onCloseComposeDialog}
            mailTo={mailTo}
            mailContent={mailContent}
            setReload={setReload}
            reload={reload}
          />
        )}
        {openEmailPopDialog && (
          <EmailListPopUp
            open={openEmailPopDialog}
            onCloseDialog={() =>
              dispatch(setEmailListPopUp({ status: false, emailList: [] }))
            }
          />
        )}
      </Box>
    </Box>
  );
};

export default MailApp;
