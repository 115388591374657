import { ROLES_LIST_SUCCESS, ROLES_CREATE_SUCCESS, ROLES_DELETE_SUCCESS, SET_ROLES_DETAILS, ROLES_UPDATE_SUCCESS, ROLES_PERMISSIONS_LIST_SUCCESS, ROLE_FETCH_SUCCESS } from "redux/actions/index";

const INIT_STATE =
{
    roles: [],
    role: null,
    // roles_users: [],
    total: 0,
    perPage: 0
    // permissions: null,
}
const INIT_STATE_DETAIL =
{
    role: null,
    role_permissions: []
}


export const RolesReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ROLES_CREATE_SUCCESS: {
            return {
                ...state,
                role: action.data,
                action: action.type,
                message: action.message,
                roles: [action.data, ...state.roles]
            };
        }
        case ROLES_LIST_SUCCESS: {
            return {
                ...state,
                roles: action.data,
                action: action.type,
                total: action.total,
                perPage: action.perPage,
                message: action.message
            };
        }
        // case ROLESS_USERS_LIST_SUCCESS: {
        //     return {...state,
        //         roles: state.roles,
        //         // roles_users: action.data,
        //         action: action.type,
        //         total: state.total,
        //         perPage: state.perPage,
        //         message: action.message
        //     };
        // }
        //roles: state.roles.map(role => (role._id === action.payload.id ? action.payload : role))
        case ROLES_UPDATE_SUCCESS: {
            return {
                ...state,
                role: action.data,
                action: action.type,
                message: action.message,
                roles: state.roles.map(role => (role._id === action.data._id ? action.data : role))
            };
        }
        case ROLES_DELETE_SUCCESS: {
            return {
                ...state,
                role: action.data,
                action: action.type,
                message: action.message,
                roles: state.roles.filter(role => role._id !== action.data),
            };
        }
        // case DELETE_BULK_ROLESS_SUCCESS: {
        //     return {...state,
        //         ...state,
        //         action: action.type,
        //         message: action.message,
        //         roles: state.roles.filter(role => !action.data.includes(role._id)),
        //     };
        // }
        default:
            return state;
    }
};
export const SetRoleDetailReducer = (state = INIT_STATE_DETAIL, action) => {
    switch (action.type) {
        case SET_ROLES_DETAILS:
            return {
                ...state,
                role: action.data,
                action: action.type,
            };
        case ROLES_PERMISSIONS_LIST_SUCCESS:
            return {
                ...state,
                role_permissions: action.data,
                action: action.type,
                message: action.message,
                role: state.role
            };
        case ROLE_FETCH_SUCCESS:
            return {
                ...state,
                role: action.data,
                action: action.type,
                message: action.message,
            };

        default:
            return state;
    }
};











// import { ROLES_CREATE_SUCCESS, ROLES_LIST_SUCCESS, ROLES_DELETE_SUCCESS, ROLES_UPDATE_SUCCESS, SET_ROLES_DETAILS } from "redux/actions/Manage/Roles/Constant";
// import { PERMISSIONS_UPDATE_SUCCESS } from "redux/actions/index"

// const INIT_STATE =
// {
//     roles: null,
//     role: null,
//     permissions: null,
//     total: 0,
//     perPage: 0
// }
// const INIT_STATE_DETAIL =
// {
//     role: null,
// }

// export const RolesReducer = (state = INIT_STATE, action) => {
//     switch (action.type) {
//         case ROLES_CREATE_SUCCESS: {
//             return {...state,
//                 role: action.data,
//                 action: action.type,
//                 message: action.message
//             };
//         }
//         case ROLES_LIST_SUCCESS: {
//             return {...state,
//                 roles: action.data,
//                 action: action.type,
//                 message: action.message
//             };
//         }
//         case ROLES_UPDATE_SUCCESS: {
//             return {...state,
//                 role: action.data,
//                 action: action.type,
//                 message: action.message,
//                 total: action.total,
//                 perPage: action.perPage,
//                 roles: state.roles.map(role => (role._id === action.data._id ? action.data : role))
//             };
//         }
//         case PERMISSIONS_UPDATE_SUCCESS: {
//             return {...state,
//                 permissions: action.data,
//                 action: action.type,
//                 message: action.message
//             };
//         }
//         case ROLES_DELETE_SUCCESS: {
//             return {...state,
//                 role: action.data,
//                 action: action.type,
//                 message: action.message,
//                 roles: state.roles.filter(role => role._id !== action.data._id),
//             };
//         }
//         default:
//             return state;
//     }
// };
// export const SetRoleDetailReducer = (state = INIT_STATE_DETAIL, action) => {
//     switch (action.type) {
//         case SET_ROLES_DETAILS:
//             return {...state,
//                 role: action.data,
//                 action: action.type,
//             };
//         default:
//             return state;
//     }
// };
// // export const RolesListReducer = (state = INIT_STATE_LIST, action) => {
// //     switch (action.type) {
// //         // case ROLESS_LIST_REQUEST:
// //         //     return {...state,
// //         //         loading: action.loading,
// //         //         action: action.type,
// //         //     };
// //         case ROLESS_LIST_SUCCESS: {
// //             return {...state,
// //                 loading: action.loading,
// //                 roles: action.data,
// //                 action: action.type,
// //             };
// //         }
// //         // case ROLESS_LIST_FAILED:
// //         //     return {...state,
// //         //         loading: false,
// //         //         error: action.payload,
// //         //         action: action.type
// //         //     };
// //         default:
// //             return state;
// //     }
// // };
// // export const RoleDetailsReducer = (state = INIT_STATE_DETAIL, action) => {
// //     switch (action.type) {
// //         case ROLES_DETAILS_REQUEST:
// //             return {...state,
// //                 loading: true,
// //                 action: action.type,
// //             };
// //         case ROLES_DETAILS_SUCCESS: {
// //             return {...state,
// //                 loading: false,
// //                 role: action.payload,
// //                 action: action.type,
// //             };
// //         }
// //         case ROLES_DETAILS_FAILED:
// //             return {...state,
// //                 loading: false,
// //                 error: action.payload,
// //                 action: action.type
// //             };
// //         default:
// //             return state;
// //     }
// // };

// // export const RoleCreateReducer = (state = INIT_STATE_DETAIL, action) => {
// //     switch (action.type) {
// //         // case ROLES_CREATE_REQUEST:
// //         //     return {...state,
// //         //         loading: action.loading,
// //         //         action: action.type,
// //         //     };
// //         case ROLES_CREATE_SUCCESS: {
// //             return {...state,
// //                 loading: action.loading,
// //                 role: action.data,
// //                 action: action.type,
// //             };
// //         }
// //         // case ROLES_CREATE_FAILED:
// //         //     return {...state,
// //         //         loading: false,
// //         //         error: action.payload,
// //         //         action: action.type
// //         //     };
// //         default:
// //             return state;
// //     }
// // };
// // export const RoleUpdateReducer = (state = INIT_STATE_DETAIL, action) => {
// //     switch (action.type) {
// //         // case ROLES_UPDATE_REQUEST:
// //         //     return {...state,
// //         //         loading: action.loading,
// //         //         action: action.type,
// //         //     };
// //         case ROLES_UPDATE_SUCCESS: {
// //             return {...state,
// //                 loading: action.loading,
// //                 role: action.data,
// //                 action: action.type,
// //             };
// //         }
// //         // case ROLES_UPDATE_FAILED:
// //         //     return {...state,
// //         //         loading: false,
// //         //         error: action.payload,
// //         //         action: action.type
// //         //     };
// //         default:
// //             return state;
// //     }
// // };
// // export const RolePermissionsUpdate = (state = INIT_STATE_PERMISSIONS, action) => {
// //     switch (action.type) {
// //         // case PERMISSIONS_UPDATE_REQUEST:
// //         //     return {...state,
// //         //         loading: true,
// //         //         action: action.type,
// //         //     };
// //         case PERMISSIONS_UPDATE_SUCCESS: {
// //             return {...state,
// //                 loading: false,
// //                 permissions: action.data,
// //                 action: action.type,
// //             };
// //         }
// //         // case PERMISSIONS_UPDATE_FAILED:
// //         //     return {...state,
// //         //         loading: false,
// //         //         error: action.payload,
// //         //         action: action.type
// //         //     };
// //         default:
// //             return state;
// //     }
// // };
// // export const RoleDeleteReducer = (state = INIT_STATE_DETAIL, action) => {
// //     switch (action.type) {
// //         // case ROLES_DELETE_REQUEST:
// //         //     return {...state,
// //         //         loading: true,
// //         //         action: action.type,
// //         //     };
// //         case ROLES_DELETE_SUCCESS: {
// //             return {...state,
// //                 loading: false,
// //                 role: action.data,
// //                 action: action.type,
// //             };
// //         }
// //         // case ROLES_DELETE_FAILED:
// //         //     return {...state,
// //         //         loading: false,
// //         //         error: action.payload,
// //         //         action: action.type
// //         //     };
// //         default:
// //             return state;
// //     }
// // };