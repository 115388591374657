import Axios from "axios";
import { MSG_ERR_RESPONDING_SERVER, PERMISSION_LIST } from "redux/actions/Common/Constant";
import { setError } from "redux/actions/Common/ErrorAction";
import { setLoading } from "redux/actions/Common/LoadingAction";
import { setSuccess } from "redux/actions/Common/SuccessAction";
import { ErrorCatch } from "utils/ErrorCatch";
import { PERMISSIONS_LIST_FAILED, PERMISSIONS_LIST_REQUEST, PERMISSIONS_LIST_SUCCESS, ROLES_PERMISSIONS_LIST_REQUEST, ROLES_PERMISSIONS_LIST_SUCCESS, ROLES_PERMISSIONS_UPDATE_FAILED, ROLES_PERMISSIONS_UPDATE_REQUEST, ROLES_PERMISSIONS_UPDATE_SUCCESS } from "./Constant";
let RES_MSG = MSG_ERR_RESPONDING_SERVER
export const getAllPermissions = (callbackFun) =>
    async (dispatch) => {
        // dispatch(setLoading(PERMISSIONS_LIST_REQUEST))
        dispatch({
            type: PERMISSIONS_LIST_SUCCESS,
            data: PERMISSION_LIST,
            // message: data.responseMsg,
            // total: data.data.total,
            // perPage: data.data.perPage
        });
        // if (callbackFun) callbackFun(PERMISSION_LIST);
        // try {
        //     const { data } = await Axios.get(`/permission/list`)
        //     if (data.statusCode === 200) {
        //         // dispatch(setSuccess(null, PERMISSIONS_LIST_SUCCESS))
        //     }
        //     else {
        //         dispatch(setError(MSG_ERR_RESPONDING_SERVER, PERMISSIONS_LIST_FAILED));
        //     }
        // } catch (error) {
        //     ErrorCatch(error, PERMISSIONS_LIST_FAILED, dispatch)
        // }
    };
;
export const getAllRolesPermissions = (roleId, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(ROLES_PERMISSIONS_LIST_REQUEST))
        try {
            const { data } = await Axios.get(`/permission/allow-list/${roleId}`)
            if (data.statusCode === 200) {
                // dispatch(setSuccess(null, ROLES_PERMISSIONS_LIST_SUCCESS))
                dispatch({
                    type: ROLES_PERMISSIONS_LIST_SUCCESS,
                    data: data.data.rolePermissions,
                    message: data.responseMsg,
                    total: data.data.total,
                    perPage: data.data.perPage
                });
                if (callbackFun) callbackFun(data.data.rolePermissions);
            }
            else {
                // dispatch(setError(MSG_ERR_RESPONDING_SERVER, ROLES_PERMISSIONS_LIST_FAILED));
            }
        } catch (error) {
            // ErrorCatch(error, ROLES_PERMISSIONS_LIST_FAILED, dispatch)

        }
    };
;
export const updateRolePermissions = (payload, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(ROLES_PERMISSIONS_UPDATE_REQUEST))
        try {
            const { data } = await Axios.post(`/permission/allow`, payload)
            if (data.statusCode === 200) {
                dispatch(setSuccess(data.responseMsg, ROLES_PERMISSIONS_UPDATE_SUCCESS))
                dispatch({
                    type: ROLES_PERMISSIONS_UPDATE_SUCCESS,
                    data: data.data.allowRolePermission,
                    message: data.responseMsg,
                    total: data.data.total,
                    perPage: data.data.perPage
                });
                if (callbackFun) callbackFun(data.data.allowRolePermission);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, ROLES_PERMISSIONS_UPDATE_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, ROLES_PERMISSIONS_UPDATE_FAILED, dispatch)
        }
    };
;