// successReducer.js

import { HIDE_SUCCESS } from "redux/actions/Common/Constant";

const initState = {
    success: false,
    isOpen: false
};

export function SuccessReducer(state = initState, action) {
    const { success } = action;

    if (success) {
        return {
            success: action.message,
            action: action.action,
            // data: action.data,
            isOpen: true
        }
    } else if (action.type === HIDE_SUCCESS) {
        return initState
    }

    return state;
}