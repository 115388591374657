const { SEND_RFQ_EMAIL_REQUEST, SEND_RFQ_EMAIL_SUCCESS, SEND_RFQ_EMAIL_FAILED, REQUEST_FREE_SUPPLIER_LIST, SUCCESS_FREE_SUPPLIER_LIST, FAILED_FREE_SUPPLIER_LIST } = require("redux/actions/Manage/SendRfqEmail/constant");

const sendRfqEmail = {
    loading: false,
    sendRfqDataStatus: {},
    error: null
}

const freeSupplier = {
    loading: false,
    freeSupplierList: [],
    total: 0,
    error: null
}

export const sendRfqEmailReducer = (state = sendRfqEmail, action) => {
    switch (action.type) {
        case SEND_RFQ_EMAIL_REQUEST:
            return {
                ...state,
                loading: true,
                error:null
            };
        case SEND_RFQ_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                sendRfqDataStatus: action?.payload?.data,
                error:null
            };
        case SEND_RFQ_EMAIL_FAILED:
            return {
                loading: false,
                sendRfqDataStatus: {},
                error: action?.payload?.error
            }
        default:
            return state
    }
}
export const freeSupplierReducer = (state = freeSupplier, action) => {
    switch (action.type) {
        case REQUEST_FREE_SUPPLIER_LIST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case SUCCESS_FREE_SUPPLIER_LIST:
            return {
                ...state,
                loading: false,
                freeSupplierList: action?.payload?.data,
                total: action?.payload?.total,
                error:null
            };
        case FAILED_FREE_SUPPLIER_LIST:
            return {
                loading: false,
                freeSupplierList: [],
                total: 0,
                error: action?.payload?.error
            }
        default:
            return state
    }
}