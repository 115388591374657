import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import { isValidPassword } from '@jumbo/utils/commonHelper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { adminUpdatePassword, updateUserPassword } from 'redux/actions/Manage/Users/Users';
// import { getAllDepts } from 'redux/actions/Manage/Department/Department';
import AlertBox from 'components/AlertBox';
// import { addNewUser, updateUser } from '../../../../redux/actions/Users';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
}));

function PhoneNumberInput({ onChange, value, ...other }) {
  const [phoneNumber, setPhoneNumber] = useState('');
  useEffect(() => {
    if (!phoneNumber && value) {
      setTimeout(() => {
        setPhoneNumber(value);
      }, 300);
    }
  }, [phoneNumber, value]);

  const onNumberChange = number => {
    setPhoneNumber(number.formattedValue);
    onChange(number.formattedValue);
  };

  return <NumberFormat {...other} onValueChange={onNumberChange} value={phoneNumber} format="(###) ###-####" />;
}
const labels = [
  // { title: 'Deleted', code: 0 },
  { title: 'Active', code: 1 },
  { title: 'Disable', code: 2 },
];

const splitName = user => {
  if (user) {
    const [fName, mName, lName] = user.name.split(' ');
    return [fName, lName ? mName + ' ' + lName : mName];
  }

  return ['', ''];
};

const ChangePassword = ({ currentPasswordBlock = false, open, onCloseDialog }) => {
  const classes = useStyles();
  const { user } = useSelector((state) => state.SetUserDetail);
  const { error, isOpen } = useSelector(state => state.Error)
  // const { role } = useSelector((state) => state.SetRoleDetail);

  const [userDetail, setUserDetail] = useState({
    newPassword: '',
    conformNewPassword: '',
    userId: '',
  });
  const [userDetailProfile, setUserDetailProfile] = useState({
    newPassword: '',
    conformNewPassword: '',
    currentPassword: '',
    _id: '',
  });

  const dispatch = useDispatch();
  const [errorText, setErrorText] = React.useState({});
  const validate = () => {
    const errors = {};
    let isError = false;
    let newPassword
    let conformNewPassword
    let currentPassword
    if (currentPasswordBlock) {
      newPassword = userDetailProfile.newPassword
      conformNewPassword = userDetailProfile.conformNewPassword
      currentPassword = userDetailProfile.currentPassword
    } else {
      newPassword = userDetail.newPassword
      conformNewPassword = userDetail.conformNewPassword
    }
    if (currentPasswordBlock && !currentPassword) {
      errors.currentPassword = 'Please enter current password.';
      isError = true;
    }
    if (!newPassword) {
      errors.newPassword = 'Please enter new password.';
      isError = true;
    }
    else if (isValidPassword(newPassword)) {
      errors.newPassword =
        'Password must include at least 8 characters and contain at least 1 lowercase & 1 uppercase letter, a number and special symbols.';
      isError = true;
    }
    if (!conformNewPassword) {
      errors.conformNewPassword = 'Please enter confirm password.';
      isError = true;
    }
    // else if (isValidPassword(conformNewPassword)) {
    //   errors.conformNewPassword =
    //     'Password must include at least 8 characters and contain at least 1 lowercase & 1 uppercase letter, a number and special symbols.';
    //   isError = true;
    // } 
    else if (newPassword !== conformNewPassword) {
      errors.conformNewPassword =
        'New password and confirm password does not match. '
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };
  useEffect(() => {
    if (user && currentPasswordBlock) {
      const { _id } = user
      setUserDetailProfile({
        id: _id
      })
    }
    else if (user) {
      const { _id } = user
      setUserDetail({
        userId: _id
      })
    }
  }, [user]);

  const onSubmitClick = () => {
    let validateData = validate();
    if (!validateData.isError) {
      onUserSave();
    }
  };

  const onUserSave = () => {
    if (currentPasswordBlock && user)
      dispatch(
        updateUserPassword(userDetailProfile, () => {
          onCloseDialog();
        }),
      );
    else if (user) {
      dispatch(
        adminUpdatePassword(userDetail, () => {
          onCloseDialog();
        }),
      );
    }
  };

  const onLabelChange = evt => {
    if (currentPasswordBlock)
      setUserDetailProfile({ ...userDetailProfile, [evt.target.name]: evt.target.value });
    else
      setUserDetail({ ...userDetail, [evt.target.name]: evt.target.value });
  };
  // useEffect(() => {
  //   dispatch(getAllDepts())
  //   dispatch(getAllRoles())
  // }, [dispatch])
  let newPassword
  let conformNewPassword
  let currentPassword
  if (currentPasswordBlock) {
    newPassword = userDetailProfile.newPassword
    conformNewPassword = userDetailProfile.conformNewPassword
    currentPassword = userDetailProfile.currentPassword
  } else {
    newPassword = userDetail.newPassword
    conformNewPassword = userDetail.conformNewPassword
  }
  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>{user && `Change Password`}</DialogTitle>
      <DialogContent dividers>
        {currentPasswordBlock && <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            eyeBtn={true}
            type="password"
            name='currentPassword'
            fullWidth
            variant="outlined"
            label="Current Password"
            value={currentPassword}
            onChange={onLabelChange}
            helperText={errorText?.currentPassword}
          />
        </Box>}
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            eyeBtn={true}
            type="password"
            name={'newPassword'}
            fullWidth
            variant="outlined"
            label="New Password"
            value={newPassword}
            onChange={onLabelChange}
            helperText={errorText?.newPassword}
          />
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            type="password"
            name='conformNewPassword'
            eyeBtn={true}
            fullWidth
            variant="outlined"
            label="Confirm Password"
            value={conformNewPassword}
            onChange={onLabelChange}
            helperText={errorText?.conformNewPassword}
          />
        </Box>
        {/* <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <GridContainer>
            {currentPasswordBlock && <Grid item xs={12} sm={6}>
              <AppTextInput
                type="password"
                name='conformNewPassword'
                fullWidth
                variant="outlined"
                label="Current Password"
                value={conformNewPassword}
                onChange={onLabelChange}
                helperText={errorText?.conformNewPassword}
              />
            </Grid>}
            <Grid item xs={12} sm={6}>
              <AppTextInput
                type="password"
                name='password'
                fullWidth
                variant="outlined"
                label="Password"
                value={password}
                onChange={onLabelChange}
                helperText={errorText?.password}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AppTextInput
                type="password"
                name='conformNewPassword'
                fullWidth
                variant="outlined"
                label="Confirm Password"
                value={conformNewPassword}
                onChange={onLabelChange}
                helperText={errorText?.conformNewPassword}
              />
            </Grid>
          </GridContainer>
        </Box> */}
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog}>Cancel</Button>
          <Box ml={2}>
            <Button variant="contained" color="primary" onClick={onSubmitClick}>
              Update
            </Button>
          </Box>
        </Box>
        {error && isOpen && <AlertBox type='error' style="filled" >{error}</AlertBox>}
      </DialogContent>
    </Dialog >
  );
};

export default ChangePassword;

ChangePassword.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
