import { setLoading } from "redux/actions/Common/LoadingAction";
import { RFQ_ADD_FAILED, RFQ_ADD_REQUEST, RFQ_ADD_SUCCESS } from "./constant";
import { setError } from "redux/actions/Common/ErrorAction";

const { default: Axios } = require("axios");

export const rfqList = (queryData)=>{
    return async (dispatch)=>{
        try {
            dispatch(setLoading(RFQ_ADD_REQUEST));
            dispatch({type:RFQ_ADD_REQUEST});
            const {data} = await Axios.get(`api/v1/admin/rfq/list?search=${queryData?.search || ""}&page=${queryData?.page  || 1}`);
            dispatch({type:RFQ_ADD_SUCCESS,payload:{
                list:data?.RFQ,
                totalPage:data?.totalPage
            }})
        } catch (error) {
            dispatch(setError(error?.data?.error, RFQ_ADD_FAILED));
            dispatch({type:RFQ_ADD_FAILED,payload:{
                error:error?.data?.error
            }})
        }
    }
}