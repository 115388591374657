import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Switch } from '@material-ui/core';
import { MoreHoriz, Edit } from '@material-ui/icons';

import CustomTable from 'components/CustomeTable';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import CustomModal from 'components/CustomModal';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import { SetGradeListForEqGrade } from 'redux/reducers/Manage/EqGrades';
import {
  crateGradeModalList,
  getGradeModalList,
  updateGradeModalList,
} from 'redux/actions/Manage/GradeModal';

const GradeModal = () => {
  const dispatch = useDispatch();
  const { gradesModal, total, perPage } = useSelector(
    state => state?.GradeModel,
  );
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [errorText, setErrorText] = useState({});
  const [grade, setGrade] = useState('');
  const [gradeList, setGradeList] = useState([]);
  const [id, setId]= useState('')
  const [refresh, setRefresh]= useState(false)

  let headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Grade Name',
      visible: true,
    },

    {
      id: 'active',
      numeric: false,
      disablePadding: false,
      sort: false,
      label: 'Status',
      render: elm => (
        <Switch
          checked={elm.isActive === true ? true : false}
          onChange={() => handleChangeStatusSwitch(elm)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      ),
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Actions',
      sort: false,
      render: elm => (
        <CmtDropdownMenu
          items={userActions}
          onItemClick={menu => onUserMenuClick(menu, elm)}
          TriggerComponent={<MoreHoriz />}
        />
      ),
    },
  ];


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const getUserActions = () => {
    const actions = [];
    actions.push({ action: 'edit', label: 'Edit', icon: <Edit /> });
    return actions;
  };

  const userActions = getUserActions();
  const onUserMenuClick = (menu, elm) => {
    if (menu.action === 'edit') {
      setOpenModal(true);
      setIsEdit(true);
      setId(elm?._id)
      setGrade(elm?.name)
    }
  };

  const handleChangeStatusSwitch = elm => {
    let status;
    if (elm.isActive === true) status = false;
    else status = true;
    const data = { isActive: status };
    dispatch(updateGradeModalList(elm?._id, data)).then(res => {
      if (res?.status === 200) {
        setOpenModal(false);
        setIsEdit(false);
        setRefresh(!refresh)
      }
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setIsEdit(false);
    setGrade('')
    setId('')
    setErrorText({})
  };

  const handleValidate = () => {
    let errors = {};
    let isError = false;
    if (!grade) {
      errors.name = 'Please enter grade name.';
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };

  const handleSubmit = () => {
    let validate = handleValidate();
    if (!validate?.isError) {
      const value={name:grade}
      dispatch(isEdit? updateGradeModalList(id, value) :crateGradeModalList(value)).then(res=>{
        if(res?.status === 200){
          setRefresh(!refresh)
          handleCloseModal()
        }
      })
    }
  };

  useEffect(() => {
    let value = { page: page + 1, perPage: 10 }
    if (searchTerm) {
      value.search = searchTerm
    }
    dispatch(getGradeModalList(value)).then(res => {
      if (res?.status === 200) {
        setGradeList(res?.data?.data?.gradeList);
      }
    });


  }, [dispatch, refresh, page]);

  return (
    <>
      <CustomTable
        headCells={headCells}
        row={gradesModal || []}
        count={total || 0}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        rowsPerPage={perPage}
        onPageChange={handlePageChange}
        page={page}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleSearch={text => setSearchTerm(text)}
        handleSearchSubmit={() => {
          setPage(0)
          setRefresh(!refresh)
        }}
        addButtonText={'Add Grade'}
        handleAddButton={() => setOpenModal(true)}
        isDynamic={true}
      />
      <CustomModal
        open={openModal}
        onClose={handleCloseModal}
        title={isEdit ? 'Edit Grade' : 'Add Grade'}
      >
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            name="name"
            variant="outlined"
            label="Grade Name"
            value={grade}
            onChange={(evt)=>setGrade(evt.target.value)}
            helperText={errorText?.name}
          />
        </Box>
        <Box>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {isEdit ? 'Update' : 'Submit'}
          </Button>
        </Box>
      </CustomModal>
    </>
  );
};

export default GradeModal;
