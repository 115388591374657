import { IMAGE_UPLOAD_SUCCESS } from "redux/actions/FileUpload/Constant";

const INIT_STATE =
{
    files: [],
    // permissions: null,
}
// const INIT_STATE_DETAIL =
// {
//     material: null,
// }

export const uploadFileReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case IMAGE_UPLOAD_SUCCESS: {
            return {
                ...state,
                files: action.data,
                action: action.type,
                message: action.message,
            };
        }
        default:
            return state;
    }
};
// export const SetMaterialDetailReducer = (state = INIT_STATE_DETAIL, action) => {
//     switch (action.type) {
//         case SET_MATERIAL_DETAILS:
//             return {
//                 ...state,
//                 material: action.data,
//                 action: action.type,
//             };
//         default:
//             return state;
//     }
// };