import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { isValidEmail, isValidPassword, isValidPhone } from '@jumbo/utils/commonHelper';
import { createUser, updateUser } from 'redux/actions/Manage/Users/Users';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
}));

const AddEditUser = ({ open, onCloseDialog, setDataUpdated }) => {
  const classes = useStyles();
  const { user } = useSelector((state) => state.SetUserDetail);
  const [userDetail, setUserDetail] = useState({
    name: '',
    mobileNo: '',
    email: '',
    password: '',
    address: ''
  });
  const [errorText, setErrorText] = React.useState({});
  const validate = () => {
    const errors = {};
    let isError = false;
    const { name, mobileNo, password, email, address } = userDetail
    if (!name) {
      errors.name = 'Please enter name.';
      isError = true;
    } else if (!/^[A-Za-z\s]*$/.test(name)) {
      errors.name = 'Only letters are allowed.';
      isError = true;
    }
    if (!mobileNo) {
      errors.mobileNo = 'Please enter mobile number.';
      isError = true;
    } else if (!isValidPhone(mobileNo)) {
      errors.mobileNo =
        'Phone number should be 10 digits number.';
      isError = true;
    }
    if (!email) {
      errors.email = 'Please enter email.';
      isError = true;
    } else if (!isValidEmail(email)) {
      errors.email = 'Invalid email address.';
      isError = true;
    }
    if (!user && !password) {
      errors.password = 'Please enter password.';
      isError = true;
    } else if (!user && isValidPassword(password)) {
      errors.password =
        'Password must include at least 8 characters and contain at least 1 lowercase & 1 uppercase letter, a number and special symbols.';
      isError = true;
    }
    if (!address) {
      errors.address = 'Please enter address.';
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      const { name, mobileNo, email, address } = user
      setUserDetail({
        name,
        mobileNo,
        email,
        address
      })
    }
  }, [user]);

  const onLabelChange = evt => {
    setUserDetail({ ...userDetail, [evt.target.name]: evt.target.value });
  };

  const onSubmitClick = () => {
    let validateData = validate();
    if (!validateData.isError) {
      onUserSave();
    }
  };

  const onUserSave = () => {
    const userDetail = {
      name,
      mobileNo,
      email,
      password,
      address
    };
    if (user) {
      dispatch(
        updateUser(user._id, { ...user, ...userDetail }, () => {
          onCloseDialog();
          setDataUpdated((data) => !data)

        }),
      );
    } else {
      dispatch(createUser(userDetail)).then(res=>{
        if(res?.status===200){
          onCloseDialog();
          setDataUpdated((data) => !data)
        }
      })
      // dispatch(
      //   createUser(userDetail, () => {
      //     onCloseDialog();
      //     setDataUpdated((data) => !data)

      //   }),
      // );
    }
  };


  const { name, mobileNo, password, email, address } = userDetail
  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>{user ? 'Edit User Details' : 'Create New User'}</DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          {/* <Box {...getRootProps()} mr={{ xs: 0, md: 5 }} mb={{ xs: 3, md: 0 }} className="pointer">
            <input {...getInputProps()} />
            <CmtAvatar size={70} src={profile_pic} />
          </Box> */}
          <GridContainer>
            <Grid item xs={12} sm={6}>
              <AppTextInput
                fullWidth
                name='name'
                variant="outlined"
                label="Name"
                value={name}
                onChange={onLabelChange}
                helperText={errorText?.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AppTextInput
                fullWidth
                name='mobileNo'
                variant="outlined"
                label="Mobile No."
                value={mobileNo}
                onChange={onLabelChange}
                helperText={errorText?.mobileNo}
              />
            </Grid>
          </GridContainer>
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            name='email'
            variant="outlined"
            label="Email Address"
            value={email}
            onChange={onLabelChange}
            helperText={errorText?.email}
          />
        </Box>
        {!user &&
          <Box mb={{ xs: 6, md: 5 }}>
            <AppTextInput
              type="password"
              eyeBtn={true}
              name='password'
              fullWidth
              variant="outlined"
              label="Password"
              value={password}
              onChange={onLabelChange}
              helperText={errorText?.password}
            />
          </Box>
        }
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            multiline
            rows={3}
            name='address'
            // size="large"
            variant="outlined"
            label="Address"
            value={address}
            onChange={onLabelChange}
            helperText={errorText?.address}
          />
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
        </Box>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog}>Cancel</Button>
          <Box ml={2}>
            <Button variant="contained" color="primary" onClick={onSubmitClick}>
              {user ? "Update" : "Save"}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog >
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
