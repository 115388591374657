import { SEND_FORGET_PASSWORD_EMAIL, SESSION_EXPIRED, UPDATE_AUTH_USER, UPDATE_FAILED_USER, UPDATE_LOAD_USER, USER_LOGIN_SUCCESS, USER_LOGOUT_SUCCESS, VERIFIED_OTP } from '@jumbo/constants/ActionTypes';

export const setAuthUser = user => {
  let authUser
  if (user && !user.token) {
    authUser = { token: "", user: user }
  }
  else {
    authUser = { token: "", user: user }
  }
  return dispatch => {
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: authUser ? authUser : user,
      // success: true,
      error: false,
      message: "User Logged in successfully!"
    });
  };
};
export const userLoginSuccess = () => {
  return dispatch => {
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: null,
      success: true,
      message: "User Logged in successfully!"
    });
  };
};
export const userLogoutSuccess = () => {
  return dispatch => {
    dispatch({
      type: USER_LOGOUT_SUCCESS,
      action: USER_LOGOUT_SUCCESS,
      payload: null,
      success: true,
      message: "User Logged out successfully!"
    });
  };
};
export const userSessionExpired = () => {
  return dispatch => {
    dispatch({
      type: SESSION_EXPIRED,
      action: SESSION_EXPIRED,
      payload: null,
      success: true,
      message: "Session expired ! please login again to continue"
    });
  };
};

export const updateLoadUser = loading => {
  return dispatch => {
    dispatch({
      type: UPDATE_LOAD_USER,
      loading: true,
      payload: loading,
    });
  };
};
export const updateFailedUser = error => {
  return dispatch => {
    dispatch({
      type: UPDATE_FAILED_USER,
      payload: null,
      success: false,
      // error: true,
      // message: error
    });
  };
};

export const setForgetPassMailSent = status => {
  return dispatch => {
    dispatch({
      type: SEND_FORGET_PASSWORD_EMAIL,
      payload: status,
      // success: true,
      error: false,
      message: "Mail sent to user successfully"
    });
  };
};
export const setOtpVerified = status => {
  return dispatch => {
    dispatch({
      type: VERIFIED_OTP,
      payload: status,
      success: true,
      error: false,
      message: "OTP verified successfully!"
    });
  };
};
// export const setRefreshToken = token => {
//   return dispatch => {
//     dispatch({
//       type: TOKEN_REFRESHED,
//       payload: token,
//       success: true,
//       error: false,
//       message: "Token refreshed successfully !"
//     });
//   };
// };
