import Axios from "axios";
import { ErrorCatch } from "utils/ErrorCatch";

import { RECEIVE_INBOX_EMAIL_LIST_FAILED, RECEIVE_INBOX_EMAIL_LIST_SUCCESS, SENT_APPROVE_EMAIL_LIST_FAILED, SENT_APPROVE_EMAIL_LIST_SUCCESS, SUPPLER_REGISTRATION_EMAIL_LIST_FAILED, SUPPLER_REGISTRATION_EMAIL_LIST_SUCCESS, TOP_10_SUPPLIER_EMAIL_LIST_FAILED, TOP_10_SUPPLIER_EMAIL_LIST_SUCCESS } from "./Constant";


export const sentApprovEmailCount = params => {
    return dispatch => {
        return Axios.get('api/v1/admin/dashboard/list-of-send-receive-email', { params })
            .then(response => {
                if (response?.status === 200) {
                    const { approveEmailCount, sentEmailCount, receiverCount } = response?.data?.data;
                    dispatch({
                        type: SENT_APPROVE_EMAIL_LIST_SUCCESS,
                        data: { approveEmailCount, sentEmailCount, receiverCount },
                    });
                    return Promise.resolve(response);
                }
            })
            .catch(err => {
                ErrorCatch(err, SENT_APPROVE_EMAIL_LIST_FAILED, dispatch);
                return Promise.resolve(err);
            });
    };
};

export const receiveInboxEmailCount = params => {
    return dispatch => {
        return Axios.get('api/v1/admin/dashboard/list-of-send-receive-email', { params })
            .then(response => {
                if (response?.status === 200) {
                    const { inboxEmailCount } = response?.data?.data;
                    dispatch({
                        type: RECEIVE_INBOX_EMAIL_LIST_SUCCESS,
                        data: inboxEmailCount,
                    });
                    return Promise.resolve(response);
                }
            })
            .catch(err => {
                ErrorCatch(err, RECEIVE_INBOX_EMAIL_LIST_FAILED, dispatch);
                return Promise.resolve(err);
            });
    };
};



export const topSupplierEmailId = params => {
    return dispatch => {
        return Axios.get('api/v1/admin/dashboard/list-top-10-supplier', { params })
            .then(response => {
                if (response?.status === 200) {
                    const { listOfTop10Supplier } = response?.data?.data;
                    dispatch({
                        type: TOP_10_SUPPLIER_EMAIL_LIST_SUCCESS,
                        data: listOfTop10Supplier,

                    });
                    return Promise.resolve(response);
                }
            })
            .catch(err => {
                ErrorCatch(err, TOP_10_SUPPLIER_EMAIL_LIST_FAILED, dispatch);
                return Promise.resolve(err);
            });
    };
};


export const supplierRegistrationEmailId = params => {
    return dispatch => {
        return Axios.get('api/v1/admin/dashboard/list-supplier-registration', { params })
            .then(response => {
                if (response?.status === 200) {
                    const { supplierList, total, perPage } = response?.data?.data;
                    dispatch({
                        type: SUPPLER_REGISTRATION_EMAIL_LIST_SUCCESS,
                        data: supplierList,
                        total: total,

                        perPage: perPage,
                    });
                    return Promise.resolve(response);
                }
            })
            .catch(err => {
                ErrorCatch(err, SUPPLER_REGISTRATION_EMAIL_LIST_FAILED, dispatch);
                return Promise.resolve(err);
            });
    };
};

