import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Divider, IconButton, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import Pagination from '@mui/material/Pagination';
const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
    },
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,

    },
  },
  titleRoot: {
    fontSize: 16,
    marginLeft: 12,
    fontWeight: theme.typography.fontWeightBold,
  },
  tagListRoot: {
    color: theme.palette.text.disabled,
    padding: '0 3px 0 0',
    letterSpacing: 0.4,
    fontSize: 12,
    width: 'auto',
  },
  blockRoot: {
    display: 'block',
    color: theme.palette.text.disabled,
  },
  descriptionBlock: {
    '& p': {
      marginBottom: 16,
      fontSize: 14,
      color: theme.palette.text.secondary,
    },
  },
  linkBtn: {
    cursor: 'pointer',
    textTransform: 'uppercase',
  },
  imageRoot: {
    width: '100%',
    height: 250,
  },
  badge: {
    position: 'absolute',
    bottom: 15,
    left: 20,
    zIndex: 1,
    fontSize: 12,
    padding: '4px 16px',
    letterSpacing: 0.4,
    borderRadius: 16,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  marginClass: {
    marginX: 5,
  },
}));

const ViewMaterialList = ({ open, onCloseDialog }) => {
  const classes = useStyles();

  //------------all use selectors
  const {  productElm:product } = useSelector(state => state.SetProductDetail);

  //-------------all use states
  const [productData, setProductData] = useState({});
  const [materialList, setMaterialList] = useState([]);
  const [keywords, setkeywords] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [dataLength, setDataLength] = useState(0);

  //------all useEffects
  useEffect(() => {
    if (product) {
      setProductData(product);
      //   setMaterialList(product.materials);

      let count = product.materials.length;
      setDataLength(count);
      setTotalPage(Math.ceil(count / 25));
      if (page < 2) {
        setMaterialList(product.materials.slice(0,25));
      } else {
        // setMaterialList(product.materials.slice(page + 8, 10 * page));
        setMaterialList(
          product.materials.slice((page - 1) * 25 , page * 25),
        );
      }
    }
  }, [page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const getSubTitle = () => (
    <Box
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      color="text.disabled"
      fontSize={12}
      mb={3}
    >
      <Box component="span" mr={2} color="primary.main">
        {productData.name}
      </Box>
      <Divider orientation="vertical" flexItem />
      <Divider orientation="vertical" flexItem />
      <Box ml={2}>
        <Box component="span" color="text.primary" mr={2}></Box>
        <Box component="span">Description</Box>
      </Box>
    </Box>
  );
  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box mt={-2} mb={-2} display="flex" justifyContent="space-between">
        <Box ml={-2}>
          <DialogTitle className={classes.dialogTitleRoot}>
            {productData && `${productData.name + ' Material List'}`}
          </DialogTitle>
        </Box>
        <Box ml={2}>
          <IconButton onClick={onCloseDialog}>
            <ClearIcon />
          </IconButton>
        </Box>
      </Box>
      <DialogContent dividers>
        {materialList && materialList.length > 0 ? (
          materialList.map((mat, key) => {
            return (
              <Box display="flex" justifyContent="space-between">
                <Typography className="mb-2">
                  {page === 1
                    ? `${key + 1}. `
                    : `${(page - 1) * 25 + 1 + key}. `}
                  {/* {page === 1 ? `${key + 1}. ` : `${page + 9 + key}. `} */}
                  {mat.displayName}
                </Typography>
              </Box>
            );
          })
        ) : (
          <Typography>No Materials</Typography>
        )}
        {dataLength > 0 && (
          <Pagination
              count={totalPage}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ViewMaterialList;

ViewMaterialList.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
