import React, { useContext, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import MailLabels from '../MailsList/MailLabels';
import CmtObjectSummary from '@coremat/CmtObjectSummary';
import CmtAvatar from '@coremat/CmtAvatar';
import { getDateInDesiredFormat, isToday } from '@jumbo/utils/dateHelper';
import useStyles from '../index.style';
import Typography from '@material-ui/core/Typography';
import ReplyMailForm from './ReplyMailForm';
import DetailHeader from './DetailHeader';
import MailReply from './MailReply';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getMailContainerHeight } from '@jumbo/constants/AppConstants';
import { withWidth } from '@material-ui/core';
import ReplyIcon from '@material-ui/icons/Reply';
import Chip from '@material-ui/core/Chip';
import PhotoIcon from '@material-ui/icons/Photo';
import GetAppIcon from '@material-ui/icons/GetApp';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import { linkify } from '@jumbo/utils/commonHelper';
import { Done, Forward, Reply, Schedule } from '@material-ui/icons';
import CmtCardMedia from '@coremat/CmtCard/CmtCardMedia';
import config from 'utils/Config.util';
import { Link } from 'react-router-dom';
// const useStyles = makeStyles(theme => ({

// }));
const MailDetail = ({ width, onClickForwardMail }) => {
  const { showFooter } = useContext(AppContext);
  const dispatch = useDispatch();
  const { selectedMail, labelsList, filterType } = useSelector(
    ({ Emails }) => Emails,
  );
  const classes = useStyles({
    height: getMailContainerHeight(width, showFooter),
    imageRoot: {
      width: 2,
      height: 2,
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
  });

  const getSenderInfo = () => (
    <Box component="span" display="flex" alignItems="center">
      <Box component="span" fontSize={16}>
        {selectedMail.senderDetails?.name}
      </Box>
      <Box
        component="span"
        fontSize={12}
        ml={2}
        color="text.secondary"
      >{`<${selectedMail.senderEmail}>`}</Box>
    </Box>
  );

  // const onClickReplyMail = mail => {
  //   dispatch(onReplyToMail(selectedMail._id, mail));
  // };

  const getMailDate = date => {
    return isToday(date)
      ? 'Today'
      : emailStatus === 'scheduled'
        ? getDateInDesiredFormat(date, 'DD/MM/YYYY')
        : getDateInDesiredFormat(date, 'MMM DD');
  };

  const downloadAttachment = item => {
    // window.location.href(encodeURI(config.REACT_APP_API_BASE_URL + '/' + item.filename))
  };

  const onShowAttachments = attachments => {
    return (
      <Box className={classes.attachRoot}>
        {attachments.map((item, index) => {
          let filePath = process.env.NODE_ENV === 'production' ? item?.path.split('/') : item?.path.split('\\')
          return (
            <>
              {/* <Link to={encodeURI(config.REACT_APP_API_BASE_URL + '/' + item.filename)} className="btn btn-primary"> */}
              <a
                href={encodeURI(
                  config.REACT_APP_API_BASE_URL + '/' + filePath[1],
                )}
                target="_blank"
                rel="noreferrer"
              >
                <Chip
                  label={filePath[1]}
                  key={index}
                  icon={<PhotoIcon />}
                  deleteIcon={<GetAppIcon />}
                  onDelete={() => downloadAttachment(item)}
                />
              </a>
              {/* </Link> */}
              {/* <Box  > */}
              {/* <CmtCardMedia className={classes.imageRoot} image={encodeURI(config.REACT_APP_API_BASE_URL + '/' + item.filename)} /> */}
              {/* </Box> */}
            </>
          )
        })}
      </Box>
    );
  };

  const {
    senderEmail,
    otherReceivers,
    receiverEmail,
    replyThread,
    senderDetails,
    emailContent,
    read,
    emailType,
    date,
    companyName,
    emailStatus,
  } = selectedMail;

  // const { subject, labels, from, to, date, message, replyThread, attachments } = selectedMail;
  const labels = [emailType];
  return (
    <Box className={classes.inBuildAppMainContent}>
      <DetailHeader
        classes={classes}
        selectedMail={selectedMail}
        labelsList={labelsList}
      />

      <PerfectScrollbar className={classes.perfectScrollbarMailCon}>
        <Box flex={1} display="flex" flexDirection="column">
          <Box className={classes.detailHeader}>
            <Box className={classes.detailHeaderSub}>
              <Typography
                component="div"
                variant="h1"
                className={classes.subjectTitle}
              >
                {emailContent.subject}
              </Typography>
              <Box ml={{ xs: -1, sm: 'auto' }}>
                {labels.length > 0 && labelsList.length > 0 && (
                  <MailLabels mailLabels={labels} labelsList={labelsList} />
                )}
              </Box>
            </Box>
            <CmtObjectSummary
              avatar={
                <CmtAvatar
                  size={40}
                  src={senderDetails?.profile_pic}
                  alt={senderDetails?.name}
                />
              }
              title={getSenderInfo()}
              subTitle={
                otherReceivers.length
                  ? '1-' +
                  receiverEmail +
                  ' ' +
                  (otherReceivers &&
                    otherReceivers?.map(
                      (e, index) => index + 2 + '-' + e + ' ',
                    ))
                  : '' +
                  receiverEmail +
                  ' ' +
                  (otherReceivers &&
                    otherReceivers?.map(
                      (e, index) => index + 2 + '-' + e + ' ',
                    ))
              }
              showItemBadge={false}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              align={'horizontal'}
            />
          </Box>

          <Box className={classes.messageDetail}>
            <Box className={classes.messageItem}>
              <Box className={classes.messageItemInner}>
                {emailStatus === 'scheduled' ? (
                  <Box className={classes.scheduleDateRoot}>
                    <span>Received in Inbox - {getMailDate(date)}</span>
                    <br />
                    <span>
                      Scheduled Time & Date -{' '}
                      {getDateInDesiredFormat(
                        selectedMail?.scheduleOn,
                        'DD/MM/YYYY kk:mm',
                      )}
                    </span>
                  </Box>
                ) : (
                  <Box className={classes.dateRoot}>{getMailDate(date)}</Box>
                )}
                {/* <Box className={classes.dateRoot}>{getMailDate(date)}</Box> */}
                {filterType.selectedFolder === 'inbox' ||
                  filterType.selectedFolder === 're-schedule' ||
                  filterType.selectedFolder === 'approve' ||
                  filterType.selectedFolder === 'sent' ? (
                  <Box
                    className={
                      filterType.selectedFolder === 're-schedule' || filterType.selectedFolder === 'approve'
                        ? classes?.scheduleReplyRoot
                        : classes.replyRoot
                    }
                  >
                    <IconButton
                      onClick={() => {
                        if (filterType.selectedFolder === 'sent' || filterType.selectedFolder === 'approve') {
                          localStorage.setItem('forward', true);
                        }
                        onClickForwardMail(
                          emailContent.html,
                          selectedMail?._id,
                        );
                      }}
                    >
                      {filterType.selectedFolder === 're-schedule' ? (
                        <Schedule />
                      ) : filterType.selectedFolder === 'sent' || filterType.selectedFolder === 'approve' ? (
                        <Reply />
                      ) : (
                        <Done />
                      )}
                    </IconButton>
                  </Box>
                ) : null}
                {emailType === 'enquiry' &&
                  selectedMail?.status !== 'approve' &&
                  selectedMail?.emailStatus !== 'cancelScheduled' && (
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                      <span>
                        <strong>Name : </strong>
                        {senderDetails?.name}
                      </span>
                      <span>
                        <strong>Contact No. : </strong>
                        {senderDetails?.mobileNo}
                      </span>
                      <span>
                        <strong>Country : </strong>
                        {senderDetails?.country}
                      </span>
                      <span>
                        <strong>Company : </strong>
                        {companyName}
                      </span>
                      <span className="mt-4">
                        <strong>Requirement : </strong>
                      </span>

                    </Box>
                  )}

                {/* <Box
                  // mt={4}
                  mb={
                    emailContent.attachments &&
                    emailContent.attachments?.length > 0
                      ? 8
                      : 0
                  }
                  component="p"
                  dangerouslySetInnerHTML={{
                    __html: linkify(
                      emailContent.html.replace(/(?:\r\n|\r|\n)/g, '<br />'),
                    ),
                  }}
                /> */}
                <Box
                  // mt={4}
                  mb={
                    emailContent.attachments &&
                      emailContent.attachments?.length > 0
                      ? 8
                      : 0
                  }
                  component="p"
                  dangerouslySetInnerHTML={{
                    __html: linkify(
                      emailContent?.raw?.text
                        ? emailContent?.raw?.text.replace(
                          /(?:\r\n|\r|\n)/g,
                          '<br />',
                        )
                        : emailContent.html.replace(
                          /(?:\r\n|\r|\n)/g,
                          '<br />',
                        ),
                    ),
                  }}
                />
                {emailContent.attachments &&
                  emailContent.attachments.length > 0 &&
                  onShowAttachments(emailContent.attachments)}
              </Box>
            </Box>
            {/* 
            {replyThread.map((reply, index) => (
              <MailReply
                key={index}
                classes={classes}
                reply={reply}
                getMailDate={getMailDate}
                onShowAttachments={onShowAttachments}
                onClickForwardMail={onClickForwardMail}
              />
            ))} */}
          </Box>

          {/* <ReplyMailForm onClickReplyMail={onClickReplyMail} /> */}
        </Box>
      </PerfectScrollbar>
    </Box>
  );
};

export default withWidth()(MailDetail);

MailDetail.prototype = {
  onClickForwardMail: PropTypes.func,
};
