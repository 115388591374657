import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CmtCard from '@coremat/CmtCard';
import CmtCardMedia from '@coremat/CmtCard/CmtCardMedia';
import { Chip, Divider, IconButton, Tooltip } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import About from 'components/About';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
    '& .MuiDialog-paperWidthLg': {
      width: '100%',
    },
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,

      color: theme.palette.common.dark,
    },
  },
  titleRoot: {
    fontSize: 16,
    marginLeft: 12,
    fontWeight: theme.typography.fontWeightBold,
  },
  tagListRoot: {
    color: theme.palette.text.disabled,
    padding: '0 3px 0 0',
    letterSpacing: 0.4,
    fontSize: 12,
    width: 'auto',
  },
  blockRoot: {
    display: 'block',
    color: theme.palette.text.disabled,
  },
  descriptionBlock: {
    '& p': {
      marginBottom: 16,
      fontSize: 14,
      color: theme.palette.text.secondary,
    },
  },
  linkBtn: {
    cursor: 'pointer',
    textTransform: 'uppercase',
  },
  imageRoot: {
    width: '100%',
    height: "150px",
  },
  badge: {
    position: 'absolute',
    bottom: 15,
    left: 20,
    zIndex: 1,
    fontSize: 12,
    padding: '4px 16px',
    letterSpacing: 0.4,
    borderRadius: 16,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  marginClass: {
    marginX: 5,
  },
}));

const ViewGradeDetails = ({ open, onCloseDialog, showKeyword= true, showImage=true }) => {
  const classes = useStyles();

  //------------all use selectors
  const { gradeElm:grade } = useSelector(state => state.SetGradeDetail);

  //-------------all use states
  const [productData, setproductData] = useState({});
  const [keywords, setkeywords] = useState([]);

  //------all useEffects
  useEffect(() => {
    if (grade) {
      setproductData(grade);
        const data = grade?.keywords.split(',');
        setkeywords(data);
    }
  }, []);
  // console.log(supplier);
  const getSubTitle = () => (
    <Box
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      color="text.disabled"
      fontSize={12}
      mb={3}
    >
      <Box component="span" mr={2} color="primary.main">
        {productData.name}
      </Box>
      <Divider orientation="vertical" flexItem />
      <Divider orientation="vertical" flexItem />
      <Box ml={2}>
        <Box component="span" color="text.primary" mr={2}></Box>
        <Box component="span">Description</Box>
      </Box>
    </Box>
  );
  return (
    <Dialog
      open={open}
      onClose={onCloseDialog}
      className={classes.dialogRoot}
      maxWidth="lg"
    >
      <Box mt={1} display="flex" justifyContent="space-between">
        <Box ml={-2}>
          <DialogTitle className={classes.dialogTitleRoot}>
            {productData && `${productData.name}`}
          </DialogTitle>
        </Box>
        <Box ml={2}>
          <IconButton onClick={onCloseDialog}>
            <ClearIcon />
          </IconButton>
        </Box>
      </Box>
      <DialogContent dividers>
        <CmtCard>
          {showImage && <Box position="relative">
            <CmtCardMedia
              className={classes.imageRoot}
              image={productData.logo}
              title={productData.name}
            />
          </Box>}
          <Box p={6}>
            {getSubTitle()}
            <Box
              className={classes.descriptionBlock}
              dangerouslySetInnerHTML={{ __html: productData.description }}
            />
            {showKeyword &&
            <Box display="flex" alignItems="center">
              <Box mr={2}>
                Keywords :{' '}
                {keywords &&
                  keywords.length &&
                  keywords.map((e, key) => {
                    return (
                      e.length !== 0 && (
                        <Tooltip key={key} title={e} m={3}>
                          <Chip
                            size="small"
                            label={e}
                            clickable
                            color="secondary"
                            variant="outlined"
                          />
                        </Tooltip>
                      )
                    );
                  })}
              </Box>
            </Box>
}
          </Box>
        </CmtCard>
       
      </DialogContent>
    </Dialog>
  );
};

export default ViewGradeDetails;

ViewGradeDetails.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
