import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import ClearIcon from '@material-ui/icons/Clear';

import {
  isValidEmail,
  isValidPassword,
  isValidPhone,
} from '@jumbo/utils/commonHelper';

import WysiwygEditorExample from '../wysiwygEditor';
import { Tooltip } from 'chart.js';
import {
  Chip,
  Divider,
  Paper,
  Select,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { Add, Remove } from '@material-ui/icons';
// import { PRODUCT_CREATE_SUCCESS } from 'redux/actions';
import { Typography } from '@mui/material';
// import Dropzone from 'react-dropzone';
// import { DropzoneArea } from 'material-ui-dropzone';
import { uploadFile } from 'redux/actions/FileUpload/FileUpload';
import {
  clearSupplierDetail,
  createSupplier,
  fetchProductMaterialGradeList,
  updateSupplier,
  addproducttosupplier,
  // editproducttosupplier,
} from 'redux/actions/Manage/Suppliers/Suppliers';
import { Column } from 'react-virtualized';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
// import { setSupplierDetail } from "../../../../../redux/actions/Manage/Suppliers/Suppliers"

import { useParams } from 'react-router';


const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  formControl: {
    minWidth: 100,
    minHeight: 200,
  },
  previewContainer: {
    container: 'true',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  preview: {
    xs: '12',
  },
  errorCk: {
    color: '#f44336',
    marginRight: '14px',
    marginTop: '4px',
    fontSize: '0.75rem',
  },
}));

const EditProductSupplier = ({
  history,
  open,
  onCloseDialog,
  setDataUpdated,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const{id} = useParams()

  //------------all use selectors
  const { user } = useSelector(({ auth }) => auth.authUser);
  const { supplier } = useSelector(state => state.SetSupplierDetail);
  const successAction = useSelector(state => state.Success.action);
  const { products, materials, grades } = useSelector(
    state => state.SetProductMaterialGradeList,
  );
  const { productId, materialId, gId, userId } = useSelector(
    state => state.SetSupplierProdDetail,
  );

  // console.log('productId:', products);
  // console.log('MaterialId:', materials);
  // console.log('gradeId:', grades);
  // console.log('userId:', userId);
  const [product, setProduct] = useState(productId||"");
  const [material, setMaterial] = useState(materialId||'');
  const [grade, setGrade] = useState([]);
  const [gradeId, setGradeId] = useState(gId||"");
  const [productName, setProductName] = useState("");
  const [materialName, setMaterialName] = useState('');
  const [gradeName, setGradeName] = useState("");
  


  const handleProductChange = event => {
    setProduct(event.target.value);
  };

  const handleMaterialChange = event => {
    setMaterial(event.target.value);
    let arr = grades.filter((el, index) => {
      return event.target.value == el._id;
    });
    setGrade(arr[0].grades);
    // console.log("arr",arr[0].grades)
    // console.log(grades)
    // console.log(event.target.value)
  };

  const handleGradeChange = event => {
    setGradeId(event.target.value);
  };

  // console.log('product', product);
  // console.log('material', material);
  console.log('grade', gradeId);
  //-------------all use states
  // const [keywordsArr, setkeywordsArr] = useState([]);
  const [descriptionHTML, setDescriptionHTML] = useState(supplier?.about || '');
  const [productDetail, setproductDetail] = useState({
    product: product,
    material: material,
    grade: grade,
  });
  const [file, setFile] = useState([]);

  const [errorText, setErrorText] = React.useState({});

  
  //-------all handle functions    
  const handleCancel = () => {
    history.push('/manage-suppliers');
    dispatch(clearSupplierDetail());
  };

  // useEffect(() => {
  //   if(material){
  //     console.log("inside useEffect")
  //    console.log( materials?.filter((item)=>{return item._id==material}))
  //   }
  // }, [material]);
  // console.log(grade)

  const onSubmitClick = () => {
    console.log(material, product, gradeId, supplier?.userId);

    // dispatch(
    //   editproducttosupplier(supplier?.userId, material, gradeId, product),
    // );
    history.push('/manage-suppliers');
  };

  const handleFileUpload = files => {
    // console.log(files, "file")
    let formData = new FormData();
    formData.append('fileName', files[0]?.name);
    formData.append('fileCategory', 'logo');
    formData.append('uploadedFile', files[0]);
    dispatch(uploadFile(formData));
    // const payload = {
    //   fileName: files?.name,
    //   fileCategory: 'logo'
    // }
  };

  //------all useEffects
   useEffect(() => {
     dispatch(fetchProductMaterialGradeList());
   }, []);
   useEffect(() => {
     if (productId) {
       let productname = products.filter(
         (el, index) => productId === el.productId,
       );
      //  console.log(productname[0]);
       setProductName(productname[0]);
       let materialname = materials.filter(
         (el, index) => materialId == el.materialId,
       );
      //  console.log(materialname[0]);
       setMaterialName(materialname[0]);
       let gradename = grades && grades.filter((el, index) => materialId == el._id);
      //  console.log(gradename[0].grades);
       let gname = gradename[0].grades.filter(el => gradeId == el.gradeId);
       setGradeName(gname);
     }
   }, [productId, materialId, gId,dispatch]);
  return (
    <PageContainer>
      <div>
        <Paper className={classes.paper}></Paper>
        <Box
          mt={'6rem'}
          display="flex"
          flexDirection={'column'}
          justifyContent="space-between"
          boxShadow={3}
        >
          <Typography margin="1rem">{supplier?.name}</Typography>

          <FormControl variant="outlined" margin="auto">
            <Grid item xs={12} sm={10} style={{ margin: '1rem' }}>
              <AppSelectBox
                fullWidth
                data={products}
                name="product"
                label="Products"
                valueKey="productId"
                variant="outlined"
                labelKey="name"
                // placeholder="test"
                // defaultValue={products.filter((el, index) => productId==el.productId)}
                // defaultValue={productName}
                value={productName.name}
                displayName={products.name}
                onChange={handleProductChange}
                // helperText={errorText?.materialId}
              />
            </Grid>

            <Grid item xs={12} sm={10} style={{ margin: '1rem' }}>
              <AppSelectBox
                fullWidth
                data={materials}
                name="material"
                label="Materials"
                valueKey="materialId"
                variant="outlined"
                labelKey="name"
                value={materials.materialId}
                displayName={materials.name}
                onChange={handleMaterialChange}
                // helperText={errorText?.materialId}
              />
            </Grid>

            <Grid item xs={12} sm={10} style={{ margin: '1rem' }}>
              <AppSelectBox
                fullWidth
                data={grade}
                name="grade"
                label="Grades"
                valueKey="gradeId"
                variant="outlined"
                labelKey="name"
                value={grade.gradeId}
                displayName={grade.name}
                onChange={handleGradeChange}
                // helperText={errorText?.materialId}
              />
            </Grid>
          </FormControl>
          <Box
            display="flex"
            justifyContent="flex-start"
            paddingBottom={3}
            margin="auto"
            // style={{ width: '100%', margin: 'auto' }}
          >
            <Button onClick={() => handleCancel()}>Cancel</Button>
            <Box ml={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={onSubmitClick}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </div>
    </PageContainer>
    //   </DialogContent>
    // </Dialog >
  );
};
export default EditProductSupplier;

EditProductSupplier.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
