import CmtCardMedia from '@coremat/CmtCard/CmtCardMedia';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import { Box, makeStyles, Switch, Button } from '@material-ui/core';
import {
  Add,
  Edit,
  FilterList,
  MoreHoriz,
  Visibility,
  Link
} from '@material-ui/icons';
import CustomTable from 'components/CustomeTable';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_MATERIAL_CREATE_SUCCESS,
  PRODUCT_UPDATE_SUCCESS,
} from 'redux/actions';
import {
  deleteProduct,
  getAllProducts,
  getProductList,
  setProductDetail,
  setProductElmDetail,
  updateProduct,
} from 'redux/actions/Manage/Products/Products';
import config from 'utils/Config.util';
import AddEditProduct from './AddEditProduct';
import AddMaterialToProduct from './AddMaterialToProduct';
import ViewMaterialList from './VIewMaterialLIst';
import ViewProductDetails from './ViewProductDetails';
import AddEditFaqs from './AddEditFnqs';

const useStyles = makeStyles(theme => ({
  imageRoot: {
    width: 100,
    height: 100,
    objectFit: 'cover',
    objectPosition: 'center',
    padding: 0,
  },
}));
const Products = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  //------------all use selectors
  const { user } = useSelector(({ auth }) => auth.authUser);
  const { products, total, perPage } = useSelector(state => state.Products);
  const ProductData = useSelector(state => state.setProductDetail);
  const productDetail = ProductData?.user;

  //-------------all use states
  const [orderBy, setOrderBy] = React.useState('');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  // const [products, setProducts]= useState([])
  // const [total,setTotal] = useState(null)
  // const perPage =10
  // let demo =[]
  //   let demoo
  const [selectedUser, setSelectedUser] = useState({ name: '' });
  const [productsFetched, setproductsFetched] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [row, setRow] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [permissionData] = useState(
    JSON.parse(localStorage.getItem('permission')),
  );
  const [createPermission, setCreatePermission] = useState(false);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [exportPermission, setExportPermission] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openViewProductDetails, setOpenViewProductDetails] = useState(false);
  const [openViewMaterialList, setopenViewMaterialList] = useState(false);
  const [openViewMaterialAdd, setOpenViewMaterialAdd] = useState(false);
  // const [search, setSearch] = useState({ sort: null, field: null });
  const [fileData, setFileData] = useState([]);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [downloadData, setDownloadData] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const successAction = useSelector(state => state.Success.action);
  const [searchFlag, setSearchFlag] = useState(false);
  const [openProductFnqs,setOpenProductFnqs] = useState(false)


  //-------table config actions
  const getUserActions = user => {
    const actions = [];
    actions.push({ action: 'url', label: 'View Live', icon: <Link /> });
    actions.push({ action: 'view', label: 'View', icon: <Visibility /> });
    actions.push({ action: 'edit', label: 'Edit', icon: <Edit /> });
    actions.push({
      action: 'list_material',
      label: 'Material List',
      icon: <FilterList />,
    });
    actions.push({
      action: 'add_material',
      label: 'Add Material',
      icon: <Add />,
    });
    return actions;
  };

  const userActions = getUserActions(row);

  const onUserMenuClick = (menu, elm) => {
    if (menu.action === "url") {
      // window.open(`https://www.thepipingmart.com/products/${elm.url}`);
      window.open( config.REACT_APP_API_BASE_URL === 'https://api.pipingmart.co.in' ? `http://pipingmart.co.in/products/${elm.url}` : `https://www.thepipingmart.com/products/${elm.url}`)
    }
    else if (menu.action === 'view') {
      dispatch(setProductElmDetail(elm));
      setOpenViewProductDetails(true);
    } else if (menu.action === 'add_material') {
      dispatch(setProductElmDetail(elm));
      setOpenViewMaterialAdd(true);
      // onUserChangePassword(row);
    } else if (menu.action === 'edit') {
      setOpenUserDialog(true);
      history.push(`/edit-manage-products/${elm?._id}`);
    } else if (menu.action === 'list_material') {
      dispatch(setProductElmDetail(elm));
      setopenViewMaterialList(true);
    }else if(menu.action === 'add_edit_faqs'){
      dispatch(setProductElmDetail(elm))
      setOpenProductFnqs(true)
    }
    // } else if (menu.action === 'delete') {
    //     setSelectedUser(elm);
    //     setOpenConfirmDialog(true)
    // }
  };
  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'logo',
      numeric: false,
      disablePadding: false,
      label: 'Image',
      sort: false,
      render: elm => (
        <Box>
          <CmtCardMedia className={classes.imageRoot} image={elm.logo} />
        </Box>
      ),
    },
    {
      id: 'title',
      numeric: false,
      disablePadding: false,
      label: 'Title',
      sort: false,
    },
    {
      id: 'showInMiddle',
      numeric: false,
      disablePadding: false,
      sort: false,
      label: 'Show in Middle',
      render: elm => (
        <Switch
          // defaultChecked={elm.showInMiddle === true ? true : false}
          checked={elm.showInMiddle === true ? true : false}
          onChange={() => handleChangeSwitch(elm)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      ),
    },
    {
      id: 'active',
      numeric: false,
      disablePadding: false,
      sort: false,
      label: 'Status',
      render: elm => (
        <Switch
          // defaultChecked={elm.showInMiddle === true ? true : false}
          checked={elm.active === true ? true : false}
          onChange={() => handleChangeStatusSwitch(elm)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      ),
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Actions',
      sort: false,
      render: elm => (
        <>
          {/* {!updatePermission && !deletePermission ? '-' : <CmtDropdownMenu items={userActions} onItemClick={(menu) => onUserMenuClick(menu, elm)} TriggerComponent={<MoreHoriz />} />} */}
          {
            <CmtDropdownMenu
              items={userActions}
              onItemClick={menu => onUserMenuClick(menu, elm)}
              TriggerComponent={<MoreHoriz />}
            />
          }
        </>
      ),
    },
    // {
    //   id: 'url',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Link',
    //   render: elm => (
    //    <Button><a href={`https://www.thepipingmart.com/products/${elm.url}`}>Link</a></Button>
    // )}
  ];
  //download file setting
  var fileHeaders = [
    {
      key: 'firstName',
      label: 'Name',
    },
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'roleName',
      label: 'Role',
    },
    {
      key: 'departmentName',
      label: 'Dept',
    },
    {
      key: 'status',
      label: 'Status',
    },
  ];

  //-------all handle functions
  const handleChangeSwitch = elm => {
    let showInMiddle;
    if (elm.showInMiddle === true) showInMiddle = 'false';
    else showInMiddle = 'true';
    const data = {
      productId: elm.productId,
      showInMiddle: showInMiddle,
      idOfProductCreator: user?.userId,
      nameOfProductCreator: user?.name,
    };
    dispatch(
      updateProduct(data, data => {
        if (data) history.push('/manage-products');
      }),
    );
  };

  const handleChangeStatusSwitch = elm => {
    let status;
    if (elm.active === true) status = 'false';
    else status = 'true';
    const data = {
      productId: elm.productId,
      active: status,
      idOfProductCreator: user?.userId,
      nameOfProductCreator: user?.name,
    };
    dispatch(
      updateProduct(data, data => {
        if (data) history.push('/manage-products');
      }),
    );
  };

  const handleCloseViewMaterialList = () => {
    setopenViewMaterialList(false);
    dispatch(setProductElmDetail(null));
  };

  const handleCloseViewMaterialAdd = () => {
    setOpenViewMaterialAdd(false);
    dispatch(setProductElmDetail(null));
  };
  
  const handleCloseProductFnqs = ()=>{
    setOpenProductFnqs(false)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleViewDialog = user => {
    dispatch(setProductDetail(user));
    setOpenViewDialog(true);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    dispatch(setProductDetail(null));
  };

  // const handleCloseUserDialog = () => {
  //   setOpenUserDialog(false);
  //   dispatch(setProductDetail(null));
  // };

  const handleAddButton = () => {
    setOpenUserDialog(true);
    dispatch(setProductElmDetail(null));
    history.push('/add-manage-products');
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    dispatch(
      deleteProduct(selectedUser._id, data => {
        if (data) setDataUpdated(data => !data);
      }),
    );
  };

  const handleCloseViewProductDetails = () => {
    setOpenViewProductDetails(false);
    dispatch(setProductElmDetail(null));
  };

  useEffect(() => {
    let params;
    params = {
      search: searchTerm,
    };
    dispatch(getAllProducts(params), () => {
      setproductsFetched(true);
    });
  }, [dispatch, dataUpdated]);

  useEffect(() => {
    if (
      successAction === PRODUCT_UPDATE_SUCCESS ||
      successAction === PRODUCT_CREATE_SUCCESS ||
      successAction === PRODUCT_MATERIAL_CREATE_SUCCESS
    ) {
      let params;
      params = {
        search: searchTerm,
      };
      dispatch(getAllProducts(params), () => {
        setproductsFetched(true);
      });
    }
  }, [successAction]);

  return (
    <>
      <CustomTable
        TableName="products"
        downloadTableBtn={exportPermission ? true : false}
        fileName="products.xlsx"
        fileHeaders={fileHeaders}
        fileData={fileData}
        setIsDownload={setIsDownload}
        downloadData={downloadData}
        row={products && products}
        addButtonText={'Add Product'}
        handleAddButton={handleAddButton}
        headCells={headCells}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        count={total || 0}
        rowsPerPage={perPage}
        onPageChange={handlePageChange}
        page={page}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleSearch={(text) => setSearchTerm(text)}
        handleSearchSubmit={() => {
          setPage(0)
          setDataUpdated(!dataUpdated)
        }}
        handleViewDialog={handleViewDialog}
        productDetailModal={{
          emailData: productDetail?.email,
          modalHeading: productDetail?.name,
          headerArray: [
            'MemberType: ' + productDetail?.membertype,
            productDetail?.address && 'Address: ' + productDetail?.address,
          ],
          sectionHeading: 'Contact Details',
          openViewDialog: openViewDialog,
          handleCloseViewDialog: handleCloseViewDialog,
          activeChip: productDetail?.active,
          phoneData: productDetail?.mobileNo,
        }}
      />
      {/* {openUserDialog && (
        <AddEditProduct
          setDataUpdated={setDataUpdated}
          open={openUserDialog}
          onCloseDialog={handleCloseUserDialog}
        />
      )} */}
      {openViewProductDetails && (
        <ViewProductDetails
          open={openViewProductDetails}
          onCloseDialog={handleCloseViewProductDetails}
        />
      )}
      {openViewMaterialList && (
        <ViewMaterialList
          open={openViewMaterialList}
          onCloseDialog={handleCloseViewMaterialList}
        />
      )}
      {openViewMaterialAdd && (
        <AddMaterialToProduct
          setDataUpdated={setDataUpdated}
          history={history}
          open={openViewMaterialAdd}
          onCloseDialog={handleCloseViewMaterialAdd}
        />
      )}
      {openProductFnqs && (
        <AddEditFaqs
        open={openProductFnqs}
        onCloseDialog={handleCloseProductFnqs}
        />
      )}
      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete`}
        content={'Are you sure, you want to  delete this user?'}
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};

export default Products;
