import { MSG_ERR_RESPONDING_SERVER } from "redux/actions/Common/Constant"
import { setError } from "redux/actions/Common/ErrorAction"
import log from "./Logger.util"
let RES_MSG = MSG_ERR_RESPONDING_SERVER

export const ErrorCatch = (error, actionType, dispatch) => {
    if (error && error.data) {
        log.error(error.data)
        if (error.data.responseMsg)
            RES_MSG = error.data.responseMsg
    } else
        log.error(error)
    dispatch(setError(RES_MSG, actionType))
    dispatch({
        type: actionType,
        data: null,
        message:
            RES_MSG
    });
}
