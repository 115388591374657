import {
  SUBPRODUCT_ADD_FAILED,
  SUBPRODUCT_ADD_REQUEST,
  SUBPRODUCT_ADD_SUCCESS,
  SUBPRODUCT_LIST_REQUEST,
  SUBPRODUCT_LIST_SUCCESS,
  SUBPRODUCT_LIST_FAILED,
  SUBPRODUCT_UPDATE_SUCCESS,
  SUBPRODUCT_UPDATE_REQUEST,
  SUBPRODUCT_UPDATE_FAILED,
  SUBPRODUCT_DELETE_SUCCESS,
  SUBPRODUCT_CREATE_SUCCESS,
  DELETE_BULK_SUBPRODUCT_SUCCESS,
  SET_SUBPRODUCT_DETAILS,
  SET_SUBPRODUCTELM_DETAILS
} from "../../actions/Manage/SubProduct/Constant";
const INIT_STATE = {
  products: [],
  subproduct: null,
  subproducts: [],
  title: "",
  meta: "",
  description: null,
  total: 0,
  perPage: 0
};
const INIT_STATE_DETAIL =
{
  subProductElm:null,
  subproduct: null,
}

export const SubProductReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SUBPRODUCT_CREATE_SUCCESS: {
      return {
        ...state,
        subproduct: action.data,
        action: action.type,
        message: action.message,
        subproducts: [action.data, ...state.subproducts]
      };
    }
    case SUBPRODUCT_LIST_SUCCESS: {
      return {
        ...state,
        subproducts: action.data,
        total: action.total,
        // title: action.payload.name,
        action: action.type,
        perPage: action.perPage,

        // subproducts: [ payload, ...state.subproducts ]
      };
    }
    //   case PRODUCT_LIST_SUCCESS: {
    //     return {
    //         ...state,
    //         products: action.data,
    //         total: action.total,
    //         action: action.type,
    //         message: action.message,
    //         perPage: action.perPage,
    //     };
    // }
    //products: state.products.map(product => (product.id === action.payload.id ? action.payload : product))
    case SUBPRODUCT_UPDATE_SUCCESS: {
      return {
        ...state,
        product: action.data,
        action: action.type,
        message: action.message,
        products: state.products.map(product => (product._id === action.data._id ? action.data : product))
      };
    }
    case SUBPRODUCT_DELETE_SUCCESS: {
      return {
        ...state,
        product: action.data,
        action: action.type,
        message: action.message,
        products: state.products.filter(product => product._id !== action.data._id),
      };
    }
    case DELETE_BULK_SUBPRODUCT_SUCCESS: {
      return {
        ...state,
        ...state,
        action: action.type,
        message: action.message,
        products: state.products.filter(product => !action.data.includes(product.id)),
      };
    }
    default: {
      return state;
    }
  }
};

export const SetSubProductDetailReducer = (state = INIT_STATE_DETAIL, action) => {
  switch (action.type) {
    case SET_SUBPRODUCT_DETAILS:
      return {
        ...state,
        subproduct: action.data,
        action: action.type,
      };
    case SET_SUBPRODUCTELM_DETAILS:
      return {
        ...state,
        subProductElm:action.data
      }
    default:
      return state;
  }
};
