//-------------PRODUCTMATERIAL constants------------------
export const PRODUCTMATERIAL_LIST_REQUEST = "PRODUCTMATERIAL_LIST_REQUEST";
export const PRODUCTMATERIAL_LIST_SUCCESS = "PRODUCTMATERIAL_LIST_SUCCESS";
export const PRODUCTMATERIAL_LIST_FAILED = "PRODUCTMATERIAL_LIST_FAILED";
export const DELETE_BULK_PRODUCTMATERIAL_REQUEST = "DELETE_BULK_PRODUCTMATERIAL_REQUEST";
export const DELETE_BULK_PRODUCTMATERIAL_SUCCESS = "DELETE_BULK_PRODUCTMATERIAL_SUCCESS";
export const DELETE_BULK_PRODUCTMATERIAL_FAILED = "DELETE_BULK_PRODUCTMATERIAL_FAILED";
export const SET_PRODUCTMATERIAL_DETAILS = "SET_PRODUCTMATERIAL_DETAILS";
export const PRODUCTMATERIAL_CREATE_REQUEST = "PRODUCTMATERIAL_CREATE_REQUEST";
export const PRODUCTMATERIAL_CREATE_SUCCESS = "PRODUCTMATERIAL_CREATE_SUCCESS";
export const PRODUCTMATERIAL_CREATE_FAILED = "PRODUCTMATERIAL_CREATE_FAILED";
export const PRODUCTMATERIAL_MATERIAL_CREATE_REQUEST = "PRODUCTMATERIAL_MATERIAL_CREATE_REQUEST";
export const PRODUCTMATERIAL_MATERIAL_CREATE_SUCCESS = "PRODUCTMATERIAL_MATERIAL_CREATE_SUCCESS";
export const PRODUCTMATERIAL_MATERIAL_CREATE_FAILED = "PRODUCTMATERIAL_MATERIAL_CREATE_FAILED";
export const PRODUCTMATERIAL_UPDATE_REQUEST = "PRODUCTMATERIAL_UPDATE_REQUEST";
export const PRODUCTMATERIAL_UPDATE_SUCCESS = "PRODUCTMATERIAL_UPDATE_SUCCESS";
export const PRODUCTMATERIAL_UPDATE_FAILED = "PRODUCTMATERIAL_UPDATE_FAILED";
export const PRODUCTMATERIAL_PROFILE_UPDATE_REQUEST = "PRODUCTMATERIAL_PROFILE_UPDATE_REQUEST";
export const PRODUCTMATERIAL_PROFILE_UPDATE_SUCCESS = "PRODUCTMATERIAL_PROFILE_UPDATE_SUCCESS";
export const PRODUCTMATERIAL_PROFILE_UPDATE_FAILED = "PRODUCTMATERIAL_PROFILE_UPDATE_FAILED";
export const PRODUCTMATERIAL_PASSWORD_UPDATE_REQUEST = "PRODUCTMATERIAL_PASSWORD_UPDATE_REQUEST";
export const PRODUCTMATERIAL_PASSWORD_UPDATE_SUCCESS = "PRODUCTMATERIAL_PASSWORD_UPDATE_SUCCESS";
export const PRODUCTMATERIAL_PASSWORD_UPDATE_FAILED = "PRODUCTMATERIAL_PASSWORD_UPDATE_FAILED";
export const PRODUCTMATERIAL_DELETE_REQUEST = "PRODUCTMATERIAL_DELETE_REQUEST";
export const PRODUCTMATERIAL_DELETE_SUCCESS = "PRODUCTMATERIAL_DELETE_SUCCESS";
export const PRODUCTMATERIAL_DELETE_FAILED = "PRODUCTMATERIAL_DELETE_FAILED";
export const SET_PRODUCTMATERIALELM_DETAILS = "SET_PRODUCTMATERIALELM_DETAILS";