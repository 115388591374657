import { RFQ_ADD_FAILED, RFQ_ADD_SUCCESS, RFQ_LIST_REQUEST } from "redux/actions/Manage/Rfq/constant"

const INIT = {
    loading: false,
    totalPage: 0,
    rfqList: [],
    error: false,
    errorMessage: null
}

export const RfqReducer = (state = INIT, action) => {
    switch (action.type) {
        case RFQ_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case RFQ_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                rfqList: action.payload.list,
                totalPage: action.payload.totalPage
            }
        case RFQ_ADD_FAILED:
            return {
                loading: false,
                totalPage: 0,
                rfqList: [],
                error: true,
                errorMessage: action.payload.error
            }
        default:
            return state;
    }
}