// --------------- Material Grade ----------------------

export const MATERIAL_GRADE_LIST_REQUEST = "MATERIAL_GRADE_LIST_REQUEST";
export const MATERIAL_GRADE_LIST_SUCCESS = "MATERIAL_GRADE_LIST_SUCCESS";
export const MATERIAL_GRADE_LIST_FAILED = "MATERIAL_GRADE_LIST_FAILED";

export const MATERIAL_GRADE_CREATE_REQUEST = "MATERIAL_GRADE_CREATE_REQUEST";
export const MATERIAL_GRADE_CREATE_SUCCESS = "MATERIAL_GRADE_CREATE_SUCCESS";
export const MATERIAL_GRADE_CREATE_FAILED = "MATERIAL_GRADE_CREATE_FAILED";

export const MATERIAL_GRADE_UPDATE_REQUEST = "MATERIAL_GRADE_UPDATE_REQUEST";
export const MATERIAL_GRADE_UPDATE_SUCCESS = "MATERIAL_GRADE_UPDATE_SUCCESS";
export const MATERIAL_GRADE_UPDATE_FAILED = "MATERIAL_GRADE_UPDATE_FAILED";

export const MATERIAL_GRADE_STATUS_UPDATE_REQUEST = "MATERIAL_GRADE_STATUS_UPDATE_REQUEST";
export const MATERIAL_GRADE_STATUS_UPDATE_SUCCESS = "MATERIAL_GRADE_STATUS_UPDATE_SUCCESS";
export const MATERIAL_GRADE_STATUS_UPDATE_FAILED = "MATERIAL_GRADE_STATUS_UPDATE_FAILED";

export const MATERIAL_GRADE_LIST_BY_ID_REQUEST = "MATERIAL_GRADE_LIST_BY_ID_REQUEST";
export const MATERIAL_GRADE_LIST_BY_ID_SUCCESS = "MATERIAL_GRADE_LIST_BY_ID_SUCCESS";
export const MATERIAL_GRADE_LIST_BY_ID_FAILED = "MATERIAL_GRADE_LIST_BY_ID_FAILED";

export const MATERIAL_GRADE_UPDATE_STATUS_REQUEST = "MATERIAL_GRADE_UPDATE_STATUS_REQUEST";
export const MATERIAL_GRADE_UPDATE_STATUS_SUCCESS = "MATERIAL_GRADE_UPDATE_STATUS_SUCCESS";
export const MATERIAL_GRADE_UPDATE_STATUS_FAILED = "MATERIAL_GRADE_UPDATE_STATUS_FAILED";

export const MATERIAL_LIST_SUCCESS = "MATERIAL_LIST_SUCCESS";
export const MATERIAL_LIST_REQUEST = "MATERIAL_LIST_REQUEST";
export const MATERIAL_LIST_FAILED = "MATERIAL_LIST_FAILED";