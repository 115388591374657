import CmtCardMedia from '@coremat/CmtCard/CmtCardMedia';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import { Box, makeStyles, Switch,Button } from '@material-ui/core';
import { Add, Edit, FilterList, MoreHoriz, Visibility,Link } from '@material-ui/icons';
import CustomTable from 'components/CustomeTable';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import config from 'utils/Config.util'; 
import { PRODUCTMATERIAL_CREATE_SUCCESS, PRODUCTMATERIAL_MATERIAL_CREATE_SUCCESS, PRODUCTMATERIAL_UPDATE_SUCCESS } from 'redux/actions/Manage/ProductMaterial/Constant';
import { getAllProductMaterials, setProductMaterialDetail, setProductMaterialDetailElm, updateProductMaterial } from 'redux/actions/Manage/ProductMaterial/ProductMaterial';
import { deleteProduct, getAllProducts, setProductDetail, updateProduct } from 'redux/actions/Manage/Products/Products';
import ViewProductMaterialDetails from './ViewProductMaterialDetails';
import AddEditProductMaterial from './AddEditProductMaterial';
// import AddEditProduct from './AddEditProduct';
// import AddMaterialToProduct from './AddMaterialToProduct';
// import ViewMaterialList from './VIewMaterialLIst';
// import ViewProductDetails from './ViewProductDetails';
const useStyles = makeStyles(theme => ({
    imageRoot: {
        width: '100px',
        height: '100px',
        backgroundSize:"cover"
    },
}));
const ProductMaterial = ({ history }) => {
    // var productMaterialTotal;
    const classes = useStyles()
    const dispatch = useDispatch()

    //------------all use selectors
    const { user } = useSelector(({ auth }) => auth.authUser);
    const { productmaterials, total, perPage } = useSelector((state) => state.ProductMaterials);
    const ProductData = useSelector((state) => state.SetProductMaterial);
    const productDetail = ProductData?.user

    // console.log(products)

    //-------------all use states
    // const [productMaterial,setProductMaterial]= React.useState([])
    // const [productMaterialTotal,setproductMaterialTotal]= React.useState(0)
    const [orderBy, setOrderBy] = React.useState('name');
    const [order, setOrder] = React.useState('asc');
    const [page, setPage] = React.useState(0);
    const [searchTerm, setSearchTerm] = useState('')
    const [selectedUser, setSelectedUser] = useState({ name: '' });
    const [productsFetched, setproductsFetched] = useState(false);
    const [openUserDialog, setOpenUserDialog] = useState(false);
    const [row, setRow] = useState([]);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [permissionData] = useState(JSON.parse(localStorage.getItem('permission')));
    const [createPermission, setCreatePermission] = useState(false);
    const [updatePermission, setUpdatePermission] = useState(false);
    const [deletePermission, setDeletePermission] = useState(false);
    const [exportPermission, setExportPermission] = useState(false);
    const [openViewDialog, setOpenViewDialog] = useState(false);
    const [openViewProductDetails, setOpenViewProductDetails] = useState(false);
    const [openViewMaterialList, setopenViewMaterialList] = useState(false);
    const [openViewMaterialAdd, setOpenViewMaterialAdd] = useState(false);
    // const [search, setSearch] = useState({ sort: null, field: null });
    const [fileData, setFileData] = useState([]);
    const [dataUpdated, setDataUpdated] = useState(false);
    const [downloadData, setDownloadData] = useState(false);
    const [isDownload, setIsDownload] = useState(false)
    const successAction = useSelector(state => state.Success.action)

    // const [sort, setSort] = useState({
    //     orderBy: '',
    //     order: 'asc',
    // });

    // const getProductMaterialData=(data)=>{
    //     let arr=[]
    //         for(let i=0;i<data.length;i++){

    //             for(let j=0;j<data[i].materials.length;j++){
    //                 arr.push(
    //                 {
    //                     displayName:data[i].materials[j].displayName,
    //                     name:data[i].materials[j].name,
    //                     materialId:data[i].materials[j].materialId,
    //                     active:data[i].active
    //                 })
    //             }
    //         }
    //         // setProductMaterial(arr)
    //         // productMaterialTotal=arr.length;
    //         // setproductMaterialTotal(arr.length)
    //         // console.log(productMaterialTotal)
    // }
    // console.log(productMaterial)
    //-------table config actions
    const getUserActions = user => {
        const actions = [

        ];
        actions.push({ action: 'url', label: 'View Live', icon: <Link /> });
        actions.push({ action: 'view', label: 'View', icon: <Visibility /> })
        actions.push({ action: 'edit', label: 'Edit', icon: <Edit /> })
        // actions.push({ action: 'list_material', label: 'Material List', icon: <FilterList /> })
        // actions.push({ action: 'add_material', label: 'Add Material', icon: <Add /> })
        return actions;
    };
    const userActions = getUserActions(row);
    const onUserMenuClick = (menu, elm) => {
        if (menu.action==="url") {
            // window.open(`https://www.thepipingmart.com/material/${elm.url}`);
            window.open( config.REACT_APP_API_BASE_URL === 'https://api.pipingmart.co.in' ? `http://pipingmart.co.in/product/${elm.url}}` : `https://www.thepipingmart.com/product/${elm.url}`)
          }
        else if(menu.action === 'view') {
            dispatch(setProductMaterialDetailElm(elm));
            setOpenViewProductDetails(true)
        } else if (menu.action === 'edit') {
            // dispatch(setProductMaterialDetail(elm));
            setOpenUserDialog(true)
            history.push(`/manage-edit-productMaterial/${elm?._id}`)
        }
        // } else if (menu.action === 'add_material') {
        //     dispatch(setProductMaterialDetail(elm));
        //     setOpenViewMaterialAdd(true)
        //     // onUserChangePassword(row);
        // } else if (menu.action === 'list_material') {
        //     dispatch(setProductMaterialDetailElm(elm));
        //     setopenViewMaterialList(true)
        // }
        // } else if (menu.action === 'delete') {
        //     setSelectedUser(elm);
        //     setOpenConfirmDialog(true)
        // }
    };
    const headCells = [
      {
        id: 'displayName',
        numeric: false,
        disablePadding: false,
        label: 'Name',
        sort: true,
        render: elm => elm.displayName,
      },
      {
        id: 'logo',
        numeric: false,
        disablePadding: false,
        label: 'Image',
        sort: false,
        render: elm => (
          <>
            {' '}
            <Box>
              <CmtCardMedia className={classes.imageRoot} image={elm.logo} />
            </Box>
          </>
        ),
      },
      {
        id: 'title',
        numeric: false,
        disablePadding: false,
        label: 'Title',
        sort: false,
        render: elm => elm.title,
      },
      {
        id: 'active',
        numeric: false,
        disablePadding: false,
        sort: false,
        label: 'Status',
        render: elm => (
          <Switch
            // defaultChecked={elm.showInMiddle === true ? true : false}
            checked={elm.active === true ? true : false}
            onChange={() => handleChangeStatusSwitch(elm)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        ),
      },
      // {
      //     id: 'showInMiddle',
      //     numeric: false,
      //     disablePadding: false,
      //     sort: false,
      //     label: 'Show in Middle',
      //     render: (elm) => (
      //         < Switch
      //             // defaultChecked={elm.showInMiddle === true ? true : false}
      //             checked={elm.showInMiddle === true ? true : false}
      //             onChange={() => handleChangeSwitch(elm)}
      //             inputProps={{ 'aria-label': 'controlled' }}
      //         />
      //     )
      // },
      {
        id: 'actions',
        numeric: false,
        disablePadding: false,
        label: 'Actions',
        sort: false,
        render: elm => (
          <>
            {/* {!updatePermission && !deletePermission ? '-' : <CmtDropdownMenu items={userActions} onItemClick={(menu) => onUserMenuClick(menu, elm)} TriggerComponent={<MoreHoriz />} />} */}
            {
              <CmtDropdownMenu
                items={userActions}
                onItemClick={menu => onUserMenuClick(menu, elm)}
                TriggerComponent={<MoreHoriz />}
              />
            }
          </>
        ),
      },
      // {
      //     id: 'url',
      //     numeric: false,
      //     disablePadding: false,
      //     label: 'Link',
      //     render: elm => (
      //      <Button><a href={`https://www.thepipingmart.com/material/${elm.url}`}>Link</a></Button>
      //   )}
    ];
    //download file setting
    var fileHeaders = [
        {
            key: 'firstName',
            label: 'Name',
        },
        {
            key: 'email',
            label: 'Email'
        },
        {
            key: 'roleName',
            label: 'Role'
        },
        {
            key: 'departmentName',
            label: 'Dept'
        },
        {
            key: 'status',
            label: 'Status'
        },
    ];

    //-------all handle functions
    // const handleChangeSwitch = (elm) => {
    //     let showInMiddle
    //     if (elm.showInMiddle === true)
    //         showInMiddle = "false"
    //     else
    //         showInMiddle = "true"
    //     const data = {
    //         "productId": elm.productId,
    //         "showInMiddle": showInMiddle,
    //         "idOfProductCreator": user?.userId,
    //         "nameOfProductCreator": user?.name
    //     }
    //     dispatch(
    //         updateProduct(data, (data) => {
    //             if (data)
    //                 history.push('/manage-productMaterial')
    //         }),
    //     );
    // };

    const handleChangeStatusSwitch = (elm) => {
        // const {parameters,...elmData} = elm  // when pararmeter
        let status
        if (elm.active === true)
        status = "false"
        else
        status = "true"
        const data = {
            // ...elmData, // when pararmeter
            ...elm,
            active: status,
        }
        dispatch(
            updateProductMaterial(data, (data) => {
                if (data)
                    history.push('/manage-productMaterial')
            }),
        );
    };
    const handleCloseViewMaterialList = () => {
        setopenViewMaterialList(false);
        dispatch(setProductDetail(null));
    };
    const handleCloseViewMaterialAdd = () => {
        setOpenViewMaterialAdd(false);
        dispatch(setProductDetail(null));
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrderBy(property);
        setOrder(isAsc ? 'desc' : 'asc');
    };
    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };
    const handleViewDialog = user => {
        dispatch(setProductMaterialDetail(user));
        setOpenViewDialog(true);
    };
    const handleCloseViewDialog = () => {
        setOpenViewDialog(false);
        dispatch(setProductMaterialDetail(null));
    };
    const handleCloseUserDialog = () => {
        setOpenUserDialog(false);
        dispatch(setProductMaterialDetailElm(null));
    };
    const handleAddButton = () => {
        setOpenUserDialog(true);
        // dispatch(setProductMaterialDetail(null));
        history.push(`/manage-add-productMaterial`)
    };
    const handleCancelDelete = () => {
        setOpenConfirmDialog(false);
    };
    const handleConfirmDelete = () => {
        setOpenConfirmDialog(false);
        dispatch(deleteProduct(selectedUser._id, (data) => {
            if (data)
                setDataUpdated((data) => !data)
        }));
    };
    const handleCloseViewProductDetails = () => {
        setOpenViewProductDetails(false);
        dispatch(setProductMaterialDetailElm(null));
    };
    // const handleSearch = dateset_name => {
    //     setPage(0);
    //     setSearchTerm(dateset_name);
    // };
    // const downloadFn = async (params) => {
    //     dispatch(getAllProducts(params, (data) => {
    //         if (data) {
    //             let fileData = []
    //             data.forEach((item) => {
    //                 fileData.push({
    //                     firstName: item.firstName + " " + item.lastName,
    //                     email: item.email,
    //                     roleName: item.roleId?.roleName,
    //                     departmentName: item.roleId?.departmentId?.departmentName,
    //                     status: item.status === 1 ? 'Active' : 'Inactive',
    //                 });
    //             });
    //             setFileData(fileData)
    //         }
    //     }, true))
    // }
    //?to check permission function
    // const getPermission = useCallback(() => {
    //     permissionData &&
    //         permissionData.forEach(function (num) {
    //             if (user?.roleId?.roleSlug === 'admin' || num.name === 'user-create') {
    //                 setCreatePermission(true);
    //             }
    //             if (user?.roleId?.roleSlug === 'admin' || num.name === 'user-delete') {
    //                 setDeletePermission(true);
    //             }
    //             if (user?.roleId?.roleSlug === 'admin' || num.name === 'user-update') {
    //                 setUpdatePermission(true);
    //             }
    //             if (user?.roleId?.roleSlug === 'admin' || num.name === 'user-export') {
    //                 setExportPermission(true);
    //             }
    //         });
    // }, [permissionData, user]);

    //------all useEffects
    // useEffect(()=>{
    //     if(productmaterials){
    //         getProductMaterialData(products)
    //     }
    // },[products])
    useEffect(() => {
        let params
        params = {
            search: searchTerm,
            // page:page+1
        }
        // if (search) {
        //     params = {
        //         ...params,
        //         ...search
        //     };
        // }
        // if (products.length >= 0)
            dispatch(getAllProductMaterials(params), () => { setproductsFetched(true); })
        // getPermission()
       
    }, [dispatch, dataUpdated])

    // useEffect(() => { //to download data 
    //     let params
    //     params = {
    //         search: searchTerm
    //     }
    //     // if (search) {
    //     //     params = {
    //     //         ...params,
    //     //         ...search
    //     //     };
    //     // }
    //     downloadFn(params)
    // }, [isDownload])

    // useEffect(() => {
    //     setDownloadData(data => !data) //update state at custom toolbar component
    // }, [fileData])
    useEffect(() => {
        if (successAction === PRODUCTMATERIAL_UPDATE_SUCCESS || successAction === PRODUCTMATERIAL_CREATE_SUCCESS || successAction === PRODUCTMATERIAL_MATERIAL_CREATE_SUCCESS) {
            let params
            params = {
                search: searchTerm,
                // page:page+1
            }
            // if (search) {
            //     params = {
            //         ...params,
            //         ...search
            //     };
            // }
            dispatch(getAllProductMaterials(params), () => { setproductsFetched(true); })
        }
    }, [successAction])
    // console.log(productmaterials, total, perPage)
    
    return (
        <>
            <CustomTable
                TableName="ProductMaterial"
                downloadTableBtn={exportPermission ? true : false}
                fileName="products.xlsx"
                fileHeaders={fileHeaders}
                fileData={fileData}
                setIsDownload={setIsDownload}
                downloadData={downloadData}
                row={productmaterials && productmaterials}
                // addButtonText={createPermission ? "Add User" : ""}
                addButtonText={"Add Product Material"}
                handleAddButton={handleAddButton}
                headCells={headCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                count={total}
                // count={total}
                rowsPerPage={perPage}
                onPageChange={handlePageChange}
                page={page}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                // handleSearch={handleSearch}
                handleSearch={(text)=> setSearchTerm(text)}
                handleSearchSubmit={()=>{
                    setPage(0)
                    setDataUpdated(!dataUpdated)}}
                handleViewDialog={handleViewDialog}
                productDetailModal={
                    {
                        emailData: productDetail?.email,
                        modalHeading: productDetail?.name,
                        headerArray: ["MemberType: " + productDetail?.membertype, productDetail?.address && "Address: " + productDetail?.address],
                        sectionHeading: "Contact Details",
                        openViewDialog: openViewDialog,
                        handleCloseViewDialog: handleCloseViewDialog,
                        activeChip: productDetail?.active,
                        phoneData: productDetail?.mobileNo,
                    }}
            />
            {openUserDialog && <AddEditProductMaterial setDataUpdated={setDataUpdated} open={openUserDialog} onCloseDialog={handleCloseUserDialog} />} 
            {openViewProductDetails && <ViewProductMaterialDetails open={openViewProductDetails} onCloseDialog={handleCloseViewProductDetails} />}
            {/* {openViewMaterialList && <ViewMaterialList open={openViewMaterialList} onCloseDialog={handleCloseViewMaterialList} />} */}
            {/* {openViewMaterialAdd && <AddMaterialToProduct setDataUpdated={setDataUpdated} history={history} open={openViewMaterialAdd} onCloseDialog={handleCloseViewMaterialAdd} />} */}
            <ConfirmDialog
                open={openConfirmDialog}
                title={`Confirm delete`}
                content={'Are you sure, you want to  delete this user?'}
                onClose={handleCancelDelete}
                onConfirm={handleConfirmDelete}
            />
        </>
    )
}

export default ProductMaterial