import Axios from "axios";
import { MSG_ERR_RESPONDING_SERVER, PER_PAGE_DEFAULT } from 'redux/actions/Common/Constant';
import { ErrorCatch } from "utils/ErrorCatch";
import { setError } from "../../Common/ErrorAction";
import { setLoading } from "../../Common/LoadingAction";
import { setSuccess } from "../../Common/SuccessAction";
import { DELETE_BULK_NEWS_FAILED, DELETE_BULK_NEWS_REQUEST, DELETE_BULK_NEWS_SUCCESS, NEWS_CREATE_FAILED, NEWS_CREATE_REQUEST, NEWS_CREATE_SUCCESS, NEWS_DELETE_FAILED, NEWS_DELETE_REQUEST, NEWS_DELETE_SUCCESS, NEWS_LIST_FAILED, NEWS_LIST_REQUEST, NEWS_LIST_SUCCESS, NEWS_MATERIAL_CREATE_FAILED, NEWS_MATERIAL_CREATE_REQUEST, NEWS_MATERIAL_CREATE_SUCCESS, NEWS_UPDATE_FAILED, NEWS_UPDATE_REQUEST, NEWS_UPDATE_SUCCESS, SET_NEWS_DETAILS } from "./Constant";

let RES_MSG = MSG_ERR_RESPONDING_SERVER

// export const getAllUsers = (filterOptions = [],params, searchTerm = '', callbackFun) =>
export const getAllNews = (params, callbackFun, isDownload = false) =>
    async (dispatch) => {
        dispatch(setLoading(NEWS_LIST_REQUEST))
        try {
            // const { data } = await Axios.get(`/user/list,?page=${1}`)
            // const { data } = await Axios.get(`/auth/fetch_admins`, { params })
            // const { data } = await Axios.get(`/auth/fetch_admins`)
            const response = await Axios.post(`/auth/fetchNewsAdmin`, { params })
            const { data } = response
            const total = data?.data?.length
            if (response.status === 200) {
                if (isDownload)
                    dispatch(setSuccess(null, NEWS_LIST_SUCCESS))
                else {
                    dispatch({
                        type: NEWS_LIST_SUCCESS,
                        data: data.data,
                        total: total,
                        message: data.message,
                        perPage: PER_PAGE_DEFAULT,
                    });
                }
                if (callbackFun) callbackFun(data.data.userList);
            }
            else {
                dispatch(setError(RES_MSG, NEWS_LIST_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, NEWS_LIST_FAILED, dispatch)
        }
    };

export const getNewsList = ({  search}) => {
    return dispatch => {
        dispatch(setLoading(NEWS_LIST_REQUEST))
      return Axios
        .post('/product/products', { params: { search,  } })
        .then(response => {
            const { data } = response
            const total = data?.data?.length
          if (response?.status === 200) {
            dispatch({
                type: NEWS_LIST_SUCCESS,
                data: data.data,
                total: total,
                message: data.message,
                perPage: PER_PAGE_DEFAULT,
            });
            return Promise.resolve(response);
          }
        })
        .catch(err => {
            ErrorCatch(err, NEWS_LIST_FAILED, dispatch)
          return Promise.resolve(err);
        });
    };
  };
export const updateNews = (payload, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(NEWS_UPDATE_REQUEST))
        try {
            const response = await Axios.post(`/auth/updateNews`, payload)
            const { data } = response
            if (response.status === 200) {
                dispatch(setSuccess(data.message, NEWS_UPDATE_SUCCESS))
                dispatch({
                    type: NEWS_UPDATE_SUCCESS,
                    data: data.data,
                    message: data.message,
                });
                if (callbackFun) callbackFun(data.data);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, NEWS_UPDATE_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, NEWS_UPDATE_FAILED, dispatch)
        }
    };

export const setNewsDetail = user => {
    return dispatch => {
        dispatch({
            type: SET_NEWS_DETAILS,
            data: user,
        });
    };
};
export const createNews = (payload, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(NEWS_CREATE_REQUEST))
        try {
            const response = await Axios.post(`/auth/addNews`, payload)
            const { data } = response
            if (response.status === 200) {
                dispatch(setSuccess(data.message, NEWS_CREATE_SUCCESS))
                dispatch({
                    type: NEWS_CREATE_SUCCESS,
                    data: data.data,
                    message: data.message,
                });
                if (callbackFun) callbackFun(data.data);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, NEWS_CREATE_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, NEWS_CREATE_FAILED, dispatch)
        }
    };
//take user id for delete api request
export const deleteNews = (userId, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(NEWS_DELETE_REQUEST))
        try {
            const { data } = await Axios.delete(`/auth/dtn`)
            if (data.statusCode === 200) {
                dispatch(setSuccess(data.responseMsg, NEWS_DELETE_SUCCESS))
                dispatch({
                    type: NEWS_DELETE_SUCCESS,
                    data: data.data.userDelete,
                    message: data.responseMsg,
                });
                if (callbackFun) callbackFun(data.data.userDelete);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, NEWS_DELETE_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, NEWS_DELETE_FAILED, dispatch)
        }
    };

export const deleteBulkNews = (userIds, callbackFun) =>
    async (dispatch) => {
        dispatch(setLoading(DELETE_BULK_NEWS_REQUEST))
        try {
            // const { data } = await Axios.delete(`/admin/roles/delete/${roleId}`)
            const data = {
                statusCode: 200,
                data: userIds,
                responseMsg: "Selected Users were deleted successfully!",
            }
            if (data.statusCode === 200) {
                dispatch(setSuccess(data.responseMsg, DELETE_BULK_NEWS_SUCCESS))
                dispatch({
                    type: DELETE_BULK_NEWS_SUCCESS,
                    data: data.data,
                    message: data.message,
                });
                if (callbackFun) callbackFun(data.data);
            }
            else {
                dispatch(setError(MSG_ERR_RESPONDING_SERVER, DELETE_BULK_NEWS_FAILED));
            }
        } catch (error) {
            ErrorCatch(error, DELETE_BULK_NEWS_FAILED, dispatch)
        }
    };
