import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setGradeDetail, updateGrade, updateProductGradeFnqDetail } from 'redux/actions/Manage/ProductMaterialGrades';
import CustomEditor from 'components/CustomEditor';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import GridContainer from '@jumbo/components/GridContainer';
import { Chip, Divider, IconButton, Paper, TextareaAutosize } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ClearIcon from '@material-ui/icons/Clear'; import { getAllProducts, setProductDetail, updateProductDetail } from 'redux/actions/Manage/Products/Products';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCard from '@coremat/CmtCard';
import { setError } from 'redux/actions/Common/ErrorAction';
import { MSG_ERR_RESPONDING_SERVER } from 'redux/actions/Common/Constant';
import { PRODUCT_UPDATE_FAILED } from 'redux/actions';
import Axios from 'axios';
;

const useStyles = makeStyles(theme => ({
    dialogRoot: {
        position: 'relative',
    },
    dialogTitleRoot: {
        '& .MuiTypography-h6': {
            fontSize: 16,
            color: theme.palette.common.dark,
            minWidth: '400px',
        },
    },
    removeBtn: {
        backgroundColor: "#d32f2f",
        color: "white"
    }
}));

const AddEditFaqs = ({ history, open, onCloseDialog, setDataUpdated, grade }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    //------------all use selectors

    const [faqArr, setFaqArr] = useState([]);


    const handleFaqQuestionChange = (e, index) => {
        const updatedFaqArr = [...faqArr];
        updatedFaqArr[index] = {
            ...updatedFaqArr[index],
            question: e.target.value,
        };
        setFaqArr(updatedFaqArr);
    };

    const handleEditor = (e, index) => {
        const updatedFaqArr = [...faqArr];
        updatedFaqArr[index] = {
            ...updatedFaqArr[index],
            answer: e,
        };
        setFaqArr(updatedFaqArr);
    }

    const onSubmitAddFAQ = () => {
        setFaqArr([...faqArr, { question: "", answer: "" }]);
    };
    const onSubmitClick = () => {
        setFaqArr([...faqArr])
        dispatch(updateProductGradeFnqDetail(grade?._id, { faqs: faqArr }))
            .then(() => {
                dispatch(setGradeDetail(grade._id))
            })
    };
    const handleRemoveFaqs = async (faqId) => {
        try {
            const delFaqs = await Axios.post(`/product/deleteFaq-materialGrade/${grade._id}/${faqId}`);
            dispatch(setGradeDetail(grade._id))
        } catch (error) {
            dispatch(setError(MSG_ERR_RESPONDING_SERVER, PRODUCT_UPDATE_FAILED));
        }
    }

    //------all useEffects
    useEffect(() => {
        if (grade) {
            setFaqArr(grade.faqs);
        }
    }, [grade]);


    return (
        <PageContainer>
            <Paper className={classes.paper}></Paper>
            <CmtCard>
                <CmtCardContent>
                    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="flex-start" mb={{ xs: 6, md: 5 }}>
                        <Grid item xs={12} sm={12}>
                            {grade && faqArr &&
                                faqArr.map((ele, index) => (
                                    <Box marginBottom="50px" key={index}>
                                        <Grid item xs={12} sm={12}>
                                            <AppTextInput
                                                fullWidth
                                                name="question"
                                                variant="outlined"
                                                label="Question"
                                                value={ele.question}
                                                onChange={(evt) => handleFaqQuestionChange(evt, index, ele)}
                                            />
                                        </Grid>
                                        <Box marginTop="10px">
                                            <CustomEditor
                                                editorData={ele.answer}
                                                setEditorData={(e) => handleEditor(e, index)}
                                            />
                                        </Box>
                                        <Box marginTop="10px" sx={{ textAlign: "end" }}>
                                            <Button variant="contained" className={classes.removeBtn} onClick={() => ele._id ? handleRemoveFaqs(ele._id) : setFaqArr((pre) => pre.filter((e, i) => i !== index))}>
                                                Remove
                                            </Button>
                                        </Box>
                                    </Box>
                                ))}
                        </Grid>
                    </Box>
                    <Box display="flex" justifyContent="space-between" mb={4}>
                        <Box ml={2}>
                            <Button variant="contained" color="primary" onClick={onSubmitAddFAQ}>
                                Add New Faq
                            </Button>
                        </Box>
                        <Box display="flex" justifyContent="flex-end" mb={4}>
                            <Button onClick={() => {
                                history.push('/manage-product-material-grade')
                            }}>Cancel</Button>
                            <Box ml={2}>
                                <Button variant="contained" color="primary" onClick={onSubmitClick}>
                                    {grade ? 'Update' : 'Save'}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </CmtCardContent>
            </CmtCard>
        </PageContainer>
    );
};

export default AddEditFaqs;

AddEditFaqs.prototype = {
    open: PropTypes.bool.isRequired,
    onCloseDialog: PropTypes.func,
};
