import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { updateMailsFolder, updateMailsLabel } from 'redux/actions/MailApp';
import MoreOptions from './MoreOptions';
import HeaderOptions from './HeaderOptions';
import useStyles from '../../index.style';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import TablePagination from '@material-ui/core/TablePagination';
import PropTypes from 'prop-types';
import { bulkUpdateStatus, setFilterType } from 'redux/actions/Manage/Emails/Emails';

const selectCategories = [
  { id: 12, label: 'None', slug: 'none' },
  { id: 11, label: 'All', slug: 'all' },
  { id: 1, label: 'Supplier', slug: 'supplier_email' },
  { id: 4, label: 'Enquiry', slug: 'enquiry' },
  { id: 2, label: 'Read', slug: 'read' },
  { id: 3, label: 'Unread', slug: 'unread' },
];
const selectStatusCategories = [
  { id: 12, label: 'None', slug: 'none' },
  { id: 11, label: 'All', slug: 'all' },
  { id: 5, label: 'Archive', slug: 'archive' },
  { id: 6, label: 'Trash', slug: 'trash' },
  // { id: 7, label: 'Blocked', slug: 'block' },
  { id: 2, label: 'Read', slug: 'read' },
  { id: 3, label: 'Unread', slug: 'unread' },
];

const onSelectStatusCategories = (category, mails) => {
  let selectedMails = [];
  switch (category) {
    case 'all': {
      selectedMails = mails;
      break;
    }
    case 'approve': {
      selectedMails = mails.filter(mail => mail.status === 'approve');
      break;
    }
    case 'disapprove': {
      selectedMails = mails.filter(mail => mail.status === 'disapprove');
      break;
    }
    case 'archive': {
      selectedMails = mails.filter(mail => mail.status === 'archive');
      break;
    }
    case 'trash': {
      selectedMails = mails.filter(mail => mail.status === 'trash');
      break;
    }
    case 'block': {
      selectedMails = mails.filter(mail => mail.status === 'block');
      break;
    }
    case 'read': {
      selectedMails = mails.filter(mail => mail.seenStatus === 'read');
      break;
    }
    case 'unread': {
      selectedMails = mails.filter(mail => mail.seenStatus === 'unread');
      break;
    }
    default:
  }
  return selectedMails.map(mail => mail._id);
};
const onSelectMails = (category, mails) => {
  let selectedMails = [];
  switch (category) {
    case 'all': {
      selectedMails = mails;
      break;
    }
    case 'supplier_email': {
      selectedMails = mails.filter(mail => mail.emailType === 'supplier_email');
      break;
    }
    case 'enquiry': {
      selectedMails = mails.filter(mail => mail.emailType === 'enquiry');
      break;
    }
    case 'read': {
      selectedMails = mails.filter(mail => mail.seenStatus === 'read');
      break;
    }
    case 'unread': {
      selectedMails = mails.filter(mail => mail.seenStatus === 'unread');
      break;
    }
    default:
  }
  return selectedMails.map(mail => mail._id);
};

const ListHeader = ({ checkedMails, setCheckedMails }) => {
  const dispatch = useDispatch();
  const [category, setCategory] = useState(selectCategories[0].slug);
  const [statusCategory, setStatusCategory] = useState(selectStatusCategories[0].slug);
  const classes = useStyles();

  const { mailsList, labelsList, filterType, totalMailCount } = useSelector(({ Emails }) => Emails);

  useEffect(() => {
    if (filterType && filterType.selectedLabel?.length > 0) {
      const selectedMails = onSelectStatusCategories(statusCategory, mailsList);
      setCheckedMails(selectedMails);
    }
    else {
      const selectedMails = onSelectMails(category, mailsList);
      setCheckedMails(selectedMails);
    }
  }, [category, statusCategory, dispatch, setCheckedMails, mailsList]);

  const handleCheckBox = event => {
    if (event.target.checked) {
      const mailIds = mailsList.map(mail => mail.id);
      setCheckedMails(mailIds);
    } else {
      setCheckedMails([]);
      setCategory('none');
      setStatusCategory('none');
    }
  };

  const onChangeMailFolder = folder => {
    let params = {
      idArr: checkedMails,
      status: folder
    }
    dispatch(bulkUpdateStatus(params));
    setCheckedMails([]);
  };

  const onSelectLabel = label => {
    dispatch(updateMailsLabel(checkedMails, label));
    setCheckedMails([]);
  };

  const onPageChange = (event, value) => {
    dispatch(
      setFilterType({
        ...filterType,
        page: value,
      }),
    );
  };

  return (
    <Box className={classes.appContentHeader}>
      <Checkbox
        color="primary"
        indeterminate={checkedMails.length > 0 && checkedMails.length < mailsList.length}
        checked={checkedMails.length > 0 && checkedMails.length === mailsList.length}
        onChange={handleCheckBox}
      />

      <AppSelectBox
        id="mail-app"
        data={filterType.selectedLabel?.length > 0 ? selectStatusCategories : selectCategories}
        value={filterType.selectedLabel?.length > 0 ? statusCategory : category}
        fullWidth={false}
        onChange={e => {
          if (filterType.selectedLabel?.length > 0)
            setStatusCategory(e.target.value)
          else
            setCategory(e.target.value)
        }
        }
        className={classes.selectBoxRoot}
        renderRow={(item, index) => (
          <MenuItem key={index} value={item.slug}>
            {item.label}
          </MenuItem>
        )}
      />

      {checkedMails.length > 0 ? (
        <Box ml="auto" display="flex" alignItems="center">
          <HeaderOptions onChangeMailFolder={onChangeMailFolder} onSelectLabel={onSelectLabel} labelsList={labelsList} />
          {/* <MoreOptions checkedMails={checkedMails} setCheckedMails={setCheckedMails} mailsList={mailsList} /> */}
        </Box>
      ) : (
        <Box ml="auto" display="flex" alignItems="center">
          {totalMailCount ? (
            <TablePagination
              component="div"
              count={totalMailCount}
              rowsPerPage={10}
              page={filterType.page}
              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
              onChangePage={onPageChange}
              rowsPerPageOptions={[]}
            />
          ) : null}
        </Box>
      )}
    </Box>
  );
};

export default ListHeader;

ListHeader.prototype = {
  checkedMails: PropTypes.array.isRequired,
  setCheckedMails: PropTypes.func,
};
