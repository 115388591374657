import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchGrade } from 'redux/actions/Manage/Suppliers/Suppliers';

const Grades = ({
  productId,
  materialId,
  supplierProducts,
  handleAddProduct,
  setNewAddedProducts,
  setRemovedProducts,
  setsupplierProducts,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { gradesList } = useSelector(state => state.SetSupplierDetail);
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [selectAll, setSelectAll] = useState(false); // Track "Select All" checkbox state
  console.log(selectedGrades,"selectedGradesselectedGrades")
  const getCheckedValue = (data, event, index) => {
    const isChecked = event.target.checked;

    setSelectedGrades(prevGrades => {
      if (isChecked) {
        return [...prevGrades, data];
      } else {
        return prevGrades.filter(grade => grade.gradeId !== data.gradeId);
      }
    });

    // setNewAddedProducts(prevProducts => {
    //   if (isChecked) {
    //     return [...prevProducts, data];
    //   } else {
    //     return prevProducts.filter(product => product.gradeId !== data.gradeId);
    //   }
    // });

    // setRemovedProducts(prevProducts => {
    //   if (isChecked) {
    //     return prevProducts.filter(product => product.gradeId !== data.gradeId);
    //   } else {
    //     return [...prevProducts, data];
    //   }
    // });

    if (isChecked && !supplierProducts.some(product => product.gradeId === data.gradeId)) {
      setsupplierProducts(prevProducts => [...prevProducts, data]);
    } else if (!isChecked) {
      setsupplierProducts(prevProducts =>
        prevProducts.filter(product => product.gradeId !== data.gradeId)
      );
    }
  };

  const handleSelectAll = event => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);

    if (isChecked) {
      const allGrades = gradesList.filter(
        grade => !supplierProducts.some(product => product.gradeId === grade.gradeId)
      );
      setSelectedGrades(prevGrades => [...prevGrades, ...allGrades]);
      setsupplierProducts(prevProducts => [...prevProducts, ...allGrades]);
    } else {
      setSelectedGrades([]);
      setsupplierProducts(prevProducts =>
        prevProducts.filter(product => !selectedGrades.some(grade => grade.gradeId === product.gradeId))
      );
    }
  };

  useEffect(() => {
    // Fetch grade list when productId or materialId changes
    if (productId && materialId) {
      dispatch(fetchGrade(productId, materialId));
    }
  }, [dispatch, productId, materialId]);

  useEffect(() => {
    // Reset "Select All" checkbox when a new grade list is fetched
    setSelectAll(false);
  }, [gradesList]);

  useEffect(() => {
    const filteredGrades = gradesList?.filter(grade =>
      supplierProducts.some(product => product.gradeId === grade.gradeId)
    );
    setSelectedGrades(filteredGrades);
  }, [gradesList, supplierProducts]);

  return (
    <>
      <Box bgcolor={'black'} color={'white'} textAlign={'center'} p={3} zIndex="1000">
        Grades
        <span>
          <input type="checkbox" checked={selectAll} onChange={handleSelectAll} /> Select All
        </span>
      </Box>
      <Box p={3} height={'68vh'} overflow={'auto'} >
        {gradesList &&
          gradesList.map((data, index) => (
            <Box
              key={index}
              display="flex"
              bgcolor="white"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box p={2}>{data.name}</Box>
              <Box p={2}>
                <input
                  type="checkbox"
                  id={data.gradeId}
                  onClick={event => getCheckedValue(data, event, index)}
                  checked={selectedGrades?.some(grade => grade.gradeId === data.gradeId)}
                />
              </Box>
            </Box>
          ))}
      </Box>
      <Box textAlign={'center'} py={3}>
      </Box>
    </>
  );
};

export default Grades;
