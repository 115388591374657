import Axios from 'axios';
import {
  MSG_ERR_RESPONDING_SERVER,
  PER_PAGE_DEFAULT,
} from 'redux/actions/Common/Constant';
import { ErrorCatch } from 'utils/ErrorCatch';
import { setError } from '../../Common/ErrorAction';
import { setLoading } from '../../Common/LoadingAction';
import { setSuccess } from '../../Common/SuccessAction';
import {

  BLOCK_ALL_IP_FAILED,
  BLOCK_ALL_IP_REQUEST,
  BLOCK_ALL_IP_SUCCESS,
  BLOCK_SINGLE_IP_FAILED,
  BLOCK_SINGLE_IP_REQUEST,
  BLOCK_SINGLE_IP_SUCCESS,
  BLOCKED_USERS_LIST_FAILED,
  BLOCKED_USERS_LIST_REQUEST,
  BLOCKED_USERS_LIST_SUCCESS,

} from './Constant';
let RES_MSG = MSG_ERR_RESPONDING_SERVER;

export const addBlockIp = function (data) {
  return async function (dispatch) {
    try {
      dispatch(setLoading());
      const response = await Axios.post('/api/v1/admin/blockIp/add', data);
      if (response.status === 201) {
        dispatch(setSuccess('Blocked Successfully'));
      }
    } catch (error) {
      console.error(error)
      dispatch(setError(error?.data?.message));
    }
  }
}

export const editBlockIp = function (id,data) {
  return async function (dispatch) {
    try {
      dispatch(setLoading());
      const response = await Axios.put(`/api/v1/admin/blockIp/edit/${id}`, data);
      if (response.status === 200) {
        dispatch(setSuccess('Updated Successfully'));
      }
    } catch (error) {
      console.error(error)
      dispatch(setError(error?.data?.message));
    }
  }
}

export const fetchBlockIp = function (id) {
  return async function (dispatch) {
    try {
      dispatch(setLoading(BLOCK_SINGLE_IP_REQUEST));
      dispatch({ type: BLOCK_SINGLE_IP_REQUEST })
      const response = await Axios.get(`/api/v1/admin/blockIp/single-list/${id}`);
      if (response.status === 200) {
        dispatch({ type: BLOCK_SINGLE_IP_SUCCESS, payload: { singleBlockIp: response?.data?.data } })
        return response?.data?.data
      }
    } catch (error) {
      dispatch(setError(error?.data?.message));
      dispatch({ type: BLOCK_SINGLE_IP_FAILED, payload: { errorMessage: error } })
    }
  }
}
export const fetchAllBlockIp = function (data) {
  return async function (dispatch) {
    try {
      dispatch(setLoading(BLOCK_ALL_IP_REQUEST));
      dispatch({ type: BLOCK_ALL_IP_REQUEST })
      const response = await Axios.get(`/api/v1/admin/blockIp/list?search=${data?.search || ''}&&page=${data?.page}`);
      if (response.status === 200) {
        dispatch({ type: BLOCK_ALL_IP_SUCCESS, payload: { blockedIp: response?.data?.data,total:response?.data?.total } })
      }
    } catch (error) {
      dispatch(setError(error?.data?.message));
      dispatch({ type: BLOCK_ALL_IP_FAILED, payload: { errorMessage: error } })
    }
  }
}
export const updateLimitAndDuration = function (id,data) {
  return async function (dispatch) {
    try {
      dispatch(setLoading());
      const response = await Axios.put(`/api/v1/admin/blockIp/setLmitAndDuration/${id}`,data);
      if(response.status === 200){
        dispatch(setSuccess('Updated Successfully'));
      }
    } catch (error) {
      dispatch(setError(error?.data?.message));
    }
  }
}
export const getLimitAndDuration = function (id,data) {
  return async function (dispatch) {
    try {
      // dispatch(setLoading());
      const response = await Axios.get(`/api/v1/admin/blockIp/getLmitAndDuration`);
      if(response.status === 200){
        // dispatch(setSuccess('fetched Successfully'));
        return Promise.resolve(response?.data?.data)
      }
    } catch (error) {
      dispatch(setError(error?.data?.message));
    }
  }
}