import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Switch } from '@material-ui/core';
import { MoreHoriz, Edit } from '@material-ui/icons';

import CustomTable from 'components/CustomeTable';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import CustomModal from 'components/CustomModal';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import { getGradeModalList } from 'redux/actions/Manage/GradeModal';
import {
  createEqGrades,
  getAllEqGrades,
  updateEqGrades,
} from 'redux/actions/Manage/EqGrades/EqGrade';

const EqGrade = () => {
  const dispatch = useDispatch();
  const gradeData = useSelector(state => state?.GradeModel?.gradesModal);
  const { eqGrades } = useSelector(state => state.EqGrades);
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [errorText, setErrorText] = useState({});
  const [flag, setFlag] = useState(false);
  const [id, setId] = useState('');
  const [isActiveData, setIsActiveData] = useState(null);
  const [eqGradeData, setEqGradeData] = useState({
    gradeId: '',
    eqGradeName: '',
  });

  let headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Plan Name',
      visible: true,
    },
    {
      id: 'active',
      numeric: false,
      disablePadding: false,
      sort: false,
      label: 'Status',
      render: elm => (
        <Switch
          // defaultChecked={elm.showInMiddle === true ? true : false}
          checked={elm?.isActive === true ? true : false}
          onChange={() => handleChangeStatusSwitch(elm)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      ),
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Actions',
      sort: false,
      render: elm => (
        <CmtDropdownMenu
          items={userActions}
          onItemClick={menu => onUserMenuClick(menu, elm)}
          TriggerComponent={<MoreHoriz />}
        />
      ),
    },
  ];

  const handleChangeStatusSwitch = elm => {
    let status;
    if (elm?.isActive === true) status = false;
    else status = true;
    const data = { isActive: status };
    dispatch(updateEqGrades(elm?._id, data)).then(res => {
      if (res?.status === 200) {
        setIsEdit(false);
        setFlag(!flag);
        setOpenModal(false);
      }
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const getUserActions = () => {
    const actions = [];
    actions.push({ action: 'edit', label: 'Edit', icon: <Edit /> });
    return actions;
  };

  const userActions = getUserActions();
  const onUserMenuClick = (menu, elm) => {
    if (menu.action === 'edit') {
      setOpenModal(true);
      setIsEdit(true);
      setId(elm?._id);
      setEqGradeData({ gradeId: elm?.gradeModelId, eqGradeName: elm?.name });
    }
  };

  const handleChange = evt => {
    setEqGradeData({ ...eqGradeData, [evt.target.name]: evt.target.value });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setIsEdit(false);
    setId('');
    setEqGradeData({ gradeId: '', eqGradeName: '' });
    setErrorText({})
  };

  const validate = () => {
    let errors = {};
    let isError = false;
    const { eqGradeName, gradeId } = eqGradeData;
    if (!gradeId) {
      errors.gradeId = 'Please select grade.';
      isError = true;
    }
    if (!eqGradeName) {
      errors.eqGradeName = 'Please enter equivalent grade name.';
      isError = true;
    }

    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };

  const handleSubmit = () => {
    let validateData = validate();
    if (!validateData?.isError) {
      let data = { name: eqGradeName, gradeModelId: gradeId };
      dispatch(id ? updateEqGrades(id, data) : createEqGrades(data)).then(
        res => {
          if (res?.status === 200) {
            setFlag(!flag);
            setIsEdit(false);
            setOpenModal(false);
          }
        },
      );
    }
  };

  useEffect(() => {
    dispatch(getGradeModalList({}));
    let value = { page: page + 1, perPage: 10 };
    if (searchTerm) {
      value.search = searchTerm;
    }
    dispatch(getAllEqGrades(value)).then(res => {
      if (res?.status === 200) {
        const { isActive, name, gradeId } = res?.data?.data;
        setEqGradeData({ eqGradeName: name, gradeId });
        setIsActiveData(isActive);
      }
    });
  }, [flag, dispatch, page]);

  const { eqGradeName, gradeId } = eqGradeData;

  return (
    <>
      <CustomTable
        headCells={headCells}
        row={eqGrades?.equivalentGradeList || []}
        count={eqGrades?.total || 0}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        rowsPerPage={eqGrades?.perPage}
        onPageChange={handlePageChange}
        page={page}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleSearch={text => setSearchTerm(text)}
        handleSearchSubmit={() => {
          setPage(0);
          setFlag(!flag);
        }}
        addButtonText={'Add Equivalent Grade'}
        handleAddButton={() => setOpenModal(true)}
        isDynamic={true}
      />
      <CustomModal
        open={openModal}
        onClose={handleCloseModal}
        title={isEdit ? 'Edit Equivalent Grade' : 'Add Equivalent Grade'}
      >
        <Box mb={{ xs: 6, md: 5 }}>
          <AppSelectBox
            required
            data={gradeData}
            name="gradeId"
            label="Grade"
            valueKey="_id"
            variant="outlined"
            labelKey="name"
            value={gradeId}
            onChange={handleChange}
            helperText={errorText?.gradeId}
          />
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            required
            name="eqGradeName"
            variant="outlined"
            label="Equivalent Grade Name"
            value={eqGradeName}
            onChange={handleChange}
            helperText={errorText?.eqGradeName}
          />
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {isEdit ? 'Update' : 'Submit'}
          </Button>
        </Box>
      </CustomModal>
    </>
  );
};

export default EqGrade;
