import Axios from 'axios';
import {
  MSG_ERR_RESPONDING_SERVER,
  PER_PAGE_DEFAULT,
} from 'redux/actions/Common/Constant';
import { ErrorCatch } from 'utils/ErrorCatch';
import { setError } from '../../Common/ErrorAction';
import { setLoading } from '../../Common/LoadingAction';
import { setSuccess } from '../../Common/SuccessAction';
import {
  DELETE_BULK_MATERIAL_FAILED,
  DELETE_BULK_MATERIAL_REQUEST,
  DELETE_BULK_MATERIAL_SUCCESS,
  MATERIAL_CREATE_FAILED,
  MATERIAL_CREATE_REQUEST,
  MATERIAL_CREATE_SUCCESS,
  MATERIAL_DELETE_FAILED,
  MATERIAL_DELETE_REQUEST,
  MATERIAL_DELETE_SUCCESS,
  MATERIAL_GRADE_FAILED,
  MATERIAL_GRADE_REQUEST,
  MATERIAL_GRADE_SUCCESS,
  MATERIAL_LIST_FAILED,
  MATERIAL_LIST_REQUEST,
  MATERIAL_LIST_SUCCESS,
  MATERIAL_UPDATE_FAILED,
  MATERIAL_UPDATE_REQUEST,
  MATERIAL_UPDATE_SUCCESS,
  SET_MATERIAL_DETAILS,
  SET_SINGLEMATERAIL_DETAILS,
  SET_SINGLEPRODUCT_DETAILS,
} from './Constant';

let RES_MSG = MSG_ERR_RESPONDING_SERVER;

// export const getAllUsers = (filterOptions = [],params, searchTerm = '', callbackFun) =>
export const getAllMaterials = (
  params,
  callbackFun,
  isDownload = false,
) => async dispatch => {
  dispatch(setLoading(MATERIAL_LIST_REQUEST));
  try {
    // const { data } = await Axios.get(`/user/list,?page=${1}`)
    // const { data } = await Axios.get(`/auth/fetch_admins`, { params })
    // const { data } = await Axios.get(`/auth/fetch_admins`)
    const response = await Axios.post(`/product/materials`, { params });
    const { data } = response;
    const total = data?.data?.length;
    if (response.status === 200) {
      if (isDownload) dispatch(setSuccess(null, MATERIAL_LIST_SUCCESS));
      else {
        dispatch({
          type: MATERIAL_LIST_SUCCESS,
          data: data.data,
          total: total,
          message: data.message,
          perPage: PER_PAGE_DEFAULT,
        });
      }
      if (callbackFun) callbackFun(data.data.userList);
    } else {
      dispatch(setError(RES_MSG, MATERIAL_LIST_FAILED));
    }
  } catch (error) {
    ErrorCatch(error, MATERIAL_LIST_FAILED, dispatch);
  }
};
export const getMaterialsDropdown = (
  params,
  callbackFun,
) => async dispatch => {
  dispatch(setLoading(MATERIAL_LIST_REQUEST));
  try {
    // const { data } = await Axios.get(`/user/list,?page=${1}`)
    // const { data } = await Axios.get(`/auth/fetch_admins`, { params })
    // const { data } = await Axios.get(`/auth/fetch_admins`)
    const response = await Axios.post(`/product/materials-dropdown`, { 
      productId:params?.productId,
      subProductId:params?.subProductId
     });
    const { data } = response;
    if (response.status === 200) {
      dispatch({
        type: MATERIAL_LIST_SUCCESS,
        data: data.data,
        message: data.message,
        perPage: PER_PAGE_DEFAULT,
      });
      if (callbackFun) callbackFun(data.data.userList);
    } else {
      dispatch(setError(RES_MSG, MATERIAL_LIST_FAILED));
    }
  } catch (error) {
    ErrorCatch(error, MATERIAL_LIST_FAILED, dispatch);
  }
};
export const updateMaterial = (payload, callbackFun) => async dispatch => {
  dispatch(setLoading(MATERIAL_UPDATE_REQUEST));
  try {
    const response = await Axios.post(`/product/updateMaterial`, payload);
    const { data } = response;
    if (response.status === 200) {
      dispatch(setSuccess(data.message, MATERIAL_UPDATE_SUCCESS));
      dispatch({
        type: MATERIAL_UPDATE_SUCCESS,
        data: data.data,
        message: data.message,
      });
      if (callbackFun) callbackFun(data.data);
    } else {
      dispatch(setError(MSG_ERR_RESPONDING_SERVER, MATERIAL_UPDATE_FAILED));
    }
  } catch (error) {
    dispatch(setError(error?.data?.message, MATERIAL_CREATE_FAILED));
    // ErrorCatch(error, MATERIAL_UPDATE_FAILED, dispatch);
  }
};

export const setMaterialDetail = user => {
  return dispatch => {
    dispatch({
      type: SET_MATERIAL_DETAILS,
      data: user,
    });
  };
};
export const createMaterial = (payload, callbackFun) => async dispatch => {
  dispatch(setLoading(MATERIAL_CREATE_REQUEST));
  try {
    const response = await Axios.post(`/product/addMaterial`, payload);
    const { data } = response;
    if (response.status === 200) {
      dispatch(setSuccess(data.message, MATERIAL_CREATE_SUCCESS));
      dispatch({
        type: MATERIAL_CREATE_SUCCESS,
        data: data.data,
        message: data.message,
      });
      if (callbackFun) callbackFun(data.data);
    } else {
      dispatch(setError(MSG_ERR_RESPONDING_SERVER, MATERIAL_CREATE_FAILED));
    }
  } catch (error) {
    dispatch(setError(error?.data?.message, MATERIAL_CREATE_FAILED));
    // ErrorCatch(error, MATERIAL_CREATE_FAILED, dispatch);
  }
};

export const getMaterialGrades = (payload, callbackFun) => async dispatch => {
  dispatch(setLoading(MATERIAL_GRADE_REQUEST));
  try {
    const response = await Axios.post(`/product/grades`, payload);
    const { data } = response;
    if (response.status === 200) {
      dispatch(setSuccess(data.message, MATERIAL_GRADE_SUCCESS));
      dispatch({
        type: MATERIAL_GRADE_SUCCESS,
        data: data.data,
        message: data.message,
      });
      return Promise.resolve(response);
    //   if (callbackFun) callbackFun(data.data);
    } else {
      dispatch(setError(MSG_ERR_RESPONDING_SERVER, MATERIAL_GRADE_FAILED));
    }
  } catch (error) {
    ErrorCatch(error, MATERIAL_GRADE_FAILED, dispatch);
  }
};

//take user id for delete api request
export const deleteMaterial = (userId, callbackFun) => async dispatch => {
  dispatch(setLoading(MATERIAL_DELETE_REQUEST));
  try {
    const { data } = await Axios.delete(`/user/delete/${userId}`);
    if (data.statusCode === 200) {
      dispatch(setSuccess(data.responseMsg, MATERIAL_DELETE_SUCCESS));
      dispatch({
        type: MATERIAL_DELETE_SUCCESS,
        data: data.data.userDelete,
        message: data.responseMsg,
      });
      if (callbackFun) callbackFun(data.data.userDelete);
    } else {
      dispatch(setError(MSG_ERR_RESPONDING_SERVER, MATERIAL_DELETE_FAILED));
    }
  } catch (error) {
    ErrorCatch(error, MATERIAL_DELETE_FAILED, dispatch);
  }
};
export const deleteBulkMaterial = (userIds, callbackFun) => async dispatch => {
  dispatch(setLoading(DELETE_BULK_MATERIAL_REQUEST));
  try {
    // const { data } = await Axios.delete(`/admin/roles/delete/${roleId}`)
    const data = {
      statusCode: 200,
      data: userIds,
      responseMsg: 'Selected Users were deleted successfully!',
    };
    if (data.statusCode === 200) {
      dispatch(setSuccess(data.responseMsg, DELETE_BULK_MATERIAL_SUCCESS));
      dispatch({
        type: DELETE_BULK_MATERIAL_SUCCESS,
        data: data.data,
        message: data.message,
      });
      if (callbackFun) callbackFun(data.data);
    } else {
      dispatch(
        setError(MSG_ERR_RESPONDING_SERVER, DELETE_BULK_MATERIAL_FAILED),
      );
    }
  } catch (error) {
    ErrorCatch(error, DELETE_BULK_MATERIAL_FAILED, dispatch);
  }
};

export const setSingleMaterialDetail = id => {
  return async dispatch => {
      try {
          const material = await Axios.get(`/product/fetchSingleMaterials/${id}`)
          dispatch({
              type: SET_SINGLEMATERAIL_DETAILS,
              data: material?.data.data,
              message: material?.message
          });
          // dispatch(setSuccess(material?.message, MATERIAL_UPDATE_SUCCESS))
      } catch (error) {
          dispatch(setError(MSG_ERR_RESPONDING_SERVER, MATERIAL_UPDATE_FAILED));
      }
  };
};

export const updateMaterialFnqDetail = (id, data) => {
  return async dispatch => {
      try {
          await Axios.post(`/product/updateFaqs-material/${id}`, data);
          dispatch(setSuccess("Updated"))
          return Promise.resolve();
      } catch (error) {
          dispatch(setError(MSG_ERR_RESPONDING_SERVER));
      }
  };
};