import CustomTable from "components/CustomeTable";
import React, { useEffect, useState } from "react";
import { Edit, MoreHoriz, Visibility } from "@material-ui/icons";
import Axios from "axios";
import { Box, Button, Checkbox, makeStyles, Switch, TableCell, TableRow } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { rfqList } from "redux/actions/Manage/Rfq/rfq";
import CustomModal from "components/CustomModal";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import { setSuccess } from "redux/actions/Common/SuccessAction";
import { setError } from "redux/actions/Common/ErrorAction";
import CmtDropdownMenu from "@coremat/CmtDropdownMenu";
import { Autocomplete, TextField } from "@mui/material";
import { State, City, Country } from "country-state-city";

const useStyles = makeStyles(() => ({
  textarea: {
    width: '100%',
    height: '100%',
    padding: '10px',
    fontSize: '16px',
    fontFamily: 'inherit',
    fontWeight: 300,
    borderColor: 'lightgray',
    borderRadius: '4px',

    '&:focus': {
      borderColor: '#1C61CA',
      borderWidth: '2px',
      outline: 'none',
      borderRadius: 4,
    },
  },
}));


const Rfqs = () => {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [page, setPage] = React.useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();
  const rfqs = useSelector((state) => state?.Rfq);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [rfqData, setRfqData] = useState({
    rfqNo: "",
    location: "",
    countryCode: "",
    requirement: "",
    description: "",
  })

  const onUserMenuClick = (menu, elm) => {
    if (menu.action === 'edit') {
      setRfqData(elm)
      setOpenModal(true);
      setIsEdit(true);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeStatusSwitch = async (elm) => {
    let status;
    if (elm.status === true) status = false;
    else status = true;
    const { data } = await Axios.put(`/api/v1/admin/rfq/update/${elm?._id}`, {
      status
    })
    dispatch(setSuccess(data.message))
    dispatch(rfqList({
      search: searchTerm,
      page: page + 1
    }));
  };

  const getUserActions = user => {
    const actions = [];
    actions.push({ action: 'edit', label: 'Edit', icon: <Edit /> });
    return actions;
  };

  const userActions = getUserActions();


  const headCells = [
    {
      id: 'rfqNo',
      numeric: false,
      disablePadding: false,
      label: 'RFQ No',
    },
    {
      id: 'location',
      numeric: false,
      disablePadding: false,
      label: 'Location',
    },
    {
      id: 'requirement',
      numeric: false,
      disablePadding: false,
      label: 'Requirement',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      sort: false,
      label: 'Status',
      render: elm => (
        <Switch
          // defaultChecked={elm.showInMiddle === true ? true : false}
          checked={elm.status === true ? true : false}
          onChange={() => handleChangeStatusSwitch(elm)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      ),
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Actions',
      sort: false,
      render: elm => (
        <>
          {/* {!updatePermission && !deletePermission ? '-' : <CmtDropdownMenu items={userActions} onItemClick={(menu) => onUserMenuClick(menu, elm)} TriggerComponent={<MoreHoriz />} />} */}
          {
            <CmtDropdownMenu
              items={userActions}
              onItemClick={menu => onUserMenuClick(menu, elm)}
              TriggerComponent={<MoreHoriz />}
            />
          }
        </>
      ),
    },
  ]

  const handleAddButton = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setRfqData({
      rfqNo: "",
      location: "",
      countryCode: "",
      requirement: "",
      description:""
    });
    setIsEdit(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRfqData((pre) => ({ ...pre, [name]: value }))
  }

  const handleAddEditRfq = async (e) => {
    try {
      e.preventDefault();
      const { data } = !isEdit ? await Axios.post("/api/v1/admin/rfq/add", {
        ...rfqData
      }) : await Axios.put(`/api/v1/admin/rfq/update/${rfqData?._id}`, {
        ...rfqData
      })
      dispatch(setSuccess(data.message))
      dispatch(rfqList())
    } catch (error) {
      dispatch(setError(error?.data?.error));
    } finally {
      setOpenModal(false)
      setRfqData({
        rfqNo: "",
        location: "",
        countryCode: "",
        requirement: "",
        description: ""
      });
      setIsEdit(false);
    }
  }

  useEffect(() => {
    dispatch(rfqList({
      search: searchTerm,
      page: page + 1
    }))
  }, [searchTerm, page])

  return (
    <>
      <CustomTable
        TableName="All RFQ"
        addButtonText={'Add RFQ'}
        handleAddButton={handleAddButton}
        row={rfqs?.rfqList}
        headCells={headCells}
        isDynamic={true}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        count={rfqs?.totalPage || 0}
        rowsPerPage={perPage}
        onPageChange={handlePageChange}
        page={page}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleSearch={(text) => setSearchTerm(text)}
        handleSearchSubmit={() => {
          setPage(0)
        }}
      />

      <CustomModal
        open={openModal}
        onClose={handleCloseModal}
        title={isEdit ? 'Edit RFQ' : 'Add RFQ'}
      >
        <form onSubmit={handleAddEditRfq}>
          <Box mb={4}>
            <AppTextInput
              fullWidth
              required
              sx={{ mb: 2 }}
              name="rfqNo"
              variant="outlined"
              label="RFQ No"
              value={rfqData?.rfqNo}
              onChange={handleChange}
            />
            <Box sx={{ my: 5 }}>
              {/* <AppTextInput
                fullWidth
                required
                name="location"
                variant="outlined"
                label="Location"
                value={rfqData?.location}
                onChange={handleChange}
              /> */}
              <Autocomplete
                id="country-select"
                sx={{ width: "100%" }}
                size="small"
                options={Country.getAllCountries()}
                value={{
                  name: rfqData?.location || ""
                }}
                getOptionLabel={(option) => option?.name}
                filterOptions={(options, { inputValue }) => {
                  const lowerInput = inputValue.toLowerCase();
                  return options.filter(
                    (option) =>
                      option?.name?.toLowerCase()?.includes(lowerInput) ||
                      option?.isoCode?.toLowerCase()?.includes(lowerInput)
                  );
                }}
                isOptionEqualToValue={(option, value) => option?.name === value?.name}
                onChange={(e, newValue) => {
                  setRfqData((pre) => ({ ...pre, location: newValue?.name, countryCode: newValue?.isoCode }))
                }}
                renderOption={(props, option) => {
                  const { key, ...optionProps } = props;
                  return (
                    <Box
                      key={key}
                      component="li"
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      {...optionProps}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        srcSet={`https://flagcdn.com/w40/${option?.isoCode.toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${option?.isoCode.toLowerCase()}.png`}
                        alt={option?.isoCode}
                        style={{ marginRight: "10px" }}
                      />
                      {option?.name} ({option?.isoCode})
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Location"
                    required
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Box>
            <AppTextInput
              fullWidth
              required
              sx={{ mb: 2 }}
              name="requirement"
              variant="outlined"
              label="Requirement"
              value={rfqData?.requirement}
              onChange={handleChange}
            />
            <Box sx={{ my: 5 }}>
              <textarea
                className={classes.textarea}
                rows={5}
                fullWidth
                name="description"
                width={100}
                variant="outlined"
                label="Description"
                value={rfqData?.description}
                placeholder="Description"
                onChange={handleChange}
              />
            </Box>
            <Box mt={5}>
              <Button variant="contained" color="primary" type="submit" >
                {isEdit ? 'Update' : 'Submit'}
              </Button>
            </Box>
          </Box>
        </form>
      </CustomModal>
    </>
  )
}

export default Rfqs