import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';
import { Grow, Slide, Snackbar } from '@material-ui/core';
import { hideError } from 'redux/actions/Common/ErrorAction';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading } from 'redux/actions/Common/LoadingAction';
import { hideSuccess } from 'redux/actions/Common/SuccessAction';

function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
}

function GrowTransition(props) {
    return <Grow {...props} />;
}
function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
}

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

function TransitionRight(props) {
    return <Slide {...props} direction="right" />;
}

function TransitionDown(props) {
    return <Slide {...props} direction="down" />;
}
const AlertBox = ({ children, type, style, closeBtn, verPos, horPos, transitionPos, duration }) => {
    const { error } = useSelector(state => state.Error)
    const { loading } = useSelector(state => state.Loading)
    const { success } = useSelector(state => state.Success)

    const dispatch = useDispatch()
    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const [transition, setTransition] = React.useState(undefined);
    const { vertical, horizontal, open } = state;

    const handleClose = () => {
        setState({ ...state, open: false });
        if (loading)
            dispatch(hideLoading())
        if (error)
            dispatch(hideError())
        if (success)
            dispatch(hideSuccess())
    };

    useEffect(() => {
        setTransition(() => transitionPos ? transitionPos : TransitionDown);
        setState({ open: true, vertical: 'top', horizontal: 'center' });
    }, [])

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: verPos ? verPos : 'top', horizontal: horPos ? horPos : 'center' }}
                open={open}
                autoHideDuration={duration ? duration : 3000}
                onClose={handleClose}
                TransitionComponent={transitionPos ? transitionPos : transition}
                key={vertical + horizontal}>
                <Alert variant={style ? style : 'filled'} severity={type ? type : 'success'}>
                    {children}
                </Alert>
            </Snackbar>
        </>
    )
}
AlertBox.propTypes = {
    type: PropTypes.oneOf(['success', 'error', 'info', 'warning']),
    style: PropTypes.oneOf(['outlined', 'filled']),
    closeBtn: PropTypes.bool,
    verPos: PropTypes.oneOf(['top', 'bottom']),
    horPos: PropTypes.oneOf(['left', 'right', 'center']),
    transitionPos: PropTypes.oneOf([TransitionLeft, TransitionUp, TransitionRight, TransitionDown, SlideTransition, GrowTransition]),
    duration: PropTypes.number,
};
export default AlertBox