// --------------- Grade ----------------------

export const GRADE_MODAL_LIST_REQUEST = "GRADE_MODAL_LIST_REQUEST";
export const GRADE_MODAL_LIST_SUCCESS = "GRADE_MODAL_LIST_SUCCESS";
export const GRADE_MODAL_LIST_FAILED = "GRADE_MODAL_LIST_FAILED";

export const GRADE_MODAL_CREATE_REQUEST = "GRADE_MODAL_CREATE_REQUEST";
export const GRADE_MODAL_CREATE_SUCCESS = "GRADE_MODAL_CREATE_SUCCESS";
export const GRADE_MODAL_CREATE_FAILED = "GRADE_MODAL_CREATE_FAILED";

export const GRADE_MODAL_UPDATE_REQUEST = "GRADE_MODAL_UPDATE_REQUEST";
export const GRADE_MODAL_UPDATE_SUCCESS = "GRADE_MODAL_UPDATE_SUCCESS";
export const GRADE_MODAL_UPDATE_FAILED = "GRADE_MODAL_UPDATE_FAILED";