import { DELETE_BULK_GRADE_SUCCESS, GRADE_CREATE_SUCCESS, GRADE_DELETE_SUCCESS, GRADE_LIST_SUCCESS, GRADE_UPDATE_SUCCESS, SET_GRADEELM_DETAILS, SET_GRADE_DETAILS } from "redux/actions/index";

const INIT_STATE =
{
    grades: [],
    grade: null,
    total: 0,
    perPage: 0
    // permissions: null,
}
const INIT_STATE_DETAIL =
{
    gradeElm:null,
    grade: null,
}

export const GradesReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GRADE_CREATE_SUCCESS: {
            return {
                ...state,
                grade: action.data,
                action: action.type,
                message: action.message,
                grades: [action.data, ...state.grades]
            };
        }
        case GRADE_LIST_SUCCESS: {
            return {
                ...state,
                grades: action.data,
                total: action.total,
                action: action.type,
                message: action.message,
                perPage: action.perPage,
            };
        }
        //grades: state.grades.map(grade => (grade.id === action.payload.id ? action.payload : grade))
        case GRADE_UPDATE_SUCCESS: {
            return {
                ...state,
                grade: action.data,
                action: action.type,
                message: action.message,
                grades: state.grades.map(grade => (grade._id === action.data._id ? action.data : grade))
            };
        }
        case GRADE_DELETE_SUCCESS: {
            return {
                ...state,
                grade: action.data,
                action: action.type,
                message: action.message,
                grades: state.grades.filter(grade => grade._id !== action.data._id),
            };
        }
        case DELETE_BULK_GRADE_SUCCESS: {
            return {
                ...state,
                ...state,
                action: action.type,
                message: action.message,
                grades: state.grades.filter(grade => !action.data.includes(grade.id)),
            };
        }
        default:
            return state;
    }
};
export const SetGradeDetailReducer = (state = INIT_STATE_DETAIL, action) => {
    switch (action.type) {
        case SET_GRADE_DETAILS:
            return {
                ...state,
                grade: action.data,
                action: action.type,
            };
        case SET_GRADEELM_DETAILS:
            return {
                ...state,
                gradeElm:action.data
            }
        default:
            return state;
    }
};