import React, { useState } from 'react';
import { USER_LOGIN_SUCCESS } from '@jumbo/constants/ActionTypes';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import SidebarButtons from '../../../@jumbo/components/AppLayout/partials/SideBar/SIdebarButtons';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';

const breadcrumbs = [
  { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  { label: <IntlMessages id={'pages.samplePage'} />, isActive: true },
];
const SamplePage = () => {
  const { success } = useSelector(state => state.Success)
  const successAction = useSelector(state => state.Success.action)

  const [Success, setSuccess] = useState(false)
  const [successMsg, setSuccessMsg] = useState("")
  useEffect(() => {
    if (successAction === USER_LOGIN_SUCCESS) {
      setSuccess(true)
      setSuccessMsg(success)
    }
  }, [successAction])
  useEffect(() => {
    if (!success)
      setSuccess(false)
  }, [success])
  return (
    <>
      {/* {Success && <AlertBox type='success' style="filled" >{successMsg}</AlertBox>} */}
      <PageContainer heading={<IntlMessages id="pages.samplePage" />} breadcrumbs={breadcrumbs}>
        <GridContainer>
          <Grid item xs={12}>
            <div style={{ marginBottom: 10 }}>
              <IntlMessages id="pages.samplePage.description" />
            </div>
            <Divider />
            <div style={{ marginTop: 24 }}>
              <h3>Knowledge Base and Support</h3>
              <SidebarButtons />
            </div>
          </Grid>
        </GridContainer>
      </PageContainer>
    </>
  );
};

export default SamplePage;
