import {
  DELETE_BULK_SUPPLIER_SUCCESS,
  SUPPLIER_CREATE_SUCCESS,
  SUPPLIER_DELETE_SUCCESS,
  SUPPLIER_LIST_SUCCESS,
  SUPPLIER_UPDATE_SUCCESS,
  SUPPLIER_PRODUCTLIST_SUCCESS,
  SET_SUPPLIER_DETAILS,
  CLEAR_SUPPLIER_DETAILS,
  PRODUCT_MATERIAL_GRADE_LIST_SUCCESS,
  SUPPLIER_ADDPRODUCT_SUCCESS,
  SUPPLIER_SETPRODUCT_SUCCESS,
  SUPPLIER_EDITPRODUCT_SUCCESS,
  SUPPLIER_SETPRODUCTLIST_SUCCESS,
  FETCH_GRADE_SUCCESS,
  FETCH_HOT_PRODUCT_REQUEST,
  FETCH_HOT_PRODUCT_SUCCESS,
  FETCH_HOT_PRODUCT_FAILED

} from 'redux/actions/index';

const INIT_STATE = {
  suppliers: [],
  supplier: null,
  count: null,
  total: 0,
  perPage: 0,
  supplierproduct: null,
  // permissions: null,
};
const INIT_STATE_DETAIL = {
  supplier: null,
  supplierProductList: null,
  gradesList: null
};
const INIT_STATE_LIST = {
  products: [],
  materials: [],
  grades: []
}
const INIT_STATE_PRODDETAIL = {
  productId: null,
  materialId: null,
  gId: null,
  userId: null
}

const INIT_STATE_HOTPRODUCT = {
  hotProducts: [],
  totalPages: 0,
  totalHotProduct:0,
  isLoading: false,
  isError: false,
  errorMessage: null
}

export const SuppliersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SUPPLIER_CREATE_SUCCESS: {
      return {
        ...state,
        supplier: action.data,
        action: action.type,
        message: action.message,
        suppliers: [action.data, ...state.suppliers],
      };
    }
    case SUPPLIER_LIST_SUCCESS: {
      return {
        ...state,
        suppliers: action.data,
        total: action.total,
        action: action.type,
        message: action.message,
        perPage: action.perPage,

      };
    }
    //suppliers: state.suppliers.map(supplier => (supplier.id === action.payload.id ? action.payload : supplier))
    case SUPPLIER_UPDATE_SUCCESS: {
      return {
        ...state,
        supplier: action.data,
        action: action.type,
        message: action.message,
        suppliers: state.suppliers.map(supplier =>
          supplier._id === action.data._id ? action.data : supplier,
        ),
      };
    }
    case SUPPLIER_DELETE_SUCCESS: {
      return {
        ...state,
        supplier: action.data,
        action: action.type,
        message: action.message,
        suppliers: state.suppliers.filter(
          supplier => supplier.userId !== action.data.UserId,
        ),
      };
    }
    case DELETE_BULK_SUPPLIER_SUCCESS: {
      return {
        ...state,
        ...state,
        action: action.type,
        message: action.message,
        suppliers: state.suppliers.filter(
          supplier => !action.data.includes(supplier.id),
        ),
      };
    }
    case SUPPLIER_PRODUCTLIST_SUCCESS: {
      return {
        ...state,
        action: action.type,
        message: action.message,
        supplierproduct: action.data,
        total: action.total,
      };
    }
    default:
      return state;
  }
};
export const SetsupplierDetailReducer = (state = INIT_STATE_DETAIL, action) => {
  switch (action.type) {
    case SET_SUPPLIER_DETAILS:
      return {
        ...state,
        supplier: action.data,
        action: action.type,
      };
    case CLEAR_SUPPLIER_DETAILS:
      return {
        ...state,
        supplier: null,
        action: action.type,
      };
    case SUPPLIER_ADDPRODUCT_SUCCESS:
      return {
        ...state,
        supplier: null,
        action: action.type,
      };
    case SUPPLIER_EDITPRODUCT_SUCCESS:
      return {
        ...state,
        supplier: null,
        action: action.type,
      };
    case SUPPLIER_SETPRODUCTLIST_SUCCESS: {
      return {
        ...state,
        supplierProductList: action.data,
        action: action.type
      };
    }
    case FETCH_GRADE_SUCCESS: {
      return {
        ...state,
        gradesList: action.data,
        action: action.type
      }
    }
    default:
      return state;
  }
};
export const SetProductMaterialGradeListReducer = (state = INIT_STATE_LIST, action) => {
  switch (action.type) {
    case PRODUCT_MATERIAL_GRADE_LIST_SUCCESS: return {
      ...state,
      products: action.data.products,
      materials: action.data.material,
      grades: action.data.gradeslist,
    };
    default: return state;
  }
};
export const SetSupplierProductDetail = (
  state = INIT_STATE_PRODDETAIL,
  action,
) => {
  switch (action.type) {
    case SUPPLIER_SETPRODUCT_SUCCESS:

      console.log(action.data)
      return {
        ...state,
        productId: action.data.productId,
        materialId: action.data.materialId,
        gId: action.data.gradeId,
        userId: action.data.userId,
      };

    default:
      return state;
  }
};
export const SetSupplierHotProductReducer = function (state = INIT_STATE_HOTPRODUCT, action) {
  switch (action.type) {
    case FETCH_HOT_PRODUCT_REQUEST: {
      return {
        hotProducts: [],
        totalPages: 0,
        totalHotProduct: 0,
        isLoading: true,
        isError: false,
        errorMessage: null
      }
    }
    case FETCH_HOT_PRODUCT_SUCCESS: {
      return {
        isLoading: false,
        isError: false,
        errorMessage: null,
        hotProducts: action.payload.hotProduct,
        totalPages: action.payload.totalPages,
        totalHotProduct: action.payload.totalHotProduct
      }
    }
    case FETCH_HOT_PRODUCT_FAILED: {
      return {
        hotProducts: [],
        totalPages: 0,
        totalHotProduct: 0,
        isLoading: false,
        isError: true,
        errorMessage: action.payload.errorMessage
      }
    }
    default:
      return state;
  }
}