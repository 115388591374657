import React, { useState } from 'react'
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import IdleTimerProgressBar from 'components/IdleTimerProgressBar'
import { useIdleTimer } from 'react-idle-timer'
import { useDispatch } from 'react-redux'
import { AuhMethods } from 'services/auth'

const useStyles = makeStyles(theme => ({
    dialogRoot: {
        position: 'relative',
    },
    dialogTitleRoot: {
        '& .MuiTypography-h6': {
            fontSize: 16,
            color: theme.palette.common.dark,
        },
    },
    dialogActionsRoot: {
        padding: '16px 24px',
    },
}));

const IdleTimerModal = () => {
    const dispatch = useDispatch()
    const [showIdleModal, setShowIdleModal] = useState(false)

    const handleOnIdle = event => {
        setShowIdleModal(true)
    }
    const { getTotalIdleTime, getElapsedTime, getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * 11,
        onIdle: handleOnIdle,
        // onActive: handleOnActive,
        // onAction: handleOnAction,
        debounce: 500
    })
    const handleConfirmLogout = () => {
        setShowIdleModal(false)
        dispatch(AuhMethods[CurrentAuthMethod].onLogout());
    }
    const handleCancelLogout = () => {
        setShowIdleModal(false)
    }
    const classes = useStyles();
    return (
        <Dialog open={showIdleModal} onClose={handleCancelLogout} aria-labelledby="confirm-dialog-title" className={classes.dialogRoot}>
            <DialogTitle id="confirm-dialog-title" className={classes.dialogTitleRoot}>
                You have been Idle!{<IdleTimerProgressBar />}
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText>You will get timed out. Do you want to stay?</DialogContentText>
            </DialogContent>
            <DialogActions classes={{ root: classes.dialogActionsRoot }}>
                <Button onClick={handleCancelLogout} color="primary" variant="outlined">
                    Stay
                </Button>
                <Button onClick={handleConfirmLogout} color="primary" variant="contained">
                    Logout
                </Button>
            </DialogActions>
        </Dialog>
    );
}
// }
export default IdleTimerModal