import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import {
  Box,
  Grid,
  Button,
  Chip,
  Divider,
  Paper,
  Typography,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import { Add, Label } from '@material-ui/icons';

import GridContainer from '@jumbo/components/GridContainer';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import WysiwygEditorExample from 'components/wysiwygEditor';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';

import {
  createProduct,
  setProductDetail as setSingleProductDetail,
  updateProduct,
} from 'redux/actions/Manage/Products/Products';
import CustomEditor from 'components/CustomEditor';

// const useStyles = makeStyles(() => ({
//   errorCk: {
//     color: '#f44336',
//     marginRight: '14px',
//     marginTop: '4px',
//     fontSize: '0.75rem',
//   },
//   avatar: {
//     borderRadius: '0',
//     width: 140,
//     height: 140,
//     objectFit: 'cover',
//     objectPosition: 'center',
//     padding: 5,
//     marginTop: "14px"
//   },
//   typoMarginBottom: {
//     marginBottom: '10px',
//   },
//   editorLabelError: {
//     color: '#f44336',
//     fontSize: '16px',
//     fontWeight: 500,
//   },
//   textarea: {
//     width: '100%',
//     // height: '43%',
//     padding: "8px",
//     fontSize: "17px",
//     fontFamily: 'inherit',
//     fontWeight: 300,
//     borderColor: "lightgray",
//     borderRadius: "4px",

//     '&:focus': {
//       borderColor: "#1C61CA",
//       borderWidth: "2px",
//       outline: "none",
//       borderRadius: 4
//     }
//   },
//   textarea_red: {
//     width: '100%',
//     // height: '43%',
//     padding: "8px",
//     fontSize: "19px",
//     fontFamily: 'Normal',
//     fontWeight: 200,
//     borderColor: "#f44336",
//     borderRadius: "4px",
//     '&:focus': {
//       borderColor: "#1C61CA",
//       borderWidth: "2px",
//       outline: "none",
//       borderRadius: 4
//     },
//     addTextArea:{
//       height:'43%'
//     },
//     editTextArea:{
//       height:'24%'
//     }

//   },

//   label: {
//     fontSize: '16px',
//     fontFamily: "inherit",
//     fontWeight: 400,
//     lineHeight: 1.43,
//   },
//   grid: {
//     marginBottom: "-20px",
//     marginTop: "-10px"
//   }

// }));

const AddEditProduct = ({
  history,
  open,
  onCloseDialog,
  setDataUpdated,
  product,
}) => {
  const { id } = useParams();
  const useStyles = makeStyles(() => ({
    errorCk: {
      color: '#f44336',
      marginRight: '14px',
      marginTop: '4px',
      fontSize: '0.75rem',
    },
    avatar: {
      borderRadius: '0',
      width: 140,
      height: 140,
      objectFit: 'cover',
      objectPosition: 'center',
      padding: 5,
      marginTop: '14px',
    },
    typoMarginBottom: {
      marginBottom: '10px',
    },
    editorLabelError: {
      color: '#f44336',
      fontSize: '16px',
      fontWeight: 500,
    },
    textarea: {
      width: '100%',
      height: '42%',
      padding: '8px',
      fontSize: '17px',
      fontFamily: 'inherit',
      fontWeight: 300,
      borderColor: 'lightgray',
      borderRadius: '4px',

      '&:focus': {
        borderColor: '#1C61CA',
        borderWidth: '2px',
        outline: 'none',
        borderRadius: 4,
      },
    },
    textarea_red: {
      width: '100%',
      height: '42%',
      padding: '8px',
      fontSize: '19px',
      fontFamily: 'Normal',
      fontWeight: 200,
      borderColor: '#f44336',
      borderRadius: '4px',
      '&:focus': {
        borderColor: '#1C61CA',
        borderWidth: '2px',
        outline: 'none',
        borderRadius: 4,
      },
      addTextArea: {
        height: '43%',
      },
      editTextArea: {
        height: '24%',
      },
    },

    label: {
      fontSize: '16px',
      fontFamily: 'inherit',
      fontWeight: 400,
      lineHeight: 1.43,
    },
    grid: {
      marginBottom: '-20px',
      marginTop: '-10px',
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();

  //------------all use selectors
  const { user } = useSelector(({ auth }) => auth.authUser);
  // const { product } = useSelector(state => state?.SetProductDetail);

  const successAction = useSelector(state => state.Success.action);

  //-------------all use states
  const [keywordsArr, setKeywordsArr] = useState([]);
  const [descriptionHTML, setDescriptionHTML] = useState('');
  const [file, setFile] = useState([]);
  const [icon, setIcon] = useState([]);
  const [productDetail, setProductDetail] = useState({
    // code: '',
    description: '',
    h1: '',
    h2: '',
    idOfProductCreator: '',
    keywords: null,
    meta: '',
    name: '',
    nameOfProductCreator: '',
    title: '',
    url: '',
    AltImageName: '',
    preview: '',
    productId: '',
    iconPreview: '',
  });
  const [errorText, setErrorText] = React.useState({});
  const [metaCount, setMetaCount] = useState({
    title: 0,
    meta: 0,
  });

  const countWords = str => {
    // Remove leading and trailing whitespaces
    str = str?.trim();
    // Remove consecutive whitespaces and replace them with a single space
    str = str?.replace(/\s+/g, ' ');
    // Split the string by spaces to get an array of words
    var words = str?.split(' ');
    // Return the number of words
    return words.length;
  };

  //-------all handle functions
  const validate = () => {
    let errors = {};
    let isError = false;
    const {
      // code
      h1,
      h2,
      meta,
      name,
      title,
      url,
    } = productDetail;
    let tempDescription = descriptionHTML;
    let noSpaceData = tempDescription?.replace(/\&nbsp;/g, '');
    const noTagData = noSpaceData?.replace(/<[^>]*>/g, '');

    if (!name.trim()) {
      errors.name = 'Please enter name.';
      isError = true;
    }
    // if (!code) {
    //   errors.code = 'Please enter code.';
    //   isError = true;
    // }
    if (noTagData.length < 2) {
      errors.descriptionHTML = 'Please enter description.';
      isError = true;
    }
    if (!meta) {
      errors.meta = 'Please enter meta description.';
      isError = true;
    }
    if (countWords(meta) > 300) {
      errors.meta = 'Meta description must be less than 300 words.';
      isError = true;
    }
    if (!title) {
      errors.title = 'Please enter meta title.';
      isError = true;
    }
    if (!url) {
      errors.url = 'Please enter URL.';
      isError = true;
    }
    if (!keywordsArr.length) {
      errors.keywords = 'Please enter keyword.';
      isError = true;
    }
    if (!file.length && !preview) {
      errors.file = 'Please upload image.';
      isError = true;
    }
    if (!icon.length && !iconPreview) {
      errors.icon = 'Please upload icon image.';
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };

  const validateKeyword = () => {
    let error = '';
    let isError = false;
    if (!keywords) {
      error = 'Please enter keywords.';
      isError = true;
    }
    setErrorText({ ...errorText, keywords: error });
    return {
      error,
      isError,
    };
  };

  const onKeywordsAdd = () => {
    let validateData = validateKeyword();
    if (!validateData.isError) {
      setKeywordsArr(keywordsArr => [...keywordsArr, keywords]);
      setProductDetail({ ...productDetail, keywords: '' });
    }
  };

  const onKeywordsDelete = (e, value) => {
    setKeywordsArr(keywordsArr =>
      keywordsArr.filter(e => {
        if (e.length) {
          return e != value;
        }
      }),
    );
  };

  const onLabelChange = evt => {
    const { name, value } = evt.target;
    const regex = /[\W_]+|\s+/g;
    if (name === 'name') {
      const nameValue = value ? value.replace(regex, '-').toLowerCase() : '';
      // productDetail.code = nameValue
      productDetail.url = nameValue;
    }
    setProductDetail({ ...productDetail, [name]: value });
    setMetaCount({ ...metaCount, [name]: value.length });
    console.log(productDetail);
  };

  const onSubmitClick = () => {
    let validateData = validate();
    if (!validateData.isError) {
      handleProductSave();
    }
  };

  const handleProductSave = async () => {
    let keywordStr = '';
    keywordsArr.map(e => (keywordStr += ',' + e));
    let formData = new FormData();
    // formData.append('code', code);
    formData.append('description', descriptionHTML);
    formData.append('idOfProductCreator', user?.userId);
    formData.append('keywords', keywordStr);
    formData.append('meta', meta);
    formData.append('name', name);
    formData.append('nameOfProductCreator', user?.name);
    formData.append('title', title);
    formData.append('url', url);
    formData.append('logo', file[0]);
    formData.append('icon', icon[0]);
    formData.append('AltImageName', AltImageName);
    productId && formData.append('id', id);
    productId && formData.append('productId', productId);
    dispatch(
      id
        ? updateProduct(formData, data => {
            window.reload();
          })
        : createProduct(formData, data => {
            if (data) history.push('/manage-products');
          }),
    );
  };

  //------all useEffects

  useEffect(() => {
    if (product && id) {
      const {
        // code,
        description,
        h1,
        h2,
        idOfProductCreator,
        keywords,
        meta,
        name,
        nameOfProductCreator,
        title,
        url,
        AltImageName,
        logo,
        productId,
        icon
      } = product;
      setProductDetail({
        // code,
        description,
        h1,
        h2,
        idOfProductCreator,
        keywords: '',
        meta,
        name,
        nameOfProductCreator,
        title,
        url,
        AltImageName,
        preview: logo,
        iconPreview: icon,
        productId,
      });
      setMetaCount({ ...metaCount, title: title.length, meta: meta.length });
      const x = keywords?.split(',') || [];
      let data = x?.filter((item, index) => item !== '');
      setKeywordsArr(data);
      setDescriptionHTML(description);
    }
  }, [product]);

  // useEffect(()=>{
  //   if(id){
  //     dispatch(setSingleProductDetail(id))
  //   }
  // },[id])

  const {
    // code,
    description,
    h1,
    h2,
    keywords,
    meta,
    name,
    title,
    url,
    AltImageName,
    preview,
    iconPreview,
    productId,
  } = productDetail;
  return (
    <PageContainer>
      <Paper className={classes.paper}></Paper>
      <CmtCard>
        <CmtCardContent>
          <Typography variant="h3" className={classes?.typoMarginBottom}>
            {id ? 'Edit Product' : 'Add Product'}
          </Typography>
          <Divider />
          <Box
            mt={3}
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            alignItems="center"
            mb={{ xs: 6, md: 5 }}
          >
            <GridContainer>
              <Grid item xs={12} sm={6}>
                <AppTextInput
                  fullWidth
                  required
                  name="name"
                  variant="outlined"
                  label="Product Name"
                  value={name}
                  onChange={onLabelChange}
                  helperText={errorText?.name}
                />
                <br />
                <br />
                <AppTextInput
                  fullWidth
                  name="url"
                  required
                  variant="outlined"
                  label="URL"
                  value={url}
                  onChange={onLabelChange}
                  helperText={errorText?.url}
                />
                <br />
                <br />
                <AppTextInput
                  fullWidth
                  name="AltImageName"
                  variant="outlined"
                  label="Alt Image Name"
                  value={AltImageName}
                  onChange={onLabelChange}
                />
                <br />
                <br />
                <AppTextInput
                  fullWidth
                  name="file"
                  type="file"
                  required
                  variant="outlined"
                  onChange={evt => {
                    setProductDetail({ ...productDetail, preview: '' });
                    setFile(evt.target.files);
                  }}
                  helperText={errorText?.file}
                />
                {preview && (
                  <img
                    src={preview}
                    alt={AltImageName}
                    className={classes?.avatar}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <AppTextInput
                  fullWidth
                  name="title"
                  required
                  variant="outlined"
                  label={`Meta Title / ${metaCount.title}`}
                  value={title}
                  onChange={onLabelChange}
                  helperText={errorText?.title}
                />
                {/* <p style={{ marginLeft: "18px", fontSize: "13px" }}>{metaCount.title}</p> */}
                <br />
                <br />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <label className={classes.label}>Meta Description*</label>
                  <label className={classes.label}>{metaCount.meta}</label>
                </Box>
                <textarea
                  className={
                    errorText.meta ? classes.textarea_red : classes.textarea
                  }
                  rows={2}
                  fullWidth
                  name="meta"
                  width={100}
                  required
                  variant="outlined"
                  label="Meta Description"
                  value={meta}
                  onChange={onLabelChange}
                />
                {errorText?.meta ? (
                  <p style={{ color: '#f44336', fontSize: '12px' }}>
                    {errorText?.meta}
                  </p>
                ) : (
                  ''
                )}
                <AppTextInput
                  fullWidth
                  name="icon"
                  type="file"
                  required
                  variant="outlined"
                  onChange={evt => {
                    setProductDetail({ ...productDetail, iconPreview: '' });
                    setIcon(evt.target.files);
                  }}
                  helperText={errorText?.icon}
                />
                {iconPreview && (
                  <img src={iconPreview} className={classes?.avatar} />
                )}
              </Grid>
              <Grid item xs={12} sm={11} md={11}>
                <AppTextInput
                  fullWidth
                  name="keywords"
                  variant="outlined"
                  required
                  label="Keywords"
                  value={keywords}
                  onChange={onLabelChange}
                  helperText={errorText?.keywords}
                />
              </Grid>
              <Grid item sm={1} md={1}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={onKeywordsAdd}
                >
                  <Add />
                </Button>
              </Grid>
              {keywordsArr && keywordsArr.length > 0 && (
                <Grid item xs={12} sm={12}>
                  <Box>
                    {keywordsArr.map((e, key) => {
                      return (
                        e.length !== 0 && (
                          <Chip
                            size="medium"
                            key={e}
                            label={e}
                            clickable
                            onDelete={evt => onKeywordsDelete(evt, e)}
                            color="secondary"
                            variant="outlined"
                            className="mb-2 mr-2"
                          />
                        )
                      );
                    })}
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} sm={12}>
                <span
                  className={
                    errorText?.descriptionHTML ? classes?.editorLabelError : ''
                  }
                >
                  Description *
                </span>
                <CustomEditor
                  editorData={descriptionHTML || ''}
                  setEditorData={setDescriptionHTML}
                  error={errorText?.descriptionHTML}
                />

                <span className={classes?.errorCk}>
                  {errorText?.descriptionHTML}
                </span>
              </Grid>
            </GridContainer>
          </Box>
          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button onClick={() => history.push('/manage-products')}>
              Cancel
            </Button>
            <Box ml={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={onSubmitClick}
              >
                {id ? 'Update' : 'Save'}
              </Button>
            </Box>
          </Box>
        </CmtCardContent>
      </CmtCard>
    </PageContainer>
  );
};

export default AddEditProduct;

AddEditProduct.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
